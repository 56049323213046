import InlineModal from "./InlineModal";
import {useToggle, Document, WithDropDown, ArrowOverlay, XCol} from "@cdx/common";
import {useShouldShowHintKey} from "../features/hints/HintManager";
import {DSIconButton, DSIconInfo, DSIconQuestion, css} from "@cdx/ds";

/** @type {(props: {heading?: string | ReactNode, children: ReactNode, colorTheme?: string | null}) => ReactElement} */
const Helper = ({heading, children, colorTheme = "purpleTextOnLight"}) => {
  const [isActive, {off, toggle}] = useToggle();
  return (
    <InlineModal
      isOpen={isActive}
      onClose={off}
      renderFn={() => (
        <Document>
          {heading && <h1 style={{marginRight: 30}}>{heading}</h1>}
          {children}
        </Document>
      )}
    >
      {(measureRef) => (
        <DSIconButton
          icon={<DSIconInfo />}
          active={isActive}
          onClick={toggle}
          size="sm"
          ref={measureRef}
          variant="tertiary"
          negatePadding
          className={css({colorTheme})}
        />
      )}
    </InlineModal>
  );
};

export default Helper;

/** @type {(props: {
 * children: ReactNode,
 * maxWidth?: number,
 * initialOpenKey?: string,
 * hide?: boolean,
 * color?: string}) => ReactElement} */
export const HelperDropDown = ({
  children,
  maxWidth = 275,
  initialOpenKey,
  hide,
  color: _,
  ...props
}) => {
  // only show initially if a `initialOpenKey` is passed
  const [shouldShowInitially, dismiss] = useShouldShowHintKey(initialOpenKey);
  const handleClose = () => {
    if (initialOpenKey && shouldShowInitially) return dismiss();
  };
  return (
    <WithDropDown
      renderOverlay={(overlayProps) => (
        <ArrowOverlay bg="gray800" arrowSize="xs" {...overlayProps}>
          <XCol px={3} py={2} style={{maxWidth}}>
            <Document variant="onDark">{children}</Document>
          </XCol>
        </ArrowOverlay>
      )}
      initialOpen={shouldShowInitially}
      withHover
      overlayProps={{placement: "bottom", distanceFromAnchor: 10}}
      as={DSIconButton}
      setProps={({isClicked}) => ({
        active: isClicked,
        style: {opacity: hide && !isClicked ? 0 : 1},
      })}
      variant="tertiary"
      size="sm"
      disabled={hide}
      {...props}
      onClose={handleClose}
      icon={<DSIconQuestion />}
      negatePadding
    />
  );
};

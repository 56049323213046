import {createContext, useMemo, useContext, ReactNode} from "react";
import {Root} from "../../cdx-models/Root";
import {CardDiffNotification} from "../../cdx-models/CardDiffNotification";
import {ResolvableNotification} from "../../cdx-models/ResolvableNotification";
import {DueCard} from "../../cdx-models/DueCard";
import {CardId} from "../../cdx-models/Card";
import {DeckId} from "../../cdx-models/Deck";
import {ResolvableId} from "../../cdx-models/Resolvable";

const NotificationContext = createContext<Notifications>(null as any as Notifications);

const emptyList: unknown[] = [];

const getNotificationInfo = (
  dueCards: DueCard[],
  cardDiffNotis: CardDiffNotification[],
  resolvableNotis: ResolvableNotification[]
) => {
  return {
    dueCards: dueCards.filter((n) => !n.$meta.isDeleted() && n.card && !n.card.$meta.isDeleted()),
    diffs: cardDiffNotis,
    resolvables: resolvableNotis,
    perCardDiffs: cardDiffNotis.reduce(
      (memo, n) => {
        if (n.card && !n.card.$meta.isDeleted()) {
          const cardId = n.card.$meta.get("cardId", null);
          if (cardId) {
            if (memo[cardId]) console.warn("Already notis for card", cardId);
            memo[cardId] = n;
          }
        }
        return memo;
      },
      {} as Record<CardId, CardDiffNotification>
    ),
    perCardResolvables: resolvableNotis.reduce(
      (memo, n) => {
        const cardId = n.resolvable.card?.$meta.get("cardId", null);
        if (cardId) (memo[cardId] = memo[cardId] || []).push(n);
        return memo;
      },
      {} as Record<CardId, ResolvableNotification[]>
    ),
    perDeckDiffs: cardDiffNotis.reduce(
      (memo, n) => {
        const deckId = n.card.deck?.$meta.get("id", null);
        if (deckId) {
          (memo[deckId] = memo[deckId] || []).push(n);
        }
        return memo;
      },
      {} as Record<DeckId, CardDiffNotification[]>
    ),
    perDeckResolvables: resolvableNotis.reduce(
      (memo, n) => {
        const deckId = n.resolvable.card?.deck?.$meta.get("id", null);
        if (deckId) (memo[deckId] = memo[deckId] || []).push(n);
        return memo;
      },
      {} as Record<DeckId, ResolvableNotification[]>
    ),
    perResolvable: resolvableNotis.reduce(
      (memo, n) => {
        const resolvableId = n.resolvable.$meta.get("id", null);
        if (resolvableId) {
          if (memo[resolvableId]) console.warn("Already notis for resolvable", resolvableId);
          memo[resolvableId] = n;
        }
        return memo;
      },
      {} as Record<ResolvableId, ResolvableNotification>
    ),
  };
};

export type Notifications = ReturnType<typeof getNotificationInfo>;

export const ProvideNotificationContext = ({root, children}: {root: Root; children: ReactNode}) => {
  const {loggedInUser, account} = root;
  const accountId = account.$meta.get("id", null);
  const cardDiffNotis =
    loggedInUser && accountId
      ? loggedInUser.$meta.find("cardDiffNotifications", {accountId})
      : (emptyList as CardDiffNotification[]);
  if (cardDiffNotis.length === 1 && !cardDiffNotis[0].$meta.isLoaded) {
    (["changes", "changers", "asOwner", "createdAt", "card"] as const).forEach((p) =>
      cardDiffNotis[0].$meta.get(p)
    );
  }
  const resolvableNotis =
    loggedInUser && accountId
      ? loggedInUser.$meta.find("resolvableNotifications", {
          accountId,
        })
      : (emptyList as ResolvableNotification[]);
  if (resolvableNotis.length === 1 && !resolvableNotis[0].$meta.isLoaded) {
    (
      [
        "isParticipating",
        "isSnoozing",
        "unseenEntryCount",
        "unseenAuthors",
        "isLastParticipant",
        "createdAt",
        "latestEntry",
      ] as const
    ).forEach((p) => resolvableNotis[0].$meta.get(p));
  }

  const dueCards =
    loggedInUser && accountId
      ? loggedInUser.$meta.find("dueCards", {
          accountId,
        })
      : (emptyList as DueCard[]);

  const value = useMemo(() => {
    const existingCardDiffNotis = cardDiffNotis.filter(
      (n) => !n.$meta.isDeleted() && n.card && !n.card.$meta.isDeleted()
    );
    const existingResolvableNotis = resolvableNotis.filter(
      (n) => !n.$meta.isDeleted() && n.resolvable && !n.resolvable.$meta.isDeleted()
    );
    return getNotificationInfo(dueCards, existingCardDiffNotis, existingResolvableNotis);
  }, [cardDiffNotis, resolvableNotis, dueCards]);
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotifications = () => useContext(NotificationContext);

import {delayedTrigger} from "@cdx/common";
import {create} from "zustand";
import {useCurrShiftHoverStore} from "../card-panel/useShiftHover";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";
import {useCardContainerStore} from "./useCardContainer";

const emptySet = new Set();
const emptyMap = new Map();

export const useSelectionStore = create((set) => ({
  selectedCardIds: emptySet,
  focusContainerKeys: emptySet,
  lastSelectedCardId: null,

  shiftHoverMap: emptyMap,

  addIds: (ids, cardContainerKey) =>
    set((prev) => {
      const {shiftHoverMap, selectedCardIds, focusContainerKeys} = prev;
      const shiftIds = [];
      for (const cardIdSet of shiftHoverMap.values()) {
        shiftIds.push(...[...cardIdSet]);
      }
      const nextSelectedCardIds = new Set([...selectedCardIds, ...ids, ...shiftIds]);
      if (nextSelectedCardIds.size > 1) {
        completeOnboardingStep(ONBOARDING_STEPS.cardSelection);
      }
      return {
        selectedCardIds: nextSelectedCardIds,
        lastSelectedCardId: ids[ids.length - 1],
        focusContainerKeys: cardContainerKey
          ? new Set([...focusContainerKeys, cardContainerKey])
          : emptySet,
      };
    }),
  removeIds: (ids, cardContainerKey, {focusContainerKeys} = {}) =>
    set((prev) => {
      const nextSet = new Set(prev.selectedCardIds);
      ids.forEach((id) => nextSet.delete(id));
      return {
        selectedCardIds: nextSet.size === 0 ? emptySet : nextSet,
        lastSelectedCardId: null,
        focusContainerKeys: focusContainerKeys
          ? focusContainerKeys
          : cardContainerKey
            ? new Set([...prev.focusContainerKeys, cardContainerKey])
            : emptySet,
      };
    }),
  setIds: (idSet, focusContainerKeys) =>
    set({
      selectedCardIds: idSet,
      lastSelectedCardId: null,
      focusContainerKeys: focusContainerKeys ? new Set([...focusContainerKeys]) : emptySet,
    }),
  setShiftHoverMap: (map) => set({shiftHoverMap: map}),
}));

/**
 * @returns {Set<import("../../cdx-models/Card").CardId>}
 */
export const getSelectedCardIds = () => useSelectionStore.getState().selectedCardIds;

export const useShiftHoverCards = ({cardContainerKey, cardId}) => {
  return useSelectionStore((s) =>
    ((cardContainerKey && s.shiftHoverMap.get(cardContainerKey)) || emptySet).has(cardId)
  );
};

const removeNonPresentTrigger = delayedTrigger();

useCardContainerStore.subscribe(({cardContainerBox: {map}}) => {
  if (useSelectionStore.getState().selectedCardIds.size > 0) {
    removeNonPresentTrigger.fire(() => {
      const {selectedCardIds, removeIds, focusContainerKeys} = useSelectionStore.getState();
      const toBeRemoved = new Set(selectedCardIds);
      for (const {visibleCardIds} of map.values()) {
        for (const cardId of visibleCardIds) toBeRemoved.delete(cardId);
      }
      if (toBeRemoved.size > 0) removeIds([...toBeRemoved], null, {focusContainerKeys});
    }, 50);
  }
});

useCurrShiftHoverStore.subscribe(
  (s) => s.hoverCardInfo,
  (info) => {
    const {selectedCardIds, lastSelectedCardId, focusContainerKeys, setShiftHoverMap} =
      useSelectionStore.getState();
    if (!info || !selectedCardIds.size) {
      setShiftHoverMap(emptyMap);
      return;
    }
    const {
      cardContainerBox: {map: cardContainerMap},
    } = useCardContainerStore.getState();
    const sortedContainers = [...cardContainerMap.entries()]
      .map(([cardContainerKey, {visibleCardIds, idx}]) => ({cardContainerKey, visibleCardIds, idx}))
      .sort((e1, e2) => e1.idx - e2.idx);

    const getPos = (posInfo) => {
      if (!posInfo) return null;
      const {cardContainerKey, cardId} = posInfo;
      const containerIdx = sortedContainers.findIndex(
        (c) => c.cardContainerKey === cardContainerKey
      );
      if (containerIdx === -1) return null;
      const container = sortedContainers[containerIdx];
      const cardIdx = container.visibleCardIds.findIndex((id) => id === cardId);
      if (!cardIdx === -1) return null;
      return {containerIdx, cardIdx};
    };

    const getAnchor = () => {
      if (lastSelectedCardId) {
        for (const key of focusContainerKeys) {
          const contInfo = cardContainerMap.get(key);
          if (!contInfo) continue;
          if (contInfo.visibleCardIds.some((id) => id === lastSelectedCardId)) {
            return {cardContainerKey: key, cardId: lastSelectedCardId};
          }
        }
        // if not found, or no focus container key, just find the first occurence of this card
        for (const {visibleCardIds, cardContainerKey} of sortedContainers) {
          if (visibleCardIds.some((id) => id === lastSelectedCardId)) {
            return {cardContainerKey, cardId: lastSelectedCardId};
          }
        }
      }

      // look for selected card in info.cardContainer
      const hoverContainer = cardContainerMap.get(info.cardContainerKey);
      const selectedCardId =
        hoverContainer && hoverContainer.visibleCardIds.find((id) => selectedCardIds.has(id));
      if (selectedCardId) {
        return {cardContainerKey: info.cardContainerKey, cardId: selectedCardId};
      }

      // else look for first selected card
      for (const {visibleCardIds, cardContainerKey} of sortedContainers) {
        const selCardId = visibleCardIds.find((id) => selectedCardIds.has(id));
        if (selCardId) {
          return {cardContainerKey, cardId: selCardId};
        }
      }
    };

    const targetPos = getPos({cardContainerKey: info.cardContainerKey, cardId: info.cardId});
    const anchorPos = targetPos && getPos(getAnchor());

    if (
      !targetPos ||
      !anchorPos ||
      (targetPos.containerIdx === anchorPos.containerIdx && targetPos.cardIdx === anchorPos.cardIdx)
    ) {
      setShiftHoverMap(emptyMap);
      return;
    }

    const [minPos, maxPos] =
      targetPos.containerIdx < anchorPos.containerIdx ||
      (targetPos.containerIdx === anchorPos.containerIdx && targetPos.cardIdx < anchorPos.cardIdx)
        ? [targetPos, anchorPos]
        : [anchorPos, targetPos];

    const nextMap = new Map();
    let currCardIdx = minPos.cardIdx;
    for (
      let containerIdx = minPos.containerIdx;
      containerIdx <= maxPos.containerIdx;
      containerIdx += 1
    ) {
      const {visibleCardIds, cardContainerKey} = sortedContainers[containerIdx];
      const containerCardIds = new Set();
      nextMap.set(cardContainerKey, containerCardIds);
      if (containerIdx === maxPos.containerIdx) {
        while (currCardIdx < maxPos.cardIdx) {
          containerCardIds.add(visibleCardIds[currCardIdx]);
          currCardIdx += 1;
        }
      } else {
        while (currCardIdx < visibleCardIds.length) {
          containerCardIds.add(visibleCardIds[currCardIdx]);
          currCardIdx += 1;
        }
        currCardIdx = 0;
      }
    }
    setShiftHoverMap(nextMap);
  }
);

const getContainerCards = (containerInfo) => {
  if (!containerInfo) return {focusContainerCards: []};
  const {allCardsInfo, visibleCardIds} = containerInfo;
  return allCardsInfo
    ? {focusContainerCards: allCardsInfo.cardIds, onShow: allCardsInfo.onShow}
    : {focusContainerCards: visibleCardIds};
};

export const selectAll = (defaultContainerKey) => {
  const {selectedCardIds, focusContainerKeys: rawKeys, setIds} = useSelectionStore.getState();
  const {
    cardContainerBox: {map: cardContainerMap},
  } = useCardContainerStore.getState();
  const focusContainerKeys = [];
  if (rawKeys.size > 0) {
    focusContainerKeys.push(...rawKeys);
  } else if (selectedCardIds.size === 0 && defaultContainerKey) {
    focusContainerKeys.push(defaultContainerKey);
  }
  let allFocusCardsSelected = true;
  const nextIds = new Set([...selectedCardIds]);
  for (const key of focusContainerKeys) {
    const {focusContainerCards, onShow} = getContainerCards(cardContainerMap.get(key));
    if (focusContainerCards.length) {
      for (const id of focusContainerCards) nextIds.add(id);
      if (selectedCardIds.size > 0 && focusContainerCards.every((id) => selectedCardIds.has(id)))
        continue;
      allFocusCardsSelected = false;
      if (onShow) onShow();
    }
  }
  if (allFocusCardsSelected) {
    for (const key of cardContainerMap.keys()) {
      const {focusContainerCards, onShow} = getContainerCards(cardContainerMap.get(key));
      for (const id of focusContainerCards) nextIds.add(id);
      if (onShow) onShow();
    }
  }
  setIds(nextIds);
};

export const invertSelection = () => {
  const {selectedCardIds, setIds, focusContainerKeys} = useSelectionStore.getState();
  const {
    cardContainerBox: {map: cardContainerMap},
  } = useCardContainerStore.getState();
  const nextIds = new Set();
  const infoList = [];
  for (const key of cardContainerMap.keys()) {
    const nonSelectedIds = new Set();
    const {focusContainerCards, onShow} = getContainerCards(cardContainerMap.get(key));
    for (const id of focusContainerCards) {
      if (!selectedCardIds.has(id)) nonSelectedIds.add(id);
    }
    infoList.push({
      nonSelectedIds,
      onShow,
      state:
        nonSelectedIds.size === 0
          ? "all"
          : nonSelectedIds.size === focusContainerCards.length
            ? "none"
            : "some",
    });
  }
  const hasSome = infoList.some(({state}) => state === "some");
  const processList = hasSome
    ? infoList.filter(({state}) => state === "some" || state === "all")
    : infoList;
  for (const {onShow, nonSelectedIds} of processList) {
    for (const id of nonSelectedIds) nextIds.add(id);
    if (onShow) onShow();
  }
  setIds(nextIds, focusContainerKeys);
};

export const cardSelectionActions = {
  setSelectedCardIds: (cardIdSet) => useSelectionStore.getState().setIds(cardIdSet),
  addSelectedCardIds: (cardIds, cardContainerKey) =>
    useSelectionStore.getState().addIds(cardIds, cardContainerKey),
  removeSelectedCardIds: (cardIds, cardContainerKey) =>
    useSelectionStore.getState().removeIds(cardIds, cardContainerKey),
  clearSelectedCardIds: () => useSelectionStore.getState().setIds(emptySet),
};

export const useSelectedCardIds = () => useSelectionStore((data) => data.selectedCardIds);
export const useIsSelectingCards = () => useSelectionStore((data) => data.selectedCardIds.size > 0);

import {countWorkdaysForAccount, dayToDate} from "../../lib/date-utils";
import {getLimits} from "../billing/billing-utils";

export const getDueDateInfo = ({card, account}: {card: any; account: any}) => {
  if (!card.dueDate) return null;
  if (card.status === "done") return null;
  if (card.visibility !== "default") return null;
  return {
    dueDate: card.dueDate,
    workDays: countWorkdaysForAccount({
      account,
      targetDay: dayToDate(card.dueDate),
    } as any),
    dueDatesEnabled: getLimits(account).withDueDates === "true",
  } as DueDateInfo;
};

export type DueDateInfo = {
  dueDate: {year: number; month: number; day: number};
  workDays: number;
  dueDatesEnabled: boolean;
};

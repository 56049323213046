import {ReactNode, useEffect, useRef} from "react";
import {createPortal} from "react-dom";

const createNewNode = () => {
  const node = document.createElement("div");
  document.body.appendChild(node);
  return node;
};

export const SimplePortal = ({children}: {children: ReactNode}) => {
  const nodeRef = useRef(null as any as HTMLDivElement);
  // FIXME: setting `.current` here is unsafe as if the component might get
  // rerendered due to Suspense and the previous node won't be removed
  if (!nodeRef.current) nodeRef.current = createNewNode();
  useEffect(() => {
    return () => {
      if (!nodeRef.current) return;
      document.body.removeChild(nodeRef.current);
      nodeRef.current = null as any;
    };
  }, []);
  return createPortal(children, nodeRef.current);
};

import {forwardRef} from "react";
import {adler32} from "../lib/utils";
import {cx, XCol, XRow} from "@cdx/common";
import {avatarStyles as styles} from "./avatar.css";
import {CdxCropImgByFile} from "./CdxImg";
import uiClasses from "@cdx/common/xui/ui.css";
import {DSIconRobot} from "@cdx/ds";

const idToCol = {};

const getIdCol = (id) => {
  const exist = idToCol[id];
  if (!exist) {
    const col = `hsl(${adler32(id) % 360}, 96%, 46%)`;
    return (idToCol[id] = col);
  } else {
    return exist;
  }
};

const avatarSizes = [{width: 24, height: 24}];

/** @type React.ForwardRefExoticComponent<{user: any, size?: number, className?: string} & React.RefAttributes<HTMLElement>> */
const Avatar = forwardRef(({user, size = 24, gray, className, border, style, ...rest}, ref) => {
  if (!user) return null;
  const outerSize = size + (border ? 4 : 0);

  return (
    <CdxCropImgByFile
      width={size}
      height={size}
      file={user.profileImage}
      ref={ref}
      style={{width: outerSize, height: outerSize, ...style}}
      className={cx(
        styles.outer,
        className,
        rest.onClick && styles.container.interactive,
        border && uiClasses.borderColor[border],
        border && uiClasses.fullBorder,
        border && uiClasses.borderWidth.md
      )}
      imgClassName={cx(styles.container.base, gray && styles.container.gray)}
      title={user.name}
      alt={user.name}
      pickSize={avatarSizes}
      {...rest}
      fallback={() => (
        <div
          className={cx(styles.container.base, gray && styles.container.gray)}
          title={user.name}
          style={{
            width: size,
            height: size,
            backgroundColor: getIdCol(user.id || ""),
          }}
          {...rest}
        >
          {/*
      having text within a div makes it behave differently from non-child element when
      using e.g. inline-flex, thus we use absoultely positioned child here
      */}
          <XCol
            absolute
            inset="full"
            align="center"
            justify="center"
            style={{fontSize: size / 2.5}}
            className={styles.fallbackContent}
          >
            {(user.$meta.get("name", "?") || "?").slice(0, 2)}
          </XCol>
        </div>
      )}
    />
  );
});

export default Avatar;

export const Robot = ({size = 24, gray, style, className, border, ...rest}) => (
  <XCol
    align="center"
    justify="center"
    className={cx(
      className,
      styles.outer,
      styles.robotContainer,
      styles.container.base,
      gray && styles.container.gray,
      rest.onClick && styles.container.interactive,
      border && uiClasses.borderColor[border],
      border && uiClasses.fullBorder,
      border && uiClasses.borderWidth.md
    )}
    title="Codecks Bot"
    style={{width: size + (border ? 4 : 0), height: size + (border ? 4 : 0), ...style}}
    {...rest}
  >
    <DSIconRobot size={size >= 18 ? 24 : 16} style={{fontSize: Math.round(size * 0.9)}} />
  </XCol>
);

export const AvatarWithName = ({className, size, user, gap = 10}) => (
  <XRow align="center" className={className}>
    <Avatar size={size} user={user} style={{marginRight: gap}} />
    <div>{user.name}</div>
  </XRow>
);

import {useState, useLayoutEffect} from "react";
import ResizeObserver from "resize-observer-polyfill";

const excludePadding = (rect, node) => {
  const inputStyle = window.getComputedStyle(node);
  const left = rect.left + parseInt(inputStyle.getPropertyValue("padding-left"), 10);
  const top = rect.top + parseInt(inputStyle.getPropertyValue("padding-top"), 10);
  const right = rect.right - parseInt(inputStyle.getPropertyValue("padding-right"), 10);
  const bottom = rect.bottom - parseInt(inputStyle.getPropertyValue("padding-bottom"), 10);
  const width = right - left;
  const height = bottom - top;
  return {top, bottom, left, right, height, width};
};

export const useMeasureWithNode = (node, opts = {}) => {
  const [bounds, setBounds] = useState(null);

  useLayoutEffect(() => {
    if (!node) return;
    const updateBounds = () => {
      const rect = node.getBoundingClientRect();
      const {top, bottom, left, right, height, width} = opts.excludePadding
        ? excludePadding(rect, node)
        : rect;
      setBounds((prev) => {
        if (
          !prev ||
          prev.top !== top ||
          prev.bottom !== bottom ||
          prev.left !== left ||
          prev.right !== right
        ) {
          return {top, bottom, left, right, height, width};
        } else {
          return prev;
        }
      });
    };
    const ro = new ResizeObserver(updateBounds);
    ro.observe(node);
    updateBounds();
    return () => ro.disconnect();
  }, [node, opts.excludePadding]);

  return bounds;
};

export const useMeasure = (opts) => {
  const [node, setNode] = useState();
  return [useMeasureWithNode(node, opts), setNode];
};

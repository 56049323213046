import {
  cx,
  useGetNodeFromRef,
  SpawnAnchoredOverlayWithNode,
  DefaultOverlay,
  useGlobalKeyPress,
} from "@cdx/common";
import {ReactElement, ReactNode, Suspense, cloneElement, forwardRef, lazy, useState} from "react";
import {useInstance} from "../../../lib/mate/mate-utils";
import useMutation from "../../../lib/hooks/useMutation";
import {moveCardsToDeck} from "../../../lib/cross-project-cards-or-decks";
import {create} from "zustand";
import {Col, DSSpinner} from "@cdx/ds";
import {Card, CardId} from "../../../cdx-models/Card";
import {CardPropChangeKey} from "./CardPropChangeOverlay";

const LazyOverlay = lazy(() => import("./CardPropChangeOverlay"));

export type CardPropOverlayForExistingCardProps = {
  cardId: CardId | null;
  overlayProps: any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
};

export const CardPropOverlayForExistingCard = ({
  cardId,
  overlayProps,
  onClose,
  initialTab,
}: CardPropOverlayForExistingCardProps) => {
  const [doUpdate] = useMutation("cards", "update");

  const handleUpdate = ({deckId, ...rest}: any) => {
    const proms = [];
    if (deckId) {
      proms.push(moveCardsToDeck([cardId], deckId));
    }
    if (Object.keys(rest).length > 0) {
      proms.push(doUpdate({id: cardId, ...rest}));
    }
    return Promise.all(proms);
  };

  const card = useInstance("card", cardId);

  return (
    <DefaultOverlay
      {...overlayProps}
      onClick={(e: any) => e.stopPropagation()}
      onMouseMove={(e: any) => e.stopPropagation()}
    >
      {card ? (
        <Suspense
          fallback={
            <Col style={{height: 400, width: 250}} align="center" justify="center">
              <DSSpinner size={24} />
            </Col>
          }
        >
          <LazyOverlay
            card={card}
            onChange={handleUpdate}
            onClose={onClose}
            initialTab={initialTab}
          />
        </Suspense>
      ) : (
        <div>No card found</div>
      )}
    </DefaultOverlay>
  );
};

const useStore = create<{open: null | {cardKey: string; tab: CardPropChangeKey | null}}>((set) => ({
  open: null,
}));

type CardPropChangeOverlayForChildProps = {
  children: ReactNode;
  className?: string;
  placement?: string;
  cardId: CardId | null;
  cardContainerKey: string;
  availableShortcuts?: CardPropChangeKey[];
  initialTab?: CardPropChangeKey | null;
  getChildProps?: (open: boolean) => any;
  onClick?: (e: any) => void;
  disabled?: boolean;
};
export const CardPropChangeOverlayForChild = forwardRef<
  HTMLElement,
  CardPropChangeOverlayForChildProps
>((props, passedRef) => {
  const {
    children,
    placement = "bottom",
    cardId,
    cardContainerKey,
    availableShortcuts,
    initialTab,
    getChildProps,
    onClick,
    disabled,
  } = props;
  const {node, ref} = useGetNodeFromRef(passedRef);
  const {open} = useStore();

  if (process.env.REACT_APP_MODE === "open" || disabled) return children as ReactElement;
  if (!cardId) return children as ReactElement;
  const key = `${cardContainerKey}:${cardId}`;

  const events = {
    onClick: (e: MouseEvent) => {
      onClick?.(e);
      if (e.defaultPrevented) return;
      e.stopPropagation();
      e.preventDefault();
      const getInitialTab = () => {
        if (initialTab) return initialTab;
        if (availableShortcuts?.length) {
          return availableShortcuts[0];
        }
        return "deck";
      };
      useStore.setState({
        open: open && open.cardKey === key ? null : {cardKey: key, tab: getInitialTab()},
      });
    },
  };

  return (
    <SpawnAnchoredOverlayWithNode
      node={node}
      isOpen={open?.cardKey === key}
      distanceFromAnchor={5}
      renderOverlay={(op: any) => (
        <CardPropOverlayForExistingCard
          cardId={cardId}
          onClose={() => useStore.setState({open: null})}
          overlayProps={op}
          initialTab={open?.tab}
        />
      )}
      placement={placement}
      preventPlacement="top"
    >
      {(availableShortcuts || []).map((changeKey) => (
        <OnShortCut
          key={changeKey}
          changeKey={changeKey}
          onPress={() => useStore.setState({open: {cardKey: key, tab: changeKey}})}
        />
      ))}
      {cloneElement(children as any, {
        ...events,
        ...getChildProps?.(open?.cardKey === key),
        ref,
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

type CardPropOverlayForNewCardProps = {
  card: Card;
  onChange: (arg: any) => void;
  overlayProps: any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
};

export const CardPropOverlayForNewCard = ({
  card,
  onChange,
  overlayProps,
  onClose,
  initialTab,
}: CardPropOverlayForNewCardProps) => {
  return (
    <DefaultOverlay
      {...overlayProps}
      onClick={(e: any) => e.stopPropagation()}
      onMouseMove={(e: any) => e.stopPropagation()}
    >
      <Suspense
        fallback={
          <Col style={{height: 400}} align="center" justify="center">
            <DSSpinner size={24} />
          </Col>
        }
      >
        <LazyOverlay
          card={card}
          onChange={onChange}
          onClose={onClose}
          initialTab={initialTab}
          isNewCard
        />
      </Suspense>
    </DefaultOverlay>
  );
};

// {openShortcut && <OnShortCut key={openShortcut} onUpdate={() => setOpen(!open)} />}

const shotCutMapping: {[key in CardPropChangeKey]: string} = {
  deck: "d",
  owner: "o",
  milestone: "m",
  sprint: ",",
  priority: "p",
  effort: "e",
};

type OnShortCutProps = {changeKey: CardPropChangeKey; onPress: () => void};
export const OnShortCut = ({changeKey, onPress}: OnShortCutProps) => {
  useGlobalKeyPress({
    key: shotCutMapping[changeKey],
    fn: () => onPress(),
  });
  return null;
};

type NewCardPropChangeOverlayForChildProps = {
  children: ReactNode;
  className?: string;
  placement?: string;
  card: Card;
  onChange: (arg: any) => void;
  availableShortcuts?: CardPropChangeKey[];
  initialTab?: CardPropChangeKey | null;
  getChildProps?: (open: boolean) => any;
  onClick?: (e: any) => void;
};
export const NewCardPropChangeOverlayForChild = forwardRef<
  HTMLElement,
  NewCardPropChangeOverlayForChildProps
>((props, passedRef) => {
  const {
    children,
    className,
    placement = "bottom",
    card,
    onChange,
    availableShortcuts,
    initialTab,
    getChildProps,
    onClick,
  } = props;
  const [open, setOpen] = useState<CardPropChangeKey | null>(null);
  const events = {
    onClick: (e: MouseEvent) => {
      onClick?.(e);
      if (e.defaultPrevented) return;
      e.stopPropagation();
      e.preventDefault();
      const getInitialTab = () => {
        if (initialTab) return initialTab;
        if (availableShortcuts?.length) {
          return availableShortcuts[0];
        }
        return "deck";
      };
      setOpen(open ? null : getInitialTab());
    },
  };
  const {node, ref} = useGetNodeFromRef(passedRef);

  return (
    <SpawnAnchoredOverlayWithNode
      node={node}
      isOpen={Boolean(open)}
      distanceFromAnchor={5}
      renderOverlay={(op: any) => (
        <CardPropOverlayForNewCard
          card={card}
          onChange={onChange}
          onClose={() => setOpen(null)}
          overlayProps={op}
          initialTab={open}
        />
      )}
      placement={placement}
      preventPlacement="top"
    >
      {(availableShortcuts || []).map((key) => (
        <OnShortCut key={key} changeKey={key} onPress={() => setOpen(key)} />
      ))}
      {cloneElement(children as any, {
        ...events,
        ref,
        className: cx(className, (children as any).props.className),
        ...getChildProps?.(Boolean(open)),
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

import {useState, useEffect} from "react";
import MiniEvent from "../mini-event";

let _events;
let _currVal;

const getDims = () => {
  if (!_events) {
    _events = new MiniEvent();
    _currVal = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
    window.addEventListener("resize", () => {
      _currVal = {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
      _events.emit(_currVal);
    });
  }
  return _currVal;
};
const onUpdate = (fn) => _events.addListener(fn);

const useWindowSize = () => {
  const [dims, setDims] = useState(getDims);
  useEffect(() => {
    return onUpdate(setDims);
  }, []);
  return dims;
};

export default useWindowSize;

import {useInstance} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import MiniProjectTile from "../../../components/MiniProjectTile";
import {Project, ProjectId} from "../../../cdx-models/Project";
import {ProjectSpaceId} from "../../../cdx-models/utils/extended-fields";
import {Row} from "@cdx/ds";

const SpaceOpt = ({projectId, spaceId}: {projectId: ProjectId; spaceId: ProjectSpaceId}) => {
  const project: Project = useInstance("project", projectId);
  const space = project.spaces.find((s) => s.id === spaceId);
  return (
    <Row sp="8px" align="center">
      <MiniProjectTile project={project} size={16} />
      <div>
        <b>{space?.name || "Default Space"}</b> in <b>{project.name}</b>
      </div>
    </Row>
  );
};

type SpaceKey = {projectId: ProjectId; spaceId: ProjectSpaceId};

const spaceCategory = {
  key: "space",
  label: "Space",
  getSuggestions({input, projects}: {input: string; projects: Project[]}) {
    if (input.length < 2) return [];
    if ("space".startsWith(input)) {
      return projects.flatMap((p) => p.spaces.map((s) => ({projectId: p.id, spaceId: s.id})));
    } else {
      return projects.flatMap((p) =>
        p.spaces
          .filter((s) => (s.name?.toLowerCase() || "default space").startsWith(input))
          .map((s) => ({projectId: p.id, spaceId: s.id}))
      );
    }
  },
  valueToKey({projectId, spaceId}: SpaceKey) {
    return `${projectId}:${spaceId}`;
  },
  valuesToDbQuery(values: SpaceKey[]) {
    if (values.length === 1) {
      const {projectId, spaceId} = values[0];
      return {deck: {projectId, spaceId}};
    } else {
      return {$or: values.map(({projectId, spaceId}) => ({deck: {projectId, spaceId}}))};
    }
  },
  renderSuggestion({projectId, spaceId}: SpaceKey) {
    return <SpaceOpt projectId={projectId} spaceId={spaceId} />;
  },
  savedSearchLabel({projectId, spaceId}: SpaceKey) {
    const project: Project = api.getModel({modelName: "project", id: projectId});
    const space = project.spaces.find((s) => s.id === spaceId);
    return {
      prio: -3,
      label: `in '${space?.name || "Default Space"}'`,
    };
  },
};

export default spaceCategory;

import {useState, useEffect} from "react";
import {MiniEvent, errorToString, shrinker} from "@cdx/common";
import {cdxRequest} from "../../lib/request";
import messenger from "../../lib/messenger";
import {filesize} from "filesize";

const getSigningUrl = ({file, affectsQuota}) =>
  cdxRequest({
    path: "/s3/sign",
    method: "GET",
    query: {objectName: file.name, affectsQuota},
  });

const uploadToS3 = (file, url, fields, updateProgress) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("Content-Type", file.type);
    Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
    formData.append("file", file);
    const request = new XMLHttpRequest();
    request.open("POST", url);
    if (request.upload) {
      request.upload.onprogress = function (e) {
        updateProgress(Math.ceil((e.loaded / e.total) * 100));
      };
    }
    request.onload = function () {
      updateProgress(100);
      if (request.status < 400) {
        resolve(request.responseXML);
      } else {
        if (request.responseXML) {
          const code = request.responseXML.documentElement.getElementsByTagName("Code")[0];
          if (code.textContent === "EntityTooLarge") {
            let error = "File too large!";
            const maxSize =
              request.responseXML.documentElement.getElementsByTagName("MaxSizeAllowed")[0];
            const maxSizeNum = maxSize && parseInt(maxSize.textContent, 10);
            if (maxSizeNum) {
              error += ` Max allows size: ${filesize(maxSizeNum, {base: 2, standard: "jedec"})}`;
            }
            return reject(error);
          }
        }
        return reject("Upload failed");
      }
    };
    request.onerror = function (e) {
      console.error(e);
      reject(errorToString(e));
    };
    request.send(formData);
  });

const listenersById = {};
const dataById = {};

export const useUploadData = (id) => {
  const [, setData] = useState(0);
  useEffect(() => {
    let listener = (listenersById[id] = listenersById[id] || new MiniEvent());
    return listener.addListener(() => setData((c) => c + 1));
  }, [id]);
  return dataById[id] || [];
};

export const uploadFile = ({id, file, onUpload, noAccount, affectsQuota}) => {
  const listener = (listenersById[id] = listenersById[id] || new MiniEvent());
  const fileData = {progressInPercent: 0, fileName: file.name};
  const uploadsList = (dataById[id] = dataById[id] || []);
  uploadsList.push(fileData);

  const updateProgress = (progressInPercent) => {
    fileData.progressInPercent = progressInPercent;
    listener.emit();
  };

  const onDone = () => {
    uploadsList.splice(uploadsList.indexOf(fileData), 1);
    if (uploadsList.length === 0) delete dataById[id];
    listener.emit();
  };

  return getSigningUrl({file, affectsQuota})
    .then(({signedUrl, publicUrl, fields, sizeLimit}) => {
      if (file.size && file.size > sizeLimit) {
        return Promise.reject(
          new Error(
            `File size ${filesize(file.size, {
              base: 2,
              standard: "jedec",
            })} exceeds limit (${filesize(Number(sizeLimit), {
              base: 2,
              standard: "jedec",
            })})`
          )
        );
      } else {
        return uploadToS3(file, signedUrl, fields, updateProgress)
          .then(() =>
            onUpload({
              fileName: file.name,
              url: publicUrl,
              size: file.size,
              type: file.type,
              noAccount,
            })
          )
          .then(
            (uploadRes) => {
              messenger.send(`${shrinker(file.name, 40)} was uploaded`);
              return uploadRes;
            },
            (e) => {
              console.error(e);
              messenger.send(`Upload failed: ${errorToString(e)}`, {type: "error"});
            }
          )
          .then((uploadRes) => ({url: publicUrl, fileName: file.name, uploadRes}));
      }
    })
    .then(
      (res) => {
        onDone();
        return res;
      },
      (err) => {
        onDone();
        return Promise.reject(err);
      }
    );
};

import {useEffect, useRef} from "react";
import useMutation from "./useMutation";

const getCurrTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return null;
  }
};

const useSyncTimezone = (root) => {
  const {loggedInUser: me} = root;
  const loadedTimeZone = me ? me.$meta.get("timezone", false) : false;
  const meId = me && me.$meta.get("id", null);
  const lastLoadedTimeZoneInfoRef = useRef();
  useEffect(() => {
    if (loadedTimeZone !== false && meId && !lastLoadedTimeZoneInfoRef.current) {
      lastLoadedTimeZoneInfoRef.current = {tz: loadedTimeZone, meId};
    }
  }, [loadedTimeZone, meId]);
  const isLoaded = loadedTimeZone !== false;
  const [doUpdate] = useMutation("users", "update");

  useEffect(() => {
    if (isLoaded) {
      const currTimeZone = getCurrTimeZone();
      if (!currTimeZone || !lastLoadedTimeZoneInfoRef.current) return;
      if (currTimeZone !== lastLoadedTimeZoneInfoRef.current.tz) {
        doUpdate({
          id: lastLoadedTimeZoneInfoRef.current.meId,
          timezone: currTimeZone,
        });
      }
    }
  }, [doUpdate, isLoaded]);
};

export default useSyncTimezone;

import {$isElementNode, RangeSelection, LexicalNode, $isLineBreakNode} from "lexical";
import {PointType} from "lexical/LexicalSelection";

import {
  LineAstNodeType,
  RootLineAstNode,
  NonRootLineAstNode,
  IndentLineAstNode,
  CheckListContent,
} from "./parseMarkdownLineAst";

const recursiveSome = (
  node: NonRootLineAstNode,
  fn: (n: NonRootLineAstNode) => boolean
): boolean => {
  if (fn(node)) return true;
  if (node.type === LineAstNodeType.Text) return false;
  return recursiveSome(node.content, fn);
};

export const needToLookAtPreviousLine = (lineAst: RootLineAstNode) => {
  return recursiveSome(lineAst.content, (n) => n.type === LineAstNodeType.Indentation);
};
const addCheckbox = (
  {checkList}: {checkList: null | CheckListContent},
  copyContent: boolean = false
) => {
  return checkList
    ? `[${copyContent ? checkList.content : " ".repeat(checkList.content.length)}]${
        checkList.trailingSpace ? " " : ""
      }`
    : "";
};

const nodeToPrefix = (node: NonRootLineAstNode | RootLineAstNode): [string, string] | null => {
  switch (node.type) {
    case LineAstNodeType.Indentation: {
      const str = " ".repeat(node.depth);
      return [str, str];
    }
    case LineAstNodeType.OrderedList: {
      const str = `${node.number + 1}${node.orderSuffixChar} ${addCheckbox(node)}`;
      return [" ".repeat(str.length), str];
    }
    case LineAstNodeType.UnorderedList: {
      const str = `${node.char} ${addCheckbox(node)}`;
      return [" ".repeat(str.length), str];
    }
    case LineAstNodeType.Quote: {
      return [`> `, `> `];
    }
    default:
      return null;
  }
};

// const nodeToStr = (node: RootLineAstNode | NonRootLineAstNode): string => {
//   switch (node.type) {
//     case LineAstNodeType.Root:
//       return nodeToStr(node.content);
//     case LineAstNodeType.Indentation:
//       return `${" ".repeat(node.depth)}${nodeToStr(node.content)}`;
//     case LineAstNodeType.Text:
//       return node.content;
//     case LineAstNodeType.Quote:
//       return `> ${nodeToStr(node.content)}`;
//     case LineAstNodeType.UnorderedList:
//       return `${node.char} ${addCheckbox(node)}${nodeToStr(node.content)}`;
//     case LineAstNodeType.OrderedList:
//       return `${node.number}${node.orderSuffixChar} ${addCheckbox(node)}${nodeToStr(node.content)}`;
//   }
// };

const findContentForIndent = (
  baseIndentNode: IndentLineAstNode,
  baseRoot: RootLineAstNode,
  lines: RootLineAstNode[]
): {remainingDepth: number; prefixes: [string, string][]} => {
  let remainingDepth = baseIndentNode.depth;
  const prefixes: [string, string][] = [];
  if (lines.length === 0) return {remainingDepth, prefixes};
  let currNode = lines[0] as RootLineAstNode | NonRootLineAstNode;
  // console.log("line", nodeToStr(currNode));
  let currBaseNode = baseRoot as RootLineAstNode | NonRootLineAstNode;
  while (true) {
    if (currBaseNode === baseIndentNode) {
      if (currNode.type === LineAstNodeType.Indentation) {
        if (currNode.depth >= baseIndentNode.depth) {
          /* example 1
          > - first   <- prev
          >   second  <- curr
          >   base    <- base

          indent is at least as big as base one -> ask previous lines

          */

          // console.log("> full ask parent");
          return findContentForIndent(baseIndentNode, baseRoot, lines.slice(1));
        } else {
          // console.log("> partial ask parent", baseIndentNode.depth, "vs only", currNode.depth);
          // currNode indent doesn't cover full range, ask prev lines, and fill gap with more content
          const parentRes = findContentForIndent(currNode, lines[0], lines.slice(1));
          /* example 1
          > - first     <- prev
          >   * second  <- curr
          >     base    <- base

          base indent: 4
          curr indent: 2

          we ask "prev" and get back: ["  ", "-  "], remainingDepth: 0
          i.e. the remaining depth fully explains the indent, so we can use the remaining content of "curr".
          */

          const removedDepth = currNode.depth - parentRes.remainingDepth;
          const lastPrefix = parentRes.prefixes[parentRes.prefixes.length - 1];
          if (lastPrefix && parentRes.remainingDepth === 0) {
            if (lastPrefix[0].length > currNode.depth) {
              lastPrefix[0] = " ".repeat(currNode.depth);
            }
          }
          prefixes.push(...parentRes.prefixes);
          remainingDepth -= removedDepth;

          // console.log("> parent said:", parentRes);
          currNode = currNode.content as NonRootLineAstNode;
          continue;
        }
      } else {
        // console.log("> go deep");
        /* example
        > * first  <- curr
        >   base   <- base

        go through the curr until the indent depth is reached
        */
        while (true) {
          const prefix = nodeToPrefix(currNode);
          if (!prefix) return {remainingDepth, prefixes};
          // console.log("> add prefix", prefix);
          prefixes.push(prefix);
          if (remainingDepth - prefix[0].length < 0) {
            prefix[0] = " ".repeat(remainingDepth);
          }
          remainingDepth = Math.max(0, remainingDepth - prefix[0].length);
          if (remainingDepth === 0) return {remainingDepth, prefixes};
          currNode = currNode.content as NonRootLineAstNode;
        }
      }
    } else {
      if (currNode.type !== currBaseNode.type) break;
      if (currNode.type === LineAstNodeType.Text) break;
      currNode = currNode.content;
      currBaseNode = currBaseNode.content as NonRootLineAstNode;
    }
  }

  return {remainingDepth, prefixes};
};

const getPrefixes = (lines: RootLineAstNode[]): [string, string][] => {
  let currNode = lines[0] as RootLineAstNode | NonRootLineAstNode;
  const prefixes: [string, string][] = [];
  while (true) {
    switch (currNode.type) {
      case LineAstNodeType.Text: {
        return prefixes;
      }
      case LineAstNodeType.Indentation: {
        if (lines.length > 1) {
          const res = findContentForIndent(currNode, lines[0], lines.slice(1));
          const lastPrefix = res.prefixes[res.prefixes.length - 1];
          if (res.remainingDepth) {
            if (lastPrefix) {
              lastPrefix[0] += " ".repeat(res.remainingDepth);
            } else {
              const str = " ".repeat(res.remainingDepth);
              prefixes.push([str, str]);
            }
          } else {
            if (lastPrefix && lastPrefix[0].length > currNode.depth) {
              lastPrefix[0] = " ".repeat(currNode.depth);
            }
          }
          prefixes.push(...res.prefixes);
        } else {
          const prefix = nodeToPrefix(currNode);
          if (prefix) prefixes.push(prefix);
        }
        break;
      }
      default: {
        const prefix = nodeToPrefix(currNode);
        if (prefix) prefixes.push(prefix);
      }
    }
    currNode = currNode.content as NonRootLineAstNode;
  }
};

export const getSmartEnterCompletion = (lines: RootLineAstNode[]): [string, string][] | null => {
  const list = getPrefixes(lines);
  return list.length ? list : null;
};

export const indentLine = (line: RootLineAstNode) => {
  const strList = [];
  let i = 0;
  let relevantIdx = 0;
  let currNode = line.content;
  outer: while (true) {
    switch (currNode.type) {
      case LineAstNodeType.Indentation: {
        relevantIdx = i;
        strList.push(" ".repeat(currNode.depth));
        break;
      }
      case LineAstNodeType.Text: {
        strList.push(currNode.content);
        break outer;
      }
      case LineAstNodeType.OrderedList: {
        relevantIdx = i;
        const s = `${currNode.number}${currNode.orderSuffixChar} ${addCheckbox(currNode, true)}`;
        strList.push(s);
        break;
      }
      case LineAstNodeType.UnorderedList: {
        relevantIdx = i;
        const s = `${currNode.char} ${addCheckbox(currNode, true)}`;
        strList.push(s);
        break;
      }
      case LineAstNodeType.Quote: {
        strList.push("> ");
        break;
      }
      default: {
        break;
      }
    }
    currNode = currNode.content;
    i += 1;
  }
  strList[relevantIdx] = "  " + strList[relevantIdx];
  return strList.join("");
};

export const outdentLine = (line: RootLineAstNode) => {
  const strList = [];
  let isDone = false;
  let currNode = line.content;
  outer: while (true) {
    switch (currNode.type) {
      case LineAstNodeType.Indentation: {
        if (isDone) {
          strList.push(" ".repeat(currNode.depth));
        } else {
          isDone = true;
          if (currNode.depth > 2) {
            strList.push(" ".repeat(currNode.depth - 2));
          }
        }
        break;
      }
      case LineAstNodeType.Text: {
        strList.push(currNode.content);
        break outer;
      }
      case LineAstNodeType.OrderedList: {
        const s = `${currNode.number}${currNode.orderSuffixChar} ${addCheckbox(currNode, true)}`;
        strList.push(s);
        break;
      }
      case LineAstNodeType.UnorderedList: {
        const s = `${currNode.char} ${addCheckbox(currNode, true)}`;
        strList.push(s);
        break;
      }
      case LineAstNodeType.Quote: {
        strList.push("> ");
        break;
      }
      default: {
        break;
      }
    }
    currNode = currNode.content;
  }
  return strList.join("");
};

const selectionPointInfo = (sel: PointType) => {
  const node = sel.getNode();
  if ($isElementNode(node)) {
    const idx = Math.min(node.getChildrenSize() - 1, sel.offset);
    if (idx < 0) return null;
    const child = node.getChildAtIndex(idx)!;
    return [child, 0] as const;
  } else {
    return [node, sel.offset] as const;
  }
};

export type SelectionInfo = {
  text: string;
  startOffset: number;
  endOffset: number;
  lineOffset: number;
  lines: LexicalNode[][];
};

export const getSelectionInfo = (selection: RangeSelection): SelectionInfo | null => {
  const anchorInfo = selectionPointInfo(selection.anchor);
  const focusInfo = selectionPointInfo(selection.focus);
  if (!anchorInfo || !focusInfo) return null;
  const [start, end] = selection.isBackward() ? [focusInfo, anchorInfo] : [anchorInfo, focusInfo];
  const [startNode, startOffset] = start;
  const [endNode, endOffset] = end;
  const parent = startNode.getParentOrThrow();
  if (parent !== endNode.getParent()) {
    throw new Error(`start node and end node don't share common parent`);
  }
  const allLines: LexicalNode[][] = [];
  let currLine: LexicalNode[] = [];
  let startLineIdx = 0;
  let endLineIdx = -1;
  allLines.push(currLine);
  for (const child of parent.getChildren()) {
    if (child === startNode) startLineIdx = allLines.length - 1;
    if (child === endNode) endLineIdx = allLines.length - 1;
    currLine.push(child);
    if ($isLineBreakNode(child)) {
      if (endLineIdx !== -1) break;
      currLine = [];
      allLines.push(currLine);
    }
  }
  const texts: string[] = [];
  let offset = 0;
  let finalStartOffset = 0;
  let finalEndOffset = 0;
  const lines = allLines.slice(startLineIdx, endLineIdx + 1);
  for (const nodes of lines) {
    for (const node of nodes) {
      if (node === startNode) {
        finalStartOffset = offset + startOffset;
      }
      if (node === endNode) {
        finalEndOffset = offset + endOffset;
      }
      const text = node.getTextContent();
      offset += text.length;
      texts.push(text);
    }
  }
  const lineOffset = allLines
    .slice(0, startLineIdx)
    .reduce((s, l) => s + l.reduce((ls, n) => ls + n.getTextContentSize(), 0), 0);

  return {
    text: texts.join(""),
    startOffset: finalStartOffset,
    endOffset: finalEndOffset,
    lines,
    lineOffset,
  };
};

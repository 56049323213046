type FieldValues = Record<string, any>;

export type AsyncCheckFn<T> = (value: T) => Promise<ValidationResult>;

type AsyncSuccessValidationValue = {state: "resolved"} & ValidationResult;

export type AsyncValidationValue =
  | {state: "pending"; promise: Promise<AsyncSuccessValidationValue | "canceled">}
  | {state: "error"; error: any}
  | AsyncSuccessValidationValue;

export type ValidationResult =
  | {isValid: true}
  | {isValid: false; code: string; message?: string; ctx?: any};

export type AsyncCheck<T> = {
  debounceMs?: number;
  memoize?: boolean;
  valueToCacheKey?: (value: T) => string;
  fn: AsyncCheckFn<T>;
};

type Validator<T> = {
  scheduleCheck: (value: T) => void;
  getPendingValidation: () => null | Promise<AsyncSuccessValidationValue | "canceled">;
};

const defaultGetCacheKey = (vals: any) => JSON.stringify(vals);

const createValidator = <T>(opts: {
  debounceMs: number;
  memoize: boolean;
  fn: AsyncCheckFn<T>;
  onValidate: (res: AsyncValidationValue) => void;
  valueToCacheKey: (val: T) => string;
}): Validator<T> => {
  const {debounceMs, fn, memoize, onValidate, valueToCacheKey} = opts;
  let onCancelTimeout = null as null | (() => void);
  // let checkValues: any = {};
  let currPromise = null as null | Promise<unknown>;
  const cache = memoize ? new Map<string, AsyncValidationValue>() : null;
  let pendingValidation = null as null | Promise<AsyncSuccessValidationValue | "canceled">;

  return {
    scheduleCheck: (value) => {
      if (onCancelTimeout) onCancelTimeout();
      const cacheKey = valueToCacheKey(value);

      if (cache) {
        const exists = cache.get(cacheKey);
        if (exists) {
          onValidate(exists);
          return;
        }
      }

      pendingValidation = new Promise<AsyncSuccessValidationValue | "canceled">(
        (outerResolve, rej) => {
          let timeoutId = setTimeout(() => {
            const myPromise = fn(value).then(
              (res) => {
                const asyncRes = {state: "resolved", ...res} as const;
                outerResolve(asyncRes);
                cache?.set(cacheKey, asyncRes);
                if (myPromise !== currPromise) return;
                onValidate(asyncRes);
              },
              (error) => {
                cache?.delete(cacheKey);
                rej(error);
                if (myPromise !== currPromise) return;
                onValidate({state: "error", error});
              }
            );
            currPromise = myPromise;
            onCancelTimeout = null;
          }, debounceMs);
          onCancelTimeout = () => {
            clearTimeout(timeoutId);
            outerResolve("canceled");
            cache?.delete(cacheKey);
          };
        }
      );
      const pendingValue = {state: "pending", promise: pendingValidation} as const;
      cache?.set(cacheKey, pendingValue);
      onValidate(pendingValue);
    },
    getPendingValidation: () => pendingValidation,
  };
};

export type AsyncCheckManager<TParsed extends FieldValues> = {
  onValueChange: <K extends keyof TParsed>(pathKey: K, value: TParsed[K]) => void;
  getPending: () => null | Promise<{valid: boolean}>;
};

export function createAsyncCheckManager<
  TParsed extends FieldValues,
  TKey extends keyof TParsed & string,
>({
  asyncChecks,
  onValidate,
}: {
  asyncChecks: {[K in TKey]: AsyncCheck<TParsed[K]>};
  onValidate: (name: TKey, res: AsyncValidationValue, validator: any) => void;
}): AsyncCheckManager<TParsed> {
  const mapToValidator = new Map<keyof TParsed, Validator<TParsed[keyof TParsed]>>();
  for (const [name, check] of Object.entries(asyncChecks)) {
    const onInnerValidate = (res: AsyncValidationValue) => {
      onValidate(name as TKey, res, validator);
    };
    const {
      debounceMs = 300,
      memoize = true,
      valueToCacheKey = defaultGetCacheKey,
      fn,
    } = check as AsyncCheck<any>;
    const validator = createValidator({
      debounceMs,
      memoize,
      fn,
      onValidate: onInnerValidate,
      valueToCacheKey,
    });
    mapToValidator.set(name, validator);
  }
  return {
    onValueChange: (pathKey, value) => {
      mapToValidator.get(pathKey)?.scheduleCheck(value);
    },
    getPending: () => {
      const promises: Promise<void>[] = [];
      for (const [, validator] of mapToValidator) {
        const pending = validator.getPendingValidation();
        if (!pending) continue;
        promises.push(
          pending.then((res) => (res === "canceled" || !res.isValid ? Promise.reject() : void 0))
        );
      }
      if (promises.length === 0) return null;
      return Promise.all(promises).then(
        () => ({valid: true}),
        () => ({valid: false})
      );
    },
  };
}

import {useState, useCallback, forwardRef} from "react";
import XText from "../xui/XText";
import XRow from "../xui/XRow";
import XCol from "../xui/XCol";
import {RevealContainer} from "../hooks/useReveal";
import {RawSpinner} from "../xui/Spinner";
import {labelStyles as styles} from "./form.css";
import cx from "../cx";

/** @type any */
export const Label = ({showingErrors, className, ...rest}) => (
  <XText
    size={1}
    as="label"
    preset="label"
    color="gray700"
    className={cx(styles.label.base, showingErrors && styles.label.error, className)}
    {...rest}
  />
);

const InputRow = ({showingErrors, isFocussed, className, ...rest}) => (
  <XRow
    sp={2}
    align="baseline"
    className={cx(
      styles.row.base,
      showingErrors && styles.row.error,
      isFocussed && styles.row.focus,
      className
    )}
    {...rest}
  />
);

const useFocussedState = ({onBlur: onParentBlur, onFocus: onParentFocus}) => {
  const [isFocussed, setIsFocussed] = useState(false);
  return [
    isFocussed,
    useCallback(
      (e) => {
        if (onParentFocus) onParentFocus(e);
        setIsFocussed(true);
      },
      [onParentFocus]
    ),
    useCallback(
      (e) => {
        if (onParentBlur) onParentBlur(e);
        setIsFocussed(false);
      },
      [onParentBlur]
    ),
  ];
};

export const SimpleWithLabel = ({label, className, htmlFor, error, children}) => (
  <XCol sp={2} className={className}>
    <Label htmlFor={htmlFor}>{label}</Label>
    <XCol sp={1}>
      {children}
      {error && (
        <XText size={1} color="error600" preset="bold">
          {error}
        </XText>
      )}
    </XCol>
  </XCol>
);

/** @type any */
export const WithLabel = ({
  children,
  className,
  style,
  label,
  name,
  showErrors = [],
  hint,
  hasPendingValidation,
}) => (
  <XCol sp={2} className={className} style={style}>
    {label && (
      <Label htmlFor={name} showingErrors={showErrors.length > 0}>
        {label}
        <RevealContainer show={hasPendingValidation}>
          <RawSpinner />
        </RevealContainer>
      </Label>
    )}
    <XCol sp={1}>
      {children}
      {showErrors.length > 0 && (
        <XRow sp={1}>
          {showErrors.map((e, i) => (
            <XText size={1} color="error600" preset="bold" key={i}>
              {e}
            </XText>
          ))}
        </XRow>
      )}
      {hint && (
        <XText preset="bold" color="gray500" size={1}>
          {hint}
        </XText>
      )}
    </XCol>
  </XCol>
);

export const FieldWithoutLabel = forwardRef((props, ref) => {
  const {
    children,
    showErrors = [],
    onFocus,
    onBlur,
    as: Comp,
    prefix,
    postfix,
    className,
    hasPendingValidation,
    fieldClassName,
    ...rest
  } = props;
  const [isFocussed, onWrappedFocus, onWrappedBlur] = useFocussedState({onFocus, onBlur});
  const showingErrors = showErrors.length > 0;

  // TODO: show errors

  return (
    <InputRow isFocussed={isFocussed} showingErrors={showingErrors} className={className}>
      {prefix}
      <Comp
        showingErrors={showingErrors}
        ref={ref}
        onFocus={onWrappedFocus}
        onBlur={onWrappedBlur}
        className={fieldClassName}
        {...rest}
      />
      {postfix}
    </InputRow>
  );
});

export const FieldWithLabel = forwardRef((props, ref) => {
  const {
    as: Comp,
    name,
    label = name,
    showErrors = [],
    prefix,
    postfix,
    onFocus,
    onBlur,
    hint,
    className,
    fieldClassName,
    hasPendingValidation,
    style,
    fieldStyle,
    ...rest
  } = props;
  const showingErrors = showErrors.length > 0;
  const [isFocussed, onWrappedFocus, onWrappedBlur] = useFocussedState({onFocus, onBlur});

  return (
    <WithLabel
      label={label}
      name={label}
      showErrors={showErrors}
      hint={hint}
      className={className}
      style={style}
      hasPendingValidation={hasPendingValidation}
    >
      <InputRow isFocussed={isFocussed} showingErrors={showingErrors}>
        {prefix}
        <Comp
          showingErrors={showingErrors}
          id={name}
          name={name}
          ref={ref}
          onFocus={onWrappedFocus}
          onBlur={onWrappedBlur}
          style={fieldStyle}
          className={fieldClassName}
          {...rest}
        />
        {postfix}
      </InputRow>
    </WithLabel>
  );
});

import {api} from "./api";
import useMutation from "./hooks/useMutation";
import messenger from "./messenger";

export const useLogout = ({history} = {}) => {
  const [logout] = useMutation("users", "logout");

  return () =>
    logout().then(() => {
      messenger.send("You have been logged out successfully.");
      api.cache.clear({delayReload: true});
      if (history) history.replace("/login");
    });
};

import {Activity} from "../../../cdx-models/Activity";
import {ResolvableEntryId} from "../../../cdx-models/ResolvableEntry";
import {Box, Col, css, DSIconConversation, DSIconDeck, DSIconPlus, Row} from "@cdx/ds";
import {FeedEntryComp, getCardLink} from "../shared";
import {ArenaCtx} from "../../card-panel/card-panel-utils";
import {activityCommentStyle, cardUpdateStyles} from "../activity-feed.css";
import UnMarkedown from "../../../components/Markdown/UnMarkedown";
import {Link} from "react-router-dom";
import {Deck} from "../../../cdx-models/Deck";
import {Project} from "../../../cdx-models/Project";
import {CdxCropImgByFile} from "../../../components/CdxImg";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import {CardLine, getFeedCardUpdateInfo} from "./FeedCardUpdateEntryInfo";

type CommentData = {content: string; entryId: ResolvableEntryId; resolvableId: ResolvableEntryId};

type ProperActivity = Omit<Activity, "type" | "date"> &
  (
    | {
        type: "comment";
        data: CommentData;
      }
    | {
        type: "card_update";
        data: {
          version: number;
          diff: any & {orderLabelChange: [prev: string | null, next: string | null, type: string]};
        };
      }
    | {
        type: "deck_update";
        data: {type: "created" | "movedInto" | "movedAway"};
      }
    | {
        type: "unknown";
        data: unknown;
      }
  );

const CommentLine = (props: {values: CommentData[]; arenaCtx: ArenaCtx; firstItem: Activity}) => {
  const {values, arenaCtx, firstItem} = props;
  const data = values[0];
  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconConversation size={16} className={cardUpdateStyles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      <span>
        wrote{" "}
        <UnMarkedown
          as={Link}
          maxChars={80}
          className={activityCommentStyle.base}
          to={getCardLink(firstItem.card, arenaCtx, {
            targetPanel: "comments",
            resolvableId: data.resolvableId,
          })}
        >
          {data.content}
        </UnMarkedown>
      </span>
    </CardLine>
  );
};

const DeckTile = ({deck}: {deck: Deck}) => (
  <Row sp="4px" align="center" bg="foreground" display="inline-flex" relative top="2px">
    <CdxCropImgByFile
      width={16}
      height={16}
      file={deck?.coverFile}
      className={css({
        flex: "none",
        elevation: 100,
        rounded: 4,
        overflow: "hidden",
      })}
      fallbackClassName={css({bg: "foreground"}, colorThemes.gray500)}
    />
    <Box color="primary" size={14} bold>
      {deck.title}
    </Box>
  </Row>
);

const ProjectTile = ({project}: {project: Project}) => (
  <Row sp="4px" align="center" bg="foreground" display="inline-flex" relative top="2px">
    <CdxCropImgByFile
      file={project.coverFile}
      width={16}
      height={16}
      className={css({
        flex: "none",
        elevation: 100,
        rounded: 4,
        width: "16px",
        height: "16px",
        colorTheme: "gray550",
        bg: "foreground",
        position: "relative",
      })}
    />
    <Box color="primary" size={14} bold>
      {project.name}
    </Box>
  </Row>
);

const getDeckContent = ({
  project,
  deck,
  type,
}: {
  project: Project;
  deck: Deck;
  type: "created" | "movedInto" | "movedAway";
}) => {
  switch (type) {
    case "created":
      return {
        icon: <DSIconPlus size={16} className={cardUpdateStyles.iconActive} />,
        content: (
          <>
            Created <DeckTile deck={deck} /> in <ProjectTile project={project} />
          </>
        ),
      };
    case "movedInto":
      return {
        icon: <DSIconDeck size={16} className={cardUpdateStyles.iconActive} />,
        content: (
          <>
            Moved <DeckTile deck={deck} /> to <ProjectTile project={project} />
          </>
        ),
      };
    case "movedAway":
      return {
        icon: <DSIconDeck size={16} className={cardUpdateStyles.iconActive} />,
        content: (
          <>
            Moved <DeckTile deck={deck} /> away from <ProjectTile project={project} />
          </>
        ),
      };
    default:
      return {icon: null, content: null};
  }
};

const DeckUpdateLine = (props: {
  values: {type: "created" | "movedInto" | "movedAway"}[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, firstItem} = props;
  const {icon, content} = getDeckContent({
    deck: firstItem.deck,
    project: firstItem.project,
    type: values[0].type,
  });
  return (
    <Row sp="16px" align="start">
      <Col align="center" width="16px" relative top="2px">
        {icon}
      </Col>
      <div>{content}</div>
    </Row>
  );
};

export const classifyFeedEntry = (
  rawItem: Activity
): {key: string; val: any; isComment: boolean; Comp: FeedEntryComp} => {
  const item = rawItem as ProperActivity;
  const type = item.type;
  switch (type) {
    case "unknown": {
      return {
        key: item.id,
        val: item,
        isComment: false,
        Comp: () => <div>unknown</div>,
      };
    }
    case "comment": {
      return {
        key: item.data.entryId ?? item.id,
        val: item.data,
        isComment: true,
        Comp: CommentLine,
      };
    }
    case "deck_update": {
      return {
        key: `d:${item.data.type}-${item.deck.id}`,
        val: item.data,
        isComment: false,
        Comp: DeckUpdateLine,
      };
    }
    case "card_update": {
      return getFeedCardUpdateInfo(item);
    }
  }
};

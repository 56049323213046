import PlainButton, {
  PlainSquareButton,
  ElevatedButton,
  ElevatedSquareButton,
  IconButton,
  TextButton,
  RawButton,
} from "./Button";
import Icon from "./Icon";
import {legacyUiStyles} from "./legacy-ui2.css";
import {cx} from "@cdx/common";

const common = {
  full: {position: "absolute", top: 0, left: 0, right: 0, bottom: 0},
  fixed: {position: "fixed", top: 0, bottom: 0, right: 0, left: 0},
  stretchCol: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonReset: {
    textAlign: "inherit",
    color: "inherit",
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    fontWeight: "inherit",
    borderRadius: 0,
  },
};

const createComp =
  (Comp, defClassName) =>
  ({className, ...rest}) => (
    <Comp className={cx(className, legacyUiStyles[defClassName])} {...rest} />
  );

const Ui = {
  Col: createComp("div", "col"),
  Row: createComp("div", "row"),
  H1: createComp("h1", "h1"),
  PlainButton,
  RawButton,
  PlainSquareButton,
  ElevatedButton,
  ElevatedSquareButton,
  IconButton,
  TextButton,
  Icon,
  common,
};

export default Ui;

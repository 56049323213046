import {CSSProperties, forwardRef} from "react";
import {useReveal} from "../hooks/useReveal";
import {animated} from "react-spring";
import {spinnerStyles} from "./spinner.css";
import xcolors from "./xcolors";
import {Col, css} from "@cdx/ds";

type RawSpinnerProps = {size?: number; style?: CSSProperties};
export const RawSpinner = forwardRef<SVGSVGElement, RawSpinnerProps>(
  ({size = 16, style, ...rest}, ref) => {
    const isSmall = size < 16;
    return (
      <svg
        style={{width: Math.min(150, size), height: Math.min(150, size), ...style}}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        stroke={xcolors.gray400}
        {...rest}
        ref={ref}
      >
        <g className={spinnerStyles.g} strokeWidth={size <= 25 ? (isSmall ? 6 : 4) : 2} fill="none">
          <circle strokeOpacity=".25" cx="20" cy="20" r="18" />
          <path strokeOpacity={isSmall ? "1" : ".5"} d="M38 20A18 18 0 0 0 20 2" />
        </g>
      </svg>
    );
  }
);

type SpinnerProps = {size?: number; withCover?: boolean; coverColor?: string; className?: string};
export const Spinner = ({size = 75, withCover, coverColor, ...rest}: SpinnerProps) => (
  <Col absolute inset="0" zIndex={1} pointerEvents="none" {...rest} align="center" justify="center">
    <RawSpinner size={size} />
    {withCover && (
      <Col
        className={spinnerStyles.cover}
        absolute
        inset="0"
        style={coverColor ? {backgroundColor: coverColor} : {}}
      />
    )}
  </Col>
);

const AnimRawSpinner = animated(RawSpinner);

type RevealSpinnerProps = SpinnerProps & {
  show: boolean;
  coverMessage?: string;
  fixed?: boolean;
  delay?: number;
};

export const RevealSpinner = ({
  show,
  size = 75,
  withCover,
  coverColor,
  coverMessage,
  fixed,
  delay,
  ...rest
}: RevealSpinnerProps) => {
  const reveal = useReveal(show, delay ? ({trail: show ? delay : 0} as any) : {});
  return reveal((props: any) => (
    <animated.div
      style={{
        pointerEvents: props.value.to((val: any) => (val > 0.5 ? "initial" : "none")),
      }}
      className={css({
        display: "flex",
        flexDir: "column",
        sp: "12px",
        inset: "0",
        align: "center",
        justify: "center",
        zIndex: 1,
        pointerEvents: "none",
        position: fixed ? "fixed" : "absolute",
      })}
    >
      <AnimRawSpinner
        size={size}
        style={{
          opacity: props.value,
          transform: props.value.to((v: number) => `scale(${v / 2 + 0.5})`),
        }}
      />
      {coverMessage && (
        <animated.div
          className={css({textType: "label14", color: "secondary"})}
          style={{opacity: props.value}}
        >
          {coverMessage}
        </animated.div>
      )}
      {withCover && (
        <animated.div
          className={css(
            {
              display: "flex",
              flexDir: "column",
              position: "absolute",
              inset: "0",
              align: "center",
              justify: "center",
            },
            spinnerStyles.cover
          )}
          style={{opacity: props.value, backgroundColor: coverColor}}
        />
      )}
    </animated.div>
  ));
};

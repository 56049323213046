import {forwardRef} from "react";
import {arrowStyles} from "./arrow.css";
import cx from "../cx";

const dirInfo = {
  top: {colProp: "borderBottomColor", dir: "horizontal"},
  bottom: {colProp: "borderTopColor", dir: "horizontal"},
  left: {colProp: "borderRightColor", dir: "vertical"},
  right: {colProp: "borderLeftColor", dir: "vertical"},
};

const Arrow = forwardRef(({pointTo, color, size, className, style, ...rest}, ref) => {
  const {dir, colProp} = dirInfo[pointTo];
  return (
    <div
      ref={ref}
      style={color && {[colProp]: color, ...style}}
      className={cx(
        arrowStyles.base,
        arrowStyles.bySize[size],
        arrowStyles.byPosition[pointTo],
        arrowStyles.byDirAndSize[dir][size],
        className
      )}
      {...rest}
    />
  );
});

export default Arrow;

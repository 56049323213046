import {forwardRef} from "react";
import classes from "./ui.css";
import {propsToCss} from "./shared";

export const XGrid = forwardRef(({children, sp, as, ...rest}, ref) => {
  const innerClasses = [classes.gridClass];
  if (sp !== undefined && sp !== null) innerClasses.push(classes.gridSpacing[sp]);

  const Comp = as || "div";

  return (
    <Comp ref={ref} {...propsToCss(rest)}>
      <div className={innerClasses.join(" ")}>{children}</div>
    </Comp>
  );
});

export default XGrid;

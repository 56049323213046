import {shortFormatter, titleFormatter} from "../lib/date-utils";
import {getVarName} from "@vanilla-extract/private";
import {MiniEvent} from "@cdx/common";
import {useEffect, useState} from "react";
import {ageStyle, ageVar} from "./FromNow.css";

const tickEvents = new MiniEvent();
setInterval(() => {
  tickEvents.emit(new Date());
}, 10000);

export const useNow = () => {
  const [now, setNow] = useState(() => new Date());
  useEffect(() => tickEvents.addListener(setNow), []);
  return now;
};

const ageToScoreFn = (diffInHours) => {
  return 1 / 1.01 ** diffInHours;
};

/**
 *
 * @param {date: Date | null} props
 * @returns {opts: {style?: CSSProperties, className?: string}}
 */
export const useAgeColorProps = (date) => {
  const now = useNow();
  if (!date) return {};
  const diffInHours = (now.getTime() - date.getTime()) / (3600 * 1000);
  // console.log(date.toDateString(), ageToScoreFn(diffInHours).toFixed(2));
  return {
    style: {[getVarName(ageVar)]: ageToScoreFn(diffInHours).toFixed(2)},
    className: ageStyle,
  };
};

/**
 *
 * @param {{date: Date, onlyDays?: boolean}} props
 * @returns ReactElement
 */
const FromNow = ({date, onlyDays}) => {
  const now = useNow();
  if (!date || !date.getTime) return <span>unknown</span>;
  let timeStr = "right now";

  const diffInS = (now.getTime() - date.getTime()) / 1000;
  if (diffInS > 0) {
    if (diffInS < 60) {
      timeStr = "just now";
    } else if (diffInS < 60 * 60) {
      if (diffInS < 60 * 2) {
        timeStr = "a minute ago";
      } else {
        timeStr = `${Math.floor(diffInS / 60)} minutes ago`;
      }
    } else if (diffInS < 60 * 60 * 24) {
      if (diffInS < 60 * 60 * 2) {
        timeStr = "an hour ago";
      } else {
        timeStr = `${Math.floor(diffInS / (60 * 60))} hours ago`;
      }
    } else if (diffInS < 60 * 60 * 24 * 31 || onlyDays) {
      if (diffInS < 60 * 60 * 24 * 2) {
        timeStr = "a day ago";
      } else {
        timeStr = `${Math.floor(diffInS / (60 * 60 * 24))} days ago`;
      }
    } else {
      timeStr = `on ${shortFormatter.format(date)}`;
    }
  } else if (diffInS < -60) {
    if (diffInS > -60 * 60) {
      if (diffInS > -60 * 2) {
        timeStr = "in a minute";
      } else {
        timeStr = `in ${Math.floor(diffInS / -60)} minutes`;
      }
    } else if (diffInS > -60 * 60 * 24) {
      if (diffInS > -60 * 60 * 2) {
        timeStr = "in an hour";
      } else {
        timeStr = `in ${Math.floor(diffInS / (-60 * 60))} hours`;
      }
    } else if (diffInS > -60 * 60 * 24 * 31 || onlyDays) {
      if (diffInS > -60 * 60 * 24 * 2) {
        timeStr = "in a day";
      } else {
        timeStr = `in ${Math.floor(diffInS / (-60 * 60 * 24))} days`;
      }
    } else {
      timeStr = `on ${shortFormatter.format(date)}`;
    }
  }

  return <span title={titleFormatter.format(date)}>{timeStr}</span>;
};

export default FromNow;

import {useCallback, useRef, useEffect} from "react";
import {useMachine} from "@cdx/common";
import {useApi} from "../mate/mate-utils";

const fetchMachine = {
  ready: {
    initial: true,
    initialData: {},
    on: {
      REQUEST: (fn: Function) => ["loading", {promise: fn()}] as const,
    },
  },
  loading: {
    on: {
      SUCCESS: () => ["ready", {}] as const,
      ERROR: (error: any) => ["ready", {lastError: error}] as const,
    },
  },
};

const useMutation = (namespace: string, action: string) => {
  const api = useApi() as any;
  const [current, send] = useMachine(fetchMachine);
  const currentRef = useRef(current);
  useEffect(() => {
    currentRef.current = current;
  }, [current]);
  const mutation = api.mutate[namespace][action];
  if (!mutation) throw new Error(`No mutation found for '${namespace}.${action || ""}'`);
  const callFn = useCallback(
    (args?: any) => {
      if (currentRef.current.value === "loading") return currentRef.current.data.promise;
      return new Promise((res, rej) => {
        send("REQUEST", () =>
          mutation(args).then(
            (v: any) => {
              send("SUCCESS", v);
              res(v);
            },
            (e: any) => {
              send("ERROR", e);
              rej(e);
            }
          )
        );
      });
    },
    [mutation, send]
  );
  return [callFn, current.value === "loading"] as const;
};

export default useMutation;

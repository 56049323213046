import {forwardRef} from "react";

const radCircumference = Math.PI * 2;
const transformSlices = (data, radius, center) => {
  const total = data.reduce((m, {value}) => m + value, 0);

  let radSegment = 0;
  let lastX = radius;
  let lastY = 0;
  return data
    .map((sliceData) => {
      const {value} = sliceData;
      if (value === total) {
        return {Comp: "circle", props: {r: radius, cx: center, cy: center}, sliceData};
      }
      if (value === 0) return null;

      const valuePercentage = value / total;
      const longArc = valuePercentage <= 0.5 ? 0 : 1;

      radSegment += valuePercentage * radCircumference;
      const nextX = Math.cos(radSegment) * radius;
      const nextY = Math.sin(radSegment) * radius;

      const d = [
        `M ${center},${center}`,
        `l ${lastX},${-lastY}`,
        `a${radius},${radius}`,
        "0",
        `${longArc},0`,
        `${nextX - lastX},${-(nextY - lastY)}`,
        "z",
      ].join(" ");

      lastX = nextX;
      lastY = nextY;

      return {Comp: "path", props: {d}, sliceData};
    })
    .filter(Boolean);
};

const PieChart = forwardRef(({data, children, ...rest}, ref) => {
  const size = 100;
  const center = size / 2;
  const slices = transformSlices(data.reverse(), size / 2 - 1, center);

  return (
    <svg viewBox={`0 0 ${size} ${size}`} {...rest} ref={ref}>
      <g transform={`rotate(-90 ${center} ${center})`}>
        {slices.map(({Comp, props, sliceData}, index) => children({Comp, props, sliceData, index}))}
      </g>
    </svg>
  );
});

export default PieChart;

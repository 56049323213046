import {cx} from "@cdx/common";
import {uploadInputStyles as styles} from "./uploader.css";
import dsStyles from "@cdx/ds/css/index.css";

const Input = ({inputProps, children, className, ...rest}) => (
  <div className={cx(className, styles.text)} {...rest}>
    {children} or{" "}
    <label className={styles.label}>
      browse <input {...inputProps} className={dsStyles.display.none} />
    </label>
  </div>
);

export default Input;

import cx from "../../utils/cx";
import {dsShortcutKeyStyles as styles} from "./DSShortcutKey.css";

type DSShortcutKeyProps = {
  children: string;
  title?: string;
  size?: keyof typeof styles.size;
};
export const DSShortcutKey = ({children, title, size = "md"}: DSShortcutKeyProps) => (
  <span className={cx(styles.base, styles.size[size])} title={title || `Shortcut key: ${children}`}>
    {children}
  </span>
);

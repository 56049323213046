import {useNeoBag, DSSubmitButton, cx} from "@cdx/common";
import {DefaultProjectTile} from "../../components/ProjectMultiPicker";
import getSelectedProjects from "../../lib/hooks/useSelectedProjects";
import {msMainColors} from "./milestone-theme.css";
import MiniProjectTile from "../../components/MiniProjectTile";
import {pluralize} from "../../lib/utils";
import {Box, Col, Row} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";

export const MsFormProjectTile = ({project, getCardCountForProject, isActive}) => {
  if (!getCardCountForProject) return <DefaultProjectTile project={project} />;
  const projectId = project.$meta.get("id", null);
  const cardCount = projectId ? getCardCountForProject(projectId) : 0;
  const willRemove = cardCount > 0 && !isActive;
  return (
    <Row align="start" sp="4px" minWidth="0">
      <MiniProjectTile project={project} size={16} />
      <Col pt="1px">
        <Box
          size={14}
          color="primary"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          textDecoration={
            project.$meta.get("visibility", "default") !== "default" ? "line-through" : undefined
          }
        >
          {project.name}
        </Box>
        <Box
          colorTheme={willRemove ? "alert25" : undefined}
          size={10}
          color={willRemove ? "primary" : "secondary"}
        >
          {pluralize(cardCount, "card")}
          {willRemove && " will be removed"}
        </Box>
      </Col>
    </Row>
  );
};

export const MsFormButton = (props) => (
  <DSSubmitButton
    formBag={useNeoBag()}
    variant="primary"
    size="md"
    className={cx(dsStyles.ml.auto, dsStyles.mt.auto)}
    {...props}
  />
);

export const colorCodeToName = (hexCol) =>
  Object.keys(msMainColors).find((name) => msMainColors[name] === hexCol) || hexCol;

export const getPreselectedProjectIds = (root) => {
  const selProjectIds = getSelectedProjects(root).map((p) => p.$meta.get("id", null));
  if (root.account.staffPermission === "full") return selProjectIds;
  const accountId = root.account.$meta.get("id", null);
  const myProducerProjectIds = root.loggedInUser.$meta
    .find("withProjectAccess", {
      $or: [{projectRole: ["producer"]}, {role: ["admin", "owner"]}],
      project: {accountId, visibility: "default"},
    })
    .map((pa) => pa.project.$meta.get("id", null));
  const selSet = new Set(selProjectIds);
  const intersection = myProducerProjectIds.filter((id) => selSet.has(id));
  return intersection.length > 0 ? intersection : myProducerProjectIds;
};

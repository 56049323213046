import {useInstance} from "../../lib/mate/mate-utils";
import {TooltipForChild} from "@cdx/common";
import {forwardRef} from "react";
import {msColorThemes} from "./milestone-theme.css";
import {isWithMetaKey} from "@cdx/common/device-utils";
import {useHistory} from "react-router";
import routes from "../../routes";
import {waitForResult} from "../../lib/wait-for-result";
import {api} from "../../lib/api";
import {MilestoneOverviewTooltipContent} from "./MilestonesOverview/MilestonesOverviewTooltip";
import {ThemedMilestoneIcon} from "../../features/milestones/milestone-utils";
import {CardPropChangeOverlayForChild} from "../../components/RichTextarea/Lexical/CardPropChangeForChild";

/**
 * @type React.FC<{
 * milestone: import("../../cdx-models/Milestone").Milestone,
 * tooltip?: string
 * zoneInfo?: string | null
 * onClick?:  () => void
 * hidden?: boolean
 * children: React.ReactNode
 * }>
 */
export const MilestoneTooltipForChild = forwardRef(
  ({milestone, tooltip, placement = "right", zoneInfo, ...rest}, ref) => (
    <TooltipForChild
      tooltip={
        <MilestoneOverviewTooltipContent milestone={milestone} note={tooltip} zoneInfo={zoneInfo} />
      }
      bg="white"
      placement={placement}
      ref={ref}
      {...rest}
    />
  )
);

const MsByIdTooltipContent = ({milestoneId, tooltip, zoneInfo}) => {
  const milestone = useInstance("milestone", milestoneId);
  if (!milestone) return null;
  return (
    <MilestoneOverviewTooltipContent milestone={milestone} note={tooltip} zoneInfo={zoneInfo} />
  );
};

/**
 * @type React.FC<{
 * milestoneId: import("../../cdx-models/Milestone").Milestone["id"],
 * tooltip?: string,
 * zoneInfo?: string | null
 * options?: TooltipForChildProps,
 * children: React.ReactNode,
 * onClick?: () => void
 * }>
 */
export const MsByIdTooltipForChild = forwardRef(
  ({milestoneId, tooltip, options, children, onClick, zoneInfo}, ref) => (
    <TooltipForChild
      tooltip={
        <MsByIdTooltipContent milestoneId={milestoneId} tooltip={tooltip} zoneInfo={zoneInfo} />
      }
      placement="right"
      bg="white"
      {...options}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </TooltipForChild>
  )
);

/**
 *
 * @param {{
 * milestoneId: import("../../cdx-models/Milestone").MilestoneId,
 * color: string | null,
 * milestoneState?: null | "next" | "current" | "past",
 * tooltip?: import("react").ReactNode
 * zoneInfo?: string | null
 * propChangeInfo?: false | null | {cardId: import("../../cdx-models/Card").CardId, cardContainerKey: string}
 * openMsOnMetaClick?: {cardId: import("../../cdx-models/Card").CardId},
 * hidden?: boolean
 * }} props
 */
export const IconWithMilestoneTooltipById = ({
  milestoneId,
  color,
  milestoneState,
  tooltip,
  openMsOnMetaClick,
  hidden,
  onDark,
  propChangeInfo,
  zoneInfo,
}) => {
  const history = useHistory();
  const handleClick = (e) => {
    if (openMsOnMetaClick && isWithMetaKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      waitForResult(
        () => {
          const {accountSeq, title} = api.getModel({
            modelName: "card",
            id: openMsOnMetaClick.cardId,
          });
          const ms = api.getModel({modelName: "milestone", id: milestoneId});
          return {msAccountSeq: ms.accountSeq, card: {accountSeq, title}};
        },
        ({msAccountSeq, card}) => {
          history.push(routes.milestoneWithCard.getUrl(msAccountSeq, card));
        }
      );
    }
  };

  if (process.env.REACT_APP_MODE === "open") return null;

  const content = (
    <MsByIdTooltipForChild
      milestoneId={milestoneId}
      tooltip={tooltip}
      options={{hidden}}
      zoneInfo={zoneInfo}
    >
      <ThemedMilestoneIcon
        size={16}
        onDark={onDark}
        milestoneState={milestoneState}
        theme={msColorThemes[color] || msColorThemes.blue}
        onClick={handleClick} // will be overridden by CardPropChangeOverlayForChild
      />
    </MsByIdTooltipForChild>
  );
  return propChangeInfo ? (
    <CardPropChangeOverlayForChild
      cardId={propChangeInfo.cardId}
      cardContainerKey={`${propChangeInfo.cardContainerKey}-ms`}
      initialTab="milestone"
      getChildProps={(open) => ({options: {hidden: open ? true : hidden}})}
      onClick={handleClick}
    >
      {content}
    </CardPropChangeOverlayForChild>
  ) : (
    content
  );
};

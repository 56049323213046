import {useState, useRef, useEffect, useCallback} from "react";
import {checkIfValid, useApi} from "../mate/mate-utils";

/*
usage:
const {state, setState, isLoaded} = useStateIfLoaded(() => ({name: account.name}), [account.id])
*/

/**
 * @template T
 * @param {() => T} dataGetter
 * @param {any[]=} deps
 * @returns {{state: T, setState: React.Dispatch<React.SetStateAction<T>>, isLoaded: boolean, reset: () => void}}
 */
const useStateIfLoaded = (dataGetter, deps = []) => {
  // state = {isLoaded, result}
  const api = useApi();
  const [state, setState] = useState(() => checkIfValid(dataGetter, api));
  const isFirstRef = useRef(true);
  if (!state.isLoaded && !isFirstRef.current) {
    // retry on each successive render
    const current = checkIfValid(dataGetter, api);
    if (current.isLoaded) setState(current);
  }
  useEffect(() => {
    if (!isFirstRef.current) setState(checkIfValid(dataGetter, api));
    isFirstRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  const setResultState = useCallback(
    (newResult) =>
      setState((prev) => {
        // don't accept changes if data hasn't been loaded yet
        if (!prev.isLoaded) return prev;
        const nextResult = typeof newResult === "function" ? newResult(prev.result) : newResult;
        return nextResult === prev.result ? prev : {...prev, result: nextResult};
      }),
    []
  );
  const reset = () => setState((prev) => ({...prev, isLoaded: false}));
  return {state: state.result, setState: setResultState, isLoaded: state.isLoaded, reset};
};

export default useStateIfLoaded;

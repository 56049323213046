import {createContext, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {isEventWithinInteractiveNode} from "./dom-helpers";
import {useGlobalKeyPress} from "./KeyPress";

const ClickOutsideContext = createContext({
  listeners: [],
  register: () => {
    if (process.env.NODE_ENV !== "production") {
      console.warn("Please use ClickOutsideProvider!");
    }
    return () => {};
  },
});

export const ClickOutsideProvider = ({children}) => {
  const listenersRef = useRef([]);
  const currentBatchRef = useRef(null);

  const ctxVal = useMemo(
    () => ({
      register: (handlerObj) => {
        const listeners = listenersRef.current;
        if (currentBatchRef.current === null) {
          currentBatchRef.current = currentBatchRef.current = [];
          // FIXME: this pattern isn't safe.
          // If some underlying useEffect calls useState, all parents and children
          // useEffects are called again before setTimeout is over
          // consider using the subRef pattern of `useGlobalKeyPress`. Not sure it's safe either
          setTimeout(() => {
            listeners.unshift(...currentBatchRef.current.reverse());
            currentBatchRef.current = null;
          });
        }
        currentBatchRef.current.push(handlerObj);
        return () => {
          if (currentBatchRef.current && currentBatchRef.current.indexOf(handlerObj) > -1) {
            currentBatchRef.current.splice(currentBatchRef.current.indexOf(handlerObj), 1);
          } else if (listeners.indexOf(handlerObj) > -1) {
            listeners.splice(listeners.indexOf(handlerObj), 1);
          } else {
            console.warn("Handler found no where!?");
          }
        };
      },
    }),
    []
  );

  const handleClick = (e) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (!(range.startContainer === range.endContainer && range.startOffset === range.endOffset))
        return;
    }
    let clickOnInteractive = isEventWithinInteractiveNode(e);

    // there's already another onClick handler. So we don't want to deselect nothing. Maybe.
    listenersRef.current.some(({handler, strict, escOnly}) => {
      if ((!clickOnInteractive || strict) && !escOnly) {
        return handler(e) !== false;
      }
      return false;
    });
    e.stopPropagation();
  };

  return (
    <ClickOutsideContext.Provider value={ctxVal}>
      {children({onClick: handleClick})}
    </ClickOutsideContext.Provider>
  );
};

export const useEsc = (handler, {isDisabled} = {}) => {
  useGlobalKeyPress({
    disabled: isDisabled,
    key: "Escape",
    fn: handler,
    ignoreTarget: true,
  });
};

// strict: trigger handler, even if click goes onto e.g. button
export const useClickOutside = (onClickOutside, {isDisabled, strict} = {}) => {
  const ctx = useContext(ClickOutsideContext);
  const fnRef = useRef();
  const justPassedRef = useRef();
  useEffect(() => {
    fnRef.current = onClickOutside;
  }, [onClickOutside]);
  useEffect(() => {
    if (!isDisabled) {
      const handleClickOutside = (e, force) => {
        if (!fnRef.current) return false;
        if (force) return fnRef.current();
        let justPassed = justPassedRef.current;
        justPassedRef.current = null;
        if (e.nativeEvent === justPassed) return false;
        return fnRef.current();
      };
      return ctx.register({handler: handleClickOutside, strict});
    }
  }, [ctx, isDisabled, strict]);

  useGlobalKeyPress({
    key: "Escape",
    fn: (e) => (isDisabled ? false : onClickOutside(e, true)),
    ignoreTarget: true,
  });

  const onClick = useCallback((e) => {
    justPassedRef.current = e.nativeEvent;
  }, []);
  return {onClick};
};

export const ClickOutside = ({children, onClickOutside}) =>
  children(useClickOutside(onClickOutside));

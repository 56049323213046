import {useRef, useReducer} from "react";

const reducer = (state, action) => action(state);

const debounceActions = {
  call: (dispatch, updateFn, scheduleFn, actionFn, initialVal) =>
    dispatch((state) => {
      const scheduleAction = (withVal) => {
        scheduleFn(() => {
          const retVal = actionFn(withVal);
          if (retVal && typeof retVal.then === "function") {
            dispatch((s2) => ({...s2, isSubmitting: true, whileSubmitVal: initialVal}));
            const onDone = () => {
              dispatch((s2) => {
                if (s2.isSubmitting) {
                  if (s2.whileSubmitVal !== initialVal) {
                    scheduleAction(s2.whileSubmitVal);
                  }
                  return {
                    ...s2,
                    cachedVal: s2.whileSubmitVal,
                    isSubmitting: false,
                    whileSubmitVal: null,
                  };
                } else {
                  console.warn("calling onDone even though it's not submitting any more!?");
                  return s2;
                }
              });
            };
            retVal.then(onDone, (e) => {
              onDone();
              return Promise.reject(e);
            });
          } else {
            dispatch(() => ({cachedVal: initialVal, isSubmitting: false, whileSubmitVal: null}));
          }
        });
      };
      if (state.isSubmitting) {
        return {...state, whileSubmitVal: updateFn(state.whileSubmitVal)};
      } else {
        const nextVal = updateFn(state.cachedVal);
        scheduleAction(nextVal);
        return {...state, cachedVal: nextVal};
      }
    }),
};

const useDebounceActions = ({initialVal, fn, waitMs = 1000}) => {
  const timeoutRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    cachedVal: initialVal,
    isSubmitting: false,
    whileSubmitVal: null,
  });

  const debouncedFn = (updateFn) => {
    const scheduleFn = (targetFn) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        targetFn();
      }, waitMs);
    };
    debounceActions.call(dispatch, updateFn, scheduleFn, fn, initialVal);
  };
  return [debouncedFn, state.isSubmitting ? state.whileSubmitVal : state.cachedVal];
};

export default useDebounceActions;

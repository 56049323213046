import {forwardRef} from "react";
import {cx, LinkOrButton, rawButtonStyle, XRow} from "@cdx/common";
import {tabStyles as styles} from "./arena.css";

const LeftCornerSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 36" style={{width: 12, height: 40.5}}>
    <g fill="none" fillRule="evenodd">
      <path
        className={styles.cornerBorder}
        strokeWidth="2"
        d="M12 .5h-1.4c-.7 0-1.3.49-1.46 1.17L1.62 35.5H0"
      />
      <path
        className={styles.cornerContent}
        fillRule="nonzero"
        d="M12 .5h-1.4c-.7 0-1.3.49-1.46 1.17L1.5 36H12"
      />
    </g>
  </svg>
);

const RightCornerSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 0 11 36" style={{width: 12, height: 40.5}}>
    <g fill="none" fillRule="evenodd">
      <path
        className={styles.cornerBorder}
        strokeWidth="2"
        d="M0 .5h1.4c.7 0 1.3.49 1.46 1.17l7.52 33.83H12"
      />
      <path className={styles.cornerContent} d="M0 .5h1.4c.7 0 1.3.49 1.46 1.17L10.5 36H0" />
    </g>
  </svg>
);

const LeftCorner = () => (
  <div className={cx(styles.corner.base, styles.corner.left)}>
    <LeftCornerSvg />
  </div>
);

const RightCorner = () => (
  <div className={cx(styles.corner.base, styles.corner.right)}>
    <RightCornerSvg />
  </div>
);

const Tab = forwardRef((props, ref) => {
  const {
    children,
    active,
    handActive,
    index,
    smallText,
    canDrop,
    isRemoveDrop,
    isDropOver,
    className,
    ...rest
  } = props;
  return (
    <LinkOrButton
      className={cx(
        rawButtonStyle,
        styles.container.base,
        active && styles.container.active,
        handActive && styles.container.handActive,
        canDrop && styles.container[isRemoveDrop ? "canDropRemove" : "canDrop"],
        isDropOver && styles.container[isRemoveDrop ? "dropOverRemove" : "dropOver"]
      )}
      {...rest}
      style={{zIndex: active ? 5 : 3 - index}}
      ref={ref}
    >
      <LeftCorner />
      <XRow
        align="center"
        justify="center"
        fillParent
        relative
        className={cx(
          styles.textContent.base,
          smallText && styles.textContent.smallText,
          className
        )}
      >
        {children}
      </XRow>
      <RightCorner />
    </LinkOrButton>
  );
});

export default Tab;

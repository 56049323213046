import {api} from "../../lib/api";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {cx} from "@cdx/common";
import {dayToDate, isDayInPast} from "../../lib/date-utils";
import {msColorThemes} from "../../pages/milestones/milestone-theme.css";
import {forwardRef} from "react";
import {milestoneIconStyle} from "./milestones.css";
import {DSIconMilestone, DSIconMilestoneNext} from "@cdx/ds";

const getTodayStr = () => {
  const today = new Date();
  return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
};

export function getFutureMilestonesByProject(account, projects) {
  const constraints = {date: {op: "gte", value: getTodayStr()}};
  const ids = projects.filter((p) => p.$meta.isLoaded).map((p) => p.id);
  if (!ids.length) return [];
  return account.$meta.find("milestones", {
    milestoneProjects: {projectId: ids},
    isDeleted: false,
    ...constraints,
    $order: "date",
  });
}

export function getNextMilestoneOfColor(account, color) {
  return account.$meta.first("milestones", {
    date: {op: "gte", value: getTodayStr()},
    isDeleted: false,
    color,
    $order: "date",
  });
}

/**
 * @param {import("../../cdx-models/Milestone").Milestone} milestone
 */
export const getMilestoneState = (account, milestone) => {
  if (isMilestoneInPast(milestone)) {
    return "past";
  } else {
    return "current";
    // const col = milestone.$meta.get("color", null);
    // const nextMs = col && getNextMilestoneOfColor(account, col);
    // return nextMs === milestone ? "current" : "next";
  }
};

/**
 * @return {import("../../cdx-models/Milestone").Milestone[]}
 */
export function getMilestonesByProjectIds(account, ids, constraints) {
  if (!ids.length) return [];
  return account.$meta.find("milestones", {
    milestoneProjects: {projectId: ids},
    isDeleted: false,
    ...constraints,
    $order: "date",
  });
}

export function isMilestoneInPast(milestone) {
  return isDayInPast(milestone.date);
}

export function msToDate(milestone) {
  return dayToDate(milestone.date);
}

export const isMilestoneValidTargetForCards = ({milestoneId, cardIds}) =>
  waitForResultPromise(() => {
    if (!milestoneId) return {ok: true, unsupportedProjectNames: []};
    const ms = api.getModel({modelName: "milestone", id: milestoneId});
    const cards = cardIds.map((id) => api.getModel({modelName: "card", id}));
    const cardProjects = cards.map((c) => c && c.deck && c.deck.project).filter(Boolean);
    const targetProjectIds = new Set(ms.milestoneProjects.map((mp) => mp.projectId));
    const unsupportedProjectNames = [
      ...new Set(cardProjects.filter((p) => !targetProjectIds.has(p.id)).map((p) => p.name)),
    ];
    return {ok: unsupportedProjectNames.length === 0, unsupportedProjectNames};
  });

export const isMsPinned = ({milestone, root: {loggedInUser, account}}) => {
  const accountId = account.$meta.get("id", null);
  const milestoneId = milestone.$meta.get("id", null);
  return Boolean(
    accountId &&
      milestoneId &&
      loggedInUser?.$meta.exists("pinnedMilestoneNext", {accountId, milestoneId})
  );
};

export const setMsThemeColor = (ms) => {
  if (!ms) return msColorThemes.blue;
  const color = ms.$meta.get("color", "blue");
  return msColorThemes[color] || msColorThemes.blue;
};

/**
 * @type React.FC<{
 * theme: string
 * className?: string
 * milestoneState?: "past" | "current" | "next"
 * onDark?: boolean
 * size?: number
 * inline?: boolean
 * }>
 */
export const ThemedMilestoneIcon = forwardRef(
  ({theme, className, milestoneState, onDark, ...rest}, ref) => {
    switch (milestoneState) {
      case "past":
        return (
          <DSIconMilestone
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              milestoneIconStyle.past,
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
      case "next":
        return (
          <DSIconMilestoneNext
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
      default:
        return (
          <DSIconMilestone
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
    }
  }
);

import {forwardRef} from "react";
import cx from "../cx";
import {iconStyles} from "./icon.css";
import uiClasses from "../xui/ui.css";

import {ReactComponent as CheckSvg} from "./assets/check.svg";
import {ReactComponent as CloseSvg} from "./assets/close.svg";
import {ReactComponent as SortAscSvg} from "./assets/sort-asc.svg";
import {ReactComponent as SortDescSvg} from "./assets/sort-desc.svg";
import {ReactComponent as DoorEnterSvg} from "./assets/door-enter.svg";
import {ReactComponent as CompassSvg} from "./assets/compass.svg";
import {ReactComponent as CardNotInHandSvg} from "./assets/card-not-in-hand.svg";
import {ReactComponent as ClickTargetSvg} from "./assets/click-target.svg";
import {ReactComponent as GateClosedSvg} from "./assets/gate-closed.svg";
import {ReactComponent as GateOpenSvg} from "./assets/gate-open.svg";
import {ReactComponent as ChevronRightSvg} from "./assets/chevron-right.svg";
import {ReactComponent as RogueSvg} from "./assets/rogue.svg";
import {ReactComponent as ArrowCircleRightSvg} from "./assets/arrow-circle-right.svg";
import {ReactComponent as ArrowNarrowRightSvg} from "./assets/arrow-narrow-right.svg";
import {ReactComponent as ChecronDoubleRightSvg} from "./assets/chevron-double-right.svg";
// import {ReactComponent as WarningSvg} from "./assets/warning.svg";
import {ReactComponent as UsersSvg} from "./assets/users.svg";

import {ReactComponent as ProjectTilesSvg} from "./assets/project-tiles.svg";
import {ReactComponent as ProjectTilesSingleSvg} from "./assets/project-tiles-single.svg";

import {ReactComponent as ShareSvg} from "./assets/share.svg";

/**
 *
 * @param {*} SvgComp
 * @param {*} fallbackAlt
 * @returns {any}
 */
const makeComponent = (SvgComp, fallbackAlt) =>
  forwardRef(({className, size = "md", color, alt, strokeColor, ...rest}, ref) => (
    <div
      ref={ref}
      className={cx(
        iconStyles.container.base,
        iconStyles.container.bySize[size],
        color && uiClasses.color[color],
        strokeColor && iconStyles.container.byStrokeColor[strokeColor],
        className
      )}
      {...rest}
    >
      <SvgComp className={iconStyles.svg} alt={alt || fallbackAlt} />
    </div>
  ));

export const IconCardNotInHand = makeComponent(CardNotInHandSvg, "Card not in Hand");
export const IconCheck = makeComponent(CheckSvg, "Check");
export const IconClose = makeComponent(CloseSvg, "Close");
export const IconSortAsc = makeComponent(SortAscSvg, "Sort ascending");
export const IconSortDesc = makeComponent(SortDescSvg, "Sort descending");
export const IconDoorEnter = makeComponent(DoorEnterSvg, "Enter Door");
export const IconCompass = makeComponent(CompassSvg, "Compass");
export const IconClickTarget = makeComponent(ClickTargetSvg, "Click Target");
export const IconGateClosed = makeComponent(GateClosedSvg, "Closed Gate");
export const IconGateOpen = makeComponent(GateOpenSvg, "Open Gate");
export const IconChevronRight = makeComponent(ChevronRightSvg, "Chevron Right");
export const IconRogue = makeComponent(RogueSvg, "Inaccessible");
export const IconArrowCircleRight = makeComponent(ArrowCircleRightSvg, "Arrow Right");
export const IconArrowNarrowRight = makeComponent(ArrowNarrowRightSvg, "Arrow Right");
export const IconChecronDoubleRight = makeComponent(ChecronDoubleRightSvg, "Arrow Right");
export const IconProjectTiles = makeComponent(ProjectTilesSvg, "Projects");
export const IconProjectTilesSingle = makeComponent(ProjectTilesSingleSvg, "Single Project");
// export const IconWarning = makeComponent(WarningSvg, "Warning");
export const IconUsers = makeComponent(UsersSvg, "Users");
export const IconShare = makeComponent(ShareSvg, "Help");

export const cardHasDeps = (card) => {
  return (
    (card.$meta.modelName === "card" &&
      card.$meta.get("parentCard", null)?.$meta.get("hasBlockingDeps")) ||
    card.$meta.get("inDeps", [], {forceRelIds: true}).length > 0 ||
    card.$meta.get("outDeps", [], {forceRelIds: true}).length > 0
  );
};

export const getBlockingCards = (card) => {
  return card.inDeps.filter((c) => c.status !== "done" && c.visibility === "default");
};

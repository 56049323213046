import {unified} from "unified";
import markdown from "remark-parse";
import cdxGithubMarkdownPlugin from "../../../Markdown/plugins/cdxGithubMarkdownPlugin";
import {MdastRoot} from "mdast-util-to-hast/lib/handlers/root";
import {InlineCode} from "mdast-util-to-hast/lib/handlers/inline-code";
import {Code, Emphasis, Text} from "mdast";
import {Point, Parents, Nodes} from "mdast-util-to-markdown/lib/types";

type Node = Nodes;

export default function outputAstCompiler(this: any) {
  Object.assign(this, {Compiler: (root: any) => root});
}

export const textToMarkdownAST = (text: string): MdastRoot => {
  let processor = unified()
    .use(markdown)
    // .use(breaks) // "remark-breaks" loses position information
    .use(cdxGithubMarkdownPlugin)
    .use(outputAstCompiler);
  return processor.processSync(text).result as MdastRoot;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const prettyPrintMdLocation = (node: Nodes | Parents, indent = 0) => {
  const {position: p, type} = node;
  const pp = (pos: Point) => `(${pos.offset ?? ""}) ${pos.line}:${pos.column}`;
  const str = [
    `${" ".repeat(indent)}${pp(p?.start!)} - ${pp(p?.end!)} [${type}]${
      type === "text" || type === "inlineCode" ? ` '${node.value}'` : ""
    }`,
  ];
  if ("children" in node) {
    for (const child of node.children) {
      str.push(prettyPrintMdLocation(child, indent + 2));
    }
  }
  return str.join("\n");
};

type SelectionPosition = {start: number; end: number};

const getInnerNodesForSelection = (
  n: Node,
  selPos: SelectionPosition,
  nodes: Node[] = []
): Node[] => {
  const {position: p} = n;
  if (!p) return nodes;
  const {start, end} = selPos;
  if (p.end.offset! <= start) return nodes;
  if (p.start.offset! >= end) return nodes;
  nodes.push(n);
  if ("children" in n) {
    for (const child of n.children) getInnerNodesForSelection(child, selPos, nodes);
  }
  return nodes;
};

export type SelectionProps = {
  isBold: boolean;
  isItalic: boolean;
  isStrikeThrough: boolean;
  isCode: boolean;
  link: null | {url: string};
};

export const mdAstSelectionProps = (root: MdastRoot, selPos: SelectionPosition): SelectionProps => {
  const props: SelectionProps = {
    isBold: false,
    isItalic: false,
    isStrikeThrough: false,
    isCode: false,
    link: null,
  };
  for (const node of getInnerNodesForSelection(root, selPos)) {
    switch (node.type) {
      case "strong":
        props.isBold = true;
        break;
      case "emphasis":
        props.isItalic = true;
        break;
      case "delete":
        props.isStrikeThrough = true;
        break;
      case "inlineCode":
        props.isCode = true;
        break;
      case "link":
        if (!props.link) {
          props.link = {url: node.url};
        }
        break;
    }
  }
  return props;
};

const getNodesAtPosInside = (
  n: Node,
  pos: number,
  dir: "right" | "left",
  nodes: Node[] = []
): Node[] => {
  const {position: p} = n;
  if (!p) return nodes;
  const start = p.start.offset!;
  if (dir === "right" ? start > pos : start >= pos) return nodes;
  const end = p.end.offset!;
  if (dir === "left" ? end < pos : end <= pos) return nodes;
  nodes.push(n);
  if ("children" in n) {
    for (const child of n.children) getNodesAtPosInside(child, pos, dir, nodes);
  }
  return nodes;
};

const contentTypes = new Set(["text", "inlineCode", "code"]);
const blockTypes = new Set([
  "paragraph",
  "break",
  "code",
  "table",
  "heading",
  "list",
  "blockquote",
  "listItem",
]);
type ContentNode = Text | InlineCode | Code;

const leftSearchLeaf = (n: Node): ContentNode | null => {
  if ("children" in n) {
    const {children: list} = n;
    for (let i = 0; i < list.length; i += 1) {
      const res = leftSearchLeaf(list[i]);
      if (res) return res;
    }
    return null;
  } else {
    if (contentTypes.has(n.type)) {
      return n as ContentNode;
    } else {
      return null;
    }
  }
};

const findFirstBlockRightOfPos = (nodes: Node[], pos: number): ContentNode | null => {
  for (let i = nodes.length - 1; i >= 0; i += -1) {
    const n = nodes[i];
    if ("children" in n) {
      for (let i2 = 0; i2 < n.children.length; i2 += 1) {
        const child = n.children[i2];
        if (!child.position) continue;
        const {end} = child.position;
        if (end.offset! <= pos) continue;
        const textChild = leftSearchLeaf(child);
        if (textChild) return textChild;
      }
    }
  }
  return null;
};

const rightSearchLeaf = (n: Node): ContentNode | null => {
  if ("children" in n) {
    const {children: list} = n;
    for (let i = list.length - 1; i >= 0; i += -1) {
      const res = rightSearchLeaf(list[i]);
      if (res) return res;
    }
    return null;
  } else {
    if (contentTypes.has(n.type)) {
      return n as ContentNode;
    } else {
      return null;
    }
  }
};

const findFirstBlockLeftOfPos = (nodes: Node[], pos: number): ContentNode | null => {
  for (let i = nodes.length - 1; i >= 0; i += -1) {
    const n = nodes[i];
    if ("children" in n) {
      for (let i2 = n.children.length - 1; i2 >= 0; i2 += -1) {
        const child = n.children[i2];
        if (!child.position) continue;
        const {end} = child.position;
        if (end.offset! > pos) continue;
        const textChild = rightSearchLeaf(child);
        if (textChild) return textChild;
      }
    }
  }
  return null;
};

const findFirstTextNode = (
  r: MdastRoot,
  pos: number,
  dir: "right" | "left"
): null | [ContentNode, number] => {
  const nodes = getNodesAtPosInside(r, pos, dir);
  if (nodes.length === 0) nodes.push(r);
  const lastNode = nodes[nodes.length - 1];
  if (contentTypes.has(lastNode.type)) {
    return [lastNode as ContentNode, pos];
  } else {
    if (dir === "right") {
      const n = findFirstBlockRightOfPos(nodes, pos);
      if (n && n.position) return [n, n.position.start.offset!];
    } else {
      const n = findFirstBlockLeftOfPos(nodes, pos);
      if (n && n.position) {
        return [n, n.position.end.offset!];
      }
    }
  }

  return null;
};

const findFirstSharedParent = (parent: Node, left: Node, right: Node): null | Node => {
  if (left === right) return left;
  if (!("children" in parent)) return null;
  const lp = left.position!.end;
  const rp = right.position!.start;
  for (let c of parent.children) {
    if (!c.position) continue;
    const {start, end} = c.position;
    if (start.line > lp.line) continue;
    if (start.line === lp.line && start.column >= lp.column) continue;
    if (end.line < rp.line) continue;
    if (end.line === rp.line && end.column <= rp.column) continue;
    const res = findFirstSharedParent(c, left, right);
    if (res) return res;
  }
  return parent;
};

const getLeafBlocks = (n: Node): null | Node[] => {
  if (!blockTypes.has(n.type) && n.type !== "root") return null;
  if (!("children" in n)) return null;
  let someChildIsBlockParent = false;
  const children: Node[] = [];
  for (const child of n.children) {
    const childBlocks = getLeafBlocks(child);
    if (childBlocks) {
      someChildIsBlockParent = true;
      children.push(...childBlocks);
    } else {
      children.push(child);
    }
  }
  return someChildIsBlockParent ? children : [n];
};

const getLeafStart = (node: Node): Point => {
  if (!("children" in node) || node.children.length === 0) return node.position!.start;
  return getLeafStart(node.children[0]);
};
const getLeafEnd = (node: Node): Point => {
  if (!("children" in node) || node.children.length === 0) return node.position!.end;
  return getLeafEnd(node.children[node.children.length - 1]);
};

export type MdOp =
  | {
      type: "add";
      start: number;
      end: number;
    }
  | {type: "del"; start: number; len: number}
  | {type: "replace"; start: number; oldLen: number; newStr: string};

const getOps = (node: Node, selStart: number, selEnd: number, ops: MdOp[]): void => {
  if (!node.position) return;
  const {start, end} = node.position;
  if (start.offset! >= selEnd) return;
  if (end.offset! < selStart) return;
  const leafStart = getLeafStart(node).offset!;
  const leafEnd = getLeafEnd(node).offset!;
  if (selStart <= leafStart && selEnd >= leafEnd) {
    if ("children" in node) {
      const firstChildStart = node.children[0].position!.start.offset!;
      const lastChildEnd = node.children[node.children.length - 1].position!.end.offset!;
      ops.push({type: "add", start: firstChildStart, end: lastChildEnd});
    } else {
      ops.push({type: "add", start: start.offset!, end: end.offset!});
    }
  } else {
    // partial
    if ("children" in node) {
      for (const child of node.children) {
        getOps(child, selStart, selEnd, ops);
      }
    } else {
      if (!("value" in node) || !node.position) return;
      /* todo:
      `a|hi _the|re_`
      should become
      `a>>hi _the_<<_re_`
      (i.e. split already formated text)

      `a|hi [the|re](https://bla)`
      should stay
      `a>>hi <<[>>the<<re](https://bla)` though
      */

      ops.push({
        type: "add",
        start: Math.max(start.offset!, selStart),
        end: Math.min(end.offset!, selEnd),
      });
    }
  }
};

const rTrim = (t: [n: ContentNode, pos: number]) => {
  const [n, pos] = t;
  if (n.type === "code") return; // indent at start messes up calculation
  for (let i = pos - 1; i >= 0; i += -1) {
    if (n.value[i] !== " ") break;
    t[1] = i;
  }
};

const lTrim = (t: [n: ContentNode, pos: number]) => {
  const [n, pos] = t;
  if (n.type === "code") return; // indent at start messes up calculation
  for (let i = pos - n.position!.start.offset!; i < n.value.length; i += 1) {
    if (n.value[i] !== " ") break;
    t[1] = i + 1;
  }
};

export const addMarkdownFormat = (root: MdastRoot, selPos: SelectionPosition): MdOp[] => {
  const ops: MdOp[] = [];
  // console.log(prettyPrintMdLocation(root));
  // console.log(JSON.stringify(root, null, 2));
  const startTextNode = findFirstTextNode(root, selPos.start, "right");
  const endTextNode = findFirstTextNode(root, selPos.end, "left");
  if (!startTextNode || !endTextNode) return ops;
  lTrim(startTextNode);
  rTrim(endTextNode);
  if (startTextNode[1] > endTextNode[1]) return ops;
  // console.log(startTextNode, "to", endTextNode);
  const parent = findFirstSharedParent(root, startTextNode[0], endTextNode[0]);
  if (!parent) return ops;
  const leafBlocks = getLeafBlocks(parent) || [parent];
  // console.log("leaves", leafBlocks.map(prettyPrintMdLocation).join("\n"));
  for (const block of leafBlocks) {
    getOps(block, startTextNode[1], endTextNode[1], ops);
  }
  return ops;
};

export type DeletableTypes = "strong" | "emphasis" | "delete" | "link" | "inlineCode";

export const removeMarkdownFormat = (
  node: Node,
  selPos: SelectionPosition,
  type: DeletableTypes,
  ops: MdOp[] = []
): MdOp[] => {
  // if (node.type === "root") console.log(prettyPrintMdLocation(node));
  if (!node.position) return ops;
  if (node.position.end.offset! < selPos.start) return ops;
  if (node.position.start.offset! > selPos.end) return ops;
  if (type === node.type) {
    const myStart = node.position.start.offset!;
    const myEnd = node.position.end.offset!;
    if (node.type === "inlineCode") {
      ops.push({type: "del", start: myStart, len: 1});
      ops.push({type: "del", start: myEnd - 1, len: 1});
    } else {
      const delNode = node as Emphasis;

      if (delNode.children.length === 0) {
        ops.push({type: "del", start: myStart, len: myEnd - myStart});
      } else {
        const firstChildStart = delNode.children[0].position!.start.offset!;
        ops.push({type: "del", start: myStart, len: firstChildStart - myStart});

        const lastChildEnd = delNode.children[delNode.children.length - 1].position!.end.offset!;
        ops.push({type: "del", start: lastChildEnd, len: myEnd - lastChildEnd});
      }
    }
  } else if ("children" in node) {
    for (const child of node.children) {
      removeMarkdownFormat(child, selPos, type, ops);
    }
  }
  return ops;
};

export const replaceMarkdownLinkUrl = (
  node: Node,
  selPos: SelectionPosition,
  oldLinkUrl: string,
  newUrl: string,
  ops: MdOp[] = []
): MdOp[] => {
  // if (node.type === "root") console.log(prettyPrintMdLocation(node));
  if (!node.position) return ops;
  if (node.position.end.offset! < selPos.start) return ops;
  if (node.position.start.offset! > selPos.end) return ops;
  if (node.type === "link") {
    if (node.url !== oldLinkUrl) return ops;
    if (node.children.length === 0) {
      const myStart = node.position.start.offset!;
      ops.push({type: "replace", start: myStart + 3, oldLen: oldLinkUrl.length, newStr: newUrl});
    } else {
      const lastChildEnd = node.children[node.children.length - 1].position!.end.offset!;
      ops.push({
        type: "replace",
        start: lastChildEnd + 2,
        oldLen: oldLinkUrl.length,
        newStr: newUrl,
      });
    }
  } else if ("children" in node) {
    for (const child of node.children) {
      replaceMarkdownLinkUrl(child, selPos, oldLinkUrl, newUrl, ops);
    }
  }
  return ops;
};

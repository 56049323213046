import {setAttacherOptions} from "./plugin-helpers";

const prefix = Array.from("[userId:").map((l) => l.charCodeAt(0));

const tokenizer = (effects, ok, nok) => {
  let i = 0;
  const startAt = (code) => {
    effects.enter("cdxMention");
    effects.enter("cdxMentionMarker");
    effects.consume(code);
    return checkPrefix;
  };
  const checkPrefix = (code) => {
    if (i === prefix.length) {
      effects.exit("cdxMentionMarker");
      effects.enter("cdxMentionId");
      return idParser(code);
    } else if (code === prefix[i]) {
      i += 1;
      effects.consume(code);
      return checkPrefix;
    } else {
      nok(code);
    }
    return nok(code);
  };
  const idParser = (code) => {
    if (code === -5 || code === -4 || code === -3 || code === null || code === 91) {
      return nok(code);
    } else if (code === 93) {
      effects.exit("cdxMentionId");
      effects.enter("cdxMentionMarker");
      effects.consume(code);
      effects.exit("cdxMentionMarker");
      effects.exit("cdxMention");
      return ok;
    } else {
      effects.consume(code);
      return idParser;
    }
  };
  return startAt;
};

export const cdxMentionMicromarkExtension = {
  text: {
    // "@".charCodeAt(0) -> 64
    64: {
      name: "cdxMention",
      tokenize: tokenizer,
    },
  },
};

export const cdxMentionFromMarkdown = {
  enter: {
    cdxMentionId: function (token) {
      this.enter({type: "cdxMention", mentionId: this.sliceSerialize(token)}, token);
    },
  },
  exit: {
    cdxMentionId: function (token) {
      this.exit(token);
    },
  },
};

export default function cdxMentionMarkdownPlugin({render} = {}) {
  const data = this.data();
  (data.micromarkExtensions = data.micromarkExtensions || []).push(cdxMentionMicromarkExtension);
  (data.fromMarkdownExtensions = data.fromMarkdownExtensions || []).push(cdxMentionFromMarkdown);

  setAttacherOptions(this, "remarkRehype", (opts) => ({
    ...opts,
    handlers: {
      ...opts.handlers,
      cdxMention: (state, node) => ({
        type: "element",
        tagName: "cdxMention",
        properties: {mentionId: node.mentionId},
        children: state.all(node),
      }),
    },
  }));

  if (render) {
    const compilers = ["cdxPreviewCompiler", "cdxTextCompiler", "rehypeReact"];
    compilers.forEach((name) =>
      setAttacherOptions(this, name, (opts) => ({
        ...opts,
        components: {
          ...opts.components,
          cdxMention: ({mentionId}) => render(mentionId),
        },
      }))
    );
  }
}

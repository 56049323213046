import CdxTypeaheadPlugin, {
  CdxTypeaheadGroupInfo,
  createNodeHandler,
} from "./CdxTypeAheadLexicalPlugin";
import {$createTextNode} from "lexical";
import {Box} from "@cdx/ds";
import {waitForResultPromise} from "../../../lib/wait-for-result";
import {api} from "../../../lib/api";
import {personalTags, tagObjectsForUser} from "../../../lib/utils";
import {PersonalTag as RawPersonalTag, ProjectTag as RawProjectTag} from "../../Markdown/CardTags";
import {
  addTextTransformer,
  useSlashCommand,
} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";

const PersonalTag = RawPersonalTag as any;
const ProjectTag = RawProjectTag as any;

type TagObject =
  | {type: "personal"; tagObject: {tag: string}}
  | {type: "project"; tagObject: {tag: string; color: string | null; emoji: string | null}};

const Option = ({tag: {type, tagObject}}: {tag: TagObject}) => (
  <Box color="primary" bold size={14}>
    {type === "personal" ? (
      <PersonalTag tag={tagObject.tag} />
    ) : (
      <ProjectTag tagObject={tagObject} />
    )}
  </Box>
);

const getOptions =
  (projectId: any) =>
  async (word: string): Promise<TagObject[]> =>
    waitForResultPromise(() => {
      const {loggedInUser, account} = api.getRoot();
      const project = api.getModel({modelName: "project", id: projectId} as any);
      // deck-less card, only current user has access
      const tags = projectId
        ? tagObjectsForUser(loggedInUser, [project], account)
        : personalTags(loggedInUser, account).map((t) => ({type: "personal", tagObject: t}));
      return tags.filter(
        (t) => !word || t.tagObject.tag.toLowerCase().indexOf(word.toLowerCase()) >= 0
      );
    });

const groups: {[key: string]: CdxTypeaheadGroupInfo} = {
  project: {label: "Project Tags", prio: 1},
  personal: {label: "Private Tags", prio: 5},
};

type TagPluginProps = {project: any};
const TagPlugin = ({project}: TagPluginProps) => {
  useSlashCommand({
    key: `tag`,
    label: `Add Tag`,
    keywords: "tag project #",
    handleSelect: addTextTransformer("#", ""),
    groupKey: "references",
  });

  return (
    <CdxTypeaheadPlugin<TagObject>
      triggerFn={(text: string) => {
        const m = text.match(/(\s|\(|^)(#)([A-Za-z0-9][\w\-.]*)?$/);
        if (!m) return null;
        const offset = m.index! + m[1].length;
        return {
          offset,
          match: m[3],
          triggerChar: m[2],
        };
      }}
      getOptions={getOptions(project?.$meta.get("id", null))}
      handleOptionSelect={createNodeHandler((opt) => $createTextNode(`#${opt.tagObject.tag}`))}
      optionToKey={(opt) => `${opt.type}:${opt.tagObject.tag}`}
      renderOption={(opt) => <Option tag={opt} />}
      debounceMs={0}
      groupInfo={{
        getGroup: (key) => groups[key] || {label: "Other", prio: 99},
        getKey: (opt) => opt.type,
      }}
    />
  );
};

export default TagPlugin;

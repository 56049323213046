import {forwardRef} from "react";
import cx from "../../utils/cx";
import {iconStyles} from "../DSIcon/DSIcon.css";

import {ReactComponent as Svg} from "./assets/effort_icon_banner.svg";
import {effortIconBannerStyles as styles} from "./DSMiniCard.css";

const DSEffortIconBanner = forwardRef<SVGSVGElement, {isDoc?: boolean}>(({isDoc, ...rest}, ref) => (
  <Svg
    ref={ref}
    className={cx(
      styles.base,
      isDoc ? styles.types.doc : styles.types.task,
      iconStyles.mode.block,
      iconStyles.base
    )}
    {...rest}
  />
));
export default DSEffortIconBanner;

import {create} from "zustand";
import {CardOrder} from "../features/card-order/useCardOrder";

/**
 *
 * Purpose
 * =======
 * React sometimes makes it difficult to infer a user's intention due to it's `view = f(state)` nature.
 * Sometimes the effects that cause a state to change are important but they are hard to track.
 *
 * This library tries to keep track of the last intention. Example: opening the conversation side bar. If there's
 * no conversation already, the user probably wants to create a new one. Only then open the "new thread" section
 * and autofocus the input.
 *
 * Any later key or mouse event removes the latest intention.
 *
 */

export type Intention = {type: "setOrder"; payload: CardOrder};

type IntentionVal = {time: number; intention: Intention};

const useIntentionStore = create<{
  intentionVal: IntentionVal | null;
  setIntentionVal: (intentionVal: IntentionVal | null) => void;
}>((set) => ({
  intentionVal: null,
  setIntentionVal: (intentionVal: any) => set({intentionVal}),
}));

export const addIntention = (intention: Intention) => {
  const nextIntention: IntentionVal = {time: new Date().getTime(), intention};
  useIntentionStore.getState().setIntentionVal(nextIntention);
  setTimeout(() => {
    if (useIntentionStore.getState().intentionVal === nextIntention) {
      useIntentionStore.getState().setIntentionVal(null);
    }
  }, 2000);
};

const resetIntention = () => {
  const latest = useIntentionStore.getState().intentionVal;
  if (latest && latest.time + 25 < new Date().getTime()) {
    useIntentionStore.getState().setIntentionVal(null);
  }
};

window.addEventListener("keydown", resetIntention);
window.addEventListener("mousedown", resetIntention);
window.addEventListener("pointerdown", resetIntention);

export const getLastIntention = () => useIntentionStore.getState().intentionVal?.intention;
export const useLastIntention = (type: Intention["type"]) =>
  useIntentionStore((s) =>
    s.intentionVal?.intention.type === type ? s.intentionVal?.intention : null
  );

import {Changes} from "./cache";

let nextMutationId = 1;

/**
 * @returns {Record<string, Record<string, (data: any) => Promise<any>>>}
 */
export default function createMutator(mutations, dispatcher, cache, registerOnChangeListener) {
  const instance = {};
  Object.keys(mutations).forEach((fullActionName) => {
    const mutationDescription = mutations[fullActionName];
    const [actionNamespace, actionName] = fullActionName.split(".");
    instance[actionNamespace] = instance[actionNamespace] || {};
    instance[actionNamespace][actionName] = (data = {}) =>
      new Promise((resolve, reject) => {
        const id = nextMutationId;
        nextMutationId += 1;
        cache.addOptimisticAction(id, mutationDescription, data);
        dispatcher(`${actionNamespace}/${actionName}`, data, (err, retVal) => {
          if (err) {
            cache.failedOptimisticAction(id);
            reject(err);
          } else {
            const changes = new Changes();
            // first invalidate so that it'll be able to access old values (e.g. move from old deck to new deck)
            cache.invalidate(mutationDescription, data, retVal, false, changes);

            // optimistic actions might directly set values, so lets do it after invalidation
            cache.resolveOptimisticAction(id, mutationDescription, data, retVal, changes);
            // we wait until the changes requested by cache.invalidate get actually triggered
            // this avoids being in an inbetween-state
            const unsub = registerOnChangeListener(() => {
              unsub();
              resolve(retVal);
            });
          }
        });
      });
  });

  instance.$descriptions = mutations;

  return instance;
}

const getIdAsKey = (id) => {
  return Array.isArray(id) ? (id.length === 1 ? id[0] : JSON.stringify(id)) : id;
};

const isDev = process.env.NODE_ENV !== "production";

export default function createPool({
  cache,
  descriptions,
  collect: rawCollect,
  registerOnChangeListener,
  getApiChangeIndex,
  notLoadedEvent,
}) {
  let loadedInstances = {};
  let instancesWithoutKnownId = {};
  let rootInstance = null;
  let references = {};

  const registerQueryInCache = ({parentModel, relName, query, targetModelDesc}) => {
    // account.$meta.find("cards", {effort: 2})
    // targetModelDesc: "card"
    // parentModel: "account" + relName

    Object.entries(query).forEach(([key, val]) => {
      if (key[0] === "$") {
        if (key === "$or" || key === "$and") {
          val.forEach((q) =>
            registerQueryInCache({parentModel, relName, query: q, targetModelDesc})
          );
        } else if (key === "$order") {
          const fields = Array.isArray(val) ? val : [val];
          fields.forEach((rawField) => {
            const field = rawField[0] === "-" ? rawField.slice(1) : rawField;
            return cache.addConstraintBasedRelationField({
              parentModel,
              relName,
              targetModel: targetModelDesc.name,
              field,
            });
          });
        }
      } else {
        if (key[0] === "!") key = key.slice(1);
        const hasManyDesc = targetModelDesc.hasMany[key];
        if (
          targetModelDesc.fields[key] ||
          targetModelDesc.fkToBelongsTo[key] ||
          (hasManyDesc && hasManyDesc.fkAsArray)
        ) {
          return cache.addConstraintBasedRelationField({
            parentModel,
            relName,
            targetModel: targetModelDesc.name,
            field: key,
          });
        }
        if (hasManyDesc) {
          cache.addConstraintBasedRelationRelation({
            parentModel,
            relName,
            targetModel: hasManyDesc.model,
            fk: hasManyDesc.fk,
          });
          if (isDev && hasManyDesc.deps.length) {
            throw new Error(
              `'modern' queries may not rely on relations with deps, specify the deps in the query instead! (rel: ${targetModelDesc.name}'s ${key})`
            );
          }
          if (isDev && hasManyDesc.via) {
            throw new Error(
              `'modern' queries may not rely on relations with via, specify the via in the query instead! (rel: ${targetModelDesc.name}'s ${key})`
            );
          }
          return registerQueryInCache({
            parentModel,
            relName,
            query: val,
            targetModelDesc: descriptions[hasManyDesc.model],
          });
        }
        const belongsToDesc = targetModelDesc.belongsTo[key];
        if (belongsToDesc) {
          cache.addConstraintBasedRelationField({
            parentModel: parentModel,
            relName,
            targetModel: targetModelDesc.name,
            field: belongsToDesc.fk,
          });
          return registerQueryInCache({
            parentModel,
            relName,
            query: val,
            targetModelDesc: descriptions[belongsToDesc.model],
          });
        }
      }
    });
  };

  const createQueryStringFromQuery = ({modelDesc, query, relName, prefix = ""}) => {
    const sortedQueryKeys = Object.keys(query).sort();
    const hasManyDesc = modelDesc.hasMany[relName];
    if (isDev) {
      if (!hasManyDesc) throw new Error(`Unknown rel '${relName}' for '${modelDesc.name}'`);
    }
    const targetModelDesc = descriptions[hasManyDesc.model];

    const queryObj = sortedQueryKeys.reduce((memo, key) => {
      const entry = query[key];
      if (entry && entry.$meta)
        throw new Error(
          "Does not support passing instance as constraint anymore, please use its id instead!"
        );
      memo[key] = entry;
      return memo;
    }, {});

    const queryString = queryObj ? JSON.stringify(queryObj) : null;
    const fullRelName = `${prefix}${relName}(${queryString})`;
    if (queryString && hasManyDesc.model !== "$deprecated") {
      registerQueryInCache({
        parentModel: modelDesc.name,
        relName: fullRelName,
        query,
        targetModelDesc,
      });
    }

    return {isSingleton: hasManyDesc.isSingleton, fullRelName};
  };

  const createMetaMethods = (getRelation, getField, modelDesc) => {
    const methods = {
      find(relName, query) {
        const hasManyDesc = modelDesc.hasMany[relName];
        if (query) {
          const {fullRelName} = createQueryStringFromQuery({modelDesc, query, relName});
          return getRelation(fullRelName, hasManyDesc.isSingleton, hasManyDesc.model).get();
        } else {
          return getRelation(relName, hasManyDesc.isSingleton, hasManyDesc.model).get();
        }
      },
    };
    [
      {name: "exists", defaultValue: false},
      {name: "count", defaultValue: 0},
    ].forEach(({name, defaultValue}) => {
      methods[name] = (relName, query = null, userDefault) => {
        if (query && Object.keys(query).length) {
          const {fullRelName} = createQueryStringFromQuery({
            modelDesc,
            query,
            relName,
            prefix: `${name}:`,
          });
          return getField(fullRelName, userDefault !== undefined ? userDefault : defaultValue);
        } else {
          return getField(
            `${name}:${relName}`,
            userDefault !== undefined ? userDefault : defaultValue
          );
        }
      };
    });
    [{name: "first"}].forEach(({name}) => {
      methods[name] = (relName, query) => {
        if (!query || !query.$order) {
          throw new Error("you need to pass `$order` when asking for first!");
        }
        if (query.$limit) {
          throw new Error("don't pass `$limit` when asking for first!");
        }
        const {fullRelName} = createQueryStringFromQuery({
          modelDesc,
          query: {...query, $first: true},
          relName,
        });
        return getRelation(fullRelName, true, modelDesc.hasMany[relName].model).get();
      };
    });
    [{name: "firstN"}].forEach(({name}) => {
      methods[name] = (n, relName, query) => {
        if (!query || !query.$order) {
          throw new Error("you need to pass `$order` when asking for firstN!");
        }
        const {fullRelName} = createQueryStringFromQuery({
          modelDesc,
          query: {...query, $limit: n},
          relName,
        });
        return getRelation(fullRelName, false, modelDesc.hasMany[relName].model).get();
      };
    });
    methods.idProps = modelDesc.idPropAsArray;

    return methods;
  };

  const pool = {
    invalidateInstances(index, initialChanges) {
      if (initialChanges === "all") {
        loadedInstances = {};
        instancesWithoutKnownId = {};
        rootInstance = null;
        references = {};
      } else {
        const invalidated = [];
        const alreadyUpdated = new Set();
        const {updated, touched} = initialChanges;
        if (touched) {
          Object.keys(touched).forEach((modelName) => {
            touched[modelName].forEach((id) => {
              const idAsKey = getIdAsKey(id);
              const inst = loadedInstances[modelName] && loadedInstances[modelName][idAsKey];
              if (inst) inst.$meta._clearCache();
            });
          });
        }
        let nextChanges = updated;
        let hasMoreChanges = true;
        rootInstance = null;
        instancesWithoutKnownId = {};
        const update = (changes) => {
          Object.keys(changes || {}).forEach((modelName) => {
            if (modelName !== "_root") {
              changes[modelName].forEach((id) => {
                const idAsKey = getIdAsKey(id);
                const key = `${modelName}:${idAsKey}`;
                if (alreadyUpdated.has(key)) return;
                alreadyUpdated.add(key);
                if (loadedInstances[modelName]) {
                  if (isDev && loadedInstances[modelName][idAsKey]) invalidated.push(key);
                  delete loadedInstances[modelName][idAsKey];
                }
                const refs = references[key];
                if (refs) {
                  refs.forEach(([relModel, relId]) => {
                    hasMoreChanges = true;
                    (nextChanges[relModel] = nextChanges[relModel] || new Set()).add(relId);
                  });
                }
              });
            }
          });
        };
        while (hasMoreChanges) {
          const currentChanges = nextChanges;
          nextChanges = {};
          hasMoreChanges = false;
          update(currentChanges);
        }
        // if (isDev) console.log(`invalidated [${invalidated.join(", ")}]`);
      }
    },
    /**
     * @returns {import("../../cdx-models/Root").Root}
     */
    getRoot() {
      if (rootInstance) return rootInstance;
      rootInstance = pool.createInstanceViaId({modelName: "_root", id: null});
      return rootInstance;
    },
    createInstanceViaId({modelName, id, isOptimistic}) {
      if (cache.isDeleted(modelName, id)) {
        if (isDev)
          console.warn(
            `trying to load instance of ${modelName}(${id}) which is deleted. Returning null.`
          );
        return null;
      }
      const idKey = getIdAsKey(id);
      if (isDev && !descriptions[modelName]) {
        throw new Error(`passed unknown modelName: ${modelName} (id: ${idKey})`);
      }
      const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];
      const path = [{modelName, id, isOptimistic}];

      const instance = {};
      const props = {};
      let cachedRelations = {};

      Object.keys(fields).forEach((field) => {
        const fieldDesc = fields[field];
        const initialCacheVal = cache.getAndTellIfLoaded({
          modelName,
          id: idKey,
          field,
          collectIfMissing: false,
        });
        if (initialCacheVal.isLoaded) {
          instance[field] = initialCacheVal.val;
          cachedRelations[field] = initialCacheVal;
        } else {
          props[field] = {
            enumerable: true,
            get() {
              const existing = cachedRelations[field];
              if (existing) {
                if (!existing.isLoaded) notLoadedEvent(existing.status);
                return existing.useDefaultVal ? fieldDesc.defaultValue : existing.val;
              }
              const cacheVal = cache.getAndTellIfLoaded({
                modelName,
                id: idKey,
                field,
                collectIfMissing: !isOptimistic && "field",
              });
              if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
              cachedRelations[field] = cacheVal;
              return cacheVal.useDefaultVal ? fieldDesc.defaultValue : cacheVal.val;
            },
          };
        }
      });

      const getRelation = (relName, isSingleton, relModel) => {
        return {
          enumerable: false,
          get: () => {
            const exist = cachedRelations[relName];
            if (exist !== undefined && exist.isLoaded) {
              return exist.val;
            } else {
              if (relModel === "$deprecated") return isSingleton ? null : [];
              const cacheVal = cache.getAndTellIfLoaded({
                modelName,
                id: idKey,
                field: relName,
                collectIfMissing: !isOptimistic && "hasMany",
              });
              if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
              const {val: relIds} = cacheVal;
              const thisInstanceKey = [modelName, id];
              let val;
              if (relIds !== undefined) {
                if (isSingleton) {
                  const otherInstanceKey = `${relModel}:${getIdAsKey(relIds)}`;
                  if (modelName !== "_root")
                    (references[otherInstanceKey] = references[otherInstanceKey] || []).push(
                      thisInstanceKey
                    );
                  val =
                    relIds &&
                    pool.getInstanceViaId({
                      modelName: relModel,
                      id: relIds,
                      isOptimistic: cacheVal.status === "optimistic",
                    });
                } else {
                  val = relIds
                    ? relIds
                        .map((relId) => {
                          const otherInstanceKey = `${relModel}:${getIdAsKey(relId)}`;
                          // references["card-123"] = [[project, 4], [deck, 12]]
                          // TODO: ensure that it won't turn into 'references["card-123"] = [[project, 4], [project, 4], [project, 4]]''
                          if (modelName !== "_root")
                            (references[otherInstanceKey] =
                              references[otherInstanceKey] || []).push(thisInstanceKey);
                          return pool.getInstanceViaId({
                            modelName: relModel,
                            id: relId,
                            isOptimistic: cacheVal.status === "optimistic",
                          });
                        })
                        .filter(Boolean)
                    : [];
                }
              } else {
                const relInstance = pool.getInstanceViaPath({
                  modelName: relModel,
                  path: [...path, relName],
                });
                val = isSingleton ? relInstance : [relInstance];
              }
              cachedRelations[relName] = {...cacheVal, val};
              return val;
            }
          },
        };
      };

      Object.keys(hasMany).forEach((relName) => {
        const {isSingleton, model} = hasMany[relName];
        props[relName] = getRelation(relName, isSingleton, model);
      });

      Object.keys(belongsTo).forEach((relName) => {
        const {model} = belongsTo[relName];
        props[relName] = getRelation(relName, true, model);
      });

      if (Object.keys(props).length) Object.defineProperties(instance, props);

      if (idPropAsArray.length === 1 && idProp !== "id") instance.id = instance[idProp];

      const getField = (fieldName, defaultValue, {forceRelIds = false} = {}) => {
        const cacheKey = forceRelIds ? `$raw$${fieldName}` : fieldName;
        const existing = cachedRelations[cacheKey];
        if (existing !== undefined) {
          if (!existing.isLoaded) notLoadedEvent(existing.status);
          return existing.useDefaultVal ? defaultValue : existing.val;
        } else {
          const cacheVal = cache.getAndTellIfLoaded({
            modelName,
            id: idKey,
            field: fieldName,
            collectIfMissing: !isOptimistic && (forceRelIds ? "hasMany" : "field"),
          });
          if (!cacheVal.isLoaded) notLoadedEvent(cacheVal.status);
          cachedRelations[cacheKey] = cacheVal;
          return cacheVal.useDefaultVal ? defaultValue : cacheVal.val;
        }
      };

      instance.$meta = {
        isLoaded: true,
        modelName,
        changeIndex: getApiChangeIndex(),
        isFieldLoaded(fieldName, collectIfNotPresent) {
          // returns false if not loaded or invalid
          const {status} = cache.getAndTellIfLoaded({
            modelName,
            id: idKey,
            field: fieldName,
            collectIfMissing: !isOptimistic && collectIfNotPresent ? "field" : false,
          });
          return status === "loaded" || status === "deleted";
        },
        isFieldPresent(fieldName, collectIfNotPresent) {
          const {val} = cache.getAndTellIfLoaded({
            modelName,
            id: idKey,
            field: fieldName,
            collectIfMissing: !isOptimistic && collectIfNotPresent ? "field" : false,
          });
          return val !== undefined;
        },
        get(fieldName, defaultValue, {forceRelIds = false} = {}) {
          if (fields[fieldName] || forceRelIds) {
            return getField(fieldName, defaultValue, {forceRelIds});
          }
          const val = instance[fieldName];
          return val && val.$meta && !val.$meta.isLoaded ? defaultValue : val;
        },
        isDeleted() {
          return cache.isDeleted(modelName, id);
        },
        isFieldDeleted(fieldName) {
          const {status} = cache.getAndTellIfLoaded({
            modelName,
            id: idKey,
            field: fieldName,
            collectIfMissing: false,
          });
          return status === "deleted";
        },
        _clearCache() {
          cachedRelations = {};
        },
        ...createMetaMethods(getRelation, getField, descriptions[modelName]),
      };

      return instance;
    },

    /**
     *
     * @param {opts: {
     *  modelName: string,
     *  id: string | null,
     *  isOptimistic?: boolean,
     * }} opts
     * @returns
     */
    getInstanceViaId({modelName, id, isOptimistic}) {
      if (!id || id === "unknown") {
        // for cases like api.getModel({modelName: "x", id: something.id})
        return null;
      }
      loadedInstances[modelName] = loadedInstances[modelName] || {};
      const idAsKey = getIdAsKey(id);
      if (loadedInstances[modelName][idAsKey] !== undefined) {
        return loadedInstances[modelName][idAsKey];
      }

      const newInstance = pool.createInstanceViaId({modelName, id, isOptimistic});
      loadedInstances[modelName][idAsKey] = newInstance;
      return newInstance;
    },

    createInstanceViaPath({modelName, path}) {
      const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];

      const instance = {};
      const props = {};
      const cachedRelations = {};
      const collectedRelations = {};

      const collect = (p, fieldName, type) => {
        rawCollect(p, fieldName, type);
        collectedRelations[fieldName] = true;
      };

      const getField = (fieldName, defaultValue) => {
        if (collectedRelations[fieldName]) {
          notLoadedEvent("loading");
        } else {
          collect(path, fieldName, "field");
        }
        return defaultValue;
      };

      Object.keys(fields).forEach((field) => {
        const fieldDesc = fields[field];
        props[field] = {
          enumerable: true,
          get() {
            return getField(field, fieldDesc.defaultValue);
          },
        };
      });

      if (idPropAsArray.length === 1 && idProp !== "id") {
        props.id = {
          enumerable: true,
          get() {
            return getField(idProp, fields[idProp].defaultValue);
          },
        };
      }

      const getRelation = (relName, isSingleton, relModel) => ({
        enumerable: false,
        get() {
          const exist = cachedRelations[relName];
          if (exist) {
            notLoadedEvent("loading");
            return exist;
          } else {
            if (relModel === "$deprecated") return isSingleton ? null : [];
            collect(path, relName, "hasMany");
            const relInstance = pool.getInstanceViaPath({
              modelName: relModel,
              path: [...path, relName],
            });
            const retVal = isSingleton ? relInstance : [relInstance];
            cachedRelations[relName] = retVal;
            return retVal;
          }
        },
      });

      Object.keys(hasMany).forEach((relName) => {
        const {isSingleton, model} = hasMany[relName];
        props[relName] = getRelation(relName, isSingleton, model);
      });

      Object.keys(belongsTo).forEach((relName) => {
        const {model} = belongsTo[relName];
        props[relName] = getRelation(relName, true, model);
      });

      instance.$meta = {
        isLoaded: false,
        modelName,
        changeIndex: getApiChangeIndex(),
        isFieldLoaded(fieldName, collectIfNotPresent) {
          if (collectIfNotPresent && !collectedRelations[fieldName]) {
            collect(path, fieldName, "field");
          }
          return false;
        },
        isFieldPresent(fieldName, collectIfNotPresent) {
          if (collectIfNotPresent && !collectedRelations[fieldName]) {
            collect(path, fieldName, "field");
          }
          return false;
        },
        get(fieldName, defaultValue, {forceRelIds = false} = {}) {
          const exist = cachedRelations[fieldName];
          if (exist) {
            notLoadedEvent("loading");
            return forceRelIds ? [] : exist;
          }
          if (fields[fieldName]) {
            return getField(fieldName, defaultValue);
          } else {
            const existAsRel = collectedRelations[fieldName];
            if (existAsRel) {
              notLoadedEvent("loading");
            } else {
              collect(path, fieldName, "hasMany");
            }
            return defaultValue;
          }
        },
        isDeleted() {
          return false;
        },
        isFieldDeleted() {
          return false;
        },
        ...createMetaMethods(getRelation, getField, descriptions[modelName]),
      };

      Object.defineProperties(instance, props);

      return instance;
    },

    getInstanceViaPath({modelName, path}) {
      instancesWithoutKnownId[modelName] = instancesWithoutKnownId[modelName] || {};

      const pathAsKey = path
        .reduce((m, p) => {
          switch (p.modelName) {
            case undefined:
              m.push(p);
              break;
            case "_root":
              m.push(p.modelName);
              break;
            default:
              m.push(`${p.modelName}:${p.id}`);
              break;
          }
          return m;
        }, [])
        .join("-");

      if (instancesWithoutKnownId[modelName][pathAsKey]) {
        return instancesWithoutKnownId[modelName][pathAsKey];
      }
      const newInstance = pool.createInstanceViaPath({modelName, path});
      instancesWithoutKnownId[modelName][pathAsKey] = newInstance;
      return newInstance;
    },
  };

  registerOnChangeListener(pool.invalidateInstances);

  return pool;
}

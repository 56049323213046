import {visit} from "unist-util-visit";

const openLinksInNewTabRehypePlugin = () => {
  return (tree) => {
    visit(
      tree,
      (n) => n.tagName === "a",
      (n) => {
        if (n.properties.href?.startsWith?.("/")) {
          n.properties.$cdxInternalLink = true;
        } else {
          n.properties.target = "_blank";
          n.properties.rel = "noopener";
        }
      }
    );
  };
};

export default openLinksInNewTabRehypePlugin;

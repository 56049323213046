import {create} from "zustand";

export const useHoverTagStore = create((set) => ({
  set,
  cardIdSet: new Set(),
}));

export const hoverActions = {
  setHoverTags: (cardIdSet) => useHoverTagStore.getState().set({cardIdSet}),
};

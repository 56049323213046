import {hasChildCards} from "../features/workflows/workflow-utils";

export const sortedStatusVals = [
  "unassigned",
  "assigned",
  "started",
  "snoozing",
  "blocked",
  "review",
  "done",
  "archived",
  "deleted",
  "hero",
  "doc",
];

const statusToSortVal = sortedStatusVals.reduce((memo, val, i) => {
  memo[val] = i;
  return memo;
}, {});

/**
 * @typedef {"deleted" | "doc" | "archived" | "unassigned" | "assigned" | "snoozing" | "blocked" | "review" | "done" | "started" | "hero" | "snoozing"} CardStatus
 */

/**
 *
 * @param {import("../cdx-models/Card").Card} card
 * @returns CardStatus
 */
export const getStatusForCard = (card) => {
  if (card.visibility === "deleted") return "deleted";
  if (card.isDoc) return card.visibility === "archived" ? "archived" : "doc";
  if (isCardBlocked(card)) return "blocked";
  if (isCardInReview(card)) return "review";
  if (card.visibility === "archived" && card.status !== "done") return "archived";
  const status = card.$meta.get("status", "not_started");
  if (status === "not_started") {
    if (hasChildCards(card)) return "hero";
    return card.assignee ? "assigned" : "unassigned";
  }
  return status;
};

export const statusForWorkflowItem = (card) => {
  if (card.visibility === "deleted") return "deleted";
  return "workflowItem";
};

export const isCardBlocked = (card) =>
  card.$meta.exists("resolvables", {
    isClosed: false,
    context: "block",
    ...(process.env.REACT_APP_MODE === "open" && {isPublic: true}),
  });
export const isCardInReview = (card) =>
  card.$meta.exists("resolvables", {
    isClosed: false,
    context: "review",
    ...(process.env.REACT_APP_MODE === "open" && {isPublic: true}),
  });

export const statusToLabel = {
  snoozing: "Snoozing",
  blocked: "Blocked",
  assigned: "Assigned",
  unassigned: "Unassigned",
  review: "Review",
  done: "Done",
  started: "Started",
  archived: "Archived",
  deleted: "Deleted",
  not_started: "Not started",
  hero: "Hero card",
  doc: "Doc card",
};

// x cards are [PHRASE] used in metrics
export const statusToPhrase = {
  snoozing: "snoozing",
  blocked: "blocked",
  assigned: "assigned",
  unassigned: "unassigned",
  review: "in review",
  done: "done",
  started: "started",
  archived: "archived",
  deleted: "deleted",
  not_started: "not started",
  hero: "a Hero card",
  doc: "a Doc card",
};

export function statusSorter(status) {
  return statusToSortVal[status];
}

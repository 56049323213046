import {
  $createTextNode,
  $createLineBreakNode,
  LexicalCommand,
  createCommand,
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
  TextNode,
  LineBreakNode,
} from "lexical";
import CdxTypeaheadPlugin, {
  CdxTypeaheadGroupInfo,
  TypeAheadResolution,
  textUpdateSelectionHandler,
} from "../../../web-app/src/components/RichTextarea/Lexical/CdxTypeAheadLexicalPlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect, useRef, useState} from "react";
import {
  DSIconBrackets,
  DSIconConversation,
  DSIconHeadings,
  DSIconLink,
  DSIconList,
  DSIconProperties,
  DSIconReference,
} from "../DSIcon/DSIcon";

type SlashCommandOption = {
  key: string;
  label: string;
  keywords: string;
  handleSelect: (e: LexicalEditor, resolution: TypeAheadResolution<any>) => void;
  groupKey: string;
};

export const addTextTransformer = (
  text: string,
  placeholder: string = " ",
  ensureNewLine = false
) => {
  const handler = textUpdateSelectionHandler((_, selection, resolution) => {
    const prefix = !ensureNewLine && resolution.prefix?.trim() ? " " : "";
    const node = $createTextNode(`${prefix}${text}${placeholder}`);
    const nodes: (TextNode | LineBreakNode)[] = [node];
    if (ensureNewLine && resolution.matchStart !== 0) {
      nodes.unshift($createLineBreakNode());
    }
    selection.insertNodes(nodes);
    if (!placeholder || placeholder === " ") {
      node.select(prefix.length + text.length + placeholder.length);
    } else {
      node.select(
        prefix.length + text.length,
        prefix.length + text.length + (placeholder !== " " ? placeholder.length : 0)
      );
    }
  });
  return (e: LexicalEditor, resolution: TypeAheadResolution<any>) => handler(null, e, resolution);
};

const options: SlashCommandOption[] = [
  ...[1, 2, 3].map((l) => ({
    key: `h${l}`,
    label: `Heading ${l}`,
    keywords: `h${l} header heading`,
    handleSelect: addTextTransformer(`${"".padEnd(l, "#")} `, "Heading", true),
    groupKey: "headings",
  })),
  {
    key: `ul`,
    label: `Bulleted List`,
    keywords: "list bullet",
    handleSelect: addTextTransformer(`- `, "First Item", true),
    groupKey: "lists",
  },
  {
    key: `ol`,
    label: `Numbered List`,
    keywords: "list number digit",
    handleSelect: addTextTransformer(`1. `, "First Item", true),
    groupKey: "lists",
  },
  {
    key: `check`,
    label: `Checklist`,
    keywords: "list check todo",
    handleSelect: addTextTransformer(`- [ ] `, "First To Do", true),
    groupKey: "lists",
  },
  {
    key: `quote`,
    label: `Quote`,
    keywords: "blockquote cite citation",
    handleSelect: addTextTransformer(`> `, "Your Quote", true),
    groupKey: "formatting",
  },
  {
    key: `code`,
    label: `Code Block`,
    keywords: "code block",
    handleSelect: (e: LexicalEditor, resolution: TypeAheadResolution<any>) => {
      const handler = textUpdateSelectionHandler((_, selection, res) => {
        const placeholder = $createTextNode("Your code");
        selection.insertNodes([
          ...(res.matchStart !== 0 ? [$createLineBreakNode()] : []),
          $createTextNode("```"),
          $createLineBreakNode(),
          placeholder,
          $createLineBreakNode(),
          $createTextNode("```"),
          $createLineBreakNode(),
        ]);
        placeholder.select(0);
      });
      return handler(null, e, resolution);
    },
    groupKey: "formatting",
  },
  {
    key: `link`,
    label: `External Link`,
    keywords: "link href a",
    handleSelect: (e: LexicalEditor, resolution: TypeAheadResolution<any>) => {
      const handler = textUpdateSelectionHandler((_, selection) => {
        const prefix = "[link](";
        const node = $createTextNode(`${prefix}https://)`);
        selection.insertNodes([node]);
        node.select(prefix.length, prefix.length + 8);
      });
      return handler(null, e, resolution);
    },
    groupKey: "links",
  },
  {
    key: `hr`,
    label: `Horizontal Divider`,
    keywords: "hr horizontal divider separator",
    handleSelect: (e: LexicalEditor, resolution: TypeAheadResolution<any>) => {
      const handler = textUpdateSelectionHandler((_, selection, res) => {
        selection.insertNodes([
          ...(res.matchStart !== 0 ? [$createLineBreakNode()] : []),
          $createLineBreakNode(),
          $createTextNode("---"),
          $createLineBreakNode(),
          $createLineBreakNode(),
        ]);
      });
      return handler(null, e, resolution);
    },
    groupKey: "formatting",
  },
];

const REGISTER_SLASH_COMMAND: LexicalCommand<SlashCommandOption> = createCommand();

export const useSlashCommand = (command: SlashCommandOption) => {
  const [editor] = useLexicalComposerContext();
  const commandRef = useRef(command);
  useEffect(() => {
    commandRef.current = command;
  });
  useEffect(() => {
    editor.dispatchCommand(REGISTER_SLASH_COMMAND, {
      ...commandRef.current,
      handleSelect: (e, res) => commandRef.current.handleSelect(e, res),
    });
  }, [editor]);
};

const groups: {[key: string]: CdxTypeaheadGroupInfo} = {
  conversation: {label: "Conversation Actions", prio: 0, icon: <DSIconConversation />},
  card: {label: "Update Card Props", prio: 1, icon: <DSIconProperties />},
  references: {label: "References", prio: 5, icon: <DSIconReference />},
  headings: {label: "Headings", prio: 10, icon: <DSIconHeadings />},
  lists: {label: "Lists", prio: 20, icon: <DSIconList />},
  formatting: {label: "Other Formatting", prio: 30, icon: <DSIconBrackets />},
  links: {label: "Link & Embedding", prio: 40, icon: <DSIconLink />},
};

const CdxSlashCommandLexicalPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [moreOptions, setMoreOptions] = useState<SlashCommandOption[]>([]);

  useEffect(() => {
    return editor.registerCommand(
      REGISTER_SLASH_COMMAND,
      (slashCommand) => {
        setMoreOptions((prev) => [...prev.filter((v) => v.key !== slashCommand.key), slashCommand]);
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  const allOptions = [...moreOptions, ...options];

  return (
    <CdxTypeaheadPlugin<SlashCommandOption>
      triggerFn={(text: string) => {
        const m = text.match(/(.|^)(\/)(\S*)$/);
        if (!m) return null;
        const offset = m.index! + m[1].length;
        return {
          offset,
          match: m[3],
          triggerChar: m[2],
          prefix: m[1],
        };
      }}
      debounceMs={0}
      getOptions={async (m) =>
        m?.length ? allOptions.filter((o) => o.keywords.includes(m.toLowerCase())) : allOptions
      }
      handleOptionSelect={(option, e, resolution) => option.handleSelect(e, resolution)}
      optionToKey={(opt) => opt.key}
      renderOption={(opt) => opt.label}
      groupInfo={{
        getGroup: (key) => groups[key] || {label: "Other", prio: 99},
        getKey: (opt) => opt.groupKey,
      }}
    />
  );
};

export default CdxSlashCommandLexicalPlugin;

import {useMemo, useState, useEffect} from "react";

const useMedia = (query) => {
  const matcher = useMemo(() => window.matchMedia(query.replace(/^@media\s+/, "")), [query]);
  const [matches, setMatches] = useState(() => matcher.matches);

  useEffect(() => {
    setMatches(matcher.matches);
    const handler = () => setMatches(matcher.matches);
    matcher.addEventListener("change", handler);
    return () => matcher.removeEventListener("change", handler);
  }, [matcher]);

  return matches;
};

export default useMedia;

import * as v from "valibot";
import {type ComponentProps} from "react";
import type {ValibotFormArgs} from "./lib/create-valibot-neo-form";
import {useValibotNeoForm} from "./lib/create-valibot-neo-form";
import {FormDataSlice, useBuildFormComponent} from "./lib/utils";
import {useDelayedTrigger} from "../delayed-trigger";
import {Col, DSButton} from "@cdx/ds";
import {errorToString} from "../error-utils";

v.setSpecificMessage(v.nonEmpty, () => `Required`);

type FormSubmitState = "initial" | "loading" | "success" | "error";

type MyFormProps = {
  buttonLabel: string | null;
  formStateSlice: FormDataSlice<FormSubmitState>;
  serverErrorSlice: FormDataSlice<string | null>;
} & ComponentProps<"form">;

const FormWrapper = ({
  buttonLabel = "Save",
  formStateSlice,
  serverErrorSlice,
  children,
  ...rest
}: MyFormProps) => {
  const state = formStateSlice.useValue((s) => s);
  const serverError = serverErrorSlice.useValue();
  return (
    <form {...rest}>
      {serverError && <div style={{color: "red"}}>{serverError}</div>}
      {children}
      {buttonLabel && (
        <Col align="start">
          <DSButton type="submit" state={state}>
            {buttonLabel}
          </DSButton>
        </Col>
      )}
    </form>
  );
};

type Form2Props<TSchema extends v.BaseSchema<any, any, any>> = ValibotFormArgs<TSchema>;
export const useNextForm = <TSchema extends v.BaseSchema<any, any, any>>(
  args: Form2Props<TSchema>
) => {
  const {onSubmit, ...rest} = args;
  const trigger = useDelayedTrigger();

  const handleSubmit = async (values: v.InferOutput<TSchema>) => {
    const res = args.onSubmit(values);
    if (res && "then" in res) {
      formStateSlice.setValue("loading");
      serverErrorSlice.setValue(null);
      serverErrorFieldSlice.setValue({});
      trigger.cancel();
      res.then(
        () => {
          formStateSlice.setValue("success");
          trigger.fire(() => formStateSlice.setValue("initial"), 2000);
        },
        (err) => {
          formStateSlice.setValue("error");
          trigger.fire(() => formStateSlice.setValue("initial"), 2000);
          if (typeof err === "object") {
            // TODO: check if keys correcspond to field names
            // Probably requires a path checker utility
            serverErrorFieldSlice.setValue(err);
          } else {
            serverErrorSlice.setValue(errorToString(err));
          }
        }
      );
    }
  };

  const {
    onSubmit: _formSubmit,
    createCustomDataSlice,
    ...neoFormProps
  } = useValibotNeoForm({
    ...rest,
    onSubmit: handleSubmit,
    shouldFocusError: true,
  });
  const formStateSlice = createCustomDataSlice<FormSubmitState>("formState", "initial");
  const serverErrorSlice = createCustomDataSlice<string | null>("serverError", null);
  const serverErrorFieldSlice = createCustomDataSlice<Record<string, string>>(
    "serverFieldError",
    {}
  );

  const Form = useBuildFormComponent(FormWrapper, {
    onSubmit: _formSubmit,
    formStateSlice,
    serverErrorSlice,
  });

  return {
    ...neoFormProps,
    formStateSlice,
    serverErrorSlice,
    Form,
    triggerSubmit: () => _formSubmit(),
  };
};

import {searchCategoriesByKey} from "./search-categories";
import shallowEqual from "shallowequal";
import getSelectedProjects from "../../lib/hooks/useSelectedProjects";
import {create} from "zustand";
import {useEffect, useRef, useState} from "react";
import {getLocalStorageMap} from "../../lib/storage";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";

const forceOrCats = new Set(["prjct-tags", "prsnl-tags", "tags", "cardTitle", "content"]);

export const needsForceOrDisplay = (filters) => {
  const present = new Set();
  return filters.some(({category}) => {
    if (!forceOrCats.has(category)) return false; // ignore non-tag, non-content categories

    if (present.has(category)) return true;
    present.add(category);
    return false;
  });
};

const saneArchived = (state) => {
  if (state.includeArchivedCards) return state;
  if (!state.filters.some((f) => f.category === "status" && f.value === "archived")) return state;
  return {...state, includeArchivedCards: true};
};

const saneForceOr = (state) => {
  if (!state.forceOr) return state;
  if (needsForceOrDisplay(state.filters)) return state;
  return {...state, forceOr: false};
};

const sanityCheck = (inState) => {
  let outState = inState;
  outState = saneArchived(outState);
  outState = saneForceOr(outState);
  return outState;
};

export const useSearchStore = create((set) => ({
  set: (nextState) => set((prev) => sanityCheck({...prev, ...nextState})),

  filters: [
    // {category: "cardTitle", value: "Hello"},
    // {category: "tags", value: "spiderman"},
    // {category: "status", value: "assigned"},
  ],
  includeArchivedCards: false,
  isFocussed: false,
  forceOr: false, // when adding two or more tags (or title, content searches) should these be ANDed or ORed?
  defaultArchiveStorageKey: null,
}));

const {storageGet, storageSet} = getLocalStorageMap("default-archived-");

export const searchActions = {
  onChangeArena: ({defaultArchiveStorageKey}) => {
    const {set, ...prev} = useSearchStore.getState();
    // if arena has no defaultArchiveStorageKey, just assume `includeArchivedCards: false`
    const storedValue = Boolean(defaultArchiveStorageKey && storageGet(defaultArchiveStorageKey));
    return set({
      forceOr: false,
      includeArchivedCards: storedValue,
      defaultArchiveStorageKey,
      ...(prev.filters.length && {filters: []}),
    });
  },
  setDefaultArchiveStorageKey: (storageKey) => {
    const {set, ...prev} = useSearchStore.getState();
    if (prev.defaultArchiveStorageKey === storageKey) return;
    const storedValue = Boolean(storageKey && storageGet(storageKey));
    return set({
      includeArchivedCards: storedValue,
      defaultArchiveStorageKey: storageKey,
    });
  },
  setFilters: (filters, {forceOr} = {}) =>
    useSearchStore.getState().set({filters, ...(forceOr !== undefined ? {forceOr} : {})}),
  addFilter: (filter) => {
    const {set, ...prev} = useSearchStore.getState();
    const {category, value} = filter;
    if (
      !prev.filters.some(
        (t) => t.category === category && (t.value === value || shallowEqual(t.value, value))
      )
    ) {
      if (category === "priority" && value.priority === "a" && value.cmp === "eq") {
        completeOnboardingStep(ONBOARDING_STEPS.searchForPriority);
      }
      set({filters: [...prev.filters, filter]});
    }
  },
  removeFilter: (filter) => {
    const {set, ...prev} = useSearchStore.getState();
    set({filters: prev.filters.filter((f) => f !== filter)});
  },
  removeLast: () => {
    const {set, ...prev} = useSearchStore.getState();
    if (prev.filters.length > 0) set({filters: prev.filters.slice(0, -1)});
  },
  open: () => useSearchStore.getState().set({isFocussed: true}),
  close: () => useSearchStore.getState().set({isFocussed: false}),
  setArchived: (value) => {
    const {set, defaultArchiveStorageKey} = useSearchStore.getState();
    set({includeArchivedCards: value});
    if (defaultArchiveStorageKey) storageSet(defaultArchiveStorageKey, value);
  },
  toggleArchived: () => {
    const {set, defaultArchiveStorageKey, ...prev} = useSearchStore.getState();
    set({includeArchivedCards: !prev.includeArchivedCards});
    if (defaultArchiveStorageKey) storageSet(defaultArchiveStorageKey, !prev.includeArchivedCards);
  },
  toggleForceOr: () => {
    const {set, ...prev} = useSearchStore.getState();
    set({forceOr: !prev.forceOr});
  },
};

// use global variable to remember the last arena name across unmounts
let lastArenaName = null;

const useArenaSwitchBehaviour = ({defaultArchiveStorageKey, searchArenaName}) => {
  const currStorageKeyRef = useRef(defaultArchiveStorageKey);
  useEffect(() => {
    currStorageKeyRef.current = defaultArchiveStorageKey;
  });
  useEffect(() => {
    if (lastArenaName && lastArenaName !== searchArenaName) {
      searchActions.onChangeArena({defaultArchiveStorageKey: currStorageKeyRef.current});
    }
    lastArenaName = searchArenaName;
  }, [searchArenaName]);
  useEffect(() => {
    // shuold only matter if lastArenaName is null, as otherwise `onChangeArena` already sets the storage key
    searchActions.setDefaultArchiveStorageKey(defaultArchiveStorageKey);
  }, [defaultArchiveStorageKey]);
};

const useApplyLocationSearchState = ({location, history}) => {
  const [appliedLocationFilter, setAppliedLocationFilter] = useState(false);
  const locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  });
  const searchTokens = location.state && location.state.searchTokens;
  const searchIncludeArchived = location.state && location.state.searchIncludeArchived;

  useEffect(() => {
    if (searchTokens) {
      searchActions.setFilters(searchTokens);
      setAppliedLocationFilter(true);
    }
  }, [searchTokens]);
  useEffect(() => {
    if (searchIncludeArchived) {
      searchActions.setArchived(true);
      setAppliedLocationFilter(true);
    }
  }, [searchIncludeArchived]);

  useEffect(() => {
    if (appliedLocationFilter) {
      const {searchTokens: _1, searchIncludeArchived: _2, ...restState} = locationRef.current.state;
      history.replace({...locationRef.current, state: restState});
      setAppliedLocationFilter(false);
    }
  }, [appliedLocationFilter, history]);
};

export const useSearchBehaviour = ({
  location,
  defaultArchiveStorageKey,
  searchArenaName,
  history,
}) => {
  useArenaSwitchBehaviour({defaultArchiveStorageKey, searchArenaName});
  useApplyLocationSearchState({location, history});
};

export const useSearchFilters = () => useSearchStore((s) => s.filters);

const createDbFilters = (filters, {includeArchivedCards, forceOr}) => {
  if (!filters.length) return {};
  const byCat = filters.reduce((m, {category, value}) => {
    (m[category] = m[category] || []).push(value);
    return m;
  }, {});
  return {
    $and: Object.entries(byCat).map(([category, values]) =>
      searchCategoriesByKey[category].valuesToDbQuery(values, {includeArchivedCards, forceOr})
    ),
  };
};

export const useFilteredCards = (
  root,
  defaultFilter,
  {forceFilter, onlyCount, forceIncludeArchivedCards} = {}
) => {
  const {
    searchFilters,
    includeArchivedCards: searchArchived,
    forceOr,
  } = useSearchStore((s) => ({
    searchFilters: s.filters,
    includeArchivedCards: s.includeArchivedCards,
    forceOr: s.forceOr,
  }));
  const includeArchivedCards = searchArchived || forceIncludeArchivedCards;
  const selectedProjects = getSelectedProjects(root);
  if (defaultFilter === false) return {cards: [], getCardCount: () => 0};
  const baseFilter = {
    ...(defaultFilter.deckId
      ? {}
      : {
          $or: [
            {
              deck: {
                projectId: selectedProjects.filter((p) => p && p.$meta.isLoaded).map((p) => p.id),
              },
            },
            {deckId: null},
          ],
        }),
    visibility: includeArchivedCards ? ["default", "archived"] : "default",
    ...defaultFilter,
  };
  const finalFilter = {
    ...baseFilter,
    ...createDbFilters(searchFilters, {includeArchivedCards, forceOr}),
    ...forceFilter,
  };

  return {
    cards: onlyCount ? null : root.account.$meta.find("cards", finalFilter),
    getCardCount: () => root.account.$meta.count("cards", baseFilter),
    isFiltering: forceFilter || searchFilters.length > 0,
  };
};

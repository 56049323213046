import {Col, Row, Text} from "@cdx/ds";
import {TooltipForChild} from "@cdx/common";
import {CSSProperties} from "react";
import {Account} from "../../cdx-models/Account";
import {Card} from "../../cdx-models/Card";
import {addedDuringSprintStyles} from "./card.css";
import {shortDate} from "../../lib/date-utils";

export const AddedDuringSprintPill = ({
  style,
  size = "md",
  isDone,
  isNewUntil,
}: AddedDuringSprintProps & {style?: CSSProperties}) => (
  <TooltipForChild
    tooltip={`Card was added into a started Run. ${isNewUntil ? `It will be locked in at ${shortDate(new Date(isNewUntil))}` : ""}`}
    placement="bottom"
  >
    <Row
      rounded={4}
      align="center"
      justify="center"
      pointerEvents="auto"
      px={size === "md" ? "4px" : "12px"}
      py={size === "md" ? "4px" : "6px"}
      className={isDone ? addedDuringSprintStyles.type.done : addedDuringSprintStyles.type.notDone}
      style={style}
      elevation={100}
      lineHeight="none"
      flex="none"
    >
      <Text
        type={size === "md" ? "label10Caps" : "label16"}
        style={size === "lg" ? {textTransform: "uppercase", letterSpacing: "-0.02em"} : undefined}
      >
        New
      </Text>
    </Row>
  </TooltipForChild>
);

type AddedDuringSprintProps = {
  size?: "md" | "lg";
  isDone?: boolean;
  isNewUntil?: string;
};
const AddedDuringSprint = (props: AddedDuringSprintProps) => (
  <Col
    absolute
    left="0"
    right="0"
    width="100%"
    height="100%"
    align="center"
    justify="end"
    zIndex={1}
    pointerEvents="none"
  >
    <AddedDuringSprintPill style={{marginBottom: -4}} {...props} />
  </Col>
);

const alignWithCronJob = (date: Date) => {
  // cron jobs runs every hour at 5 minutes past the hour
  const minute = date.getMinutes();
  if (minute >= 5) {
    date.setHours(date.getHours() + 1);
  }
  date.setMinutes(0);
  return date;
};

export const getIsNewUntil = (account: Account, card: Card): null | string => {
  if (process.env.REACT_APP_MODE === "open") return null;
  const pending = card.meta?.sprintLockedIn?.pending;
  if (!pending) return null;
  if (pending.sprintId !== card.sprint?.id) return null;
  if (!card.sprint.lockedAt) return null;
  const target = new Date(
    new Date(pending.addedAt).getTime() +
      card.sprint.sprintConfig.beastGracePeriodHours * 3600 * 1000
  );
  return alignWithCronJob(target).toISOString();
};

export default AddedDuringSprint;

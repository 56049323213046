import {XTextButton} from "@cdx/common";
import cx from "@cdx/common/cx";
import {DSIconSelectionClose, DSIconSelectionEmpty} from "@cdx/ds";
import {selectionIconStyles} from "./SelectionIcon.css";

/** @type: any */
const SelectionIconButton = ({
  tooltip,
  onLight = true,
  tooltipProps,
  className,
  onClick,
  active,
  disabled,
  hidden,
}) => {
  return (
    <XTextButton
      square
      size="md"
      color={onLight ? "dim" : "white"}
      tooltip={tooltip}
      tooltipProps={tooltipProps}
      className={cx(
        selectionIconStyles.base,
        active && selectionIconStyles.active,
        hidden && selectionIconStyles.hidden,
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {active ? <DSIconSelectionClose size={20} /> : <DSIconSelectionEmpty size={20} />}
    </XTextButton>
  );
};

export default SelectionIconButton;

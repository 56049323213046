import memoize from "fast-memoize";
import memoizeOne from "memoize-one";
import {useContext, createContext} from "react";
import {matchPath} from "react-router-dom";
import shallowEqual from "shallowequal";
import {api} from "../../lib/api";
import {pronounceSafeSeq} from "../../lib/sequences";
import {slugify} from "../../lib/utils";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {cardPathToSeq} from "../../routes";
import {cardAccountSeqToCardId} from "../../lib/mate/cache";

export const toCardPath = ({title, accountSeq}) =>
  `${pronounceSafeSeq.intToSeq(accountSeq)}-${slugify(title)}`;
export const cardUrlState = ({targetPanel, resolvableId, cardContainerKey}) =>
  memoizedUrlState(targetPanel, resolvableId, cardContainerKey);

const memoizedUrlState = memoize((targetPanel, resolvableId, cardContainerKey) => ({
  ...(targetPanel ? {sidePanelKey: targetPanel} : {}),
  ...(resolvableId ? {cardTargetResolvableId: resolvableId} : {}),
  cardContainerKey,
}));

export const createArenaContext = memoizeOne(({type, shortcutCategory, model, routes}) => {
  return {
    type,
    shortcutCategory,
    model,
    routes,
  };
}, shallowEqual);

export const getPathBasedRoutes = memoizeOne(({rootPath, getRootUrl, history}) => {
  const getCardUrl = ({card, targetPanel, resolvableId, cardContainerKey}) => {
    const {accountSeq, title} = card;
    return {
      pathname: `${getRootUrl()}/card/${toCardPath({accountSeq, title})}`,
      state: cardUrlState({targetPanel, resolvableId, cardContainerKey}),
    };
  };

  return {
    getActiveCard: (location) => {
      const cardMatch = matchPath(location.pathname, {path: `${rootPath}/card/:cardId`});
      if (cardMatch) {
        const accountSeq = cardPathToSeq(cardMatch.params.cardId);
        const cardId = cardAccountSeqToCardId.get(accountSeq);
        const card = cardId
          ? api.getModel({modelName: "card", id: cardId})
          : api.getRoot().account.$meta.find("cards", {accountSeq})[0] || null;
        return card;
      } else {
        return null;
      }
    },
    getCardUrl,
    goToCard: ({cardId, targetPanel, resolvableId, cardContainerKey}) => {
      waitForResultPromise(() => {
        const {title, accountSeq} = api.getModel({modelName: "card", id: cardId});
        return {title, accountSeq};
      }).then(({title, accountSeq}) => {
        history.push(
          getCardUrl({card: {title, accountSeq}, targetPanel, resolvableId, cardContainerKey})
        );
      });
    },
    rootPath: rootPath,
    getRootUrl,
    stateBased: false,
  };
}, shallowEqual);

export const getStateBasedRoutes = memoizeOne(({rootPath, rootUrl, history}) => {
  const getCardUrl = ({card, targetPanel, resolvableId, cardContainerKey}) => ({
    pathname: rootUrl,
    state: {
      showCardId: card.id,
      ...cardUrlState({targetPanel, resolvableId, cardContainerKey}),
    },
  });

  return {
    getActiveCard: (location) => {
      if (location.state && location.state.showCardId) {
        return api.getModel({modelName: "card", id: location.state.showCardId});
      } else {
        return null;
      }
    },
    getCardUrl,
    goToCard: ({cardId, targetPanel, resolvableId, cardContainerKey}) => {
      history.push(getCardUrl({card: {id: cardId}, targetPanel, resolvableId, cardContainerKey}));
    },
    rootPath,
    getRootUrl: () => rootUrl,
    stateBased: true,
  };
}, shallowEqual);

/** @type {React.Context<ArenaCtx | null>} */
const ArenaContext = createContext(null);
export const ArenaContextProvider = ArenaContext.Provider;

export const useArenaContext = () => useContext(ArenaContext);

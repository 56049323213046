import {forwardRef} from "react";
import {cx, WithLabel} from "@cdx/common";
import {XRow, XCol} from "../xui";
import {swatchtStyles} from "./ColorSelect.css";
import ColorIcon from "./color-picker.svg";
import {msMainColors} from "../../pages/milestones/milestone-theme.css";

export const defaultColors = ["green", "blue", "pink", "red", "yellow", "brown", "gray"].map(
  (c) => msMainColors[c]
);

const ButtonOrDiv = forwardRef((props, ref) =>
  props.onClick ? <button ref={ref} type="button" {...props} /> : <div ref={ref} {...props} />
);

export const Swatch = forwardRef(
  ({color, isSelected, onClick, className, style, fallback, ...rest}, ref) => (
    <ButtonOrDiv
      ref={ref}
      className={cx(
        className,
        swatchtStyles.base,
        onClick && swatchtStyles.clickable,
        isSelected && (onClick ? swatchtStyles.selectedClickable : swatchtStyles.selected)
      )}
      onClick={onClick}
      style={color ? {...style, backgroundColor: color} : style}
      {...rest}
    >
      <XCol absolute inset="full" align="center" justify="center">
        {fallback || (!color && <img width={14} height={14} src={ColorIcon} alt="Colors" />)}
      </XCol>
    </ButtonOrDiv>
  )
);

/** @type: any */
export const ColorSelect = forwardRef(
  ({value, colors: cols = defaultColors, onChange, ...rest}, ref) => {
    const displayColors = value && !cols.some((c) => c === value) ? [value, ...cols] : cols;
    return (
      <XRow sp={1} {...rest}>
        {displayColors.map((c, i) => (
          <Swatch
            key={c}
            ref={i === 0 ? ref : undefined}
            isSelected={c === value}
            color={c}
            onClick={() => onChange(c)}
          />
        ))}
      </XRow>
    );
  }
);

export const ColorSelectWithLabel = forwardRef(
  ({label, showErrors, hint, className, hasPendingValidation, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={label}
      showErrors={showErrors}
      hint={hint}
      className={className}
      hasPendingValidation={hasPendingValidation}
    >
      <ColorSelect {...rest} ref={ref} />
    </WithLabel>
  )
);

import {XCol, XRow} from "../../../components/xui";
import {projectsDecks} from "../../../lib/utils";
import {useInstance} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import {CdxCropImgByFile} from "../../../components/CdxImg";
import uiClasses from "@cdx/common/xui/ui.css";

export const DeckOpt = ({id, ...rest}) => {
  const deck = useInstance("deck", id);
  return (
    <XRow sp={0} align="center" {...rest}>
      <XCol
        as={CdxCropImgByFile}
        width={16}
        height={16}
        file={deck.coverFile}
        elevation={1}
        noShrink
        rounded="sm"
        noOverflow
        border="gray200"
        bg="gray300"
        fallbackClassName={uiClasses.backgroundColor.gray500}
      />
      <div>{deck.title}</div>
    </XRow>
  );
};

const deckCategory = {
  key: "deck",
  label: "Deck",
  getSuggestions({input, projectIds, root}) {
    if (input.length < 2) return [];
    return [
      ...projectsDecks({
        root,
        projectIds,
      })
        .filter((d) => d.$meta.get("title", "").toLowerCase().indexOf(input) > -1)
        .map((d) => d.id),
      ...("no decks".startsWith(input) ? [null] : []),
    ];
  },
  valueToKey(value) {
    return value;
  },
  valuesToDbQuery(values) {
    return {deckId: values};
  },
  renderSuggestion(value) {
    return value ? <DeckOpt id={value} /> : "No deck";
  },
  savedSearchLabel(value) {
    if (!value) return {prio: -2, label: "w/o deck"};
    const deck = api.getModel({modelName: "deck", id: value});
    return {
      prio: -2,
      label: `in '${deck.title.slice(0, 16)}'`,
    };
  },
};

export default deckCategory;

import {lazy, Suspense} from "react";

const LazyEmoji = lazy(
  () => import(/* webpackChunkName: "EmojiRenderer" */ "../../lib/emoji/EmojiRenderer")
);

const Emoji = (props) => (
  <Suspense fallback={<span>{props.children || props.decoratedText}</span>}>
    <LazyEmoji {...props} />
  </Suspense>
);

export default Emoji;

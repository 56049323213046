import {useState} from "react";

const useToggle = (initial: boolean = false) => {
  const [value, setValue] = useState(initial);
  const [actions] = useState(() => ({
    toggle: () => setValue((prev) => !prev),
    off: () => setValue(false),
    on: () => setValue(true),
  }));

  return [value, actions] as const;
};

export default useToggle;

import {useState, useLayoutEffect} from "react";
import {getScrollParents} from "../scroll";
import {getPassiveArg} from "../feature-detection";
import ResizeObserver from "resize-observer-polyfill";
import {getZIndex} from "@cdx/ds/utils/dom-utils";

export const useZIndex = (node) => {
  const [zIndex, setZIndex] = useState(node ? 5 : null);
  useLayoutEffect(() => {
    if (node) {
      setZIndex(getZIndex(node));
    } else {
      setZIndex(null);
    }
  }, [node, setZIndex]);
  return zIndex;
};

const adaptRectToWindowScroll = (d) => ({
  ...d,
  top: d.top + window.pageYOffset,
  bottom: d.bottom + window.pageYOffset,
  left: d.left + window.pageXOffset,
  right: d.right + window.pageXOffset,
});

export const usePosition = (node) => {
  const [bounds, setBounds] = useState(null);

  useLayoutEffect(() => {
    if (!node) return;

    const scrollPanes = getScrollParents(node);

    const updatePosition = () => {
      const rect = node.getBoundingClientRect();
      if (rect.width === 0 && rect.height === 0) {
        if (!document.body.contains(node)) return setBounds(null);
      }
      const {top, bottom, left, right} = adaptRectToWindowScroll(rect);
      setBounds((prev) => {
        if (
          !prev ||
          prev.top !== top ||
          prev.bottom !== bottom ||
          prev.left !== left ||
          prev.right !== right
        ) {
          return {top, bottom, left, right, height: bottom - top, width: right - left};
        } else {
          return prev;
        }
      });
    };

    const ro = new ResizeObserver(updatePosition);
    ro.observe(node);
    const intervalId = setInterval(updatePosition, 1000);

    scrollPanes.forEach((pane) => pane.addEventListener("scroll", updatePosition, getPassiveArg()));
    window.addEventListener("resize", updatePosition);
    return () => {
      clearInterval(intervalId);
      scrollPanes.forEach((pane) => pane.removeEventListener("scroll", updatePosition));
      window.removeEventListener("resize", updatePosition);
      ro.disconnect();
    };
  }, [node]);

  return bounds;
};

import {dependencyStyles as styles} from "./DependencyArea.css";
import {
  ArrowOverlay,
  cx,
  IconGateClosed,
  XCol,
  XText,
  XRow,
  XTextButton,
  XPush,
  XPlainButton,
  IconGateOpen,
  IconArrowCircleRight,
  IconChecronDoubleRight,
  xcolors,
  WithDropDown,
} from "@cdx/common";
import ChildCardLane, {MaybeInaccessibleChildCardLane} from "../workflows/ChildCardLane";
import CardPicker from "../../components/CardPicker";
import getSelectedProjects from "../../lib/hooks/useSelectedProjects";
import useMutation from "../../lib/hooks/useMutation";
import {getStatusForCard} from "../../lib/card-status-utils";
import {useArenaContext} from "../../layouts/arena-layout/createArenaContext";
import {useRoot} from "../../lib/mate/mate-utils";
import messenger from "../../lib/messenger";
import {api} from "../../lib/api";
import WorkflowItemLane from "../workflows/WorkflowItemLane";
import {useEffect, useState} from "react";
import {completeOnboardingStep, ONBOARDING_STEPS} from "../onboarding/onboarding-utils";
import {DSIconInfo} from "@cdx/ds";
import {workflowRoutes} from "../workflows/workflow-utils";

const Column = ({top, list, root, onRemove, removeLabel, interactive, isWorkflowItem}) => {
  const maybeArenaCtx = useArenaContext();
  return (
    <XCol sp={2}>
      {top}
      <XCol sp={0}>
        {list.map(({type, cardId}) => {
          if (isWorkflowItem) {
            const item = api.getModel({modelName: "workflowItem", id: cardId});
            return (
              <WorkflowItemLane
                key={cardId}
                root={root}
                item={item}
                onRemove={interactive && onRemove}
                removeLabel={removeLabel}
                to={interactive && workflowRoutes.item.getUrl({deck: item.deck, item})}
              />
            );
          } else {
            const depCard = api.getModel({modelName: "card", id: cardId});
            const {getCardUrl, goToCard} = maybeArenaCtx.routes;
            return (
              <MaybeInaccessibleChildCardLane
                key={cardId}
                root={root}
                card={depCard}
                onRemove={type === "dep" && interactive && onRemove}
                removeLabel={removeLabel}
                to={interactive && depCard && getCardUrl({card: depCard})}
                goToCard={goToCard}
              />
            );
          }
        })}
      </XCol>
    </XCol>
  );
};

const AddButton = (props) => <XPlainButton {...props} size="sm" color="white" square />;
const InDepIcon = ({color, large}) => (
  <IconGateClosed strokeColor={color} size={large ? "xl" : "md"} />
);
const OutDepIcon = ({color, large}) => (
  <IconChecronDoubleRight strokeColor={color} size={large ? "lg" : "md"} />
);

export const DependencyOverlay = ({interactive, card, isWorkflowItem}) => {
  const root = useRoot();
  const [searching, setSearching] = useState(null);
  const selectedProjects = getSelectedProjects(root);
  const inDepIds = card.$meta.get("inDeps", [], {forceRelIds: true});
  const outDepIds = card.$meta.get("outDeps", [], {forceRelIds: true});
  const preventAddingCardIds = [
    card.$meta.get(isWorkflowItem ? "itemId" : "cardId", null),
    ...inDepIds,
    ...outDepIds,
  ].filter(Boolean);
  const [doUpdate] = useMutation(
    isWorkflowItem ? "workflows" : "cards",
    isWorkflowItem ? "updateItem" : "update"
  );
  const inList = inDepIds.map((cardId) => ({type: "dep", cardId}));
  const parentCard = !isWorkflowItem ? card.$meta.get("parentCard") : null;
  if (parentCard?.$meta.get("hasBlockingDeps")) {
    inList.unshift({type: "parent", cardId: parentCard.id});
  }
  const filterProjectIds = new Set(
    [
      card.deck?.project.$meta.get("id", null),
      ...selectedProjects.map((p) => p.$meta.get("id", null)),
    ].filter(Boolean)
  );

  const filter = isWorkflowItem
    ? {itemId: {op: "notIn", value: preventAddingCardIds}, visibility: "default"}
    : {
        cardId: {op: "notIn", value: preventAddingCardIds},
        deck: {projectId: [...filterProjectIds]},
        isDoc: [false, null],
        visibility: "default",
      };

  const idPart = isWorkflowItem ? {itemId: card.id} : {id: card.id};

  return (
    <XCol style={{width: 300, maxWidth: "100%"}}>
      <XRow sp={2} px={3} py={interactive ? 1 : 2} bg="gray800" align="baseline">
        <XText size={1} preset="bold" color="gray100">
          Dependencies
        </XText>
        {interactive && (
          <>
            <XPush />
            <XRow align="center" sp={1}>
              <XText size={1} preset="bold" color="gray400">
                Add
              </XText>
              <AddButton
                onClick={() => setSearching(searching === "inDeps" ? null : "inDeps")}
                active={searching === "inDeps"}
                tooltip="Add locking card"
              >
                <InDepIcon />
              </AddButton>
              <AddButton
                onClick={() => setSearching(searching === "outDeps" ? null : "outDeps")}
                active={searching === "outDeps"}
                tooltip="Add card locked by the current card"
              >
                <OutDepIcon />
              </AddButton>
            </XRow>
          </>
        )}
      </XRow>
      <XCol px={3} py={2}>
        {searching ? (
          <CardPicker
            topBoxProps={{bg: "white", pa: 2, rounded: "sm"}}
            label="Search for card title"
            onSelect={(cId) =>
              doUpdate({
                ...idPart,
                [searching]: [...(searching === "inDeps" ? inDepIds : outDepIds), cId],
              }).then(
                () => setSearching(null),
                (err) => messenger.send(err, {type: "error"})
              )
            }
            root={root}
            filter={filter}
            emptyMessage="The search exludes private cards as those are no valid dependencies."
            isWorkflowItem={isWorkflowItem}
            deck={card.itemDeck}
            limit={5}
          />
        ) : (
          <XCol sp={1}>
            {inList.length === 0 && outDepIds.length === 0 && (
              <XRow sp={4} align="center" px={1} py={2}>
                <DSIconInfo style={{color: xcolors.gray500}} size={24} />
                <XText color="gray300" preset="default" lineHeight="default" size={2}>
                  You can use dependencies to signal that cards shouldn't be started before other
                  cards are completed.
                </XText>
              </XRow>
            )}
            {inList.length > 0 && (
              <Column
                top={
                  <XRow sp={0} align="center">
                    <InDepIcon color="gray400" large />
                    <XText preset="bold" size={1} color="gray100">
                      Locked by
                    </XText>
                  </XRow>
                }
                list={inList}
                root={root}
                onRemove={(cId) =>
                  doUpdate({...idPart, inDeps: inDepIds.filter((id) => id !== cId)})
                }
                removeLabel={isWorkflowItem ? "Remove as locking step" : "Remove as locking card"}
                interactive={interactive}
                isWorkflowItem={isWorkflowItem}
              />
            )}
            {outDepIds.length > 0 && (
              <Column
                top={
                  <XRow sp={0} align="center">
                    <OutDepIcon color="gray400" large />
                    <XText preset="bold" size={1} color="gray100">
                      Locking
                    </XText>
                  </XRow>
                }
                list={outDepIds.map((cardId) => ({type: "dep", cardId}))}
                root={root}
                onRemove={(cId) =>
                  doUpdate({...idPart, outDeps: outDepIds.filter((id) => id !== cId)})
                }
                removeLabel={isWorkflowItem ? "Remove as locked step" : "Remove as locked card"}
                interactive={interactive}
                isWorkflowItem={isWorkflowItem}
              />
            )}
          </XCol>
        )}
      </XCol>
    </XCol>
  );
};

const Counter = ({cards: rawCards, cardIds, isWorkflowItem, considerParent}) => {
  const info = {
    parent: 0,
    started: 0,
    blocked: 0,
    review: 0,
    done: 0,
    rest: isWorkflowItem ? 1 : 0,
  };
  if (!isWorkflowItem) {
    const cards = rawCards || cardIds.map((id) => api.getModel({modelName: "card", id}));
    cards.forEach((c) => {
      if (!c) {
        info.rest += 0.5;
        return;
      }
      const status = getStatusForCard(c);
      if (status in info) {
        info[status] += c.effort || 0.5;
      } else if (c.visibility === "default" || c.status === "done") {
        info.rest += c.effort || 0.5;
      }
    });
  }
  if (considerParent && considerParent.hasBlockingDeps) {
    info.parent = 0.5;
  }

  return (
    <XCol noOverflow className={styles.counter.outer}>
      <XCol
        fillParent
        align="center"
        justify="center"
        bg="white"
        className={styles.counter.content}
      >
        {(rawCards || cardIds).length + (considerParent?.hasBlockingDeps ? 1 : 0)}
      </XCol>

      <XRow className={styles.counter.bottom} bg="gray100">
        {["parent", "done", "review", "blocked", "started", "rest"]
          .map((type) => ({type, count: info[type]}))
          .filter(({count}) => count > 0)
          .map(({type, count}) => (
            <div
              key={type}
              className={cx(styles.counter.rowElement[type])}
              style={{flex: `1px ${count * 2}`}}
            />
          ))}
      </XRow>
    </XCol>
  );
};

const DependencyArea = ({card, className, forceOpen, isWorkflowItem, canModify}) => (
  <WithDropDown
    renderOverlay={(overlayProps) => (
      <ArrowOverlay bg="gray700" arrowSize="xs" {...overlayProps}>
        <DependencyOverlay card={card} isWorkflowItem={isWorkflowItem} interactive={canModify} />
      </ArrowOverlay>
    )}
    initialOpen={forceOpen}
    withHover
    overlayProps={{placement: "right", distanceFromAnchor: 10}}
    as={XTextButton}
    setProps={({isClicked, isOpen}) => ({
      active: isClicked,
      className: isOpen ? null : className,
    })}
    square
    color="dim"
  >
    <XRow sp={1} align="center">
      {isWorkflowItem || card.hasBlockingDeps ? (
        <IconGateClosed size="xl" strokeColor="gray600" className={styles.counter.icon} />
      ) : (
        <IconGateOpen size="xl" strokeColor="gray400" className={styles.counter.icon} />
      )}
      <XRow sp={0} align="center" className={styles.counter.cardsContainer}>
        <Counter
          considerParent={!isWorkflowItem && card.$meta.get("parentCard", null)}
          isWorkflowItem={isWorkflowItem}
          cardIds={card.$meta.get("inDeps", [], {forceRelIds: true})}
        />
        <XText preset="bold">·</XText>
        <Counter
          isWorkflowItem={isWorkflowItem}
          cardIds={card.$meta.get("outDeps", [], {forceRelIds: true})}
        />
      </XRow>
    </XRow>
  </WithDropDown>
);

export default DependencyArea;

const BacklinkOverlay = ({backlinkCards, root}) => {
  useEffect(() => {
    completeOnboardingStep(ONBOARDING_STEPS.investigateCardBacklink);
  }, []);
  const arenaCtx = useArenaContext();
  const {getCardUrl, goToCard} = arenaCtx.routes;
  return (
    <XCol style={{width: 300, maxWidth: "100%"}}>
      <XRow sp={2} px={3} py={2} bg="gray800">
        <XText size={1} preset="bold" color="gray100">
          Backlinks to this card
        </XText>
      </XRow>
      <XCol sp={0} px={3} py={2}>
        {backlinkCards.map((card) => (
          <ChildCardLane
            key={card.id}
            root={root}
            card={card}
            to={getCardUrl({card})}
            goToCard={goToCard}
          />
        ))}
      </XCol>
    </XCol>
  );
};

export const BacklinkArea = ({backlinkCards, root}) => (
  <WithDropDown
    renderOverlay={(overlayProps) => (
      <ArrowOverlay bg="gray700" arrowSize="xs" {...overlayProps}>
        <BacklinkOverlay backlinkCards={backlinkCards} root={root} />
      </ArrowOverlay>
    )}
    withHover
    overlayProps={{placement: "right", distanceFromAnchor: 10}}
    as={XTextButton}
    setProps={({isClicked}) => ({
      active: isClicked,
    })}
    square
    color="dim"
  >
    <XRow sp={0} align="center">
      <IconArrowCircleRight size="lg" />
      <XRow sp={0} align="center">
        <Counter cards={backlinkCards} />
      </XRow>
    </XRow>
  </WithDropDown>
);

import {forwardRef, useState, useRef, useEffect, useCallback} from "react";
import {InputWithLabel} from "./Input";
import {NeoFormProvider, useNeoBagCreator, useNeoBag, useNeoField} from "./neo-form";
import XText from "../xui/XText";
import XCol from "../xui/XCol";
import {RevealContainer} from "../hooks/useReveal";
import {RawSpinner} from "../xui/Spinner";
import {IconCheck} from "../icons/Icon";
import XPlainButton from "../buttons/XPlainButton";
import {errorToString} from "../error-utils";
import {useGetNodeFromRef} from "..";
import {DSButton} from "@cdx/ds";

export const ShowError = ({children}) => (
  <XCol pb={2}>
    <XText preset="bold" size={1} color="accent700">
      {children}
    </XText>
  </XCol>
);

/** @type: any */
const XForm = forwardRef((props, ref) => {
  const {
    initialValues,
    rules,
    values,
    onChange,
    style,
    className,
    onSubmit,
    buttonLabel,
    buttonComp: ButtonComp,
    buttonProps,
    serverErrorComp: ServerErrorComp = ShowError,
    as: Comp = "form",
    children,
    disabled,
  } = props;
  const [serverError, setServerError] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const timeoutIdRef = useRef(null);
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    };
  }, []);
  const onSubmitRef = useRef(onSubmit);
  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);
  const handleSubmit = useCallback((submittedValues, bagWithinSubmit) => {
    const retVal = onSubmitRef.current(submittedValues, bagWithinSubmit);
    setIsDone(false);
    setServerError(null);
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    if (retVal && retVal.then) {
      return retVal.then(
        (ok) => {
          if (ok !== false) {
            setIsDone(true);
            timeoutIdRef.current = setTimeout(() => {
              setIsDone(false);
            }, 2000);
          }
        },
        (e) => {
          if (typeof e === "object" && !(e instanceof Error)) {
            const keys = Object.keys(e);
            const asFields = keys.filter((k) => bagWithinSubmit.values[k]);
            const asNonFields = keys.filter((k) => !bagWithinSubmit.values[k]);

            if (asFields.length) {
              asFields.forEach((key) => {
                bagWithinSubmit.setFieldMeta(key, (m) => ({...m, errors: [e[key]]}));
              });
              bagWithinSubmit.setServerErrorCount((prev) => prev + 1);
            }
            if (asNonFields.length) {
              setServerError(asNonFields.map((k) => errorToString(e[k])).join(", "));
            }
          } else {
            setServerError(errorToString(e));
          }
        }
      );
    }
  }, []);

  const bag = useNeoBagCreator({
    values: values,
    onChange: onChange,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    rules: rules,
    disabled,
  });

  return (
    <NeoFormProvider bag={bag}>
      <Comp className={className} style={style} onSubmit={bag.handleSubmit} ref={ref}>
        {serverError && <ServerErrorComp>{serverError}</ServerErrorComp>}
        {children}
        {buttonLabel && (
          <FormButton formBag={bag} isDone={isDone} disabled={disabled} {...buttonProps}>
            {buttonLabel}
          </FormButton>
        )}
        {ButtonComp && (
          <ButtonComp formBag={bag} isDone={isDone} disabled={disabled} {...buttonProps} />
        )}
      </Comp>
    </NeoFormProvider>
  );
});

export const SubForm = (props) => {
  const {initialValues, rules, values, onChange, children} = props;
  const bag = useNeoBagCreator({
    values: values,
    onChange: onChange,
    initialValues: initialValues,
    rules,
  });

  return <NeoFormProvider bag={bag}>{children}</NeoFormProvider>;
};

const SubmitButton = ({formBag, isDone, as: ButtonComp, disabled, ...rest}) => (
  <XCol relative>
    <ButtonComp type="submit" disabled={disabled || formBag.submitting} {...rest} />
    <RevealContainer show={formBag.submitting}>
      <RawSpinner />
    </RevealContainer>
    <RevealContainer show={isDone}>
      <IconCheck color="done" />
    </RevealContainer>
  </XCol>
);

export const DSSubmitButton = ({formBag, isDone, ...rest}) => (
  <DSButton
    type="submit"
    state={formBag.submitting ? "loading" : isDone ? "success" : "initial"}
    {...rest}
  />
);

const FormButton = (props) => (
  <XCol align="start">
    <SubmitButton as={XPlainButton} color="red" {...props} />
  </XCol>
);

const useFocusOnError = ({fieldNode, name, bag}) => {
  const refs = useRef({submitCount: bag.submitCount, serverErrorCount: bag.serverErrorCount});
  const firstErrorKey = Object.keys(bag.fieldMetas).find(
    (fieldName) => bag.fieldMetas[fieldName].errors.length > 0
  );
  useEffect(() => {
    if (
      (refs.current.submitCount !== bag.submitCount && bag.isValid !== true) ||
      refs.current.serverErrorCount !== bag.serverErrorCount
    ) {
      if (fieldNode && firstErrorKey === name) {
        fieldNode.focus();
      }
    }
    refs.current = {submitCount: bag.submitCount, serverErrorCount: bag.serverErrorCount};
  }, [bag.submitCount, bag.isValid, firstErrorKey, fieldNode, name, bag.serverErrorCount]);
};

const Field = forwardRef(
  ({as: Comp = InputWithLabel, name, submitOnCmdEnter, ...props}, passedRef) => {
    const {node, ref} = useGetNodeFromRef(passedRef);
    const [field, meta] = useNeoField(name);
    const bag = useNeoBag();
    useFocusOnError({fieldNode: node, name, bag});

    const errors = (((!meta.focussed && meta.changed) || bag.submitCount > 0) && meta.errors) || [];
    const allProps = {
      ref,
      name,
      showErrors: errors.slice(0, 1),
      hasPendingValidation: meta.pendingValidation,
      ...props,
      ...field,
      onBlur: (e) => {
        if (props.onBlur) props.onBlur(e);
        if (field.onBlur) field.onBlur(e);
      },
      onChange: (e) => {
        if (props.onChange) props.onChange(e);
        if (field.onChange) field.onChange(e);
      },
      onFocus: (e) => {
        if (props.onFocus) props.onFocus(e);
        if (field.onFocus) field.onFocus(e);
      },
    };
    return submitOnCmdEnter ? (
      <Comp {...allProps} onCmdEnter={bag.handleSubmit} />
    ) : (
      <Comp {...allProps} />
    );
  }
);

XForm.Field = Field;

// based on https://github.com/manishsaraan/email-validator/blob/113194f436418b4d868d3950d9a50700846eced7/index.js
const tester =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const isValidEmail = (email) => {
  if (!email) return false;
  const emailParts = email.split("@");
  if (emailParts.length !== 2) return false;

  const [account, address] = emailParts;
  if (account.length > 64) return false;
  if (address.length > 255) return false;

  const domainParts = address.split(".");
  if (domainParts.some((part) => part.length > 63)) return false;

  if (!tester.test(email)) return false;
  return true;
};

const defaultRules = {
  isRequired: [(v) => v !== null && v !== undefined && v !== "", "Required"],
  isEmail: [isValidEmail, "Not a valid email"],
  isDigits: [(v) => /^\d*$/.test(v), "Digits only"],
  minLength: (num) => [(v) => v.length >= num, `Needs to be at least ${num} characters long`],
  maxLength: (num) => [(v) => v.length <= num, `May have at most ${num} characters`],
  isUsername: [
    (val) => /^(|[A-Za-z0-9][\w-]*)$/.test(val),
    "may only consist of letters, numbers, '-' and '_'",
  ],
};

export {XForm, defaultRules as rules, SubmitButton};

import {lazy, Suspense} from "react";
import {Spinner} from "@cdx/common";

const LazyMarkdown = lazy(
  () => import(/* webpackChunkName: "MarkdownRenderer" */ "./InnerMarkdown")
);

const Markdown = (props) => (
  <Suspense fallback={<Spinner />}>
    <LazyMarkdown {...props} />
  </Suspense>
);

export default Markdown;

import * as v from "valibot";
import type {MutableRefObject} from "react";
import {useEffect, useRef} from "react";
import {useNeoFormWithAdapter, type CreateArgs, type NeoForm} from "./create-neo-form";
import type {Adapter, NeoFormError, ParseResult} from "./utils";

type ErrorNode = {errorKeys: Set<string>; children: Record<string, ErrorNode>};

const partialParse = <TSchema extends v.BaseSchema<any, any, any>>(
  schema: TSchema,
  values: v.InferInput<TSchema>,
  errorTree: ErrorNode
): Partial<v.InferOutput<TSchema>> => {
  switch (schema.type) {
    case "intersect": {
      const intersectSchema = schema as unknown as v.IntersectSchema<any, any>;
      const parsedValues: Record<string, any> = {};
      for (const option of intersectSchema.options) {
        Object.assign(parsedValues, partialParse(option, values, errorTree));
      }
      return parsedValues;
    }
    case "variant": {
      const variantSchema = schema as unknown as v.VariantSchema<any, any, any>;
      for (const option of variantSchema.options) {
        const res = partialParse(option, values, errorTree);
        if (variantSchema.key in res) return res;
      }
      return {};
    }
    case "object": {
      const objectSchema = schema as unknown as v.ObjectSchema<any, any>;
      if (Object.keys(errorTree).length > 0) {
        const parsedValues: Record<string, any> = {};
        const omitKeys = new Set<any>(errorTree.errorKeys);
        for (const key of Object.keys(errorTree.children)) {
          if (key in objectSchema.entries) {
            omitKeys.add(key);
            parsedValues[key] = partialParse(
              objectSchema.entries[key],
              values[key],
              errorTree.children[key]
            );
          }
        }
        const parseRes = v.safeParse(v.omit(objectSchema, [...omitKeys] as any), values);
        return {...parsedValues, ...(parseRes.success ? parseRes.output : {})};
      } else {
        // if object was wrapped with nullable, values can be empty
        if (!values) return values;
        return v.parse(objectSchema, values);
      }
    }
    case "record": {
      const recordSchema = schema as unknown as v.RecordSchema<any, any, any>;
      const parsedValues: Record<string, any> = {};
      for (const [key, value] of Object.entries(values)) {
        parsedValues[key] = partialParse(recordSchema.value, value, errorTree.children[key] || {});
      }
      return parsedValues;
    }
    case "nullable":
    case "nullish": {
      const nullableSchema = schema as unknown as v.NullableSchema<any, any>;
      return partialParse(nullableSchema.wrapped, values, errorTree);
    }
    default: {
      return {};
    }
  }
};
const issueToPathList = (issue: v.BaseIssue<any>): string[] => {
  if (!issue.path) return ["<unknown>"];
  const path = issue.path.map((p) => ("key" in p ? (p.key as string) : "<unknown>"));
  return path.filter(Boolean);
};

const buildErrorTree = (issues: v.BaseIssue<any>[]): ErrorNode => {
  const rootNode: ErrorNode = {children: {}, errorKeys: new Set()};
  for (const issue of issues) {
    let node = rootNode;
    const path = issueToPathList(issue);
    for (const dir of path.slice(0, -1)) {
      if (!(dir in node.children)) {
        node.children[dir] = {errorKeys: new Set(), children: {}};
      }
      node = node.children[dir];
    }
    node.errorKeys.add(path.slice(-1)[0]);
  }
  return rootNode;
};

export const bestEffortParse = <TSchema extends v.BaseSchema<any, any, any>>(opts: {
  schema: TSchema;
  values: v.InferInput<TSchema>;
}): ParseResult<v.InferOutput<TSchema>> => {
  const {schema, values} = opts;

  const val = v.safeParse(schema, values);
  if (val.success) {
    return {errorsByPath: null, values: val.output};
  } else {
    const errors: Record<string, NeoFormError[]> = {};
    for (const e of val.issues as v.BaseIssue<any>[]) {
      const currPath = issueToPathList(e).join(".");
      (errors[currPath] = errors[currPath] || []).push({
        code: e.type,
        message: e.message,
        ctx: e,
      });
    }
    const errorTree = buildErrorTree(val.issues);
    return {errorsByPath: errors, values: partialParse(schema, values, errorTree)};
  }
};

const createAdapter = <TSchema extends v.BaseSchema<any, any, any>>(
  schemaRef: MutableRefObject<TSchema>
): Adapter<v.InferInput<TSchema>, v.InferOutput<TSchema>> => ({
  parse: (values) => {
    return bestEffortParse({schema: schemaRef.current, values});
  },
});

export type ValibotFormArgs<TSchema extends v.BaseSchema<any, any, any>> = CreateArgs<
  v.InferInput<TSchema>,
  v.InferOutput<TSchema>
> & {schema: TSchema};

export const useValibotNeoForm = <TSchema extends v.BaseSchema<any, any, any>>(
  props: ValibotFormArgs<TSchema>
): NeoForm<v.InferInput<TSchema>, v.InferOutput<TSchema>> => {
  const {schema, ...rest} = props;
  const schemaRef = useRef(schema);
  useEffect(() => {
    schemaRef.current = schema;
  });
  return useNeoFormWithAdapter(rest, createAdapter(schemaRef));
};

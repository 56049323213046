import {forwardRef, cloneElement, ReactNode} from "react";
import type {CSSProperties} from "react";
import dsStyles from "../../css/index.css";
import type {DsStyles as RawStyleProps} from "../../css/index.css";
import cx from "../../utils/cx";

type Booleanify<E> = E extends "true" ? true : E;
export type StyleProps = {
  [Key in keyof RawStyleProps]?: Booleanify<keyof RawStyleProps[Key]> | false;
};

type PassThroughProps = {className?: string; id?: string; style?: CSSProperties};

export type BoxProps = StyleProps &
  PassThroughProps &
  (
    | {
        as?: keyof JSX.IntrinsicElements;
        children?: ReactNode;
        /**
         * @deprecated use `className={css({...})}` instead
         */
        styleChild?: null;
      }
    | {
        as?: null;
        /**
         * @deprecated use `className={css({...})}` instead
         */
        styleChild: true;
        children: ReactNode;
      }
  );

const applyProps = (props: BoxProps, defaultComp: keyof JSX.IntrinsicElements) => {
  let Comp: any = defaultComp;
  const compProps: any = {};
  const classList = [];
  for (const prop in props) {
    const val = props[prop as unknown as keyof BoxProps];
    if (val === null || val === undefined) continue;
    switch (prop) {
      case "as":
        Comp = val;
        break;
      case "children":
      case "styleChild":
      case "style":
      case "id":
        compProps[prop] = val;
        break;
      case "className":
        classList.push(val);
        break;
      default:
        const s = dsStyles[prop as keyof StyleProps];
        if (!s) {
          // let's allow `onMouseOver` etc, otherwise WithTooltip etc will become a pain?
          if (/^(on[A-Z]|data-)/.test(prop)) {
            compProps[prop] = val;
          } else {
            console.warn(`Can't apply style prop '${prop}' with value '${val as any}'`);
          }
          continue;
        }
        if (val === false) continue;
        classList.push((s as any)[val as any]);
    }
  }
  if (classList.length) {
    compProps.className = classList.join(" ");
  }
  return {Comp, compProps};
};

const createBox = (
  opts: StyleProps & Pick<BoxProps, "styleChild">,
  defaultComp: keyof JSX.IntrinsicElements = "div"
) => {
  return forwardRef<HTMLElement, BoxProps>((props, ref) => {
    const combinedProps = {...opts, ...props} as BoxProps;
    const {Comp, compProps} = applyProps(combinedProps, defaultComp);
    if (!combinedProps.styleChild) {
      return <Comp {...compProps} ref={ref} />;
    } else {
      return cloneElement(compProps.children as any, {
        className: cx(compProps.children.props.className, compProps.className),
        style: {...compProps.children.props.style, ...compProps.style},
      });
    }
  });
};

export const Box = createBox({}, "div");
export const Row = createBox({display: "flex", flexDir: "row"}, "div");
export const Col = createBox({display: "flex", flexDir: "column"}, "div");

type LabelTextTypes =
  | "label16"
  | "label14"
  | "label12"
  | "label12light"
  | "label11"
  | "label11light"
  | "label11Caps"
  | "label10Caps";

const labelTextTypeSet = new Set<LabelTextTypes>([
  "label16",
  "label14",
  "label12",
  "label12light",
  "label11",
  "label11light",
  "label11Caps",
  "label10Caps",
]);

export type TextProps = Omit<StyleProps, "textType"> &
  PassThroughProps & {
    as?: keyof JSX.IntrinsicElements;
    children?: ReactNode;
    style?: CSSProperties;
    className?: string;
  } & (
    | {
        type: Exclude<StyleProps["textType"], LabelTextTypes>;
        noOverflow?: undefined;
      }
    | {
        type: LabelTextTypes;
        noOverflow?: boolean;
      }
  );

export const Text = forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const {type, noOverflow, ...rest} = props;
  const {Comp, compProps} = applyProps(
    {...rest, textType: type, singleLine: !noOverflow && labelTextTypeSet.has(type as any)},
    "div"
  );
  return <Comp {...compProps} ref={ref} />;
});

export type CSSProps = {
  [Key in keyof RawStyleProps]?: keyof RawStyleProps[Key];
};

export const css = (props: CSSProps, ...classNames: (string | undefined | null | false)[]) => {
  const l: string[] = [...(classNames.filter(Boolean) as string[])];
  for (const prop in props) {
    const val = props[prop as keyof StyleProps];
    if (val === null || val === undefined) continue;
    const s = dsStyles[prop as keyof StyleProps];
    l.push((s as any)[val as any]);
  }
  return l.join(" ");
};

import {
  ArrowOverlay,
  XForm as RawXForm,
  SubmitButton,
  XPlainButton,
  XTextButton,
  rules,
  useNeoBag,
  useToggle,
  TooltipForChild,
  useNextDropDown,
  shrinker,
} from "@cdx/common";
import useMutation from "../../lib/hooks/useMutation";
import {ArenaCtx, CardPanelBag} from "./card-panel-utils";
import {
  Box,
  Col,
  css,
  DSIconButton,
  DSIconPencil,
  DSIconPlus,
  DSIconPyramid,
  DSIconSort,
  DSIconTrash,
  Row,
  Text,
} from "@cdx/ds";
import {ReactNode, lazy, useMemo} from "react";
import {useModalWithData as RawUseModalWithData} from "../../components/Modals";
import {api} from "../../lib/api";
import SwimlaneLabel, {useHoverWaiter} from "./SwimlaneLabel";
import {useCardContainer} from "../card-selection/useCardContainer";
import messenger from "../../lib/messenger";
import {getCardOrderUpdateMutationName} from "./manually-ordererd-card-utils";
import {NextQuickAddButton} from "./QuickAddButton";
import {Card} from "../../cdx-models/Card";
import uiClasses from "@cdx/common/xui/ui.css";
import {FEATURE_FLAGS, hasFeatureFlag} from "../../components/useFeatureFlag";
import {Account} from "../../cdx-models/Account";

const XForm = RawXForm as any;
const useModalWithData = RawUseModalWithData as any;

type EditZoneOverlayProps = {
  overlayProps: any;
  close: () => void;
  arenaCtx: ArenaCtx;
  label: string | null;
  cards: any[];
  zoneLabels: (string | null)[];
};

const EditZoneOverlay = ({
  overlayProps,
  close,
  arenaCtx,
  label,
  cards,
  zoneLabels,
}: EditZoneOverlayProps) => {
  const {id} = arenaCtx.model;
  const existingSet = new Set<string | null>(zoneLabels);
  const cardIds = cards.map((c) => c.id);
  existingSet.delete(label);
  const [doUpdateContainer] = useMutation(...getCardOrderUpdateMutationName(arenaCtx));
  const [doUpdateCardOrder] = useMutation("cardOrders", "updateLabel");
  const handleSubmit = (data: any) => {
    const next = zoneLabels.map((l) => (l === label ? data.label : l));
    return Promise.all([
      doUpdateContainer({id, manualOrderLabels: next}),
      ...(cardIds.length > 0
        ? [doUpdateCardOrder({cardIds, context: arenaCtx.type, label: data.label})]
        : []),
    ]).then(close);
  };
  const handleDelete = () => {
    const next = zoneLabels.filter((l) => l !== label);
    return doUpdateContainer({id, manualOrderLabels: next}).then(close);
  };
  return (
    <ArrowOverlay bg="white" arrowSize="xs" {...overlayProps}>
      <Box px={3} py={2} relative>
        <Box absolute right="0" top="0" pr="12px" pt="8px">
          <XTextButton
            disabled={cards.length > 0 || zoneLabels.length < 2}
            tooltip={
              cards.length > 0
                ? "Remove all cards before deleting this zone"
                : zoneLabels.length < 2
                  ? "Can't delete the last zone"
                  : "Remove zone"
            }
            color="redOnHover"
            onClick={handleDelete}
            square
            size="sm"
          >
            <DSIconTrash size={16} />
          </XTextButton>
        </Box>
        <XForm
          buttonLabel="Update"
          buttonProps={{color: "purple"}}
          rules={{
            label: [
              rules.isRequired,
              [(val: any) => !existingSet.has(val), "This label already exists"],
            ],
          }}
          initialValues={{label: label || ""}}
          onSubmit={handleSubmit}
          className={css({display: "flex", flexDir: "column", sp: "16px"})}
        >
          <XForm.Field name="label" autoFocus />
        </XForm>
      </Box>
    </ArrowOverlay>
  );
};

type EditZoneLabelProps = {
  label: string | null;
  arenaCtx: ArenaCtx;
  cards: Card[];
  zoneLabels: (string | null)[];
};

const EditZoneLabel = ({label, arenaCtx, cards, zoneLabels}: EditZoneLabelProps) => {
  const {ref, toggle, overlayElement, isOpen} = useNextDropDown({
    overlayProps: {
      placement: "right",
      distanceFromAnchor: 10,
      renderOverlay: ({close, ...props}: any) => (
        <EditZoneOverlay
          overlayProps={props}
          arenaCtx={arenaCtx}
          zoneLabels={zoneLabels}
          label={label}
          close={close}
          cards={cards}
        />
      ),
    },
  });
  return (
    <>
      {overlayElement}
      <TooltipForChild tooltip={isOpen ? null : "Edit zone"} ref={ref}>
        <DSIconButton
          onClick={toggle}
          variant="secondary"
          size="sm"
          icon={<DSIconPencil />}
          active={isOpen}
        />
      </TooltipForChild>
    </>
  );
};

const OrderZonesOverlay = lazy(
  () => import(/* webpackChunkName: "OrderZonesOverlay" */ "./OrderZonesOverlay")
);

type EditOrderButtonProps = {
  arenaCtx: ArenaCtx;
  zoneLabels: (string | null)[];
  unlabelledCardIds: string[];
};

const EditOrderButton = ({arenaCtx, zoneLabels, unlabelledCardIds}: EditOrderButtonProps) => {
  const [showManage, manageActions] = useToggle();
  const renderModal = useModalWithData(showManage, {
    onClose: manageActions.off,
    bg: "gray700",
    hideClose: true,
    width: 600,
  });

  const {id} = arenaCtx.model;
  const [doUpdate] = useMutation(...getCardOrderUpdateMutationName(arenaCtx));
  const handleSaveOrder = async (newOrder: string[]) => {
    if (newOrder[0] !== zoneLabels[0] && unlabelledCardIds.length > 0) {
      // if the first lane is moved, ensure that all cards wuthout a label get assigned the first lane's label
      // so they move along with it
      await api.mutate.cardOrders.addAfter({
        targetId: null,
        cardIds: unlabelledCardIds,
        context: arenaCtx.type,
        label: zoneLabels[0],
      });
    }
    return doUpdate({id, manualOrderLabels: newOrder}).then(manageActions.off);
  };
  return (
    <>
      {renderModal(() => (
        <OrderZonesOverlay
          onCancel={manageActions.off}
          onSave={handleSaveOrder}
          zoneLabels={zoneLabels}
        />
      ))}
      <TooltipForChild tooltip="Re-order zones">
        <DSIconButton
          variant="secondary"
          icon={<DSIconSort />}
          size="sm"
          onClick={manageActions.toggle}
        />
      </TooltipForChild>
    </>
  );
};

const ButtonWithinInput = (props: any) => {
  const bag = useNeoBag();
  return <SubmitButton as={XPlainButton} formBag={bag} color="purple" {...props} />;
};

type CreateZoneProps = {
  arenaCtx: ArenaCtx;
  zoneLabels: (string | null)[];
  hideEmptyZones?: boolean;
};
export const CreateZone = ({arenaCtx, zoneLabels, hideEmptyZones}: CreateZoneProps) => {
  const [open, {toggle, off}] = useToggle();
  const {id} = arenaCtx.model;
  const existingSet = new Set(zoneLabels);
  const [doUpdate] = useMutation(...getCardOrderUpdateMutationName(arenaCtx));
  const handleSubmit = ({label}: any) => {
    return doUpdate({id, manualOrderLabels: [...zoneLabels, label]}).then(() => {
      messenger.send(
        hideEmptyZones ? (
          <>
            Zone <b>{shrinker(label, 32)}</b> created.
            <br />
            (Hidden due to sort settings)
          </>
        ) : (
          <>
            Zone <b>{shrinker(label, 32)}</b> created.
          </>
        ),
        {msToRemove: hideEmptyZones ? 5000 : 2000, type: undefined}
      );
      off();
    });
  };
  return (
    <Col align="start" sp={4}>
      <DSIconButton
        size="sm"
        icon={<DSIconPlus />}
        onClick={toggle}
        active={open}
        label="Add Zone"
        variant="tertiary"
      />
      {open && (
        <XForm
          rules={{
            label: [
              rules.isRequired,
              [(val: any) => !existingSet.has(val), "This label already exists"],
            ],
          }}
          initialValues={{label: ""}}
          onSubmit={handleSubmit}
          className={css({
            display: "flex",
            flexDir: "column",
            rounded: 4,
            borderWidth: 1,
            borderColor: "default",
            colorTheme: "white",
            bg: "foreground",
            sp: "16px",
            px: "16px",
            py: "12px",
            maxWidth: "20rem",
          })}
        >
          <XForm.Field
            name="label"
            autoFocus
            postfix={<ButtonWithinInput>Add</ButtonWithinInput>}
          />
          <Box color="secondary" size={12}>
            Zones are available when applying manual order. They allow you to group cards according
            to your preferences. These are shared with the team.
          </Box>
        </XForm>
      )}
    </Col>
  );
};

type ManualGroupsArg = {
  manualOrderLabels: string[];
  orderedCardWithLabel: {card: any; label: false | string | null}[];
};

export const useManualGroups = ({manualOrderLabels, orderedCardWithLabel}: ManualGroupsArg) => {
  return useMemo(() => {
    const entries = (manualOrderLabels.length ? manualOrderLabels : [null]).map(
      (l) =>
        [l, {cards: [], label: l}] as [
          string | null | false,
          {cards: any[]; label: string | false | null},
        ]
    );
    const myMap = new Map(entries);
    const badLabelCardIds: string[] = [];
    const defaultLane = entries[0][1];
    orderedCardWithLabel.forEach(({card, label}) => {
      // don't show cards if they just received a card_orders entry
      // a bit hacky, but seems to interact well with the inflight logic
      // within SortableCardList
      if (label === false) return;
      const group = myMap.get(label);
      if (group) {
        group.cards.push(card);
      } else {
        defaultLane.cards.push(card);
        badLabelCardIds.push(card.id);
      }
    });
    return [myMap, badLabelCardIds] as const;
  }, [manualOrderLabels, orderedCardWithLabel]);
};

type ManageButtonsProps = {
  bag: CardPanelBag;
  label: string | null;
  zoneLabels: (string | null)[];
  unlabelledCardIds: string[];
  allowEditZones: boolean;
  targetProps: any;
  cards: Card[];
};

const ManageButtons = ({
  bag,
  label,
  zoneLabels,
  unlabelledCardIds,
  allowEditZones,
  targetProps,
  cards,
}: ManageButtonsProps) => {
  const {arenaCtx, canCreateCard} = bag;
  if (!allowEditZones && !canCreateCard) return null;
  return (
    <Row ml="auto">
      {allowEditZones && (
        <Row
          className={uiClasses.hideElement}
          sp="4px"
          bg="foreground"
          pl="8px"
          style={{marginRight: -4}}
        >
          <EditZoneLabel label={label} arenaCtx={arenaCtx} cards={cards} zoneLabels={zoneLabels} />
          {zoneLabels.length > 1 && (
            <EditOrderButton
              arenaCtx={arenaCtx}
              zoneLabels={zoneLabels}
              unlabelledCardIds={unlabelledCardIds}
            />
          )}
        </Row>
      )}
      {canCreateCard && (
        <Box bg="foreground" pl="8px" className={uiClasses.hideElement}>
          <NextQuickAddButton
            targetProps={{orderInfo: {label, context: bag.arenaCtx.type}, ...targetProps}}
            arenaCtx={arenaCtx}
          />
        </Box>
      )}
    </Row>
  );
};

type ZoneLayoutProps = {
  children: ReactNode;
  label: string | null;
  cards: Card[];
  bag: CardPanelBag;
  allowEditZones: boolean;
  zoneLabels: (string | null)[];
  unlabelledCardIds: string[];
  idx: number;
  cardContainerKey: string;
  targetProps: any;
};
export const ZoneLayout = (props: ZoneLayoutProps) => {
  const {
    children,
    label,
    cards,
    bag,
    allowEditZones,
    zoneLabels,
    unlabelledCardIds,
    idx,
    cardContainerKey,
    targetProps,
  } = props;
  const {root, onLight, isDraggingCardIds, arenaCtx} = bag;

  useCardContainer({
    cardContainerKey,
    visibleCardIds: cards.map((c) => c.cardId),
    idx,
  } as any);

  const {handlers: waitHandlers, waited} = useHoverWaiter();

  return (
    <Col sp="8px" {...waitHandlers} className={uiClasses.hideContainer}>
      <SwimlaneLabel
        cards={cards}
        waitedForSelection={Boolean(waited)}
        isDraggingCardIds={isDraggingCardIds}
        onLight={onLight}
        cardContainerKey={cardContainerKey}
        searchLabelFilter={{category: "zone", value: {context: arenaCtx.type, label}}}
        root={root}
        postfix={
          <ManageButtons
            bag={bag}
            label={label}
            zoneLabels={zoneLabels}
            unlabelledCardIds={unlabelledCardIds}
            allowEditZones={allowEditZones}
            targetProps={targetProps}
            cards={cards}
          />
        }
      >
        <Text type="label12light" color="primary">
          <DSIconPyramid size={16} inline className={css({color: "secondary"})} />{" "}
          {label ?? "Default Zone"}
        </Text>
      </SwimlaneLabel>
      {children}
    </Col>
  );
};

export const getCardOrderInfo = (card: Card, account: Account) => {
  const orders = card.cardOrders;
  if (!orders?.length) return null;
  if (!orders.some((o) => o.label)) return null;
  const withZoneIconOnCard = hasFeatureFlag(account, FEATURE_FLAGS.zoneIconOnCard);
  return {
    sprint: (card.sprint && orders.find((o) => o.context === "sprint")?.label) ?? null,
    milestone: (card.milestone && orders.find((o) => o.context === "milestone")?.label) ?? null,
    deck: (card.deck && orders.find((o) => o.context === "deck")?.label) ?? null,
    withZoneIconOnCard,
  };
};

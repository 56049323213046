import {useRef, useEffect, useState, useMemo} from "react";
import * as prod from "react/jsx-runtime";
import {unified} from "unified";
import remarkParse from "remark-parse";
import {cdxBreakPlugin} from "./plugins/cdxBreakPlugin";
import remark2rehype from "remark-rehype";
import rehype2react from "rehype-react";
import checkboxPlugin from "./plugins/checkbox-plugin";
import clickTargetPlugin from "./plugins/click-target-plugin";
import openLinksInNewTabRehypePlugin from "./plugins/open-link-in-new-tab-plugin";
import autoHeaderPlugin from "./plugins/auto-header-plugin";
import {MODAL_QUERY_PARAM, useModalAdress} from "../ModalRegistry";
import {Link, useLocation} from "react-router-dom";
import {imageStyles} from "./markdown.css";
import {useViewerInfoStore} from "../../features/image-viewer/ImageViewContext";
import {cx, Document} from "@cdx/common";
import {CdxImgForExternalUrl} from "../CdxImg";
import {externalLinkImagePlugin, uniqueImageKeyPlugin} from "./plugins/image-plugins";
import cdxGithubMarkdownPlugin from "./plugins/cdxGithubMarkdownPlugin";
import cdxMentionMarkdownPlugin from "./plugins/cdxMentionPlugin";
import cdxCardReferenceMarkdownPlugin from "./plugins/cdxCardReferencePlugin";
import cdxTagMarkdownPlugin from "./plugins/cdxTagPlugin";
import cdxEmojiMarkdownPlugin from "./plugins/cdxEmojiMarkdownPlugin";
import verbatimHtmlRehypePlugin from "./plugins/verbatim-html-rehype-plugin";
import {MagicTag} from "./CardTags";
import CardMention from "./CardMention";
import CardReference from "./CardReference";
import Emoji from "./Emoji";
import qs from "qs";

export const RawMarkdownImg = ({
  src,
  onClick,
  alt,
  title = alt,
  imageViewerKey,
  imageViewerImgKeyPrefix,
  imgDims = [500, 182],
  imageClassName,
  withinEditor,
  ...rest
}) => (
  <CdxImgForExternalUrl
    src={src}
    alt={alt}
    title={title}
    maxWidth={imgDims[0]}
    maxHeight={imgDims[1]}
    imgClassName={cx(
      imageStyles.raw,
      imageClassName,
      withinEditor ? imageStyles.types.withinEditor : imageStyles.types.normal
    )}
    outerComp="span"
    {...rest}
  />
);
const PreviewImg = ({imageViewerKey, src, imageViewerImgKeyPrefix, imgKey, ...rest}) => {
  const [myCount] = useState(() => `${imageViewerImgKeyPrefix}-${imgKey}`);
  const title = rest.title || rest.alt;
  useEffect(() => {
    return useViewerInfoStore.getState().addForKey(imageViewerKey, [myCount, {url: src, title}]);
  }, [imageViewerKey, myCount, src, title]);

  const to = useModalAdress({
    modal: `attachmentViewer.${imageViewerKey}.${myCount}`,
  });
  return (
    <Link to={to} className="img-link" onClick={(e) => e.stopPropagation()}>
      <RawMarkdownImg src={src} {...rest} />
    </Link>
  );
};

const SimplePreviewImg = ({src, title, ...rest}) => {
  const location = useLocation();
  const getLink = () => {
    const parsed = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (location.state?.$withinModal) return null;
    return {
      ...location,
      search: qs.stringify({
        ...parsed,
        [MODAL_QUERY_PARAM]: "imageViewer",
      }),
      state: {...location.state, modalState: {url: src}},
    };
  };
  const link = getLink();
  if (link) {
    return (
      <Link to={link} className="img-link">
        <RawMarkdownImg src={src} {...rest} />
      </Link>
    );
  } else {
    return <RawMarkdownImg src={src} {...rest} />;
  }
};

const MarkdownImg = ({isWithinLink, imgKey, ...props}) =>
  isWithinLink ? (
    <RawMarkdownImg {...props} />
  ) : props.imageViewerKey ? (
    <PreviewImg imgKey={imgKey} {...props} />
  ) : (
    <SimplePreviewImg {...props} />
  );

const InnerMarkdown = (props) => {
  const {
    children,
    onClick,
    parseCheckboxes,
    projectId,
    autoTitle,
    noCdxTransforms,
    variant,
    imageViewerKey,
    imageViewerImgKeyPrefix,
    imgDims,
    imageClassName,
    onCheckboxClick: _1,
    cardReferenceAddUrlState: _2,
    ...rest
  } = props;

  const imgDimsRef = useRef(imgDims);
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });

  const content = useMemo(() => {
    let processor = unified()
      .use(remarkParse)
      .use(cdxGithubMarkdownPlugin)
      .use(cdxBreakPlugin)
      .use(cdxEmojiMarkdownPlugin, {render: (value) => <Emoji decoratedText={value} />});

    if (!noCdxTransforms) {
      processor = processor
        .use(cdxMentionMarkdownPlugin, {render: (id) => <CardMention userId={id} />})
        .use(cdxCardReferenceMarkdownPlugin, {
          render: (reference) => (
            <CardReference id={reference} addUrlState={propsRef.current.cardReferenceAddUrlState} />
          ),
        })
        .use(cdxTagMarkdownPlugin, {
          render: (tag) => (
            <MagicTag tag={tag} projectId={projectId} onDark={variant === "onDark"} />
          ),
        });
    }

    if (parseCheckboxes) {
      processor = processor.use(checkboxPlugin, {
        getProps: () => ({
          onChange: (e) => {
            propsRef.current.onCheckboxClick?.(e);
          },
          disabled: parseCheckboxes === "disabled" || !Boolean(propsRef.current.onCheckboxClick),
        }),
      });
    }
    if (autoTitle) processor = processor.use(autoHeaderPlugin);

    processor = processor
      .use(verbatimHtmlRehypePlugin)
      .use(remark2rehype)
      .use(openLinksInNewTabRehypePlugin)
      .use(clickTargetPlugin)
      .use(externalLinkImagePlugin)
      .use(uniqueImageKeyPlugin)
      .use(rehype2react, {
        Fragment: prod.Fragment,
        jsx: prod.jsx,
        jsxs: prod.jsxs,
        components: {
          a: ({href, $cdxInternalLink, ...r}) =>
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            $cdxInternalLink ? <Link to={href} {...r} /> : <a href={href} {...r} />,
          img: (imgProps) => (
            <MarkdownImg
              {...imgProps}
              imageViewerKey={imageViewerKey}
              imageViewerImgKeyPrefix={imageViewerImgKeyPrefix}
              imgDims={imgDimsRef.current}
              imageClassName={imageClassName}
            />
          ),
        },
      });
    return processor.processSync(children).result;
  }, [
    parseCheckboxes,
    autoTitle,
    children,
    projectId,
    variant,
    imageViewerKey,
    imageViewerImgKeyPrefix,
    imageClassName,
    noCdxTransforms,
  ]);
  return (
    <Document onClick={onClick} variant={variant} {...rest}>
      {content}
    </Document>
  );
};

export default InnerMarkdown;

import {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ClickOutside,
  Spinner,
  DefaultOverlay,
  SpawnAnchoredOverlayWithNode,
  cx,
  XRow,
} from "@cdx/common";
import xcolors from "./xui/xcolors";
import {XCol, XText} from "./xui";
import {fancySelectStyles as styles} from "./FancySelect.css";
import {useFocusLockGroup} from "./Modals";
import FocusLock from "react-focus-lock";
import dsStyles from "@cdx/ds/css/index.css";
import {Box, DSIconChevronDown, DSIconSearch} from "@cdx/ds";

const FancySelectOption = ({
  isFocused,
  value,
  onFocusChange,
  optionKey,
  onSelectFocused,
  children,
}) => {
  const nodeRef = useRef(null);
  useEffect(() => {
    if (isFocused) {
      nodeRef.current?.scrollIntoView(false);
    }
  }, [isFocused]);
  return (
    <button
      type="button"
      className={cx(styles.option.base, isFocused && styles.option.selected)}
      onClick={(e) => {
        onSelectFocused(value);
        e.stopPropagation();
      }}
      ref={nodeRef}
    >
      {children}
    </button>
  );
};

const FocusableList = forwardRef((props, ref) => {
  const {currentKey, options, onSelect, extractKey, children} = props;
  const propsRef = useRef(props);
  useEffect(() => {
    propsRef.current = props;
  });

  const optionsTuple = options.map((o) => ({
    key: extractKey(o),
    option: o,
  }));
  const optionKeys = optionsTuple.map(({key}) => key);
  const [focusedKey, setFocusedKey] = useState(() =>
    optionKeys.includes(currentKey) ? currentKey : optionKeys[0]
  );

  useEffect(() => {
    const innerOptionKeys = options.map((o) => propsRef.current.extractKey(o));
    const isNotPresent = innerOptionKeys.indexOf(focusedKey) === -1;
    if (isNotPresent) {
      const nextFocus = innerOptionKeys.includes(propsRef.current.currentKey)
        ? propsRef.current.currentKey
        : innerOptionKeys[0];

      setFocusedKey(nextFocus);
    }
  }, [options, focusedKey]);

  // FIXME: focusedKey should be owned by the parent...
  useImperativeHandle(ref, () => ({
    focusBelow: () => {
      const currIndex = optionKeys.indexOf(focusedKey);
      if (currIndex < optionKeys.length - 1) setFocusedKey(optionKeys[currIndex + 1]);
    },
    focusAbove: () => {
      const currIndex = optionKeys.indexOf(focusedKey);
      if (currIndex > 0) setFocusedKey(optionKeys[currIndex - 1]);
    },
    selectFocused: () => {
      const foundOption = optionsTuple.find(({key}) => key === focusedKey);
      if (foundOption) onSelect(foundOption.option);
    },
  }));

  return (
    <XCol>
      {optionsTuple.map(({option, key}) => (
        <FancySelectOption
          key={key}
          isFocused={key === focusedKey}
          onFocusChange={setFocusedKey}
          onSelectFocused={onSelect}
          optionKey={key}
          value={option}
        >
          {children(option)}
        </FancySelectOption>
      ))}
    </XCol>
  );
});

const NoResults = ({children}) => (
  <XCol px={3} py={2}>
    <XText type="label" color="gray500" size={1}>
      {children}
    </XText>
  </XCol>
);

const FilterableList = ({
  getSearchText,
  options,
  showMax,
  onDeactivate,
  children,
  extractKey,
  onSelect,
  currentValue,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNode, setSearchNode] = useState(null);
  const q = searchQuery.trim().toLowerCase();
  const getSearchTextRef = useRef(getSearchText);

  const filteredOptions = useMemo(() => {
    let results;
    if (q.length === 0) {
      results = options;
    } else {
      const bucket1 = [];
      const bucket2 = [];
      const bucket3 = [];
      options.forEach((option) => {
        const currentTerm = getSearchTextRef.current(option).toLowerCase();
        const index = currentTerm.indexOf(q);
        if (index === 0) {
          bucket1.push(option);
        } else if (index > 0 && currentTerm[index - 1].match(/[^a-z0-9]/)) {
          // if not in the middle of a word
          bucket2.push(option);
        } else if (index > 0) {
          bucket3.push(option);
        }
      });
      results = [...bucket1, ...bucket2, ...bucket3];
    }
    if (showMax) results = results.slice(0, showMax);
    return results;
  }, [q, showMax, options]);

  const listRef = useRef(null);
  const focusAbove = () => {
    listRef.current && listRef.current.focusAbove();
  };
  const focusBelow = () => listRef.current && listRef.current.focusBelow();
  const selectFocused = () => listRef.current && listRef.current.selectFocused();

  const handleSearchKeyDown = (e) => {
    if (e.which === 9) {
      // TAB
      if (e.shiftKey) focusAbove();
      else focusBelow();
    } else if (e.which === 27) {
      // ESC
      onDeactivate();
    } else if (e.which === 38) {
      // UP
      focusAbove();
    } else if (e.which === 40) {
      // DOWN
      focusBelow();
    } else if (e.which === 13) {
      // Enter
      selectFocused();
      // No Space (e.which === 32) support, as sometimes you want to enter space as part of the query
    } else {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (searchNode) {
      // FIXME: is there a way to better coordinate between overlay placer and focus events??
      // i.e. the overlay placer first places the overlay in a hidden spot to allow measuring,
      // doing focus() at that stage leads to scrolling to that hidden spot..., same with `autoFocus={true}`
      setTimeout(() => {
        searchNode.focus();
      }, 200);
    }
  }, [searchNode]);

  const focusLockGroup = useFocusLockGroup();

  return (
    <FocusLock autoFocus={false} disabled={!focusLockGroup} group={focusLockGroup} as={XCol} sp={1}>
      <XCol relative>
        <Box absolute pl="6px" pt="6px" color="secondary">
          <DSIconSearch size={20} />
        </Box>
        <input
          className={styles.input}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value || "")}
          onKeyDown={handleSearchKeyDown}
          ref={setSearchNode}
          placeholder="type to filter"
        />
      </XCol>
      {!filteredOptions.length ? (
        <NoResults>No options for your filter.</NoResults>
      ) : (
        <FocusableList
          onSelect={onSelect}
          extractKey={extractKey}
          ref={listRef}
          options={filteredOptions}
          currentKey={currentValue && extractKey(currentValue)}
        >
          {children}
        </FocusableList>
      )}
    </FocusLock>
  );
};

const Options = ({
  overlayProps,
  getOptions,
  extractKey,
  getSearchText,
  value,
  children,
  showMax,
  onChange,
  onDeactivate,
  instanceToValue,
}) => {
  const [, setVersion] = useState(1);
  const handleSelectValue = (selectedVal) => {
    if (selectedVal !== value && onChange) {
      onChange(instanceToValue ? instanceToValue(selectedVal) : selectedVal);
    }
    onDeactivate();
  };

  const result = getOptions() || [];
  let content;
  if (result.then && typeof result.then === "function") {
    content = (
      <div style={{height: 30, width: 200}}>
        <Spinner size={30} />
      </div>
    );
    result.then(() => setVersion((v) => v + 1));
  } else {
    content =
      result.length > 0 ? (
        <FilterableList
          options={result}
          extractKey={extractKey}
          onDeactivate={onDeactivate}
          onSelect={handleSelectValue}
          getSearchText={getSearchText}
          currentValue={value}
          showMax={showMax}
        >
          {children}
        </FilterableList>
      ) : (
        <NoResults>No options found.</NoResults>
      );
  }

  const maxHeight = Math.min(overlayProps.style.maxHeight || 410, 410);

  return (
    <ClickOutside onClickOutside={onDeactivate}>
      {(handlers) => (
        <DefaultOverlay
          {...overlayProps}
          {...handlers}
          style={{...overlayProps.style, width: overlayProps.anchorPosition.width, maxHeight}}
        >
          {content}
        </DefaultOverlay>
      )}
    </ClickOutside>
  );
};

export default class FancySelect extends Component {
  state = {
    isOpen: false,
    containerNode: null,
  };

  componentDidMount() {
    if (this.props.autoFocus) {
      this.activate();
    }
  }

  componentWillUnmount() {
    if (this.props.selectOnUnmount) {
      if (this.state.isOpen) {
      }
    }
  }

  activate = () => {
    this.setState({isOpen: true});
  };

  focus() {
    if (this.state.containerNode) {
      this.state.containerNode.focus();
    }
    // this.activate();
  }

  // return value is needed for deselect-cascading
  deactivate = () => {
    if (!this.state.isOpen) return false;
    this.setState({isOpen: false}, () => {
      if (this.state.containerNode) this.state.containerNode.focus();
      if (this.props.onClose) this.props.onClose();
    });
    return true;
  };

  handleContainerClick = () => {
    if (this.state.isOpen) {
      this.deactivate();
    } else {
      this.activate();
    }
    if (this.props.onClick) this.props.onClick();
  };

  handleContainerKeyDown = (e) => {
    if (e.which === 32 || e.which === 13) {
      // SPACE/Enter
      this.activate();
    } else {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  getInstance = () => {
    const {value, instanceToValue, getOptions, valueToInstance} = this.props;
    if (!instanceToValue) return value;
    if (value && valueToInstance) return valueToInstance(value);
    const result = getOptions();
    if (result && result.then && typeof result.then === "function") {
      return null;
    }
    return result && result.find((instance) => instanceToValue(instance) === value);
  };

  setContainerNode = (n) => {
    this.setState({containerNode: n});
  };

  render() {
    const {
      children,
      value: rawValue,
      placeholder,
      style,
      className,
      getOptions,
      extractKey,
      getSearchText,
      showMax,
      onChange,
      instanceToValue,
    } = this.props;
    const {isOpen, containerNode} = this.state;
    const value = this.getInstance();
    return (
      <SpawnAnchoredOverlayWithNode
        placement="bottom"
        renderOverlay={(overlayProps) => (
          <Options
            overlayProps={overlayProps}
            getOptions={getOptions}
            extractKey={extractKey}
            getSearchText={getSearchText}
            value={rawValue}
            children={children}
            showMax={showMax}
            onChange={onChange}
            onDeactivate={this.deactivate}
            instanceToValue={instanceToValue}
          />
        )}
        isOpen={isOpen}
        forcePlacement
        node={containerNode}
      >
        <XRow
          align="center"
          border="gray400"
          rounded="sm"
          px={1}
          py={0}
          sp={1}
          bg="white"
          onClick={this.handleContainerClick}
          tabIndex={isOpen ? undefined : "0"}
          onKeyDown={this.handleContainerKeyDown}
          ref={this.setContainerNode}
          style={style}
          className={cx(className, styles.container, dsStyles.colorTheme.white)}
        >
          <div className={styles.valueLabel}>
            {value !== null && value !== undefined ? (
              children(value)
            ) : (
              <XText color="gray500">{placeholder || "none"}</XText>
            )}
          </div>
          <DSIconChevronDown size={16} style={{flex: "none", color: xcolors.purple500}} />
        </XRow>
      </SpawnAnchoredOverlayWithNode>
    );
  }
}

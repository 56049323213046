import {useState, useEffect, useRef, useMemo} from "react";
import maxBy from "lodash/maxBy";
import useWindowSize from "../hooks/useWindowSize";
import shallowEqual from "shallowequal";

function getTopDelta(top, overlayHeight, viewportMargin, windowSize) {
  const topEdgeOffset = top - (window.pageYOffset || document.body.scrollTop) - viewportMargin;
  const bottomEdgeOffset =
    top + overlayHeight - (window.pageYOffset || document.body.scrollTop) + viewportMargin;

  let hug = null,
    delta = 0;
  if (bottomEdgeOffset > windowSize.height) {
    hug = "bottom";
    delta = windowSize.height - bottomEdgeOffset;
  }
  if (topEdgeOffset < 0) {
    hug = hug ? "both" : "left";
    delta = -topEdgeOffset;
  }
  return hug ? {hug, delta} : null;
}

function getLeftDelta(left, overlayWidth, viewportMargin, windowSize) {
  const leftEdgeOffset = left - viewportMargin;
  const rightEdgeOffset = left + overlayWidth + viewportMargin;
  let hug = null,
    delta = 0;
  if (rightEdgeOffset > windowSize.width) {
    hug = "right";
    delta = windowSize.width - rightEdgeOffset;
  }
  if (leftEdgeOffset < 0) {
    hug = hug ? "both" : "left";
    delta = -leftEdgeOffset;
  }
  return hug ? {hug, delta} : null;
}

const nextPlacement = {top: "right", right: "bottom", bottom: "left", left: "top"};

function calcOverlayPosition(
  preferredPlacement,
  overlayWidth,
  overlayHeight,
  anchorRect,
  windowSize,
  distanceFromAnchor,
  forcePlacement,
  viewportMargin,
  anchorAlignment,
  preventPlacement
) {
  if (overlayHeight === undefined) throw new Error("overlayHeight is undefined");
  const scores = [];

  let currentPlacement = preferredPlacement;
  let currentScore;
  const anchorHeight = anchorRect.bottom - anchorRect.top;
  const anchorWidth = anchorRect.right - anchorRect.left;

  while (scores.length < 4) {
    if (preventPlacement && currentPlacement === preventPlacement) {
      currentScore = -1;
    } else {
      switch (currentPlacement) {
        case "top":
          currentScore =
            (anchorRect.top - window.pageYOffset - viewportMargin - distanceFromAnchor) /
            overlayHeight;
          break;
        case "right":
          currentScore =
            (windowSize.width - (anchorRect.right + viewportMargin + distanceFromAnchor)) /
            overlayWidth;
          break;
        case "bottom":
          currentScore =
            (windowSize.height +
              window.pageYOffset -
              (anchorRect.bottom + viewportMargin + distanceFromAnchor)) /
            overlayHeight;
          break;
        case "left":
          currentScore = (anchorRect.left - viewportMargin - distanceFromAnchor) / overlayWidth;
          break;
        default:
          throw new Error(`Dunno: ${currentPlacement}`);
      }
    }
    // currentScore > 1 -> has enough space
    // currentScore === 1 -> fit's perfectly, might be due to forced maxWidth though
    if (currentScore > 1 || forcePlacement) break;
    scores.push({placement: currentPlacement, score: currentScore});
    currentPlacement = nextPlacement[currentPlacement];
  }
  if (scores.length === 4) {
    currentPlacement = maxBy(scores, "score").placement;
  }

  let positionLeft;
  let positionTop;
  let positionRight;
  let positionBottom;
  let arrowOffsetTop;
  let arrowOffsetLeft;
  let maxWidth;
  let maxHeight;

  const shrinkedHeight = Math.min(windowSize.height - 2 * viewportMargin, overlayHeight);
  const shrinkedWidth = Math.min(windowSize.width - 2 * viewportMargin, overlayWidth);

  if (currentPlacement === "left" || currentPlacement === "right") {
    if (overlayHeight >= windowSize.height - viewportMargin * 2) {
      maxHeight = windowSize.height - viewportMargin * 2;
    }
    positionTop = anchorRect.top + (anchorHeight - shrinkedHeight) / 2;
    const topDelta = getTopDelta(positionTop, shrinkedHeight, viewportMargin, windowSize);
    if (topDelta) {
      if (topDelta.hug === "bottom") {
        positionTop = undefined;
        positionBottom = viewportMargin;
      } else {
        positionTop += topDelta.delta;
      }
    }
    arrowOffsetTop = `${Math.min(
      98,
      Math.max(2, 50 * (1 - (2 * (topDelta ? topDelta.delta : 0)) / shrinkedHeight))
    )}%`;

    if (currentPlacement === "right") {
      positionLeft = anchorRect.right + distanceFromAnchor;
      maxWidth = windowSize.width - (anchorRect.right + viewportMargin + distanceFromAnchor);
    } else {
      // currentPlacement === "left"
      maxWidth = anchorRect.left - viewportMargin - distanceFromAnchor;
      positionRight = windowSize.width - anchorRect.left + distanceFromAnchor;
    }
  } else {
    // currentPlacement === "top" || currentPlacement === "bottom"
    if (overlayWidth >= windowSize.width - viewportMargin * 2) {
      maxWidth = windowSize.width - viewportMargin * 2;
    }
    positionLeft =
      anchorAlignment === "middle"
        ? anchorRect.left + (anchorWidth - shrinkedWidth) / 2
        : anchorAlignment === "left"
          ? anchorRect.left
          : anchorRect.left + (anchorWidth - shrinkedWidth);

    const leftDelta = getLeftDelta(positionLeft, shrinkedWidth, viewportMargin, windowSize);
    if (leftDelta) {
      if (leftDelta.hug === "right") {
        positionLeft = undefined;
        positionRight = viewportMargin;
      } else {
        positionLeft += leftDelta.delta;
      }
    }
    arrowOffsetLeft = `${Math.min(
      98,
      Math.max(2, 50 * (1 - 2 * ((leftDelta ? leftDelta.delta : 0) / shrinkedWidth)))
    )}%`;
    if (currentPlacement === "bottom") {
      positionTop = anchorRect.bottom + distanceFromAnchor;
      maxHeight =
        windowSize.height +
        window.pageYOffset -
        (anchorRect.bottom + viewportMargin + distanceFromAnchor);
    } else {
      maxHeight = anchorRect.top - window.pageYOffset - viewportMargin - distanceFromAnchor;
      positionBottom =
        (document.body.clientHeight || window.innerHeight) - anchorRect.top + distanceFromAnchor;
    }
  }

  let transformOrigin = null;
  switch (currentPlacement) {
    case "top":
      transformOrigin = `${arrowOffsetLeft || 0} 100%`;
      break;
    case "right":
      transformOrigin = `${arrowOffsetLeft || 0} 100%`;
      break;
    case "bottom":
      transformOrigin = `${arrowOffsetLeft || 0} ${distanceFromAnchor}px`;
      break;
    case "left":
      transformOrigin = `${arrowOffsetLeft || 0} 100%`;
      break;
    default:
      throw new Error(`Dunno: ${currentPlacement}`);
  }

  return {
    positionLeft,
    positionRight,
    positionTop,
    positionBottom,
    arrowOffsetLeft,
    arrowOffsetTop,
    placement: currentPlacement,
    transformOrigin,
    maxWidth,
    maxHeight,
  };
}

const useIsSettledIn = () => {
  const [isSettledIn, setIsSettledIn] = useState(false);
  useEffect(() => {
    let id = setTimeout(() => {
      id = null;
      setIsSettledIn(true);
    }, 500);
    return () => {
      if (id) clearTimeout(id);
    };
  }, []);
  return isSettledIn;
};

// avoid jumping around if size changes
const regenotiatePosition = ({prev, next, windowSize, viewportMargin, overlayHeight}) => {
  if (prev === next) return prev;
  if (next === null) return next;
  if (next.placement === "right" || next.placement === "left") {
    if (next.style.top === prev.style.top) return next;
    const diff = prev.style.top - next.style.top;
    const maxHeight = windowSize.height - 2 * viewportMargin;
    if (prev.style.top + overlayHeight < maxHeight) {
      return {
        ...prev,
        arrowOffsetTop: `${50 * (1 - (2 * diff) / overlayHeight)}%`,
      };
    }
  }
  return next;
};

export const useFitOnScreen = ({
  overlayDims,
  preferredPlacement = "top",
  preventPlacement,
  anchorPosition,
  forcePlacement,
  viewportMargin = 15,
  distanceFromAnchor = 0,
  anchorAlignment = "middle",
}) => {
  const noDims = !overlayDims;
  const overlayWidth = overlayDims ? overlayDims.width : 1;
  const overlayHeight = overlayDims ? overlayDims.height : 1;
  const windowSize = useWindowSize();
  const isSettledIn = useIsSettledIn();
  const prevRef = useRef();
  const next = useMemo(() => {
    if (!anchorPosition) return null;
    if (noDims) {
      return {
        style: {
          left: viewportMargin,
          top: viewportMargin,
          maxHeight: windowSize.height - 2 * viewportMargin,
          maxWidth: windowSize.width - 2 * viewportMargin,
          zIndex: -1,
        },
        arrowOffsetLeft: 0,
        arrowOffsetTop: 0,
        placement: preferredPlacement,
      };
    } else {
      const calculated = calcOverlayPosition(
        (isSettledIn && prevRef.current && prevRef.current.returnVal.placement) ||
          preferredPlacement,
        overlayWidth,
        overlayHeight,
        anchorPosition,
        windowSize,
        distanceFromAnchor,
        forcePlacement || (isSettledIn && prevRef.current),
        viewportMargin,
        anchorAlignment,
        preventPlacement
      );
      return {
        style: {
          left: calculated.positionLeft,
          right: calculated.positionRight,
          top: calculated.positionTop,
          bottom: calculated.positionBottom,
          maxHeight: calculated.maxHeight,
          maxWidth: calculated.maxWidth,
          transformOrigin: calculated.transformOrigin,
          overflow: calculated.maxHeight || calculated.maxWidth ? "auto" : "hidden",
        },
        arrowOffsetLeft: calculated.arrowOffsetLeft,
        arrowOffsetTop: calculated.arrowOffsetTop,
        placement: calculated.placement,
      };
    }
  }, [
    preferredPlacement,
    overlayWidth,
    overlayHeight,
    anchorPosition,
    windowSize,
    distanceFromAnchor,
    forcePlacement,
    viewportMargin,
    anchorAlignment,
    noDims,
    isSettledIn,
    preventPlacement,
  ]);

  const returnVal =
    !isSettledIn ||
    !prevRef.current ||
    !shallowEqual(anchorPosition, prevRef.current.anchorPosition)
      ? next
      : regenotiatePosition({
          prev: prevRef.current.returnVal,
          next,
          windowSize,
          viewportMargin,
          overlayHeight,
        });
  useEffect(() => {
    prevRef.current = {returnVal, anchorPosition};
  }, [returnVal, anchorPosition]);
  return returnVal;
};

import "./lib/polyfills";
import "./lib/error-logger";

import {createRoot} from "react-dom/client";

const App =
  process.env.REACT_APP_MODE === "steamy"
    ? require("./features/steamy/SteamyApp").default
    : process.env.REACT_APP_MODE === "open"
      ? require("./features/public-project/PublicProjectApp").default
      : require("./App").default;

window.document.addEventListener("DOMContentLoaded", () => {
  const appEl = window.document.getElementById("app");

  const root = createRoot(appEl);
  root.render(<App tab="home" />);
  const loaderEl = window.document.getElementById("preload");
  if (loaderEl && loaderEl.parentNode) {
    loaderEl.parentNode.removeChild(loaderEl);
  }
});

import {useEffect} from "react";
import {create} from "zustand";
import cdxEnv from "../env";
import {cdxRequest} from "../lib/request";

const useDeploymentStore = create(() => ({
  replacedAt: null,
}));

const INTERVAL = 2 * 3600 * 1000; // two hours;

const checkInfo = (opts = {}) => {
  const {APP_NAME, DEPLOYMENT_LABEL} = cdxEnv;
  if (!APP_NAME || !DEPLOYMENT_LABEL) return;
  opts.timeoutId = setTimeout(
    () => {
      cdxRequest({
        path: "/services/deployment-info",
        method: "GET",
        query: {appName: APP_NAME, label: DEPLOYMENT_LABEL},
      }).then(
        (body) => {
          const {replacedAt} = body || {};
          useDeploymentStore.setState({replacedAt: replacedAt ? new Date(replacedAt) : null});
          checkInfo(opts);
        },
        () => checkInfo(opts)
      );
    },
    INTERVAL * (0.75 + Math.random() * 0.5)
  );
  return () => clearTimeout(opts.timeoutId);
};

export const DeploymentInfo = () => {
  useEffect(() => checkInfo(), []);
  return null;
};

export const OLD_RELEASE_MS = 7 * 24 * 3600 * 1000; // 7 days

export const useDeploymentAgeInMs = () => {
  const replacedAt = useDeploymentStore((s) => s.replacedAt);
  return replacedAt ? new Date().getTime() - replacedAt.getTime() : null;
};

import {mq, Spinner, useMedia} from "@cdx/common";
import {Box, css} from "@cdx/ds";
import {lazy, ReactNode, Suspense} from "react";
import {useTransition, animated} from "react-spring";
import {create} from "zustand";
import {SprintConfigId} from "../cdx-models/SprintConfig";

export type ShowEl = {el: ReactNode; width: number; type: "onboarding" | "calendar" | "msOverview"};

type Store = {
  showEl: null | ShowEl;
  // pristine is "true" initially, if it's pristine it means we
  // still need to calculate if it shall be open initially
  pristine: boolean;
  showOnboardingBar: () => void;
  showCalendar: () => void;
  showStatsOverview: (
    opts: {type: "milestones"; id?: undefined} | {type: "sprintConfig"; id: SprintConfigId}
  ) => void;
  close: () => void;
};

const OnboardingBarContents = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingBarContents" */ "../features/onboarding/OnboardingBarContents"
    )
);
const CalendarBar = lazy(
  () => import(/* webpackChunkName: "CalendarBar" */ "../pages/milestones/Calendar/CalendarBar")
);
const StatsOverviewBar = lazy(
  () =>
    import(
      /* webpackChunkName: "StatsOverviewBar" */ "../pages/milestones/MilestonesOverview/StatsOverviewBar"
    )
);

export const useSmartSidebarOpenStore = create<Store>((set) => ({
  showEl: null,
  showOnboardingBar: () =>
    set({pristine: false, showEl: {el: <OnboardingBarContents />, width: 350, type: "onboarding"}}),
  showCalendar: () =>
    set({pristine: false, showEl: {el: <CalendarBar />, width: 660, type: "calendar"}}),
  showStatsOverview: (opts) =>
    set({
      pristine: false,
      showEl: {el: <StatsOverviewBar {...opts} />, width: 640, type: "msOverview"},
    }),
  close: () => set({showEl: null, pristine: false}),
  pristine: true,
}));

const SmartSidebar = () => {
  const showEl = useSmartSidebarOpenStore((s) => s.showEl);

  const isSmall = useMedia(
    showEl && showEl.width > 400 ? "@media screen and (max-width: 2240px)" : mq.xl
  );

  const renderFn = useTransition(showEl, {
    from: {x: "100%", opacity: 0, presence: 0},
    initial: {x: "0%", opacity: 1, presence: 1},
    enter: {x: "0%", opacity: 1, presence: 1},
    leave: {x: "100%", opacity: 0.75, presence: 0},
    config: {tension: 2500, friction: 90},
  });

  return renderFn(({opacity, x, presence}, shown) =>
    shown ? (
      <>
        {!isSmall && (
          <animated.div
            style={{
              display: presence.to((v) => (v > 0.999 ? "block" : "none")),
              width: shown.width,
              flexShrink: 0,
            }}
          />
        )}
        <Box absolute inset="0" overflow="hidden" pointerEvents="none">
          <animated.aside
            style={{opacity, x, maxWidth: "100%", width: shown.width, zIndex: 5}}
            className={css({
              position: "absolute",
              top: "0",
              bottom: "0",
              right: "0",
              elevation: 200,
              colorTheme: "gray750",
              bg: "foreground",
              pointerEvents: "auto",
            })}
          >
            <Suspense fallback={<Spinner />}>{shown.el}</Suspense>
          </animated.aside>
        </Box>
      </>
    ) : null
  );
};

export default SmartSidebar;

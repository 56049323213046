import {useState} from "react";
import {iconStyles, spinnerStyles} from "./DSIcon.css";
import cx from "../../utils/cx";

type DSSpinnerProps = {className?: string; size?: keyof (typeof iconStyles)["sizes"] | null};
export const DSSpinner = ({className, size}: DSSpinnerProps) => {
  const [id] = useState(() => Math.random().toString());
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(className, size && iconStyles.sizes[size], iconStyles.base)}
    >
      <path
        d="M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm0-16.02a7.02 7.02 0 1 0 0 14.04 7.02 7.02 0 0 0 0-14.04Z"
        className={spinnerStyles.bg}
      />
      <path
        d="M12 3.99c0-.55.44-1 .99-.94a9 9 0 0 1 7.96 7.96c.06.55-.4.99-.94.99-.55 0-.98-.45-1.06-.99a7.02 7.02 0 0 0-5.96-5.96c-.54-.08-.99-.51-.99-1.06Z"
        fill={`url(#spinner-${id})`}
        className={spinnerStyles.spinner}
      />
      <defs>
        <linearGradient
          id={`spinner-${id}`}
          x1="3"
          y1="12"
          x2="21"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".59" stopOpacity="0" className={spinnerStyles.gradientStop} />
          <stop offset="1" className={spinnerStyles.gradientStop} />
        </linearGradient>
      </defs>
    </svg>
  );
};

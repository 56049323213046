import {useEffect, useRef} from "react";
import {delayedTrigger, MiniEvent} from "@cdx/common";

const titleEvents = new MiniEvent();

/*
pos
0 : [00:15]·
1 : Codecks
2 : deck name/card name / account name
3 : account - name/project name
*/

const titleTrigger = delayedTrigger();

const byPos = [[], [{value: "Codecks"}], [], []];
titleEvents.addListener(({pos, action, data}) => {
  if (action === "add") {
    byPos[pos].unshift(data);
  } else if (action === "update") {
    // the caller modified the mutable res
  } else if (action === "remove") {
    const idx = byPos[pos].indexOf(data);
    if (idx !== -1) {
      byPos[pos].splice(idx, 1);
    } else if (process.env.NODE_ENV !== "production") {
      console.warn(`[useTitle]: can't find entry ${JSON.stringify(data)} at pos ${pos}`);
    }
  }
  titleTrigger.cancel();
  titleTrigger.fire(() => {
    const innerPart = [byPos[2].length && byPos[2][0].value, byPos[3].length && byPos[3][0].value]
      .filter(Boolean)
      .join(" / ");
    const parts = [
      byPos[0].length && byPos[0][0].value,
      [byPos[1][0].value, innerPart].filter(Boolean).join(" – "),
    ];
    document.title = parts.filter(Boolean).join(" ");
  });
});

export const useSetTitle = (content, {pos}) => {
  const lastContentRef = useRef({entry: null, pos: null});
  useEffect(() => {
    if (content) {
      if (lastContentRef.current.entry) {
        lastContentRef.current.entry.value = content;
        titleEvents.emit({pos, action: "update"});
      } else {
        const data = (lastContentRef.current.entry = {value: content});
        titleEvents.emit({pos, action: "add", data});
      }
    } else {
      if (lastContentRef.current.entry) {
        titleEvents.emit({pos, action: "remove", data: lastContentRef.current.entry});
        lastContentRef.current.entry = null;
      }
    }
    lastContentRef.current.pos = pos;
  }, [content, pos]);
  useEffect(
    () => () => {
      if (lastContentRef.current.entry) {
        titleEvents.emit({
          pos: lastContentRef.current.pos,
          action: "remove",
          data: lastContentRef.current.entry,
        });
      }
    },
    []
  );
};

const metaDescEvents = new MiniEvent();
const descriptionStack = [];
let _descriptionTag = null;
const descTrigger = delayedTrigger();

const ensureDescriptionTag = () => {
  if (!_descriptionTag) {
    _descriptionTag = document.createElement("meta");
    _descriptionTag.name = "description";
    document.getElementsByTagName("head")[0].appendChild(_descriptionTag);
  }
  return _descriptionTag;
};

metaDescEvents.addListener((actionType, payload) => {
  switch (actionType) {
    case "add": {
      descriptionStack.unshift(payload);
      break;
    }
    case "remove": {
      const idx = descriptionStack.indexOf(payload);
      if (idx >= 0) descriptionStack.splice(idx, 1);
      break;
    }
    default: {
    }
  }
  const firstContent = descriptionStack.find((d) => d.content)?.content;
  descTrigger.fire(() => {
    if (firstContent) {
      ensureDescriptionTag().content = firstContent;
    } else {
      ensureDescriptionTag().content = "";
    }
  }, 16);
});

export const useMetaDescription = (content) => {
  const stackEntry = useRef(null);

  useEffect(() => {
    stackEntry.current = {content: null};
    metaDescEvents.emit("add", stackEntry.current);
    return () => {
      metaDescEvents.emit("remove", stackEntry.current);
    };
  }, []);

  useEffect(() => {
    if (content !== stackEntry.current.content) {
      stackEntry.current.content = content;
      metaDescEvents.emit("update", stackEntry.current);
    }
  }, [content]);
};

import {ReactNode} from "react";
import {UserId} from "../../cdx-models/User";
import Avatar from "../../components/Avatar";
import {api} from "../../lib/api";
import {toOrdinal} from "../../lib/utils";
import {IconGateOpen} from "@cdx/common";
import {
  css,
  DSIconAttachment,
  DSIconCalendar,
  DSIconCalendarMilestone,
  DSIconCheck,
  DSIconDeck,
  DSIconDoc,
  DSIconHandSingle,
  DSIconHandSingleDashed,
  DSIconMention,
  DSIconPlay,
  DSIconPlus,
  DSIconProperties,
  DSIconTrash,
} from "@cdx/ds";
import DSAvatar from "../../components/DSAvatar/DSAvatar";
import {CardDiffNotificationChanges} from "../../cdx-models/utils/extended-fields";
import {CardDiffNotification} from "../../cdx-models/CardDiffNotification";

const propToType: {
  [K in keyof CardDiffNotificationChanges]: (
    val: NonNullable<CardDiffNotificationChanges[K]>,
    userId: UserId,
    n: CardDiffNotificationChanges
  ) => NotificationType | null;
} = {
  isNew: () => "newCard",
  status: (val) => (val[1] === "started" ? "startedCard" : val[1] === "done" ? "doneCard" : null),
  visibility: (val) => (val[1] === "deleted" ? "deletedCard" : null),
  deckId: (val, userId, all) => (all.isNew ? null : "movedDeck"),
  effort: () => "props",
  priority: () => "props",
  milestoneId: () => "milestone",
  sprintId: () => "sprint",
  title: () => "content",
  content: () => "content",
  attachments: () => "attachments",
  assigneeId: (val, userId) => {
    if (val[1] === userId) return "isAssigned";
    if (val[0] === userId) return "isUnassigned";
    return null;
  },
  isMentioned: () => "mentioned",
  handPos: (val) => (val[0] === null ? "addHand" : val[1] === null ? "removeHand" : "moveHand"),
  depsUnblocked: () => "depsUnblocked",
};

export const cardDiffToTypes = (cardDiffNoti: CardDiffNotificationChanges, userId: UserId) => {
  const types = new Set<NotificationType>();
  Object.entries(cardDiffNoti).forEach(([_key, val]) => {
    const key = _key as keyof CardDiffNotificationChanges;
    const typeFn = propToType[key];
    if (!typeFn) return console.warn("unknown prop", key);
    const type = typeFn(val as any, userId, cardDiffNoti);
    if (type) types.add(type);
  });
  return types;
};

type UiInfo = {priority?: number; className?: string} & (
  | {title: ReactNode; getTitle?: undefined}
  | {getTitle: (n: CardDiffNotification) => ReactNode; title?: undefined}
) &
  ({icon: ReactNode} | {renderIcon: (n: CardDiffNotification) => ReactNode});

export type NotificationType = keyof typeof typesToUi;

const typesToUi = {
  props: {
    icon: <DSIconProperties size={24} />,
    title: "Properties changed",
  },

  content: {
    icon: <DSIconDoc size={24} />,
    title: "Content changed",
  },

  attachments: {
    icon: <DSIconAttachment size={24} />,
    title: "Attachments changed",
  },

  movedDeck: {
    icon: <DSIconDeck size={24} />,
    title: "Deck changed",
  },

  isAssigned: {
    renderIcon: (changes: any) => (
      <Avatar user={api.getModel({modelName: "user", id: changes.assigneeId[1]})} />
    ),
    title: "You were assigned",
    priority: 5,
  },

  milestone: {
    icon: <DSIconCalendarMilestone size={24} />,
    title: "Milestone changed",
  },
  sprint: {
    icon: <DSIconCalendar size={24} />,
    title: "Run changed",
  },

  isUnassigned: {
    renderIcon: (changes: any) => (
      <div style={{position: "relative"}} className="notifications-symbols-unassigned">
        <DSAvatar
          user={api.getModel({modelName: "user", id: changes.assigneeId[0]})}
          size={24}
          className={css({opacity: 0.5})}
        />
      </div>
    ),
    title: "You were unassigned",
    priority: 5,
  },

  newCard: {
    icon: <DSIconPlus size={24} />,
    title: "Card created",
    priority: 10,
  },

  addHand: {
    icon: <DSIconHandSingle size={20} />,
    getTitle: (n) => `Added to ${toOrdinal((n.changes.handPos![1] ?? 0) + 1)} hand slot`,
    priority: 12,
  },
  removeHand: {
    icon: <DSIconHandSingleDashed size={20} />,
    title: "Removed from hand",
    priority: 12,
  },
  moveHand: {
    icon: <DSIconHandSingle size={20} />,
    getTitle: (n) => `Moved to ${toOrdinal((n.changes.handPos![1] ?? 0) + 1)} hand slot`,
    priority: 11,
  },

  startedCard: {
    icon: <DSIconPlay size={24} />,
    title: "Card started",
    className: "is-started",
  },

  doneCard: {
    icon: <DSIconCheck size={24} />,
    title: "Card done",
    className: "is-done",
  },

  deletedCard: {
    icon: <DSIconTrash size={24} />,
    title: "Card deleted",
    priority: 15,
  },

  mentioned: {
    icon: <DSIconMention size={24} />,
    title: "You were mentioned",
    priority: 11,
  },

  depsUnblocked: {
    icon: <IconGateOpen size="xl" />,
    title: "Card unlocked",
    priority: 11,
  },
} satisfies {[key: string]: UiInfo};

export const notificationTypesToUi: Record<NotificationType, UiInfo> = typesToUi;

import {useLexicalNodeSelection} from "@lexical/react/useLexicalNodeSelection";
import dsStyles from "../../css/index.css";
import cx from "../../utils/cx";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useEffect, useRef} from "react";
import {mergeRegister} from "@lexical/utils";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from "lexical";

const useLexicalDecoratorNode = (nodeKey: string) => {
  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();

  const refObj = {isSelected, setSelected, nodeKey};
  const refs = useRef(refObj);
  useEffect(() => {
    refs.current = refObj;
  });

  useEffect(() => {
    const onDelete = (payload: KeyboardEvent) => {
      if (refs.current.isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(refs.current.nodeKey);
        if (node) node.remove();
        refs.current.setSelected(false);
      }
      return false;
    };
    return mergeRegister(
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
    );
  }, [editor]);

  return {
    selectionClassName: isSelected
      ? cx(dsStyles.useBoxShadowComponents.true, dsStyles.focusRing.sm, dsStyles.noShadow.true)
      : undefined,
  };
};

export default useLexicalDecoratorNode;

import {MouseEventHandler, MutableRefObject} from "react";
import {useSpring} from "react-spring";
import {cardVars} from "./card.css";
import {getVarName} from "@vanilla-extract/private";

const initialValues = {
  rotateX: 0,
  rotateY: 0,
  gradientX: 0,
  gradientRotation: 0,
  z: 0,
};

const config = {
  liftIntensity: 20,
  rotationIntensityX: -12,
  rotationIntensityY: -10,
  springTension: 1000,
  springFriction: 100,
} as const;

export const useMiniCardAnimation = ({
  nodeRef,
  disabled,
}: {
  nodeRef: MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
}) => {
  const [nodeStyles, api] = useSpring(() => initialValues);
  const springConfig = {tension: config.springTension, friction: config.springFriction};
  if (disabled) {
    return {
      containerEvents: {},
      cardStyles: {},
    };
  }

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!nodeRef.current) return;
    const node = nodeRef.current;
    const {top, left, width, height} = node.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 2 - 1;
    const y = ((e.clientY - top) / height) * 2 - 1;
    api.start({
      rotateX: y * config.rotationIntensityY,
      rotateY: -x * config.rotationIntensityX,
      z: (1 - Math.abs(x)) * (1 - Math.abs(y)) * config.liftIntensity,
      gradientX: 50 + ((x - y) / 4) * 50,
      gradientRotation: y * config.rotationIntensityY * 0.5,
      config: springConfig,
    });
  };
  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    api.start({
      ...initialValues,
      config: springConfig,
    });
  };

  const {gradientX, gradientRotation, ...passedStyles} = nodeStyles;

  return {
    containerEvents: {
      onMouseMove: handleMouseMove,
      onMouseLeave: handleMouseLeave,
    },
    cardStyles: {
      ...passedStyles,
      [getVarName(cardVars.gradientXPosition)]: gradientX.to((v) => `${v}%`),
      [getVarName(cardVars.gradientRotation)]: gradientRotation.to((v) => `${110 + v}deg`),
    },
  };
};

const initialBigCardValues = {
  gradientX: 2,
};

export const useBigCardAnimation = ({
  nodeRef,
  disabled,
}: {
  nodeRef: MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
}) => {
  const [nodeStyles, api] = useSpring(() => initialBigCardValues);
  const springConfig = {tension: 25, friction: 8};
  if (disabled) {
    return {
      containerEvents: {},
      cardStyles: {},
    };
  }

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!nodeRef.current) return;
    const node = nodeRef.current;
    const {
      // top, height,
      left,
      width,
    } = node.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 2 - 1;
    // const y = ((e.clientY - top) / height) * 2 - 1;
    api.start({
      gradientX: (-x + 1) * 50,
      config: springConfig,
    });
  };
  const handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    api.start({
      ...initialBigCardValues,
      config: springConfig,
    });
  };

  const {gradientX} = nodeStyles;

  return {
    containerEvents: {
      onMouseMove: handleMouseMove,
      onMouseLeave: handleMouseLeave,
    },
    cardStyles: {
      [getVarName(cardVars.gradientXPosition)]: gradientX.to((v) => `${v}%`),
      [getVarName(cardVars.gradientRotation)]: "103deg",
    },
  };
};

import {useRef} from "react";
import shallowEqual from "shallowequal";

const useOriginalCopy = (value, equals = shallowEqual) => {
  const cache = useRef(undefined);

  if (equals(cache.current, value)) {
    return cache.current;
  } else {
    // if (cache.current !== undefined) {
    //   console.log(cache.current);
    //   console.log("vs");
    //   console.log(value, "\n");
    // }
  }

  cache.current = value;
  return value;
};

export default useOriginalCopy;

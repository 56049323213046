import {Root} from "../cdx-models/Root";
import {SprintConfig} from "../cdx-models/SprintConfig";
import {UserId} from "../cdx-models/User";
import {UserRole} from "../cdx-models/utils/extended-fields";
import cdxEnv from "../env";

export const roleToSortIndex = {
  owner: 0,
  admin: 1,
  guest: 3,
  staff: 3,
  observer: 4,
  disabled: 5,
  service: 4,
  deleted: 5,
};

export const paidRoles: UserRole[] = ["owner", "admin", "staff", "guest"];

export const roleToLabel: {[role in UserRole]: string} = {
  owner: "Owner",
  admin: "Admin",
  guest: "Staff",
  staff: "Staff",
  observer: "Observer",
  disabled: "Disabled",
  service: "Disabled",
  deleted: "Disabled",
};

export const getUserRole = (
  root: Root
): ({isLoaded: false; role: null} | {isLoaded: true; role: UserRole | null}) & {
  userId: UserId | null;
} => {
  const {account, loggedInUser: user} = root;
  if (!user) return {isLoaded: true, role: null, userId: null};
  const userId = user.$meta.get("id", null);
  if (!userId) return {isLoaded: false, role: null, userId: null};
  const roles = account.$meta.find("roles", {userId});
  if (roles.length === 0) return {isLoaded: true, role: null, userId};
  const role = roles[0].$meta.get("role", null);
  if (!role) return {isLoaded: false, role: null, userId};
  return {isLoaded: true, role, userId};
};

export const roleIsAtLeast = (role: UserRole | null, targetRole: UserRole) => {
  return role && roleToSortIndex[role] <= roleToSortIndex[targetRole];
};

type Opts = {fallback?: boolean};

const userRoleAtLeast = (root: Root, targetRole: UserRole, {fallback = false}: Opts = {}) => {
  const {isLoaded, role} = getUserRole(root);
  if (!isLoaded) return fallback;
  return roleIsAtLeast(role, targetRole);
};

const isAtLeastProducer = ({root, project}: any, {fallback = false}: Opts = {}) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  const projectId = project.$meta.get("id", null);
  if (!projectId) return fallback;
  return root.loggedInUser.$meta.exists(
    "withProjectAccess",
    {projectId, projectRole: "producer"},
    fallback
  );
};
const isAtLeastProducerOrWithFullStaffPermissions = (
  {root, project}: any,
  {fallback = false}: Opts = {}
) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  if (!roleIsAtLeast(role, "staff")) return false;
  if (root.account.staffPermission === "full") return true;
  const projectId = project.$meta.get("id", null);
  if (!projectId) return fallback;
  return root.loggedInUser.$meta.exists(
    "withProjectAccess",
    {projectId, projectRole: "producer"},
    fallback
  );
};

const isAtLeastProducerSomewhere = (root: Root, {fallback = false}: Opts = {}) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  const accountId = root.account.$meta.get("id", null);
  return (
    accountId &&
    root.loggedInUser.$meta.exists(
      "withProjectAccess",
      {projectRole: "producer", project: {accountId}},
      fallback
    )
  );
};

const isAtLeastProducerSomewhereOrWithFullStaffPermissions = (
  root: Root,
  {fallback = false}: Opts = {}
) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  if (!roleIsAtLeast(role, "staff")) return false;
  if (root.account.staffPermission === "full") return true;
  const accountId = root.account.$meta.get("id", null);
  return (
    accountId &&
    root.loggedInUser.$meta.exists(
      "withProjectAccess",
      {projectRole: "producer", project: {accountId}},
      fallback
    )
  );
};

export const hasPermissionToCreateCard = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};
export const hasPermissionToModifyCard = ({root, card}: any) => {
  return userRoleAtLeast(root, "staff");
};
export const hasPermissionToModifyCardId = ({root, cardId}: any) => {
  return hasPermissionToModifyCard({root, card: null});
};
export const hasPermissionToMarkCardAsSeen = ({root, card}: any, opts: Opts = {}) => {
  return userRoleAtLeast(root, "staff", opts);
};
export const hasPermissionToBookmarkCard = (root: Root, opts: Opts = {}) => {
  return userRoleAtLeast(root, "observer", opts);
};
export const hasPermissionToWatchCard = (root: Root, opts: Opts = {}) => {
  return userRoleAtLeast(root, "observer", opts);
};

export const hasPermissionToCreateCardPreset = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};

export const hasPermissionToModifyWorkflowItem = ({root, deck}: any) => {
  return isAtLeastProducerOrWithFullStaffPermissions({root, project: deck.project});
};

export const hasPermissionToModifyPublicDeckOrder = ({root, project}: any) => {
  return isAtLeastProducer({root, project});
};
export const hasPermissionToCreateDeck = ({root, project}: any) => {
  return isAtLeastProducerOrWithFullStaffPermissions({root, project});
};
export const hasPermissionToModifyDeck = ({root, project}: any) => {
  return isAtLeastProducerOrWithFullStaffPermissions({root, project});
};
export const hasPermissionToModifyPreferredOrderInDeck = ({root, project}: any) => {
  return isAtLeastProducer({root, project});
};

export const hasPermissionToCreateMilestone = (root: Root) => {
  return isAtLeastProducerSomewhereOrWithFullStaffPermissions(root);
};
export const hasPermissionToModifySomeMilestone = (root: Root) => {
  return isAtLeastProducerSomewhereOrWithFullStaffPermissions(root);
};
export const hasPermissionToModifyMilestone = ({root, milestone}: any, {fallback}: Opts = {}) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  if (!roleIsAtLeast(role, "staff")) return false;
  if (root.account.staffPermission === "full") return true;
  return milestone.$meta.exists(
    "milestoneProjects",
    {project: {access: {userId, projectRole: "producer"}}},
    fallback
  );
};
export const hasPermissionToModifyMilestoneIsGlobal = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const hasPermissionToPinMilestone = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};
export const hasPermissionToPinSprint = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};

export const hasPermissionToModifySprint = (
  {root, sprint}: {root: Root; sprint: {sprintConfig: SprintConfig}},
  {fallback}: Opts = {}
) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return fallback ?? null;
  if (!userId) return false;
  if (roleIsAtLeast(role, "admin")) return true;
  if (!roleIsAtLeast(role, "staff")) return false;
  if (root.account.staffPermission === "full") return true;
  return sprint.sprintConfig.$meta.exists(
    "sprintProjects",
    {project: {access: {userId, projectRole: "producer"}}},
    fallback
  );
};

export const hasPermissionToCreateProject = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};

export const hasPermissionToManageSomeProjectInOrg = (root: Root) => {
  return isAtLeastProducerSomewhere(root);
};

export const hasPermissionToManageProject = ({root, project}: any) => {
  return isAtLeastProducer({root, project});
};
export const hasPermissionToManageProjectTags = ({root, project}: any) => {
  return isAtLeastProducerOrWithFullStaffPermissions({root, project});
};
export const hasPermissionToArchiveProject = ({root, project}: any) => {
  return isAtLeastProducer({root, project});
};
export const hasPermissionToDeleteProject = ({root}: any) => {
  return userRoleAtLeast(root, "admin");
};

export const hasPermissionToManageOrg = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const hasPermissionToDisableOrg = (root: Root) => {
  return userRoleAtLeast(root, "owner");
};
export const hasPermissionToManageIntegrations = (root: Root, opts: Opts = {}) => {
  return isAtLeastProducerSomewhere(root, opts);
};
export const hasPermissionToManageBilling = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const roleCanManageBilling = (role: UserRole) => roleIsAtLeast(role, "admin");

export const hasPermissionToManageUser = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const hasPermissionToManageOwner = (root: Root) => {
  return userRoleAtLeast(root, "owner");
};
export const hasPermissionToSeeInviteLink = (root: Root) => {
  return isAtLeastProducerSomewhere(root);
};
export const hasPermissionToEmptyOtherUserDonePiles = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};

export const hasPermissionToViewUserManagement = (root: Root) => {
  return userRoleAtLeast(root, "observer");
};

// used to check if e.g. hover shortcuts should be offered for a big group of cards
// also used for selection header
export const hasPermissionToModifySomeCardInOrg = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};

export const hasPermissionToOwnHand = (root: Root) => {
  return userRoleAtLeast(root, "staff");
};
export const userHasPermissionToOwnHand = ({root, userId}: any) => {
  if (!userId) return false;
  return root.account.$meta.exists("roles", {userId, role: ["guest", "staff", "admin", "owner"]});
};

export const hasPermissionToAccessAllProjects = (root: Root, opts: Opts = {}) => {
  return userRoleAtLeast(root, "admin", opts);
};

export const hasPermissionToDeleteFile = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const hasPermissionToUpdateTimeTrackingEntry = ({root, segment, card}: any) => {
  if (root.loggedInUser?.id === segment.user.id) return true;
  if (!card.deck) return false;
  return isAtLeastProducer({root, project: card.deck.project});
};
export const hasPermissionToCreateVisionBoard = (root: Root) => {
  return userRoleAtLeast(root, "admin");
};
export const hasPermissionToModifyVisionBoard = (root: Root, board: any) => {
  const {isLoaded, role, userId} = getUserRole(root);
  if (!isLoaded) return null;
  const isAdmin = roleIsAtLeast(role, "admin");
  if (isAdmin) return true;
  const accessRights = board.$meta.get("accessRights", "admin_only");
  if (accessRights === "staff_write") {
    return roleIsAtLeast(role, "staff");
  } else if (accessRights === "producers_only") {
    return board.$meta.exists(
      "visionBoardProjects",
      {project: {access: {userId, projectRole: "producer"}}},
      null
    );
  }
  return false;
};

export const hasPermissionToSeeOnboarding = (root: Root) => {
  if (cdxEnv.ON_PREMISE === "true") return false;
  return userRoleAtLeast(root, "admin");
};

export const hasPermissionToGuardCard = (root: Root, card: any) => {
  const {isLoaded, userId, role} = getUserRole(root);
  if (!isLoaded) return null;
  if (!userId) return false;
  if (!card.deck) return true;
  if (role === "admin" || role === "owner") return true;
  if (!card.deck.hasGuardians) return true;
  const projectId = card.deck.project.$meta.get("id", null);
  if (projectId) {
    const isProducer = root.loggedInUser.$meta.exists(
      "withProjectAccess",
      {projectId, projectRole: "producer"},
      false
    );
    if (isProducer) return true;
  }
  return card.deck.guardians.some((g: any) => g.userId === userId);
};

export const accessibleOrgRoles = ({
  root,
  role = ["observer", "guest", "staff", "admin", "owner"],
}: any) =>
  root.loggedInUser
    ? root.loggedInUser.$meta.find("accountRoles", {
        role,
        account: {isDisabled: false},
      })
    : [];

export const isProjectSpecificRole = (role: UserRole): boolean => {
  return role === "observer" || role === "guest";
};
// canModifyCard
// hasHand
// canEmptyOtherUserHands
// canMarkCardAsSeenForDiscord
// canCreatePreset
// canManagePreset

// canDeleteFilesInMediaLibrary

// canCreateDeck / canModifyDeck (incl hand sync & deletion)
// canModifyPreferredOrderInDeck
// canModifyDefaultCard/Journey Steps

// canCreateProject / canModifyProject
// canManageProjectTags
// canModifyPublicDeckOrder

// canCreateMilestone / canModifyMilestone (incl hand sync & deletion)
// canPinMilestone

// canManageUsers
// canModifyOrganization
// canDeleteOrganization
// canManageBilling
// canSeeOrgSettings
// canModifyIntegrations

import React from "react";

export function isIE11() {
  return window.navigator.userAgent.indexOf("Trident/") >= 0;
}

export function isMac() {
  return window.navigator.platform.toUpperCase().indexOf("MAC") >= 0;
}

export function isSafari() {
  // https://stackoverflow.com/a/23522755/616974
  return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}

export function getChromeVersion() {
  // https://stackoverflow.com/a/4900484/616974
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

export function isAndroid() {
  return window.navigator.userAgent.indexOf("Android") >= 0;
}

export function isWithMetaKey(e: React.MouseEvent<any, any> | MouseEvent | KeyboardEvent) {
  return isMac() ? e.metaKey : e.ctrlKey;
}

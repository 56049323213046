import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  Spread,
  $applyNodeReplacement,
  TextNode,
  $getSelection,
  $isRangeSelection,
  DecoratorNode,
  SerializedLexicalNode,
  $getEditor,
} from "lexical";
import {useEffect} from "react";
import {RawMarkdownImg as RawImgComp} from "../../Markdown/InnerMarkdown";
import useLexicalDecoratorNode from "@cdx/ds/components/DSTextEditor/useLexicalDecoratorNode";
import dsStyles from "@cdx/ds/css/index.css";
import {cx} from "@cdx/common";

const RawMarkdownImg = RawImgComp as any;

export interface ImagePayload {
  altText: string;
  height?: number;
  key?: NodeKey;
  src: string;
  width?: number;
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const {alt: altText, src, width, height} = domNode;
    const node = $createImageNode({altText, height, src, width});
    return {node};
  }
  return null;
}

export type SerializedImageNode = Spread<
  {
    altText: string;
    height?: number;
    src: string;
    width?: number;
  },
  SerializedLexicalNode
>;

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __alt: string;
  __width: "inherit" | number;
  __height: "inherit" | number;

  static getType(): string {
    return "cdxImage";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__alt, node.__width, node.__height);
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const {altText, height, width, src} = serializedNode;
    const node = $createImageNode({
      altText,
      height,
      src,
      width,
    });
    return node;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__alt);
    element.setAttribute("width", this.__width.toString());
    element.setAttribute("height", this.__height.toString());
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  constructor(
    src: string,
    altText: string,
    width?: "inherit" | number,
    height?: "inherit" | number,
    key?: NodeKey
  ) {
    super(key);
    this.__src = src;
    this.__alt = altText;
    this.__width = width || "inherit";
    this.__height = height || "inherit";
  }

  exportJSON(): SerializedImageNode {
    return {
      altText: this.__alt,
      height: this.__height === "inherit" ? 0 : this.__height,
      src: this.__src,
      type: this.getType(),
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
    };
  }

  getTextContent(): string {
    return `![${this.__alt}](${this.__src})`;
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    return el;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return <Image src={this.__src} alt={this.__alt} nodeKey={this.__key} />;
  }
}

const Image = ({alt, nodeKey, src}: {src: string; alt: string; nodeKey: string}) => {
  const {selectionClassName} = useLexicalDecoratorNode(nodeKey);
  return (
    <RawMarkdownImg
      src={src}
      alt={alt}
      imageClassName={selectionClassName}
      withinEditor
      className={cx(
        dsStyles.width["100%"],
        dsStyles.display["inline-block"],
        dsStyles.textAlign.center
      )}
    />
  );
};

export function $createImageNode({altText, height, src, width, key}: ImagePayload): ImageNode {
  return $applyNodeReplacement(new ImageNode(src, altText, width, height, key));
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}

const findAndTransformMention = (node: TextNode) => {
  const text = node.getTextContent();
  const imgRegex = /!(?:\[([^[]*)\])(?:\(([^(]+)\))/;
  const m = text.match(imgRegex);
  if (!m) return null;

  const selection = $getSelection();
  if ($isRangeSelection(selection) && !$getEditor()._updateTags.has("paste")) {
    if (selection.anchor.getNode() === node) {
      if (selection.anchor.offset < m.index! + m[0].length) return null;
    }
  }

  const i = m.index!;
  let targetNode;
  if (i === 0) {
    [targetNode] = node.splitText(i + m[0].length);
  } else {
    [, targetNode] = node.splitText(i, i + m[0].length);
  }
  const [, altText, src] = m;

  const imgNode = $createImageNode({altText, src});
  targetNode.replace(imgNode);
  return imgNode;
};

export const CdxDisplayMarkdownImagesPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (node) => {
      if (!node.isSimpleText()) return;
      findAndTransformMention(node);
    });
  }, [editor]);
  return null;
};

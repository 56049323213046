import {alphaSeq} from "../../lib/sequences";
import {projectTags} from "../../lib/utils";
import {useModalAdress} from "../ModalRegistry";
import Helper from "../Helper";
import Emoji from "../../lib/emoji/EmojiRenderer";
import {hasPermissionToManageProjectTags} from "../../lib/permissions";
import {Box, Col, DSIconButton, DSIconCog, Row, css, DSTag, Text} from "@cdx/ds";
import {TooltipForChild} from "@cdx/common";
import {Card} from "../../cdx-models/Card";
import {Project} from "../../cdx-models/Project";
import {Root} from "../../cdx-models/Root";
import Markdown from "../Markdown";

const tagHelper = (
  <Helper heading="What are project tags?">
    <p>
      Project tags are curated tags that can only be modified in the project settings. This enforces
      a consistent taxonomy across your project.
    </p>
    <p>
      Click on a tag to add or remove it from this card. Deprecated tags (i.e. removed within the
      project settings) will be displayed as a separate group so that you can easily spot them.
    </p>
    <p>
      Tags is a powerful feature that we encourage you to use if you want fine-grained control over
      the filtering and display of all your cards.
    </p>
  </Helper>
);

type TagPickerProps = {
  card: Card;
  project: Project;
  value: string[];
  onChange: (args: {masterTags: string[]}) => void;
  root: Root;
};

export const TagPicker = ({card, project, value, onChange, root}: TagPickerProps) => {
  const masterTags = projectTags(project);
  const lowerCaseTags = masterTags.map((t) => t.tag.toLowerCase());
  const deprecatedMasterTags = value.filter((t) => !lowerCaseTags.includes(t.toLowerCase()));
  const canManageProjectTags = hasPermissionToManageProjectTags({root, project});
  const manageUrl = useModalAdress({
    modal: `projectSettings.${alphaSeq.intToSeq(project.accountSeq)}.tags`,
  });
  const defaultTagId = card.deck?.defaultProjectTag?.id;
  return (
    <Col sp="12px" pt="16px" pb="24px">
      <Row align="center" sp="8px">
        <Box as="h3" size={12} bold color="primary" textTransform="uppercase">
          Project Tags
        </Box>
        {tagHelper}
        {canManageProjectTags ? (
          <TooltipForChild tooltip="Manage Project Tags">
            <DSIconButton
              variant="tertiary"
              to={manageUrl}
              size="sm"
              className={css({ml: "auto"})}
              icon={<DSIconCog />}
            />
          </TooltipForChild>
        ) : null}
      </Row>
      <Row sp="4px" wrap>
        {masterTags.map((val, i) => {
          const active = value.some((t) => t.toLowerCase() === val.tag.toLowerCase());
          const getTooltip = () => {
            if (defaultTagId === val.id)
              return "Can't be removed as it's the deck's auto project tag";
            const desc = val.$meta.get("description", null);
            if (desc)
              return (
                <Markdown
                  size="sm"
                  variant="onDark"
                  className={css({pa: "8px", maxWidth: "200px"})}
                >
                  {desc}
                </Markdown>
              );
          };
          return (
            <TooltipForChild
              targetIsDisabled={defaultTagId === val.id}
              tooltip={getTooltip()}
              key={val.$meta.get("tag", i)}
              bg="gray700"
            >
              <DSTag
                theme={active ? "active" : null}
                disabled={defaultTagId === val.id}
                onClick={() =>
                  onChange({
                    masterTags: active
                      ? value.filter((t) => t.toLowerCase() !== val.tag.toLowerCase())
                      : [...value, val.tag],
                  })
                }
              >
                {val.emoji ? (
                  <Emoji>{val.emoji}</Emoji>
                ) : val.color ? (
                  <span style={{color: val.color}}>#</span>
                ) : (
                  "#"
                )}
                {val.tag}
              </DSTag>
            </TooltipForChild>
          );
        })}
      </Row>
      {deprecatedMasterTags.length ? (
        <Col sp="8px">
          <Text color="primary" type="label12">
            Deprecated Project Tags
          </Text>
          <Row sp="4px" wrap>
            {deprecatedMasterTags.map((val) => (
              <TooltipForChild
                key={val}
                tooltip="This tag no longer is a project tag. Click to remove this tag from the card."
              >
                <DSTag
                  theme="active"
                  onClick={() =>
                    onChange({
                      masterTags: value.filter((t) => t.toLowerCase() !== val.toLowerCase()),
                    })
                  }
                >
                  #{val}
                </DSTag>
              </TooltipForChild>
            ))}
          </Row>
        </Col>
      ) : null}
    </Col>
  );
};

// eslint-disable-next-line no-shadow
const enum ParserState {
  Root,
  QuoteStart,
  UnorderedListStart,
  OrderedListClose,
  OrderedListStart,
  WaitForCheckListOpen,
  WaitForCheckListContent,
  WaitForCheckListClose,
  CheckListDone,
  CheckListStart,
  Indent,
  Text,
}

type ListParserContext = {
  type:
    | ParserState.UnorderedListStart
    | ParserState.WaitForCheckListOpen
    | ParserState.WaitForCheckListContent
    | ParserState.WaitForCheckListClose
    | ParserState.CheckListDone
    | ParserState.OrderedListStart
    | ParserState.OrderedListClose;
  chars: string;
  orderSuffixChar: string;
  withChecklistContent: boolean;
  ordered: boolean;
  lineCtx: LineCtx;
};

type LineCtx = {
  withinList: boolean;
};

type ParserContext =
  | {type: ParserState.Root; lineCtx: LineCtx}
  | {type: ParserState.QuoteStart; lineCtx: LineCtx}
  | {type: ParserState.Indent; depth: number; lineCtx: LineCtx}
  | ListParserContext;

// eslint-disable-next-line no-shadow
const enum NodeType {
  Text,
  Quote,
  UnorderedList,
  OrderedList,
  Indentation,
  Root,
}
export {NodeType as LineAstNodeType};

export type RootLineAstNode = {type: NodeType.Root; content: NonRootLineAstNode};
export type IndentLineAstNode = {
  type: NodeType.Indentation;
  depth: number;
  content: NonRootLineAstNode;
};
export type CheckListContent = {content: string; trailingSpace: boolean};
export type NonRootLineAstNode =
  | {
      type: NodeType.Text;
      content: string;
    }
  | IndentLineAstNode
  | {
      type: NodeType.Quote;
      content: NonRootLineAstNode;
    }
  | {
      type: NodeType.UnorderedList;
      char: string;
      checkList: null | CheckListContent;
      content: NonRootLineAstNode;
    }
  | {
      type: NodeType.OrderedList;
      checkList: null | CheckListContent;
      number: number;
      orderSuffixChar: string;
      content: NonRootLineAstNode;
    };

const getCheckListInfo = (
  text: string,
  startOffset: number,
  checkListOffset: number,
  trailingSpace: boolean
): CheckListContent | null => {
  if (!checkListOffset) return null;
  return {
    content: checkListOffset === 2 ? "" : text.slice(startOffset + 1, startOffset + 2),
    trailingSpace,
  };
};

const finalizeList = (
  ctx: ListParserContext,
  text: string,
  startOffset: number,
  checkListOffset: number = 0,
  trailingSpace: boolean = false
): NonRootLineAstNode => {
  const end =
    (trailingSpace ? 1 : 0) +
    (ctx.ordered
      ? startOffset + ctx.chars.length + checkListOffset + 2
      : startOffset + 2 + checkListOffset);
  const content: NonRootLineAstNode = checkListOffset
    ? {type: NodeType.Text, content: text.slice(end)}
    : parseNode(text, end, {withinList: true});
  if (ctx.ordered) {
    return {
      type: NodeType.OrderedList,
      checkList: getCheckListInfo(
        text,
        startOffset + ctx.chars.length + 2,
        checkListOffset,
        trailingSpace
      ),
      content,
      number: Number(ctx.chars),
      orderSuffixChar: ctx.orderSuffixChar,
    };
  } else {
    return {
      type: NodeType.UnorderedList,
      char: ctx.chars,
      checkList: getCheckListInfo(text, startOffset + 2, checkListOffset, trailingSpace),
      content,
    };
  }
};

const parseNode = (text: string, startOffset: number, lineCtx: LineCtx): NonRootLineAstNode => {
  // console.log("parse", startOffset, text.slice(startOffset));
  let ctx: ParserContext = {type: ParserState.Root, lineCtx};
  for (let i = startOffset; i <= text.length; i += 1) {
    const currChar = i === text.length ? "" : text[i];
    switch (ctx.type) {
      case ParserState.Root: {
        switch (currChar) {
          case ">": {
            ctx = {type: ParserState.QuoteStart, lineCtx};
            break;
          }
          case "\t":
          case " ": {
            if (lineCtx.withinList) {
              return {type: NodeType.Text, content: text.slice(startOffset)};
            } else {
              ctx = {type: ParserState.Indent, depth: currChar === "\t" ? 2 : 1, lineCtx};
            }
            break;
          }
          case "-":
          case "+":
          case "*": {
            ctx = {
              type: ParserState.UnorderedListStart,
              withChecklistContent: false,
              chars: currChar,
              ordered: false,
              orderSuffixChar: "",
              lineCtx,
            };
            break;
          }
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9": {
            ctx = {
              type: ParserState.OrderedListStart,
              chars: currChar,
              withChecklistContent: false,
              ordered: true,
              orderSuffixChar: "",
              lineCtx,
            };
            break;
          }
          default: {
            return {type: NodeType.Text, content: text.slice(startOffset)};
          }
        }
        break;
      }
      case ParserState.OrderedListStart: {
        switch (currChar) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            ctx.chars += currChar;
            break;
          case ".":
          case ")":
            ctx.type = ParserState.OrderedListClose;
            ctx.orderSuffixChar = currChar;
            break;
          default:
            return {type: NodeType.Text, content: text.slice(startOffset)};
        }
        break;
      }
      case ParserState.OrderedListClose: {
        switch (currChar) {
          case " ":
            ctx.type = ParserState.WaitForCheckListOpen;
            break;
          default:
            return {
              type: NodeType.Text,
              content: text.slice(startOffset),
            };
        }
        break;
      }
      case ParserState.QuoteStart: {
        switch (currChar) {
          case " ":
            return {
              type: NodeType.Quote,
              content: parseNode(text, i + 1, ctx.lineCtx),
            };
          default:
            return {type: NodeType.Text, content: text.slice(startOffset)};
        }
      }
      case ParserState.UnorderedListStart: {
        switch (currChar) {
          case " ":
            ctx.type = ParserState.WaitForCheckListOpen;
            break;
          default:
            return {type: NodeType.Text, content: text.slice(startOffset)};
        }
        break;
      }
      case ParserState.WaitForCheckListOpen: {
        switch (currChar) {
          case "[":
            ctx.type = ParserState.WaitForCheckListContent;
            break;
          default:
            return finalizeList(ctx, text, startOffset);
        }
        break;
      }
      case ParserState.WaitForCheckListContent: {
        switch (currChar) {
          case " ":
          case "x":
          case "X":
            ctx.type = ParserState.WaitForCheckListClose;
            ctx.withChecklistContent = true;
            break;
          case "]":
            ctx.type = ParserState.CheckListDone;
            break;
          default:
            return finalizeList(ctx, text, startOffset);
        }
        break;
      }
      case ParserState.WaitForCheckListClose: {
        switch (currChar) {
          case "]":
            ctx.type = ParserState.CheckListDone;
            break;
          default:
            return finalizeList(ctx, text, startOffset);
        }
        break;
      }
      case ParserState.CheckListDone: {
        const listOffset = ctx.withChecklistContent ? 3 : 2;
        switch (currChar) {
          case " ":
            return finalizeList(ctx, text, startOffset, listOffset, true);
          default:
            return finalizeList(ctx, text, startOffset, listOffset);
        }
      }
      case ParserState.Indent: {
        switch (currChar) {
          case "\t": {
            ctx.depth += 2;
            break;
          }
          case " ": {
            ctx.depth += 1;
            break;
          }
          default: {
            return {
              type: NodeType.Indentation,
              depth: ctx.depth,
              content: parseNode(text, i, ctx.lineCtx),
            };
          }
        }
        break;
      }
      default:
        return {type: NodeType.Text, content: text.slice(startOffset)};
    }
  }
  return {type: NodeType.Text, content: text.slice(startOffset)};
};

export const parseLine = (text: string): RootLineAstNode => ({
  type: NodeType.Root,
  content: parseNode(text, 0, {withinList: false}),
});

import {XCol} from "@cdx/common";
import uiClasses from "@cdx/common/xui/ui.css";
import {CdxCropImgByFile} from "./CdxImg";

const shared = {
  elevation: 1,
  rounded: "sm",
  bg: "gray200",
};

/**
 *
 * @param {{
 *  project: import("../cdx-models/Project").Project
 *  size?: number
 *  onDark?: boolean
 * }} props
 * @returns ReactElement
 */
const MiniProjectTile = ({project, size = 25, onDark, ...rest}) => (
  <XCol
    as={CdxCropImgByFile}
    {...shared}
    width={size}
    height={size}
    file={project.coverFile}
    border={onDark ? "gray500" : "white"}
    fallbackClassName={uiClasses.backgroundColor.gray500}
    {...rest}
  />
);

export default MiniProjectTile;

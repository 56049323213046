import {useRoot} from "../../lib/mate/mate-utils";
import {useHistory} from "react-router";
import {pronounceSafeSeq} from "../../lib/sequences";
import Card from "../Card";
import {getStatusForCard} from "../../lib/card-status-utils";
import {Link} from "react-router-dom";
import {cx, isWithMetaKey, shrinker, TooltipForChild} from "@cdx/common";
import {DSCardLinkStyles as styles} from "@cdx/ds/components/DSCard/DSCardLink.css";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {getParentCard} from "../../features/workflows/workflow-utils";
import UnMarkedown from "./UnMarkedown";
import {useArenaContext} from "../../layouts/arena-layout/createArenaContext";
import routes from "../../routes";
import {forwardRef} from "react";

const Wrapper = forwardRef(({noOnClick, card, addUrlState, ...rest}, ref) => {
  const arenaCtx = useArenaContext();
  const cardUrl = !noOnClick && arenaCtx && card ? arenaCtx.routes.getCardUrl({card}) : null;
  const history = useHistory();
  if (cardUrl) {
    const handleClick = (e) => {
      if (!isWithMetaKey(e) && e.button !== 1) {
        const urlObj = typeof cardUrl === "string" ? {pathname: cardUrl} : cardUrl;
        history.push(addUrlState ? {...urlObj, state: {...urlObj, ...addUrlState}} : urlObj);
        e.preventDefault();
      }
      e.stopPropagation();
    };
    const pathname =
      typeof cardUrl === "string"
        ? cardUrl
        : cardUrl.state?.showCardId
          ? routes.handWithCard.getUrl(card)
          : cardUrl.pathname;

    // use plain, stateless pathname here, so cmd+click works as expected, the real click is handled above
    return <Link onClick={handleClick} to={pathname} ref={ref} {...rest} />;
  } else {
    return <span {...rest} ref={ref} />;
  }
});

const ParentTile = ({inline, title}) => (
  <UnMarkedown
    maxChars={20}
    as="span"
    className={cx(styles.parentCard.base, inline && styles.parentCard.inline)}
    onDark
    skipCardReferences
  >
    {title}
  </UnMarkedown>
);

const CardReference = ({id, children, noOnClick, inline = true, addUrlState}) => {
  const root = useRoot();
  const card = root.account.$meta.find("cards", {
    accountSeq: pronounceSafeSeq.seqToInt(id),
    ...(process.env.REACT_APP_MODE === "open" && {isPublic: true}),
  })[0];

  const parentCard = card && getParentCard(card);
  const pTitle = parentCard && parentCard.$meta.get("title", null);
  const status = card ? getStatusForCard(card) : "unassigned";

  return (
    <TooltipForChild
      tooltip={card ? <Card card={card} root={root} /> : "No card with this id"}
      bg={"gray800"}
      style={{padding: 15}}
    >
      <Wrapper
        card={card}
        noOnClick={noOnClick}
        className={cx(
          "is-card-reference",
          styles.container.base,
          cardStatusVariants[status],
          inline ? styles.container.inline : styles.container.nonInline
        )}
        title={card && `${pTitle ? `[${pTitle}] ` : ""}${card.title}`}
        addUrlState={addUrlState}
      >
        {children ||
          (card && card.title ? (
            <>
              {pTitle && <ParentTile inline={inline} title={pTitle} />}
              {shrinker(card.title, 21)}
            </>
          ) : (
            `$${id}`
          ))}
      </Wrapper>
    </TooltipForChild>
  );
};

export default CardReference;

import {XCol, XGrid, XText, XTextButton, springConfigs, useReveal} from "@cdx/common";
import uiClasses from "@cdx/common/xui/ui.css";
import {DragController, useDropZone} from "@codecks/dnd";
import range from "lodash/range";
import {useRef, useState, useEffect, useMemo} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {animated} from "react-spring";
import Card, {DragCard, MaybeInaccessibleCard, renderCardPlaceholder} from "../../components/Card";
import {CARD_HEIGHT} from "../../components/Card/card.css";
import SortableCardList from "../card-panel/SortableCardList";
import {
  canDiscardHandFromQueueErrors,
  getAllCardPos,
  getUserQueueCardIds,
  isCardInSomeHandQueue,
  isCardInUsersHandQueue,
} from "../hand-queue/hand-queue-utils";
import {getDropErrorGetter, EmptySlot, HandBg} from "../hand-queue/HandQueue";
import {getPathBasedRoutes} from "../../layouts/arena-layout/createArenaContext";
import {api} from "../../lib/api";
import {checkIfPresent, useInstance, useRoot} from "../../lib/mate/mate-utils";
import {useModalWithData} from "../../components/Modals";
import DSAvatar from "../../components/DSAvatar/DSAvatar";
import {Col, Row, Text} from "@cdx/ds";

const getCardIdFromEntry = (entry) => (entry.$fakeEntry ? entry.card.id : entry.cardId);

const getDiscardError = ({card, account, userId}) => {
  const {isLoaded, result} = checkIfPresent(() => {
    if (!isCardInUsersHandQueue({cardId: card.cardId, account, userId})) return "Not in hand";
    return canDiscardHandFromQueueErrors(card);
  }, api);
  return isLoaded ? result : false;
};

const SingleUserHandQueue = ({
  user,
  root,
  getCardUrl,
  goToCard,
  initialCardIdSet,
  onCardsDiscarded,
}) => {
  const userId = user.$meta.get("id", null);
  const cardIds = getUserQueueCardIds({userId, root});
  const entries = cardIds.map((id) => ({cardId: id, card: api.getModel({modelName: "card", id})}));

  const handleItemDrop = (droppedCardIds, {draggedCardIds}) => {
    return api.mutate.handQueue.setCardOrders({
      cardIds: droppedCardIds,
      draggedCardIds,
      userId,
    });
  };

  return (
    <Col sp="8px" relative>
      <SortableCardList
        isOrderLoaded={true}
        getDropErrors={getDropErrorGetter(userId, root, cardIds)}
        getCardIdFromEntry={getCardIdFromEntry}
        entries={entries}
        handleItemDrop={handleItemDrop}
        getFreshItems={() => {
          const freshRoot = api.getRoot();
          return getUserQueueCardIds({userId, root: freshRoot});
        }}
        createOutsideEntry={(card) => ({card, $fakeEntry: true})}
        cardContainerKey={`overlay-hand-${userId}`}
        getKey={(entry, idx) => `${userId}-${entry.cardId}`}
        renderEntry={({entry, key, ref, dragCtx, ...rest}) => {
          const card = entry.card;
          const discardError = card && getDiscardError({card, account: root.account, userId});
          return (
            <XCol sp={2} align="center" className={uiClasses.hideContainer} key={key} ref={ref}>
              <MaybeInaccessibleCard
                root={root}
                card={card}
                arenaCtx={{type: "handQueue"}}
                dragCtx={{
                  ...dragCtx,
                  ...(!entry.$fakeEntry && {
                    type: "queueEntry",
                    id: entry.cardId,
                    sourceList: userId,
                  }),
                }}
                hideHandPosition
                getCardUrl={getCardUrl}
                goToCard={goToCard}
                isHighlighted={initialCardIdSet.has(card && card.id)}
                verticalMargin={24 + 16}
                {...rest}
              />
              {card && !card.$meta.isDeleted() && (
                <XCol className={uiClasses.hideElement}>
                  <XTextButton
                    color="white"
                    disabled={discardError}
                    tooltip={discardError ? `Can't discard: ${discardError}` : null}
                    onClick={() =>
                      api.mutate.handQueue
                        .removeCards({cardIds: [card.id]})
                        .then(() => onCardsDiscarded([card.id]))
                    }
                  >
                    Discard
                  </XTextButton>
                </XCol>
              )}
            </XCol>
          );
        }}
        renderPostfix={(dataIn) =>
          range(dataIn.length, 7, 1).map((idx) => (
            <Col sp="12px">
              <EmptySlot number={idx + 1} key={idx} type="primary" />
              <div style={{height: 12}} />
            </Col>
          ))
        }
      />
    </Col>
  );
};

const NonHandCardArea = ({root, cards, initialCardIdSet, onCardsDiscarded}) => {
  const dragItemErrorMessage = (item) => {
    if (!item) return null;
    const userId = item.data.dragCtx?.sourceList ?? null;
    if (!userId) return null;
    const {cardInfos} = item.data;
    let error = null;
    cardInfos.some(({getCard}) => {
      error = getDiscardError({card: getCard(), account: root.account, userId});
      return error;
    });
    return error;
  };

  const {
    ref: dropRef,
    isOver,
    dragItem,
  } = useDropZone({
    type: "CARD",
    onDrop: ({item}) => {
      if (dragItemErrorMessage(item)) return;
      const userId = item.data.dragCtx?.sourceList;
      if (!userId) return;
      const {cardInfos} = item.data;
      const cardIds = cardInfos.map(({cardId}) => cardId);
      return api.mutate.handQueue.removeCards({cardIds}).then(() => onCardsDiscarded(cardIds));
    },
  });

  const dragError = dragItemErrorMessage(dragItem);

  const reveal = useReveal(dragError !== "Not in hand" ? dragError : null, {
    config: springConfigs.quick,
  });

  return (
    <XCol sp={3}>
      <XText preset="bold" color="white">
        Cards not in hand
      </XText>
      <XCol pa={3} bg={isOver ? "white_a25" : "white_a10"} rounded="md" relative ref={dropRef}>
        {reveal((props, renderError) => (
          <XCol
            absolute
            inset="full"
            pa={3}
            bg="error600"
            border="error500"
            rounded="md"
            style={{borderWidth: 2, zIndex: 1, opacity: props.value}}
            align="center"
            justify="center"
            as={animated.div}
          >
            <XText color="error100" preset="bold" align="center" size={3}>
              {renderError}
            </XText>
          </XCol>
        ))}
        <XGrid sp={3} style={{minHeight: CARD_HEIGHT}}>
          {cards.map((card) => (
            <Card
              key={card.id}
              draggable
              card={card}
              root={root}
              isHighlighted={initialCardIdSet.has(card.id)}
            />
          ))}
        </XGrid>
      </XCol>
    </XCol>
  );
};

// using ref magic to remember previously shown users
// const useShownUsers = ({users, root}) => {
//   const meId = root.loggedInUser?.$meta.get("id", null);
//   const prevShownUserIdsRef = useRef([]);
//   const alreadyPresentIds = new Set(prevShownUserIdsRef.current);
//   const desiredUserMap = new Map(
//     [...users.map((u) => [u.$meta.get("id", null), u]), [meId, root.loggedInUser]].filter(([k]) =>
//       Boolean(k)
//     )
//   );
//   const shownUserIds = [...new Set([...alreadyPresentIds, ...desiredUserMap.keys()])];
//   useEffect(() => {
//     prevShownUserIdsRef.current = shownUserIds;
//   });
//   return shownUserIds.map((id) => desiredUserMap.get(id) || api.getModel({modelName: "user", id}));
// };

const useStableUsers = (users) => {
  const userIds = users.map((u) => u.$meta.get("id", null)).filter(Boolean);
  const prevUserSetRef = useRef(null);
  if (!prevUserSetRef.current) {
    prevUserSetRef.current = new Set(userIds);
    return users;
  } else {
    const alreadyPresentIds = new Set(userIds);
    userIds.forEach((id) => alreadyPresentIds.delete(id));
    alreadyPresentIds.forEach((id) => prevUserSetRef.current.add(id));
    return [...prevUserSetRef.current].map((id) => api.getModel({modelName: "user", id}));
  }
};

const LazyHandQueueOverlay = ({cardId, onClose}) => {
  const root = useRoot();
  const card = useInstance("card", cardId);
  const user = card.assignee || root.loggedInUser;
  return <HandQueueOverlay cards={[card]} users={[user]} root={root} onClose={onClose} />;
};

export const useLazyHandQueueOverlay = (cardId) => {
  const [modalOpen, setModalOpen] = useState(false);
  const renderModal = useModalWithData(modalOpen, {
    onClose: () => setModalOpen(false),
    width: 967,
    hideClose: true,
    purpleBackdrop: true,
  });
  return {
    modal: renderModal(() => (
      <LazyHandQueueOverlay cardId={cardId} onClose={() => setModalOpen(false)} />
    )),
    setModalOpen,
  };
};

export const useHandQueueOverlay = ({root, card}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const renderModal = useModalWithData(modalOpen, {
    onClose: () => setModalOpen(false),
    width: 967,
    hideClose: true,
    purpleBackdrop: true,
  });
  return {
    modal: renderModal(() => (
      <HandQueueOverlay cards={[card]} root={root} onClose={() => setModalOpen(false)} />
    )),
    setModalOpen,
  };
};

const getUsersForCards = ({initialCards, root, discardedCardIdSet}) => {
  const meId = root.loggedInUser?.$meta.get("id", null);
  const leftOverDiscardedCardIds = new Set(...discardedCardIdSet);
  const userIds = new Set(meId ? [meId] : []);
  const handLessCards = [];
  for (const card of initialCards) {
    leftOverDiscardedCardIds.delete(card.cardId);
    const assigneeId = card.assignee?.$meta.get("id", null);
    if (assigneeId) userIds.add(assigneeId);
    const userMap = getAllCardPos(card.cardId, root.account);
    if (!userMap) {
      handLessCards.push(card);
    } else {
      for (const [userId] of userMap) userIds.add(userId);
    }
  }
  for (const id of leftOverDiscardedCardIds) {
    const inSomeHand = isCardInSomeHandQueue({cardId: id, account: root.account});
    if (!inSomeHand) handLessCards.push(api.getModel({modelName: "card", id}));
  }
  return {
    users: [...userIds].map((userId) => api.getModel({modelName: "user", id: userId})),
    handLessCards,
  };
};

const HandQueueOverlay = ({cards: initialCards, root, onClose}) => {
  const history = useHistory();
  const location = useLocation();
  const initialCardIdSet = useMemo(
    () => new Set(initialCards.map((c) => c.cardId)),
    [initialCards]
  );
  // const [hiddenCardIds, {set: setHiddenCardIds}] = useSet();
  const [discardedCardIdSet, setDiscardedCardIdSet] = useState(() => new Set());
  const onCardsDiscarded = (cardIds) => {
    setDiscardedCardIdSet((prev) => new Set([...prev, cardIds]));
  };
  const {getCardUrl, goToCard} = getPathBasedRoutes({
    rootPath: "",
    getRootUrl: () => "",
    history,
  });
  const lastOnCloseRef = useRef(onClose);
  useEffect(() => {
    return () => {
      lastOnCloseRef.current();
    };
  }, [location]);
  useEffect(() => {
    lastOnCloseRef.current = onClose;
  }, [onClose]);

  // const {externalDropInfo, onDropStart, onDropEnd} = useExternalCardDrop();

  const {users: instableUsers, handLessCards} = getUsersForCards({
    initialCards,
    root,
    discardedCardIdSet,
  });
  const users = useStableUsers(instableUsers);
  // const {users, handLessCards} = useMemo(
  //   () => getUsersForCards({cards, root, discardedCardIdSet}),
  //   [cards, root, discardedCardIdSet]
  // );
  // const shownUsers = useShownUsers({users, root});

  return (
    <DragController
      type="CARD"
      renderItem={({id}) => <DragCard root={root} id={id} />}
      renderPlaceholder={renderCardPlaceholder}
      layerKey="handQueueOverlay"
    >
      <HandBg pa={5} sp={4}>
        <NonHandCardArea
          root={root}
          cards={handLessCards}
          initialCardIdSet={initialCardIdSet}
          onCardsDiscarded={onCardsDiscarded}
          // hiddenCardIds={hiddenCardIds}
          // initialCardsNotInHand={handLessCards}
          // droppedCardIds={droppedCardIds}
          // setDroppedCardIds={setDroppedCardIds}
          // onDropStart={onDropStart}
          // onDropEnd={onDropEnd}
          // shownUserId={currShownUserId}
        />
        {users.map((u) => (
          <Col key={u.id} sp="12px">
            <Row sp="8px" align="center">
              <DSAvatar user={u} size={16} />
              <Text color="secondary" type="label14">
                {u.name}
              </Text>
            </Row>
            <SingleUserHandQueue
              user={u}
              root={root}
              getCardUrl={getCardUrl}
              goToCard={goToCard}
              initialCardIdSet={initialCardIdSet}
              onCardsDiscarded={onCardsDiscarded}
              // initialCards={cards}
              // setHiddenCardIds={setHiddenCardIds}
              // setDroppedCardIds={setDroppedCardIds}
              // externalDropInfo={externalDropInfo}
            />
          </Col>
        ))}
      </HandBg>
    </DragController>
  );
};

export default HandQueueOverlay;

import {useNextForm} from "@cdx/common/forms-next/FormNext";
import {Card} from "../../../../cdx-models/Card";
import {
  CdxLexicalEditor,
  CdxLexicalState,
  useCdxEditorState,
} from "../../../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import * as v from "valibot";
import {ReactNode, useEffect, useState} from "react";
import {DSButton, DSShortcutKey, Row, Text, css} from "@cdx/ds";
import {isMac, useEsc} from "@cdx/common";
import {transformUploadForTextEditor} from "../../../../components/uploader";
import {DSInputStyles} from "@cdx/ds/components/DSForm/DSInput.css";
import {ResolvableEntry} from "../../../../cdx-models/ResolvableEntry";
import useMutation from "../../../../lib/hooks/useMutation";
import {Resolvable} from "../../../../cdx-models/Resolvable";

const commmentSchema = v.object({
  isEmpty: v.pipe(v.boolean(), v.value(false)),
});

type CommentFormProps = {
  onSubmit: () => void;
  content: CdxLexicalState;
  isEmpty: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  onReceiveFile?: (f: any) => any;
  card: Card;
  plugins?: ReactNode;
};
const CommentForm = (props: CommentFormProps) => {
  const {content, isEmpty, onSubmit, card, placeholder, autoFocus, onReceiveFile, plugins} = props;

  const {Form, setValue, formStateSlice, triggerSubmit} = useNextForm({
    initial: {isEmpty},
    schema: commmentSchema,
    onSubmit: () => onSubmit(),
  });

  useEffect(() => {
    setValue("isEmpty", isEmpty);
  }, [isEmpty, setValue]);

  const state = formStateSlice.useValue((s) => s);

  return (
    <Form buttonLabel={null} className={css({display: "flex", flexDir: "column", sp: "12px"})}>
      <CdxLexicalEditor
        value={content.state}
        card={card}
        placeholder={placeholder}
        maybeProject={card.deck && card.deck.project}
        onPasteFile={transformUploadForTextEditor(onReceiveFile)}
        autoFocus={autoFocus}
        onCmdEnter={triggerSubmit}
        className={css(
          {minHeight: "commentField"},
          DSInputStyles.root,
          DSInputStyles.sharedBase,
          DSInputStyles.inputBase,
          DSInputStyles.size.md,
          !isEmpty && DSInputStyles.withValue
        )}
        plugins={plugins}
      />
      <Row justify="end" align="baseline" sp="8px">
        <Text type="label12" color="secondary" noOverflow>
          {isEmpty ? (
            "Write a message and"
          ) : (
            <>
              <DSShortcutKey>{isMac() ? "cmd" : "ctrl"}</DSShortcutKey> +{" "}
              <DSShortcutKey>Enter</DSShortcutKey> to
            </>
          )}
        </Text>
        <DSButton size="sm" type="submit" state={state} disabled={isEmpty}>
          Send
        </DSButton>
      </Row>
    </Form>
  );
};

export default CommentForm;

type CommentEditFormProps = {
  entry: ResolvableEntry;
  resolvable: Resolvable;
  closeEdit: () => void;
  card: Card;
  onReceiveFile: (f: any) => any;
};

export const CommentEditForm = (props: CommentEditFormProps) => {
  const {entry, resolvable, closeEdit, card, onReceiveFile} = props;
  const [doUpdate] = useMutation("resolvables", "updateComment");
  const [isEmpty, setIsEmpty] = useState(false);
  const contentField = useCdxEditorState({
    initialContent: () => entry.$meta.get("content", false),
    contentKey: entry.$meta.get("entryId", null),
    onChangeListeners: {
      isEmpty: setIsEmpty,
    },
  });

  useEsc(closeEdit);

  const {Form, setValue, formStateSlice, triggerSubmit} = useNextForm({
    initial: {isEmpty},
    schema: commmentSchema,
    onSubmit: () =>
      doUpdate({
        entryId: entry.entryId,
        content: contentField.getText(),
        resolvableId: resolvable.id,
      }).then(closeEdit),
  });

  useEffect(() => {
    setValue("isEmpty", isEmpty);
  }, [isEmpty, setValue]);

  const state = formStateSlice.useValue((s) => s);

  return (
    <Form buttonLabel={null} className={css({display: "flex", flexDir: "column", sp: "12px"})}>
      <CdxLexicalEditor
        value={contentField.state}
        card={card}
        maybeProject={card.deck && card.deck.project}
        onPasteFile={transformUploadForTextEditor(onReceiveFile)}
        autoFocus
        onCmdEnter={triggerSubmit}
        className={css(
          {minHeight: "commentField"},
          DSInputStyles.root,
          DSInputStyles.sharedBase,
          DSInputStyles.inputBase,
          DSInputStyles.size.md,
          !isEmpty && DSInputStyles.withValue
        )}
      />
      <Row align="baseline" sp="8px">
        <DSButton variant="secondary" onClick={closeEdit} size="sm">
          Cancel
        </DSButton>
        <Text type="label12" color="secondary" noOverflow ml="auto">
          <DSShortcutKey>{isMac() ? "cmd" : "ctrl"}</DSShortcutKey> +{" "}
          <DSShortcutKey>Enter</DSShortcutKey> to
        </Text>
        <DSButton size="sm" type="submit" state={state} disabled={isEmpty}>
          Save
        </DSButton>
      </Row>
    </Form>
  );
};

import {projectsTags, personalTags} from "../../../lib/utils";

const defaultTagCategories = ({prop, getTags}) => ({
  getSuggestions({input, projects, root}) {
    const match = input.match(/^(?:#(\w*)|(\w{3,}))$/);
    if (match) {
      const filtered = getTags({input, projects, root}).filter((t) =>
        t.toLowerCase().startsWith(match[1] || match[2] || "")
      );
      return [...new Set(filtered)];
    } else {
      return [];
    }
  },
  valueToKey(tag) {
    return tag;
  },
  valuesToDbQuery(values, {forceOr}) {
    if (values.length === 1) return {[prop]: {op: "has", value: values[0]}};
    return {[forceOr ? "$or" : "$and"]: values.map((tag) => ({[prop]: {op: "has", value: tag}}))};
  },
  renderSuggestion(tag) {
    return `#${tag}`;
  },
  savedSearchLabel(tag) {
    return {
      prio: 1,
      label: `#${tag}`,
    };
  },
});

const tagCategories = [
  {
    label: "Project tag",
    key: "prjct-tags",
    ...defaultTagCategories({
      prop: "masterTags",
      getTags: ({projects}) => projectsTags(projects).map((t) => t.tag),
    }),
  },
  {
    label: "Personal tag",
    key: "prsnl-tags",
    ...defaultTagCategories({
      prop: "tags",
      getTags: ({root}) => personalTags(root.loggedInUser, root.account).map((t) => t.tag),
    }),
  },
  {
    label: "Other tag",
    key: "tags",
    ...defaultTagCategories({
      prop: "tags",
      getTags: ({root}) => personalTags(root.loggedInUser, root.account).map((t) => t.tag),
    }),
    getSuggestions({input}) {
      const match = input.match(/^(?:#(\w+))$/);
      return match ? [match[1]] : [];
    },
  },
];

export default tagCategories;

import {XCol, XRow, XText} from "./xui";
import xcolors from "./xui/xcolors";
import {useShouldShowHintKey} from "../features/hints/HintManager";
import XTextButton from "./xui/XTextButton";
import {DSIconInfo} from "@cdx/ds";

const Hint = ({children, action, onLight}) => {
  const actions = Array.isArray(action) ? action : action ? [action] : [];
  return (
    <XRow
      rounded="sm"
      border={onLight ? "gray400" : "gray600"}
      bg={onLight && "gray100"}
      px={2}
      py={1}
      sp={3}
      align="center"
    >
      <DSIconInfo style={{color: onLight ? xcolors.gray500 : xcolors.gray500}} size={24} />
      <XCol sp={1} fillParent>
        <XText size={2} color={onLight ? "gray600" : "gray400"}>
          {children}
        </XText>
        {actions.length > 0 && (
          <XRow justify="end" sp={1}>
            {actions.map(({label, ...rest}, i) => (
              <XTextButton color={onLight ? "red" : "white"} key={i} {...rest}>
                {label}
              </XTextButton>
            ))}
          </XRow>
        )}
      </XCol>
    </XRow>
  );
};

export const HintForHintKey = ({children, hintKey, onLight}) => {
  const [shouldShow, dismiss] = useShouldShowHintKey(hintKey);
  if (!shouldShow) return null;
  return (
    <Hint action={{label: "Understood", onClick: dismiss}} onLight={onLight}>
      {children}
    </Hint>
  );
};

export default Hint;

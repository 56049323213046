import {XCol, XText, XRow, XPush} from "../../components/xui";
import XGhostButton from "../../components/xui/XGhostButton";
import {getModalUrl} from "../../components/ModalRegistry";
import {getLimits} from "../billing/billing-utils";
import Emoji from "../../components/Markdown/Emoji";
import {getUserRole, hasPermissionToManageOrg} from "../../lib/permissions";

const NudgeArea = ({children, action, isBad}) => (
  <XRow px={4} py={3} sp={4} bg={isBad ? "warn100" : "blue600"} align="center">
    <XText size={3} color={isBad ? "warn700" : "white"}>
      {children}
    </XText>
    {action && (
      <>
        <XPush />
        <XGhostButton color={isBad ? "warn" : "white"} onClick={action.onClick} to={action.to}>
          {action.label}
        </XGhostButton>
      </>
    )}
  </XRow>
);

const nudgeList = [
  {
    shouldShow: ({root}) => {
      const {account} = root;
      const {isLoaded, role} = getUserRole(root);
      if (!isLoaded || !role) return false;
      const {seats, activeProjectCount} = account;
      const limits = getLimits(account);
      if (limits.projectLimit && activeProjectCount > limits.projectLimit) return true;
      if (limits.userLimit && seats > limits.userLimit) return true;
      return false;
    },
    comp: ({location, root}) => {
      const canManage = hasPermissionToManageOrg(root);
      return (
        <NudgeArea
          isBad
          action={
            canManage && {
              label: "Upgrade",
              to: getModalUrl({location, modal: "accountSettings.billing"}),
            }
          }
        >
          <XCol sp={0}>
            <p>
              <b>Read-only mode:</b> Your organization currently exceeds the limits of the free
              plan. <Emoji>😞</Emoji>
            </p>
            <p>
              Either remove projects and/or users from your organization or consider upgrading to a
              Pro plan.
            </p>
          </XCol>
        </NudgeArea>
      );
    },
  },
];

const TopNudges = ({root, location}) => {
  if (!root.loggedInUser) return null;
  const firstNudge = nudgeList.find(({shouldShow}) => shouldShow({root}));
  if (!firstNudge) return null;
  const Comp = firstNudge.comp;
  return <Comp root={root} location={location} />;
};

export default TopNudges;

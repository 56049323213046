import {decorationStyles} from "../css/decoration.css";
import {layoutStyles} from "../css/layout.css";
import {isMac} from "./device-utils";

export const makeScrollable = (
  opts: {dir?: "vertical" | "horizontal" | "both"; mode?: "thin" | "wide"} = {}
) => {
  const {dir = "vertical", mode = "thin"} = opts;
  const onMac = isMac();
  const list: string[] = [];
  if (dir === "vertical") {
    list.push(layoutStyles.overflowX.hidden, layoutStyles.overflowY.auto);
  } else if (dir === "horizontal") {
    list.push(layoutStyles.overflowY.hidden, layoutStyles.overflowX.auto);
  } else {
    list.push(layoutStyles.overflowY.auto, layoutStyles.overflowX.auto);
  }
  if (!onMac) {
    list.push(decorationStyles.prettyNonMacScrollBar["true"]);
    if (mode === "wide") list.push(decorationStyles.prettyNonMacScrollBar.setWide);
  }
  return list.join(" ");
};

import {useState} from "react";
import {XCol, XText} from "../../components/xui";
import Ui from "../../components/ui2";
import routes from "../../routes";
import {getModalUrl} from "../../components/ModalRegistry";
import {api} from "../../lib/api";
import {TextBadge} from "../../components/xui/Badge";
import Uploader from "../../components/uploader";
import xcolors from "../../components/xui/xcolors";
import XGhostButton from "../../components/xui/XGhostButton";
import {remainingSpaceInKB} from "../billing/billing-utils";
import {menuNudgeStyles as styles} from "./nudges.css";
import {OLD_RELEASE_MS, useDeploymentAgeInMs} from "../../components/DeploymentInfo";
import Emoji from "../../lib/emoji/EmojiRenderer";
import {hasPermissionToManageOrg} from "../../lib/permissions";

const RawHint = ({children, isBad, sp = 2}) => (
  <XCol relative px={3} py={3} sp={sp} bg={isBad ? "warn100" : "blue600"}>
    {children}
  </XCol>
);

const MenuNudge = ({children, action, isBad}) => (
  <RawHint isBad={isBad}>
    <XText size={2} color={isBad ? "warn800" : "blue100"}>
      {children}
    </XText>
    {action && (
      <XCol align="end">
        {action.doneMessage ? (
          <TextBadge>{action.doneMessage}</TextBadge>
        ) : (
          <XGhostButton
            size="sm"
            color={isBad ? "warn" : "white"}
            onClick={action.onClick}
            to={action.to}
          >
            {action.label}
          </XGhostButton>
        )}
      </XCol>
    )}
  </RawHint>
);

const nudges = {
  pastDue: {
    shouldShow: ({root: {account}, canManageOrg}) => {
      return canManageOrg && account.stripeAccountSync?.status === "past_due";
    },
    comp: ({location}) => {
      const url = getModalUrl({location, modal: "accountSettings.billing"});
      return (
        <MenuNudge isBad action={{label: "Open Billing", to: url}}>
          The latest subscription charge failed. Please verify your payment information.
          <br />
          The next payment attempt might take a few days.
        </MenuNudge>
      );
    },
  },

  insufficientStorage: {
    shouldShow: ({root: {account}, canManageOrg}) => {
      if (!canManageOrg) return false;
      return remainingSpaceInKB(account) < 0;
    },
    comp: () => (
      <MenuNudge isBad action={{label: "Open Media libary", to: routes.media.getUrl()}}>
        <XCol sp={1}>
          <p>It looks like the account storage limit has been exceeded.</p>
          <p>
            You&#8217;ll need to do some housekeeping in your media library in order to reclaim some
            space. Until then, attachments can no longer be added. We&#8217;re sorry{" "}
            <Emoji>😞</Emoji>
          </p>
        </XCol>
      </MenuNudge>
    ),
  },
  unverifiedEmail: {
    shouldShow: ({root: {loggedInUser}}) => {
      return (
        loggedInUser.unverifiedEmails.length > 0 && loggedInUser.unverifiedEmails[0].$meta.isLoaded
      );
    },
    comp: ({root: {loggedInUser}}) => {
      const first = loggedInUser.unverifiedEmails[0];
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [done, setDone] = useState(false);
      const resendVerificationMail = () =>
        api.mutate.userEmails
          .resendVerification({userId: loggedInUser.id, id: first.id})
          .then(() => setDone(true));
      return (
        <MenuNudge
          action={{
            label: "Resend verification mail",
            onClick: resendVerificationMail,
            doneMessage: done && "Email sent.",
          }}
        >
          Your email address <b style={{color: xcolors.white}}>{first.email}</b> hasn&#8217;t been
          verified yet. Please check your Inbox.
        </MenuNudge>
      );
    },
  },
  newDeployments: {
    shouldShow: ({age}) => {
      return !!age;
    },
    comp: ({age}) => (
      <MenuNudge
        isBad={age > OLD_RELEASE_MS}
        action={{
          label: "Reload page",
          onClick: () => window.location.reload(),
        }}
      >
        {age > OLD_RELEASE_MS
          ? "Codecks has been updated a while ago. Please reload this page soon to prevent issues."
          : "Codecks has been updated."}
      </MenuNudge>
    ),
  },
  noAvatar: {
    shouldShow: ({root: {loggedInUser}}) => {
      return !loggedInUser.profileImage;
    },
    comp: ({root: {loggedInUser}}) => (
      <Uploader
        key="avatarnudge"
        id={`profileImage-${loggedInUser.id}`}
        onUpload={(data) => api.mutate.users.update({id: loggedInUser.id, profileImageData: data})}
        noAccount
      >
        {({dropAreaProps, inputProps, ongoingUploads}) => (
          <RawHint sp={null}>
            <Uploader.DropArea {...dropAreaProps} label="Set as profile image" />
            <XCol sp={1}>
              <XText size={2} color="blue100" align="center">
                Codecks is more fun with a profile image!
              </XText>
              {ongoingUploads.length > 0 ? (
                <Uploader.Uploads ongoingUploads={ongoingUploads} onDark />
              ) : (
                <XCol align="center">
                  <XText color="blue100" preset="label" size={1}>
                    Drop image here or{" "}
                    <label style={{color: xcolors.active_l2, cursor: "pointer"}}>
                      browse <input {...inputProps} style={{display: "none"}} />
                    </label>
                  </XText>
                </XCol>
              )}
            </XCol>
          </RawHint>
        )}
      </Uploader>
    ),
  },
};

const menuNudges = [
  "pastDue",
  "insufficientStorage",
  "unverifiedEmail",
  "newDeployments",
  "noAvatar",
];

export const MenuNudges = ({root, location}) => {
  let Comp = null;
  const age = useDeploymentAgeInMs();
  if (!root.loggedInUser) return null;
  const canManageOrg = hasPermissionToManageOrg(root);
  // We're forcing to go through all of them to make sure we request all the necessary data in one go

  menuNudges
    .map((key) => nudges[key])
    .forEach(({shouldShow, comp}) => {
      if (shouldShow({root, age, canManageOrg}) && !Comp) Comp = comp;
    });
  return Comp && <Comp root={root} location={location} age={age} />;
};

const joystickNudges = ["pastDue", "insufficientStorage"];

export const Joystick = ({root, onClick}) => {
  let showBadge = false;
  if (root.loggedInUser) {
    const canManageOrg = hasPermissionToManageOrg(root);
    joystickNudges
      .map((key) => nudges[key])
      .forEach(({shouldShow}) => {
        if (shouldShow({root, canManageOrg}) && !showBadge) showBadge = true;
      });
  }
  const age = useDeploymentAgeInMs();
  const isOldRelease = age && age > OLD_RELEASE_MS;
  return (
    <XGhostButton className={styles.joystickBox} onClick={onClick} color="faintWhite" square>
      {(showBadge || age) && (
        <XCol
          absolute
          rounded="full"
          elevation={1}
          bg={showBadge || isOldRelease ? "warn200" : "blue300"}
          className={styles.joystickBadge}
        />
      )}
      <Ui.Icon.Joystick
        size={26}
        style={{color: process.env.REACT_APP_IS_STAGE === "true" ? "#c992fc" : xcolors.white}}
      />
    </XGhostButton>
  );
};

import {forwardRef} from "react";
import XTextButton from "../../components/xui/XTextButton";
import {useGlobalKeyPress, DropDown, TooltipForChild} from "@cdx/common";
import {DSShortcutKey, Row, css} from "@cdx/ds";

export const MenuButton = forwardRef(
  (
    {
      tooltip,
      onClick,
      hidden,
      children,
      shortcut,
      showShortcutInTooltip,
      description = tooltip,
      specialShortcut,
    },
    ref
  ) => {
    useGlobalKeyPress({
      key: shortcut,
      fn: onClick,
      description,
      category: "Card Selection",
      disabled: !shortcut && !specialShortcut,
      // to ensure that hitting `p` doesn't trigger shortcut for just opened card
      prio: "high",
      ...specialShortcut,
    });

    const tooltipShortcut = showShortcutInTooltip || shortcut;

    const getTooltip = () => {
      const val = typeof tooltip === "function" ? tooltip() : tooltip;
      return (
        <Row sp="8px" px="8px" py="4px" align="center">
          <div>{val}</div>
          {tooltipShortcut && <DSShortcutKey size="md">{tooltipShortcut}</DSShortcutKey>}
        </Row>
      );
    };

    return (
      <TooltipForChild
        tooltip={getTooltip}
        hidden={hidden}
        delayed
        placement="bottom"
        bg="gray700"
        outerClassName={css({colorTheme: "gray550"})}
        ref={ref}
      >
        <XTextButton size="lg" square onClick={onClick} color="white">
          {children}
        </XTextButton>
      </TooltipForChild>
    );
  }
);

export const MenuButtonWithDropDown = ({
  children,
  overlayFn,
  tooltip,
  shortcut,
  specialShortcut,
  description,
  showShortcutInTooltip,
}) => (
  <DropDown distanceFromAnchor={5} placement="bottom" renderOverlay={overlayFn}>
    {(isOpen, toggle, ddRef) => (
      <MenuButton
        tooltip={tooltip}
        onClick={toggle}
        hidden={isOpen}
        ref={ddRef}
        shortcut={shortcut}
        description={description}
        specialShortcut={specialShortcut}
        showShortcutInTooltip={showShortcutInTooltip}
      >
        {children}
      </MenuButton>
    )}
  </DropDown>
);

import {ComponentPropsWithoutRef, forwardRef} from "react";
import LinkOrButton from "./LinkOrButton";
import ButtonBehaviour from "./ButtonBehaviour";
import {rawButtonStyle, textButtonStyles} from "./button.css";
import cx from "../cx";

const colorIsLight = {white: true, dimWhite: true, lightRed: true} as {[key: string]: boolean};
type Props = Partial<{
  color: keyof typeof textButtonStyles.colors;
  size: keyof typeof textButtonStyles.sizes;
  active: boolean;
  square: boolean;
}> &
  ComponentPropsWithoutRef<"button">;

const TextButton = forwardRef<HTMLElement, Props>(
  ({color, size, active, square, className, ...rest}, ref) => (
    <LinkOrButton
      ref={ref}
      className={cx(
        rawButtonStyle,
        textButtonStyles.default,
        rest.disabled && textButtonStyles.disabled,
        active
          ? color && colorIsLight[color]
            ? textButtonStyles.activeLight
            : textButtonStyles.active
          : color
            ? textButtonStyles.colors[color]
            : null,
        size ? textButtonStyles.sizes[size] : textButtonStyles.sizes.md,
        square && textButtonStyles.squarePadding[size || "md"],
        className
      )}
      {...rest}
    />
  )
);

const XTextButton = forwardRef<HTMLElement, Props & {tooltip: any; as: any} & any>((props, ref) => (
  <ButtonBehaviour comp={TextButton} ref={ref} {...props} />
));

export default XTextButton;

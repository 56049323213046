import {forwardRef} from "react";
import {getChildCards} from "../features/workflows/workflow-utils";
import {DSIconMinus, DSIconPrioA, DSIconPrioB, DSIconPrioC} from "@cdx/ds";

const iconByPrio = {
  a: DSIconPrioA,
  b: DSIconPrioB,
  c: DSIconPrioC,
};

export const usePriorityInfo = ({root, priority}) => {
  if (!priority || priority === "unknown") return {label: null, Icon: DSIconPrioA};
  const label = root.account.priorityLabels[priority] || null;
  return {label, Icon: iconByPrio[priority] || DSIconPrioA};
};

export const RawPriorityIcon = forwardRef(({priority, label, ...rest}, ref) => {
  const Icon = iconByPrio[priority];
  if (!Icon) return <DSIconMinus title={label} {...rest} ref={ref} />;
  return <Icon title={label} {...rest} ref={ref} />;
});

export const PriorityIconWithTitleProp = ({priority, root, ...rest}) => {
  const {Icon, label} = usePriorityInfo({priority, root});
  if (!label) return <DSIconMinus title="No Priority" {...rest} />;
  return <Icon title={label} {...rest} />;
};

// cards might also contain workflowItems
export const effortInfo = (cards, account) => {
  let effortSum = 0;
  let doneEffortSum = 0;
  let effortlessCards = 0;
  const cardMap = new Map();
  const parentCardIds = new Set();
  cards.forEach((c) => {
    cardMap.set(c.id, c);
    const childCards = getChildCards(c);
    if (childCards.length) parentCardIds.add(c.id);
    childCards.forEach((child) => {
      cardMap.set(child.id, child);
    });
    if (c.effort === null && childCards.length === 0 && !c.isDoc) effortlessCards += 1;
  });
  const allCards = [...cardMap.values()];
  allCards.forEach((c) => {
    if (c.status !== "done" && c.visibility !== "default") return;
    if (c.isDoc) return;
    let eff = c.effort;
    // only apply fallback effort to non-hero cards
    if (eff === null && !parentCardIds.has(c.id)) eff = account.fallbackEffort;
    effortSum += eff;
    if (c.status === "done") doneEffortSum += eff;
  });
  return {effortSum, effortlessCards, doneEffort: doneEffortSum};
};

export const Effort = ({effort, ...rest}) => (
  <div {...rest}>{effort === null || effort === "unknown" ? "-" : effort}</div>
);

import qs from "qs";
import {matchPath} from "react-router-dom";
import cdxEnv from "../env";
import {errorToString, useMachine} from "@cdx/common";
import {useState, useRef, useEffect, useCallback} from "react";
import messenger from "./messenger";
import {apiErrorEvent} from "./api-error-event";

const extractAccountRegExp = cdxEnv.EXTRACT_SUBDOMAIN_REGEXP
  ? new RegExp(cdxEnv.EXTRACT_SUBDOMAIN_REGEXP)
  : null;

export const getCurrentAccount = () => {
  if (process.env.REACT_APP_MODE === "steamy") return null;
  if (process.env.REACT_APP_MODE === "open") return null;
  const match = extractAccountRegExp && window.location.hostname.match(extractAccountRegExp);
  return match ? match[1] : process.env.REACT_APP_DEFAULT_ACCOUNT;
};

export const getPublicPath = () => {
  if (process.env.REACT_APP_MODE !== "open") return null;
  const openRoutes = require("../features/public-project/public-project-routes").default;
  const match = matchPath(window.location.pathname, {path: openRoutes.publicProject.path});
  return match && match.params.publicPath;
};

export const API_ERRORS = {
  API_VERSION_MISMATCH: "api-version-mismatch",
  API_ERROR: "apiError",
  FETCH_ERROR: "fetchError",
  NEW_SUBDOMAIN: "newSubdomain",
};

let inQueue = 0;
const retryAfter = (fn, ms) =>
  new Promise((res, rej) => {
    if (inQueue > 20) {
      rej({
        type: API_ERRORS.API_ERROR,
        status: 429,
        statusText: "Too many requests",
        message: "You are being rate limited. Please slow down a bit.",
      });
    } else {
      setTimeout(
        () => {
          fn().then(
            (arg) => {
              inQueue += -1;
              res(arg);
            },
            (err) => {
              inQueue += -1;
              rej(err);
            }
          );
        },
        ms + (100 + Math.random() * 250) * (inQueue + 1)
      );
      inQueue += 1;
    }
  });

export const cdxRequest = ({path, method = "POST", data, query}) => {
  const url = `${cdxEnv.API_HOST}${path}${
    query ? qs.stringify(query, {addQueryPrefix: true}) : ""
  }`;
  return fetch(url, {
    mode: "cors",
    credentials: "include",
    method: method,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      "X-Account": getCurrentAccount(),
      ...(data && {"Content-Type": "application/json"}),
      ...(process.env.REACT_APP_MODE === "open" ? {"X-Public-Path": getPublicPath()} : null),
    },
  }).then(
    (res) => {
      if (res.status === 429) {
        const retrySecs = res.headers.get("x-ratelimit-reset");
        const secs = retrySecs ? parseInt(retrySecs, 10) : 3;
        return retryAfter(() => cdxRequest({path, method, data, query}), secs * 1000);
      } else {
        const type = res.headers.get("Content-Type");
        const fn = type.startsWith("text/plain") ? "text" : "json";
        if (res.status >= 400) {
          return res[fn]().then((body) =>
            Promise.reject({
              type: API_ERRORS.API_ERROR,
              status: res.status,
              statusText: res.statusText,
              message: res.status === 400 ? body : errorToString(body),
            })
          );
        } else {
          const newSubdomain = res.headers.get("X-New-Subdomain");
          if (newSubdomain) {
            apiErrorEvent.emit({type: API_ERRORS.NEW_SUBDOMAIN, value: newSubdomain});
          }
          return res[fn]();
        }
      }
    },
    (error) => Promise.reject({type: API_ERRORS.FETCH_ERROR, error})
  );
};

export const useServerData = ({method = "GET", path, dataKey, data, onError, onSuccess}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState(null);
  const currentRef = useRef(null);
  const refs = useRef({onError, onSuccess, data});
  useEffect(() => {
    refs.current = {onError, onSuccess, data};
  });

  useEffect(() => {
    setResult(null);
    if (path === null) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
      if (refs.current.onError) {
        refs.current.onError(null);
      }
      let marker = {};
      currentRef.current = marker;
      cdxRequest({path, method, data: refs.current.data}).then(
        (body) => {
          if (currentRef.current !== marker) return;
          setResult(body);
          setIsLoaded(true);
          if (refs.current.onSuccess) refs.current.onSuccess(body);
        },
        (err) => {
          if (currentRef.current !== marker) return;
          if (refs.current.onError) {
            refs.current.onError(errorToString(err));
          } else {
            messenger.send(`Something went wrong: ${errorToString(err)}`, {type: "error"});
          }
          setResult(null);
          setIsLoaded(true);
        }
      );
    }
  }, [method, path, dataKey]);
  return {isLoaded, data: result};
};

const fetchMachine = {
  ready: {
    initial: true,
    initialData: {},
    on: {
      REQUEST: (fn) => ["loading", {promise: fn()}],
    },
  },
  loading: {
    on: {
      SUCCESS: (result) => ["ready", {lastResult: result}],
      ERROR: (error) => ["ready", {lastError: error}],
    },
  },
};

export const useServerRequest = ({path}) => {
  const [current, send] = useMachine(fetchMachine);
  const currentRef = useRef(current);
  useEffect(() => {
    currentRef.current = current;
  }, [current]);
  const callFn = useCallback(
    (data) => {
      if (currentRef.current.value === "loading") return currentRef.current.data.promise;
      return new Promise((res, rej) => {
        send("REQUEST", () =>
          cdxRequest({path, method: "POST", data}).then(
            (body) => {
              send("SUCCESS", body);
              res(body);
            },
            (error) => {
              const asString = errorToString(error);
              send("ERROR", asString);
              rej(asString);
            }
          )
        );
      });
    },
    [path, send]
  );
  return [
    callFn,
    {
      isLoading: current.value === "loading",
      error: (current.value === "ready" && current.data.lastError) || null,
      data: (current.value === "ready" && current.data.lastResult) || null,
    },
  ];
};

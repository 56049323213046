export const isEventWithinInteractiveNode = (e) => {
  const {nodeName} = e.target;
  if (nodeName === "TEXTAREA" || nodeName === "INPUT" || nodeName === "SELECT") return true;
  let current = e.target;
  while (current) {
    if (
      current.nodeName === "BUTTON" ||
      current.nodeName === "A" ||
      current.getAttribute("contenteditable") ||
      current.dataset?.cdxClickable === "true" // for cases like `<div onClick={onClick} data-cdx-clickable>`
    )
      return true;
    current = current.parentElement;
  }
  return false;
};

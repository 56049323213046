import {addDescription, useGlobalKeyPress} from "@cdx/common";
import {useEffect} from "react";
import {invertSelection, selectAll, useIsSelectingCards} from "../card-selection/useSelectedCards";
import {Card, CardId} from "../../cdx-models/Card";
import {ArenaCtx} from "./card-panel-utils";
import {useHoverCardStore} from "../../components/Card/useHoverShortcuts";
import type {Location} from "history";

export const useSelectionShortcuts = ({
  category,
  defaultContainerKey,
}: {
  category: string;
  defaultContainerKey: string;
}) => {
  const isSelecting = useIsSelectingCards();
  useGlobalKeyPress({
    key: "a",
    withMod: true,
    fn: () => selectAll(defaultContainerKey),
    description: "Select all cards",
    category,
  });
  useGlobalKeyPress({
    key: "i",
    disabled: !isSelecting,
    fn: () => invertSelection(),
    description: "Inverts currently selected cards",
    category,
  });
};

const useGoToCard = (
  goToCard: (opts: {cardId: CardId; cardContainerKey: string}) => void,
  hasActiveCard: boolean
) => {
  useEffect(() => {
    if (!hasActiveCard) {
      const {info} = useHoverCardStore.getState();
      if (info && "mode" in info && info.mode === "keyboard") {
        useHoverCardStore.setState({info: null, prop: null});
      }
      currCursor = null;
    }
  }, [hasActiveCard]);
  return (opts: {cardId: CardId; cardContainerKey: string}) => {
    const {cardId, cardContainerKey} = opts;
    announceNextCursor = {cardId, cardContainerKey};
    lastAcceptedAnnouncedCursor = null;
    goToCard(opts);
    // if we navigated to a card using the keyboard,
    // the next hover shortcut should be applied to that one,
    // not the one we're currently hovering
    useHoverCardStore.setState((prev) => ({
      prop: null,
      info: {
        type: (prev.info?.type as "MINI_CARD") || "MINI_CARD",
        cardId: opts.cardId,
        cardContainerKey: opts.cardContainerKey,
        cardNodeRef: (prev.info as any)?.cardNodeRef || {current: null},
        mode: "keyboard",
      },
    }));
  };
};

export type CardCursor = {
  groupIdx: number;
  cardIdx: number;
  cardContainerKey: string;
  cardId: CardId | null;
};
let announceNextCursor = null as null | {cardId: CardId; cardContainerKey: string};
let lastAcceptedAnnouncedCursor = null as null | {cardId: CardId; cardContainerKey: string};
let currCursor: CardCursor | null = null;

const cardAtSamePosition = (
  prevCursor: CardCursor,
  cardId: CardId,
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[]
): boolean => {
  const {groupIdx, cardIdx} = prevCursor;
  const key = groups[groupIdx];
  if (!key || key !== prevCursor.cardContainerKey) {
    return false;
  }
  const cardIds = getCardIdsForGroup(key);
  const cardIdAtPrevCursor = cardIds[cardIdx];
  return cardId === cardIdAtPrevCursor;
};

export const onRemoveCardContainer = (cardContainerKey: string) => {
  if (currCursor && currCursor.cardContainerKey === cardContainerKey) {
    currCursor = null;
  }
};

/*
Concerns:

announcing next cursor happens via two ways:
- explicitly set when navigating using keys
- when clicking on card it's communicated via card container locationstate

Flow:
- if announced cursor exists:
  - ensure the container is present, else skip (issue if no useCardNavigation feels responsible)
  - if container is present:
    - announced cursor is set to null
    - if card id is also present, set cursor to that card

- if no card is active:
  set cursor to null

- if curr cursor is not in any of my containers, skip

- if curr cursor is in gap mode, keep it
- if curr cursor corresponds to active card in present card container
  - if position has changed, remove cardId to indicate a `gap`
  - else just return the cursor

*/

type GetCursorArgs = {
  activeCard: Card | null;
  getCardIdsForGroup: (key: string) => CardId[];
  groups: string[];
  locationCardContainerKey?: string;
};

const getInnerCursor = (opts: GetCursorArgs): CardCursor | "other" | null => {
  const {activeCard, getCardIdsForGroup, groups, locationCardContainerKey} = opts;
  const activeCardId = activeCard?.$meta.get("cardId", null) ?? null;
  if (!announceNextCursor && locationCardContainerKey && activeCardId) {
    const last = lastAcceptedAnnouncedCursor;
    const acceptedBefore =
      last && last.cardContainerKey === locationCardContainerKey && last.cardId === activeCardId;
    if (!acceptedBefore) {
      announceNextCursor = {
        cardId: activeCardId,
        cardContainerKey: locationCardContainerKey,
      };
    }
  }

  if (announceNextCursor) {
    const gIdx = groups.indexOf(announceNextCursor.cardContainerKey);
    if (gIdx > -1) {
      const announceId = announceNextCursor.cardId;
      lastAcceptedAnnouncedCursor = announceNextCursor;
      announceNextCursor = null;
      const cardIds = getCardIdsForGroup(groups[gIdx]);
      const idx = cardIds.indexOf(announceId);
      if (idx > -1) {
        return {
          groupIdx: gIdx,
          cardIdx: idx,
          cardContainerKey: groups[gIdx],
          cardId: announceId,
        };
      }
      return null;
    } else {
      return "other";
    }
  }
  if (!activeCardId) return null;
  if (currCursor) {
    if (currCursor.cardId === null) return currCursor;
    const groupIdx = groups.indexOf(currCursor.cardContainerKey);
    if (groupIdx === -1) return "other";
    if (currCursor.cardId === activeCardId) {
      const samePos = cardAtSamePosition(currCursor, activeCardId, groups, getCardIdsForGroup);
      if (samePos) {
        return currCursor;
      } else {
        currCursor.cardId = null;
        return currCursor;
      }
    } else {
      // curr cursor points to present group, but different card id
      // should rarely happen as it usually should have been announced beforehand,
      // might happen when clicking on notifications or card references though
      const cardIds = getCardIdsForGroup(currCursor.cardContainerKey);
      const idx = cardIds.indexOf(activeCardId);
      if (idx > -1) {
        return {
          groupIdx,
          cardIdx: idx,
          cardContainerKey: currCursor.cardContainerKey,
          cardId: activeCardId,
        };
      }
      return null;
    }
  } else {
    // no curr cursor, look for the first group containing the active card
    let groupIdx = 0;
    for (const cardContainerKey of groups) {
      const cardIds = getCardIdsForGroup(cardContainerKey);
      const idx = cardIds.indexOf(activeCardId);
      if (idx > -1) return {groupIdx, cardIdx: idx, cardContainerKey, cardId: activeCardId};
      groupIdx += 1;
    }
    return "other";
  }
};

const getCursor = (opts: GetCursorArgs): CardCursor | null => {
  const cursorResponse = getInnerCursor(opts);
  if (cursorResponse !== "other") {
    currCursor = cursorResponse;
  }
  return cursorResponse === "other" ? null : cursorResponse;
};

type CardNavigationOpts = {
  activeCard: Card | null;
  getCardIdsForGroup: (key: string) => CardId[];
  groups: string[];
  goToCard: (opts: {cardId: string; cardContainerKey: string}) => void;
  category: string;
  cardsPerRow: number;
  location: Location<any>;
};

const getFirstCard = (
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[]
): {cardId: CardId; cardContainerKey: string} | null => {
  for (const group of groups) {
    const cardIds = getCardIdsForGroup(group);
    if (cardIds.length === 0) continue;
    return {
      cardContainerKey: group,
      cardId: cardIds[0],
    };
  }
  return null;
};

const getPrev = (
  cursor: CardCursor | null,
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[]
): {cardId: CardId; cardContainerKey: string} | null => {
  if (!cursor) {
    if (currCursor) return null;
    return getFirstCard(groups, getCardIdsForGroup);
  }
  const {groupIdx, cardIdx, cardContainerKey} = cursor;
  if (groupIdx === 0 && cardIdx === 0) return null;
  if (cardIdx > 0) {
    const cardIds = getCardIdsForGroup(cardContainerKey);
    return {cardId: cardIds[cardIdx - 1], cardContainerKey};
  } else {
    for (let i = groupIdx - 1; i >= 0; i -= 1) {
      const key = groups[i];
      const cardIds = getCardIdsForGroup(key);
      if (!cardIds.length) continue;
      return {cardId: cardIds[cardIds.length - 1], cardContainerKey: key};
    }
  }
  return null;
};

const getNext = (
  cursor: CardCursor | null,
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[]
): {cardId: CardId; cardContainerKey: string} | null => {
  if (!cursor) {
    if (currCursor) return null;
    return getFirstCard(groups, getCardIdsForGroup);
  }
  const {groupIdx, cardIdx: rawCardIdx, cardContainerKey} = cursor;
  const cardIdx = cursor.cardId === null ? rawCardIdx - 1 : rawCardIdx;
  const currCardIds = getCardIdsForGroup(cardContainerKey);
  if (cardIdx < currCardIds.length - 1) {
    return {cardId: currCardIds[cardIdx + 1], cardContainerKey};
  }
  for (let i = groupIdx + 1; i < groups.length; i += 1) {
    const key = groups[i];
    const cardIds = getCardIdsForGroup(key);
    if (!cardIds.length) continue;
    return {cardId: cardIds[0], cardContainerKey: key};
  }
  return null;
};

const getAbove = (
  cursor: CardCursor | null,
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[],
  cardsPerRow: number
): {cardId: CardId; cardContainerKey: string} | null => {
  if (!cursor) {
    if (currCursor) return null;
    return getFirstCard(groups, getCardIdsForGroup);
  }
  const {groupIdx, cardIdx, cardContainerKey} = cursor;
  if (groupIdx === 0 && cardIdx < cardsPerRow) return null;
  if (cardIdx >= cardsPerRow) {
    const currCardIds = getCardIdsForGroup(cardContainerKey);
    return {cardId: currCardIds[cardIdx - cardsPerRow], cardContainerKey};
  } else {
    const colIdx = cardIdx % cardsPerRow;
    for (let i = groupIdx - 1; i >= 0; i -= 1) {
      const key = groups[i];
      const cardIds = getCardIdsForGroup(key);
      if (!cardIds.length) continue;
      const lastColCount = cardIds.length % cardsPerRow || cardsPerRow;
      const nextIdx = cardIds.length - (lastColCount - colIdx);
      return {cardId: cardIds[Math.min(nextIdx, cardIds.length - 1)], cardContainerKey: key};
    }
  }
  return null;
};

const getBelow = (
  cursor: CardCursor | null,
  groups: string[],
  getCardIdsForGroup: (k: string) => CardId[],
  cardsPerRow: number
): {cardId: CardId; cardContainerKey: string} | null => {
  if (!cursor) {
    if (currCursor) return null;
    return getFirstCard(groups, getCardIdsForGroup);
  }
  const {groupIdx, cardIdx, cardContainerKey} = cursor;
  const currCardIds = getCardIdsForGroup(cardContainerKey);
  if (cardIdx + cardsPerRow <= currCardIds.length - 1) {
    return {
      cardId: currCardIds[Math.min(currCardIds.length - 1, cardIdx + cardsPerRow)],
      cardContainerKey,
    };
  } else {
    const colIdx = cardIdx % cardsPerRow;
    for (let i = groupIdx + 1; i < groups.length; i += 1) {
      const key = groups[i];
      const cardIds = getCardIdsForGroup(key);
      if (!cardIds.length) continue;
      return {cardId: cardIds[Math.min(cardIds.length - 1, colIdx)], cardContainerKey: key};
    }
  }
  return null;
};

export const useCardNavigation = ({
  activeCard,
  getCardIdsForGroup,
  goToCard: passedGoToCard,
  category,
  groups,
  cardsPerRow,
  location,
}: CardNavigationOpts) => {
  const cursor = getCursor({
    activeCard,
    getCardIdsForGroup,
    groups,
    locationCardContainerKey: location.state?.cardContainerKey,
  });

  const goToCard = useGoToCard(passedGoToCard, !!activeCard);
  // const arenaCtx = useArenaContext();

  const onPrevCard = () => {
    const prevCard = getPrev(cursor, groups, getCardIdsForGroup);
    if (prevCard) {
      goToCard(prevCard);
      return true;
    } else {
      return false;
    }
  };

  const onNextCard = () => {
    const nextCard = getNext(cursor, groups, getCardIdsForGroup);
    if (nextCard) {
      goToCard(nextCard);
      return true;
    } else {
      return false;
    }
  };

  const onAboveCard = () => {
    const aboveCard = getAbove(cursor, groups, getCardIdsForGroup, cardsPerRow);
    if (aboveCard) {
      goToCard(aboveCard);
      return true;
    } else {
      return false;
    }
  };
  const onBelowCard = () => {
    const belowCard = getBelow(cursor, groups, getCardIdsForGroup, cardsPerRow);
    if (belowCard) {
      goToCard(belowCard);
      return true;
    } else {
      return false;
    }
  };

  useGlobalKeyPress({
    key: "j",
    fn: onPrevCard,
    description: "Open previous Card",
    category,
    prio: "low",
  });
  useGlobalKeyPress({
    key: "k",
    fn: onNextCard,
    description: "Open next Card",
    category,
    prio: "low",
  });

  useEffect(
    () => addDescription({key: "Arrow Keys", description: "Navigate through cards", category}),
    [category]
  );

  useGlobalKeyPress({
    code: "ArrowLeft",
    fn: onPrevCard,
    prio: "low",
  });
  useGlobalKeyPress({
    code: "ArrowRight",
    fn: onNextCard,
    prio: "low",
  });
  useGlobalKeyPress({
    code: "ArrowUp",
    fn: onAboveCard,
    prio: "low",
  });
  useGlobalKeyPress({
    code: "ArrowDown",
    fn: onBelowCard,
    prio: "low",
  });

  return cursor;
};

type TableViewNavigationOpts = {
  activeCard: Card | null;
  getCardIdsForGroup: (key: string) => CardId[];
  groups: string[];
  location: Location<any>;
  arenaCtx: ArenaCtx;
};

export const useTableViewNavigation = ({
  activeCard,
  getCardIdsForGroup,
  location,
  groups,
  arenaCtx,
}: TableViewNavigationOpts) => {
  const cursor = getCursor({
    activeCard,
    getCardIdsForGroup,
    groups,
    locationCardContainerKey: location.state?.cardContainerKey,
  });
  // console.log(cursor ? (cursor.cardId === null ? "gap" : "card") : "null");
  const goToCard = useGoToCard(arenaCtx.routes.goToCard, !!activeCard);

  const onPrevCard = () => {
    const prevCard = getPrev(cursor, groups, getCardIdsForGroup);
    if (prevCard) {
      goToCard(prevCard);
      return true;
    } else {
      return false;
    }
  };

  const onNextCard = () => {
    const nextCard = getNext(cursor, groups, getCardIdsForGroup);
    if (nextCard) {
      goToCard(nextCard);
      return true;
    } else {
      return false;
    }
  };

  useGlobalKeyPress({
    key: "j",
    fn: onPrevCard,
    description: "Open previous Card",
    category: arenaCtx.shortcutCategory,
    prio: "low",
  });
  useGlobalKeyPress({
    key: "k",
    fn: onNextCard,
    description: "Open next Card",
    category: arenaCtx.shortcutCategory,
    prio: "low",
  });

  useEffect(
    () =>
      addDescription({
        key: "Up and Down Arrow Keys",
        description: "Navigate through cards",
        category: arenaCtx.shortcutCategory,
      }),
    [arenaCtx.shortcutCategory]
  );
  useGlobalKeyPress({
    code: "ArrowUp",
    fn: onPrevCard,
    prio: "low",
  });
  useGlobalKeyPress({
    code: "ArrowDown",
    fn: onNextCard,
    prio: "low",
  });

  return cursor;
};

import {forwardRef} from "react";
import FancySelect from "./FancySelect";
import {WithLabel, XRow, XText, XPush, cx} from "@cdx/common";
import MiniDeck from "./MiniDeck";
import {api} from "../lib/api";
import {Box, Col, DSRawButton, Row, useReactiveIcon} from "@cdx/ds";
import {getMySelectedProjectIds} from "../lib/hooks/useSelectedProjects";
import UnMarkedown from "./Markdown/UnMarkedown";
import {DeckPickerImage} from "../features/card-container/DSDeckPicker";
import dsStyles from "@cdx/ds/css/index.css";

export const RawDeckPicker = forwardRef((props, ref) => {
  const {root, atLeastProducer, name, ...rest} = props;
  const hasMultiProjects = root.account.$meta.count("projects") >= 2;
  const meId = root.loggedInUser.$meta.get("id", null);
  const getOptions = () =>
    atLeastProducer && !meId
      ? []
      : root.account.$meta.find("decks", {
          project: {
            visibility: "default",
            ...(atLeastProducer && {
              access: {
                userId: meId,
                $or: [{role: ["owner", "admin"]}, {role: "guest", projectRole: "producer"}],
              },
            }),
          },
        });
  return (
    <FancySelect
      ref={ref}
      id={name}
      placeholder="Select Deck"
      getOptions={getOptions}
      extractKey={(d) => d.id}
      getSearchText={(d) => d.title}
      instanceToValue={(d) => d.id}
      valueToInstance={(id) => api.getModel({modelName: "deck", id}) || {id, isInaccessible: true}}
      {...rest}
    >
      {(deck) =>
        !deck || deck.isInaccessible || deck.$meta.isDeleted() ? (
          <XText size={2} color="gray500">
            inaccessible deck
          </XText>
        ) : hasMultiProjects ? (
          <XRow align="baseline" sp={1}>
            <MiniDeck deck={deck} size={16} onLight />
            <XPush />
            <XText size={1} color="gray500" align="right" noOverflow>
              {deck.project.name}
            </XText>
          </XRow>
        ) : (
          <MiniDeck deck={deck} size={16} onLight />
        )
      }
    </FancySelect>
  );
});

const DeckPicker = forwardRef((props, ref) => {
  const {showErrors, name, label, hint, hasPendingValidation, className, ...rest} = props;

  return (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
      className={className}
    >
      <RawDeckPicker name={name} {...rest} ref={ref} />
    </WithLabel>
  );
});

export default DeckPicker;

const DeckButton = ({deck, onPickDeckId, active}) => {
  const {handleClick, reactiveIcon, state} = useReactiveIcon({
    iconEl: <DeckPickerImage deck={deck} />,
    onClick: onPickDeckId,
  });

  return (
    <DSRawButton
      variant="secondary"
      size="sm"
      state={state}
      contentPadding={null}
      className={cx(dsStyles.zIndex[2])}
      onClick={() => handleClick(deck.id)}
      active={active}
    >
      <Row sp="4px" align="center">
        {reactiveIcon}
        <Box pl="2px">
          <UnMarkedown maxChars={16} projectId={deck.project.id} title={deck.title}>
            {deck.title}
          </UnMarkedown>
        </Box>
      </Row>
    </DSRawButton>
  );
};

export const DeckPickerWithDefaults = forwardRef(
  ({offeredDecks = [], fillWithHistoryEntries = 6, ...rest}, ref) => {
    const {root, value, onChange} = rest;
    const meId = root.loggedInUser?.$meta.get("id", null);
    const projectIds = getMySelectedProjectIds(root);
    const history =
      meId && fillWithHistoryEntries.length > offeredDecks
        ? root.account.$meta.find("deckAssignments", {
            deck: {
              isDeleted: false,
              ...(projectIds.length > 0 ? {projectId: projectIds} : {}),
              id: {
                op: "notIn",
                value: offeredDecks.map((d) => d.$meta.get("id", null)).filter(Boolean),
              },
            },
            userId: meId,
            $order: "-lastAssignedAt",
            $limit: fillWithHistoryEntries,
          })
        : [];
    const shownDecks = [...offeredDecks, ...history.map((h) => h.deck)].slice(
      0,
      Math.max(fillWithHistoryEntries, offeredDecks.length)
    );
    return (
      <Col sp="8px">
        <DeckPicker {...rest} ref={ref} />
        {shownDecks.length > 0 && (
          <Row sp="6px" wrap>
            {shownDecks.map((d, idx) => (
              <DeckButton
                key={d.$meta.get("id", idx)}
                deck={d}
                onPickDeckId={onChange}
                active={value === d.id}
              />
            ))}
          </Row>
        )}
      </Col>
    );
  }
);

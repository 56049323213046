import {useState, useEffect} from "react";
import {MiniEvent} from "@cdx/common";
import {useModalWithData} from "../components/Modals";
import {XCol, XRow, XPush, XText} from "../components/xui";
import {Section} from "../components/ui";
import Ui from "../components/ui2";

const confirmEvent = new MiniEvent();

export const ConfirmationRegistry = () => {
  const [current, setCurrent] = useState(null);
  useEffect(() => {
    return confirmEvent.addListener(setCurrent);
  }, []);

  const handleNo = () => {
    if (current) {
      current.resolve(false);
      setCurrent(null);
    }
  };
  const handleYes = () => {
    if (current) {
      current.resolve(true);
      setCurrent(false);
    }
  };

  const renderModal = useModalWithData(current, {onClose: handleNo});
  return renderModal(({title, content, cancelLabel, confirmLabel, comp: Comp, compProps}) => (
    <XCol>
      <Section.ModalHeader>
        {typeof title === "string" ? <XText preset="h1">{title}</XText> : title}
      </Section.ModalHeader>
      <Section.ModalBody>
        {Comp ? (
          <Comp {...compProps} onCancel={handleNo} onConfirm={handleYes} />
        ) : (
          <XCol sp={4}>
            {typeof content === "string" ? <XText>{content}</XText> : content}
            <XRow sp={5} align="end">
              {cancelLabel && <Ui.PlainButton onClick={handleNo}>{cancelLabel}</Ui.PlainButton>}
              <XPush />
              <Ui.ElevatedButton size="large" onClick={handleYes} autoFocus>
                {confirmLabel}
              </Ui.ElevatedButton>
            </XRow>
          </XCol>
        )}
      </Section.ModalBody>
    </XCol>
  ));
};

/** @type {(opts: {
 * title: string | ReactNode,
 * content: string | ReactNode,
 * comp?: ReactNode,
 * compProps?: any,
 * confirmLabel?: string, cancelLabel?: string})
 *  => Promise<boolean>
 * } */
const confirm = ({title, content, comp, compProps, confirmLabel = "Okay", cancelLabel = "Nope"}) =>
  new Promise((resolve) => {
    confirmEvent.emit({title, content, comp, compProps, confirmLabel, cancelLabel, resolve});
  });

export default confirm;

const cmpMap = {gte: <>&gt;=&nbsp;</>, lte: <>&lt;=&nbsp;</>};
const prioOrder = {null: 0, c: 1, b: 2, a: 3};
const prios = [null, "c", "b", "a"];
const prioCmpToFilter = (prio, cmp) => {
  if (cmp === "eq") return prio;
  if (cmp === "lte") return prios.filter((p) => prioOrder[p] <= prioOrder[prio]);
  if (cmp === "gte") return prios.filter((p) => prioOrder[p] >= prioOrder[prio]);
};

const priorityCategory = {
  label: "Priority",
  key: "priority",
  getSuggestions({input, root}) {
    const {priorityLabels} = root.account;
    if ("priority".startsWith(input)) {
      return [
        ...Object.keys(priorityLabels).map((p) => ({priority: p, cmp: "eq"})),
        {priority: null, cmp: "eq"},
      ];
    } else {
      return Object.keys(priorityLabels).reduce((results, key) => {
        if (priorityLabels[key].toLowerCase().startsWith(input)) {
          results.push(
            {priority: key, cmp: "eq"},
            {priority: key, cmp: "gte"},
            {priority: key, cmp: "lte"}
          );
        }
        return results;
      }, []);
    }
  },
  valueToKey({priority, cmp}) {
    return `${cmp}${priority}`;
  },
  valuesToDbQuery(values) {
    if (values.length === 1) {
      const {priority, cmp} = values[0];
      return {priority: prioCmpToFilter(priority, cmp)};
    } else if (values.every(({cmp}) => cmp === "eq")) {
      return {priority: values.map((v) => v.priority)};
    } else {
      return {$or: values.map(({priority, cmp}) => ({priority: prioCmpToFilter(priority, cmp)}))};
    }
  },
  renderSuggestion({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    return priority === null ? (
      "none"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {priorityLabels[priority] || priority}
      </>
    );
  },
  renderPill({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    return priority === null ? (
      "No priority"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {priorityLabels[priority] || priority}
      </>
    );
  },
  savedSearchLabel({priority, cmp}, {root}) {
    const {priorityLabels} = root.account;
    const cmpToLabel = {gte: "+", lte: "-", eq: ""};
    return {
      prio: 2,
      label:
        priority === null ? "no prio" : `${priorityLabels[priority] || priority}${cmpToLabel[cmp]}`,
    };
  },
};

export default priorityCategory;

import {useRef, useState, useEffect, ReactNode, useSyncExternalStore} from "react";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {AnyModel} from "../../cdx-models/utils/MakeModel";
import {DSSpinner, Col, DSButton} from "@cdx/ds";
import {checkIfValid} from "../../lib/mate/mate-utils";
import {api} from "../../lib/api";

type Collection<TModel extends AnyModel, TRel extends keyof TModel> = {
  parent: TModel;
  relName: TRel & string;
  filter?: any;
  orderProps: string[];
};

const filterToKey = (filter: Record<string, any>): string =>
  filter
    ? Object.entries(filter)
        .map(([k, v]) => `${k}:${JSON.stringify(v)}`)
        .join("|")
    : "";

const extractOrderVals = <T extends AnyModel>(model: T, orderProps: string[]) => {
  return orderProps.map((p) => {
    const val = model.$meta.get(p.replace(/^-/, ""), null);
    return [p, val] as [string, any];
  });
};

const fixConstraint = (constr: any, dir: 1 | -1) => {
  if (constr instanceof Date) {
    return new Date(constr.getTime() + dir).toISOString(); // because psql uses more precise timing and 0.834 might be represented as 0.834361
  } else {
    return constr;
  }
};

const cmp = (op: "gt" | "lt" | "lte", dir: 1 | -1) => (fieldAndVals: [string, any][]) => {
  if (fieldAndVals.length === 1) {
    const [k, v] = fieldAndVals[0];
    return {
      [k.replace(/^-/, "")]: {op, value: fixConstraint(v, dir)},
    };
  } else {
    const [k1, v1] = fieldAndVals[0];
    const [k2, v2] = fieldAndVals[1];
    // const leftPart = `${k1}:${fixConstraint(v1, dir)},${k2}$${op}:${fixConstraint(v2, dir)}`;
    const leftPart = {
      [k1.replace(/^-/, "")]: fixConstraint(v1, dir),
      [k2.replace(/^-/, "")]: {op, value: fixConstraint(v2, dir)},
    };
    const rightPart = {[k1.replace(/^-/, "")]: {op, value: fixConstraint(v1, dir)}};
    return {$or: [leftPart, rightPart]};
  }
};

// const greaterThan = cmp("gt", 1);
// const lessEqualThan = cmp("lte", 1);
const lessThan = cmp("lt", -1);
const greaterThanEqual = cmp("gt", -1);

type PageMangagerStore<TModel extends AnyModel, TRel extends keyof TModel> = {
  getSnapshot: () => {ref: PageManager<TModel, TRel>};
  subscribe: (callback: () => void) => () => void;
};

type PageManager<TModel extends AnyModel, TRel extends keyof TModel> = {
  state: "initializing" | "loadingNextPage" | "readyLoadedAll" | "readyWithMore";
  getItems: () => TModel[TRel];
  loadNext: () => void;
};

const createPageManager = <TModel extends AnyModel, TRel extends keyof TModel>(
  opts: WithDataProps<TModel, TRel> & {loadPage: (filter: any) => TModel[TRel]}
): PageMangagerStore<TModel, TRel> => {
  const {collection, pageSize, newestOrderVals, loadPage, preloadItem} = opts;
  let onUpdate = null as null | (() => void);
  let oldestConstraints = newestOrderVals;
  let pages: {filter: any}[] = [
    // first page shows first item and all that are newer
    {filter: greaterThanEqual(newestOrderVals)},
  ];
  const getOldestOrderValsOfPage = (page: {filter: any}) => {
    const items = loadPage(page);
    if (preloadItem) preloadItem(items);
    if (items.length === 0) return {lastOrderVals: null, itemCount: 0};
    const lastItem = items[items.length - 1];
    return {
      lastOrderVals: extractOrderVals(lastItem, collection.orderProps),
      itemCount: items.length,
    };
  };

  const pageManager: PageManager<TModel, TRel> = {
    state: "initializing",
    getItems: () => pages.flatMap(loadPage) as TModel[TRel],
    loadNext: () => {
      pageManager.state = "loadingNextPage";
      const nextPage = {filter: lessThan(oldestConstraints)};
      pages.push(nextPage);
      onUpdate?.();
      return waitForResultPromise(() => getOldestOrderValsOfPage(nextPage)).then(
        ({lastOrderVals, itemCount}) => {
          if (lastOrderVals === null || itemCount < pageSize) {
            pageManager.state = "readyLoadedAll";
          } else {
            pageManager.state = "readyWithMore";
            oldestConstraints = lastOrderVals;
          }
          onUpdate?.();
        }
      );
    },
  };
  pageManager.loadNext();

  let reffed = {ref: pageManager};

  return {
    getSnapshot: () => reffed,
    subscribe: (callback: () => void) => {
      onUpdate = () => {
        reffed = {ref: pageManager};
        callback();
      };
      return () => {
        onUpdate = null;
      };
    },
  };
};

const usePageManager = <TModel extends AnyModel, TRel extends keyof TModel>(
  props: WithDataProps<TModel, TRel>
) => {
  const propRefs = useRef({props});
  propRefs.current.props = props;
  const [pageManagerStore] = useState(() =>
    createPageManager({
      ...props,
      preloadItem: (items) => propRefs.current.props.preloadItem?.(items),
      loadPage: ({filter}: {filter: any}) => {
        const {collection, pageSize = 25} = propRefs.current.props;
        return collection.parent.$meta.find(collection.relName, {
          ...collection.filter,
          ...filter,
          $order: collection.orderProps,
          $limit: pageSize,
        });
      },
    })
  );

  const pageManager = useSyncExternalStore(
    pageManagerStore.subscribe,
    pageManagerStore.getSnapshot
  );
  return pageManager.ref;
};

type WithDataProps<TModel extends AnyModel, TRel extends keyof TModel> = {
  newestOrderVals: [string, any][];
} & PagerProps<TModel, TRel>;

const WithData = <TModel extends AnyModel, TRel extends keyof TModel>(
  props: WithDataProps<TModel, TRel>
) => {
  const {renderItems, hideMoreButton} = props;
  const pageManager = usePageManager(props);

  return (
    <>
      {pageManager.state === "initializing" ? (
        <Col pa="32px" align="center">
          <DSSpinner size={24} />
        </Col>
      ) : (
        <Col sp="32px">
          {renderItems(pageManager.getItems())}
          {!hideMoreButton && pageManager.state !== "readyLoadedAll" && (
            <Col align="center">
              <DSButton
                disabled={pageManager.state === "loadingNextPage"}
                onClick={pageManager.loadNext}
                variant="secondary"
                size="md"
              >
                Load more
              </DSButton>
            </Col>
          )}
        </Col>
      )}
    </>
  );
};

const NonEmpty = <T extends AnyModel, TRel extends keyof T>(
  props: PagerProps<T, TRel> & {newestEl: T[TRel]}
) => {
  const {collection, pageSize, newestEl} = props;
  const {parent, relName, filter, orderProps} = collection;
  const key = `${parent.id}_${relName}${filterToKey(filter)}$${orderProps.join("|")}:${pageSize}`;
  const {isLoaded, result: newestOrderVals} = checkIfValid(
    () => extractOrderVals(newestEl, orderProps),
    api
  );
  const currLoadedVal = isLoaded ? newestOrderVals : null;
  const [loadedOrderVals, setLoadedOrderVals] = useState(currLoadedVal);
  useEffect(() => {
    setLoadedOrderVals((prev) => prev || currLoadedVal);
  }, [currLoadedVal]);
  if (!loadedOrderVals) {
    return (
      <Col pa="32px" align="center">
        <DSSpinner size={24} />
      </Col>
    );
  } else {
    return <WithData key={key} newestOrderVals={loadedOrderVals} {...props} />;
  }
};

type PagerProps<TModel extends AnyModel, TRel extends keyof TModel> = {
  collection: Collection<TModel, TRel>;
  pageSize: number;
  renderItems: (items: TModel[TRel]) => ReactNode;
  renderEmpty: () => ReactNode;
  preloadItem?: (items: TModel[TRel]) => void;
  hideMoreButton?: boolean;
};
export const Pager = <T extends AnyModel, TRel extends keyof T>(props: PagerProps<T, TRel>) => {
  const {collection, renderEmpty} = props;
  const {parent, relName, filter, orderProps} = collection;
  const {isLoaded, result: newestEl} = checkIfValid(
    () =>
      parent.$meta.first(relName, {
        ...filter,
        $order: orderProps,
      }),
    api
  );

  const [loadedOnce, setLoadedOnce] = useState(isLoaded);
  useEffect(() => {
    if (isLoaded) setLoadedOnce(true);
  }, [isLoaded]);

  if (!newestEl) return renderEmpty();
  return loadedOnce ? (
    <NonEmpty newestEl={newestEl} {...props} />
  ) : (
    <Col pa="32px" align="center">
      <DSSpinner size={24} />
    </Col>
  );
};

import {useState} from "react";
import {XCol, XText, XPush} from "../../components/xui";
import XPlainButton from "../../components/xui/XPlainButton";
import XTextButton from "../../components/xui/XTextButton";
import {api} from "../../lib/api";
import {XForm, rules, useNeoBag, SubmitButton, RadioGroupWithLabel} from "@cdx/common";
import {FileInputWithLabel} from "../../components/FileInput";
import {getLimits} from "../billing/billing-utils";
import {Col, DSIconButton, DSIconCheck, DSIconLockClosed, Row} from "@cdx/ds";
import {TextBadge} from "../../components/xui/Badge";

const Container = ({children, title, right}) => (
  <Col
    rounded={4}
    relative
    sp="12px"
    px="24px"
    py="16px"
    elevation={200}
    colorTheme="white"
    bg="foreground"
  >
    <Row sp="16px" align="center">
      <XText preset="bold" size={4}>
        {title}
      </XText>
      {right && (
        <>
          <XPush />
          {right}
        </>
      )}
    </Row>
    {children}
  </Col>
);

const formRules = {
  projectName: [rules.isRequired],
};

const ButtonWithinInput = () => {
  const bag = useNeoBag();
  const hasFormErrors = bag.isValid !== true;
  return (
    <SubmitButton
      as={DSIconButton}
      formBag={bag}
      variant="primary"
      size="sm"
      disabled={hasFormErrors}
      icon={<DSIconCheck />}
    />
  );
};

export const ProjectVisibilityField = ({root, label = "Visibility"}) => {
  const limits = getLimits(root.account);
  const withoutLimitedAccess = limits.withLimitedProjectAccess === "false";
  return (
    <XForm.Field
      as={RadioGroupWithLabel}
      name="defaultUserAccess"
      label={label}
      disabled={withoutLimitedAccess}
      withDescription
      options={[
        {
          value: "everyone",
          label: "Full team",
        },
        {
          value: "limited",
          label: (
            <>
              Limited <DSIconLockClosed inline size={16} />{" "}
              <TextBadge size="sm" color="active" inline>
                Pro
              </TextBadge>
            </>
          ),
          desc: "Only admins have access by default. Additional members need to be explicitly invited.",
        },
      ]}
    />
  );
};

const CreateProject = ({onClose, root}) => {
  const [step, setStep] = useState(1);
  const [firstStepData, setFirstStepData] = useState({
    projectName: "",
    defaultUserAccess: "everyone",
  });
  const [fileId, setFileId] = useState(null);

  const handleFirstStepSubmit = ({projectName, defaultUserAccess}) => {
    setFirstStepData({name: projectName, defaultUserAccess});
    setStep(2);
  };

  const handleSubmit = () =>
    api.mutate.projects
      .create({
        ...firstStepData,
        fileId,
        userId: root.loggedInUser.id,
      })
      .then(onClose);

  const getRight = () => {
    switch (step) {
      case 1: {
        return null;
      }
      case 2: {
        return fileId ? (
          <XPlainButton color="red" onClick={handleSubmit}>
            Create
          </XPlainButton>
        ) : (
          <XTextButton color="red" onClick={handleSubmit}>
            Skip image
          </XTextButton>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Container title="New Project" right={getRight()}>
      {step === 1 && (
        <XCol
          as={XForm}
          values={firstStepData}
          onChange={setFirstStepData}
          rules={formRules}
          onSubmit={handleFirstStepSubmit}
          sp={4}
        >
          <XForm.Field
            name="projectName"
            label="Project name"
            autoFocus
            postfix={<ButtonWithinInput />}
          />
          <ProjectVisibilityField root={root} />
        </XCol>
      )}
      {step === 2 && (
        <FileInputWithLabel
          value={fileId}
          onChange={setFileId}
          name="coverFile"
          label="Cover File"
          textSize={2}
        />
      )}
    </Container>
  );
};

export default CreateProject;

import {Suspense, lazy} from "react";
import {BrowserRouter, Route, Redirect, Switch} from "react-router-dom";

import Root from "./components/Root";
import {Spinner} from "@cdx/common";
import {useRoot, MateContextProvider} from "./lib/mate/mate-utils";
import routes from "./routes";
import {NonCardArenaLayout} from "./layouts/arena-layout";
import useFullscreenWizard from "./features/wizards/useFullscreenWizard";
import {useTracker} from "./features/tracking/useTracker";
import {api} from "./lib/api";
import {getUserRole} from "./lib/permissions";

const Loader = (props) => (
  <NonCardArenaLayout {...props} bg="gray550">
    <Spinner />
  </NonCardArenaLayout>
);

const CdxRoute = ({
  isPublic,
  component: RawComp,
  path,
  exact,
  noDefaultLayout,
  root,
  roleInfo,
  props: routeProps,
}) => (
  <Route
    path={path}
    exact={exact}
    render={(props) => {
      const compProps = {...props, params: props.match.params, root, ...routeProps};
      const comp = noDefaultLayout ? (
        <Suspense fallback={<Loader {...compProps} />}>
          <RawComp {...compProps} />
        </Suspense>
      ) : (
        <Suspense fallback={<Loader {...compProps} />}>
          <NonCardArenaLayout {...compProps}>
            <RawComp {...compProps} />
          </NonCardArenaLayout>
        </Suspense>
      );
      if (isPublic) {
        return comp;
      } else if (root.account && root.account.isDisabled) {
        return <Redirect to={routes.notAuthorised.getUrl("disabled account")} />;
      } else if (!root.loggedInUser) {
        const redirectTo = props.location.pathname.startsWith(routes.notAuthorised.getUrl())
          ? null
          : props.location;
        return (
          <Redirect
            to={{pathname: routes.login.getUrl(), state: redirectTo ? {from: redirectTo} : null}}
          />
        );
      } else if (!roleInfo.isLoaded) {
        return noDefaultLayout ? <Spinner /> : <Loader {...compProps} />;
      } else if (!roleInfo.role) {
        return <Redirect to={routes.notAuthorised.getUrl()} />;
      } else if (roleInfo.role === "disabled") {
        return <Redirect to={routes.notAuthorised.getUrl("disabled user")} />;
      } else {
        return comp;
      }
    }}
  />
);

const NotFound = lazy(() => import("./pages/404"));

const modals = {
  projectSettings: {
    comp: lazy(() => import("./pages/project-settings")),
    defaultProps: {fixedHeight: true},
  },
  accountSettings: {
    comp: lazy(() => import("./pages/account-settings")),
    defaultProps: {fixedHeight: true},
  },
  userProfile: {
    comp: lazy(() => import("./features/user-profile")),
    defaultProps: {fixedHeight: true},
  },
  manageUsers: {
    comp: lazy(() => import("./pages/users")),
    defaultProps: {width: 1200, fixedHeight: true},
  },
  deckCreation: {
    comp: lazy(() => import("./pages/decks/CreateDeck")),
    defaultProps: {width: 1033},
  },
  deckEdit: {
    comp: lazy(() => import("./pages/decks/deck-edit")),
    defaultProps: {width: 1033},
  },
  attachmentViewer: {
    comp: lazy(() => import("./features/image-viewer/AttachmentViewer")),
    defaultProps: {width: "auto", hideClose: true},
  },
  imageViewer: {
    comp: lazy(() => import("./features/image-viewer/ImageViewer")),
    defaultProps: {width: "auto", hideClose: true},
  },
  commentHistory: {
    comp: lazy(() => import("./pages/comment-history")),
    defaultProps: {width: 500},
  },
  releases: {
    comp: lazy(() => import("./features/releases/ReleaseModal")),
    defaultProps: {width: 500, bg: "gray800", fixedHeight: true},
  },
  promoteProTrial: {
    comp: lazy(() => import("./features/billing/PromoteProTrialModal")),
    defaultProps: {width: 500, bg: "gray800"},
  },
  journey: {
    comp: lazy(() => import("./features/workflows/WorkflowSetup")),
    defaultProps: {width: 1200, bg: "gray800", fixedHeight: true},
  },
};

const Routes = () => {
  const root = useRoot();
  useTracker(root);
  const {isLoading: isWizardLoading, routes: wizardRoutes} = useFullscreenWizard(root);
  const roleInfo = getUserRole(root);

  return (
    <Switch>
      {wizardRoutes}
      {Object.values(routes)
        .filter((routeInfo) => !routeInfo.doNotRender)
        .map((routeInfo) => (
          <CdxRoute key={routeInfo.path} roleInfo={roleInfo} {...routeInfo} root={root} />
        ))}
      <Route
        render={(props) => (
          <Suspense fallback={<Loader {...props} root={root} />}>
            {isWizardLoading ? <Loader {...props} root={root} /> : <NotFound {...props} />}
          </Suspense>
        )}
      />
    </Switch>
  );
};

const App = () => (
  <BrowserRouter>
    <MateContextProvider api={api}>
      <Root
        modals={modals}
        notFound={
          <Suspense fallback={<Spinner />}>
            <NotFound />
          </Suspense>
        }
      >
        <Routes />
      </Root>
    </MateContextProvider>
  </BrowserRouter>
);

export default App;

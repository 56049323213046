const getTextLength = (node) => {
  if (node.type === "break") {
    return 1000;
  } else if (node.type === "text" || node.type === "inlineCode") {
    return node.value.length;
  } else if (node.children && node.children.length) {
    return node.children.reduce((s, child) => s + getTextLength(child), 0);
  } else {
    return 0;
  }
};

const autoHeaderPlugin = () => {
  return (tree) => {
    const firstChild = tree.children && tree.children[0];
    if (!firstChild) return;
    if (firstChild.type !== "paragraph") return;
    const textLength = getTextLength(firstChild);
    if (textLength > 60) return;
    firstChild.type = "heading";
    firstChild.depth = 1;
  };
};

export default autoHeaderPlugin;

import {useState} from "react";
import XRow from "./XRow";
import XText from "./XText";
import {rawButtonStyle} from "../buttons/button.css";
import {tabStyles as styles} from "./tabs.css";
import LinkOrButton from "../buttons/LinkOrButton";
import cx from "../cx";
import {makeScrollable} from "@cdx/ds/utils/makeScrollable";

export const Tabs = ({children, onPurple, title, borderBg, fillParent, sp}) => (
  <XRow
    sp={sp || (title ? 5 : 4)}
    className={cx(
      styles.box.base,
      onPurple && styles.box.purple,
      !title && makeScrollable({dir: "horizontal"})
    )}
    align={title ? "center" : "end"}
    style={borderBg && {borderBottomColor: borderBg}}
    fillParent={fillParent}
  >
    {title ? (
      <>
        <XText preset="label" color={onPurple ? "purple200" : "gray600"} size={1}>
          {title}
        </XText>
        <XRow
          sp={4}
          align="end"
          fillParent={fillParent}
          className={makeScrollable({dir: "horizontal"})}
        >
          {children}
        </XRow>
      </>
    ) : (
      children
    )}
  </XRow>
);

export const Tab = ({active, onPurple, prefix, children, ...rest}) => (
  <LinkOrButton
    className={cx(
      rawButtonStyle,
      styles.tab.base,
      // active ? styles.tab.active : styles.tab.nonActive,
      onPurple && styles.tab.purple
    )}
    {...rest}
  >
    <XRow align="center" sp={prefix && prefix.sp}>
      {prefix && prefix.content}
      <div
        className={cx(
          styles.tabContent.base,
          prefix && styles.tabContent.withPrefix,
          active && styles.tabContent.active
        )}
      >
        {children}
      </div>
    </XRow>
  </LinkOrButton>
);

/*
tabs = {
  key: "update",
  label: "Update Settings",
  content: <Bla/>
}
*/
export const useTabs = (tabs, initialKey, {onPurple, title, borderBg} = {}) => {
  const [key, setKey] = useState(initialKey);
  const currTab = tabs.find((t) => t.key === key);

  const tabsEl = (
    <Tabs onPurple={onPurple} title={title} borderBg={borderBg}>
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          active={tab.key === key}
          onClick={() => setKey(tab.key)}
          onPurple={onPurple}
          prefix={tab.prefix}
        >
          {tab.label}
        </Tab>
      ))}
    </Tabs>
  );

  return {
    tabsEl,
    activeContent: currTab && currTab.content,
    setKey,
  };
};

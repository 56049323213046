import {useRef, useEffect} from "react";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";
import useOriginalCopy from "../../lib/hooks/useOriginalCopy";
import {onRemoveCardContainer} from "../card-panel/mini-card-navigation-shortcuts";

export const useCardContainerStore = create(
  subscribeWithSelector((set) => ({
    cardContainerBox: {map: new Map()},
    registerCardContainer: ({cardContainerKey, visibleCardIds, allCardsInfo, idx}) => {
      set(({cardContainerBox: {map}}) => {
        map.set(cardContainerKey, {visibleCardIds, allCardsInfo, idx});
        return {cardContainerBox: {map}};
      });
      return () =>
        set(({cardContainerBox: {map}}) => {
          map.delete(cardContainerKey);
          return {cardContainerBox: {map}};
        });
    },
  }))
);

// allCardsInfo = null | {cardIds: [], onShow: () => void}
export const useCardContainer = ({cardContainerKey, visibleCardIds, allCardsInfo, idx}) => {
  const orgVisi = useOriginalCopy(visibleCardIds);
  const allCardsIds = useOriginalCopy(allCardsInfo && allCardsInfo.cardIds);
  const onShow = allCardsInfo && allCardsInfo.onShow;
  const onShowRef = useRef(onShow);
  useEffect(() => {
    onShowRef.current = onShow;
  }, [onShow]);

  useEffect(() => {
    return () => onRemoveCardContainer(cardContainerKey);
  }, [cardContainerKey]);

  useEffect(
    () =>
      useCardContainerStore.getState().registerCardContainer({
        cardContainerKey,
        idx,
        visibleCardIds: orgVisi,
        allCardsInfo: allCardsIds && {
          cardIds: allCardsIds,
          onShow: () => onShowRef.current && onShowRef.current(),
        },
      }),
    [cardContainerKey, orgVisi, allCardsIds, idx]
  );
};

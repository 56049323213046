import {TooltipForChild} from "@cdx/common";
import {DSIconAddCard, DSIconButton} from "@cdx/ds";
import {stringify} from "qs";
import {SIDEBAR_QUERYKEY, SIDEBAR_VALS} from "./side-bar-info";
import {ArenaCtx} from "./card-panel-utils";

type TargetProps = {};

type QuickCreateArgs = {
  arenaCtx: ArenaCtx;
  targetProps: TargetProps;
};
export const getQuickCreateUrl = ({arenaCtx, targetProps}: QuickCreateArgs) => {
  const getFromArenaCtx = () => {
    switch (arenaCtx.type) {
      case "milestone":
        return {milestoneId: arenaCtx.model.id};
      case "deck":
        return {deckId: arenaCtx.model.id};
      default:
        return {};
    }
  };
  return {
    pathname: arenaCtx.routes.getRootUrl() || "/",
    search: stringify({
      [SIDEBAR_QUERYKEY]: SIDEBAR_VALS.ADD_CARD,
    }),
    state: {
      targetProps,
      ...getFromArenaCtx(),
    },
  };
};

type NextQuickAddButtonProps = {
  arenaCtx: ArenaCtx;
  targetProps: TargetProps;
};
export const NextQuickAddButton = ({arenaCtx, targetProps}: NextQuickAddButtonProps) => (
  <TooltipForChild tooltip="Add Card">
    <DSIconButton
      icon={<DSIconAddCard />}
      to={getQuickCreateUrl({arenaCtx, targetProps})}
      size="sm"
      variant="secondary"
    />
  </TooltipForChild>
);

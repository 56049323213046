import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalEditor,
  NodeKey,
} from "lexical";
import {useRoot} from "../../../lib/mate/mate-utils";
import {Box} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";
import {pronounceSafeSeq} from "../../../lib/sequences";
import useLexicalDecoratorNode from "@cdx/ds/components/DSTextEditor/useLexicalDecoratorNode";
import {DSCardLinkStyles} from "@cdx/ds/components/DSCard/DSCardLink.css";
import {cx, shrinker} from "@cdx/common";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {getParentCard} from "../../../features/workflows/workflow-utils";
import {getStatusForCard} from "../../../lib/card-status-utils";
import {Suspense, lazy} from "react";

export class CardReferenceNode extends DecoratorNode<JSX.Element> {
  __cardSeq: string;

  static getType() {
    return "cdxCardReference";
  }

  static clone(node: CardReferenceNode) {
    return new CardReferenceNode(node.__cardSeq, node.__key);
  }

  static importJSON(serializedNode: any): CardReferenceNode {
    const node = $createCardReferenceNode(serializedNode.cardSeq);
    return node;
  }

  constructor(cardSeq: string, key?: NodeKey) {
    super(key);
    this.__cardSeq = cardSeq;
  }

  exportJSON() {
    return {
      type: "cdxCardReference",
      cardSeq: this.__cardSeq,
      version: 1,
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-cdx-cardref-seq", this.__cardSeq);
    element.innerText = this.getTextContent();
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-cdx-cardref-seq")) {
          return null;
        }
        return {
          conversion: (innerNode: HTMLElement) => {
            const cardSeq = innerNode.getAttribute("data-lexical-cdx-cardref-seq");
            if (cardSeq) {
              const node = $createCardReferenceNode(cardSeq);
              return {node};
            } else {
              return null;
            }
          },
          priority: 1,
        };
      },
    };
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    el.className = dsStyles.userSelect.none;
    return el;
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    const sib = this.getPreviousSibling();
    const hasSpace = sib && sib.getTextContent().match(/\s$/);
    return `${!sib || hasSpace ? "" : " "}$${this.__cardSeq}`;
  }

  decorate(): JSX.Element {
    return <CardReference cardSeq={this.__cardSeq} nodeKey={this.__key} />;
  }

  isInline() {
    return true;
  }
}

export function $isCardReferenceNode(node: any): node is CardReferenceNode {
  return node instanceof CardReferenceNode;
}

export function $createCardReferenceNode(cardSeq: string) {
  return $applyNodeReplacement<CardReferenceNode>(new CardReferenceNode(cardSeq));
}

// lazy because otherwise circular import issue
const UnMarkedown = lazy(() => import("../../Markdown/UnMarkedown")) as any;

const ParentTile = ({title}: {title: string}) => (
  <Suspense fallback={<span className={cx(DSCardLinkStyles.parentCard.base)}>{title}</span>}>
    <UnMarkedown
      maxChars={16}
      as="span"
      className={cx(DSCardLinkStyles.parentCard.base)}
      onDark
      skipCardReferences
    >
      {title}
    </UnMarkedown>
  </Suspense>
);

const CardReference = ({cardSeq, nodeKey}: {cardSeq: string; nodeKey: string}) => {
  const root = useRoot();
  const rawCard = root.account.$meta.find("cards", {
    accountSeq: pronounceSafeSeq.seqToInt(cardSeq),
  })[0];
  const card = (rawCard?.$meta.isDeleted() ? null : rawCard) ?? null;
  const {selectionClassName} = useLexicalDecoratorNode(nodeKey);

  const parentCard = card && getParentCard(card);
  const pTitle = parentCard && parentCard.$meta.get("title", null);
  const cTitle = card && card.$meta.get("title", null);
  const status = card && getStatusForCard(card);
  return (
    <span
      className={cx(
        DSCardLinkStyles.container.base,
        DSCardLinkStyles.container.inline,
        status && cardStatusVariants[status as "done"],
        selectionClassName
      )}
      title={card ? `${pTitle ? `[${pTitle}] ` : ""}${card.title}` : undefined}
    >
      <Box weight="normal" as="span">
        ${cardSeq}{" "}
      </Box>
      <Box as="span" fontFamily="ebony" style={{fontSize: `${16 / 14}em`}}>
        {pTitle && <ParentTile title={pTitle} />}
        {card ? (cTitle ? shrinker(cTitle, 21) : "loading") : "Not found"}
      </Box>
    </span>
  );
};

import {ReactNode, createContext, useContext, useEffect, useMemo, useState} from "react";
import {useLastIntention} from "../../lib/last-intention";
import {useLocalStorageState} from "../../lib/storage";
import orderInfo from "./order-info";

type CardOrderMode = "miniCard" | "tableView";

export type CardOrder = {
  prop: string;
  isReversed: boolean;
  secondary?: {prop: string; isReversed: boolean}[] | null;
  isCompact?: boolean;
  hideEmpty?: boolean;
  mode?: CardOrderMode;
};
export type LoadedCardOrder = CardOrder & {isLoaded: boolean};

export const isSameOrder = (o1: CardOrder, o2: CardOrder) => {
  if (o1 === o2) return true;
  if (!o1 || !o2) return false;
  if (o1.prop !== o2.prop) return false;
  if (Boolean(o1.isReversed) !== Boolean(o2.isReversed)) return false;
  if ((o1.mode || "miniCard") !== (o2.mode || "miniCard")) return false;
  if (Boolean(o1.isCompact) !== Boolean(o2.isCompact)) return false;
  if (Boolean(o1.hideEmpty) !== Boolean(o2.hideEmpty)) return false;
  const o1Sec = o1.secondary || [];
  const o2Sec = o2.secondary || [];
  if (o1Sec.length !== o2Sec.length) return false;
  const secSame = o1Sec.every((sec1, i) => {
    const sec2 = o2Sec[i];
    return sec1.prop === sec2.prop && Boolean(sec1.isReversed) === Boolean(sec2.isReversed);
  });
  if (!secSame) return false;
  return true;
};

const getPreferredOrder = ({
  modelWithPreferredOrder,
  initialOrderProp,
}: {
  modelWithPreferredOrder: any;
  initialOrderProp?: string;
}): LoadedCardOrder => {
  const preferredOrder =
    modelWithPreferredOrder && modelWithPreferredOrder.$meta.get("preferredOrder", false);
  const fallback = {prop: initialOrderProp || "priority", isReversed: false};
  if (preferredOrder === false) return {isLoaded: false, ...fallback};
  return {isLoaded: true, ...(preferredOrder || fallback)};
};

const getOrder = ({
  localStorageVal,
  modelWithPreferredOrder,
  initialOrderProp,
}: {
  localStorageVal?: CardOrder | null;
  modelWithPreferredOrder: any;
  initialOrderProp?: string;
}) => {
  if (localStorageVal) return {isLoaded: true, ...localStorageVal};
  return getPreferredOrder({modelWithPreferredOrder, initialOrderProp});
};

const CardOrderContext = createContext<{
  data: CardOrder;
  setOrder: (o: CardOrder) => void;
  setCompact: (c: boolean) => void;
  setHideEmpty: (e: boolean) => void;
  clear: () => void;
  setMode: (mode: CardOrderMode) => void;
  orderKey: string;
}>({data: {prop: "priority", isReversed: false}, orderKey: null} as any);

export const useCardOrder = () => {
  const {data, setOrder, setCompact, clear, setMode, orderKey, setHideEmpty} =
    useContext(CardOrderContext);
  return [data, {clear, setOrder, setCompact, setMode, orderKey, setHideEmpty}] as const;
};

type CardOrderProviderProps = {
  initialOrderProp?: string;
  modelWithPreferredOrder?: any;
  children: ReactNode;
  orderKey: string;
};

const PublicCardOrderProvider = ({
  initialOrderProp,
  modelWithPreferredOrder,
  children,
  orderKey,
}: CardOrderProviderProps) => {
  const [orderVal, setOrderVal] = useState<CardOrder | null>(null);

  const order = getOrder({
    localStorageVal: orderVal,
    modelWithPreferredOrder,
    initialOrderProp,
  });

  const {prop, isReversed, secondary, isCompact, mode, hideEmpty} =
    !(orderInfo as any)[order.prop] && order.prop !== "manual"
      ? {
          prop: initialOrderProp || "priority",
          isReversed: false,
          isCompact: order.isCompact || false,
          mode: order.mode || "miniCard",
          secondary: null,
          hideEmpty: false,
        }
      : order;

  const value = useMemo(
    () => ({
      data: {
        prop,
        isReversed,
        secondary: secondary && secondary.length > 0 ? secondary : null,
        isCompact,
        hideEmpty,
        mode: mode || "miniCard",
      },
      setOrder: (next: CardOrder) => setOrderVal((prev) => ({...prev, ...next})),
      setCompact: (val: boolean) =>
        setOrderVal((prev) => ({...(prev as CardOrder), isCompact: val})),
      setHideEmpty: (val: boolean) =>
        setOrderVal((prev) => ({...(prev as CardOrder), hideEmpty: val})),
      setMode: (val: CardOrderMode) => setOrderVal((prev) => ({...(prev as CardOrder), mode: val})),
      clear: () => setOrderVal(null),
      orderKey,
    }),
    [prop, isReversed, setOrderVal, secondary, isCompact, mode, orderKey, hideEmpty]
  );

  return <CardOrderContext.Provider value={value}>{children}</CardOrderContext.Provider>;
};

const NonPublicCardOrderProvider = ({
  orderKey,
  initialOrderProp,
  modelWithPreferredOrder,
  children,
}: CardOrderProviderProps) => {
  const [localStorageVal, setLsVal, {clear}] = useLocalStorageState<LoadedCardOrder>(
    `default-search-${orderKey}`
  );
  const wantsChange = useLastIntention("setOrder");

  useEffect(() => {
    if (wantsChange) {
      setLsVal(
        {isLoaded: true, isCompact: false, ...(wantsChange.payload as CardOrder)},
        {dontPersist: true}
      );
    }
  }, [wantsChange, setLsVal]);

  const rawOrder = getOrder({
    localStorageVal,
    modelWithPreferredOrder,
    initialOrderProp,
  });

  const fullOrder =
    !(orderInfo as any)[rawOrder.prop] && rawOrder.prop !== "manual"
      ? {
          prop: initialOrderProp || "priority",
          isReversed: false,
          isCompact: rawOrder.isCompact || false,
          mode: rawOrder.mode || "miniCard",
          secondary: null,
          isLoaded: true,
        }
      : rawOrder;

  const value = {
    data: {
      ...fullOrder,
      secondary: fullOrder.secondary && fullOrder.secondary.length > 0 ? fullOrder.secondary : null,
      mode: fullOrder.mode || "miniCard",
    },
    setOrder: (next: CardOrder) => setLsVal({...fullOrder, ...next}),
    setCompact: (val: boolean) => setLsVal({...fullOrder, isCompact: val}),
    setHideEmpty: (val: boolean) => setLsVal({...fullOrder, hideEmpty: val}),
    setMode: (val: CardOrderMode) => setLsVal({...fullOrder, mode: val}),
    clear,
    orderKey,
  };

  return <CardOrderContext.Provider value={value}>{children}</CardOrderContext.Provider>;
};

export const CardOrderProvider = (props: CardOrderProviderProps) =>
  process.env.REACT_APP_MODE === "open" ? (
    <PublicCardOrderProvider key={props.orderKey} {...props} />
  ) : (
    <NonPublicCardOrderProvider {...props} />
  );

import {Link} from "react-router-dom";
import {filesize} from "filesize";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import {getModalUrl} from "../../components/ModalRegistry";
import routes from "../../routes";
import Avatar from "../../components/Avatar";
import {formatAsEur} from "../../lib/utils";
import {useLogout} from "../../lib/auth";
import {shortFormatterWithTime} from "../../lib/date-utils";
import {cx, rawButtonStyle, WithTooltip} from "@cdx/common";
import {arenaNavStyles as styles} from "./arena.css";
import {getBalance, getIncludedStorageInKB} from "../billing/billing-utils";
import {
  hasPermissionToManageBilling,
  hasPermissionToManageOrg,
  hasPermissionToViewUserManagement,
  hasPermissionToManageIntegrations,
} from "../../lib/permissions";
import {
  Box,
  DSIconAttachment,
  DSIconCog,
  DSIconHistory,
  DSIconRevenue,
  DSIconSignout,
  DSIconUser,
} from "@cdx/ds";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import cdxEnv from "../../env";

const NavItem = ({onClick, to, href, children, leftIcon: icon, left, right, isRed}) => (
  <XRow
    as={href ? "a" : onClick ? "button" : Link}
    py={2}
    px={3}
    sp={2}
    to={to}
    href={href}
    onClick={onClick}
    className={cx(rawButtonStyle, styles.navItem, isRed && colorThemes.alert800)}
    align="center"
  >
    <XCol align="center" style={{width: "3rem"}}>
      {icon || left}
    </XCol>
    <Box size={16} color="primary">
      {children}
    </Box>
    {right && (
      <>
        <XPush />
        {right}
      </>
    )}
  </XRow>
);

const FilesInfo = ({root: {account}}) => {
  const maxSizeKB = getIncludedStorageInKB(account);
  const maxSize = filesize(maxSizeKB * 1024, {exponent: 3, base: 2, standard: "jedec"});
  const usedSize = filesize(Number(account.totalMediaByteUsage), {base: 2, standard: "jedec"});
  const usedSizeGb = filesize(Number(account.totalMediaByteUsage), {
    exponent: 3,
    round: 2,
    output: "object",
    base: 2,
    standard: "jedec",
  }).value;
  const tooltip = `You've used ${usedSize} out of your ${
    maxSize === 0 ? "∞" : maxSize
  } of available storage`;
  return (
    <WithTooltip tooltip={tooltip} as={XText} size={1} color="gray400">
      {JSON.stringify(usedSizeGb)} / {maxSize}
    </WithTooltip>
  );
};

const SubInfo = ({root, balance}) => {
  const {account} = root;
  const {
    vatTaxPercentage,
    centsPerSeat: normalCentsPerSeat,
    billingCycleEnd,
    repeatingCoupon,
  } = account.stripeAccountSync;
  const percentOff =
    repeatingCoupon && (repeatingCoupon.months === null || repeatingCoupon.months > 0)
      ? repeatingCoupon.percentOff
      : 0;

  const centsPerSeat = (normalCentsPerSeat * (100 - percentOff)) / 100;
  const nextCharge = account.seats * centsPerSeat;
  const tooltip = `Based on the current number of users, your next charge will be ${formatAsEur(
    nextCharge
  )}${vatTaxPercentage ? ` excl. VAT` : ""} on ${shortFormatterWithTime.format(billingCycleEnd)}`;

  return (
    <WithTooltip tooltip={tooltip} as={XText} size={1} color="done500">
      {balance !== 0 ? `${formatAsEur(balance)} - ` : null}
      {formatAsEur(nextCharge)}
    </WithTooltip>
  );
};

const PayementEntry = ({to, root}) => {
  const {account} = root;
  const {stripeAccountSync} = account;
  const balance = getBalance(account);
  const activeSub =
    stripeAccountSync &&
    stripeAccountSync?.status !== null &&
    stripeAccountSync?.status !== "trialing";
  if (balance === 0 && !activeSub) return null;

  return (
    <NavItem
      to={to}
      leftIcon={<DSIconRevenue />}
      right={
        activeSub ? (
          <SubInfo root={root} balance={balance} />
        ) : (
          <XText size={1} color="done500">
            {formatAsEur(getBalance(account))}
          </XText>
        )
      }
    >
      {activeSub ? "Next charge" : "Balance"}
    </NavItem>
  );
};

const MiniNavItem = ({to, href, children, highlight}) => (
  <XText
    as={href ? "a" : Link}
    to={to}
    href={href}
    color={highlight ? "gray500" : "gray600"}
    size={1}
    className={styles.miniNavItem}
  >
    {children}
  </XText>
);

// We need to consider logged out users and users from wrong accounts!
const MenuNav = ({root, location, history}) => {
  const handleLogout = useLogout({history});
  const lastReleaseId = root.$meta.first("releases", {$order: "-createdAt"}).id;
  const releaseUrl = getModalUrl({modal: `releases.${lastReleaseId}`, location});
  const profileUrl = getModalUrl({modal: "userProfile", location});

  const canManageBilling = hasPermissionToManageBilling(root) && cdxEnv.ON_PREMISE !== "true";

  return (
    <XCol sp={2}>
      <XCol>
        {root.loggedInUser && (
          <NavItem
            to={profileUrl}
            left={<Avatar user={root.loggedInUser} border="accent" size={32} />}
          >
            Your Profile
          </NavItem>
        )}
        {root.loggedInUser && (
          <NavItem
            to={routes.media.getUrl()}
            leftIcon={<DSIconAttachment />}
            right={<FilesInfo root={root} />}
          >
            Files
          </NavItem>
        )}
        {root.loggedInUser && root.account.timeTrackingMode !== "none" && (
          <NavItem to={routes.timeTrackingReport.getUrl()} leftIcon={<DSIconHistory />}>
            Time Tracking Report
          </NavItem>
        )}
        {canManageBilling && (
          <PayementEntry
            to={getModalUrl({location, modal: "accountSettings.billing"})}
            root={root}
          />
        )}
        {hasPermissionToViewUserManagement(root) && (
          <NavItem
            to={getModalUrl({location, modal: "manageUsers"})}
            leftIcon={<DSIconUser />}
            right={
              <WithTooltip tooltip="Number of active users." as={XText} size={2} color="gray400">
                {root.account.seats}
              </WithTooltip>
            }
          >
            Users
          </NavItem>
        )}
        {(hasPermissionToManageOrg(root) || hasPermissionToManageIntegrations(root)) && (
          <NavItem to={getModalUrl({location, modal: "accountSettings"})} leftIcon={<DSIconCog />}>
            Organization Settings
          </NavItem>
        )}
        {root.loggedInUser ? (
          <NavItem isRed onClick={handleLogout} leftIcon={<DSIconSignout />}>
            Logout
          </NavItem>
        ) : (
          <NavItem to={routes.login.getUrl()} leftIcon={<DSIconUser />}>
            Login
          </NavItem>
        )}
      </XCol>
      {cdxEnv.ON_PREMISE !== "true" && (
        <XRow px={3} sp={2} align="baseline">
          <MiniNavItem href="https://www.codecks.io/imprint">Imprint</MiniNavItem>
          <MiniNavItem href="https://www.codecks.io/tos">ToS</MiniNavItem>
          <MiniNavItem href="https://www.codecks.io/privacy">Privacy</MiniNavItem>
          <XPush />
          <MiniNavItem to={releaseUrl}>Changelog</MiniNavItem>
        </XRow>
      )}
    </XCol>
  );
};

export default MenuNav;

import {useEffect} from "react";
import {create} from "zustand";
import {subscribeWithSelector} from "zustand/middleware";
import {useHoverCardStore} from "../../components/Card/useHoverShortcuts";

export const useCurrShiftHoverStore = create(
  subscribeWithSelector((set) => ({
    hoverCardInfo: null,
    setHoverCardInfo: (info) => set({hoverCardInfo: info}),
  }))
);

const getHoverInfo = ({info}) =>
  info && info.type === "MINI_CARD"
    ? {cardId: info.cardId, cardContainerKey: info.cardContainerKey}
    : null;

export const useShiftHover = () => {
  useEffect(() => {
    let unsub = null;
    const handleKeyDown = (e) => {
      if (e.which === 16 && !unsub) {
        useCurrShiftHoverStore
          .getState()
          .setHoverCardInfo(getHoverInfo(useHoverCardStore.getState()));
        unsub = useHoverCardStore.subscribe(getHoverInfo, (info) =>
          useCurrShiftHoverStore.getState().setHoverCardInfo(info)
        );
      }
    };

    const handleKeyUp = (e) => {
      if (e.which === 16) {
        if (unsub) {
          unsub();
          useCurrShiftHoverStore.getState().setHoverCardInfo(null);
          unsub = null;
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      if (unsub) {
        unsub();
        useCurrShiftHoverStore.getState().setHoverCardInfo(null);
        unsub = null;
      }
    };
  }, []);
};

import {useState, useEffect} from "react";
import {api} from "../lib/api";
import routes from "../routes";
import {
  springConfigs,
  useReveal,
  XCol,
  Portal,
  XText,
  XPlainButton,
  XRow,
  errorToString,
  XGhostButton,
} from "@cdx/common";
import {animated} from "react-spring";
import {API_ERRORS} from "../lib/request";
import cdxEnv from "../env";
import {apiErrorEvent} from "../lib/api-error-event";

export const ApiErrors = ({history, location}) => {
  const [error, setError] = useState(null);
  const [redirectSubdomain, setRedirectSubdomain] = useState(null);

  const handleRetry = () =>
    new Promise((resolve) =>
      api.cache.clear({
        onDone: () => {
          setError(null);
          resolve();
        },
      })
    );

  useEffect(
    () =>
      apiErrorEvent.addListener((e) => {
        if (e === null) {
          setError(null);
          return;
        }
        switch (e.type) {
          case API_ERRORS.FETCH_ERROR: {
            setError({
              type: "retryable",
              message: `There's an issue with requesting data from the server: '${errorToString(
                e.error
              )}'`,
              link:
                cdxEnv.ON_PREMISE !== "true"
                  ? {url: "https://status.codecks.io", label: "Open Status Page"}
                  : undefined,
            });
            return;
          }
          case API_ERRORS.API_VERSION_MISMATCH: {
            setError({
              type: "versionMismatch",
              message: "Codecks has been updated. Please refresh this window.",
            });
            return;
          }
          case API_ERRORS.API_ERROR: {
            switch (e.status) {
              case 401: {
                api.cache.clear({
                  delayReload: true,
                  onDone: () => {
                    history.replace({
                      pathname: "/login",
                      state: {from: location},
                    });
                  },
                });
                return;
              }
              case 403:
                api.cache.clear({
                  delayReload: true,
                  onDone: () => {
                    if (e.message === "too_many_projects" || e.message === "too_many_seats") {
                      history.push(routes.freeLimitsExceeded.getUrl());
                    } else {
                      history.push(routes.notAuthorised.getUrl());
                    }
                  },
                });
                return;
              default: {
                const message = errorToString(e.message || e.statusText);
                setError((prev) => {
                  if (prev && prev.message === message) return prev;
                  return {type: "default", message: errorToString(e.message || e.statusText)};
                });
                return;
              }
            }
          }
          case API_ERRORS.NEW_SUBDOMAIN: {
            setRedirectSubdomain(e.value);
            return;
          }
          default: {
            setError({type: "default", message: `Unknown error: ${e.type}`});
          }
        }
      }),
    [history, location]
  );
  const reveal = useReveal(
    error ||
      (redirectSubdomain
        ? {
            type: "newSubdomain",
            message: "The URL of your Organization has changed",
            value: redirectSubdomain,
          }
        : null),
    {config: springConfigs.quick}
  );

  return reveal((props, renderError) => {
    const isWarning = renderError.type === "versionMismatch" || renderError.type === "newSubdomain";
    return (
      <Portal>
        <XCol
          as={animated.div}
          inset="x"
          align="center"
          fixed
          style={{zIndex: 50, bottom: 0, opacity: props.value, pointerEvents: "none"}}
          px={1}
          pb={1}
        >
          <XCol
            bg={isWarning ? "purple600" : "error100"}
            elevation={2}
            px={3}
            py={2}
            rounded="md"
            style={{maxWidth: "100%", pointerEvents: "auto"}}
            sp={2}
            align="center"
          >
            <XText forceWrap color={isWarning ? "purple100" : "error600"} preset="bold" size={2}>
              {renderError.message}
            </XText>
            <XRow justify="center" sp={1}>
              {renderError.type === "newSubdomain" && (
                <XPlainButton
                  onClick={handleRetry}
                  href={cdxEnv.HOST_PATTERN.replace("[SUB]", renderError.value)}
                >
                  Open{" "}
                  {cdxEnv.HOST_PATTERN.replace("[SUB]", renderError.value).replace(
                    /https?:\/\//,
                    ""
                  )}
                </XPlainButton>
              )}
              {renderError.type === "retryable" && (
                <XPlainButton color="red" onClick={handleRetry}>
                  Retry
                </XPlainButton>
              )}
              {renderError.link ? (
                <XGhostButton
                  onClick={handleRetry}
                  href={renderError.link.url}
                  target="_blank"
                  rel="noopener"
                  color="warn"
                >
                  {renderError.link.label}
                </XGhostButton>
              ) : null}
              {renderError.type === "versionMismatch" && (
                <XPlainButton onClick={() => window.location.reload()}>Reload</XPlainButton>
              )}
            </XRow>
          </XCol>
        </XCol>
      </Portal>
    );
  });
};

import type {MutableRefObject, ChangeEvent, RefCallback, Ref} from "react";
import {useEffect, useId, useRef, useState} from "react";
import {useStore, createStore} from "zustand";
import {useShallow} from "zustand/react/shallow";
import type {AsyncCheck, AsyncValidationValue} from "./AsyncCheckManager";
import {createAsyncCheckManager} from "./AsyncCheckManager";
import type {
  Adapter,
  DeepIndex,
  FieldMeta,
  FieldValues,
  FormDataSlice,
  NeoFormError,
  NeoFormErrors,
  ParseResult,
  StoreContent,
} from "./utils";
import {
  asyncErrorToNeoFormError,
  mergeErrors,
  pathInto,
  setIntoPath,
  tryPathInto,
  useDeepEqual,
} from "./utils";

const emptyFieldMeta: FieldMeta = {
  visited: false,
  hasChanged: false,
  isFocussed: false,
};

type BasicFieldProps<TFieldVal> = {
  name: string;
  value: TFieldVal;
  onChange: (next: ChangeEvent<{value: TFieldVal}>) => unknown;
};

export type UseMetaArgs = FieldMeta & {
  errors: NeoFormError[];
  isPending: boolean;
  submitAttemptCount: number;
};

export type NeoField<T = string, TParsed = unknown> = {
  useValue: () => T;
  useParsedValue: () => undefined | TParsed;
  useMeta: <TMeta>(getter: (meta: UseMetaArgs) => TMeta) => TMeta;
  useCustomDataSlice: <TData>(namespace: string, getter: (store: any) => TData) => TData;
  name: string;
  id: string;
  getHandlers: (passedHandlers?: {
    ref?: Ref<any>;
    onChange?: (next: T) => void;
    onBlur?: (event?: any) => void;
    onFocus?: (event?: any) => void;
  }) => {
    onChange: (next: T) => void;
    onBlur: (event?: any) => void;
    onFocus: (event?: any) => void;
    ref: RefCallback<any>;
  };
};

export type NeoForm<TInput extends FieldValues, TParsed extends FieldValues> = {
  useValues: <TGetterRetVal>(
    getter: (partialValues: ParseResult<TParsed>["values"]) => TGetterRetVal
  ) => TGetterRetVal;
  useInputValues: <V>(getter: (v: TInput) => V) => V;
  useErrors: <V>(
    getter: (v: NeoFormErrors | null, opts: {isPending: boolean; submitAttemptCount: number}) => V
  ) => V;
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  useBasicField: <TPath extends string>(path: TPath) => BasicFieldProps<DeepIndex<TInput, TPath>>;
  reset: (nextData?: TInput) => void;
  field: <TPath extends string>(
    path: TPath
  ) => NeoField<DeepIndex<TInput, TPath>, DeepIndex<TParsed, TPath>>;
  setValue: <TPath extends string>(path: TPath, value: DeepIndex<TInput, TPath>) => void;
  createCustomDataSlice: <TSlice>(namespace: string, initial: TSlice) => FormDataSlice<TSlice>;
};

export type CreateArgs<TInput extends FieldValues, TParsed extends FieldValues> = {
  initial: TInput;
  asyncChecks?: {[K in keyof TParsed]?: AsyncCheck<TParsed[K]>};
  onSubmit: (values: TParsed) => Promise<unknown> | void;
  shouldFocusError?: boolean;
};

type CreateNeoFormArgs<TInput extends FieldValues, TParsed extends FieldValues> = CreateArgs<
  TInput,
  TParsed
> & {
  optsRef: MutableRefObject<CreateArgs<any, TParsed>>;
  adapter: Adapter<TInput, TParsed>;
  baseId: string;
};

const createNeoForm = <TInput extends FieldValues, TParsed extends FieldValues>(
  opts: Omit<CreateNeoFormArgs<TInput, TParsed>, "onSubmit">
) => {
  const {initial, asyncChecks, adapter, optsRef, baseId, shouldFocusError} = opts;

  const getAsyncCheckManager = () => {
    if (!asyncChecks) return null;
    const handleValidateAsync = (path: string, res: AsyncValidationValue) => {
      const prev = store.getState().asyncChecksByPath._ref;
      prev[path] = res;
      store.setState({asyncChecksByPath: {_ref: prev}});
    };
    const reffedAsyncChecks = Object.fromEntries(
      Object.entries(asyncChecks).map(([key, val]) => [
        key,
        {
          ...(val as AsyncCheck<TParsed[keyof TParsed]>),
          fn: (arg: any) => {
            const reffedCheck = optsRef.current.asyncChecks?.[key];
            if (!reffedCheck) throw new Error(`No async check function for ${key}`);
            return reffedCheck.fn(arg);
          },
        },
      ])
    );
    return createAsyncCheckManager({
      asyncChecks: reffedAsyncChecks,
      onValidate: handleValidateAsync,
    });
  };
  const asyncCheckManager = getAsyncCheckManager();

  const getFreshState = (input: TInput): StoreContent<TInput, TParsed> => ({
    inputState: {_ref: structuredClone(input)},
    parsedState: adapter.parse(input),
    fieldMetaByPath: {_ref: {}},
    asyncChecksByPath: {_ref: {}},
    submitAttemptCount: 0,
  });

  const store = createStore<StoreContent<TInput, TParsed>>(() => getFreshState(initial));

  const handleFieldChange = <TPath extends string>(
    path: TPath,
    value: DeepIndex<TInput, TPath>
  ) => {
    store.setState((prev) => {
      setIntoPath(prev.inputState._ref, path, value);
      const parsedState = adapter.parse(prev.inputState._ref);
      const res = tryPathInto(parsedState.values, path);
      if (res.ok) {
        asyncCheckManager?.onValueChange(path, res.value as any);
      }

      return {
        inputState: {_ref: prev.inputState._ref},
        parsedState,
      };
    });
  };

  const refMap = new Map<string, {cb: RefCallback<any>; node: any; passedRef?: Ref<any>}>();
  const addRef = (path: string, passedRef?: Ref<any>): RefCallback<any> => {
    let refObj = refMap.get(path);
    const registerNode = (someRef: Ref<any> | undefined, node: any) => {
      if (someRef) {
        if (typeof someRef === "function") someRef(node);
        if ("current" in someRef) {
          (someRef as any).current = node;
        }
      }
    };
    if (!refObj) {
      refObj = {
        cb: (node: any) => {
          refObj!.node = node;
          registerNode(refObj!.passedRef, node);
        },
        node: null,
        passedRef,
      };
      refMap.set(path, refObj);
    } else {
      if (refObj.passedRef !== passedRef) {
        if (refObj.passedRef) {
          if (typeof refObj.passedRef === "function") refObj.passedRef(null);
          if ("current" in refObj.passedRef) {
            (refObj.passedRef as any).current = null;
          }
        }
        if (passedRef) {
          registerNode(passedRef, refObj.node);
        }
        refObj.passedRef = passedRef;
      }
    }
    return refObj.cb;
  };

  const neoForm: NeoForm<TInput, TParsed> = {
    onSubmit: async (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      const pending = asyncCheckManager?.getPending();
      const invalidPending = pending && !(await pending).valid;
      store.setState((prev) => ({submitAttemptCount: prev.submitAttemptCount + 1}));
      if (invalidPending) return;
      const {parsedState} = store.getState();
      if (parsedState.errorsByPath === null) {
        void optsRef.current.onSubmit(parsedState.values);
      } else {
        if (shouldFocusError) {
          for (const [path, refObj] of refMap) {
            if (refObj.node && path in parsedState.errorsByPath) {
              refObj.node.focus?.();
              break;
            }
          }
        }
      }
    },
    reset: (nextData) => {
      store.setState(getFreshState(nextData || initial), true);
    },
    useValues: (getter) =>
      useStore(
        store,
        useShallow((s) => getter(s.parsedState.values))
      ),
    useErrors: (getter) =>
      useStore(
        store,
        useDeepEqual((s) =>
          getter(mergeErrors(s), {
            isPending: Object.values(s.asyncChecksByPath._ref).some((v) => v?.state === "pending"),
            submitAttemptCount: s.submitAttemptCount,
          })
        )
      ),
    useInputValues: (getter) =>
      useStore(
        store,
        useShallow((s) => getter(s.inputState._ref))
      ),
    useBasicField: (path) => {
      return {
        name: path.toString(),
        value: useStore(
          store,
          useShallow((s) => pathInto(s.inputState._ref, path))
        ),
        onChange: (e) => handleFieldChange(path, e.target.value), // toValue(e),
      };
    },
    setValue: (path, value) => handleFieldChange(path, value),
    createCustomDataSlice: (namespace, initialSliceData) => {
      const sliceName = `$slice_${namespace}`;
      return {
        useValue: (getter) =>
          useStore(
            store,
            useShallow((s) => {
              const slice = (s as any)[sliceName] || initialSliceData;
              return getter ? getter(slice) : slice;
            })
          ),
        setValue: (next) =>
          store.setState({
            [sliceName]: next,
          }),
      };
    },
    field: (path) => {
      const updateFieldMeta = (next: Partial<FieldMeta>) => {
        store.setState((prev) => {
          const meta = prev.fieldMetaByPath._ref[path];
          if (!meta) {
            prev.fieldMetaByPath._ref[path] = {
              isFocussed: false,
              hasChanged: false,
              visited: false,
              ...next,
            };
          } else {
            Object.assign(meta, next);
          }
          return {fieldMetaByPath: {_ref: prev.fieldMetaByPath._ref}};
        });
      };

      return {
        id: `field-${path as string}-${baseId}`,
        name: path.toString(),
        useValue: () =>
          useStore(
            store,
            useShallow((s) => pathInto(s.inputState._ref, path))
          ),
        useParsedValue: () =>
          useStore(
            store,
            useShallow((s) => pathInto(s.parsedState.values, path))
          ),
        useMeta: (getter) =>
          useStore(
            store,
            useDeepEqual((s) => {
              const meta = s.fieldMetaByPath._ref[path] || emptyFieldMeta;
              const asyncValidation = s.asyncChecksByPath._ref[path];
              const parseErrors = s.parsedState.errorsByPath?.[path];
              const list: NeoFormError[] = [...(parseErrors || [])];
              const asyncError = asyncErrorToNeoFormError(asyncValidation);
              if (asyncError) list.push(asyncError);
              return getter({
                ...meta,
                isPending: asyncValidation?.state === "pending",
                errors: list,
                submitAttemptCount: s.submitAttemptCount,
              });
            })
          ),
        useCustomDataSlice: (namespace, getter) => {
          const sliceName = `$slice_${namespace}`;
          return useStore(
            store,
            useShallow((s) => {
              const slice = (s as any)[sliceName];
              return getter ? getter(slice) : slice;
            })
          );
        },
        getHandlers: (passedProps) => ({
          ref: addRef(path, passedProps?.ref),
          onChange: (next) => {
            updateFieldMeta({hasChanged: true});
            handleFieldChange(path, next);
            passedProps?.onChange?.(next);
          },
          onBlur: (e) => {
            updateFieldMeta({isFocussed: false});
            passedProps?.onBlur?.(e);
          },
          onFocus: (e) => {
            updateFieldMeta({isFocussed: true, visited: true});
            passedProps?.onFocus?.(e);
          },
        }),
      };
    },
  };
  return neoForm;
};

export const useNeoFormWithAdapter = <TInput extends FieldValues, TParsed extends FieldValues>(
  opts: CreateArgs<TInput, TParsed>,
  adapter: Adapter<TInput, TParsed>
): NeoForm<TInput, TParsed> => {
  const optsRef = useRef(opts);
  useEffect(() => {
    optsRef.current = opts;
  });
  const baseId = useId();
  const [retVal] = useState<NeoForm<TInput, TParsed>>(() =>
    createNeoForm({
      initial: opts.initial,
      adapter,
      asyncChecks: opts.asyncChecks || {},
      shouldFocusError: opts.shouldFocusError,
      optsRef,
      baseId,
    })
  );
  return retVal;
};

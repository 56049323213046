import {Fragment} from "react";
import {Link} from "react-router-dom";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import UnMarkedown from "../../components/Markdown/UnMarkedown";
import {hoverInfoStyles as styles} from "./workflows.css";
import Avatar from "../../components/Avatar";
import xcolors from "../../components/xui/xcolors";
import {RawPriorityIcon} from "../../components/props";
import {EmojiTag} from "../../components/Card";
import {ProjectTagHint, ProjectTagTooltip} from "../../components/Card/CardBottomHints";
import {cx, TooltipForChild, WithTooltip} from "@cdx/common";
import {rawEnrichCardProjectTags} from "../../components/Markdown/CardTags";
import {cardStatusVariants} from "@cdx/ds/components/DSCard/DSCardTheme.css";
import {Box, DSIconButton, DSIconClose, DSIconEffort, Row} from "@cdx/ds";
import dsStyles from "@cdx/ds/css/index.css";

const ProjectTagHintPart = ({enrichedTags, interactable}) => {
  const tagColors = enrichedTags.map((t) => t.color).filter(Boolean);
  return tagColors.length > 0 ? (
    <WithTooltip
      tooltip={interactable && <ProjectTagTooltip tags={enrichedTags} />}
      options={{bg: "gray600"}}
      as={ProjectTagHint}
      tagColors={tagColors}
      isSmall
    />
  ) : null;
};

const WorkflowItemLane = ({
  item,
  interactable,
  to,
  forwardedRef,
  onRemove,
  removeLabel,
  onClick,
  isShown,
}) => {
  const enrichedTags = rawEnrichCardProjectTags({
    masterTags: item.masterTags,
    project: item.deck.project,
  });
  return (
    <XCol
      bg="white"
      px={2}
      py={1}
      sp={0}
      elevation={1}
      rounded="xs"
      className={cx(
        cardStatusVariants.workflowItem,
        styles.cardLane.container.base,
        isShown && styles.cardLane.container.isShown,
        onClick && styles.cardLane.container.isClickable
      )}
      {...(to && {as: Link, to})}
      onClick={onClick}
      ref={forwardedRef}
    >
      <XRow sp={0} align="start">
        <XRow sp={0} align="center" minWidth>
          <XText color="gray700" size={1} noOverflow>
            <EmojiTag enrichedProjectTags={enrichedTags} />
            <UnMarkedown as={Fragment} projectId={item.deck.project.id}>
              {item.title}
            </UnMarkedown>
          </XText>
        </XRow>
        <XPush />
        {onRemove && (
          <TooltipForChild tooltip={removeLabel}>
            <DSIconButton
              icon={<DSIconClose />}
              size="sm"
              negatePadding
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onRemove(item.id);
              }}
            />
          </TooltipForChild>
        )}
      </XRow>
      <XRow sp={0} align="center" style={{color: xcolors.gray600}}>
        {item.assignee && <Avatar size={14} user={item.assignee} />}
        {item.effort != null && (
          <Row align="center" sp="2px">
            <DSIconEffort size={16} className={dsStyles.color.secondary} />
            <Box bold size={12} lineHeight="none">
              {item.effort}
            </Box>
          </Row>
        )}
        {item.priority && (
          <RawPriorityIcon
            className={dsStyles.color.secondary}
            size={16}
            priority={item.priority}
          />
        )}
        <XPush />
        <ProjectTagHintPart enrichedTags={enrichedTags} interactable={interactable} />
      </XRow>
    </XCol>
  );
};

export default WorkflowItemLane;

import {statusToLabel} from "../../../lib/card-status-utils";

const labels = [
  "unassigned",
  "assigned",
  "started",
  "snoozing",
  "blocked",
  "review",
  "done",
  "archived",
  "undone",
];
const searchStatusToLabel = {
  ...statusToLabel,
  undone: "Undone",
};

const statusToFilter = (status, includeArchivedCards) => {
  switch (status) {
    case "deleted":
      return {visibility: "deleted"};
    case "archived":
      return {visibility: "archived"};
    case "review":
      return {resolvables: {context: "review", isClosed: false}};
    case "blocked":
      return {resolvables: {context: "block", isClosed: false}};
    case "done":
      return {
        "!resolvables": {context: ["block", "review"], isClosed: false},
        status: "done",
        visibility: includeArchivedCards ? ["default", "archived"] : "default",
      };
    case "undone":
      return {
        status: {op: "neq", value: "done"},
        isDoc: [false, null],
        visibility: includeArchivedCards ? ["default", "archived"] : "default",
      };
    case "started":
      return {
        "!resolvables": {context: ["block", "review"], isClosed: false},
        status: "started",
        visibility: "default",
      };
    case "snoozing":
      return {
        "!resolvables": {context: ["block", "review"], isClosed: false},
        status: "snoozing",
        visibility: "default",
      };
    case "assigned":
      return {
        "!resolvables": {context: ["block", "review"], isClosed: false},
        status: "not_started",
        visibility: "default",
        assigneeId: {op: "neq", value: null},
      };
    case "unassigned":
      return {
        "!resolvables": {context: ["block", "review"], isClosed: false},
        status: "not_started",
        visibility: "default",
        assigneeId: null,
      };
    default:
      throw new Error(`unknown status: '${status}'`);
  }
};

const statusCategory = {
  label: "Workflow state",
  key: "status",
  getSuggestions({input}) {
    if ("status".startsWith(input)) return labels;
    if ("state".startsWith(input)) return labels;
    return labels.filter((s) => s.toLowerCase().startsWith(input));
  },
  valueToKey(val) {
    return val;
  },
  valuesToDbQuery(values, {includeArchivedCards}) {
    if (values.length === 1) return statusToFilter(values[0], includeArchivedCards);
    return {$or: values.map(statusToFilter, includeArchivedCards)};
  },
  renderSuggestion(value) {
    return searchStatusToLabel[value];
  },
  savedSearchLabel(value) {
    return {
      prio: 1,
      label: searchStatusToLabel[value].toLowerCase(),
    };
  },
};

export default statusCategory;

import {cx} from "@cdx/common";
import {Box, Col} from "@cdx/ds";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {useState} from "react";
import {OnboardingBadgeStyles as styles} from "./onboarding.css";

export type OnboardingBadgeProps = {
  size: "md" | "lg";
  text: string;
};

const OnboardingBadge = (props: OnboardingBadgeProps) => {
  const {size, text} = props;
  const [id] = useState(() => Math.random().toString());
  return (
    <Box relative className={cx(styles.types.blue, styles.sizes[size])}>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56 56"
        className={cx(iconStyles.base, iconStyles.mode.block)}
      >
        <path
          d="M27 52.78c.74.3 1.26.3 2 0 10.74-4.3 20-13.07 20-24.48V3H7v23.49c0 11.84 8.84 21.82 20 26.29Z"
          fill={`url(#onboarding-badge-${id})`}
          strokeWidth="2"
          className={styles.inner}
        />
        <path
          d="M29.39 53.3c-.3.12-.83.2-1.39.2a4.1 4.1 0 0 1-1.39-.2C15.06 48.67 6.5 38.82 6.5 26.65V2.5h43v26.05c0 11.67-8.95 20.27-20.11 24.75Z"
          className={styles.mid}
        />
        <path
          d="M26.36 54.76c.81.32 2.47.32 3.28 0C41.41 50.06 51 41 51 28.57V2.43C51 1.64 50.35 1 49.54 1h-43C5.7 1 5 1.68 5 2.51V26.6c0 12.9 9.14 23.29 21.36 28.16Z"
          strokeWidth="2"
          className={styles.outer}
        />
        <defs>
          <linearGradient
            id={`onboarding-badge-${id}`}
            x1="48.09"
            y1="3.92"
            x2="27.83"
            y2="52.48"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".1" className={styles.g1} />
            <stop offset=".4" className={styles.g2} />
            <stop offset=".9" className={styles.g3} />
          </linearGradient>
        </defs>
      </svg>
      <Col
        color="primary"
        absolute
        top="0"
        left="0"
        right="0"
        bottom="10%"
        align="center"
        justify="center"
        bold
      >
        {text}
      </Col>
    </Box>
  );
};

export default OnboardingBadge;

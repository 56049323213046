import {DSListSelector} from "@cdx/ds";
import {api} from "../../lib/api";
import {checkIfPresent, useRoot} from "../../lib/mate/mate-utils";
import messenger from "../../lib/messenger";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {ONBOARDING_STEPS, completeOnboardingStep} from "../onboarding/onboarding-utils";
import {ArenaCtx} from "./card-panel-utils";
import sortBy from "lodash/sortBy";
import useMutation from "../../lib/hooks/useMutation";
import {
  hasPermissionToModifyDeck,
  hasPermissionToModifyMilestone,
  hasPermissionToModifySprint,
} from "../../lib/permissions";
import {CardId} from "../../cdx-models/Card";

export const dropChecksByType = {
  milestone: {
    handleDroppedCards: async (msId: string, cardIds: string[]) => {
      const newCardIds = await waitForResultPromise(() => {
        return cardIds.filter(
          (id) => api.getModel({id, modelName: "card"} as any)?.milestone?.id !== msId
        );
      });
      if (newCardIds.length) {
        await api.mutate.cards.bulkUpdate({ids: newCardIds, milestoneId: msId});
      }
    },
  },
  sprint: {
    handleDroppedCards: async (sprintId: string, cardIds: string[]) => {
      const newCardIds = await waitForResultPromise(() => {
        return cardIds.filter(
          (id) => api.getModel({id, modelName: "card"} as any)?.sprint?.id !== sprintId
        );
      });
      if (newCardIds.length) {
        await api.mutate.cards.bulkUpdate({ids: newCardIds, sprintId});
      }
    },
  },
  deck: {
    handleDroppedCards: async (deckId: string, cardIds: string[]) => {
      const newCardIds = await waitForResultPromise(() => {
        return cardIds.filter(
          (id) => api.getModel({id, modelName: "card"} as any)?.deck?.id !== deckId
        );
      });
      if (newCardIds.length) {
        await api.mutate.cards.bulkUpdate({ids: newCardIds, deckId});
      }
    },
  },
};

export const getCardOrders = ({
  account,
  arenaCtx,
}: {
  account: any;
  arenaCtx: Pick<ArenaCtx, "type" | "model">;
}) => {
  const getContstraint = () => {
    switch (arenaCtx.type) {
      case "milestone":
        return {milestoneId: arenaCtx.model.id};
      case "deck":
        return {deckId: arenaCtx.model.id};
      case "sprint":
        return {sprintId: arenaCtx.model.id};
      default:
        throw new Error("Invalid arenaCtx");
    }
  };
  const cardOrders: any[] = account.$meta.find("cardOrders", {
    context: arenaCtx.type,
    card: getContstraint(),
    $order: "sortValue",
  });
  return Object.fromEntries(
    cardOrders.map((co, idx) => [co.cardId, {idx, label: co.$meta.get("label", false)}])
  );
};

type DbBackedManualOrderArg = {
  arenaCtx: ArenaCtx;
  root: any;
  cards: any[];
};

type DropOpts = {draggedCardIds: string[]; targetIndex: number; label: string};

type CardWithOrderInfo = {card: any; idx: number; label: string | null | false};

export type DbBackedManualOrderRetVal = {
  orderedCardWithLabel: CardWithOrderInfo[];
  isOrderLoaded: boolean;
  onDrop: (cardIds: CardId[], opts: DropOpts) => Promise<any>;
  ctxKey: string | null;
  getFreshItems: () => void;
};
export const getDbBackedManualOrder = ({
  arenaCtx,
  root,
  cards,
}: DbBackedManualOrderArg): DbBackedManualOrderRetVal => {
  switch (arenaCtx.type) {
    case "sprint":
    case "milestone":
    case "deck": {
      const dropChecks = dropChecksByType[arenaCtx.type];
      const context = arenaCtx.type;
      const {isLoaded, result} = checkIfPresent(() => {
        const modelId = arenaCtx.model.$meta.get("id", null);
        const ctxKey = `${arenaCtx.type}-${modelId}`;
        const cardIdToOrderAndLabel = getCardOrders({
          account: root.account,
          arenaCtx,
        });

        const cardWithOrderInfo: CardWithOrderInfo[] = cards.map((c) => ({
          card: c,
          ...cardIdToOrderAndLabel[c.id],
        }));
        const orderedCardWithLabel = sortBy(cardWithOrderInfo, [
          ({idx}) => idx ?? Number.POSITIVE_INFINITY,
          ({card}) => card.$meta.get("createdAt", new Date()), // will cause `checkIfPresent`=false if card was added
          ({card}) => card.id,
        ]);

        const handleDrop = async (
          cardIds: CardId[],
          {draggedCardIds, targetIndex, label}: DropOpts
        ) => {
          if (!modelId) return;
          await dropChecks.handleDroppedCards(modelId, draggedCardIds);
          completeOnboardingStep(ONBOARDING_STEPS.dragAndDropCard);

          if (targetIndex === 0) {
            return api.mutate.cardOrders.addBefore({
              targetId: orderedCardWithLabel.length
                ? orderedCardWithLabel[0].card.$meta.get("cardId", null)
                : null,
              cardIds: draggedCardIds,
              context,
              label,
            });
          } else {
            const target = cardIds[Math.min(cardIds.length - 1, targetIndex - 1)];
            if (target && cardIdToOrderAndLabel[target] === undefined) {
              // target has no order info yet, so let's look for the first entry without an order
              const lastEntryWithOrderIdx =
                cardIds.findIndex((id) => cardIdToOrderAndLabel[id] === undefined) - 1;
              if (lastEntryWithOrderIdx < 0) {
                return api.mutate.cardOrders.addAfter({
                  targetId: null,
                  cardIds,
                  context,
                  label,
                });
              } else {
                return api.mutate.cardOrders.addAfter({
                  targetId: cardIds[lastEntryWithOrderIdx],
                  cardIds: cardIds.slice(lastEntryWithOrderIdx + 1),
                  context,
                  label,
                });
              }
            } else {
              return api.mutate.cardOrders.addAfter({
                targetId: target,
                cardIds: draggedCardIds,
                context,
                label,
              });
            }
          }
        };
        return {
          orderedCardWithLabel,
          isOrderLoaded: true,
          onDrop: handleDrop,
          ctxKey,
          getFreshItems: () => getCardOrders({account: api.getRoot().account, arenaCtx}),
        };
      }, api);

      return {...result, isOrderLoaded: isLoaded};
    }
    default: {
      return {
        isOrderLoaded: true,
        onDrop: async () => messenger.send("Not supported", {type: "error"}),
        getFreshItems: () => {},
        orderedCardWithLabel: [],
        ctxKey: null,
      };
    }
  }
};

export const getCardOrderUpdateMutationName = (
  arenaCtx: Pick<ArenaCtx, "type">
): [string, string] => {
  switch (arenaCtx.type) {
    case "milestone":
      return ["milestones", "update"];
    case "deck":
      return ["decks", "update"];
    case "sprint":
      return ["sprints", "updateSprint"];
    default:
      throw new Error("missing type", arenaCtx.type as any);
  }
};

type ZoneSelectorProps = {
  arenaCtx: Pick<ArenaCtx, "type" | "model">;
  currentLabel?: string | null;
  onChange: (label: string | null) => any;
};
export const ZoneSelector = (props: ZoneSelectorProps) => {
  const {currentLabel, onChange, arenaCtx} = props;
  const root = useRoot();
  const passedLabels = arenaCtx.model.manualOrderLabels as (string | null)[];
  const labels = passedLabels.length === 0 ? [null] : passedLabels;
  const [doUpdate] = useMutation(...getCardOrderUpdateMutationName(arenaCtx));
  const getCanEdit = () => {
    switch (arenaCtx.type) {
      case "sprint":
        return hasPermissionToModifySprint({root, sprint: arenaCtx.model});
      case "milestone":
        return hasPermissionToModifyMilestone({root, milestone: arenaCtx.model});
      case "deck":
        return hasPermissionToModifyDeck({root, project: arenaCtx.model.project});
      default:
        return false;
    }
  };
  return (
    <DSListSelector
      label="Choose Zone"
      getOptions={() => labels}
      optionToKey={(p) => p ?? null}
      optionToSearchString={(p) => (p === null ? "Default Zone" : p)}
      renderOption={(p) => (p === null ? "Default Zone" : p)}
      initalSelectionKey={currentLabel ?? null}
      currentOptionKey={currentLabel}
      autoFocus
      onConfirm={onChange}
      createOpts={
        getCanEdit()
          ? {
              label: "Create new Zone",
              onCreate: async (term) => {
                await doUpdate({id: arenaCtx.model.id, manualOrderLabels: [...labels, term]});
                return onChange(term);
              },
            }
          : undefined
      }
    />
  );
};

import {useState, useRef, useContext, useEffect, forwardRef} from "react";
import UiHandlerContext from "@cdx/ds/components/DSForm/UiHandlerCtx";
import {useGetNodeFromRef, useTooltip} from "../overlay/WithTooltip";
import XCol from "../xui/XCol";
import {isWithMetaKey} from "../device-utils";

const useButtonBehaviour = ({onClick, onMetaClick, disabled, noErrorHandling, ...rest}: any) => {
  const [pending, setPending] = useState(false);
  const unmountedRef = useRef<undefined | boolean>();
  const {onButtonError} = useContext(UiHandlerContext);
  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    []
  );

  const handleClick = (e: React.MouseEvent) => {
    const clickFn = onMetaClick && isWithMetaKey(e) ? onMetaClick : onClick;
    const retVal = clickFn(e);
    if (retVal && typeof retVal.then === "function") {
      setPending(true);
      retVal.then(
        () => !unmountedRef.current && setPending(false),
        (error: any) => {
          if (!noErrorHandling) onButtonError(error);
          if (!unmountedRef.current) setPending(false);
        }
      );
    }
  };
  return {
    disabled: pending ? true : disabled,
    onClick: onClick ? handleClick : undefined,
    ...rest,
  };
};

const WithTooltip = ({
  tooltip,
  tooltipProps,
  innerRef,
  comp: Comp = "button",
  children,
  ...rest
}: any) => {
  const {node, ref} = useGetNodeFromRef(innerRef);
  const {events, tooltipElement} = useTooltip({
    node,
    content: tooltip,
    targetIsDisabled: rest.disabled,
    ...tooltipProps,
  });
  return (
    <>
      {tooltipElement}
      <Comp {...rest} {...(!rest.disabled && events)} ref={ref}>
        {rest.disabled ? <XCol absolute inset="full" style={{zIndex: 5}} {...events} /> : null}
        {children}
      </Comp>
    </>
  );
};

const ButtonBehaviour = forwardRef<HTMLElement, any>((props, ref) => {
  const buttonProps = useButtonBehaviour(props);
  if ("tooltip" in buttonProps || "tooltipProps" in buttonProps) {
    return <WithTooltip {...buttonProps} innerRef={ref} />;
  } else {
    const {comp: Comp = "button", ...rest} = buttonProps;
    return <Comp {...rest} ref={ref} />;
  }
});

export default ButtonBehaviour;

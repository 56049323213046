import {visit} from "unist-util-visit";
import {isSelectionActive} from "../../../lib/utils";

const clickTargetPlugin = () => {
  return (tree) => {
    // wrap each text that has a position with a span
    visit(
      tree,
      (n) => n.position && n.type === "text",
      (n, idx, parent) => {
        parent.children[idx] = {
          type: "element",
          tagName: "span",
          properties: {},
          position: n.position,
          children: [n],
        };
      }
    );

    visit(
      tree,
      (n) => n.position && n.properties,
      (n) => {
        n.properties.onClick = (e) => {
          if (n.tagName === "a") {
            e.stopPropagation();
          } else {
            if (e.nativeEvent.$cdxEvent) return;
            if (isSelectionActive()) e.stopPropagation();

            const {start, end} = n.position;
            e.nativeEvent.$cdxEvent = {
              position: [
                [start.line, start.column],
                [end.line, end.column],
              ],
            };
          }
        };
      }
    );
  };
};

export default clickTargetPlugin;

import {
  useEsc,
  XCol,
  XGrid,
  XPush,
  XRow,
  XText,
  XTextButton,
  useReveal,
  formatMsAsMin,
  TooltipForChild,
  cx,
} from "@cdx/common";
import {MaybeInaccessibleCard} from "../../components/Card";
import useMutation from "../../lib/hooks/useMutation";
import {useCardContainer} from "../card-selection/useCardContainer";
import {IconClose} from "@cdx/common/icons/Icon";
import {
  addDays,
  getStartOfMonday,
  getWeekAndYear,
  shortDate,
  superShortDate,
} from "../../lib/date-utils";
import {EffortSummary} from "../card-panel/SwimlaneLabel";
import {useDropZone} from "@codecks/dnd";
import {animated} from "react-spring";
import {handQueueStyles as styles, streakIconStyles} from "./hand-queue.css";
import range from "lodash/range";
import {decorationStyles, transitions} from "@cdx/ds/css/decoration.css";
import {useMemo} from "react";
import {useInstance} from "../../lib/mate/mate-utils";
import {Box, css, DSIconCheck, DSIconClock} from "@cdx/ds";
import uiClasses from "@cdx/common/xui/ui.css";
import StreakIcon from "./StreakIcon";
import dsStyles from "@cdx/ds/css/index.css";
import {hasPermissionToGuardCard} from "../../lib/permissions";
import {api} from "../../lib/api";
import {Pager} from "../activity-feed/Pager";
import {useCardNavigation} from "../card-panel/mini-card-navigation-shortcuts";
import {hasChildCards} from "../workflows/workflow-utils";

const getCardIdFromEntry = ({card}) =>
  !card || card.$meta.isDeleted() ? null : card.$meta.get("cardId", null);

const getWeeklyGroups = (items) => {
  if (!items.length) return [];
  const getKey = (i) => {
    const date = i.$meta.get("cardDoneAt", null);
    if (!date) return "unknown";
    const [y, w] = getWeekAndYear(date);
    return `${y}-${w}`;
  };
  const groups = [];
  let currGroup = {key: getKey(items[0]), items: [items[0]]};
  groups.push(currGroup);
  for (const item of items.slice(1)) {
    const key = getKey(item);
    if (key === currGroup.key) {
      currGroup.items.push(item);
    } else {
      currGroup = {key, items: [item]};
      groups.push(currGroup);
    }
  }
  return groups;
};

const GroupLabel = ({startOfMonday}) => (
  <XText size={1} lineHeight="none" color="gray100" preset="bold">
    {startOfMonday ? `Cards done in week of ${superShortDate(startOfMonday)}` : "loading"}
  </XText>
);

const TimeTrackingSummary = ({monday, userId, root}) => {
  const user = useInstance("user", userId);
  const nextMonday = addDays(monday, 7);
  const segs = root.account.$meta.find("timeTrackingSegments", {
    userId,
    $and: [{startedAt: {op: "gte", value: monday}}, {startedAt: {op: "lt", value: nextMonday}}],
  });
  const totalMs = useMemo(() => {
    let sum = 0;
    for (const seg of segs) {
      const {startedAt, finishedAt, modifyDurationMsBy} = seg;
      sum += (finishedAt || new Date()) - startedAt + modifyDurationMsBy;
    }
    return sum;
  }, [segs]);
  if (!totalMs) return null;
  return (
    <TooltipForChild
      tooltip={
        user && `${user === root.loggedInUser ? "Your" : `${user.name}'s`} tracked time that week`
      }
    >
      <XRow align="center" sp={1}>
        <DSIconClock size={16} className={decorationStyles.color.secondary} />
        <XText size={0} color={"gray400"} lineHeight="tight" style={{marginLeft: -2}}>
          {formatMsAsMin(totalMs)}
        </XText>
      </XRow>
    </TooltipForChild>
  );
};

const Group = ({
  items,
  root,
  notifications,
  getCardUrl,
  goToCard,
  cursor,
  activeCard,
  arenaCtx,
  cardContainerKey,
  tail,
  userId,
}) => {
  const firstItemStart = items[0]?.$meta.get("cardDoneAt", null);
  const monday = firstItemStart && getStartOfMonday(firstItemStart);

  return (
    <XCol sp={2}>
      <XRow sp={3} align="center">
        <GroupLabel startOfMonday={monday} />
        <EffortSummary cards={items.map((i) => i.card).filter(Boolean)} account={root.account} />
        {monday && userId && <TimeTrackingSummary monday={monday} userId={userId} root={root} />}
      </XRow>
      <XGrid sp={3}>
        {items.map((entry) => {
          const card = entry.card;
          const cardId = getCardIdFromEntry(entry);
          return (
            <XCol sp={1} key={cardId}>
              <XText color="gray400" size={0}>
                {entry.$meta.get("cardDoneAt", false) ? shortDate(entry.cardDoneAt) : "-"}
              </XText>
              <MaybeInaccessibleCard
                root={root}
                notifications={notifications}
                card={card}
                getCardUrl={getCardUrl}
                goToCard={goToCard}
                isLeader={
                  cursor && cursor.cardId === cardId && cursor.cardContainerKey === cardContainerKey
                }
                activeCardId={activeCard && activeCard.id}
                allowSelection
                draggable
                arenaCtx={arenaCtx}
                cardContainerKey={cardContainerKey}
              />
            </XCol>
          );
        })}
        {tail}
      </XGrid>
    </XCol>
  );
};

const WithItems = ({
  items,
  root,
  notifications,
  getCardUrl,
  goToCard,
  activeCard,
  arenaCtx,
  userId,
  user,
  sortIdx,
  location,
}) => {
  const cardContainerKey = `u:${userId}-done-cards`;
  const cursor = useCardNavigation({
    activeCard,
    getCardIdsForGroup: () => items.map(getCardIdFromEntry),
    groups: [cardContainerKey],
    goToCard,
    category: arenaCtx.shortcutCategory,
    location,
  });
  useCardContainer({
    cardContainerKey,
    visibleCardIds: items.map(getCardIdFromEntry),
    idx: sortIdx,
  });

  const groups = getWeeklyGroups(items);

  return (
    <XCol sp={7}>
      {groups.map((group) => (
        <Group
          key={group.key}
          items={group.items}
          root={root}
          notifications={notifications}
          getCardUrl={getCardUrl}
          goToCard={goToCard}
          cursor={cursor}
          activeCard={activeCard}
          arenaCtx={arenaCtx}
          cardContainerKey={cardContainerKey}
          userId={userId}
          user={user}
        />
      ))}
    </XCol>
  );
};

const DonePile = ({
  onHide,
  root,
  user,
  userId,
  notifications,
  getCardUrl,
  goToCard,
  activeCard,
  arenaCtx,
  location,
  idx: sortIdx,
}) => {
  const collection = {
    parent: root.account,
    relName: "queueEntries",
    filter: {
      userId,
      cardDoneAt: {op: "neq", value: null},
    },
    orderProps: ["-cardDoneAt"],
  };

  useEsc(onHide);

  return (
    <XCol sp={3} bg="gray800" px={3} py={2} rounded="md" border="gray600" relative>
      <XRow align="baseline" sp={3}>
        <XText color="gray300" preset="bold" size={2}>
          Done pile
        </XText>
        <XPush />
        <XTextButton onClick={onHide} color="white" square>
          <IconClose />
        </XTextButton>
      </XRow>
      <Pager
        collection={collection}
        pageSize={25}
        renderItems={(items) => (
          <WithItems
            items={items}
            root={root}
            notifications={notifications}
            getCardUrl={getCardUrl}
            goToCard={goToCard}
            activeCard={activeCard}
            arenaCtx={arenaCtx}
            userId={userId}
            user={user}
            sortIdx={sortIdx}
            location={location}
          />
        )}
        renderEmpty={() => (
          <XCol justify="center" align="center" className={dsStyles.height.cardHeight}>
            <XText preset="bold" color="gray400">
              No done cards right now
            </XText>
          </XCol>
        )}
      />
    </XCol>
  );
};

export default DonePile;

const getCanDropToDoneStackError = (dragItem, userId) => {
  if (!dragItem) return null;
  const cards = dragItem.data.cardInfos.map((i) => i.getCard()).filter(Boolean);
  if (cards.some((c) => c.status === "done")) {
    return "Card already done";
  }
  if (cards.some((c) => hasChildCards(c))) {
    return "Can't move hero cards to done pile";
  }
  if (cards.some((c) => c.isDoc)) {
    return "Docs are never done! Archive the card if not relevant anymore.";
  }
  if (cards.some((c) => c.$meta.exists("resolvables", {isClosed: false, context: "block"}))) {
    return "Card still blocked";
  }
  if (cards.some((c) => c.$meta.exists("resolvables", {isClosed: false, context: "review"}))) {
    return "Card still in review";
  }
  if (cards.some((c) => c.assignee && c.assignee.id !== userId)) {
    return "Card not owned";
  }
  if (cards.some((card) => !hasPermissionToGuardCard(api.getRoot(), card))) {
    return "Only Guardians may set this card as done";
  }
  return null;
};

const degs = [2, -3, 5, -1, 2, -4, 1, 4, -3, -1, 4];

const CardStash = ({latestCard, root, count: rawCount}) => {
  const count = Math.min(rawCount, 24);
  const startScale = 0.85 - (count / 24) * 0.01;
  const startOffset = (count / 24) * 10;
  return (
    <>
      {range(count).map((rawIdx) => {
        const idx = count - rawIdx;
        return (
          <div
            key={idx}
            className={styles.fauxDoneCard.container}
            style={{
              transform: `rotate(${degs[idx % degs.length]}deg) translate3d(${
                ((degs[idx % degs.length] * idx) / 24) * 4
              }px,${startOffset + (idx / 24) * -50}px,0.1px) scale(${
                startScale - (idx / 24) * startScale * 0.5
              }) `,
            }}
          >
            <div className={styles.fauxDoneCard.body} />
            <div className={styles.fauxDoneCard.footer} />
          </div>
        );
      })}
      <XCol
        absolute
        className={transitions.transforms}
        style={{
          transform: `rotate(${
            degs[(count + 1) % degs.length]
          }deg) scale(${startScale}) translate3d(0,${startOffset}px,0.1px)`,
        }}
      >
        <MaybeInaccessibleCard card={latestCard} root={root} />
      </XCol>
    </>
  );
};

export const getDoneCardCountThisWeek = ({root, userId}) =>
  root.account.$meta.count("queueEntries", {
    userId,
    cardDoneAt: {op: "gte", value: getStartOfMonday(new Date())},
  });

export const DonePileTile = ({userId, root, onShow, showStreak}) => {
  const [updateCards] = useMutation("cards", "bulkUpdate");
  const lastDoneEntry = root.account.$meta.first("queueEntries", {
    userId,
    cardDoneAt: {op: "gte", value: getStartOfMonday(new Date())},
    $order: "-cardDoneAt",
  });

  const countThisWeek = getDoneCardCountThisWeek({root, userId});

  const onDropCard = ({item}) => {
    const dropErr = getCanDropToDoneStackError(item, userId);
    if (dropErr) return;
    return updateCards({ids: item.data.cardInfos.map((i) => i.cardId), status: "done"});
  };

  const {dragItem, isOver, ref} = useDropZone({
    type: "CARD",
    onDrop: onDropCard,
  });

  const revealDragOverlay = useReveal(dragItem, {
    from: {opacity: 0},
    enter: {opacity: 0.9},
    leave: {opacity: 0.01},
  });

  const hideStreak = useReveal(!dragItem, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  });

  return (
    <XCol sp={1}>
      <Box relative>
        <XCol
          ref={ref}
          className={cx(dsStyles.width.cardWidth, dsStyles.height.cardHeight)}
          bg="gray700"
          rounded="md"
          align="center"
          justify="center"
          pa={1}
          noOverflow
          relative
        >
          {revealDragOverlay((props, currDragItem) => {
            const dropError = getCanDropToDoneStackError(currDragItem, userId);
            return (
              <XCol
                inset="full"
                absolute
                style={{...props, zIndex: 2}}
                as={animated.div}
                bg={dropError ? "gray300" : isOver ? "done400" : "done700"}
                align="center"
                justify="center"
                pa={2}
              >
                {dropError ? (
                  <XCol sp={2}>
                    <XText color="gray700" preset="bold" align="center">
                      Can't drop card
                    </XText>
                    <XText color="gray700" size={1} align="center" lineHeight="none">
                      {dropError}
                    </XText>
                  </XCol>
                ) : (
                  <XText color="done100" preset="bold" align="center">
                    Done cards go here
                  </XText>
                )}
              </XCol>
            );
          })}
          {countThisWeek === 0 ? (
            <XTextButton onClick={onShow} className={styles.emptyButton}>
              <XCol align="center" sp={3}>
                <Box rounded="full" className={uiClasses.backgroundColor.gray600} pa={1}>
                  <DSIconCheck className={styles.emptyIcon} size={20} />
                </Box>
                <XText size={1} color="gray400" align="center" lineHeight="tight">
                  Done cards go here
                </XText>
              </XCol>
            </XTextButton>
          ) : (
            <>
              <CardStash latestCard={lastDoneEntry?.card} root={root} count={countThisWeek - 1} />
              <XTextButton onClick={onShow} className={styles.stashButton}>
                See all cards
              </XTextButton>
            </>
          )}
        </XCol>
        {showStreak &&
          hideStreak((props) => (
            <animated.div
              style={props}
              className={css({
                display: "flex",
                flexDir: "column",
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                align: "center",
                pointerEvents: "none",
              })}
            >
              <StreakIcon
                root={root}
                meId={userId}
                className={css({pointerEvents: "auto"}, streakIconStyles.box)}
              />
            </animated.div>
          ))}
      </Box>
    </XCol>
  );
};

import {lazy} from "react";
import {pronounceSafeSeq} from "../../lib/sequences";
import {api} from "../../lib/api";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {slugify} from "../../lib/utils";

const toCardPath = ({title, accountSeq}) =>
  `${pronounceSafeSeq.intToSeq(accountSeq)}-${slugify(title)}`;

const toDeckPath = ({title, accountSeq}) => `${accountSeq}-${slugify(title)}`;

const publicProjectRoutes = {
  portal: {
    path: "/",
    getUrl: () => "/",
    component: lazy(
      () => import(/* webpackChunkName: "PublicProjectPortal" */ "../public-project-portal")
    ),
    doNotRender: true, // is explicitly rendered in a higher up layer
  },
  publicProject: {
    path: "/:publicPath",
    getUrl: (project) => `/${project.publicPath}`,
    component: lazy(() => import(/* webpackChunkName: "PublicProject" */ "./index")),
  },
  publicProjectWithDeck: {
    doNotRender: true,
    path: "/:publicPath/decks/:deckId",
    getUrl: (project, {accountSeq, title}) =>
      `/${project.publicPath}/decks/${toDeckPath({accountSeq, title})}`,
    ensureUrl: (deckId) =>
      waitForResultPromise(() => {
        const deck = api.getModel({modelName: "deck", id: deckId});
        return {accountSeq: deck.accountSeq, publicPath: deck.project.publicPath};
      }).then(({accountSeq, publicPath}) => `/${publicPath}/decks/${accountSeq}`),
  },
  publicProjectWithDeckAndCard: {
    doNotRender: true,
    path: "/:publicPath/decks/:deckId/card/:cardId",
    getUrl: (project, {accountSeq, title}, {accountSeq: cSeq, title: cTitle}) =>
      `/${project.publicPath}/decks/${toDeckPath({accountSeq, title})}/card/${toCardPath({
        accountSeq: cSeq,
        title: cTitle,
      })}`,
  },
};

export default publicProjectRoutes;

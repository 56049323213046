import {CSSProperties, forwardRef} from "react";
import {DSAvatarStyles as styles, DSAvatarVars} from "./DSAvatar.css";
import {getVarName} from "@vanilla-extract/private";
import {adler32} from "../../lib/utils";
import {File} from "../../cdx-models/File";
import {cx} from "@cdx/common";
import {User, UserId} from "../../cdx-models/User";
import {CdxCropImgByFile} from "../CdxImg";
import dsStyles from "@cdx/ds/css/index.css";
import {Box, DSIconRobot} from "@cdx/ds";

const idToCol: {[userId: UserId]: number} = {};
const getIdVal = (id: UserId) => {
  const exist = idToCol[id];
  if (!exist) {
    // const col = `hsl(${adler32(id) % 360}, 96%, 46%)`;
    return (idToCol[id] = adler32(id) % 360);
  } else {
    return exist;
  }
};

export const avatarSizes = [{width: 24, height: 24}];

const Img = forwardRef<
  HTMLElement,
  {
    file: File;
    alt: string;
    title?: string;
    className: string;
    size: number;
    onClick?: (e: any) => void;
  }
>((props, ref) => {
  const {file, alt, title = alt, className, size: outerSize, ...rest} = props;
  const imgSize = outerSize - 2;
  return (
    <CdxCropImgByFile
      width={imgSize}
      height={imgSize}
      pickSize={avatarSizes}
      file={file}
      alt={alt}
      title={title}
      className={cx(styles.img, className)}
      {...rest}
      ref={ref}
    />
  );
});

const InitialsAvatar = forwardRef<
  HTMLDivElement,
  {
    title: string;
    className: string;
    name: string;
    userId: UserId;
  }
>((props, ref) => {
  const {title, className, name, userId, ...rest} = props;
  const hueStyle = {[getVarName(DSAvatarVars.hue)]: getIdVal(userId)};
  return (
    <figure
      style={hueStyle}
      className={cx(className, styles.initials)}
      title={title}
      ref={ref}
      {...rest}
    >
      {name.slice(0, 2)}
    </figure>
  );
});

const Robot = forwardRef<any, Pick<DSAvatarProps, "onClick" | "className">>(
  ({className, ...rest}, ref) => {
    return (
      <div {...rest} className={cx(className, styles.robot)} title="Codecks Bot">
        <DSIconRobot ref={ref} />
      </div>
    );
  }
);

export type DSAvatarProps = {
  user: User | null;
  size?: keyof (typeof styles)["sizes"];
  className?: string;
  style?: CSSProperties;
  flat?: boolean;
  onClick?: (e: any) => void;
};
const DSAvatar = forwardRef<HTMLDivElement, DSAvatarProps>((props, ref) => {
  const {user, size = 24, className, flat, ...rest} = props;
  const baseClasses = cx(
    styles.sizes[size],
    !flat && dsStyles.elevation[100],
    rest.onClick && dsStyles.cursor.pointer,
    className
  );

  if (!user) return <Robot {...rest} className={baseClasses} ref={ref} />;

  return user.profileImage ? (
    <Img
      file={user.profileImage}
      alt={user.name || "Anonymous User"}
      className={baseClasses}
      size={size}
      ref={ref}
      {...rest}
    />
  ) : (
    <InitialsAvatar
      className={baseClasses}
      userId={user.id as UserId}
      name={user.$meta.get("name", "?") || "?"}
      title={user.name || "Anonymous User"}
      ref={ref}
      {...rest}
    />
  );
});

export default DSAvatar;

type DSAvatarStackProps = Pick<DSAvatarProps, "flat" | "size"> & {
  users: User[];
  maxCount: number;
  className?: string;
};
export const DSAvatarStack = (props: DSAvatarStackProps) => {
  const {users, maxCount, className, ...rest} = props;
  const tooMuch = users.length > maxCount;
  const shownUsers = tooMuch ? users.slice(0, maxCount - 1).reverse() : [...users].reverse();
  return (
    <Box display="flex" flexDir="row-reverse" align="center" className={className}>
      {tooMuch && (
        <div className={cx(styles.sizes[rest.size ?? 24], styles.tooMuch)}>
          +{users.length - shownUsers.length}
        </div>
      )}
      {shownUsers.map((u) => (
        <DSAvatar key={u.id} {...rest} className={styles.stacked} user={u} />
      ))}
    </Box>
  );
};

export const formatMsAsMin = (rawMs) => {
  const ms = Math.abs(rawMs);
  const hours = Math.floor(ms / 1000 / 3600);
  const leftMinutesMs = ms - hours * 1000 * 3600;
  const minutes = Math.floor(leftMinutesMs / 1000 / 60);
  return `${ms < 0 ? "-" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes} h`;
};

export const formatMsAsSec = (rawMs, {short} = {}) => {
  const ms = Math.abs(rawMs);
  const hours = Math.floor(ms / 1000 / 3600);
  const leftMinutesMs = ms - hours * 1000 * 3600;
  const minutes = Math.floor(leftMinutesMs / 1000 / 60);
  const leftSecondsMs = leftMinutesMs - minutes * 1000 * 60;
  const seconds = Math.floor(leftSecondsMs / 1000);
  const parts = [`${minutes < 10 ? "0" : ""}${minutes}`, `${seconds < 10 ? "0" : ""}${seconds}`];
  if (!short || hours) parts.unshift(hours);
  return `${ms < 0 ? "-" : ""}${parts.join(":")}`;
};

import {Text, Parent, Root, Content} from "mdast";

function addBreaks(tree: Root) {
  const walk = (node: Content, parent: Parent) => {
    if (node.type === "text") {
      handler(node, parent);
    } else {
      if ("children" in node) {
        for (const child of node.children) {
          walk(child, node);
        }
      }
    }
  };
  for (const child of tree.children) walk(child, tree);

  function handler(node: Text, parent: Parent) {
    let nodes: Content[] = [];
    const lines = node.value.split("\n");
    const pos = node.position;
    if (!pos) {
      for (let i = 0; i < lines.length; i += 1) {
        const value = lines[i];
        nodes.push({type: "text", value});
        if (i < lines.length - 1) {
          nodes.push({type: "break"});
        }
      }
    } else {
      const indent = pos.start.column - 1;
      const startLine = pos.start.line;
      const startOffset = pos.start.offset!;
      let offset = 0;
      for (let i = 0; i < lines.length; i += 1) {
        const value = lines[i];
        const len = value.length;
        const position: Content["position"] = {
          start: {
            line: startLine + i,
            column: indent + 1,
            offset: startOffset + offset,
          },
          end: {
            line: startLine + i,
            column: indent + len + 1,
            offset: startOffset + offset + len,
          },
        };
        nodes.push({type: "text", value, position});
        if (i < lines.length - 1) {
          nodes.push({
            type: "break",
            position: {
              start: {
                line: startLine + i,
                column: indent + len + 1,
                offset: startOffset + offset + len,
              },
              end: {
                line: startLine + i,
                column: indent + len + 2,
                offset: startOffset + offset + len + 1,
              },
            },
          });
        }
        offset += indent + len + 1;
      }
    }

    const index = parent.children.indexOf(node);
    parent.children.splice(index, 1, ...nodes);
  }
}

export const cdxBreakPlugin = () => {
  return addBreaks;
};

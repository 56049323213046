import {createElement, Fragment} from "react";
import sortBy from "lodash/sortBy";

export function debounce(fn, ms) {
  let id = null;
  const debounced = (...args) => {
    if (id) clearTimeout(id);
    return new Promise((resolve) => {
      id = setTimeout(() => resolve(fn(...args)), ms);
    });
  };

  debounced.cancel = () => {
    if (id) clearTimeout(id);
  };

  return debounced;
}

// export function shallowEqualDebug(objA, objB) {
//   if (objA === objB) return true;

//   if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
//     console.log(typeof objA, "vs", typeof objB);
//     return false;
//   }

//   const keysA = Object.keys(objA);
//   const keysB = Object.keys(objB);

//   if (keysA.length !== keysB.length) return false;

//   // Test for A's keys different from B.
//   const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
//   for (let i = 0; i < keysA.length; i += 1) {
//     if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
//       if (!bHasOwnProperty(keysA[i])) {
//         console.log("b dont have", keysA[i]);
//       } else {
//         console.log(keysA[i], ":", objA[keysA[i]], "vs", objB[keysA[i]]);
//       }
//       return false;
//     }
//   }

//   return true;
// }

export function formatAsEur(amount) {
  return `€${(amount / 100).toFixed(2)}`;
}

const emptyArr = [];

export function unsortedProjectTags(project) {
  return project.tags.length > 0 && project.tags[0].$meta.isFieldPresent("tag", true)
    ? project.tags
    : emptyArr;
}

export function unsortedPersonalTags(user, account) {
  const personalTags2 =
    account.$meta.isLoaded && user ? user.$meta.find("tags", {accountId: account.id}) : emptyArr;
  return personalTags2.length > 0 && personalTags2[0].$meta.isFieldPresent("tag", true)
    ? personalTags2
    : emptyArr;
}

export function tagObjectsForUser(user, projects, account) {
  const tags = unsortedPersonalTags(user, account).map((t) => ({type: "personal", tagObject: t}));
  projects.forEach((project) =>
    tags.push(...unsortedProjectTags(project).map((t) => ({type: "project", tagObject: t})))
  );
  return sortBy(tags, (tO) => tO.tagObject.tag.toLowerCase());
}

export function personalTags(user, account) {
  return sortBy(unsortedPersonalTags(user, account), (t) => t.tag.toLowerCase());
}

/**
 * @param {import("../cdx-models/Project").Project} project
 * @returns {import("../cdx-models/ProjectTag").ProjectTag[]}
 */
export function projectTags(project) {
  return sortBy(unsortedProjectTags(project), (t) => t.tag.toLowerCase());
}

export function projectsTags(projects) {
  const list = projects.reduce((l, p) => {
    l.push(...unsortedProjectTags(p));
    return l;
  }, []);
  return sortBy(list, (t) => t.tag.toLowerCase());
}

export function accountRoles(account, {allowObservers} = {}) {
  return account.$meta.find("roles", {
    role: allowObservers ? ["owner", "admin", "guest", "observer"] : ["owner", "admin", "guest"],
  });
}

export function accountUsers(account, {allowObservers} = {}) {
  return accountRoles(account, {allowObservers}).map((r) => r.user);
}

export function projectsUsers(account, projects, {allowObservers} = {}) {
  const projectIds = projects.map((p) => p.$meta.get("id", null)).filter(Boolean);
  return account.$meta
    .find("roles", {
      $or: [
        {role: ["admin", "owner"]},
        {
          role: allowObservers ? ["guest", "observer"] : "guest",
          user: {withProjectAccess: {projectId: projectIds}},
        },
      ],
    })
    .map((r) => r.user);
}

export function projectDecks(p) {
  return p.$meta.find("decks", {isDeleted: false});
}

/**
 * @param {import("../cdx-models/Project").Project} projects
 * @returns {import ("../cdx-models/Deck").Deck[]}
 */
export function sortedProjectDecks(p) {
  return p.$meta.find("decks", {isDeleted: false, $order: "sortValue"});
}

export function projectsDecks({root, projectIds}) {
  return root.account.$meta.find("anyDecks", {projectId: projectIds, isDeleted: false});
}

const MOD_ADLER = 65521;

export function adler32(str) {
  var a = 1,
    b = 0;
  for (var i = 0, len = str.length; i < len; ++i) {
    a = (a + str.charCodeAt(i)) % MOD_ADLER;
    b = (b + a) % MOD_ADLER;
  }
  return (b << 16) | a;
}

export const joinComponents = (comps, joiner) => {
  const list = [];
  comps.forEach((c, i) => {
    list.push(c);
    if (i + 1 < comps.length) list.push(joiner);
  });
  return createElement(Fragment, {}, ...list);
};

/**
 * @template T
 * @param {T[]} list
 * @returns {string}
 */
export const joinAnd = (list) => {
  if (list.length === 0) return "";
  if (list.length === 1) return list[0];
  return [list.slice(0, -1).join(", "), list[list.length - 1]].join(" and ");
};

export const joinAndComponents = (list) => {
  if (list.length === 0) return "";
  if (list.length === 1) return list[0];
  const children = [list[0]];
  for (let i = 1; i < list.length - 1; i += 1) {
    children.push(", ", list[i]);
  }
  children.push(" and ", list[list.length - 1]);
  return createElement(Fragment, {}, ...children);
};

export const pluralize = (count, word, plural) => {
  return `${count} ${count === 1 ? word : plural || `${word}s`}`;
};

export const slugify = (input) =>
  (input || "")
    .replace(/[^\p{L}0-9]+/gu, " ")
    .trim()
    .replace(/ /g, "-")
    .toLowerCase();

const specialOrds = {11: "th", 12: "th", 13: "th"};
const singleDigitOrds = {1: "st", 2: "nd", 3: "rd"};

// convert to ordinal numbers:
export const ordinalSuffix = (num) => {
  return specialOrds[num % 100] || singleDigitOrds[num % 10] || "th";
};

export const toOrdinal = (num) => {
  return `${num}${ordinalSuffix(num)}`;
};

export const isSelectionActive = () => {
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    if (!(range.startContainer === range.endContainer && range.startOffset === range.endOffset)) {
      return true;
    }
  }
  return false;
};

// brify
export const joinByBr = (list) => {
  const lines = [];
  list.forEach((entry) => lines.push(entry, <br />));
  return createElement(Fragment, {}, ...lines.slice(0, -1));
};

export const toBigInt = (str) => {
  return str === null ? null : window.BigInt ? window.BigInt(str) : Number(str);
};

/**
 * @template TListItem
 * @template TEl
 * @param {TListItem[]} list
 * @param {TEl} el
 * @param {function(TEl, TListItem):number} cmpFn
 * @returns {number} index
 */
export const getIndexViaBinarySearch = (list, el, cmpFn) => {
  var m = 0;
  var n = list.length - 1;
  while (m <= n) {
    var k = (n + m) >> 1;
    var cmp = cmpFn(el, list[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return m;
};

import {visit} from "unist-util-visit";

export const externalLinkImagePlugin = () => {
  return (tree) => {
    visit(
      tree,
      (n) => n.tagName === "a",
      (n) => {
        (n.children || []).forEach((child) => {
          if (child.tagName === "img") {
            child.properties.isWithinLink = true;
            n.properties.className = "img-link";
          }
        });
      }
    );
  };
};

export const uniqueImageKeyPlugin = () => {
  return (tree) => {
    let key = 0;
    visit(
      tree,
      (n) => n.tagName === "img",
      (n) => {
        n.properties.imgKey = key += 1;
      }
    );
  };
};

export default externalLinkImagePlugin;

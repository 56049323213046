import {getScrollParents} from "@cdx/common";
import {useEffect, useRef} from "react";
import {create} from "zustand";

type Area = {
  bounds: DOMRect;
  reach: number;
  cardScale: number;
};

type Point = {x: number; y: number};

const useAreaStore = create<{ref: {areas: Area[]}}>(() => ({
  ref: {areas: []},
}));

const getAreaScale = ({x, y}: Point, area: Area): number => {
  const b = area.bounds;
  const dx = Math.max(b.left - x, 0, x - b.right);
  const dy = Math.max(b.top - y, 0, y - b.bottom);
  const dist = Math.sqrt(dx * dx + dy * dy);
  if (dist === 0) return area.cardScale;
  if (dist > area.reach) return 1;
  const r = 1 - dist / area.reach;
  return 1 - (1 - area.cardScale) * r;
};

export const getCardResizeFactor = (point: Point) => {
  const {areas} = useAreaStore.getState().ref;
  return Math.min(0.75, ...areas.map((area) => getAreaScale(point, area)));
};

export const useCardResizeArea = (args: {reach: number; cardScale: number; disabled?: boolean}) => {
  const {reach, cardScale, disabled} = args;
  const nodeRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (disabled) return;
    const node = nodeRef.current;
    if (!node) return;

    const myArea: Area = {
      bounds: node.getBoundingClientRect(),
      reach,
      cardScale,
    };
    useAreaStore.setState((prev) => {
      prev.ref.areas.push(myArea);
      return {ref: {areas: prev.ref.areas}};
    });

    const update = () => {
      myArea.bounds = node.getBoundingClientRect();
      useAreaStore.setState((prev) => ({ref: {areas: prev.ref.areas}}));
    };

    const ro = new ResizeObserver(update);
    ro.observe(node);
    window.addEventListener("resize", update);

    const scrollPanes = getScrollParents(node);
    scrollPanes.forEach((pane) => pane.addEventListener("scroll", update, {passive: true}));

    return () => {
      ro.disconnect();
      window.removeEventListener("resize", update);
      scrollPanes.forEach((pane) => pane.removeEventListener("scroll", update));
      useAreaStore.setState((prev) => {
        const idx = prev.ref.areas.indexOf(myArea);
        prev.ref.areas.splice(idx, 1);
        return {ref: {areas: prev.ref.areas}};
      });
    };
  }, [cardScale, reach, disabled]);
  return nodeRef;
};

export const cardResizeAreaStore = useAreaStore;

import UnMarkedown from "./Markdown/UnMarkedown";
import routes from "../routes";
import {XTextButton, XText, XRow, cx, XCol} from "@cdx/common";
import {miniDeckStyles as styles} from "./MiniDeck.css";
import publicProjectRoutes from "../features/public-project/public-project-routes";
import {CdxCropImgByFile} from "./CdxImg";
import uiClasses from "@cdx/common/xui/ui.css";

const InnerMiniDeck = ({maxChars, deck, onLight, className, nonBold, ...rest}) => (
  <XRow
    sp={0}
    className={cx(className, styles.container.base, deck.isDeleted && styles.container.isDeleted)}
    align="baseline"
    {...rest}
  >
    <XCol
      as={CdxCropImgByFile}
      width={18}
      height={18}
      file={deck.coverFile}
      elevation={1}
      noShrink
      rounded="sm"
      noOverflow
      border={onLight ? "gray100" : "gray500"}
      bg={onLight ? "gray300" : "gray700"}
      className={styles.coverImg}
      fallbackClassName={uiClasses.backgroundColor.gray500}
    />
    <XText
      as={UnMarkedown}
      noOverflow
      maxChars={maxChars}
      projectId={deck.project.id}
      preset={nonBold ? "body" : "bold"}
      color={onLight ? "gray700" : "gray100"}
      size={nonBold ? 1 : 2}
    >
      {deck.title}
    </XText>
  </XRow>
);

// Note: there's a more modern implementation within OpenCardTopArea
/** @deprecated use `MiniDeckButton` instead!? */
const MiniDeck = ({
  deck,
  asLink: rawAsLink,
  maxChars = 25,
  inline,
  style,
  onLight,
  nonBold,
  ...rest
}) => {
  if (!deck || deck.$meta.isDeleted()) {
    if (process.env.REACT_APP_MODE === "open") {
      return (
        <XText
          preset="bold"
          size={2}
          color={onLight ? "gray500" : "gray400"}
          style={{display: "inline-block"}}
        >
          some deck
        </XText>
      );
    } else {
      return (
        <XText
          preset="bold"
          size={2}
          color={onLight ? "gray500" : "gray400"}
          style={{textDecoration: "line-through", display: "inline-block"}}
        >
          Deleted deck
        </XText>
      );
    }
  }

  const asLink = !deck.isDeleted && rawAsLink;

  const finalStyle = {...style, ...(inline && {display: "inline-flex"})};

  return asLink ? (
    <XTextButton
      to={
        process.env.REACT_APP_MODE === "open"
          ? publicProjectRoutes.publicProjectWithDeck.getUrl(deck.project, deck)
          : routes.deck.getUrl(deck)
      }
      square
      style={finalStyle}
      {...rest}
    >
      <InnerMiniDeck maxChars={maxChars} deck={deck} onLight={onLight} nonBold={nonBold} />
    </XTextButton>
  ) : (
    <InnerMiniDeck
      maxChars={maxChars}
      deck={deck}
      onLight={onLight}
      {...rest}
      style={finalStyle}
      nonBold={nonBold}
    />
  );
};

export default MiniDeck;

import {useClickOutside, useGlobalKeyPress} from "@cdx/common";
import {Box, Col, DSButton, DSIconButton, Row, DSShortcutKey} from "@cdx/ds";
import {ReactNode, useState} from "react";
import dsStyles from "@cdx/ds/css/index.css";

type ListPickerProps = {
  onChange: (arg: any, mode: "enter" | "click" | "navigation") => unknown;
  pendingChanges?: any;
  confirmOnNavigation?: boolean;
};

export type TabDef<T> = {
  key: string;
  label: string;
  icon: ReactNode;
  cardProp: string;
  isShown: (opts: T) => boolean;
  component: (props: ListPickerProps & T) => ReactNode;
};

export type CardPropChangeOverlayLayoutProps<T> = {
  onChange: (data: any) => any;
  onClose: () => void;
  initialTab?: string | null;
  passProps: T;
  tabs: TabDef<T>[];
  getCurrentValue: (key: any) => any;
};

const CardPropChangeOverlayLayout = <T extends object>(
  props: CardPropChangeOverlayLayoutProps<T>
) => {
  const {onChange, onClose, initialTab, passProps, tabs: allTabs, getCurrentValue} = props;
  const [openTabKey, setOpenTabKey] = useState<string | null>(initialTab ?? null);
  const [pendingChanges, setPendingChanges] = useState<any>({});
  const handlers = useClickOutside(onClose, {strict: true, isDisabled: false});
  const tabs = allTabs.filter((t) => t.isShown(passProps));
  const currTabIdx = Math.max(
    0,
    tabs.findIndex((t) => t.key === openTabKey)
  );
  const currTab = tabs[currTabIdx];

  const handleConfirm = (changes: any) => {
    if (Object.keys(changes).length > 0) {
      onChange(changes);
    }
    onClose();
  };

  const handleChange = (updates: any, mode: "enter" | "click" | "navigation") => {
    const next = {...pendingChanges};
    for (const [key, value] of Object.entries(updates)) {
      if (value === getCurrentValue(key)) {
        delete next[key];
      } else {
        next[key] = value;
      }
    }
    if (mode === "enter" || mode === "click") {
      handleConfirm(next);
    } else {
      setPendingChanges(next);
    }
  };

  useGlobalKeyPress({
    code: "ArrowLeft",
    fn: () => setOpenTabKey(() => tabs[(currTabIdx + tabs.length - 1) % tabs.length].key),
    ignoreTarget: true,
  });
  useGlobalKeyPress({
    code: "ArrowRight",
    fn: () => setOpenTabKey(() => tabs[(currTabIdx + 1) % tabs.length].key),
    ignoreTarget: true,
  });

  return (
    <Col
      colorTheme="gray750"
      bg="foreground"
      rounded={4}
      overflow="hidden"
      elevation={200}
      width="cardPropChangeOverlay"
      {...handlers}
    >
      <Col sp="16px" px="16px" py="12px" style={{minHeight: 300}} overflow="hidden">
        <Row sp="8px" justify="spaceBetween" colorTheme="purpleTextOnDark">
          {tabs.map((tab) => (
            <Box relative key={tab.key}>
              <DSIconButton
                active={currTab.key === tab.key}
                icon={tab.icon}
                onClick={() => setOpenTabKey(tab.key)}
                title={tab.label}
                variant="tertiary"
                size="sm"
              />
              {tab.cardProp in pendingChanges && (
                <Box
                  absolute
                  top="0"
                  right="0"
                  style={{width: 10, height: 10, top: -4, right: -4}}
                  rounded="full"
                  colorTheme="active200"
                  bg="foreground"
                />
              )}
            </Box>
          ))}
        </Row>
        {currTab.component({
          ...passProps,
          pendingChanges,
          onChange: handleChange,
          confirmOnNavigation: true,
        })}
      </Col>
      <Box color="secondary" size={12} px="24px" py="4px">
        Use <DSShortcutKey size="sm">←</DSShortcutKey>
        <DSShortcutKey size="sm">↑</DSShortcutKey>
        <DSShortcutKey size="sm">↓</DSShortcutKey>
        <DSShortcutKey size="sm">→</DSShortcutKey> to navigate,
        <DSShortcutKey size="sm">Enter</DSShortcutKey> to{" "}
        <DSButton
          onClick={handleConfirm}
          variant="tertiary"
          size="sm"
          className={dsStyles.display["inline-block"]}
          negatePadding
        >
          confirm
        </DSButton>
        .
      </Box>
    </Col>
  );
};

export default CardPropChangeOverlayLayout;

import {forwardRef, useState} from "react";
import {WithLabel, XText, XCol, xcolors, XPlainButton, errorToString} from "@cdx/common";
import Uploader, {useUpload} from "./uploader";
import {api} from "../lib/api";
import {useInstance} from "../lib/mate/mate-utils";
import {CdxImgByFile} from "./CdxImg";

export const FileInput = forwardRef(
  ({value, onChange, id, affectsQuota, maxWidth = 300, maxHeight = 150, bg, textSize = 3}, ref) => {
    const [error, setError] = useState(null);

    const handleUpload = (fileData) => {
      setError(null);
      return api.mutate.files.create({fileData}).then(
        (data) => onChange(data.id),
        (e) => setError(errorToString(e))
      );
    };

    const {inputProps, dropAreaProps, ongoingUploads} = useUpload({
      id,
      affectsQuota,
      onUpload: handleUpload,
    });

    const file = useInstance("file", value);

    return (
      <XCol sp={2}>
        {error && (
          <XText size={1} color="error600" preset="bold">
            {error}
          </XText>
        )}
        {ongoingUploads.length > 0 && <Uploader.Uploads ongoingUploads={ongoingUploads} />}
        <XCol relative>
          {file ? (
            <XCol ref={ref} align="center">
              <XCol
                relative
                elevation={2}
                pa={0}
                rounded="md"
                align="center"
                justify="center"
                style={{minHeight: 100, minWidth: 100}}
                bg={bg || "white"}
              >
                <CdxImgByFile file={file} alt="" maxHeight={maxHeight} maxWidth={maxWidth} />
                <XCol absolute pa={1} style={{right: 0, top: 0}}>
                  <XPlainButton size="sm" onClick={() => onChange(null)}>
                    Remove
                  </XPlainButton>
                </XCol>
                <Uploader.DropArea {...dropAreaProps} label="Drop cover image here" />
              </XCol>
            </XCol>
          ) : (
            <XCol px={5} py={4} bg="gray200" rounded="md" relative>
              <Uploader.DropArea {...dropAreaProps} label="Drop cover image here" />
              <XText color="gray500" preset="bold" align="center" size={textSize}>
                Drop Image or{" "}
                <label style={{color: xcolors.active600, cursor: "pointer"}}>
                  select file <input {...inputProps} style={{display: "none"}} ref={ref} />
                </label>
              </XText>
            </XCol>
          )}
        </XCol>
      </XCol>
    );
  }
);

export const FileInputWithLabel = forwardRef(
  ({root, showErrors, name, label, hint, hasPendingValidation, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <FileInput ref={ref} id={name} {...rest} />
    </WithLabel>
  )
);

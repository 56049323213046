import {useEffect, useRef, useState} from "react";

type Info = {
  node: HTMLElement | null;
  disabled: boolean;
  rootMargin: string;
  seenOnce: boolean;
  unsub: null | (() => void);
  debug?: boolean;
};

const doIt = (info: Info, setSeenOnce: React.Dispatch<boolean>) => {
  const {disabled, node, rootMargin, seenOnce, unsub} = info;
  if (unsub) {
    unsub();
    info.unsub = null;
  }
  if (!node || !IntersectionObserver || disabled || seenOnce) return;

  let timeoutId: null | ReturnType<typeof setTimeout> = null;

  const handleIntersect: IntersectionObserverCallback = ([e1]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (e1.rootBounds === null) {
      // Somehow Chrome sets this to null if a card moves :/
      return;
    }
    if (e1.isIntersecting || e1.rootBounds.width === 0) {
      // e1.rootBounds.width === 0 seems to happen when doing flip animations in Chrome
      setSeenOnce(true);
    } else {
      const initalViewport = e1.rootBounds;
      if (initalViewport) {
        // known issue: Callback won't fire when node initially not visible
        // but initial flip animation moves it into viewport
        // good test case: "Released deck" order Prio asc, "Infrastructure" deck order Prio desc, if `getArenaContentKey` is not used as `key`

        timeoutId = setTimeout(() => {
          const rect = info.node?.getBoundingClientRect();
          if (!rect) return;
          if (rect.bottom > initalViewport.top && rect.top < initalViewport.bottom) {
            setSeenOnce(true);
          }
        }, 500);
      }
    }
  };

  const observer = new IntersectionObserver(handleIntersect, {
    // "root: null" doesn't work in iframes!?
    root: document,
    rootMargin,
    threshold: [0],
  });
  observer.observe(node);
  info.unsub = () => {
    observer.disconnect();
    info.unsub = null;
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };
};

export const useIsInViewport = (opts: {rootMargin?: string; disabled?: boolean} = {}) => {
  const {rootMargin = "100px", disabled = false} = opts;
  const [seenOnce, setSeenOnce] = useState(IntersectionObserver && !disabled ? false : true);

  const refs = useRef<Info>({node: null, disabled, rootMargin, seenOnce, unsub: null});

  // we're using a callback-based ref here because useRef based ones don't fire, and using
  // a useState-based ref ([node, setNode] = useState()) causes a re-render which we want to avoid
  const [nodeRef] = useState(() => {
    return (node: HTMLElement | null) => {
      refs.current.node = node;
      doIt(refs.current, setSeenOnce);
    };
  });

  useEffect(() => {
    refs.current = {...refs.current, rootMargin, disabled, seenOnce};
    doIt(refs.current, setSeenOnce);
    return () => {
      if (refs.current.unsub) refs.current.unsub();
    };
  }, [nodeRef, rootMargin, disabled, seenOnce]);

  return [nodeRef, seenOnce];
};

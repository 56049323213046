import {cx, TooltipForChild, uiStyles, Document as RawDocument} from "@cdx/common";
import {Box, Row} from "@cdx/ds";
import {iconStyles} from "@cdx/ds/components/DSIcon/DSIcon.css";
import {pluralize} from "../../lib/utils";
import {getDoneCardCountThisWeek} from "./DonePile";
import {streakIconStyles} from "./hand-queue.css";
import {ReactComponent as FireSvg} from "./imgs/fire.svg";

const Document = RawDocument as any;

const StreakIcon = ({root, meId, className}: {root: any; meId: string; className?: string}) => {
  const achs = root.account.$meta.find("accountUserAchievements", {
    userId: meId,
    key: "week_done_card_streak",
  });
  const ach = achs[0];
  const doneCardsThisWeek = getDoneCardCountThisWeek({root, userId: meId});
  const value = (ach ? Number(ach.$meta.get("value", "0")) : 0) + (doneCardsThisWeek ? 1 : 0);
  const getTooltip = () => {
    const longestStreakLength = (ach && ach.context.longest_streak?.length) || 0;
    return (
      <Box px={3} py={2} maxWidth="20rem">
        <Document variant="onDark" size="md">
          <p>
            Increase your streak every week by setting at least one assigned card to done. Streaks
            are never reset if you are on a paid plan.
          </p>
          {value > 0 && (
            <p>
              Current Streak: <b>{pluralize(value, "week")}</b>
            </p>
          )}
          {longestStreakLength > 1 && (
            <p>
              Longest Streak: <b>{pluralize(longestStreakLength, "week")}</b>
            </p>
          )}
        </Document>
      </Box>
    );
  };

  return (
    <TooltipForChild tooltip={getTooltip}>
      <Row sp={0} align="center" className={className}>
        <FireSvg
          className={cx(
            iconStyles.mode.block,
            iconStyles.base,
            iconStyles.sizes[20],
            doneCardsThisWeek ? streakIconStyles.active : streakIconStyles.inactive
          )}
        />
        {value > 0 && (
          <Box bold size={12} className={(uiStyles.color as any).gray300}>
            {value}
          </Box>
        )}
      </Row>
    </TooltipForChild>
  );
};

export default StreakIcon;

import remark2rehype from "remark-rehype";
import rehype2react from "rehype-react";
import {cdxTextCompiler} from "../markdownToTextCompiler";
import {cdxPreviewCompiler} from "../markdownToPreviewCompiler";

const nameToModule = {
  remarkRehype: remark2rehype,
  rehypeReact: rehype2react,
  cdxTextCompiler,
  cdxPreviewCompiler,
};

export const setAttacherOptions = (instance, name, cb) => {
  const mod = nameToModule[name];
  if (!mod) {
    console.warn(`attacher '${name}' has no module set up`);
    return;
  }
  const attacher = (instance.attachers || []).find((a) => a[0] === mod);
  if (attacher) {
    if (attacher.length === 1) attacher.push({});
    attacher[1] = cb(attacher[1]);
  }
};

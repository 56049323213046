import {forwardRef} from "react";
import {Link} from "react-router-dom";

const LinkOrButton = forwardRef<HTMLElement, any>(({forwardedRef, disabled, ...rest}, ref) => {
  if (rest.as) {
    const Comp = rest.as;
    return <Comp {...rest} disabled={disabled} ref={ref} />;
  } else if (rest.to !== undefined) {
    const props = {
      innerRef: ref,
      ...rest,
      ...(disabled ? {onClick: (e: MouseEvent) => e.preventDefault()} : {}),
    };
    return <Link {...props} />;
  } else if ("href" in rest) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} {...rest} />;
  } else {
    return <button ref={ref} disabled={disabled} type="button" {...rest} />;
  }
});

export default LinkOrButton;

import {setAttacherOptions} from "./plugin-helpers";

export default function verbatimHtmlRehypePlugin() {
  setAttacherOptions(this, "remarkRehype", (opts) => ({
    ...opts,
    handlers: {
      ...opts.handlers,
      html: (state, node) => ({
        type: "element",
        tagName: "span",
        properties: {},
        children: [{type: "text", value: node.value}],
      }),
    },
  }));
}

import {ReactNode} from "react";
import {Activity} from "../../../cdx-models/Activity";
import {
  Box,
  Col,
  css,
  DSIconArchive,
  DSIconAttachment,
  DSIconBell,
  DSIconCheck,
  DSIconCrown,
  DSIconDeck,
  DSIconDoc,
  DSIconMilestone,
  DSIconPlay,
  DSIconPlus,
  DSIconProperties,
  DSIconPyramid,
  DSIconSnoozing,
  DSIconSprint,
  DSIconStar,
  DSIconTag,
  DSIconTrash,
  DSIconUser,
  Row,
} from "@cdx/ds";
import {CardLink, FeedEntryComp} from "../shared";
import {ArenaCtx} from "../../card-panel/card-panel-utils";
import {Deck, DeckId} from "../../../cdx-models/Deck";
import {cardUpdateStyles as styles} from "../activity-feed.css";
import {MiniDeckButton} from "../../card-container/card-container-utils";
import routes from "../../../routes";
import {Milestone, MilestoneId} from "../../../cdx-models/Milestone";
import {api} from "../../../lib/api";
import {
  getMilestoneState,
  setMsThemeColor,
  ThemedMilestoneIcon,
} from "../../milestones/milestone-utils";
import {Root} from "../../../cdx-models/Root";
import {Sprint, SprintId} from "../../../cdx-models/Sprint";
import {getSprintState, sprintLabel, ThemedSprintIcon} from "../../milestones/sprint-utils";
import {Card, CardId} from "../../../cdx-models/Card";
import {joinAndComponents, joinComponents} from "../../../lib/utils";
import {IconGateClosed, shrinker} from "@cdx/common";
import {PriorityIconWithTitleProp} from "../../../components/props";
import {User, UserId} from "../../../cdx-models/User";
import DSAvatar from "../../../components/DSAvatar/DSAvatar";
import {TagStyle} from "../../../components/Markdown/CardTags";
import {AttachmentId} from "../../../cdx-models/Attachment";
import {useInstance} from "../../../lib/mate/mate-utils";
import {useModalAdress} from "../../../components/ModalRegistry";
import {canDisplayAsImage} from "../../../lib/file-utils";
import {Link} from "react-router-dom";
import {CdxImgByFile} from "../../../components/CdxImg";
import {superShortDateWithWeekdayIfThisYear} from "../../../lib/date-utils";
import {File, FileId} from "../../../cdx-models/File";

type CardLineProps = {
  card: any;
  children: ReactNode;
  icon: ReactNode;
  arenaCtx: ArenaCtx;
};
export const CardLine = (props: CardLineProps) => {
  const {card, children, icon, arenaCtx} = props;
  return (
    <Row sp="16px" align="start">
      <Col align="center" width="16px" relative top="2px">
        {icon}
      </Col>
      <div>
        <CardLink card={card} maxChars={64} inline arenaCtx={arenaCtx} /> {children}
      </div>
    </Row>
  );
};

const MovedDeck = (props: {values: DeckId[]; arenaCtx: ArenaCtx; firstItem: Activity}) => {
  const {values, arenaCtx, firstItem} = props;
  const decks: Deck[] = values.map((id) => api.getModel({modelName: "deck", id})).reverse();
  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconDeck size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      <span>
        moved to{" "}
        <Box display="inline-flex" relative top="2px" pl="4px" sp="4px">
          {decks.map((deck) => (
            <MiniDeckButton
              buttonProps={{to: routes.deck.getUrl(deck)}}
              deck={deck}
              key={deck.id}
              maxChars={24}
            />
          ))}
        </Box>
      </span>
    </CardLine>
  );
};

const MsTile = ({ms, root}: {ms: Milestone | null; root: Root}) =>
  ms ? (
    <span>
      <ThemedMilestoneIcon
        theme={setMsThemeColor(ms)}
        size={16}
        inline
        milestoneState={getMilestoneState(root.account, ms)}
      />
      <b> {ms.name}</b>
    </span>
  ) : (
    <>no Milestone</>
  );

const MovedMilestone = (props: {
  values: [MilestoneId | null, MilestoneId | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
  root: Root;
}) => {
  const {values, arenaCtx, firstItem, root} = props;
  const firstId = values[values.length - 1][0];
  const firstMilestone: Milestone | null = api.getModel({modelName: "milestone", id: firstId});
  const targets: (Milestone | null)[] = values
    .map(([, next]) => api.getModel({modelName: "milestone", id: next}))
    .reverse();
  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconMilestone size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      <span>
        {firstMilestone ? (
          <>
            moved from <MsTile ms={firstMilestone} root={root} />
          </>
        ) : (
          <>assigned</>
        )}
        {` to `}
        <Box display="inline-flex" sp="4px">
          {joinComponents(
            targets.map((target, idx) => (
              <MsTile ms={target} root={root} key={target?.$meta.get("id", idx) || idx} />
            )),
            " "
          )}
        </Box>
      </span>
    </CardLine>
  );
};

const SprintTile = ({sprint, root}: {sprint: Sprint | null; root: Root}) =>
  sprint ? (
    <span>
      <ThemedSprintIcon
        theme={setMsThemeColor(sprint.sprintConfig)}
        size={16}
        inline
        sprintState={getSprintState(sprint)}
      />
      <b> {sprintLabel(sprint)}</b>
    </span>
  ) : (
    <>no Run</>
  );

const MovedSprint = (props: {
  values: [SprintId | null, SprintId | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
  root: Root;
}) => {
  const {values, arenaCtx, firstItem, root} = props;
  const firstId = values[values.length - 1][0];
  const firstSprint: Sprint | null = api.getModel({modelName: "sprint", id: firstId});
  const targets: (Sprint | null)[] = values
    .map(([, next]) => api.getModel({modelName: "sprint", id: next}))
    .reverse();
  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconSprint size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      <span>
        {firstSprint ? (
          <>
            moved from <SprintTile sprint={firstSprint} root={root} />
          </>
        ) : (
          <>assigned</>
        )}
        {` to `}
        <Box display="inline-flex" sp="4px">
          {joinComponents(
            targets.map((target, idx) => (
              <SprintTile sprint={target} root={root} key={target?.$meta.get("id", idx) || idx} />
            )),
            " "
          )}
        </Box>
      </span>
    </CardLine>
  );
};

const CreatedCard = ({firstItem, arenaCtx}: {firstItem: Activity; arenaCtx: ArenaCtx}) => (
  <CardLine
    card={firstItem.card}
    icon={<DSIconPlus size={16} className={styles.iconActive} />}
    arenaCtx={arenaCtx}
  >
    was created
  </CardLine>
);

const getCardList = (cardIds: CardId[]) =>
  joinComponents(
    cardIds.map((id) => <CardLink key={id} card={api.getModel({modelName: "card", id})} />),
    " "
  );

const ChildCards = (props: {
  values: {"+": CardId[]; "-": CardId[]}[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const added = new Set(values.flatMap((v) => v["+"]));
  const removed = new Set(values.flatMap((v) => v["-"]));
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconCrown
          size={16}
          className={added.size === 0 ? styles.iconInactive : styles.iconActive}
        />
      }
      arenaCtx={arenaCtx}
    >
      {added.size > 0 && <span>added {getCardList([...added])} as Child Card</span>}
      {removed.size > 0 && (added.size > 0 ? " and removed " : "removed ")}
      {removed.size > 0 && <span>{getCardList([...removed])} as Child Card</span>}
    </CardLine>
  );
};

const DepsCards = (props: {
  values: {"+": CardId[]; "-": CardId[]}[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const added = new Set(values.flatMap((v) => v["+"]));
  const removed = new Set(values.flatMap((v) => v["-"]));
  return (
    <CardLine
      card={firstItem.card}
      icon={<IconGateClosed size="lg" strokeColor={added.size > 0 ? "gray700" : "gray400"} />}
      arenaCtx={arenaCtx}
    >
      {added.size > 0 && <span>added {getCardList([...added])} as locking Card</span>}
      {removed.size > 0 && (added.size > 0 ? " and removed " : "removed ")}
      {removed.size > 0 && <span>{getCardList([...removed])} as locking Card</span>}
    </CardLine>
  );
};

const ParentCard = (props: {
  values: [CardId | null, CardId | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const firstId = values[values.length - 1][0];
  const firstParent: Card | null = api.getModel({modelName: "card", id: firstId});
  const targets: (Card | null)[] = values
    .map(([, next]) => api.getModel({modelName: "card", id: next}))
    .reverse();
  const lastIsNone = targets[targets.length - 1] === null;
  const setToNone = firstParent && targets.length === 1 && targets[0] === null;
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconCrown size={16} className={lastIsNone ? styles.iconInactive : styles.iconActive} />
      }
      arenaCtx={arenaCtx}
    >
      {setToNone ? (
        <>
          removed <CardLink card={firstParent} /> as Parent Card
        </>
      ) : (
        <>
          {firstParent ? (
            <>
              changed Parent Card from{" "}
              <CardLink card={firstParent} maxChars={64} inline arenaCtx={arenaCtx} /> to{" "}
            </>
          ) : (
            <>set Parent Card to </>
          )}
          <Box display="inline-flex" sp="4px">
            {joinComponents(
              targets.map((target, idx) =>
                target ? (
                  <CardLink
                    card={target}
                    key={target?.$meta.get("cardId", idx) || idx}
                    maxChars={64}
                    inline
                    arenaCtx={arenaCtx}
                  />
                ) : (
                  "No Card"
                )
              ),
              " "
            )}
          </Box>
        </>
      )}
    </CardLine>
  );
};

const ChangedOwner = (props: {
  values: [UserId | null, UserId | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const firstId = values[values.length - 1][0];
  const firstUser: User | null = api.getModel({modelName: "user", id: firstId});
  const targets: (User | null)[] = values
    .map(([, next]) => api.getModel({modelName: "user", id: next}))
    .reverse();
  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconUser size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      <Box display="inline-flex" sp="4px" align="center">
        {firstUser ? (
          <>
            <span>assigned from</span>
            <DSAvatar flat user={firstUser} size={20} />
          </>
        ) : (
          <span>assigned</span>
        )}
        <span>to</span>
        {targets.map((target, idx) => (
          <DSAvatar size={20} flat user={target} key={target?.$meta.get("id", idx) || idx} />
        ))}
      </Box>
    </CardLine>
  );
};

type CardProp =
  | {title: true}
  | {content: true}
  | {priority: Card["priority"]}
  | {effort: number | null};
const CardProps = (props: {
  values: CardProp[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
  root: Root;
}) => {
  const {values, arenaCtx, firstItem, root} = props;
  const revValues = [...values];
  const prios = new Set(revValues.filter((v) => "priority" in v).map((v) => v.priority));
  const efforts = new Set(revValues.filter((v) => "effort" in v).map((v) => v.effort));
  const changedTitle = revValues.some((v) => "title" in v);
  const changedContent = revValues.some((v) => "content" in v);

  const getPrios = () => {
    if (prios.size === 0) return null;
    return (
      <>
        Priority set to{" "}
        {joinComponents(
          [...prios]
            .reverse()
            .map((prio) => (
              <PriorityIconWithTitleProp priority={prio} root={root} inline size={16} />
            )),
          ", "
        )}
      </>
    );
  };
  const getEfforts = () => {
    if (efforts.size === 0) return null;
    return (
      <>
        Effort set to{" "}
        {[...efforts]
          .reverse()
          .map((eff) => (eff === null ? "None" : `${eff}`))
          .join(", ")}
      </>
    );
  };
  const title = changedTitle ? <>title changed</> : null;
  const content = changedContent ? <>content changed</> : null;

  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconProperties size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      {joinComponents([getPrios(), getEfforts(), title, content].filter(Boolean), " and ")}
    </CardLine>
  );
};

const getTagList = (tags: string[]) =>
  joinComponents(
    tags.map((t) => <TagStyle type="master">#{t}</TagStyle>),
    " "
  );

const Tags = (props: {
  values: {"+": string[]; "-": string[]}[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const added = new Set(values.flatMap((v) => v["+"]));
  const removed = new Set(values.flatMap((v) => v["-"]));
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconTag
          size={16}
          className={added.size === 0 ? styles.iconInactive : styles.iconActive}
        />
      }
      arenaCtx={arenaCtx}
    >
      {added.size > 0 && <span>added {getTagList([...added])}</span>}
      {removed.size > 0 && (added.size > 0 ? " and removed " : "removed ")}
      {removed.size > 0 && <span>{getTagList([...removed])}</span>}
    </CardLine>
  );
};

const Attachment = ({id}: {id: AttachmentId}) => {
  const attachment = useInstance("attachment", id);
  const url = !attachment.$meta.isDeleted() && attachment.file.$meta.get("url", null);
  const {width, height} = attachment.file.meta || {};
  const to = useModalAdress({
    modal: "imageViewer",
    state: {url, width, height, title: attachment.title},
  });
  return url && canDisplayAsImage(attachment.file) ? (
    <Link to={to} style={{display: "inline-block", marginTop: -10, position: "relative", top: 4}}>
      <CdxImgByFile
        className={css({rounded: 4, bg: "foreground"})}
        imgClassName={css({maxWidth: "100%"})}
        file={attachment.file}
        maxHeight={20}
        alt={attachment.title}
      />
    </Link>
  ) : (
    <Box as="code" wordBreak="break-all" size={14}>
      {shrinker(attachment.title, 24)}
    </Box>
  );
};

const getAttachmentList = (ids: AttachmentId[]) =>
  joinComponents(
    ids.map((id) => <Attachment id={id} />),
    " "
  );

const Attachments = (props: {
  values: {"+": AttachmentId[]; "-": AttachmentId[]}[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const added = new Set(values.flatMap((v) => v["+"]));
  const removed = new Set(values.flatMap((v) => v["-"]));
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconAttachment
          size={16}
          className={added.size === 0 ? styles.iconInactive : styles.iconActive}
        />
      }
      arenaCtx={arenaCtx}
    >
      {added.size > 0 && <span>attached {getAttachmentList([...added])}</span>}
      {removed.size > 0 && (added.size > 0 ? " and removed " : "removed ")}
      {removed.size > 0 && <span>{getAttachmentList([...removed])}</span>}
    </CardLine>
  );
};

const actionMap: Record<string, {label: string; icon: ReactNode}> = {
  started: {label: "started", icon: <DSIconPlay size={16} className={styles.iconActive} />},
  done: {label: "set to done", icon: <DSIconCheck size={16} className={styles.iconActive} />},
  archived: {label: "archived", icon: <DSIconArchive size={16} className={styles.iconActive} />},
  deleted: {label: "deleted", icon: <DSIconTrash size={16} className={styles.iconActive} />},
  resurrected: {
    label: "resurrected",
    icon: <DSIconTrash size={16} className={styles.iconInactive} />,
  },
  unarchived: {
    label: "unarchived",
    icon: <DSIconArchive size={16} className={styles.iconInactive} />,
  },
  unStarted: {
    label: "stopped working on this card",
    icon: <DSIconPlay size={16} className={styles.iconInactive} />,
  },
  unDone: {
    label: "set as not done",
    icon: <DSIconCheck size={16} className={styles.iconInactive} />,
  },
  unsnooze: {
    label: "unsnoozed",
    icon: <DSIconSnoozing size={16} className={styles.iconInactive} />,
  },
  snoozing: {
    label: "set to snoozing",
    icon: <DSIconSnoozing size={16} className={styles.iconActive} />,
  },
};

const Status = (props: {
  values: (
    | ["status", Card["status"], Card["status"]]
    | ["visibility", Card["visibility"], Card["visibility"]]
  )[];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const actions = values
    .map(([key, prev, next]) => {
      if (key === "status") {
        if (prev === "snoozing") return "unsnooze";
        if (next === "started") return "started";
        if (prev === "done") return "unDone";
        if (next === "not_started") return "unStarted";
        return next;
      } else {
        if (next === "deleted") return "deleted";
        if (next === "archived") return "archived";
        if (prev === "deleted") return "resurrected";
        if (prev === "archived") return "unarchived";
        return next;
      }
    })
    .reverse();
  return (
    <CardLine
      card={firstItem.card}
      icon={
        actionMap[actions[actions.length - 1]].icon || (
          <DSIconPlay size={16} className={styles.iconActive} />
        )
      }
      arenaCtx={arenaCtx}
    >
      {actions[0] === "unStarted" ? "" : "was "}
      {joinAndComponents(actions.map((a) => actionMap[a].label || a))}
    </CardLine>
  );
};

const DueDate = (props: {
  values: [string | null, string | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const targets: (Date | null)[] = values
    .map(([, next]) => (next ? new Date(next) : null))
    .reverse();
  const lastIsNone = targets[targets.length - 1] === null;
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconBell size={16} className={lastIsNone ? styles.iconInactive : styles.iconActive} />
      }
      arenaCtx={arenaCtx}
    >
      set Due Date to{" "}
      {joinComponents(
        targets.map((target) => (target ? superShortDateWithWeekdayIfThisYear(target) : "none"))
      )}
    </CardLine>
  );
};

const DocCard = (props: {
  values: [boolean, boolean][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const targets: boolean[] = values.map(([, next]) => next).reverse();
  const lastIsNone = targets[targets.length - 1] === null;
  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconDoc size={16} className={lastIsNone ? styles.iconInactive : styles.iconActive} />
      }
      arenaCtx={arenaCtx}
    >
      {targets
        .map((target) => (target ? "set Doc card state " : "unset Doc card state"))
        .join(" and ")}
    </CardLine>
  );
};

const FileComp = ({id}: {id: FileId}) => {
  const file: File = useInstance("file", id);
  const url = file?.$meta.get("url", null);
  const {width, height} = file.meta || ({} as any);
  const to = useModalAdress({
    modal: "imageViewer",
    state: {url, width, height, title: file.name},
  });
  return url && canDisplayAsImage(file) ? (
    <Link to={to} style={{display: "inline-block", marginTop: -10, position: "relative", top: 4}}>
      <CdxImgByFile
        className={css({rounded: 4, bg: "foreground"})}
        imgClassName={css({maxWidth: "100%"})}
        file={file}
        maxHeight={20}
        alt={file.name}
      />
    </Link>
  ) : (
    <Box as="code" wordBreak="break-all" size={14}>
      {shrinker(file.name, 24)}
    </Box>
  );
};

const CoverFile = (props: {
  values: [FileId | null, FileId | null][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const targets: (FileId | null)[] = values.map(([, next]) => next).reverse();
  const lastIsNone = targets[targets.length - 1] === null;

  return (
    <CardLine
      card={firstItem.card}
      icon={
        <DSIconStar size={16} className={lastIsNone ? styles.iconInactive : styles.iconActive} />
      }
      arenaCtx={arenaCtx}
    >
      {targets.length === 1 && lastIsNone ? (
        "removed cover image"
      ) : (
        <>
          set cover image to{" "}
          {joinComponents(
            targets.map((target) => (target ? <FileComp id={target} /> : "none")),
            " "
          )}
        </>
      )}
    </CardLine>
  );
};

const OrderLabelChange = (props: {
  values: [prev: string | null, next: string | null, type: string][];
  arenaCtx: ArenaCtx;
  firstItem: Activity;
}) => {
  const {values, arenaCtx, firstItem} = props;
  const first = values[values.length - 1][0];
  const targets: (string | null)[] = values.map(([, next]) => next).reverse();

  return (
    <CardLine
      card={firstItem.card}
      icon={<DSIconPyramid size={16} className={styles.iconActive} />}
      arenaCtx={arenaCtx}
    >
      updated Zone from <b>{first || "Default Zone"}</b> to{" "}
      {joinComponents(
        targets.map((t) => <b>{t || "Default Zone"}</b>),
        ", "
      )}
    </CardLine>
  );
};

const Unknown = ({firstItem, arenaCtx}: {firstItem: Activity; arenaCtx: ArenaCtx}) => (
  <CardLine
    card={firstItem.card}
    icon={<DSIconPlus size={16} className={styles.iconActive} />}
    arenaCtx={arenaCtx}
  >
    was updated
  </CardLine>
);

type Data = {
  version: number;
  diff: any & {orderLabelChange: [prev: string | null, next: string | null, type: string]};
};

const createInfo = <T extends any>(opts: {key: string; val: T; Comp: FeedEntryComp<T>}) => opts;
export const getInnerCardUpdateInfo = (
  rawItem: Activity
): {key: string; val: any; Comp: FeedEntryComp} => {
  const item = rawItem as Omit<Activity, "data"> & {data: Data};
  if (item.isRemovedFromDeckEntry) {
    return createInfo({
      key: "deck",
      val: item.data.diff.deckId[1] as DeckId,
      Comp: MovedDeck,
    });
  } else if (item.isRemovedFromMilestoneEntry) {
    return createInfo({
      key: "milestone",
      val: item.data.diff.milestoneId as [MilestoneId, MilestoneId | null],
      Comp: MovedMilestone,
    });
  } else if (item.isRemovedFromSprintEntry) {
    return createInfo({
      key: "sprint",
      val: item.data.diff.sprintId as [SprintId, SprintId | null],
      Comp: MovedSprint,
    });
  } else if (item.data?.version === 1) {
    return createInfo({
      key: "creation",
      val: null,
      Comp: CreatedCard,
    });
  } else if (item.data.diff) {
    const diff = item.data.diff;
    if ("childCards" in diff) {
      return createInfo({
        key: "childCards",
        val: diff.childCards as {"+": CardId[]; "-": CardId[]},
        Comp: ChildCards,
      });
    } else if ("inDeps" in diff) {
      return createInfo({
        key: "deps",
        val: diff.inDeps as {"+": CardId[]; "-": CardId[]},
        Comp: DepsCards,
      });
    } else if ("parentCardId" in diff) {
      return createInfo({
        key: "parentCard",
        val: diff.parentCardId as [CardId | null, CardId | null],
        Comp: ParentCard,
      });
    } else if ("isDoc" in diff) {
      return createInfo({
        key: "doc",
        val: diff.isDoc as [boolean, boolean],
        Comp: DocCard,
      });
    } else if ("deckId" in diff) {
      return createInfo({
        key: "deck",
        val: diff.deckId[1] as DeckId,
        Comp: MovedDeck,
      });
    } else if ("milestoneId" in diff) {
      return createInfo({
        key: "milestone",
        val: diff.milestoneId as [MilestoneId | null, MilestoneId | null],
        Comp: MovedMilestone,
      });
    } else if ("sprintId" in diff) {
      return createInfo({
        key: "sprint",
        val: diff.sprintId as [SprintId | null, SprintId | null],
        Comp: MovedSprint,
      });
    } else if ("assigneeId" in diff) {
      return createInfo({
        key: "owner",
        val: diff.assigneeId as [UserId | null, UserId | null],
        Comp: ChangedOwner,
      });
    } else if ("title" in diff) {
      return createInfo({
        key: "props",
        val: {title: true},
        Comp: CardProps,
      });
    } else if ("content" in diff) {
      return createInfo({
        key: "props",
        val: {content: true},
        Comp: CardProps,
      });
    } else if ("priority" in diff) {
      return createInfo({
        key: "props",
        val: {priority: diff.priority[1]},
        Comp: CardProps,
      });
    } else if ("effort" in diff) {
      return createInfo({
        key: "props",
        val: {effort: diff.effort[1]},
        Comp: CardProps,
      });
    } else if ("masterTags" in diff) {
      return createInfo({
        key: "tags",
        val: diff.masterTags as {"+": string[]; "-": string[]},
        Comp: Tags,
      });
    } else if ("attachments" in diff) {
      return createInfo({
        key: "attachments",
        val: diff.attachments as {"+": AttachmentId[]; "-": AttachmentId[]},
        Comp: Attachments,
      });
    } else if ("status" in diff) {
      return createInfo({
        key: "status",
        val: ["status", ...diff.status] as ["status", Card["status"], Card["status"]],
        Comp: Status,
      });
    } else if ("visibility" in diff) {
      return createInfo({
        key: "status",
        val: ["visibility", ...diff.visibility] as [
          "visibility",
          Card["visibility"],
          Card["visibility"],
        ],
        Comp: Status,
      });
    } else if ("dueDate" in diff) {
      return createInfo({
        key: "dueDate",
        val: diff.dueDate as [string | null, string | null],
        Comp: DueDate,
      });
    } else if ("coverFileId" in diff) {
      return createInfo({
        key: "coverFile",
        val: diff.coverFileId as [FileId | null, FileId | null],
        Comp: CoverFile,
      });
    } else if ("orderLabelChange" in diff) {
      return createInfo({
        key: "orderLabel",
        val: diff.orderLabelChange as [prev: string | null, next: string | null, type: string],
        Comp: OrderLabelChange,
      });
    }
  }

  return {
    key: "unknown",
    val: item.data.diff,
    Comp: Unknown,
  };
};

export const getFeedCardUpdateInfo = (
  item: Activity
): {key: string; val: any; isComment: boolean; Comp: FeedEntryComp} => {
  const {Comp, key, val} = getInnerCardUpdateInfo(item);
  return {
    key: `${item.card.$meta.get("cardId", null)}-${key}`,
    isComment: false,
    Comp,
    val,
  };
};

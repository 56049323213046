import {Box, Col, css} from "../Box/Box";
import {DSInputStyles as styles} from "./DSInput.css";
import cx from "../../utils/cx";
import {ComponentProps, forwardRef, MouseEventHandler, ReactNode, useMemo} from "react";
import dsStyles from "../../css/index.css";
import {DSIconChevronDown} from "../DSIcon/DSIcon";
import RawTextarea from "react-textarea-autosize";
import {useGetNodeRefFromRef} from "@cdx/common";

type DSBaseInput<T> = {
  onChange?: (value: T) => unknown;
  hasError?: boolean;
  size?: keyof (typeof styles)["size"];
};

export type DSInputProps = Omit<JSX.IntrinsicElements["input"], "onChange" | "size"> &
  DSBaseInput<string>;

export const DSInput = forwardRef<HTMLInputElement, DSInputProps>((props, ref) => {
  const {onChange, type = "text", value, hasError, className, size = "md", ...rest} = props;
  return (
    <input
      type={type}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      value={value}
      className={cx(
        className,
        styles.root,
        styles.sharedBase,
        styles.inputBase,
        styles.size[size],
        hasError && styles.hasError,
        !rest.disabled && Boolean(value) && styles.withValue
      )}
      ref={ref}
      {...rest}
    />
  );
});

export type DSInputWithPostfixProps = DSInputProps & {postfix: ReactNode; inputClassname?: string};
export const DSInputWithPostfix = forwardRef<HTMLInputElement, DSInputWithPostfixProps>(
  (props, passedRef) => {
    const {
      onChange,
      type = "text",
      value,
      hasError,
      className,
      inputClassname,
      size = "md",
      postfix,
      ...rest
    } = props;
    const {nodeRef, ref} = useGetNodeRefFromRef(passedRef);
    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
      if (!nodeRef.current) return;
      console.log(e.target === nodeRef.current);
      if (e.target === nodeRef.current) return;
      nodeRef.current.focus();
    };
    return (
      <div
        onClick={handleClick}
        className={css(
          {sp: "4px", display: "flex"},
          className,
          styles.root,
          styles.sharedBase,
          styles.inputBase,
          styles.size[size],
          styles.wrappedInput,
          hasError && styles.hasError,
          !rest.disabled && Boolean(value) && styles.withValue
        )}
      >
        <input
          type={type}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
          value={value}
          className={cx(styles.inlineInput, inputClassname)}
          ref={ref}
          {...rest}
        />
        <Box color="secondary">{postfix}</Box>
      </div>
    );
  }
);

export type DSTextAreaProps = Omit<ComponentProps<typeof RawTextarea>, "onChange" | "size"> &
  DSBaseInput<string>;

export const DSTextArea = forwardRef<HTMLTextAreaElement, DSTextAreaProps>((props, ref) => {
  const {onChange, value, hasError, className, size = "md", minRows = 1, ...rest} = props;
  return (
    <RawTextarea
      ref={ref as any}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      value={value}
      className={cx(
        className,
        styles.root,
        styles.sharedBase,
        styles.inputBase,
        styles.size[size],
        hasError && styles.hasError,
        !rest.disabled && Boolean(value) && styles.withValue
      )}
      minRows={minRows}
      cacheMeasurements
      {...rest}
    />
  );
});

export type DSSelectProps<T> = Omit<JSX.IntrinsicElements["select"], "onChange" | "size"> &
  DSBaseInput<T> & {options: {value: T; label: string}[]; size?: "sm" | "md"};

function toSelectValue<T>(value: T): string {
  return typeof value === "string" ? value : JSON.stringify(value);
}

export const DSSelect = forwardRef<HTMLSelectElement, DSSelectProps<any>>((props, ref) => {
  const {onChange, value, hasError, options, className, size = "md", ...rest} = props;
  const transformedOptions = useMemo(
    () =>
      options.map((o) => ({
        label: o.label,
        orgValue: o.value,
        stringValue: toSelectValue(o.value),
      })),

    [options]
  );
  return (
    <div
      className={cx(
        styles.root,
        dsStyles.relative.true,
        !rest.disabled && Boolean(value) && styles.withValue,
        hasError && styles.hasError
      )}
    >
      <select
        onChange={
          onChange
            ? (e) =>
                onChange(transformedOptions.find((o) => o.stringValue === e.target.value)!.orgValue)
            : undefined
        }
        value={toSelectValue(value)}
        className={cx(className, styles.sharedBase, styles.selectBase, styles.size[size])}
        {...rest}
      >
        {transformedOptions.map(({label, stringValue}) => (
          <option key={stringValue} value={stringValue}>
            {label}
          </option>
        ))}
      </select>
      <Col
        absolute
        top="0"
        bottom="0"
        right="0"
        justify="center"
        pr={2}
        color="secondary"
        pointerEvents="none"
      >
        <DSIconChevronDown size={24} />
      </Col>
    </div>
  );
});

import {lazy, Suspense} from "react";
import {useDragItem} from "@codecks/dnd";
import {useSelectedCardIds} from "../card-selection/useSelectedCards";
import {useReveal, Spinner} from "@cdx/common";

const LazySelectionHeader = lazy(
  () => import(/* webpackChunkName: "SelectionHeader" */ "./InnerSelectionHeader")
);

const SelectionHeader = ({root, arenaCtx}) => {
  const cardDragInfo = useDragItem("CARD");
  const selectedCardIds = useSelectedCardIds();
  const isActive = selectedCardIds && selectedCardIds.size > 0 && !cardDragInfo;

  const reveal = useReveal(isActive, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0.01},
  });

  return (
    <Suspense fallback={<Spinner size={25} />}>
      {reveal((props) => (
        <LazySelectionHeader
          style={props}
          root={root}
          arenaCtx={arenaCtx}
          selectedCardIds={selectedCardIds}
        />
      ))}
    </Suspense>
  );
};

export default SelectionHeader;

import {
  RadioGroupWithLabel,
  XForm as RawXForm,
  WithLabel as RawWithLabel,
  rules,
  SubmitButton,
  useNeoBag,
} from "@cdx/common";
import {Col, Row, Box, DSButton, css} from "@cdx/ds";
import useStateIfLoaded from "../../../lib/hooks/useStateIfLoaded";
import CoverImageEditor from "./CoverImageEditor";
import {forwardRef} from "react";

const XForm = RawXForm as any;
const WithLabel = RawWithLabel as any;

const formRules = {
  mode: [rules.isRequired],
  noBlur: [rules.isRequired],
  offset: [rules.isRequired],
};

const getModeFromFileMeta = (meta: any) => {
  const h = meta?.luminanceHisto;
  if (!h) return "dark";
  const lightAreaScore = h[9] + h[8] * 0.15 + h[7] * 0.05;
  if (lightAreaScore > 300) return "light";
  return "dark";
};

const CoverImageEditorWithLabel = forwardRef<any, any>(
  ({showErrors, name, label, hint, hasPendingValidation, value, onChange, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <CoverImageEditor
        offsetVals={[value.x, value.y]}
        setOffsetVals={([x, y]) => onChange({x, y})}
        {...rest}
        ref={ref}
      />
    </WithLabel>
  )
);

const FormSubmitButton = (props: any) => {
  const formBag = useNeoBag();
  return <SubmitButton as={DSButton} formBag={formBag} {...props} />;
};

type CoverFileModifier = {
  mode: null | "dark" | "light";
  noBlur: boolean;
  offsetX: number;
  offsetY: number;
};

type CoverImageOptionProps = {
  onSetCoverFile: (fileId: null | string, modifier: CoverFileModifier | null) => unknown;
  onClose: () => void;
  file: any;
  cardMeta: any;
  isCurrent: boolean;
  isHeroCard: boolean;
};
const CoverImageOptions = (props: CoverImageOptionProps) => {
  const {cardMeta, file, onSetCoverFile, onClose, isCurrent, isHeroCard} = props;
  const {state, setState} = useStateIfLoaded(() => {
    const mod = isCurrent ? cardMeta.coverFileModifier : null;
    return {
      mode: mod?.mode || getModeFromFileMeta(file.meta),
      noBlur: mod?.noBlur ?? (isCurrent ? false : true),
      offset: {x: mod?.offsetX ?? 50, y: mod?.offsetY ?? 50},
    };
  });
  const handleSubmit = async () => {
    await onSetCoverFile(file.id, {
      mode: state.mode,
      noBlur: state.noBlur,
      offsetX: state.offset.x,
      offsetY: state.offset.y,
    });
    onClose();
  };

  const handleUnset = async () => {
    await onSetCoverFile(null, null);
    onClose();
  };
  return (
    <XForm
      rules={formRules}
      values={state}
      onChange={setState}
      onSubmit={handleSubmit}
      className={css({display: "flex", flexDir: "column", pa: "16px", sp: "16px"})}
    >
      <Row sp="12px">
        <Col sp="12px" maxWidth="200px" rounded={4} borderWidth={1} borderColor="default" pa="16px">
          <Box width="cardWidth">
            <XForm.Field
              as={CoverImageEditorWithLabel}
              name="offset"
              label="Position"
              url={file.url}
              width={file.meta.width}
              height={file.meta.height}
              hint="Drag the image to the desired position"
              isHeroCard={isHeroCard}
            />
          </Box>
        </Col>
        <Col sp="16px" maxWidth="200px" rounded={4} borderWidth={1} borderColor="default" pa="16px">
          <XForm.Field
            as={RadioGroupWithLabel}
            name="mode"
            label="Mode"
            options={[
              {label: "Dark", value: "dark"},
              {label: "Light", value: "light"},
            ]}
          />
          <XForm.Field
            as={RadioGroupWithLabel}
            name="noBlur"
            label="Blur bottom"
            options={[
              {label: "Yes", value: false},
              {label: "No", value: true},
            ]}
            hint="Blurring the bottom helps recognising icons on noisy backgrounds"
          />
        </Col>
      </Row>
      <Row justify="end" sp="12px" overflow="hidden">
        {isCurrent && (
          <DSButton variant="secondary" onClick={handleUnset}>
            Unset
          </DSButton>
        )}
        <FormSubmitButton>Save</FormSubmitButton>
      </Row>
    </XForm>
  );
};

export default CoverImageOptions;

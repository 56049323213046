import {
  ComponentType,
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {Box, CSSProps, Col, Row, css} from "../Box/Box";
import {DSInput} from "../DSForm/DSInput";
import {DSSpinner} from "../DSIcon/DSSpinner";
import {VirtualItem, useVirtualizer} from "@tanstack/react-virtual";
import {makeScrollable} from "../../utils/makeScrollable";
import {RevealSpinner} from "@cdx/common";

type Renderer<T> = {
  OptionRow: ComponentType<OptionRowProps<T>>;
  SectionRow: ComponentType<SectionRowProps>;
  CreateRow: ComponentType<CreateRowProps>;
  optionHeight: number;
  sectionHeight: number;
};

type OptionRowProps<T> = {
  virtualRow: VirtualItem<any>;
  option: T;
  selected: boolean;
  isCurrent: boolean;
  onClick: () => void;
  children: ReactNode;
};
const OptionRow = <T,>(props: OptionRowProps<T>) => {
  const {virtualRow, children, selected, isCurrent, onClick} = props;
  const styleProps: CSSProps = selected
    ? {colorTheme: "active600", bg: "foreground"}
    : {
        useHoverBg: "true",
        useHoverColor: "true",
      };

  return (
    <div
      role="button"
      onClick={onClick}
      style={{height: `${virtualRow.size - 1}px`, transform: `translateY(${virtualRow.start}px)`}}
      data-cdx-clickable
      className={css({
        ...styleProps,
        position: "absolute",
        display: "flex",
        top: "0",
        left: "0",
        width: "100%",
        align: "center",
        rounded: 4,
        color: "primary",
        cursor: "pointer",
      })}
    >
      <Row px="8px" py="8px" width="100%" sp="8px">
        <Box
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          size={14}
          lineHeight="16px"
          flex="auto"
        >
          {children}
        </Box>
        {isCurrent && (
          <Box ml="auto" size={12} color="secondary">
            current
          </Box>
        )}
      </Row>
    </div>
  );
};

type SectionRowProps = {
  children: ReactNode;
  virtualRow: VirtualItem<any>;
  optionCount: number;
  optionHeight: number;
};

const SectionRow = (props: SectionRowProps) => {
  const {children, virtualRow, optionCount, optionHeight} = props;
  return (
    <Col
      style={{
        height: `${virtualRow.size - 1 + optionCount * optionHeight}px`,
        top: virtualRow.start,
      }}
      absolute
      left="0"
      width="100%"
    >
      <Col
        position="sticky"
        top="0"
        style={{height: `${virtualRow.size - 1}px`}}
        zIndex={1}
        bg="foreground"
        color="primary"
        justify="end"
        pb="4px"
        textTransform="uppercase"
        size={12}
        bold
        width="100%"
      >
        {children}
      </Col>
    </Col>
  );
};

type CreateRowProps = {
  children: ReactNode;
  virtualRow: VirtualItem<any>;
  label: ReactNode;
  selected: boolean;
  onClick: () => void;
};

const CreateRow = (props: CreateRowProps) => {
  const {children, virtualRow, label, selected, onClick} = props;
  const styleProps: CSSProps = selected
    ? {colorTheme: "active600", bg: "foreground"}
    : {
        useHoverBg: "true",
        useHoverColor: "true",
      };
  return (
    <div
      role="button"
      onClick={onClick}
      style={{height: `${virtualRow.size - 1}px`, transform: `translateY(${virtualRow.start}px)`}}
      data-cdx-clickable
      className={css({
        ...styleProps,
        position: "absolute",
        display: "flex",
        top: "0",
        left: "0",
        width: "100%",
        align: "center",
        rounded: 4,
        color: "primary",
        cursor: "pointer",
      })}
    >
      <Row px="8px" py="8px" width="100%" sp="8px">
        <Box
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          size={14}
          lineHeight="16px"
          flex="auto"
        >
          {children}
        </Box>
        <Box ml="auto" size={12} color="secondary">
          {label}
        </Box>
      </Row>
    </div>
  );
};

type DataRowProps<T> = {
  virtualRow: VirtualItem<any>;
  items: Item<T>[];
  selIdx: number;
  onClickOption: (key: any, index: number, type: "create" | "option", value: T | null) => void;
  searchString?: string;
  renderer: Renderer<T>;
} & Pick<DSListSelectorProps<T>, "renderOption" | "currentOptionKey" | "createOpts">;
const DataRow = <T,>(props: DataRowProps<T>) => {
  const {
    virtualRow,
    items,
    renderOption,
    selIdx,
    currentOptionKey,
    onClickOption,
    createOpts,
    searchString,
    renderer,
  } = props;
  const item = items[virtualRow.index];
  switch (item.type) {
    case "create": {
      return (
        <renderer.CreateRow
          virtualRow={virtualRow}
          label={createOpts?.label || "create"}
          selected={virtualRow.index === selIdx}
          onClick={() => onClickOption(searchString, virtualRow.index, "create", null)}
        >
          {searchString}
        </renderer.CreateRow>
      );
    }
    case "option":
      return (
        <renderer.OptionRow
          virtualRow={virtualRow}
          selected={virtualRow.index === selIdx}
          option={item.value}
          isCurrent={currentOptionKey === virtualRow.key}
          onClick={() => onClickOption(virtualRow.key, virtualRow.index, "option", item.value)}
        >
          {renderOption(item.value)}
        </renderer.OptionRow>
      );
    case "section":
      return (
        <renderer.SectionRow
          virtualRow={virtualRow}
          optionCount={item.optionCount ?? 0}
          optionHeight={renderer.optionHeight}
        >
          {item.value}
        </renderer.SectionRow>
      );
    default:
      return <div>unknown</div>;
  }
};

export const defaultRenderer: Renderer<any> = {
  CreateRow,
  OptionRow,
  SectionRow,
  optionHeight: 16 + 16 + 4 + 1,
  sectionHeight: 16 + 16 + 4 + 1,
};

type ListProps<T> = Pick<
  DSListSelectorProps<T>,
  "renderOption" | "optionToKey" | "currentOptionKey" | "createOpts"
> & {
  items: Item<T>[];
  scrollNode: HTMLElement;
  selIdx: number;
  onClickOption: (key: any, index: number, type: "option" | "create", value: T | null) => void;
  searchString?: string;
  renderer: Renderer<T>;
};
const List = <T,>(props: ListProps<T>) => {
  const {
    items,
    optionToKey,
    renderOption,
    scrollNode,
    selIdx,
    currentOptionKey,
    onClickOption,
    createOpts,
    searchString,
    renderer,
  } = props;

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollNode,
    estimateSize:
      renderer.optionHeight !== renderer.sectionHeight
        ? (idx) => (items[idx].type === "section" ? renderer.sectionHeight : renderer.optionHeight)
        : () => renderer.optionHeight,
    overscan: 2,
    getItemKey: (idx: number) => {
      const item = items[idx];
      return item.type === "option" ? optionToKey(item.value) : `s:${item.key}`;
    },
    rangeExtractor: (range) => {
      const start = Math.max(range.startIndex - range.overscan, 0);
      const end = Math.min(range.endIndex + range.overscan, range.count - 1);
      const arr: number[] = [];
      let prevIdx = -1;
      for (let i = start; i <= end; i++) {
        const item = items[i];
        if (item.type === "option") {
          if (prevIdx !== item.idxOfSection) {
            if (item.idxOfSection! < start) {
              arr.push(item.idxOfSection!);
            }
            prevIdx = item.idxOfSection!;
          }
        }
        arr.push(i);
      }

      return arr;
    },
  });

  useEffect(() => {
    rowVirtualizer.scrollToIndex(selIdx, {align: "center", behavior: "auto"});
  }, [selIdx, rowVirtualizer]);

  const toKey = (key: any, item: Item<T>) => {
    if (item.type === "option") {
      return `${item.sectionKey}:${key}`;
    } else {
      return item.key;
    }
  };

  return (
    <Box
      width="100%"
      absolute
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
        <DataRow
          key={toKey(virtualRow.key, items[virtualRow.index])}
          items={items}
          renderOption={renderOption}
          virtualRow={virtualRow}
          selIdx={selIdx}
          currentOptionKey={currentOptionKey}
          onClickOption={onClickOption}
          createOpts={createOpts}
          searchString={searchString}
          renderer={renderer}
        />
      ))}
    </Box>
  );
};

type SectionItem = {
  type: "section";
  value: ReactNode;
  key: string | number;
  optionCount?: number;
};
type CreationItem = {
  key: string;
  type: "create";
  value?: undefined;
};
type Item<T> =
  | {type: "option"; value: T; idxOfSection?: number | null; sectionKey?: string | number}
  | SectionItem
  | CreationItem;
type WithSearchString<T> = {item: Item<T>; searchString: string | null};

export type DSListItem<T> = Item<T>;

type UseSearchFn<T> = (opts: {searchString: string}) => {
  isLoading: boolean;
  value: Item<T>[];
  tryToFindCurrentOption: (
    nextSearch: string,
    currOption: T | undefined,
    setSelIdx: Dispatch<number>,
    onConfirm: ConfirmHandler<T> | undefined
  ) => void;
};

const getFullOptionsSearcher = <T,>(opts: {
  optionToSearchString: (t: T) => string;
  createOpts?: DSListSelectorProps<T>["createOpts"];
  optionToKey: (t: T) => any;
  getItems: () => Item<T>[];
}): UseSearchFn<T> => {
  const {optionToSearchString, createOpts, optionToKey, getItems} = opts;
  const allowCreation = Boolean(createOpts);

  const findCurrentOptionOrZero = (
    searchString: string,
    itemsWithSearchString: WithSearchString<T>[],
    currOption: T | undefined
  ): {nextIndex: number; foundPrevSelected: boolean; foundItem: Item<T> | null} => {
    const lowerSearch = searchString.toLowerCase();
    const filtered = lowerSearch
      ? transformAndFilter(itemsWithSearchString, searchString)
      : itemsWithSearchString.map((i) => i.item);
    if (filtered.length === 0) {
      return {nextIndex: 0, foundPrevSelected: false, foundItem: null};
    }
    const foundIndex = filtered.findIndex((i) => currOption === i.value);
    const minIndex = filtered.findIndex((i) => i.type !== "section");
    return {
      nextIndex: foundIndex > -1 ? foundIndex : minIndex,
      foundPrevSelected: foundIndex !== -1,
      foundItem: filtered[Math.max(minIndex, foundIndex)],
    };
  };

  const transformAndFilter = (
    itemsWithSearchString: WithSearchString<T>[],
    searchString: string | null
  ): Item<T>[] => {
    const lowerSearch = searchString?.toLowerCase();
    const items: Item<T>[] = [];
    let currSection: null | {item: SectionItem; idx: number} = null;
    let idx = 0;
    let exactMatch = false;
    for (const {item, searchString: itemSearch} of itemsWithSearchString) {
      if (item.type === "option") {
        const lower = itemSearch!.toLowerCase();
        if (!lowerSearch || lower.indexOf(lowerSearch) !== -1) {
          if (lower === lowerSearch) exactMatch = true;
          if (currSection) {
            item.idxOfSection = currSection.idx;
            item.sectionKey = currSection.item.key;
            currSection.item.optionCount = (currSection.item.optionCount ?? 0) + 1;
          } else {
            item.idxOfSection = undefined;
            item.sectionKey = undefined;
          }
          items.push(item);
          idx += 1;
        }
      } else if (item.type === "section") {
        if (currSection) {
          if (!currSection.item.optionCount) {
            items.pop();
            idx -= 1;
          }
        }
        item.optionCount = 0;
        currSection = {item, idx};
        items.push(item);
        idx += 1;
      }
    }
    if (currSection && !currSection.item.optionCount) items.pop();
    if (searchString && allowCreation && !exactMatch) items.push({type: "create", key: "$create"});
    return items;
  };

  const useSearch: UseSearchFn<T> = ({searchString}) => {
    const fnRef = useRef(optionToSearchString);
    fnRef.current = optionToSearchString;
    const allItems = getItems();
    const itemsWithSearchString = useMemo(() => {
      return allItems.map((i) => ({
        item: i,
        searchString: i.type === "option" ? fnRef.current(i.value).toLowerCase() : null,
      }));
    }, [allItems]);

    const filteredItems = useMemo(() => {
      return transformAndFilter(itemsWithSearchString, searchString);
    }, [itemsWithSearchString, searchString]);

    return {
      isLoading: false,
      value: filteredItems,
      tryToFindCurrentOption: (
        nextSearch: string,
        currOption: T | undefined,
        setSelIdx: Dispatch<number>,
        onConfirm: ConfirmHandler<T> | undefined
      ) => {
        const {foundPrevSelected, nextIndex, foundItem} = findCurrentOptionOrZero(
          nextSearch,
          itemsWithSearchString,
          currOption
        );
        setSelIdx(nextIndex);
        if (onConfirm && foundItem) {
          if (foundItem.type === "option") {
            if (!foundPrevSelected) {
              onConfirm(optionToKey(foundItem.value), "navigation", foundItem.value);
            }
          } else if (foundItem.type === "create" && createOpts?.toKey) {
            onConfirm(createOpts.toKey(nextSearch), "navigation", null);
          }
        }
      },
    };
  };
  return useSearch;
};

const DSListSelectorWithLoadedOptions = <T,>(
  props: Omit<DSListSelectorProps<T>, "getOptions" | "optionToSearchString" | "getItems"> & {
    useSearch: UseSearchFn<T>;
  }
) => {
  const {
    optionToKey,
    renderOption,
    currentOptionKey,
    autoFocus,
    onConfirm,
    confirmOnNavigation,
    initalSelectionKey,
    label,
    createOpts,
    renderer = defaultRenderer,
    emptyMessage = "No options found",
    useSearch,
  } = props;
  const [searchString, setSearchString] = useState("");
  const [scrollNode, setScrollNode] = useState<HTMLElement | null>(null);

  const {value: filteredItems, tryToFindCurrentOption, isLoading} = useSearch({searchString});

  const minSelIdx = Math.max(
    0,
    filteredItems.findIndex((i) => i.type !== "section")
  );

  const [selIdx, setSelIdx] = useState(() =>
    initalSelectionKey !== undefined
      ? Math.max(
          minSelIdx,
          filteredItems.findIndex(
            (i) => i.type === "option" && optionToKey(i.value) === initalSelectionKey
          )
        )
      : minSelIdx
  );

  const count = filteredItems.length;
  const currSelItem = filteredItems[selIdx];

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown": {
        let nextIdx = Math.min(selIdx + 1, count - 1);
        if (nextIdx < count - 1) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "section") nextIdx += 1;
        }
        setSelIdx(nextIdx);
        if (onConfirm && confirmOnNavigation) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "option") {
            onConfirm(optionToKey(selItem.value), "navigation", selItem.value);
          } else if (selItem.type === "create" && createOpts?.toKey) {
            onConfirm(createOpts.toKey(searchString), "navigation", null);
          }
        }
        e.preventDefault();
        break;
      }
      case "ArrowUp": {
        const minIdx = filteredItems.findIndex((i) => i.type !== "section");
        let nextIdx = Math.max(selIdx - 1, minIdx, 0);
        if (nextIdx > minIdx) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "section") nextIdx -= 1;
        }
        setSelIdx(nextIdx);
        if (onConfirm && confirmOnNavigation) {
          const selItem = filteredItems[nextIdx];
          if (selItem.type === "option") {
            onConfirm(optionToKey(selItem.value), "navigation", selItem.value);
          } else if (selItem.type === "create" && createOpts?.toKey) {
            onConfirm(createOpts.toKey(searchString), "navigation", null);
          }
        }
        e.preventDefault();
        break;
      }
      case "Enter": {
        if (currSelItem.type === "option") {
          const selOpt = currSelItem.value;
          if (onConfirm) {
            onConfirm(optionToKey(selOpt), "enter", selOpt);
            e.preventDefault();
          }
        } else if (currSelItem.type === "create") {
          if (createOpts?.onCreate) {
            createOpts.onCreate(searchString);
            e.preventDefault();
          }
        }
        break;
      }
    }
  };

  const handleClickOption = (key: any, index: number, type: "create" | "option", value: any) => {
    setSelIdx(index);
    if (type === "option") {
      if (onConfirm) onConfirm(key, "click", value);
    } else {
      if (createOpts?.onCreate) createOpts.onCreate(key);
    }
  };

  const handleChange = (next: string) => {
    setSearchString(next);
    tryToFindCurrentOption(
      next,
      currSelItem?.type === "option" ? currSelItem.value : undefined,
      setSelIdx,
      confirmOnNavigation ? onConfirm : undefined
    );
  };

  return (
    <Col sp="8px">
      {label && (
        <Box color="secondary" size={12} textTransform="uppercase" bold>
          {label}
        </Box>
      )}
      <DSInput
        size="sm"
        value={searchString}
        autoFocus={autoFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={css({width: "100%"})}
      />
      <Col
        style={{height: count * renderer.optionHeight}}
        minHeight="24px"
        maxHeight="20rem"
        className={makeScrollable()}
        relative
        ref={setScrollNode}
      >
        {isLoading && <RevealSpinner show={isLoading} withCover />}
        {!scrollNode ? (
          <Col align="center" justify="center" flex="auto" />
        ) : filteredItems.length === 0 ? (
          <Col align="center" justify="center" flex="auto">
            <Box size={14} color="secondary" textAlign="center">
              {emptyMessage}
            </Box>
          </Col>
        ) : (
          <List
            items={filteredItems}
            optionToKey={optionToKey}
            renderOption={renderOption}
            scrollNode={scrollNode}
            selIdx={selIdx}
            currentOptionKey={currentOptionKey}
            onClickOption={handleClickOption}
            createOpts={createOpts}
            searchString={createOpts && searchString}
            renderer={renderer}
          />
        )}
      </Col>
    </Col>
  );
};

type ConfirmHandler<T> = (
  key: string,
  mode: "enter" | "click" | "navigation",
  value: T | null
) => unknown | Promise<unknown>;

type SharedProps<T> = {
  renderOption: (t: T) => ReactNode;
  optionToKey: (t: T) => any;
  currentOptionKey?: any;
  autoFocus?: boolean;
  onConfirm?: ConfirmHandler<T>;
  confirmOnNavigation?: boolean;
  initalSelectionKey?: any;
  label?: string;
  renderer?: Renderer<T>;
  emptyMessage?: ReactNode;
  createOpts?: {
    label: ReactNode;
    onCreate: (term: string) => unknown | Promise<unknown>;
    toKey?: (term: string) => any;
  };
};

export type DSListSelectorProps<T> = SharedProps<T> & {
  optionToSearchString: (t: T) => string;
} & (
    | {getOptions: () => T[] | null; getItems?: undefined}
    | {getItems: () => Item<T>[] | null; getOptions?: undefined}
  );
export const DSListSelector = <T,>({
  getOptions,
  getItems,
  optionToSearchString,
  ...rest
}: DSListSelectorProps<T>) => {
  const getValues = (): Item<T>[] | null => {
    if (getOptions) {
      return getOptions()?.map((value) => ({type: "option", value})) ?? null;
    } else {
      return getItems();
    }
  };
  const items = getValues();
  if (!items) {
    return (
      <Col align="center" justify="center" flex="auto">
        <DSSpinner size={20} />
      </Col>
    );
  } else {
    const useSearch = getFullOptionsSearcher({
      getItems: () => items,
      optionToSearchString,
      ...rest,
    });
    return <DSListSelectorWithLoadedOptions {...rest} useSearch={useSearch} />;
  }
};

type CacheVal<T> =
  | {type: "promise"; value: Promise<{value: T} | null>}
  | {type: "loaded"; value: T};
type Cache<T> = {
  get: (searchFn: string) => CacheVal<T>;
};

const createCache = <T,>(refFn: MutableRefObject<(s: string) => Promise<T>>): Cache<T> => {
  const cache = new Map<string, CacheVal<T>>();
  let clearPending: (() => void) | null = null;
  return {
    get: (searchVal) => {
      if (clearPending) clearPending();
      const exist = cache.get(searchVal);
      if (exist) return exist;
      const promise = new Promise<{value: T} | null>((resolve) => {
        const id = setTimeout(() => {
          clearPending = null;
          refFn.current(searchVal).then((res) => {
            cache.set(searchVal, {type: "loaded", value: res});
            resolve({value: res});
          });
        }, 250);
        clearPending = () => {
          clearTimeout(id);
          cache.delete(searchVal);
          resolve(null);
          clearPending = null;
        };
      });

      cache.set(searchVal, {type: "promise", value: promise});
      return {type: "promise", value: promise};
    },
  };
};

const useAsyncSearcher = <T,>(opts: {
  searchItems: (searchString: string) => Promise<Item<T>[]>;
}): UseSearchFn<T> => {
  const {searchItems} = opts;
  const refFn = useRef(searchItems);
  refFn.current = searchItems;
  const [cache] = useState<Cache<Item<T>[]>>(() => createCache(refFn));

  const useSearch: UseSearchFn<T> = ({searchString}) => {
    const doSearch = (searchVal: string, fallback: Item<T>[] = []) => {
      const cacheRes = cache.get(searchVal);
      if (cacheRes.type === "loaded") {
        return {searchVal, value: cacheRes.value, isLoading: false};
      } else {
        cacheRes.value.then((promiseVal) => {
          if (promiseVal) {
            setItems((prev) =>
              prev.searchVal === searchVal
                ? {searchVal, value: promiseVal.value, isLoading: false}
                : prev
            );
          }
        });
        return {searchVal, value: fallback, isLoading: true};
      }
    };
    const [items, setItems] = useState<{isLoading: boolean; searchVal: string; value: Item<T>[]}>(
      () => doSearch(searchString, [])
    );
    useEffect(() => {
      if (items.searchVal === searchString) return;
      setItems(doSearch(searchString, items.value));
    }, [items, searchString]);
    return {value: items.value, tryToFindCurrentOption: () => {}, isLoading: items.isLoading};
  };
  return useSearch;
};

export type DSAsyncListSelectorProps<T> = SharedProps<T> & {
  searchItems: (searchString: string) => Promise<Item<T>[]>;
};
export const DSAyncListSelector = <T,>({searchItems, ...rest}: DSAsyncListSelectorProps<T>) => {
  const useSearch = useAsyncSearcher({
    searchItems,
    ...rest,
  });
  return <DSListSelectorWithLoadedOptions {...rest} useSearch={useSearch} />;
};

import {create} from "zustand";

export const useCardHighlightStore = create((set) => ({
  highlightedCardIds: new Set(),
  _timeoutId: null,
  set,
}));

export const cardHighlightActions = {
  setCardHighlights: (cardIdSet, duration = 1000) => {
    const _timeoutId = setTimeout(() => {
      useCardHighlightStore
        .getState()
        .set((prev) =>
          prev.highlightedCardIds === cardIdSet
            ? {...prev, _timeoutId: null, highlightedCardIds: new Set()}
            : prev
        );
    }, duration);
    useCardHighlightStore.getState().set({
      _timeoutId,
      highlightedCardIds: cardIdSet,
    });
  },
  highlightCardId: (cardId, duration = 1000) => {
    const set = new Set([cardId]);
    const _timeoutId = setTimeout(() => {
      useCardHighlightStore
        .getState()
        .set((prev) =>
          prev.highlightedCardIds === set
            ? {...prev, _timeoutId: null, highlightedCardIds: new Set()}
            : prev
        );
    }, duration);
    useCardHighlightStore.getState().set({_timeoutId, highlightedCardIds: set});
  },
};

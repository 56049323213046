const processSearchValue = (val) => {
  return {op: "search", value: val};
};

const contentCategories = [
  {
    label: "Title",
    key: "cardTitle",
    getSuggestions({input}) {
      const m = input.match(/^\s*t:\s*(\S.*)/);
      if (m) {
        return [m[1]];
      } else {
        return [];
      }
    },
    valueToKey(title) {
      return title;
    },
    valuesToDbQuery(values, {forceOr}) {
      if (values.length === 1) return {title: {op: "contains", value: values[0]}};
      return {
        [forceOr ? "$or" : "$and"]: values.map((title) => ({
          title: {op: "contains", value: title},
        })),
      };
    },
    renderSuggestion(title) {
      return `contains '${title}'`;
    },
    savedSearchLabel(title) {
      return {
        prio: 1,
        label: `title '${title}'`,
      };
    },
  },
  {
    label: "Content",
    key: "content",
    getSuggestions({input}) {
      return [input];
    },
    valueToKey(content) {
      return content;
    },
    valuesToDbQuery(values, {forceOr}) {
      if (values.length === 1) return {content: processSearchValue(values[0])};
      return {
        [forceOr ? "$or" : "$and"]: values.map((content) => ({
          content: processSearchValue(content),
        })),
      };
    },
    renderSuggestion(content) {
      return `contains '${content}'`;
    },
    savedSearchLabel(content) {
      return {
        prio: 1,
        label: `'${content}'`,
      };
    },
  },
];

export default contentCategories;

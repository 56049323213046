import {Component, forwardRef} from "react";
import messenger from "../../lib/messenger";
import {cx, errorToString, LinkOrButton} from "@cdx/common";
import {legacyButtonStyles as styles} from "./legacy-ui2.css";

class ButtonBehaviour extends Component {
  state = {pending: false};

  componentWillUnmount() {
    this.umounted = true;
  }

  handleClick = (...args) => {
    const retVal = this.props.onClick(...args);
    if (retVal && typeof retVal.then === "function") {
      this.setState({pending: true});
      retVal.then(
        () => !this.umounted && this.setState({pending: false}),
        (error) => {
          console.error("button click resulted in ", error);
          messenger.send(errorToString(error), {type: "error", msToRemove: 10000});
          if (!this.umounted) this.setState({pending: false});
          return Promise.reject(error);
        }
      );
    }
  };

  render() {
    const {onClick, comp: Comp, forwardedRef, bgCol: providedBgCol, ...rest} = this.props;
    const {pending} = this.state;
    return (
      <Comp
        onClick={onClick ? this.handleClick : undefined}
        disabled={pending}
        bgCol={providedBgCol}
        forwardedRef={forwardedRef}
        {...rest}
      />
    );
  }
}

const RawButtonBase = ({className, bgCol, forwardedRef, active, ...rest}) => (
  <LinkOrButton ref={forwardedRef} className={cx(className, styles.shared)} {...rest} />
);

const PlainButtonBase = ({className, bgCol, active, size = "default", forwardedRef, ...rest}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.plain.base,
      active ? styles.active : bgCol === "dark" ? styles.plain.onDark : styles.plain.onLight,
      styles.plain.bySize[size]
    )}
    {...rest}
  />
);

const PlainSquareButtonBase = ({
  className,
  bgCol,
  active,
  size = "default",
  forwardedRef,
  ...rest
}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.plain.base,
      active ? styles.active : bgCol === "dark" ? styles.plain.onDark : styles.plain.onLight,
      styles.plain.bySizeSquare[size]
    )}
    {...rest}
  />
);

const ElevatedButtonBase = ({
  className,
  bgCol,
  active,
  size = "default",
  forwardedRef,
  ...rest
}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.elevated.base,
      active ? styles.active : styles.elevated.nonActive,
      styles.elevated.bySize[size]
    )}
    {...rest}
  />
);

const ElevatedSquareButtonBase = ({
  className,
  bgCol,
  active,
  size = "default",
  forwardedRef,
  ...rest
}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.elevated.base,
      active ? styles.active : styles.elevated.nonActive,
      styles.plain.bySizeSquare[size]
    )}
    {...rest}
  />
);

const IconButtonBase = ({className, bgCol, active, size = "default", forwardedRef, ...rest}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.icon.base,
      active ? styles.active : styles.icon.nonActive,
      styles.icon.bySize[size],
      styles.icon.byBg[bgCol === "dark" ? "dark" : "light"]
    )}
    {...rest}
  />
);

const TextButtonBase = ({
  className,
  bgCol,
  active,
  size = "default",
  isRed,
  forwardedRef,
  ...rest
}) => (
  <LinkOrButton
    ref={forwardedRef}
    className={cx(
      className,
      styles.shared,
      styles.text.base,
      active
        ? styles.text.active
        : styles.text.byColor[isRed ? "red" : "default"][bgCol === "dark" ? "onDark" : "onLight"],
      styles.text.bySize[size]
    )}
    {...rest}
  />
);

const withBehaviour = (Comp) =>
  forwardRef((props, ref) => <ButtonBehaviour {...props} comp={Comp} forwardedRef={ref} />);

export default withBehaviour(PlainButtonBase);
export const RawButton = withBehaviour(RawButtonBase);
export const PlainSquareButton = withBehaviour(PlainSquareButtonBase);
export const ElevatedButton = withBehaviour(ElevatedButtonBase);
export const ElevatedSquareButton = withBehaviour(ElevatedSquareButtonBase);
export const IconButton = withBehaviour(IconButtonBase);
export const TextButton = withBehaviour(TextButtonBase);

import {forwardRef, lazy} from "react";
import {XCol, XRow} from "../xui";
import {RawPriorityIcon} from "../props";
import Avatar from "../Avatar";
import {api} from "../../lib/api";
import {usePropHover} from "./useHoverShortcuts";
import {cx, TooltipForChild} from "@cdx/common";
import styles from "./card.css";
import {
  Box,
  DSIconDoc,
  DSIconEffort,
  DSIconGhost,
  DSIconGlobe,
  DSIconJourney,
  DSIconStar,
  DSShortcutKey,
  Row,
} from "@cdx/ds";
import DSEffortIconBanner from "@cdx/ds/components/DSCard/DSEffortIconBanner";
import {CardPropChangeOverlayForChild} from "../RichTextarea/Lexical/CardPropChangeForChild";
import Beast from "./Beast";
import {WorkflowPropChangeOverlayForChild} from "../../features/workflows/WorkflowPropChangeForChild";
import {DefaultCardPropChangeOverlayForChild} from "../../features/workflows/DefaultCardPropChangeForChild";
import AddedDuringSprint from "./AddedDuringSprint";

const SharedPropChangeOverlayForChild = ({
  bag,
  children,
  initialTab,
  cardContainerKey,
  getChildProps,
}) => {
  const {type, cardId, canModify} = bag;
  switch (type) {
    case "DEFAULT_CARD":
      return (
        <DefaultCardPropChangeOverlayForChild
          deck={bag.deck}
          cardContainerKey={cardContainerKey}
          initialTab={initialTab}
          disabled={!canModify}
          getChildProps={getChildProps}
        >
          {children}
        </DefaultCardPropChangeOverlayForChild>
      );
    case "WORKFLOW_ITEM":
      return (
        <WorkflowPropChangeOverlayForChild
          itemId={cardId}
          cardContainerKey={cardContainerKey}
          initialTab={initialTab}
          disabled={!canModify}
          getChildProps={getChildProps}
        >
          {children}
        </WorkflowPropChangeOverlayForChild>
      );
    default:
      return (
        <CardPropChangeOverlayForChild
          cardId={cardId}
          cardContainerKey={cardContainerKey}
          initialTab={initialTab}
          disabled={!canModify}
          getChildProps={getChildProps}
        >
          {children}
        </CardPropChangeOverlayForChild>
      );
  }
};

const effortIconMap = {
  journey: DSIconJourney,
  star: DSIconStar,
  ghost: DSIconGhost,
  globe: DSIconGlobe,
};
const EffortIcon = forwardRef(({icon, shownEffort, ...rest}, ref) => {
  const Comp = (icon && effortIconMap[icon]) || DSIconEffort;
  return (
    <Comp
      className={styles.effortIcon.modern}
      size={shownEffort >= 10 ? 16 : 20}
      ref={ref}
      {...rest}
    />
  );
});

const EffortArea = ({bag}) => {
  const {effort, cardContainerKey, childCardInfo, markerColor, isDoc, effortIcon} = bag;
  const effortListener = usePropHover("effort");
  const shownEffort = childCardInfo ? childCardInfo.effort : effort;
  return (
    <XRow
      className={styles.bottomContainer}
      align="center"
      sp={0}
      {...(childCardInfo || isDoc ? null : effortListener)}
    >
      {isDoc ? (
        <TooltipForChild
          tooltip="Doc cards are for documentation purposes. They don't have priority, effort or workflow states."
          delayed
          placement="bottom"
        >
          {markerColor ? (
            <DSEffortIconBanner isDoc />
          ) : (
            <DSIconDoc size={20} className={styles.docIcon.base} />
          )}
        </TooltipForChild>
      ) : (
        <>
          {markerColor ? (
            <DSEffortIconBanner />
          ) : (
            <SharedPropChangeOverlayForChild
              bag={bag}
              cardContainerKey={`${cardContainerKey}-effort`}
              initialTab="effort"
              getChildProps={(open) => ({hidden: open ? true : undefined})}
            >
              <TooltipForChild
                tooltip={
                  bag.canModify && (
                    <Box px="8px" py="4px">
                      Set Effort <DSShortcutKey>e</DSShortcutKey> or{" "}
                      <DSShortcutKey>0</DSShortcutKey> - <DSShortcutKey>9</DSShortcutKey>
                    </Box>
                  )
                }
                delayed
                placement="bottom"
              >
                <EffortIcon icon={effortIcon} shownEffort={shownEffort} />
              </TooltipForChild>
            </SharedPropChangeOverlayForChild>
          )}
          <div
            className={cx(
              styles.effortText.base,
              shownEffort && shownEffort >= 10 && styles.effortText.big
            )}
          >
            {shownEffort !== null ? shownEffort : "-"}
          </div>
        </>
      )}
    </XRow>
  );
};

const PrioArea = ({bag}) => {
  const {priority, wordCount, isDoc, cardContainerKey} = bag;

  const prioListener = usePropHover("priority");

  return (
    <XRow
      className={styles.bottomContainer}
      align="center"
      justify="end"
      {...(isDoc ? null : prioListener)}
    >
      {isDoc ? (
        <div className={cx(styles.docText.base, wordCount >= 1000 && styles.docText.fourDigits)}>
          {wordCount}
          <span className={styles.docText.wLetter}>w</span>
        </div>
      ) : (
        <SharedPropChangeOverlayForChild
          bag={bag}
          cardContainerKey={`${cardContainerKey}-priority`}
          initialTab="priority"
          getChildProps={(open) => ({hidden: open ? true : undefined})}
        >
          <TooltipForChild
            tooltip={
              bag.canModify && (
                <Box px="8px" py="4px">
                  Set Priority <DSShortcutKey>p</DSShortcutKey> or <DSShortcutKey>0</DSShortcutKey>{" "}
                  - <DSShortcutKey>3</DSShortcutKey>
                </Box>
              )
            }
            delayed
            placement="bottom"
          >
            <RawPriorityIcon size={16} priority={priority} />
          </TooltipForChild>
        </SharedPropChangeOverlayForChild>
      )}
    </XRow>
  );
};

const ProgressCircle = ({progress}) => {
  return (
    <XCol absolute className={styles.progressCircle}>
      <svg viewBox="0 0 50 50" className={styles.progressCircleInner}>
        <clipPath id="myClip">
          <circle cx="25" cy="25" r="25" />
        </clipPath>
        <circle
          transform="rotate(-90 25 25)"
          cx="25"
          cy="25"
          r="25"
          strokeWidth="8"
          strokeDasharray={25 * Math.PI * 2}
          strokeDashoffset={25 * (1 - progress) * Math.PI * 2}
          clipPath="url(#myClip)"
          className={styles.checkboxCircle}
        />
      </svg>
    </XCol>
  );
};

const MaybeOwnerAvatar = ({bag}) => {
  const {checkboxStats, cardContainerKey, assignee} = bag;
  return (
    <SharedPropChangeOverlayForChild
      bag={bag}
      cardContainerKey={`${cardContainerKey}-owner`}
      initialTab="owner"
      getChildProps={(open) => ({hidden: open ? true : undefined})}
    >
      <TooltipForChild
        tooltip={
          bag.canModify && (
            <Box px="8px" py="4px">
              Set Owner <DSShortcutKey>o</DSShortcutKey>
            </Box>
          )
        }
        placement="bottom"
        delayed
      >
        {assignee ? (
          <div className={styles.avatarContainer}>
            <XCol
              absolute
              inset="x"
              className={cx(
                styles.avatar.base,
                checkboxStats.total > 0 && styles.avatar.withCheckboxStats
              )}
            >
              {checkboxStats.total > 0 && (
                <ProgressCircle progress={checkboxStats.checked / checkboxStats.total} />
              )}
              <Avatar user={assignee} />
            </XCol>
          </div>
        ) : (
          <Box width="16px" />
        )}
      </TooltipForChild>
    </SharedPropChangeOverlayForChild>
  );
};

const CheckboxStatsBar = ({checkboxStats}) =>
  checkboxStats.total > 0 && (
    <XCol absolute inset="x" bg="gray200" className={styles.checkbox}>
      <XCol
        absolute
        inset="y"
        bg="active"
        className={styles.checkboxBar}
        style={{width: `${(checkboxStats.checked / checkboxStats.total) * 100}%`}}
      />
    </XCol>
  );

const LazyWorkflowHoverInfo = lazy(
  () =>
    import(/* webpackChunkName: "WorkflowOverInfo" */ "../../features/workflows/WorkflowHoverInfo")
);

const getChildChardInfoContent = (info) =>
  ["done", "review", "blocked", "started", "rest"].map((label) =>
    info[label].map((effort, idx) => (
      <div
        key={`${label}-${idx}`}
        style={{flex: `1px ${(effort || 0) + 1}`}}
        className={cx(
          styles.childCardInfoBarTile.base,
          label !== "rest" && styles.childCardInfoBarTile[label]
        )}
      />
    ))
  );

export const MaybeChildcardOverlay = ({
  childCardInfo,
  cardId,
  children,
  overlayPlacement = "bottom",
}) =>
  childCardInfo ? (
    <TooltipForChild
      placement={overlayPlacement}
      bg="gray800"
      distanceFromAnchor={7}
      tooltip={() => (
        <LazyWorkflowHoverInfo hideParent card={api.getModel({modelName: "card", id: cardId})} />
      )}
    >
      {children}
    </TooltipForChild>
  ) : (
    children
  );

export const ChildCardInfo = forwardRef(({info, isBig, className, ...rest}, ref) => {
  return (
    <Row
      relative
      className={cx(
        className,
        styles.childCardInfo.base,
        isBig ? styles.childCardInfo.big : styles.childCardInfo.small,
        info.count > 25
          ? styles.childCardInfo.sectionCount.lg
          : info.count > 15
            ? styles.childCardInfo.sectionCount.md
            : null
      )}
      ref={ref}
      {...rest}
    >
      {getChildChardInfoContent(info)}
    </Row>
  );
});

export const CardBottom = ({bag}) => {
  const {
    cardId,
    status,
    assignee,
    gotoPanel,
    checkboxStats,
    isArchived,
    childCardInfo,
    isDoc,
    allowAnimation,
    beastLevel,
    isNewUntil,
  } = bag;

  const getContainerClass = () => {
    const base = cx(styles.bottom.base, isArchived && styles.bottom.archivedStripes);
    if (allowAnimation) {
      if (childCardInfo) {
        if (status === "hero") {
          return cx(base, styles.bottom.shinyHero);
        } else if (isDoc) {
          return cx(base, styles.bottom.shinyDoc);
        } else {
          return cx(
            base,
            styles.bottom.shinyStatus,
            isArchived && styles.bottom.shinyArchivedStatus
          );
        }
      }
    }
    return base;
  };
  return (
    <XCol relative>
      {!assignee && <CheckboxStatsBar checkboxStats={checkboxStats} />}
      <MaybeChildcardOverlay childCardInfo={childCardInfo} cardId={cardId}>
        <XCol className={getContainerClass()} px={1} py={0} sp={1}>
          {beastLevel > 0 ? (
            <Beast level={beastLevel} isDone={status === "done"} isNewUntil={isNewUntil} />
          ) : (
            isNewUntil && <AddedDuringSprint isDone={status === "done"} isNewUntil={isNewUntil} />
          )}
          <XRow>
            <EffortArea bag={bag} />
            <MaybeOwnerAvatar assignee={assignee} bag={bag} />
            <PrioArea bag={bag} />
          </XRow>
          {childCardInfo && (
            <ChildCardInfo
              info={childCardInfo}
              onClick={(e) => {
                gotoPanel("childCards");
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </XCol>
      </MaybeChildcardOverlay>
    </XCol>
  );
};

import {shrinker} from "./shrinker";

export const errorToString = (maybeError, fallback) => {
  if (!maybeError) return "Unknown error";
  if (typeof maybeError === "string") return maybeError;
  if (maybeError.message) return errorToString(maybeError.message);
  if (maybeError.error) return errorToString(maybeError.error);
  if (maybeError.payload) return errorToString(maybeError.payload);
  console.error("Couldn't interpret error", maybeError);
  try {
    return shrinker(JSON.stringify(maybeError), 60);
  } catch {
    return fallback !== undefined ? fallback : "Unknown error";
  }
};

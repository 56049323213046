import {
  DSField,
  DSInput,
  DSInputProps,
  DSInputWithPostfix,
  DSInputWithPostfixProps,
  DSSelect,
  DSSelectProps,
  DSTextArea,
  DSTextAreaProps,
} from "@cdx/ds";
import {ReactNode, forwardRef, useState} from "react";

type Merge<T1, T2> = T1 & Omit<T2, keyof T1>;

let nextId = 1;

type WrapProps = {
  showErrors: ReactNode[];
  hint?: ReactNode;
  label: ReactNode;
  hasPendingValidation?: boolean;
  name: string;
};

type WrappedDSInputProps = Merge<WrapProps, DSInputProps>;
export const WrappedDSInput = forwardRef<HTMLInputElement, WrappedDSInputProps>((props, ref) => {
  const {label, hint, showErrors, hasPendingValidation, name, id, className, style, ...rest} =
    props;
  const [fieldId] = useState(() => id || `${name}-${(nextId += 1)}`);
  return (
    <DSField
      errors={showErrors}
      hint={hint}
      label={label}
      fieldId={fieldId}
      className={className}
      style={style}
    >
      <DSInput name={name} id={fieldId} {...rest} ref={ref} />
    </DSField>
  );
});

export type WrappedDSInputWithPostfixProps = Merge<WrapProps, DSInputWithPostfixProps>;
export const WrappedDSInputWithPostfix = forwardRef<
  HTMLInputElement,
  WrappedDSInputWithPostfixProps
>((props, ref) => {
  const {label, hint, showErrors, hasPendingValidation, name, id, className, style, ...rest} =
    props;
  const [fieldId] = useState(() => id || `${name}-${(nextId += 1)}`);
  return (
    <DSField
      errors={showErrors}
      hint={hint}
      label={label}
      fieldId={fieldId}
      className={className}
      style={style}
    >
      <DSInputWithPostfix name={name} id={fieldId} {...rest} ref={ref} />
    </DSField>
  );
});

type WrappedDSTextAreaProps = Merge<WrapProps, DSTextAreaProps>;
export const WrappedDSTextArea = forwardRef<HTMLTextAreaElement, WrappedDSTextAreaProps>(
  (props, ref) => {
    const {label, hint, showErrors, hasPendingValidation, name, id, className, style, ...rest} =
      props;
    const [fieldId] = useState(() => id || `${name}-${(nextId += 1)}`);
    return (
      <DSField
        errors={showErrors}
        hint={hint}
        label={label}
        fieldId={fieldId}
        className={className}
        style={style}
      >
        <DSTextArea name={name} id={fieldId} {...rest} ref={ref} />
      </DSField>
    );
  }
);

type WrappedDSSelectProps<T> = Merge<WrapProps, DSSelectProps<T>>;
export const WrappedDSSelect = forwardRef<HTMLSelectElement, WrappedDSSelectProps<any>>(
  (props, ref) => {
    const {label, hint, showErrors, hasPendingValidation, name, id, className, style, ...rest} =
      props;
    const [fieldId] = useState(() => id || `${name}-${(nextId += 1)}`);
    return (
      <DSField
        errors={showErrors}
        hint={hint}
        label={label}
        fieldId={fieldId}
        className={className}
        style={style}
      >
        <DSSelect name={name} id={fieldId} {...rest} ref={ref} />
      </DSField>
    );
  }
);

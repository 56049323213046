const contentTypes = new Set(["text", "inlineCode", "code"]);
const newLineTypes = new Set(["paragraph", "break", "code", "table", "heading", "list"]);

const walk = (n, cb) => {
  cb(n);
  if (n.children && n.children.length) {
    n.children.forEach((c) => walk(c, cb));
  }
};

export function cdxTextCompiler({components = {}} = {}) {
  const compiler = (root) => {
    const texts = [];
    let addedTextInBlock = false;
    walk(root, (node) => {
      if (node.type in components) {
        const text = components[node.type](node);
        if (text) {
          texts.push(text);
          addedTextInBlock = true;
        }
      } else if (newLineTypes.has(node.type)) {
        if (addedTextInBlock) {
          texts.push(" ");
          addedTextInBlock = false;
        }
      } else if (contentTypes.has(node.type)) {
        const text = node.value;
        if (text) {
          texts.push(text);
          addedTextInBlock = true;
        }
      }
      return undefined;
    });
    return texts.join("");
  };
  this.Compiler = compiler;
}

import {shrinker} from "@cdx/common/shrinker";
import {Fragment, createElement} from "react";

const contentTypes = new Set(["text", "inlineCode", "code"]);
const newLineTypes = new Set(["paragraph", "break", "code", "table", "heading", "list"]);
const inlineNodes = new Map([
  ["strong", {tag: "b"}],
  ["inlineCode", {tag: "code", addContent: true}],
  ["emphasis", {tag: "i"}],
  ["delete", {tag: "del"}],
  [
    "link",
    {
      tag: "a",
      extractProps: (node) => ({href: node.url}),
    },
  ],
]);

const walk = (n, cb) => {
  const unsub = cb(n);
  if (unsub === false) return false;
  if (n.children && n.children.length) {
    n.children.every((c) => walk(c, cb) !== false);
  }
  if (unsub) unsub();
};

export function cdxPreviewCompiler({components = {}, maxLength = 0} = {}) {
  const compiler = (root) => {
    let currContent = [];
    let addedTextInBlock = false;
    let length = 0;
    let maxLengthReached = false;
    const addContent = (text) => {
      if (maxLengthReached) return true;
      addedTextInBlock = true;
      if (!maxLength) {
        currContent.push(text);
        return false;
      }
      if (text === " " || length + text.length < maxLength) {
        currContent.push(text);
        length += text.length;
        return false;
      }

      const shrinkText = shrinker(text, Math.max(1, maxLength - length));
      currContent.push(shrinkText);
      maxLengthReached = true;
      return true;
    };

    walk(root, (node) => {
      const inlineInfo = inlineNodes.get(node.type);
      if (inlineInfo) {
        const prevContent = currContent;
        currContent = [];
        if (inlineInfo.addContent) {
          if (addContent(node.value)) return false;
        }
        return () => {
          const props = inlineInfo.extractProps ? inlineInfo.extractProps(node) : {};
          prevContent.push(createElement(inlineInfo.tag, props, ...currContent));
          currContent = prevContent;
        };
      }
      if (node.type in components) {
        const text = components[node.type](node);
        if (text) {
          if (addContent(text)) return false;
        }
      } else if (newLineTypes.has(node.type)) {
        if (addedTextInBlock) {
          addContent(" ");
          addedTextInBlock = false;
        }
      } else if (contentTypes.has(node.type)) {
        const text = node.value;
        if (text) {
          if (addContent(text)) return false;
        }
      }
      return undefined;
    });
    // return texts.join("");
    return createElement(Fragment, {}, ...currContent);
  };
  this.Compiler = compiler;
}

import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalEditor,
  NodeKey,
} from "lexical";
import dsStyles from "@cdx/ds/css/index.css";
import Emoji from "../../Markdown/Emoji";

export class EmojiNode extends DecoratorNode<JSX.Element> {
  __emoji: string;

  static getType() {
    return "cdxEmoji";
  }

  static clone(node: EmojiNode) {
    return new EmojiNode(node.__emoji, node.__key);
  }

  static importJSON(serializedNode: any): EmojiNode {
    const node = $createEmojiNode(serializedNode.emoji);
    return node;
  }

  constructor(emoji: string, key?: NodeKey) {
    super(key);
    this.__emoji = emoji;
  }

  exportJSON() {
    return {
      type: "cdxEmoji",
      emoji: this.__emoji,
      version: 1,
    };
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement("span");
    element.textContent = this.__emoji;
    element.setAttribute("data-lexical-cdx-emoji", "true");
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-cdx-emoji")) {
          return null;
        }
        return {
          conversion: (innerNode: HTMLElement) => {
            const emoji = innerNode.textContent;
            if (emoji) {
              const node = $createEmojiNode(emoji);
              return {node};
            } else {
              return null;
            }
          },
          priority: 1,
        };
      },
    };
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    el.className = dsStyles.userSelect.none;
    return el;
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(): string {
    return this.__emoji;
  }

  decorate(): JSX.Element {
    return <Emoji children={this.__emoji} />;
  }

  isInline() {
    return true;
  }
}

export function $isEmojiNode(node: any) {
  return node instanceof EmojiNode;
}

export function $createEmojiNode(emoji: string) {
  return $applyNodeReplacement<EmojiNode>(new EmojiNode(emoji));
}

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type {Entries, NeoForm} from "./neo-form-types";

const FormCtx = createContext<NeoForm.NeoBag<any> | null>(null);

function useControlledState<T extends object>(
  value: T,
  onChange: ((val: T, fieldName?: string) => void) | null
): [T, Dispatch<NeoForm.UpdateFn<T>>] {
  const [innerState, _setInnerState] = useState(value);
  const setInnerState = useCallback<Dispatch<SetStateAction<T>>>((next) => {
    if (next instanceof Function) {
      _setInnerState((prev) => {
        const val = next(prev) as NeoForm.FormUpdateEvent<T> | T;
        if ("__type" in val && val.__type === "__neoFormEvent") {
          return val.value;
        } else {
          return val as T;
        }
      });
    } else {
      _setInnerState(next);
    }
  }, []);
  if (onChange) {
    return [
      value,
      (updateFn: NeoForm.UpdateFn<T>) => {
        const val = updateFn(value) as NeoForm.FormUpdateEvent<T> | T;
        if ("__type" in val && val.__type === "__neoFormEvent") {
          if (val.value !== value) onChange(val.value, val.fieldName);
        } else {
          onChange(val as T);
        }
      },
    ];
  } else {
    return [innerState, setInnerState as Dispatch<NeoForm.UpdateFn<T>>];
  }
}

function asyncValidation<T extends object>({
  promisesAndFields,
  currentValidationRunnerRef,
  setFieldMeta,
  setAsyncIsValid,
  syncRulesAreValid,
}: {
  promisesAndFields: NeoForm.FieldValidation<T>[];
  currentValidationRunnerRef: React.MutableRefObject<{}>;
  setFieldMeta: NeoForm.NeoBag<T>["setFieldMeta"];
  setAsyncIsValid: (isValid: boolean) => void;
  syncRulesAreValid: boolean;
}): Promise<NeoForm.ValidValue> {
  const myObj = {};
  currentValidationRunnerRef.current = myObj;
  return Promise.all(
    promisesAndFields.map(({isValidPromise, fieldName, errorMessage}) => {
      setFieldMeta(fieldName, (prev) => ({...prev, pendingValidation: true}));
      return isValidPromise.then((isValid) => {
        if (currentValidationRunnerRef.current !== myObj) return false;
        setFieldMeta(fieldName, (prev) => ({
          ...prev,
          errors: isValid ? prev.errors : [...prev.errors, errorMessage],
          pendingValidation: false,
        }));
        return isValid;
      });
    })
  ).then((results) => {
    const asyncRulesAreValid = results.every((isValid) => isValid);
    const isAllValid = syncRulesAreValid && asyncRulesAreValid;
    setAsyncIsValid(isAllValid);
    return isAllValid;
  });
}

function validateValuesFn<T extends object>({
  values,
  rules: rawRules,
  setFieldMeta,
  currentValidationRunnerRef,
  setAsyncIsValid,
}: {
  values: T;
  rules: NeoForm.Rules<T>;
  setFieldMeta: NeoForm.NeoBag<T>["setFieldMeta"];
  setAsyncIsValid: Dispatch<SetStateAction<NeoForm.AsyncValidationInfo | null>>;
  currentValidationRunnerRef: React.MutableRefObject<{}>;
}): NeoForm.ValidationInfo {
  const promisesAndFields: NeoForm.FieldValidation<T>[] = [];
  let syncRulesAreValid = true;
  const rules = rawRules instanceof Function ? rawRules(values) : rawRules;
  (Object.entries(values) as Entries<T>).forEach(([fieldName, value]) => {
    const errors: string[] = [];
    const fieldValidations = rules[fieldName];
    if (fieldValidations) {
      fieldValidations.forEach(([validator, errorMessage]) => {
        const isValid = validator(value);
        if (isValid === false) {
          syncRulesAreValid = false;
          errors.push(errorMessage);
        } else if (isValid === true) {
          // all fine
        } else if (isValid && isValid.then && typeof isValid.then === "function") {
          promisesAndFields.push({fieldName, isValidPromise: isValid, errorMessage});
        } else {
          console.error(
            `invalid 'isValid' value for ${fieldName as string}: ${JSON.stringify(isValid)}`
          );
        }
      });
    }
    setFieldMeta(fieldName, (m) =>
      m.errors.length === 0 && errors.length === 0 ? m : {...m, errors}
    );
  });
  if (promisesAndFields.length) {
    const validationPromise: Promise<NeoForm.ValidValue> = asyncValidation({
      promisesAndFields,
      currentValidationRunnerRef,
      setFieldMeta,
      setAsyncIsValid: (isValid) => setAsyncIsValid({validationPromise, isValid}),
      syncRulesAreValid,
    });
    return {isPending: true, validationPromise};
  } else {
    return {isPending: false, isValid: syncRulesAreValid};
  }
}

function useValidation<T extends object>({
  rules,
  values,
  setFieldMeta,
}: {
  rules: NeoForm.Rules<T>;
  values: T;
  setFieldMeta: NeoForm.NeoBag<T>["setFieldMeta"];
}): NeoForm.ValidationInfo {
  const [asyncValidationInfo, setResolvedAsyncValid] = useState<NeoForm.AsyncValidationInfo | null>(
    null
  );
  const refs = useRef({rules, setFieldMeta});
  const currentValidationRunnerRef = useRef({});
  useEffect(() => {
    refs.current = {rules, setFieldMeta};
  });
  const output = useMemo(
    () =>
      validateValuesFn({
        values,
        rules: refs.current.rules,
        setFieldMeta: refs.current.setFieldMeta,
        currentValidationRunnerRef,
        setAsyncIsValid: setResolvedAsyncValid,
      }),
    [values]
  );
  if (
    asyncValidationInfo &&
    output.isPending &&
    output.validationPromise === asyncValidationInfo.validationPromise
  ) {
    return {isPending: false, isValid: asyncValidationInfo.isValid};
  }
  return output;
}

const initialFieldMeta = {
  errors: [],
  changed: false,
  touched: false,
  pendingValidation: false,
  focussed: false,
};

const emptyFn = () => {};

export function useNeoBagCreator<T extends object>({
  values: outerValues,
  onChange: outerOnChange,
  initialValues,
  onSubmit,
  rules,
  disabled,
}: NeoForm.CreatorProps<T>) {
  const [values, setValues] = useControlledState(
    (outerValues || initialValues) as T,
    outerValues ? outerOnChange || emptyFn : null
  );
  const [submitCount, setSubmitCount] = useState(0);
  const [serverErrorCount, setServerErrorCount] = useState(0);
  const [fieldMetas, setFieldMetas] = useState<NeoForm.FieldMetas<T>>({});
  const [submitting, setSubmitting] = useState(false);
  const bagRef = useRef<NeoForm.NeoBag<T>>(null as unknown as NeoForm.NeoBag<T>);
  const setFieldMeta = useCallback<NeoForm.NeoBag<T>["setFieldMeta"]>(
    (fieldName, fieldInfoOrCb) => {
      if (typeof fieldInfoOrCb === "function") {
        setFieldMetas((allMetas) => {
          const prevFieldInfo = allMetas[fieldName];
          const nextFieldInfo = fieldInfoOrCb(prevFieldInfo || initialFieldMeta);
          return nextFieldInfo === prevFieldInfo
            ? allMetas
            : {...allMetas, [fieldName]: nextFieldInfo};
        });
      } else {
        setFieldMetas((allMetas) => ({...allMetas, [fieldName]: fieldInfoOrCb}));
      }
    },
    []
  );
  const onSubmitRef = useRef(onSubmit);
  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  const validationInfo = useValidation({rules, values, setFieldMeta});
  // validationInfo = {isPending: true, validationPromise} | {isPending: false, isValid: true | false}

  const handleSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
    if (e && e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    const bagVals = bagRef.current;
    if (bagVals.submitting) return false;
    setSubmitCount((c) => c + 1);
    const doSubmit = (v: T, b: NeoForm.NeoBag<T>) => {
      const res = onSubmitRef.current(v, b);
      if (res && "then" in res) {
        setSubmitting(true);
        res.then(
          (val) => {
            setSubmitting(false);
            return val;
          },
          (val) => {
            setSubmitting(false);
            return Promise.reject(val);
          }
        );
      }
    };
    if (!bagVals.validationInfo.isPending) {
      if (bagVals.validationInfo.isValid) {
        doSubmit(bagVals.values, bagVals);
      }
    } else {
      setSubmitting(true);
      bagVals.validationInfo.validationPromise.then((isValid) => {
        setSubmitting(false);
        if (isValid) {
          doSubmit(bagVals.values, bagVals);
        }
      });
    }
    return;
  }, []);

  const bag: NeoForm.NeoBag<T> = {
    values,
    setValues,
    validationInfo,
    isValid: validationInfo.isValid === true,
    submitCount,
    fieldMetas,
    setFieldMeta,
    submitting,
    setSubmitting,
    handleSubmit,
    serverErrorCount,
    setServerErrorCount,
    formDisabled: disabled,
    resetFieldMetas: () => {
      setFieldMetas({});
      setServerErrorCount(0);
      setSubmitCount(0);
    },
  };
  useEffect(() => {
    bagRef.current = bag;
  });

  return bag;
}

export function NeoFormProvider<T extends object>({
  bag,
  children,
}: {
  bag: NeoForm.NeoBag<T>;
  children: ReactNode;
}) {
  return <FormCtx.Provider value={bag}>{children}</FormCtx.Provider>;
}

export function useNeoBag<T extends object>() {
  return useContext(FormCtx) as NeoForm.NeoBag<T>;
}

export function useNeoField<T extends object, K extends keyof T>(name: K) {
  const bag = useNeoBag<T>();
  if (!bag) throw new Error("Used field outside of XForm!");
  const {fieldMetas, values, setValues, setFieldMeta, formDisabled} = bag;
  const fieldProps = {
    value: values[name],
    ...(formDisabled && {disabled: true}),
    onChange: (value: T[K]) => {
      setValues((prev) => {
        const next = prev[name] === value ? prev : {...prev, [name]: value};
        return {
          __type: "__neoFormEvent",
          value: next,
          fieldName: name,
        } as NeoForm.FormUpdateEvent<T>;
      });
      setFieldMeta(name, (prev) => (prev.changed ? prev : {...prev, changed: true}));
    },
    onBlur: () => {
      setFieldMeta(name, (prev) => (prev.focussed ? {...prev, focussed: false} : prev));
    },
    onFocus: () =>
      setFieldMeta(name, (prev) => {
        let next = prev;
        if (!next.touched) next = {...next, touched: true};
        if (!next.focussed) next = {...next, focussed: true};
        return next;
      }),
  };

  return [fieldProps, fieldMetas[name] || initialFieldMeta];
}

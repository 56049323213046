import {springConfigs} from "@cdx/common";
import {Box, css} from "@cdx/ds";
import {useSpring, animated} from "react-spring";
import {OnboardingHeaderBarStyles as styles} from "./onboarding.css";

const OnboardingProgressBar = (props: {progress: number; height: "6px" | "3px"}) => {
  const animProps = useSpring({
    width: `${Math.min(1, props.progress ?? 0) * 100}%`,
    config: springConfigs.quick,
  });
  return (
    <Box relative>
      <animated.div style={animProps} className={styles.progressBar.shadowBox} />
      <Box
        elevation={100}
        rounded="full"
        height={props.height}
        className={styles.progressBar.base}
        bg="background"
        overflow="hidden"
        relative
      >
        <animated.div
          style={animProps}
          className={css({bg: "foreground", height: "100%", minWidth: "1px"})}
        />
      </Box>
    </Box>
  );
};

export default OnboardingProgressBar;

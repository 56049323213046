import ErrorStackParser from "error-stack-parser";
import {cdxRequest} from "./request";
import {MiniEvent} from "@cdx/common";
import throttle from "lodash/throttle";
import cdxEnv from "../env";

// TODO: given that StackTrace seems to be quite bad at offering a generic solution for
// all browsers and we're not using stuff like source map requests etc, maybe we don't need
// stacktrace-js after all...

/* eslint-disable import/prefer-default-export */

export const exceptionEvent = new MiniEvent();

const throttledRequest = throttle(
  ({stackFrames, msg}) =>
    cdxRequest({
      path: "/services/send-error",
      method: "POST",
      data: {stackFrames, message: msg, appVersion: cdxEnv.DEPLOYMENT_LABEL},
    }),
  1000
);

export const postError = (error, msg) => {
  throttledRequest({stackFrames: ErrorStackParser.parse(error), msg});
};

window.onerror = (msg, file, line, col, error) => {
  if (msg === "Script error.") return;
  if (msg.indexOf("ResizeObserver loop") >= 0) return;
  if (msg.indexOf("Minified exception occurred") >= 0) return;
  if (msg.indexOf("Cannot read property '_currentElement'") >= 0) return;
  setTimeout(() => {
    exceptionEvent.emit();
  });
  const stackFrames = error
    ? ErrorStackParser.parse(error)
    : [{fileName: file, lineNumber: line, columnNumber: col, functionName: "unknown"}];
  throttledRequest({stackFrames, msg});
};

window.addEventListener("unhandledrejection", (event) => {
  if (event.reason instanceof Error) {
    throttledRequest({
      stackFrames: ErrorStackParser.parse(event.reason),
      msg: `unhandled rejection: ${event.reason.message}`,
    });
  } else {
    throttledRequest({stackFrames: [], msg: JSON.stringify(event.reason)});
  }
});

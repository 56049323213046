import {forwardRef} from "react";
import classes from "./ui.css";
import cx from "../cx";

const XHorLine = forwardRef((props, ref) => {
  const {className, fillParent, color = "gray200", as: Comp = "div", ...rest} = props;
  return (
    <Comp
      className={cx(
        classes.horLine,
        classes.borderColor[color],
        className,
        fillParent && classes.flex.auto
      )}
      {...rest}
      ref={ref}
    />
  );
});

export default XHorLine;

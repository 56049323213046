import {combineExtensions} from "micromark-util-combine-extensions";
import {gfmAutolinkLiteral} from "micromark-extension-gfm-autolink-literal";
import {gfmFootnote} from "micromark-extension-gfm-footnote";
import {gfmStrikethrough} from "micromark-extension-gfm-strikethrough";
import {gfmTable} from "micromark-extension-gfm-table";
import {gfmAutolinkLiteralFromMarkdown} from "mdast-util-gfm-autolink-literal";
import {gfmFootnoteFromMarkdown} from "mdast-util-gfm-footnote";
import {gfmStrikethroughFromMarkdown} from "mdast-util-gfm-strikethrough";
import {gfmTableFromMarkdown} from "mdast-util-gfm-table";
import {markdownLineEndingOrSpace} from "micromark-util-character";
import {codes} from "micromark-util-symbol";

const tokenizeTasklistCheck = function (effects, ok, nok) {
  const self = this;

  const open = (code) => {
    // assert(code === codes.leftSquareBracket, "expected `[`");

    if (
      // Exit if there’s stuff before.
      self.previous !== codes.eof ||
      // Exit if not in the first content that is the first child of a list item.
      !self._gfmTasklistFirstContentOfListItem
    ) {
      return nok(code);
    }

    effects.enter("taskListCheck");
    effects.enter("taskListCheckMarker");
    effects.consume(code);
    effects.exit("taskListCheckMarker");
    return inside;
  };

  const inside = (code) => {
    // cdx specific case of allowing both `- []` and `- [ ]`

    if (code === codes.rightSquareBracket) {
      return close(code);
    }

    // To match how GH works in comments, use `markdownSpace` (`[ \t]`) instead
    // of `markdownLineEndingOrSpace` (`[ \t\r\n]`).

    if (markdownLineEndingOrSpace(code)) {
      effects.enter("taskListCheckValueUncheckedWithSpace");
      effects.consume(code);
      effects.exit("taskListCheckValueUncheckedWithSpace");
      return close;
    }

    if (code === codes.uppercaseX || code === codes.lowercaseX) {
      effects.enter("taskListCheckValueChecked");
      effects.consume(code);
      effects.exit("taskListCheckValueChecked");
      return close;
    }

    return nok(code);
  };

  const close = (code) => {
    if (code === codes.rightSquareBracket) {
      effects.enter("taskListCheckMarker");
      effects.consume(code);
      effects.exit("taskListCheckMarker");
      effects.exit("taskListCheck");
      return ok(code);
    }

    return nok(code);
  };

  return open;
};

const exitCheckMarker = function (token) {
  const stackItem = this.stack[this.stack.length - 2];
  if (stackItem.checked === null) stackItem.checked = false;
  stackItem.markerEndOffset = token.end.offset;
};

const exitChecked = function () {
  const stackItem = this.stack[this.stack.length - 2];
  stackItem.checked = true;
};

const exitParagraphWithTaskListItem = function (token) {
  const parent = this.stack[this.stack.length - 2];
  const node = this.stack[this.stack.length - 1];
  const siblings = parent.children;
  let index = -1;
  let firstParaghraph;

  if (parent && parent.type === "listItem" && typeof parent.checked === "boolean") {
    while (++index < siblings.length) {
      const sibling = siblings[index];
      if (sibling.type === "paragraph") {
        firstParaghraph = sibling;
        break;
      }
    }

    if (firstParaghraph === node) {
      const head = node.children[0];
      const diff = parent.checkboxLen === 3 ? 0 : 1;
      if (!head) {
        siblings.splice(index, 1);
      } else if (head && head.type === "text") {
        if (head.value[0] === " ") head.value = head.value.slice(1);

        if (head.value.length === 0) {
          node.children.shift();
        } else {
          head.position.start.column += diff;
          head.position.start.offset += diff;
          node.position.start = Object.assign({}, head.position.start);
        }
      }
    }
  }

  this.exit(token);
};

const tasklistCheck = {tokenize: tokenizeTasklistCheck};

const gfmTaskListItem = {
  text: {[codes.leftSquareBracket]: tasklistCheck},
};

const gfmTaskListItemFromMarkdown = {
  enter: {
    taskListCheck: function (token) {
      const stackItem = this.stack[this.stack.length - 2];
      stackItem.markerStartOffset = token.start.offset;
    },
  },
  exit: {
    taskListCheck: exitCheckMarker,
    taskListCheckValueChecked: exitChecked,
    paragraph: exitParagraphWithTaskListItem,
  },
};

export default function cdxGithubMarkdownPlugin() {
  const data = this.data();
  (data.micromarkExtensions = data.micromarkExtensions || []).push(
    combineExtensions([
      gfmAutolinkLiteral(),
      gfmFootnote(),
      gfmStrikethrough(),
      gfmTable(),
      gfmTaskListItem,
    ])
  );
  (data.fromMarkdownExtensions = data.fromMarkdownExtensions || []).push(
    gfmAutolinkLiteralFromMarkdown(),
    gfmFootnoteFromMarkdown(),
    gfmStrikethroughFromMarkdown(),
    gfmTableFromMarkdown(),
    gfmTaskListItemFromMarkdown
  );
}

import {XCol, XRow, XText} from "@cdx/common";
import memoizeOne from "memoize-one";
import {DSAvatarStack} from "../../components/DSAvatar/DSAvatar";
import Emoji from "../../components/Markdown/Emoji";
import {pluralize} from "../../lib/utils";

const upvoteMemoizer = memoizeOne((user, accountId) => {
  const upvotes = user.$meta.find("upvotes", {accountId});
  return new Map(upvotes.map((u) => [u.card.id, u]));
});

/**
 *
 * @param {{
 *  card: import("../../cdx-models/Card").Card
 *  root: import("../../cdx-models/Root").Root
 * }}} opts
 * @returns {{
 *  isLoaded: boolean
 *  myUpvoteId: import("../../cdx-models/CardUpvote").CardUpvoteId | null
 *  counts: {
 *    discord: number
 *    team: number
 *    visitors: number
 *  }
 *  totalCount: number
 * }}
 */
export const getUpvoteInfo = ({card, root}) => {
  const user = root.loggedInUser;
  const accountId = root.account.$meta.get("id", null);
  const cardId = card.$meta.get("cardId", null);
  const upvote = accountId && user && cardId && upvoteMemoizer(user, accountId).get(cardId);

  const {discord, upvotes} = card.meta;
  const counts = {
    discord: (discord && discord.reactionCount) || 0,
    team: (upvotes && upvotes.team) || 0,
    visitors: (upvotes && upvotes.visitors) || 0,
  };

  return {
    isLoaded: accountId && cardId && (!user || !upvote || upvote.$meta.isLoaded),
    myUpvoteId: upvote && upvote.$meta.isLoaded ? upvote.id : null,
    counts,
    totalCount: counts.discord + counts.team + counts.visitors,
  };
};

export const getTotalUpvotes = (card) => {
  const {discord, upvotes} = card.meta;
  return (
    ((discord && discord.reactionCount) || 0) + ((upvotes && upvotes.team + upvotes.visitors) || 0)
  );
};

const UpvoteRow = ({label, count, suffix}) => (
  <XRow sp={1} align="center">
    <XRow align="baseline" sp={0}>
      <XText preset="bold" color="gray400" size={0} style={{minWidth: "4em"}}>
        {label}:
      </XText>
      <XText preset="bold" color="gray100" size={2}>
        {count}
      </XText>
    </XRow>
    {suffix}
  </XRow>
);

export const UpvoteTooltip = ({upvoteInfo: {counts, totalCount}, card}) => {
  return process.env.REACT_APP_MODE === "open" ? (
    <XCol px={2} py={1} sp={1}>
      <XText preset="bold" color="gray200" size={2}>
        {pluralize(totalCount, "upvote")}
      </XText>
    </XCol>
  ) : (
    <XCol px={2} py={1} sp={1}>
      <XText preset="bold" color="gray100" size={1}>
        Upvotes
      </XText>
      {totalCount === 0 ? (
        <XText color="gray400" size={1}>
          None
        </XText>
      ) : (
        <XCol sp={0}>
          {card.meta.discord && (
            <UpvoteRow
              label="Discord"
              count={counts.discord}
              suffix={
                card.meta.discord.reaction && (
                  <XText size={1}>
                    <Emoji>{card.meta.discord.reaction}</Emoji>
                  </XText>
                )
              }
            />
          )}
          {counts.team > 0 && (
            <UpvoteRow
              label="Team"
              count={counts.team}
              suffix={
                counts.team > 0 && (
                  <DSAvatarStack
                    users={card.$meta.find("upvotes", {type: "team"}).map((v) => v.user)}
                    maxCount={5}
                    size={16}
                  />
                )
              }
            />
          )}
          {counts.visitors > 0 && <UpvoteRow label="Visitors" count={counts.visitors} />}
        </XCol>
      )}
    </XCol>
  );
};

import {MiniEvent} from "@cdx/common";
import {useEffect, useMemo, useRef, useState} from "react";

type RawStorageMap<T> = {
  storageGet(key: string): T | null;
  storageSet(key: string, val: T): boolean;
  storageRemove(key: string): boolean;
};

const fallbackStorage: RawStorageMap<any> = {
  storageGet() {
    return null;
  },
  storageSet() {
    return false;
  },
  storageRemove() {
    return false;
  },
};

const getRawStorageMap = <T>(prefix: string, storageGetter: () => Storage): RawStorageMap<T> => {
  const attemptToGetStorage = (): Storage | null => {
    try {
      return storageGetter();
    } catch {
      return null;
    }
  };

  const storage = attemptToGetStorage();
  if (!storage) return fallbackStorage;

  return {
    storageGet(key) {
      try {
        const content = storage.getItem(`${prefix}${key}`);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    storageSet(key, val) {
      try {
        storage.setItem(`${prefix}${key}`, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    storageRemove(key) {
      try {
        storage.removeItem(`${prefix}${key}`);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

type RawStorage<T> = {
  get(): T | null;
  set(val: T): boolean;
  remove(): boolean;
};

const getRawStorage = <T>(key: string, storageGetter: () => Storage): RawStorage<T> => {
  const attemptToGetStorage = (): Storage | null => {
    try {
      return storageGetter();
    } catch {
      return null;
    }
  };

  const storage = attemptToGetStorage();
  if (!storage) {
    return {
      get() {
        return null;
      },
      set() {
        return false;
      },
      remove() {
        return false;
      },
    };
  }
  return {
    get() {
      try {
        const content = storage.getItem(key);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    set(val) {
      try {
        storage.setItem(key, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    remove() {
      try {
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

export const getLocalStorageMap = <T>(prefix: string) =>
  getRawStorageMap<T>(prefix, () => window.localStorage);
export const getSessionStorageMap = <T>(prefix: string) =>
  getRawStorageMap<T>(prefix, () => window.sessionStorage);

export const singleKeyLocalStorage = <T>(key: string) =>
  getRawStorage<T>(key, () => window.localStorage);
export const singleKeySessionStorage = <T>(key: string) =>
  getRawStorage<T>(key, () => window.sessionStorage);

const cdxLocalStorage = getRawStorageMap("", () => window.localStorage);
const cdxSessionStorage = getRawStorageMap("", () => window.sessionStorage);

const getStorageValOrDefaultWithKey = <T>(
  storage: RawStorageMap<T>,
  key: string,
  defaultVal?: T
): {key: string; value: T | null} => {
  if (!key) return {key, value: null};
  const storageVal = storage.storageGet(key);
  const value = storageVal === null ? defaultVal || null : storageVal;
  return {key, value};
};

const storageChangeListener = new MiniEvent<{key: string; val: any; by: any}>();

export const useLocalStorageState = <T>(key: string, defaultVal?: T, {type = "local"} = {}) => {
  if (process.env.NODE_ENV !== "production" && key === undefined)
    throw new Error("please pass key to 'useLocalStorageState'");
  const storage = (type === "local" ? cdxLocalStorage : cdxSessionStorage) as RawStorageMap<T>;
  const [data, setData] = useState(() => getStorageValOrDefaultWithKey(storage, key, defaultVal));
  let nextVal: {key: string; value: T | null} | null = null;
  const meRef = useRef<{}>();
  if (!meRef.current) meRef.current = {};

  if (key !== data.key) {
    nextVal = getStorageValOrDefaultWithKey(storage, key, defaultVal);
    setData(nextVal);
  }

  useEffect(() => {
    storageChangeListener.addListener((event) => {
      const {key: eventKey, val, by} = event;
      if (key !== eventKey) return;
      if (by === meRef.current) return;
      setData({key, value: val});
    });
  }, [key]);

  const defaultValueRef = useRef(defaultVal);
  useEffect(() => {
    defaultValueRef.current = defaultVal;
  }, [defaultVal]);

  const handlers = useMemo(
    () => ({
      setVal: (next: T | ((prev: T) => T), {dontPersist}: {dontPersist?: boolean} = {}) => {
        setData((prev) => {
          const val = next instanceof Function ? (next as (p: T) => T)(prev.value as T) : next;
          if (val === prev.value) return prev;
          if (!dontPersist) {
            storage.storageSet(key, val);
            storageChangeListener.emit({key, val, by: meRef.current});
          }
          return {key, value: val};
        });
      },
      clear: () => {
        storage.storageRemove(key);
        setData({key, value: defaultValueRef.current as T});
        storageChangeListener.emit({key, val: defaultValueRef.current, by: meRef.current});
      },
    }),
    [key, storage]
  );

  return [nextVal ? nextVal.value : data.value, handlers.setVal, {clear: handlers.clear}] as const;
};

import {ReactElement} from "react";
import {CardId} from "../../../../cdx-models/Card";
import {Resolvable, ResolvableId} from "../../../../cdx-models/Resolvable";
import {UserId} from "../../../../cdx-models/User";
import {api} from "../../../../lib/api";
import {hasPermissionToGuardCard, hasPermissionToManageProject} from "../../../../lib/permissions";
import {DSIconBan, DSIconCheck, DSIconClose, SelfDSRawButtonProps} from "@cdx/ds";
import {ConvoBag} from "./convo-utils";
import {themedConvoButton} from "./convo.css";

export type ConvoAction = {
  type: "close" | "opt-out" | "post-close-update-card" | "post-close-mark-done";
  tooltip?: string | null;
  message: string;
  icon: ReactElement;
  onClick?: (() => unknown) | null;
  disabled?: boolean;
  buttonProps?: Partial<SelfDSRawButtonProps> & {className?: string};
};

export type AvatarAction = {
  tooltip?: string | null;
  type: "owner" | "return" | "last" | "optOut";
  onClick?: (() => unknown) | null;
};

type ActionOptions = {
  resolvable: Resolvable;
  bag: ConvoBag;
};

export const getConversationActions = ({
  resolvable,
  bag,
}: ActionOptions): {shownActions: ConvoAction[]; avatarAction: AvatarAction | null} => {
  const {card, canModify, root, onCloseThread, onCardDone} = bag;
  const meId = root.loggedInUser?.id as UserId;
  if (resolvable.isClosed || !meId) return {shownActions: [], avatarAction: null};
  const ctx = resolvable.$meta.get("context", "comment");
  const mePart = resolvable.participants.find((p) => p.user.id === meId);
  const isGuardianCard = card.deck?.hasGuardians;
  const isGuardian = isGuardianCard && hasPermissionToGuardCard(root, card);
  const cardId = card.cardId as CardId;

  if (!mePart) {
    // if I'm not part of the convo
    if (!canModify) return {shownActions: [], avatarAction: null};
    if (isGuardianCard && !isGuardian) return {shownActions: [], avatarAction: null};
    // i.e. only continue if I'm part of convo
    // or I'm guardian, or I can modify the card
  }

  const getAvatarAction = (): AvatarAction | null => {
    if (!mePart) return null;
    if (card.assignee && card.assignee.id === meId) {
      return {tooltip: "Card owner cannot opt out", onClick: null, type: "owner"};
    }
    if (mePart.done) {
      return {
        tooltip: "Return to conversation",
        type: "return",
        onClick: () =>
          api.mutate.resolvables.updateParticipantDone({
            resolvableId: resId,
            userId: meId,
            done: false,
            status: "active",
          }),
      };
    }
    const presentParticipants = resolvable.participants.filter((p) => !p.done);
    if (presentParticipants.length === 1) {
      return {
        tooltip: "Can't opt out: you're the last in this conversation.",
        type: "last",
        onClick: null,
      };
    }
    const handleClick = () =>
      api.mutate.resolvables.updateParticipantDone({
        resolvableId: resId,
        userId: meId,
        done: true,
        status: "opt_out",
      });
    return {tooltip: "Click to opt out", onClick: handleClick, type: "optOut"};
  };
  const avatarAction = getAvatarAction();
  const shownActions: ConvoAction[] = [];

  const resId = resolvable.id as ResolvableId;

  const handleClose = () => {
    onCloseThread?.(resId);
    return api.mutate.resolvables.close({
      id: resId,
      isClosed: true,
      cardId: card.cardId,
      closedBy: meId,
    });
  };

  if (!isGuardianCard || ctx !== "review") {
    // non guardian actions
    if (avatarAction?.type === "optOut") {
      shownActions.push({
        type: "opt-out",
        tooltip: null,
        message: "Opt out of conversation",
        icon: <DSIconBan />,
        onClick: avatarAction.onClick,
        buttonProps: {className: themedConvoButton},
      });
    }
    if (canModify || ctx === "comment") {
      const closeMessages = {block: "Unblock this card", review: "Close this review"};
      const message = closeMessages[ctx as "block"] || "Close this thread";
      shownActions.push({
        type: "close",
        tooltip: null,
        message,
        icon: <DSIconClose />,
        onClick: handleClose,
        buttonProps: {className: themedConvoButton},
      });
    }
  } else {
    // guardian actions!
    const handleCloseAndDone = () => {
      onCloseThread?.(resId);
      return api.mutate.resolvables
        .close({
          id: resId,
          isClosed: true,
          cardId: cardId,
          closedBy: meId,
        })
        .then(() => api.mutate.cards.update({id: cardId, status: "done"}))
        .then(() => {
          onCardDone?.();
        });
    };
    const checkIfManager = () => {
      if (!card.deck) return true; // I'm manager of (my own) private cards
      return hasPermissionToManageProject({root, project: card.deck.project});
    };
    if (isGuardian || checkIfManager()) {
      if (avatarAction?.type === "optOut") {
        const handleClick = () =>
          api.mutate.resolvables.updateParticipantDone({
            resolvableId: resId,
            userId: meId,
            done: true,
            status: "approve",
          });
        shownActions.push({
          type: "opt-out",
          tooltip: null,
          message: "Approve and leave conversation",
          icon: <DSIconCheck />,
          onClick: handleClick,
          buttonProps: {theme: "success"},
        });
      } else {
        // opted out
        shownActions.push({
          type: "close",
          tooltip: null,
          message: "Close review and mark card as done",
          icon: <DSIconCheck />,
          onClick: handleCloseAndDone,
          buttonProps: {theme: "success"},
        });
      }
    } else {
      if (!card.deck) return {avatarAction: null, shownActions: []};
      // non guardian in guardian card
      const guardianUserIds = new Set(card.deck.guardians.map((g) => g.user.id));
      const someActiveGuardianPresent = resolvable.participants.some(
        (p) => !p.done && guardianUserIds.has(p.user.id)
      );
      const handleApprove = () =>
        api.mutate.resolvables.updateParticipantDone({
          resolvableId: resId,
          userId: meId,
          done: true,
          status: "approve",
        });

      if (!someActiveGuardianPresent) {
        if (card.assignee?.id !== meId) {
          if (avatarAction?.type === "optOut") {
            shownActions.push({
              type: "opt-out",
              tooltip: null,
              message: "Approve and leave conversation",
              icon: <DSIconCheck />,
              onClick: handleApprove,
              buttonProps: {theme: "success"},
            });
          }
        }
        shownActions.push({
          type: "close",
          message: "Close review and mark as done",
          icon: <DSIconCheck />,
          onClick: handleCloseAndDone,
          buttonProps: {theme: "success"},
        });
      } else if (card.assignee?.id === meId) {
        shownActions.push({
          type: "close",
          tooltip: someActiveGuardianPresent ? "Available once all Guardians approved" : null,
          message: "Close review and mark as done",
          icon: <DSIconCheck />,
          onClick: handleCloseAndDone,
          disabled: someActiveGuardianPresent,
          buttonProps: {theme: "success"},
        });
      } else {
        // non owners
        if (avatarAction?.type === "optOut") {
          shownActions.push({
            type: "opt-out",
            tooltip: null,
            message: "Approve and leave conversation",
            icon: <DSIconCheck />,
            onClick: handleApprove,
            buttonProps: {theme: "success"},
          });
        }
      }
    }
    shownActions.push({
      type: "close",
      tooltip: null,
      message: "Cancel review",
      icon: <DSIconClose />,
      onClick: handleClose,
      buttonProps: {className: themedConvoButton},
    });
  }

  return {shownActions, avatarAction};
};

import {TooltipForChild, XCol, XText} from "@cdx/common";
import cx from "@cdx/common/cx";
import {DSIconBell} from "@cdx/ds";
import {
  dayToDate,
  prettyWorkdaysForAccount,
  superShortDateWithWeekdayIfThisYear,
} from "../../lib/date-utils";
import {useRoot} from "../../lib/mate/mate-utils";
import {dueDateCardHintStyles as styles} from "./due-date.css";
import {forwardRef} from "react";

const TooltipContent = ({dueDate, workDays: rawWorkDays}) => {
  const root = useRoot();
  const date = dayToDate(dueDate);
  const workDays = prettyWorkdaysForAccount({account: root.account, targetDay: date});
  return (
    <XCol px={2} py={1}>
      <XText color="white" size={1}>
        Card {rawWorkDays < 0 ? "was" : "is"} due <b>{workDays}</b> on{" "}
        <b>{superShortDateWithWeekdayIfThisYear(date)}</b>.
      </XText>
    </XCol>
  );
};

export const DueDateTooltipContent = TooltipContent;

export const DueDateIcon = forwardRef(
  ({dueDatesEnabled, workDays, onClick, size = 16, ...rest}, ref) => {
    const getType = () => {
      if (!dueDatesEnabled) return "disabled";
      if (workDays <= 0) return "dueNow";
      if (workDays <= 5) return "dueSoon";
      return "later";
    };
    return (
      <div
        {...(onClick && {onClick, "data-cdx-clickable": true})}
        ref={ref}
        {...rest}
        className={cx(styles.container.base, styles.container.types[getType()], rest.className)}
      >
        <DSIconBell size={size} />
      </div>
    );
  }
);

export const DueDateCardHintIcon = ({dueDate, workDays, onClick, dueDatesEnabled}) => {
  return (
    <div>
      <TooltipForChild
        tooltip={() =>
          dueDatesEnabled ? (
            <TooltipContent dueDate={dueDate} workDays={workDays} />
          ) : (
            "This card has a disabled due date. Upgrade to the Pro plan to use this feature."
          )
        }
      >
        <DueDateIcon dueDatesEnabled={dueDatesEnabled} workDays={workDays} onClick={onClick} />
      </TooltipForChild>
    </div>
  );
};

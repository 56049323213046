import {addDays, toDateStr} from "../../../lib/date-utils";

const getUpvotes = () => {
  const processSearchValue = (val) => {
    return {op: "cardUpvotes", compareOp: "gte", value: val};
  };
  return {
    label: "Upvotes",
    key: "upvotes",
    getSuggestions({input}) {
      if ("upvotes".startsWith(input)) {
        return [5, 10, 25, 50];
      } else {
        return [];
      }
    },
    valueToKey(content) {
      return content;
    },
    valuesToDbQuery(values, {forceOr}) {
      if (values.length === 1) return {meta: processSearchValue(values[0])};
      return {
        [forceOr ? "$or" : "$and"]: values.map((content) => ({
          meta: processSearchValue(content),
        })),
      };
    },
    renderSuggestion(content) {
      return `${content}+ upvotes`;
    },
    savedSearchLabel(content) {
      return {
        prio: 0,
        label: `with ${content}+ upvotes`,
      };
    },
  };
};

const getDueDate = () => {
  const keys = {
    dueNow: {constraint: {dueDate: toDateStr(new Date())}, label: "due today"},
    soon: {
      constraint: {
        $and: [
          {dueDate: {op: "gte", value: toDateStr(new Date())}},
          {dueDate: {op: "lte", value: toDateStr(addDays(new Date(), 7))}},
        ],
      },
      label: "due within 7 days",
    },
    due: {constraint: {dueDate: {op: "neq", value: null}}, label: "with due date"},
  };

  const processSearchValue = (val) => {
    return keys[val].constraint;
  };

  return {
    label: "Due Date",
    key: "dueDate",
    getSuggestions({input}) {
      if ("due".startsWith(input)) {
        return ["dueNow", "soon", "due"];
      } else {
        return [];
      }
    },
    valueToKey(content) {
      return content;
    },
    valuesToDbQuery(values, {forceOr}) {
      if (values.length === 1) return processSearchValue(values[0]);
      return {
        [forceOr ? "$or" : "$and"]: values.map((content) => processSearchValue(content)),
      };
    },
    renderSuggestion(content) {
      return keys[content].label;
    },
    savedSearchLabel(content) {
      return {
        prio: 0,
        label: keys[content].label,
      };
    },
  };
};

const metaCategories = [getUpvotes(), getDueDate()];

export default metaCategories;

import emojiDfa from "./emoji-dfa.json";
import {setAttacherOptions} from "./plugin-helpers";

const getNodes = () => {
  const map = new Map();
  const {edges, finals} = emojiDfa;
  for (const [fromId, toId, ...codes] of edges) {
    let nodeMap = map.get(fromId);
    if (!nodeMap) {
      nodeMap = new Map();
      map.set(fromId, nodeMap);
    }
    for (const code of codes) {
      if (typeof code === "number") {
        // if (nodeMap.get(code)) console.warn(":((((", fromId, code, toId);
        nodeMap.set(code, toId);
      } else {
        for (let i = code[0]; i <= code[0] + code[1]; i += 1) {
          // if (nodeMap.get(i)) console.warn(":((((", fromId, i, toId);
          nodeMap.set(i, toId);
        }
      }
    }
  }
  return {map, finals: new Set(finals)};
};

let _mmExt = null;

export const getCdxEmojiMicroMarkExtension = () => {
  if (!_mmExt) {
    const nodeInfo = getNodes();
    const text = {};
    for (const codePoint of nodeInfo.map.get(1).keys()) {
      text[codePoint] = {name: "cdxEmoji", tokenize: tokenizer, nodeInfo};
    }
    _mmExt = {text};
  }
  return _mmExt;
};

const tokenizer = function (effects, ok, nok) {
  const {nodeInfo} = this.currentConstruct;
  let myNodeId = null;

  const start = (code) => {
    if (!myNodeId) effects.enter("cdxEmoji");
    let nextId = nodeInfo.map.get(myNodeId || 1)?.get(code);
    // console.log({myNodeId, code, hex: code?.toString(16)}, "->", nextId);
    if (!nextId) {
      if (nodeInfo.finals.has(myNodeId)) {
        effects.exit("cdxEmoji");
        return ok(code);
      } else {
        return nok(code);
      }
    }
    effects.consume(code);
    myNodeId = nextId;
    return start;
  };
  return start;
};

export const cdxEmojiFromMarkdown = {
  enter: {
    cdxEmoji: function (token) {
      this.enter({type: "cdxEmoji", value: this.sliceSerialize(token)}, token);
    },
  },
  exit: {
    cdxEmoji: function (token) {
      this.exit(token);
    },
  },
};

export default function cdxEmojiMarkdownPlugin({render} = {}) {
  const data = this.data();

  (data.micromarkExtensions = data.micromarkExtensions || []).push(getCdxEmojiMicroMarkExtension());
  (data.fromMarkdownExtensions = data.fromMarkdownExtensions || []).push(cdxEmojiFromMarkdown);

  setAttacherOptions(this, "remarkRehype", (opts) => ({
    ...opts,
    handlers: {
      ...opts.handlers,
      cdxEmoji: (state, node) => ({
        type: "element",
        tagName: "cdxEmoji",
        properties: {value: node.value},
        children: state.all(node),
      }),
    },
  }));

  if (render) {
    setAttacherOptions(this, "rehypeReact", (opts) => ({
      ...opts,
      components: {
        ...opts.components,
        cdxEmoji: ({value}) => render(value),
      },
    }));
  }
}

import {api} from "../../lib/api";
import confirm from "../../lib/confirm";
import messenger from "../../lib/messenger";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {Document, Label, ToggleButton, shrinker} from "@cdx/common";
import {hasChildCards} from "../workflows/workflow-utils";
import {hasPermissionToGuardCard} from "../../lib/permissions";
import {Col, Row} from "@cdx/ds";
import {LazyCardAsText} from "../activity-feed/shared";
import {useLocalStorageState} from "../../lib/storage";
import {useEffect, useRef} from "react";

const checkCardId = (cardId) =>
  waitForResultPromise(() => {
    const freshCard = api.getModel({modelName: "card", id: cardId});
    return {
      hasOpenResolvables: freshCard.$meta.exists("resolvables", {isClosed: false}),
      unarchivedChildCards: freshCard.childCards.filter((c) => c.visibility === "default").length,
      childCardsWithConvos: freshCard.childCards.filter((c) =>
        c.$meta.exists("resolvables", {isClosed: false})
      ).length,
    };
  });

const getMessage = (results) => {
  const hasOpenResolvablesCount = results.filter((r) => r.hasOpenResolvables).length;
  const unarchivedChildCardsCount = results.reduce((s, r) => s + r.unarchivedChildCards, 0);
  const childCardsWithConvosCount = results.reduce((s, r) => s + r.childCardsWithConvos, 0);
  const convoCard = [
    hasOpenResolvablesCount &&
      `${
        hasOpenResolvablesCount === 1
          ? `${results.length === 1 ? "this" : "one"} card`
          : `${hasOpenResolvablesCount} cards`
      }`,
    childCardsWithConvosCount &&
      `${
        childCardsWithConvosCount === 1 ? "one sub card" : `${childCardsWithConvosCount} sub cards`
      }`,
  ]
    .filter(Boolean)
    .join(" and ");
  const singularConvo = hasOpenResolvablesCount + childCardsWithConvosCount === 1;
  const convo =
    convoCard && `${convoCard} ${singularConvo ? "has" : "have"} unresolved conversations`;
  const child =
    unarchivedChildCardsCount &&
    (unarchivedChildCardsCount === 1
      ? `there's one unarchived sub card`
      : `there are ${unarchivedChildCardsCount} unarchived sub cards`);
  return {
    msg: [convo, child].filter(Boolean).join(" and "),
    consequence: [
      convo && `auto-close all unresolved conversations`,
      child && "archive all sub cards",
    ]
      .filter(Boolean)
      .join(" and "),
  };
};

export const performBulkArchiveAction = (cardIds) => {
  return Promise.all(cardIds.map(checkCardId))
    .then((results) => {
      const {msg, consequence} = getMessage(results);
      if (msg) {
        return confirm({
          title: msg[0].toUpperCase() + msg.slice(1),
          content: `${
            results === 1 ? "Archiving this card" : "Archiving these cards"
          } will ${consequence}`,
          cancelLabel: "Cancel",
          confirmLabel: "Archive",
        });
      } else {
        return Promise.resolve(true);
      }
    })
    .then((ok) => {
      if (ok) {
        return api.mutate.cards.bulkUpdate({visibility: "archived", ids: cardIds}).then(() => {
          messenger.send(
            cardIds.length === 1
              ? "One card was archived."
              : `${cardIds.length} cards were archived.`
          );
          return true;
        });
      } else {
        return false;
      }
    });
};

const performArchiveAction = (card) =>
  checkCardId(card.cardId)
    .then((result) => {
      const {msg, consequence} = getMessage([result]);
      if (msg) {
        return confirm({
          title: msg[0].toUpperCase() + msg.slice(1),
          content: `Archiving this card will ${consequence}`,
          cancelLabel: "Cancel",
          confirmLabel: "Archive card",
        });
      } else {
        return Promise.resolve(true);
      }
    })
    .then((ok) => {
      if (ok) {
        return api.mutate.cards.update({id: card.id, visibility: "archived"}).then(() => {
          messenger.send(
            <span>
              <b>{shrinker(card.title, 20)}</b> was archived.
            </span>
          );
          return true;
        });
      } else {
        return false;
      }
    });

export default performArchiveAction;

const DeleteFileToggle = ({onChange}) => {
  const [active, setActive] = useLocalStorageState("delete-files-toggle", false);
  const fnRef = useRef(onChange);
  fnRef.current = onChange;
  useEffect(() => {
    fnRef.current(Boolean(active));
  }, [active]);
  return (
    <Row align="center" sp="4px" pt="16px">
      <ToggleButton checked={active} onChange={() => setActive(!active)} />
      <Label>Also delete attached files</Label>
    </Row>
  );
};

/**
 * @param {{
 *  cardId?: import("../../cdx-models/Card").CardId
 *  cardIds?: import("../../cdx-models/Card").CardId[]
 *  onDone?: (ok: boolean) => void
 * }}
 */
export const peformCardDeletion = ({cardId, cardIds: rawIds, onDone}) => {
  const cardIds = rawIds ? rawIds : [cardId];
  let deleteFiles = false;
  waitForResultPromise(() => {
    const cards = cardIds
      .map((id) => api.getModel({modelName: "card", id}))
      .filter(Boolean)
      .map((c) => ({
        cardId: c.cardId,
        withChildCard: hasChildCards(c),
        title: c.title,
        attachmentCount: c.attachments.length,
      }));
    const unguarded = cards.some((card) => !hasPermissionToGuardCard(api.getRoot(), card));
    const withAttachments = cards.some((c) => c.attachmentCount > 0);
    return {cards, unguarded, withAttachments};
  }).then(({cards, unguarded, withAttachments}) => {
    if (unguarded) {
      confirm({content: "Card(s) can't be deleted as they are in a guarded deck."});
    }
    const withChildCards = cards.some((c) => c.withChildCard);
    const one = cardIds.length === 1;
    const thisThese = one ? "this card" : "these cards";

    confirm({
      title: `Delete card${one ? "" : "s"}`,
      content: (
        <Document size="lg">
          <Col
            colorTheme="gray50"
            bg="foreground"
            pa="12px"
            borderWidth={1}
            borderColor="default"
            rounded={4}
            size={14}
            color="primary"
          >
            {withChildCards ? (
              <p>Deleting {thisThese} will also delete all sub cards</p>
            ) : (
              <p>
                Deleted cards can still be found in the activity feed or by accessing them by their
                id.
              </p>
            )}
          </Col>
          <ul>
            {cards.map((c, idx) => (
              <li key={idx}>
                <LazyCardAsText cardId={c.cardId} />
              </li>
            ))}
          </ul>
          {withAttachments && (
            <DeleteFileToggle
              onChange={(val) => {
                deleteFiles = val;
              }}
            />
          )}
        </Document>
      ),
      confirmLabel: `Yes, delete ${one ? "it" : "them"}`,
      cancelLabel: `No, keep ${one ? "it" : "them"}`,
    }).then((ok) => {
      if (ok) {
        return api.mutate.cards
          .bulkUpdate({ids: cardIds, visibility: "deleted", deleteFiles})
          .then(() => {
            messenger.send(`Card${one ? "" : "s"} deleted.`);
            if (onDone) onDone();
          });
      } else {
        return null;
      }
    });
  });
};

import {useState, useEffect, ReactNode} from "react";
import ReactDOM from "react-dom";

const newNode = () => {
  const node = document.createElement("div");
  document.body.appendChild(node);
  return node;
};

const Portal = ({children, renderInPlace}: {children: ReactNode; renderInPlace?: ReactNode}) => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const hasChildren = !!children;
  useEffect(() => {
    if (hasChildren) {
      const n = newNode();
      setNode(n);
      return () => {
        document.body.removeChild(n);
      };
    } else {
      setNode(null);
    }
  }, [hasChildren]);
  return renderInPlace !== undefined ? (
    <>
      {renderInPlace}
      {node && ReactDOM.createPortal(children, node)}
    </>
  ) : (
    node && ReactDOM.createPortal(children, node)
  );
};

export default Portal;

import {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {create} from "zustand";
import {
  ClickOutside,
  OverlayPlacer,
  Portal,
  SpawnAnchoredOverlay,
  SpawnAnchoredOverlayWithNode,
  springConfigs,
  useGetNodeFromRef,
  useReveal,
  useToggle,
} from ".";

const createStore = () =>
  create((set) => ({
    openRef: null,
    setOpenRef: (ref) => set({openRef: ref}),
  }));

const DropDownContentContext = createContext(createStore());

const DropDownOverlay = ({renderOverlay, close, ...rest}) => {
  const [ctxVal] = useState(createStore);
  return (
    <DropDownContentContext.Provider value={ctxVal}>
      <ClickOutside onClickOutside={close}>
        {(handlers) => renderOverlay({close, ...handlers, ...rest})}
      </ClickOutside>
    </DropDownContentContext.Provider>
  );
};

let nextId = 1;

/** @type {(initialOpen?: boolean) => [isOpen: boolean, toggle: () => void, close: () => void]} */
const useDropDown = (initialOpen) => {
  const useOpenStore = useContext(DropDownContentContext);
  const {openRef, setOpenRef} = useOpenStore();
  const myIdRef = useRef();
  if (!myIdRef.current) {
    myIdRef.current = nextId += 1;
  }
  useEffect(() => {
    if (initialOpen) setOpenRef(myIdRef.current);
  }, [initialOpen, setOpenRef]);

  const isOpen = openRef === myIdRef.current;
  const toggle = () => (isOpen ? setOpenRef(null) : setOpenRef(myIdRef.current));
  const close = () => setOpenRef(null);
  return [isOpen, toggle, close];
};

/** @type {(opts?: {initial?: boolean, overlayProps: any}) => {ref: import("react").Dispatch<null | HTMLElement>, toggle: () => void, overlayElement: null, isOpen: boolean}} */
export const useNextDropDown = ({
  initial = false,
  springConfig = springConfigs.quick,
  overlayProps,
} = {}) => {
  const [isOpen, toggle, close] = useDropDown(initial);
  const [node, setNode] = useState(null);
  const reveal = useReveal(isOpen, {config: springConfig});

  const overlayElement = reveal((props) => (
    <Portal>
      <OverlayPlacer
        node={node}
        presenceProps={props}
        isOpen={isOpen}
        {...overlayProps}
        renderOverlay={(p) => (
          <DropDownOverlay
            close={isOpen ? toggle : () => {}}
            renderOverlay={overlayProps.renderOverlay}
            {...p}
          />
        )}
      />
    </Portal>
  ));

  return {ref: setNode, toggle, overlayElement, isOpen, close};
};

/**
 * @deprecated use `DropDownForChild` or `useNextDropDown` instead
 */
export const DropDown = ({children, renderOverlay, ...rest}) => {
  const [isOpen, toggle] = useDropDown();
  return (
    <SpawnAnchoredOverlay
      {...rest}
      isOpen={isOpen}
      renderOverlay={(p) => (
        <DropDownOverlay close={isOpen ? toggle : () => {}} renderOverlay={renderOverlay} {...p} />
      )}
    >
      {(measureRef) => children(isOpen, toggle, measureRef)}
    </SpawnAnchoredOverlay>
  );
};

/**
 * @type any
 */
export const DropDownForChild = forwardRef((props, passedRef) => {
  const {withHover, onClose, initialOpen, children, renderOverlay, overlayProps, setChildProps} =
    props;
  const [hovered, {on, off}] = useToggle();
  const [isClicked, toggle] = useDropDown(initialOpen);
  const {node, ref} = useGetNodeFromRef(passedRef);
  const handlers = {
    ...(withHover && {onMouseEnter: on, onMouseLeave: off}),
    onClick: (e) => {
      e?.stopPropagation?.();
      if (isClicked) {
        handleClose();
      } else {
        toggle();
      }
    },
  };
  const handleClose = () => {
    off();
    if (isClicked) toggle();
    if (onClose) return onClose();
  };
  return (
    <SpawnAnchoredOverlayWithNode
      isOpen={isClicked || hovered}
      node={node}
      renderOverlay={(p) => (
        <DropDownOverlay close={handleClose} renderOverlay={renderOverlay} {...p} />
      )}
      {...overlayProps}
    >
      {cloneElement(children, {
        ...handlers,
        ...(setChildProps &&
          setChildProps({
            isOpen: isClicked || hovered,
            isClicked,
            toggle,
            hovered,
            close: handleClose,
          })),
        ref,
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

/**
 * @deprecated use `DropDownForChild` or `useNextDropDown` instead
 */
export const WithDropDown = forwardRef(
  (
    {as: Comp, overlayProps, setProps, renderOverlay, withHover, initialOpen, onClose, ...rest},
    passedRef
  ) => {
    const [hovered, {on, off}] = useToggle();
    const [isClicked, toggle] = useDropDown(initialOpen);
    const {node, ref} = useGetNodeFromRef(passedRef);
    const handlers = {
      ...(withHover && {onMouseEnter: on, onMouseLeave: off}),
      onClick: () => {
        if (isClicked) {
          handleClose();
        } else {
          toggle();
        }
      },
    };
    const handleClose = () => {
      off();
      if (isClicked) toggle();
      if (onClose) return onClose();
    };

    return (
      <SpawnAnchoredOverlayWithNode
        isOpen={isClicked || hovered}
        node={node}
        renderOverlay={(p) => (
          <DropDownOverlay close={handleClose} renderOverlay={renderOverlay} {...p} />
        )}
        {...overlayProps}
      >
        <Comp
          ref={ref}
          {...handlers}
          {...rest}
          {...(setProps &&
            setProps({
              isOpen: isClicked || hovered,
              isClicked,
              toggle,
              hovered,
              close: handleClose,
            }))}
        />
      </SpawnAnchoredOverlayWithNode>
    );
  }
);

import {calc} from "@vanilla-extract/css-utils";
import {sizeThemeVars} from "./themes/size-themes.css";
import prismColors from "./prism-colors.json";

export const lineHeights = {none: "1", tight: "1.25", "14px": "14px", "16px": "16px"} as const;

export const boxShadowPartials = {
  soft: (color: string, intensity: string) =>
    [
      `0 ${sizeThemeVars.space["0.25"]} ${sizeThemeVars.space["0.5"]}
      rgba(${color},${calc(intensity).multiply(0.05).toString()})`,
      `0 1px 2px
      rgba(${color},${calc(intensity).multiply(0.025).toString()})`,
    ].join(", "),
};

export const legacyColors = {
  white: "#fff",
  // via http://www.zonums.com/online/color_converter/
  white_rgb: "255, 255, 255",

  gray900: "#262832",
  gray800: "#333642",
  gray700: "#4C5060",
  gray600: "#707587",
  gray500: "#979EAF",
  gray400: "#BBC1CE",
  gray300: "#DADFE7",
  gray200: "#EBEEF3",
  gray100: "#F2F4F7",
  gray900_rgb: "38, 40, 50",
  gray800_rgb: "51, 54, 66",
  gray700_rgb: "76, 80, 96",
  gray600_rgb: "112, 117, 135",
  gray500_rgb: "151, 158, 175",
  gray400_rgb: "187, 193, 206",
  gray300_rgb: "218, 223, 231",
  gray200_rgb: "235, 238, 243",
  gray100_rgb: "242, 244, 247",

  active900: "#05241A",
  active800: "#0F5F47",
  active700: "#1E8C6B",
  active600: "#30A783",
  active500: "#47BE9A",
  active400: "#65D1B0",
  active300: "#8CE6CA",
  active200: "#BAF6E3",
  active100: "#F3FDFA",

  purple900: "#120326",
  purple800: "#230947",
  purple700: "#3A176A",
  purple600: "#57318C",
  purple500: "#7D5DA9",
  purple400: "#A48BC6",
  purple300: "#C0AED9",
  purple200: "#DBD1E9",
  purple100: "#EDE6F7",
  purple900_rgb: "18, 3, 38",
  purple800_rgb: "35, 9, 71",
  purple700_rgb: "58, 23, 106",
  purple600_rgb: "87, 49, 140",
  purple500_rgb: "125, 93, 169",
  purple400_rgb: "164, 139, 198",
  purple300_rgb: "192, 174, 217",
  purple200_rgb: "219, 209, 233",
  purple100_rgb: "237, 230, 247",

  error900: "#230205",
  error800: "#610910",
  error700: "#8B121C",
  error600: "#AF222D",
  error500: "#C13642",
  error400: "#D3616A",
  error300: "#E2979D",
  error200: "#F0C7CC",
  error100: "#FFEDEF",
  error900_rgb: "35, 2, 5",
  error800_rgb: "97, 9, 16",
  error700_rgb: "139, 18, 28",
  error600_rgb: "175, 34, 45",
  error500_rgb: "193, 54, 66",
  error400_rgb: "211, 97, 106",
  error300_rgb: "226, 151, 157",
  error200_rgb: "240, 199, 204",
  error100_rgb: "255, 237, 239",

  blue900: "#010C1B",
  blue800: "#042A5D",
  blue700: "#09418E",
  blue600: "#1158BA",
  blue500: "#1E6BD6",
  blue400: "#3A89EB",
  blue300: "#61A7F6",
  blue200: "#98CAFC",
  blue100: "#E8F4FF",

  done: "#31d367", // 600

  done900: "#021F0C",
  done800: "#15873B",
  done700: "#28B758",
  done600: "#31D367",
  done500: "#47EA7E",
  done400: "#66F194",
  done300: "#90F3B1",
  done200: "#BBF7CF",
  done100: "#ECFBF1",

  started: "#2569AD", // 600

  started900: "#02172D",
  started800: "#07325D",
  started700: "#134C86",
  started600: "#2569AD",
  started500: "#3A81C7",
  started400: "#5597D9",
  started300: "#7DB2E7",
  started200: "#B1D4F6",
  started100: "#E8F3FD",

  deleted: "#43282A", // 600

  deleted900: "#0F0506",
  deleted800: "#2A1214",
  deleted700: "#391D1F",
  deleted600: "#43282A",
  deleted500: "#543E3F",
  deleted400: "#746263",
  deleted300: "#9D8F90",
  deleted200: "#CABCBD",
  deleted100: "#FDF0F1",

  archived: "#291600", // 800

  archived900: "#0B0600",
  archived800: "#291600",
  archived700: "#362512",
  archived600: "#4E3F2C",
  archived500: "#746859",
  archived400: "#9A9084",
  archived300: "#BDB6AD",
  archived200: "#DBD6CF",
  archived100: "#EFEBE7",

  review: "#159590", // 600

  review900: "#011E1D",
  review800: "#064D4A",
  review700: "#0D726E",
  review600: "#159590",
  review500: "#24ACA6",
  review400: "#46BDB9",
  review300: "#76CCC9",
  review200: "#A9DFDD",
  review100: "#E6F6F6",

  assigned: "#a8925d", // 500

  assigned900: "#1D1706",
  assigned800: "#50411B",
  assigned700: "#7C6734",
  assigned600: "#9A834B",
  assigned500: "#A8925D",
  assigned400: "#B8A473",
  assigned300: "#C9B890",
  assigned200: "#DACFB3",
  assigned100: "#F4EFE4",

  blocked: "#c01b1b", // 600

  blocked900: "#310202",
  blocked800: "#740909",
  blocked700: "#9F1010",
  blocked600: "#C01B1B",
  blocked500: "#D83333",
  blocked400: "#E56767",
  blocked300: "#ED9595",
  blocked200: "#F2BEBE",
  blocked100: "#F8DBDB",

  snoozing: "#7d1274", // 600

  snoozing900: "#290226",
  snoozing800: "#470642",
  snoozing700: "#630B5C",
  snoozing600: "#7D1274",
  snoozing500: "#98208E",
  snoozing400: "#B540AB",
  snoozing300: "#D772CF",
  snoozing200: "#EFA7E9",
  snoozing100: "#F9CFF6",

  gold900: "#4C2300",
  gold800: "#743E00",
  gold700: "#9D5F00",
  gold600: "#D59100",
  gold500: "#EFB418",
  gold400: "#F6CD36",
  gold300: "#FBDA62",
  gold200: "#FDE793",
  gold100: "#FFF4CD",
};

type Key10Nums =
  | "25"
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";
type Keys10<Prefix extends string> = `${Prefix}${Key10Nums}`;
type Out10Palette<Prefix extends string> = {[K in Keys10<Prefix>]: string};

type Key20Nums =
  | "25"
  | "50"
  | "100"
  | "150"
  | "200"
  | "250"
  | "300"
  | "350"
  | "400"
  | "450"
  | "500"
  | "550"
  | "600"
  | "650"
  | "700"
  | "750"
  | "800"
  | "850"
  | "900"
  | "950"
  | "975";
type Keys20<Prefix extends string> = `${Prefix}${Key20Nums}`;
type Out20Palette<Prefix extends string> = {[K in Keys20<Prefix>]: string};

type InPalette10 = {[Key in Key10Nums]: string};
type InPalette20 = {[Key in Key20Nums]: string};

const create10Pallette = <Prefix extends string>(
  prefix: Prefix,
  colors: InPalette10
): Out10Palette<Prefix> => {
  return {
    [`${prefix}${25}`]: colors["25"],
    [`${prefix}${50}`]: colors["50"],
    [`${prefix}${100}`]: colors["100"],
    [`${prefix}${200}`]: colors["200"],
    [`${prefix}${300}`]: colors["300"],
    [`${prefix}${400}`]: colors["400"],
    [`${prefix}${500}`]: colors["500"],
    [`${prefix}${600}`]: colors["600"],
    [`${prefix}${700}`]: colors["700"],
    [`${prefix}${800}`]: colors["800"],
    [`${prefix}${900}`]: colors["900"],
  } as Out10Palette<Prefix>;
};

const create20Pallette = <Prefix extends string>(
  prefix: Prefix,
  colors: InPalette20
): Out20Palette<Prefix> => {
  return {
    [`${prefix}${25}`]: colors["25"],
    [`${prefix}${50}`]: colors["50"],
    [`${prefix}${100}`]: colors["100"],
    [`${prefix}${150}`]: colors["150"],
    [`${prefix}${200}`]: colors["200"],
    [`${prefix}${250}`]: colors["250"],
    [`${prefix}${300}`]: colors["300"],
    [`${prefix}${350}`]: colors["350"],
    [`${prefix}${400}`]: colors["400"],
    [`${prefix}${450}`]: colors["450"],
    [`${prefix}${500}`]: colors["500"],
    [`${prefix}${550}`]: colors["550"],
    [`${prefix}${600}`]: colors["600"],
    [`${prefix}${650}`]: colors["650"],
    [`${prefix}${700}`]: colors["700"],
    [`${prefix}${750}`]: colors["750"],
    [`${prefix}${800}`]: colors["800"],
    [`${prefix}${850}`]: colors["850"],
    [`${prefix}${900}`]: colors["900"],
    [`${prefix}${950}`]: colors["950"],
    [`${prefix}${975}`]: colors["975"],
  } as Out20Palette<Prefix>;
};

export const dsColors = {
  white: "#fff",
  ...create20Pallette("gray", prismColors.cdxColorGrey),
  ...create20Pallette("purple", prismColors.cdxColorPurple),
  ...create20Pallette("dimPurple", prismColors.cdxColorDpurple),
  ...create10Pallette("active", prismColors.cdxColorActive),
  ...create10Pallette("alert", prismColors.cdxColorAlert),
  ...create10Pallette("warning", prismColors.cdxWarning),
};

export const cardStateColors = {
  ...create10Pallette("doc", prismColors.cdxDocumentation),
  ...create10Pallette("hero", prismColors.cdxColorHero),
  ...create10Pallette("done", prismColors.cdxColorDone),
  ...create10Pallette("blocked", prismColors.cdxColorBlocked),
  ...create10Pallette("review", prismColors.cdxColorReview),
  ...create10Pallette("snoozing", prismColors.cdxColorSnoozing),
  ...create10Pallette("started", prismColors.cdxColorStarted),
  ...create10Pallette("assigned", prismColors.cdxColorAssigned),
  ...create10Pallette("snoozingColorblind", prismColors.cdxSnoozingColorblind),
  ...create10Pallette("doneColorblind", prismColors.cdxDoneColorblind),
  ...create10Pallette("docColorblind", prismColors.cdxDocumentationColorblind),
};

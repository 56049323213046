import {forwardRef} from "react";
import MiniProjectTile from "./MiniProjectTile";
import {XRow} from "./xui";
import {orderProjects} from "../lib/hooks/useSelectedProjects";
import {WithLabel, XCol, XText, ToggleButton} from "@cdx/common";
import {splitArray} from "../lib/collection-utils";
import {api} from "../lib/api";
import {roleIsAtLeast} from "../lib/permissions";

export const DefaultProjectTile = ({project}) => (
  <XRow align="center" sp={0} minWidth>
    <MiniProjectTile project={project} size={16} />
    <XText
      noOverflow
      preset="bold"
      size={1}
      style={
        project.$meta.get("visibility", "default") !== "default"
          ? {textDecoration: "line-through"}
          : {}
      }
    >
      {project.name}
    </XText>
  </XRow>
);

const ProjectRow = ({
  isActive,
  type,
  value,
  onChange,
  project,
  buttonRef,
  disabled,
  tooltip,
  projectTileComp: ProjectComp = DefaultProjectTile,
  projectTileProps = {},
}) => {
  const handleChange = () =>
    onChange(isActive ? value.filter((pId) => pId !== project.id) : [...value, project.id]);

  const handleMetaClick = () => onChange([project.id]);
  return (
    <XRow sp={1} align="start">
      <div style={{paddingTop: 3}}>
        <ToggleButton
          checked={isActive}
          onChange={handleChange}
          onMetaClick={handleMetaClick}
          ref={buttonRef}
          disabled={type !== "default" || disabled}
          tooltip={tooltip}
        />
      </div>
      {type !== "inaccessible" ? (
        <ProjectComp project={project} {...projectTileProps} isActive={isActive} />
      ) : (
        <XText preset="bold" size={1} color="gray500">
          Inaccessible Project
        </XText>
      )}
    </XRow>
  );
};

export const ProjectMultiPickerRow = ProjectRow;

export const getProjectAccessTypesForUser = (root) => {
  const accountId = root.account.$meta.get("id", null);
  const projectAccess = root.loggedInUser.$meta
    .find("withProjectAccess", {project: {visibility: "default", accountId}})
    .map(({projectRole, role, project}, idx) => ({
      key: project.$meta.get("id", idx),
      type:
        roleIsAtLeast(role, "admin") ||
        projectRole === "producer" ||
        root.account.staffPermission === "full"
          ? "default"
          : "read-only",
      project,
    }));
  return projectAccess;
};

export const ProjectMultiPicker = forwardRef((props, ref) => {
  const {value, onChange, cols = 2, root, disabled, projectTileComp, projectTileProps} = props;
  const idSet = new Set(value);
  const projectAccess = getProjectAccessTypesForUser(root);
  const visibleProjectIds = new Set(projectAccess.map(({project: p}) => p.id));
  const extra = value
    .filter((id) => !visibleProjectIds.has(id))
    .map((id) => {
      const project = api.getModel({modelName: "project", id});
      return {
        key: id,
        project: project || {id},
        type: !project || project.$meta.isDeleted() ? "inaccessible" : "read-only",
      };
    });
  return (
    <XRow sp={3}>
      {splitArray(orderProjects(root, [...projectAccess, ...extra]), cols).map((group, i1, i2) => (
        <XCol sp={1} key={i1} fillParent style={{minWidth: 100}}>
          {group.map(({key, project, type}) => (
            <ProjectRow
              key={key}
              project={project}
              type={type}
              onChange={onChange}
              value={value || []}
              buttonRef={i1 === 0 && i2 === 0 ? ref : null}
              disabled={disabled}
              isActive={idSet.has(project.id)}
              projectTileComp={projectTileComp}
              projectTileProps={projectTileProps}
            />
          ))}
        </XCol>
      ))}
    </XRow>
  );
});

export const ProjectMultiPickerWithLabel = forwardRef(
  ({showErrors, name, label, hint, hasPendingValidation, useAllProjects, ...rest}, ref) => (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <ProjectMultiPicker {...rest} ref={ref} />
    </WithLabel>
  )
);

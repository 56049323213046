import {unified} from "unified";
import markdown from "remark-parse";
import {waitForResult} from "../../lib/wait-for-result";
import {useMemo, useEffect, useState} from "react";
import cdxMentionMarkdownPlugin from "./plugins/cdxMentionPlugin";
import cdxGithubMarkdownPlugin from "./plugins/cdxGithubMarkdownPlugin";
import {api} from "../../lib/api";
import {cdxPreviewCompiler} from "./markdownToPreviewCompiler";
import {cdxBreakPlugin} from "./plugins/cdxBreakPlugin";

const markdownToPreview = (content, maxLength) => {
  let result = null;
  const p = new Promise(
    (resolve) =>
      waitForResult(
        () =>
          unified()
            .use(markdown)
            .use(cdxBreakPlugin)
            .use(cdxMentionMarkdownPlugin, {
              render: (id) => {
                const user = api.getModel({modelName: "user", id});
                return user && !user.$meta.isDeleted() ? `@${user.name}` : `@unknown`;
              },
            })
            .use(cdxGithubMarkdownPlugin)
            .use(cdxPreviewCompiler, {maxLength})
            .processSync(content).result,
        (contents) => {
          result = contents;
          resolve(contents);
        }
      ),
    result
  );
  return result ? {type: "immediate", value: result} : {type: "promise", value: p};
};

export const MarkdownToPreview = ({content, maxLength}) => (
  <>{useMarkdownToPreview(content, maxLength)}</>
);

export const useMarkdownToPreview = (content, maxLength) => {
  const val = useMemo(() => content && markdownToPreview(content, maxLength), [content, maxLength]);
  const [result, setResult] = useState(val);
  useEffect(() => {
    setResult(val);
    if (!val) return;
    if (val.type === "promise") {
      setResult(val);
      let alive = true;
      val.value.then((value) => {
        if (alive) setResult({type: "resolved", value});
      });
      return () => {
        alive = false;
      };
    }
  }, [val]);
  return !result || result.type === "promise" ? null : result.value;
};

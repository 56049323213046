import {cloneElement, forwardRef, lazy, ReactElement, ReactNode, Suspense} from "react";
import useMutation from "../../lib/hooks/useMutation";
import {DefaultOverlay, SpawnAnchoredOverlayWithNode, useGetNodeFromRef} from "@cdx/common";
import {Col, DSSpinner} from "@cdx/ds";
import {CardPropChangeKey} from "../../components/RichTextarea/Lexical/CardPropChangeOverlay";
import {create} from "zustand";
import {OnShortCut} from "../../components/RichTextarea/Lexical/CardPropChangeForChild";
import {Deck} from "../../cdx-models/Deck";

const LazyOverlay = lazy(() => import("./DefaultCardPropChangeOverlay"));

type DefaultCardPropOverlayProps = {
  deck: Deck;
  overlayProps: any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
};

const DefaultCardPropOverlay = ({
  deck,
  overlayProps,
  onClose,
  initialTab,
}: DefaultCardPropOverlayProps) => {
  const [doUpdate] = useMutation("decks", "update");

  const handleUpdate = (data: any) => {
    const {assignee, ...rest} = data;
    if (assignee !== undefined) rest.assigneeId = assignee;
    doUpdate({id: deck.id, defaultCard: {...deck.defaultCard, ...rest}});
  };

  const cardPreset = deck.$meta.get("defaultCard", false);

  return (
    <DefaultOverlay
      {...overlayProps}
      onClick={(e: any) => e.stopPropagation()}
      onMouseMove={(e: any) => e.stopPropagation()}
    >
      {cardPreset !== false ? (
        <Suspense
          fallback={
            <Col style={{height: 400, width: 250}} align="center" justify="center">
              <DSSpinner size={24} />
            </Col>
          }
        >
          <LazyOverlay
            deck={deck}
            item={cardPreset || {}}
            onChange={handleUpdate}
            onClose={onClose}
            initialTab={initialTab}
          />
        </Suspense>
      ) : (
        <div>Loading</div>
      )}
    </DefaultOverlay>
  );
};

const useStore = create<{open: null | {cardKey: string; tab: CardPropChangeKey | null}}>((set) => ({
  open: null,
}));
type DefaultCardPropChangeOverlayForChildProps = {
  children: ReactNode;
  className?: string;
  placement?: string;
  deck: Deck;
  cardContainerKey: string;
  availableShortcuts?: CardPropChangeKey[];
  initialTab?: CardPropChangeKey | null;
  getChildProps?: (open: boolean) => any;
  onClick?: (e: any) => void;
  disabled?: boolean;
};
export const DefaultCardPropChangeOverlayForChild = forwardRef<
  HTMLElement,
  DefaultCardPropChangeOverlayForChildProps
>((props, passedRef) => {
  const {
    children,
    placement = "bottom",
    deck,
    availableShortcuts,
    initialTab,
    getChildProps,
    onClick,
    disabled,
    cardContainerKey,
  } = props;
  const {node, ref} = useGetNodeFromRef(passedRef);
  const {open} = useStore();

  if (process.env.REACT_APP_MODE === "open" || disabled) return children as ReactElement;
  if (!deck) return children as ReactElement;
  const key = `${cardContainerKey}:${deck.id}`;

  const events = {
    onClick: (e: MouseEvent) => {
      onClick?.(e);
      if (e.defaultPrevented) return;
      e.stopPropagation();
      e.preventDefault();
      const getInitialTab = () => {
        if (initialTab) return initialTab;
        if (availableShortcuts?.length) {
          return availableShortcuts[0];
        }
        return "deck";
      };
      useStore.setState({
        open: open && open.cardKey === key ? null : {cardKey: key, tab: getInitialTab()},
      });
    },
  };

  return (
    <SpawnAnchoredOverlayWithNode
      node={node}
      isOpen={open?.cardKey === key}
      distanceFromAnchor={5}
      renderOverlay={(op: any) => (
        <DefaultCardPropOverlay
          deck={deck}
          onClose={() => useStore.setState({open: null})}
          overlayProps={op}
          initialTab={open?.tab}
        />
      )}
      placement={placement}
      preventPlacement="top"
    >
      {(availableShortcuts || []).map((changeKey) => (
        <OnShortCut
          key={changeKey}
          changeKey={changeKey}
          onPress={() => useStore.setState({open: {cardKey: key, tab: changeKey}})}
        />
      ))}
      {cloneElement(children as any, {
        ...events,
        ...getChildProps?.(open?.cardKey === key),
        ref,
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

import {useEffect, useRef} from "react";
import {getSessionStorageMap} from "../storage";
import {create} from "zustand";

const {storageGet, storageRemove, storageSet} = getSessionStorageMap("ss-");

type CacheStoreActions = {
  getDataForKey: <T>(key: string | null) => T | null;
  hasDataForKey: (key: string | null) => Boolean | null;
  setDataForKey: <T>(key: string, value: T) => void;
  deleteKey: (key: string | null, opts?: {explicitClear?: boolean}) => void;
};

type CacheStore = {
  hasDataMap: {map: Map<string, boolean>};
  justCleared: Set<string>;
  actions: CacheStoreActions;
};

const useCachedStorageStore = create<CacheStore>((set, get) => ({
  hasDataMap: {map: new Map<string, boolean>()},
  justCleared: new Set<string>(),
  actions: {
    getDataForKey: <T>(key: string | null): T | null => {
      if (key === null) return null;
      const map = get().hasDataMap.map;
      const val = map.get(key);
      if (val === false) return null;
      const storedVal = storageGet(key);
      if (!storedVal) {
        map.set(key, false);
        return null;
      } else {
        map.set(key, true);
        return storedVal as T;
      }
    },
    hasDataForKey: (key: string | null): Boolean | null => {
      if (key === null) return null;
      const map = get().hasDataMap.map;
      const val = map.get(key);
      if (val !== undefined) return val;
      const storedVal = storageGet(key);
      if (!storedVal) {
        map.set(key, false);
        return null;
      } else {
        map.set(key, true);
        return true;
      }
    },
    setDataForKey: <T>(key: string, value: T) =>
      set((prev) => {
        prev.hasDataMap.map.set(key, true);
        storageSet(key, value);
        return {hasDataMap: {map: prev.hasDataMap.map}};
      }),
    deleteKey: (key: string | null, {explicitClear} = {}) =>
      set((prev) => {
        if (key === null) return prev;
        if (explicitClear) {
          prev.justCleared.add(key);
          setTimeout(() => {
            prev.justCleared.delete(key);
          }, 2000);
        }
        prev.hasDataMap.map.set(key, false);
        storageRemove(key);
        return {
          hasDataMap: {map: prev.hasDataMap.map},
        };
      }),
  },
}));

export type PersistStore<T = unknown> = {
  getStoredValue: (keyEl: T) => unknown;
  clearStoredValue: (keyEl: T) => void;
  useHasStoredValues: (keyEl: T) => boolean;
  usePersistValues: (keyEl: T, saveValue: () => null | any) => void;
};

export const createPersistStore = <KeyEl>(opts: {
  elementToKey: (keyEl: KeyEl) => string | null;
}) => {
  const {elementToKey} = opts;

  const store: PersistStore<KeyEl> = {
    getStoredValue: (keyEl: KeyEl) => {
      return useCachedStorageStore.getState().actions.getDataForKey(elementToKey(keyEl));
    },
    clearStoredValue: (keyEl: KeyEl) => {
      useCachedStorageStore
        .getState()
        .actions.deleteKey(elementToKey(keyEl), {explicitClear: true});
    },
    useHasStoredValues: (keyEl: KeyEl) => {
      return useCachedStorageStore((s) => Boolean(s.actions.hasDataForKey(elementToKey(keyEl))));
    },
    usePersistValues: (keyEl: KeyEl, saveValue: () => null | any) => {
      const key = elementToKey(keyEl);
      const latestValsRef = useRef({saveValueFn: saveValue, keyEl});
      useEffect(() => {
        latestValsRef.current = {saveValueFn: saveValue, keyEl};
      });
      useEffect(() => {
        if (!key) return;
        const storeFn = () => {
          const state = useCachedStorageStore.getState();
          const saveVal = latestValsRef.current.saveValueFn();
          if (saveVal) {
            state.actions.setDataForKey(key, saveVal);
          } else {
            if (state.hasDataMap.map.get(key)) {
              state.actions.deleteKey(key);
            }
          }
        };
        let timeoutId = setInterval(storeFn, 5000);
        return () => {
          if (!useCachedStorageStore.getState().justCleared.has(key)) {
            storeFn();
          }
          clearInterval(timeoutId);
        };
      }, [key]);

      useEffect(() => {
        const handleUnload = (e: BeforeUnloadEvent) => {
          const latest = latestValsRef.current;
          const lKey = elementToKey(latest.keyEl);
          if (lKey && latestValsRef.current.saveValueFn()) {
            e.preventDefault();
            e.returnValue = "You have unsaved changes. Are you sure you want to close this tab?";
            return e.returnValue;
          }
        };
        window.addEventListener("beforeunload", handleUnload);
        return () => {
          window.removeEventListener("beforeunload", handleUnload);
        };
      }, []);
    },
  };
  return store;
};

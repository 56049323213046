import {useRef} from "react";

const useOnKeepDown = (fn) => {
  const downTimeRef = useRef(null);
  const nextCallTimeoutIdRef = useRef(null);

  const cancel = () => {
    if (nextCallTimeoutIdRef.current) {
      clearTimeout(nextCallTimeoutIdRef.current);
      nextCallTimeoutIdRef.current = null;
    }
    downTimeRef.current = null;
  };

  const scheduleNext = () => {
    const timeSinceDown = new Date().getTime() - downTimeRef.current;
    const nextCallInMs = Math.max(100, (1 / Math.log(timeSinceDown / 250 + 2)) * 300);
    nextCallTimeoutIdRef.current = setTimeout(() => {
      nextCallTimeoutIdRef.current = null;
      fn();
      scheduleNext();
    }, nextCallInMs);
  };

  return {
    onMouseDown: () => {
      downTimeRef.current = new Date().getTime();
      scheduleNext();
    },
    onClick: () => {
      if (!downTimeRef.current) fn();
      cancel();
    },
    onMouseUp: cancel,
    onMouseLeave: cancel,
  };
};

export default useOnKeepDown;

import {forwardRef} from "react";
import LinkOrButton from "./LinkOrButton";
import ButtonBehaviour from "./ButtonBehaviour";
import {rawButtonStyle, plainButtonStyles} from "./button.css";
import cx from "../cx";

const PlainButton = forwardRef(({color, size, active, square, full, className, ...rest}, ref) => (
  <LinkOrButton
    ref={ref}
    className={cx(
      rawButtonStyle,
      plainButtonStyles.default,
      rest.disabled && plainButtonStyles.disabled,
      active
        ? plainButtonStyles.active
        : color
          ? plainButtonStyles.colors[color]
          : plainButtonStyles.colors.default,
      size ? plainButtonStyles.sizes[size] : plainButtonStyles.sizes.md,
      square && plainButtonStyles.squarePadding[size || "md"],
      full && plainButtonStyles.full,
      className
    )}
    {...rest}
  />
));

const XPlainButton = forwardRef((props, ref) => (
  <ButtonBehaviour comp={PlainButton} ref={ref} {...props} />
));

export default XPlainButton;

import {Box, Col, DSIconBell, DSIconPyramid, NonInteractiveDSTag, Row, Text, css} from "@cdx/ds";
import {cx} from "@cdx/common";
import {
  ThemedMilestoneIcon,
  getMilestoneState,
  msToDate,
  setMsThemeColor,
} from "../../../features/milestones/milestone-utils";
import dsStyles from "@cdx/ds/css/index.css";
import {StatGroups, getLastProgress} from "./MilestoneOverviewComponents";
import {MarkdownToPreview} from "../../../components/Markdown/markdownToPreview";
import {checkIfPresent, useRoot} from "../../../lib/mate/mate-utils";
import {CdxImgByFile} from "../../../components/CdxImg";
import {api} from "../../../lib/api";
import {TimeContainer} from "../SprintTooltip";

export const MilestoneOverviewTooltipContent = ({milestone: ms, note, zoneInfo}) => {
  const root = useRoot();
  const {isLoaded, result} = checkIfPresent(() => ms.coverFile, api);
  const coverFile = isLoaded ? result : null;
  const state = getMilestoneState(root.account, ms);
  const getLabel = () => {
    switch (state) {
      case "next":
        return "Upcoming Milestone";
      case "current":
        return "Upcoming Milestone";
      case "past":
        return "Past Milestone";
      default:
        return "Milestone";
    }
  };
  return (
    <Col style={{width: 360}}>
      {coverFile && (
        <CdxImgByFile
          file={coverFile}
          maxWidth={600}
          imgClassName={cx(dsStyles.width["100%"], dsStyles.maxHeight["64px"])}
          loadingClassName={cx(dsStyles.maxHeight["64px"])}
        />
      )}
      <Col className={setMsThemeColor(ms)} divideX minWidth="0">
        <Row sp="8px" align="start" px="16px" py="12px" relative minWidth="0">
          <ThemedMilestoneIcon
            theme={setMsThemeColor(ms)}
            size={16}
            milestoneState={getMilestoneState(root.account, ms)}
            className={css({position: "relative", top: "4px"})}
          />
          <Col flex="auto" minWidth="0">
            <Row sp="8px" align="start">
              <Box size={16} bold color="primary">
                {ms.name}
              </Box>
              <NonInteractiveDSTag theme="dim" className={css({ml: "auto"})}>
                {getLabel()}
              </NonInteractiveDSTag>
            </Row>
            {ms.description && (
              <Box size={14} color="primary">
                <MarkdownToPreview content={ms.description} maxLength={32} />
              </Box>
            )}
          </Col>
        </Row>
        <Row px="16px" py="12px" sp="8px" align="center">
          <DSIconBell size={16} className={css({color: "secondary"})} />
          <Col sp="8px" flex="auto">
            <TimeContainer label="Due" date={msToDate(ms)} root={root} />
          </Col>
        </Row>
        <StatGroups
          account={root.account}
          stats={ms.stats}
          lastProgress={getLastProgress(ms)}
          className={cx(dsStyles.px["16px"], dsStyles.py["24px"])}
        />
        {zoneInfo && (
          <Row sp="8px" align="center" px="16px" py="12px">
            <DSIconPyramid size={16} className={css({color: "secondary"})} />
            <Text type="label14" color="primary">
              {zoneInfo}
            </Text>
          </Row>
        )}
      </Col>
      {note && (
        <Box
          size={14}
          colorTheme="gray50"
          color="secondary"
          bg="foreground"
          px="16px"
          py="12px"
          lineHeight="none"
        >
          {note}
        </Box>
      )}
    </Col>
  );
};

import {getChildCards} from "../workflows/workflow-utils";

export const getSegmentDuration = (seg) =>
  (seg.finishedAt || new Date()).getTime() - seg.startedAt.getTime() + seg.modifyDurationMsBy;

export const timeByUser = (segments) => {
  const byUser = {};
  segments.forEach((seg) => {
    const {user, startedAt, finishedAt, modifyDurationMsBy} = seg;
    if (!user) return;
    if (!byUser[user.id]) byUser[user.id] = {user: user, msCount: 0, isRunning: false};
    const userData = byUser[user.id];
    userData.msCount += (finishedAt || new Date()) - startedAt + modifyDurationMsBy;
    if (!finishedAt) userData.isRunning = true;
  });
  return byUser;
};

export const timeTrackingInfo = (cards) => {
  const cardMap = new Map();
  cards.forEach((c) => {
    cardMap.set(c.id, c);
    getChildCards(c).forEach((child) => {
      cardMap.set(child.id, child);
    });
  });
  const allCards = [...cardMap.values()];
  return {
    totalTime: allCards.reduce(
      (s, c) => s + getTotalTimeForTimeTrackingSum(c.totalTimeTrackingSums),
      0
    ),
    getTimeByUser: () => {
      const allTimes = [];
      allCards.forEach((card) =>
        allTimes.push(...card.$meta.find("userTimeTrackingSums", {$order: "-sumMs"}))
      );
      /**
       * @type {{[userId: string]: {user: import("../../cdx-models/User").User, time: number, isRunning: boolean}}}
       */
      const byUser = {};
      allTimes.forEach((ut) => {
        const key = ut.user.id;
        const time = getTotalTimeForTimeTrackingSum(ut);
        const exist = byUser[key];
        if (exist) {
          exist.time += time;
        } else {
          byUser[key] = {user: ut.user, time, isRunning: false};
        }
        if (ut.runningStartedAt) byUser[key].isRunning = true;
      });
      return byUser;
    },
  };
};

export const getTotalTimeForTimeTrackingSum = (ttts) => {
  if (!ttts) return 0;
  const {sumMs, runningModifyDurationMsBy} = ttts;
  const runningStartedAt = ttts.$meta.get("runningStartedAt", null);
  const currTime = runningStartedAt
    ? new Date().getTime() - runningStartedAt.getTime() + runningModifyDurationMsBy
    : 0;
  return sumMs + currTime;
};

export const getTotalTimeForCard = (card) => {
  const myTime = getTotalTimeForTimeTrackingSum(card.totalTimeTrackingSums);
  const childCards = getChildCards(card);
  return childCards.reduce(
    (sum, child) => sum + getTotalTimeForTimeTrackingSum(child.totalTimeTrackingSums),
    myTime
  );
};

function getTotalOffset(node) {
  const {body} = document;
  if (node === window) {
    return {
      top: 0,
      left: 0,
      width: document.body.clientWidth,
      height: window.innerHeight,
      scrollHeight: window.innerHeight,
      scrollWidth: document.body.clientWidth,
    };
  } else {
    const box = node.getBoundingClientRect();
    return {
      top: box.top + (window.pageYOffset || body.scrollTop) - (body.clientTop || 0),
      left: box.left + (window.pageXOffset || body.scrollLeft) - (body.clientLeft || 0),
      width: (box.width === null ? node.offsetWidth : box.width) || 0,
      height: (box.height === null ? node.offsetHeight : box.height) || 0,
      scrollHeight: node.scrollHeight,
      scrollWidth: node.scrollWidth,
    };
  }
}

export function getScrollParent(node) {
  let offsetParent = node;
  while ((offsetParent = offsetParent.offsetParent)) {
    const overflowYVal = window.getComputedStyle(offsetParent, null).getPropertyValue("overflow-y");
    if (overflowYVal === "auto" || overflowYVal === "scroll") return offsetParent;
  }
  return window;
}

export function getScrollParents(node) {
  const scrollParents = [window];
  let offsetParent = node;
  while ((offsetParent = offsetParent.offsetParent)) {
    const overflowYVal = window.getComputedStyle(offsetParent, null).getPropertyValue("overflow-y");
    if (overflowYVal === "auto" || overflowYVal === "scroll") scrollParents.push(offsetParent);
  }
  return scrollParents;
}

const rafIdByNode = new Map();

function easeOutQuart(t) {
  return 1 - --t * t * t * t;
}

const smoothScroll = (scrollParent, targetY) => {
  const ongoing = rafIdByNode.get(scrollParent);
  if (ongoing) window.cancelAnimationFrame(ongoing);
  const startY = scrollParent === window ? window.pageYOffset : scrollParent.scrollTop;
  const diff = targetY - startY;
  const totalDuration = Math.max(100, Math.min(Math.abs(diff * 0.5), 300));
  const startScrollTime = new Date().getTime();

  const fn = () => {
    const timePassed = new Date().getTime() - startScrollTime;
    const relTime = Math.min(1, timePassed / totalDuration);
    const nextY = startY + diff * easeOutQuart(relTime);
    if (scrollParent === window) {
      window.scrollTo(0, nextY);
    } else {
      scrollParent.scrollTop = nextY;
    }
    if (relTime < 1) {
      rafIdByNode.set(scrollParent, window.requestAnimationFrame(fn));
    } else {
      rafIdByNode.delete(scrollParent);
    }
  };
  rafIdByNode.set(scrollParent, window.requestAnimationFrame(fn));
};

export const smoothScrollToNode = (node, {offset = 50, immediate, onlyIfInvisible} = {}) => {
  const sp = getScrollParent(node);
  if (!sp) {
    console.warn("couldn't find scroll parent for ", node);
    return;
  }
  const nodeOffset = getTotalOffset(node);
  const spOffset = getTotalOffset(sp);
  const relTop = nodeOffset.top - spOffset.top - (sp === window ? window.scrollY : 0);
  const relScrollY = sp === window ? window.scrollY : sp.scrollTop;
  if (onlyIfInvisible) {
    const spHeight = sp === window ? window.innerHeight : sp.getBoundingClientRect().height;

    const isBelow = relTop + nodeOffset.height > spHeight;
    const isAbove = relTop < 0;

    if (isBelow) {
      smoothScroll(sp, relScrollY + relTop - spHeight + nodeOffset.height + offset / 4);
    } else if (isAbove) {
      smoothScroll(sp, relScrollY + relTop - offset);
    }
    return;
  } else {
    const targetY = relScrollY + relTop - offset;
    if (immediate) {
      if (sp === window) {
        window.scrollTo(0, targetY);
      } else {
        sp.scrollTop = targetY;
      }
    } else {
      smoothScroll(sp, targetY);
    }
  }
  if (sp !== window) smoothScrollToNode(sp, {onlyIfInvisible: true});
};

import {api} from "./api";
import {waitForResultPromise} from "./wait-for-result";
import confirm from "./confirm";
import {XCol, XText, XPush, XRow} from "../components/xui";
import XPlainButton from "../components/xui/XPlainButton";
import MilestoneLabel from "../pages/milestones/MilestoneLabel";
import {AvatarWithName} from "../components/Avatar";
import {DISCORD_USER_ID} from "./ids";
import {projectsUsers, accountUsers} from "./utils";
import {useInstance, useRoot} from "./mate/mate-utils";
import {hasPermissionToManageUser} from "./permissions";

const modificationReasons = (cardIds, targetProject) => {
  const root = api.getRoot();
  const activeUserIds = new Set(
    accountUsers(root.account, {allowObservers: false}).map((u) => u.id)
  );
  const validUserIdSet = new Set(
    projectsUsers(root.account, [targetProject], {allowObservers: true}).map((u) => u.id)
  );
  const validMilestoneIds = new Set(targetProject.milestoneProjects.map((mp) => mp.milestoneId));
  validUserIdSet.add(DISCORD_USER_ID);
  const modifications = [];
  cardIds.forEach((id) => {
    const card = api.getModel({modelName: "card", id});
    if (!card) return;
    if ((card.deck && card.deck.project) !== targetProject) {
      if (
        card.assignee &&
        !validUserIdSet.has(card.assignee.id) &&
        activeUserIds.has(card.assignee.id)
      ) {
        modifications.push({type: "unassign", card: card, data: card.assignee});
      }
      if (card.milestone && !validMilestoneIds.has(card.milestone.id)) {
        modifications.push({type: "milestone", card: card, data: card.milestone});
      }
      const removeUsers = new Set();
      card.$meta.find("resolvables", {isClosed: false}).forEach((r) =>
        r.participants.forEach((p) => {
          if (!validUserIdSet.has(p.user.id)) {
            removeUsers.add(p.user);
          }
        })
      );
      removeUsers.forEach((user) => {
        modifications.push({type: "optOut", card: card, data: user});
      });
    }
  });
  return modifications;
};

const groupReasons = (reasons) => {
  const byMsId = {};
  const byUserId = {};
  reasons.forEach(({type, data}) => {
    if (type === "milestone") {
      if (!byMsId[data.id]) byMsId[data.id] = {ms: data, count: 0};
      byMsId[data.id].count += 1;
    } else {
      if (!byUserId[data.id]) byUserId[data.id] = {user: data, convoCount: 0, unassignCount: 0};
      if (type === "unassign") {
        byUserId[data.id].unassignCount += 1;
      } else {
        byUserId[data.id].convoCount += 1;
      }
    }
  });
  return {
    msReasons: Object.values(byMsId),
    userReasons: Object.values(byUserId),
  };
};

const MsReason = ({ms, count, targetProject, canModifyMilestoneProjects, root}) => {
  const msProjectIds = ms.milestoneProjects.map((mp) => mp.projectId);
  const {isGlobal} = ms;
  const onAddProjectToMs = () =>
    api.mutate.milestones.update({
      id: ms.id,
      isGlobal,
      projectIds: [...msProjectIds, targetProject.id],
    });
  return (
    <XRow sp={3} align="start">
      <XCol sp={1}>
        <XText size={1} preset="bold" color="gray800">
          Milestone is specific to old project
        </XText>
        <XRow align="baseline" sp={2}>
          <XText size={2}>
            <MilestoneLabel ms={ms} account={root.account} />
          </XText>
          <XText size={1} color="gray600">
            {count} card{count === 1 ? "" : "s"} affected
          </XText>
        </XRow>
      </XCol>
      <XPush />
      {canModifyMilestoneProjects ? (
        <XPlainButton size="sm" onClick={onAddProjectToMs}>
          Add '{targetProject.name}' project to milestone '{ms.name}'
        </XPlainButton>
      ) : (
        <XText preset="bold" size={1}>
          Ask admin to add '{targetProject.name}' milestone '{ms.name}'
        </XText>
      )}
    </XRow>
  );
};

const UserReason = ({user, unassignCount, convoCount, canManageUsers, targetProject}) => {
  const onGiveAccess = () =>
    api.mutate.roles.addProjectAccess({userId: user.id, projectId: targetProject.id});
  return (
    <XRow sp={3} align="start">
      <XCol sp={1}>
        <XText size={1} preset="bold" color="gray800">
          User has no access to target project
        </XText>
        <XRow align="center" sp={2}>
          <AvatarWithName user={user} />
          {unassignCount > 0 && (
            <XText size={1} color="gray600">
              assigned to {unassignCount} card{unassignCount === 1 ? "" : "s"}
            </XText>
          )}
          {convoCount > 0 && (
            <XText size={1} color="gray600">
              involved in conversations in {convoCount} card{convoCount === 1 ? "" : "s"}
            </XText>
          )}
        </XRow>
      </XCol>
      <XPush />
      {canManageUsers ? (
        <XPlainButton size="sm" onClick={onGiveAccess}>
          Give {user.name} access to '{targetProject.name}'
        </XPlainButton>
      ) : (
        <XText preset="bold" size={1}>
          Ask admin to give {user.name} access to '{targetProject.name}'
        </XText>
      )}
    </XRow>
  );
};

const Dialog = ({cardIds, targetProjectId, onCancel, onConfirm, target}) => {
  const targetProject = useInstance("project", targetProjectId);
  const root = useRoot();
  const reasons = modificationReasons(cardIds, targetProject);
  const {msReasons, userReasons} = groupReasons(reasons);
  const affected = [];
  if (msReasons.length) affected.push("Milestones");
  if (userReasons.length) affected.push("Users");
  const canManageUsers = hasPermissionToManageUser(root);
  const canModifyMilestoneProjects = hasPermissionToManageUser(root);

  return (
    <XCol sp={4}>
      <XCol sp={4}>
        {msReasons.map((r) => (
          <MsReason
            key={`ms-${r.ms.id}`}
            ms={r.ms}
            count={r.count}
            canModifyMilestoneProjects={canModifyMilestoneProjects}
            targetProject={targetProject}
            root={root}
          />
        ))}
        {userReasons.map((r) => (
          <UserReason
            key={`u-${r.user.id}`}
            user={r.user}
            unassignCount={r.unassignCount}
            convoCount={r.convoCount}
            canManageUsers={canManageUsers}
            targetProject={targetProject}
          />
        ))}
        {affected.length === 0 && <XText preset="bold">All issues resolved!</XText>}
      </XCol>
      <XRow sp={5} align="center">
        <XPlainButton onClick={onCancel}>Close</XPlainButton>
        <XPush />
        <XPlainButton color="red" size="xl" onClick={onConfirm}>
          Move {target}
          {affected.length > 0 && ` and Remove ${affected.join(" and ")} from Affected Cards`}
        </XPlainButton>
      </XRow>
    </XCol>
  );
};

const canCardsBeMovedToProject = (cardIds, targetProjectId, targetLabel) => {
  return waitForResultPromise(() => {
    const targetProject = api.getModel({modelName: "project", id: targetProjectId});
    return modificationReasons(cardIds, targetProject);
  }).then((cardModifications) => {
    if (cardModifications.length > 0) {
      return confirm({
        title: `Access issues for target project`,
        comp: Dialog,
        compProps: {cardIds, targetProjectId, target: targetLabel},
      });
    } else {
      return true;
    }
  });
};

export const canDeckBeMoved = (deckId, targetProjectId) => {
  return waitForResultPromise(() => {
    const deck = api.getModel({modelName: "deck", id: deckId});
    if (deck.project.id === targetProjectId) return true;
    return deck.$meta.find("cards", {visibility: "default"}).map((c) => c.id);
  }).then((res) => {
    if (res === true) return true;
    return canCardsBeMovedToProject(res, targetProjectId, "Deck");
  });
};

export const moveCardsToDeck = (cardIds, deckId) => {
  return waitForResultPromise(() => api.getModel({modelName: "deck", id: deckId}).project.id)
    .then((targetProjectId) =>
      canCardsBeMovedToProject(cardIds, targetProjectId, cardIds.length === 1 ? "Card" : "Cards")
    )
    .then((ok2) =>
      ok2 ? api.mutate.cards.bulkUpdate({deckId, ids: cardIds}).then(() => true) : false
    );
};

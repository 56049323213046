import {Arrow, XCol, XPush, XRow, XText, useEsc, xcolors} from "@cdx/common";
import Markdown from "../../components/Markdown";
import dsStyles from "@cdx/ds/css/index.css";
import {DSButton} from "@cdx/ds";

export const PreviewCardContent = ({
  contentAsText,
  togglePreview,
  handleSubmit,
  buttonLabel,
  project,
}) => {
  useEsc(togglePreview);
  return (
    <XCol fillParent minHeight pb={1}>
      <XCol px={1} py={1} bg="active" relative noShrink className={dsStyles.roundedTop.card}>
        <Arrow pointTo="bottom" color={xcolors.active} size="md" />
        <XText size={1} color="white" align="center">
          Previewing content
        </XText>
      </XCol>
      <XCol sp={1} fillParent minHeight relative>
        <XCol scrollable fillParent onClick={togglePreview} relative>
          <Markdown
            projectId={project && project.id}
            size="lg"
            style={{padding: "1rem", flex: "auto"}}
            parseCheckboxes
            autoTitle
          >
            {contentAsText}
          </Markdown>
        </XCol>
        <XRow sp={2} align="center" px={1} pl={2}>
          <DSButton variant="tertiary" size="sm" active onClick={togglePreview}>
            Preview
          </DSButton>
          <XPush />
          <DSButton size="md" onClick={() => handleSubmit()}>
            {buttonLabel}
          </DSButton>
        </XRow>
      </XCol>
    </XCol>
  );
};

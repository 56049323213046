import {api} from "../../lib/api";
import messenger from "../../lib/messenger";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {pluralize} from "../../lib/utils";

export const addCardsAsBookmark = ({cards, loggedInUser}) => {
  const cardIds = cards.map((c) => c.cardId);
  const userId = loggedInUser.id;
  return waitForResultPromise(() =>
    cardIds.filter((cardId) => !api.getRoot().account.$meta.exists("handCards", {userId, cardId}))
  ).then((nonBookmarkedCardIds) => {
    if (!nonBookmarkedCardIds.length) return;
    return api.mutate.bookmarks
      .addCards({
        userId: loggedInUser.id,
        ids: nonBookmarkedCardIds,
      })
      .then(() => {
        messenger.send(
          `Added ${pluralize(nonBookmarkedCardIds.length, "card")} to your bookmarks.`,
          {msToRemove: 2000}
        );
      });
  });
};

export const removeCardsAsBookmark = ({cards, loggedInUser}) => {
  const cardIds = cards.map((c) => c.cardId);
  const userId = loggedInUser.id;
  return waitForResultPromise(() =>
    cardIds.filter((cardId) => {
      const card = api.getModel({modelName: "card", id: cardId});
      return (
        api.getRoot().account.$meta.exists("handCards", {userId, cardId}) &&
        (card.deck || card.visibility !== "default")
      );
    })
  ).then((affectedCardIds) => {
    if (!affectedCardIds.length) return;
    return api.mutate.bookmarks
      .removeCards({userId: loggedInUser.id, ids: affectedCardIds})
      .then(() => {
        messenger.send(
          `Removed ${pluralize(affectedCardIds.length, "card")} from your bookmarks.`,
          {
            msToRemove: 2000,
          }
        );
      });
  });
};

import {
  PASTE_COMMAND,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
  $getSelection,
  $isRangeSelection,
  DOMConversionMap,
  DOMExportOutput,
  NodeKey,
  Spread,
  $applyNodeReplacement,
  $nodesOfType,
  $createTextNode,
  LexicalNode,
  DecoratorNode,
  SerializedLexicalNode,
} from "lexical";
import {mergeRegister} from "@lexical/utils";
import {useEffect, useRef} from "react";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {CreateImageNodePayload} from "./ToyImagePlugin";
import {DSSpinner} from "../DSIcon/DSSpinner";
import {Box, Row} from "../Box/Box";

const PASTE_FILES_COMMAND: LexicalCommand<File[]> = createCommand();

type FileInfo = {img: {width: number; height: number} | null; name: string; src: string};

type SerializedSpinnerNode = Spread<{processId: number}, SerializedLexicalNode>;

export class SpinnerNode extends DecoratorNode<JSX.Element> {
  __processId: number;

  static getType(): string {
    return "spinner";
  }

  static clone(node: SpinnerNode): SpinnerNode {
    return new SpinnerNode(node.__processId);
  }

  static importJSON(serializedNode: SerializedSpinnerNode): SpinnerNode {
    return $createSpinnerNode({processId: serializedNode.processId});
  }

  createDOM(): HTMLElement {
    const el = document.createElement("span");
    return el;
  }

  updateDOM(): false {
    return false;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute("data-lexical-cdx-spinner-process-id", this.__processId.toString());
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-cdx-spinner-process-id")) {
          return null;
        }
        return {
          conversion: (innerNode: HTMLElement) => {
            const processId = innerNode.getAttribute("data-lexical-cdx-spinner-process-id");
            if (processId) {
              const node = $createSpinnerNode({processId: Number(processId)});
              return {node};
            } else {
              return null;
            }
          },
          priority: 1,
        };
      },
    };
  }

  constructor(processId: number, key?: NodeKey) {
    super(key);
    this.__processId = processId;
  }

  exportJSON(): SerializedSpinnerNode {
    return {
      processId: this.__processId,
      type: "spinner",
      version: 1,
    };
  }

  decorate(): JSX.Element {
    return (
      <Row align="start" as="span">
        <Box colorTheme="gray50" bg="foreground" pa="16px" rounded={4} as="span">
          <DSSpinner size={24} />
        </Box>
      </Row>
    );
  }
}

export interface SpinnerPayload {
  processId: number;
  key?: NodeKey;
}

export function $createSpinnerNode({processId, key}: SpinnerPayload): SpinnerNode {
  return $applyNodeReplacement(new SpinnerNode(processId, key));
}

let nextProcessId = 1;

const CdxPasteImagePlugin = (props: {
  onFile: (file: File) => Promise<FileInfo>;
  createNodeFn: (args: CreateImageNodePayload) => LexicalNode;
}) => {
  const refs = useRef(props);
  useEffect(() => {
    refs.current = props;
  });
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          if (event instanceof ClipboardEvent) {
            const dataTransfer = event.clipboardData;
            const hasFiles = dataTransfer?.types.includes("Files");
            if (hasFiles) {
              const files = Array.from(dataTransfer!.files);
              editor.dispatchCommand(PASTE_FILES_COMMAND, files);
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        PASTE_FILES_COMMAND,
        (files) => {
          if (files.length === 0) return false;
          editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) return;
            for (const file of files) {
              const processId = (nextProcessId += 1);
              selection.insertNodes([$createSpinnerNode({processId})]);
              refs.current.onFile(file).then(
                ({name, img, src}) => {
                  editor.update(() => {
                    const processed = new Set<string>();
                    // $nodesOfType returns duplicates!?
                    for (const node of $nodesOfType(SpinnerNode)) {
                      if (node.__processId !== processId || processed.has(node.__key)) continue;
                      processed.add(node.__key);
                      if (img) {
                        node.replace(refs.current.createNodeFn({altText: name, ...img, src}));
                      } else {
                        node.replace($createTextNode(`[${name}](${src})`));
                      }
                    }
                  });
                },
                (err) => {
                  editor.update(() => {
                    const processed = new Set<string>();
                    for (const node of $nodesOfType(SpinnerNode)) {
                      if (node.__processId !== processId || processed.has(node.__key)) continue;
                      processed.add(node.__key);
                      node.replace($createTextNode(`Failed embedding file: ${err}`));
                    }
                  });
                }
              );
            }
          });
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);
  return null;
};

export default CdxPasteImagePlugin;

import {useState, forwardRef} from "react";
import {ButtonBehaviour, rawButtonStyle, cx} from "@cdx/common";
import {XRow} from "../../components/xui";
import {animated, useSpring} from "react-spring";
import {stackStyles} from "./notifications.css";
import {DSIconClose} from "@cdx/ds";

const Pill = ({isForceOpen, isHovered, onClick, children}) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      rawButtonStyle,
      stackStyles.pill.base,
      isHovered && stackStyles.pill.hoverOpen,
      isForceOpen && stackStyles.pill.forceOpen
    )}
  >
    <XRow align="center" justify="center">
      {children}
    </XRow>
  </button>
);

const DismissButton = ({canDismiss, onDismiss, isForceOpen}) => {
  const [isPressed, setPressed] = useState();
  const [isDoing, setDoing] = useState();
  const props = useSpring({
    reset: isDoing,
    progress: !isDoing && isPressed ? 1 : 0,
    config: {mass: 100, friction: 300, tension: 300, clamp: true},
    onChange: ({value: v}) => {
      if (!isDoing && v.progress > 0.99) {
        const p = onDismiss && onDismiss();
        setPressed(false);
        if (p) {
          setDoing(true);
          p.then(
            () => setDoing(false),
            (e) => {
              setDoing(false);
              return Promise.reject(e);
            }
          );
        }
      }
    },
  });

  return (
    <ButtonBehaviour
      type="button"
      className={cx(
        rawButtonStyle,
        stackStyles.closeButton.base,
        isForceOpen && stackStyles.closeButton.forceOpen,
        isDoing && stackStyles.closeButton.isDoing
      )}
      disabled={!canDismiss}
      tooltip={
        canDismiss
          ? "Keep pressed to dismiss all notifications"
          : "Can't dismiss due dates notifications."
      }
      tooltipProps={{placement: "left"}}
      onMouseDown={(e) => setPressed(true)}
      onMouseUp={(e) => setPressed(false)}
      onMouseLeave={(e) => setPressed(false)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <animated.div
        className={stackStyles.closeButtonBg}
        style={{height: props.progress.to((curr) => `${curr * 100}%`)}}
      />
      <DSIconClose size={20} />
    </ButtonBehaviour>
  );
};

const Panel = forwardRef(({isForceOpen, children, style, canDismiss, onDismiss, onClick}, ref) => (
  <XRow
    className={cx(stackStyles.panel.base, isForceOpen && stackStyles.panel.open)}
    ref={ref}
    style={style}
  >
    <DismissButton isForceOpen={isForceOpen} canDismiss={canDismiss} onDismiss={onDismiss} />
    <button
      className={cx(
        rawButtonStyle,
        stackStyles.panelContent.base,
        isForceOpen && stackStyles.panelContent.forceOpen
      )}
      type="button"
      onClick={onClick}
    >
      <XRow align="center">{children}</XRow>
    </button>
  </XRow>
));

const AnimatedPanel = animated(Panel);

const StackLabel = ({
  isForceOpen,
  isHovered,
  label,
  icon,
  onClick,
  openReveal,
  canDismiss,
  onDismiss,
}) => (
  <div className={cx(stackStyles.container.base, isForceOpen && stackStyles.container.open)}>
    <Pill icon={icon} isForceOpen={isForceOpen} isHovered={isHovered} onClick={onClick}>
      {icon}
    </Pill>
    {openReveal((props) => (
      <AnimatedPanel
        isForceOpen={isForceOpen}
        style={props}
        canDismiss={canDismiss}
        onDismiss={onDismiss}
        onClick={onClick}
      >
        {label}
      </AnimatedPanel>
    ))}
  </div>
);

export default StackLabel;

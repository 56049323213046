let passiveArg = null;

export const getPassiveArg = () => {
  if (passiveArg === null) {
    passiveArg = false;
    try {
      var opts = Object.defineProperty({}, "passive", {
        // eslint-disable-next-line getter-return
        get() {
          passiveArg = {passive: true};
        },
      });
      window.addEventListener("testPassive", null, opts);
      window.removeEventListener("testPassive", null, opts);
    } catch (e) {}
  }
  return passiveArg;
};

import {useEffect, useRef, useState} from "react";
import SmallDeck from "./Deck/SmallDeck";
import MiniDeck from "./MiniDeck";
import {useInstance} from "../lib/mate/mate-utils";
// import Ui from "./ui2/index";
import {
  getMyProjects,
  getRawSelectedProjects,
  orderProjects,
} from "../lib/hooks/useSelectedProjects";
import {deckSelectStyles} from "./DeckSelect.css";
import {cx, ToggleButton} from "@cdx/common";
import {useLocalStorageState} from "../lib/storage";
import {Box, Col, DSIconButton, DSIconClose, DSIconEyeSlash, DSIconSearch, Row} from "@cdx/ds";
import {sortedProjectDecks} from "../lib/utils";
import {groupByFn} from "../lib/collection-utils";
import {CdxCropImgByFile} from "./CdxImg";
import dsStyles from "@cdx/ds/css/index.css";
import {SpaceIcon} from "../pages/decks/SpaceComponents";
import {makeScrollable} from "@cdx/ds/utils/makeScrollable";

export const DECK_SELECT_MODAL_WIDTH = 830;

const Existing = ({deck}) => (
  <Row align="baseline" sp="4px">
    <Box size={12} color="secondary">
      Current deck:
    </Box>
    <MiniDeck inline deck={deck} onLight />
    <Box size={12} color="secondary">
      in
    </Box>
    <Box size={12} color="primary" bold>
      {deck.project.name}
    </Box>
  </Row>
);

const History = ({onSelect, root, projects}) => {
  const userId = root.loggedInUser.$meta.get("id", null);
  const projectIds = projects?.map((p) => p.$meta.get("id", null)).filter(Boolean);
  const history = userId
    ? root.account.$meta.find("deckAssignments", {
        deck: {isDeleted: false, ...(projectIds ? {projectId: projectIds} : null)},
        userId,
        $order: "-lastAssignedAt",
        $limit: 8,
      })
    : [];
  if (!history.length || !history[0].$meta.isLoaded) return null;
  return (
    <Col px="24px" py="24px" sp="24px" flex="auto">
      <Box as="h3" bold size={14}>
        Your last used decks
      </Box>
      <Row wrap sp="16px">
        {history.map(
          (entry, i) =>
            entry.deck && (
              <SmallDeck
                deck={entry.deck}
                key={entry.deck.$meta.get("id", i)}
                onClick={() => onSelect(entry.deck.id)}
                root={root}
              />
            )
        )}
      </Row>
    </Col>
  );
};

const getSpacesAndDecks = (projects, searchTerm) => {
  const spacesAndDecksByProjectId = {};
  const deckList = [];
  for (const project of projects) {
    const spacesAndDecks = [];
    let allDecks = sortedProjectDecks(project);
    if (searchTerm) {
      allDecks = allDecks.filter((d) => d.title.toLowerCase().indexOf(searchTerm) > -1);
    }
    const presentSpaceIds = new Set(project.spaces.map((s) => s.id));
    const defaultSpaceId = project.spaces.length > 0 ? project.spaces[0].id : 0;
    const bySpaceId = groupByFn(allDecks, (d) =>
      presentSpaceIds.has(d.spaceId) ? d.spaceId : defaultSpaceId
    );
    for (const space of project.spaces) {
      const decks = bySpaceId[space.id];
      if (decks) {
        deckList.push(...decks);
        spacesAndDecks.push({space, decks});
      }
    }
    spacesAndDecksByProjectId[project.id] = spacesAndDecks;
  }
  return {projectInfo: spacesAndDecksByProjectId, deckList};
};

const ProjectHeader = ({project}) => (
  <Row
    sp="8px"
    px="8px"
    py="4px"
    align="center"
    position="sticky"
    top={0}
    bg="foreground"
    zIndex={1}
  >
    <Box
      elevation={100}
      rounded={4}
      width="20px"
      height="20px"
      colorTheme="gray550"
      bg="foreground"
      styleChild
    >
      <CdxCropImgByFile file={project.coverFile} width={20} height={20} />
    </Box>
    <Box color="primary" size={16} bold>
      {project.name}
    </Box>
  </Row>
);

const DeckItem = ({deck, onSelect, isSelected}) => {
  const nodeRef = useRef();
  useEffect(() => {
    if (!isSelected) return;
    nodeRef.current.scrollIntoView({block: "nearest"});
  }, [isSelected]);
  return (
    <Row
      px="8px"
      py="4px"
      flex="none"
      cursor="pointer"
      rounded={4}
      className={cx(
        deckSelectStyles.deckItem.default,
        isSelected && deckSelectStyles.deckItem.focussed
      )}
      onClick={() => onSelect(deck.id)}
      ref={nodeRef}
    >
      <MiniDeck deck={deck} onLight />
    </Row>
  );
};

const PureDeckList = ({projectInfoList, selectedDeck, onSelect}) => (
  <Col relative flex="auto" className={makeScrollable()}>
    <Col absolute inset="0" px="24px" divideX>
      {projectInfoList.map(({project, spacesAndDecks}) => (
        <Col sp="12px" py="8px" key={project.id}>
          <ProjectHeader project={project} />
          {spacesAndDecks.map(({space, decks}) => (
            <Col sp="8px" key={space.id}>
              <Row
                sp="8px"
                align="center"
                px="8px"
                colorTheme="gray50"
                bg="foreground"
                py="2px"
                rounded={4}
              >
                <SpaceIcon space={space} size={20} className={dsStyles.color.secondary} />
                <Box size={14} color="primary" whiteSpace="nowrap" textOverflow="ellipsis">
                  {space.name ?? "Default Space"}
                </Box>
              </Row>
              <Col sp="4px">
                {decks.map((deck, i) => (
                  <DeckItem
                    key={deck.$meta.get("id", `$${i}`)}
                    deck={deck}
                    isSelected={deck.id === selectedDeck?.id}
                    onSelect={onSelect}
                  />
                ))}
              </Col>
            </Col>
          ))}
        </Col>
      ))}
    </Col>
  </Col>
);

const DeckList = ({
  onSelect,
  projects: passedProjects,
  root,
  onSetHidden,
  showHidden,
  canShowHidden,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedIndex(0);
  };

  const orderedProjects = orderProjects(root, showHidden ? getMyProjects(root) : passedProjects);

  const {projectInfo, deckList} = getSpacesAndDecks(
    orderedProjects,
    searchTerm.toLowerCase().trim()
  );
  const projectInfoList = orderedProjects
    .map((project) => ({project, spacesAndDecks: projectInfo[project.id]}))
    .filter(({spacesAndDecks}) => spacesAndDecks?.length > 0);

  const selectedDeck = deckList.length > 0 && deckList[selectedIndex];

  const handleKeyDown = (e) => {
    if (e.which === 38) {
      // UP
      if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
    } else if (e.which === 40) {
      // DOWN
      if (selectedIndex < deckList.length - 1) setSelectedIndex(selectedIndex + 1);
    } else if (e.which === 13) {
      // Enter
      if (selectedDeck) onSelect(selectedDeck.id);
    } else {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Col
      bg="foreground"
      colorTheme="gray25"
      className={deckSelectStyles.deckListContainer}
      pt="16px"
    >
      <Col px="24px" sp="16px">
        <Box relative>
          <DSIconSearch className={deckSelectStyles.searchIcon} size={16} />
          <input
            className={deckSelectStyles.searchInput}
            placeholder="Search by deck title"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={searchTerm}
            autoFocus
          />
        </Box>
      </Col>
      {deckList.length === 0 ? (
        <Box bold color="secondary" size={14} textAlign="center" flex="auto" pa="24px">
          No decks found
        </Box>
      ) : (
        <PureDeckList
          projectInfoList={projectInfoList}
          selectedDeck={selectedDeck}
          onSelect={onSelect}
        />
      )}
      {canShowHidden && (
        <Row sp="8px" px="24px" py="12px" colorTheme="gray50" bg="foreground" align="center">
          <ToggleButton checked={showHidden} onChange={() => onSetHidden((v) => !v)} />
          <Box
            bold
            size={12}
            color="secondary"
            preset="bold"
            cursor="poiner"
            onClick={() => onSetHidden((v) => !v)}
          >
            Include hidden projects
          </Box>
          <DSIconEyeSlash size={16} className={dsStyles.color.secondary} inline />
        </Row>
      )}
    </Col>
  );
};

const DeckSelect = ({onChange, onClose, title, value, root, hideClose, forceProjects}) => {
  const [showHidden, setHidden] = useLocalStorageState("deck-select-with-hidden", false);
  const handleDeckSelect = (id) => {
    const res = onChange(id);
    if (res && res.then) {
      res.then(() => onClose());
    } else {
      onClose();
    }
  };
  const existingDeck = useInstance("deck", value);
  const explicitSelections = getRawSelectedProjects(root);

  const projects = forceProjects
    ? forceProjects
    : explicitSelections.length
      ? explicitSelections
      : getMyProjects(root);

  return (
    <Col flex="auto" style={{minHeight: 490}} colorTheme="white" divideX>
      <Row sp="12px" py="16px" px="24px">
        <Row align="baseline" sp="24px">
          <Box size={16} bold as="h2">
            {title || "Move selected card to deck"}
          </Box>
          {existingDeck && <Existing deck={existingDeck} />}
        </Row>
        <Box ml="auto">
          {!hideClose && onClose && (
            <DSIconButton
              onClick={onClose}
              negatePadding
              icon={<DSIconClose />}
              variant="tertiary"
            />
          )}
        </Box>
      </Row>
      <Row flex="auto">
        <DeckList
          onSelect={handleDeckSelect}
          root={root}
          projects={projects}
          onSetHidden={setHidden}
          showHidden={showHidden}
          canShowHidden={!forceProjects && explicitSelections.length > 0}
        />
        <History onSelect={handleDeckSelect} root={root} projects={showHidden ? null : projects} />
      </Row>
    </Col>
  );
};

export default DeckSelect;

const cmpMap = {gte: <>&gt;=&nbsp;</>, lte: <>&lt;=&nbsp;</>};

const effortCmpToFilter = (effort, cmp) => {
  if (cmp === "eq") return {effort};
  if (cmp === "gte") return {effort: {op: cmp, value: effort}};
  if (cmp === "lte") return {$or: [{effort: {op: cmp, value: effort}}, {effort: null}]};
};

const effortCategory = {
  label: "Effort",
  key: "effort",
  getSuggestions({input, root}) {
    if ("effort".startsWith(input)) {
      const {effortScale} = root.account;
      return [...effortScale.map((e) => ({effort: e, cmp: "eq"})), {effort: null, cmp: "eq"}];
    }
    if (/^\d+$/.test(input)) {
      const num = parseInt(input, 10);
      return [
        {effort: num, cmp: "eq"},
        {effort: num, cmp: "gte"},
        {effort: num, cmp: "lte"},
      ];
    }
    return [];
  },
  valueToKey({effort, cmp}) {
    return `${cmp}${effort}`;
  },
  valuesToDbQuery(values) {
    if (values.length === 1) {
      const {effort, cmp} = values[0];
      return effortCmpToFilter(effort, cmp);
    } else if (values.every(({cmp}) => cmp === "eq")) {
      return {effort: values.map((v) => v.effort)};
    } else {
      return {$or: values.map(({effort, cmp}) => effortCmpToFilter(effort, cmp))};
    }
  },
  renderSuggestion({effort, cmp}) {
    return effort === null ? (
      "none"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {effort}
      </>
    );
  },
  renderPill({effort, cmp}) {
    return effort === null ? (
      "No effort"
    ) : (
      <>
        {cmpMap[cmp] || ""}
        {effort}
      </>
    );
  },
  savedSearchLabel({effort, cmp}) {
    const cmpToLabel = {gte: "+", lte: "-", eq: ""};
    return {
      prio: 2,
      label: effort === null ? "no effort" : `${effort}${cmpToLabel[cmp]}`,
    };
  },
};

export default effortCategory;

import {useLayoutEffect, useRef} from "react";
import {XTextButton} from "@cdx/common";
import {Box, Col, Row} from "@cdx/ds";
import {getCurrentLevel, toRomanNumeral, getOnboardingStepInfoIfLoaded} from "./onboarding-utils";
import OnboardingBadge from "./OnboardingBadge";
import {useSmartSidebarOpenStore} from "../../components/SmartSidebar";
import OnboardingProgressBar from "./OnboardingProgressBar";
import {EXPERIMENTS, useExperiment} from "../../lib/ab-experiments/experiments";

type OnboardingIconProps = {root: any};
const OnboardingIcon = ({root}: OnboardingIconProps) => {
  const toggle = useSmartSidebarOpenStore((s) =>
    s.showEl && s.showEl.type === "onboarding" ? s.close : s.showOnboardingBar
  );
  const info = getOnboardingStepInfoIfLoaded(root);
  const levelInfo = info && getCurrentLevel(info);

  const infoRef = useRef(info);
  const isPresent = Boolean(info);

  const experimentActive = useExperiment(EXPERIMENTS.ExpandOnboarding, root.account);

  useLayoutEffect(() => {
    infoRef.current = info;
  });
  useLayoutEffect(() => {
    if (!isPresent || !experimentActive) return;
    const state = useSmartSidebarOpenStore.getState();
    if (!state.pristine) return;
    const {availableSteps, stepProgress} = infoRef.current!;
    const firstChapterSteps = availableSteps.filter((s) => s.chapter === "basics1");
    const allCompleted = firstChapterSteps.every((s) => stepProgress[s.key]?.completedAt);
    if (!allCompleted) state.showOnboardingBar();
  }, [isPresent, experimentActive]);

  return (
    <XTextButton onClick={toggle} square>
      <Row sp="8px" align="center" colorTheme="gray850">
        <Col sp="4px">
          <Box color="primary" size={14} bold>
            Lvl
          </Box>
          <OnboardingProgressBar
            height="3px"
            progress={
              levelInfo
                ? (levelInfo.currXp - levelInfo.levelStartXp) /
                  (levelInfo.levelEndXp - levelInfo.levelStartXp || 1)
                : 0
            }
          />
        </Col>
        <OnboardingBadge size="md" text={levelInfo ? toRomanNumeral(levelInfo.level) : "?"} />
      </Row>
    </XTextButton>
  );
};

export default OnboardingIcon;

import {XCol, XPush, XRow, XText} from "@cdx/common";

const Uploads = ({ongoingUploads, noLabel, onDark, style, ...rest}) => {
  // const ongoingUploads = [
  //   {
  //     progressInPercent: 65,
  //     fileName: "asdasdalguygd87gasdbasdh_ 9(((sadahsdasdasdasd.jpg",
  //   },
  //   {
  //     progressInPercent: 5,
  //     fileName: "Some File.pdf",
  //   },
  // ];

  if (ongoingUploads.length === 0) return null;

  return (
    <XCol style={{maxWidth: "100%", ...style}} sp={1} relative {...rest}>
      {ongoingUploads.map((fileData, idx) => (
        <XCol key={idx}>
          <XRow sp={0}>
            {noLabel && <XPush />}
            <XText
              color={onDark ? "gray200" : "gray700"}
              preset="bold"
              size={0}
              style={{minWidth: "2.5em"}}
            >
              {Math.ceil(fileData.progressInPercent)}%
            </XText>
            <XPush />
            {!noLabel && (
              <XText color={onDark ? "gray200" : "gray700"} noOverflow size={0}>
                {fileData.fileName}
              </XText>
            )}
          </XRow>
          <XCol
            noOverflow
            style={{height: 3}}
            rounded="full"
            bg={onDark ? "gray600" : "gray400"}
            relative
          >
            <XCol
              absolute
              inset="y"
              bg={onDark ? "active300" : "active500"}
              style={{left: 0, width: `${fileData.progressInPercent}%`}}
            />
          </XCol>
        </XCol>
      ))}
    </XCol>
  );
};

export default Uploads;

import {XRow} from "../../xui";
import {XCol, xcolors} from "@cdx/common";
import {Box, DSIconButton} from "@cdx/ds";

export const Header = ({style, ...rest}) => (
  <XCol pt={4} pb={3} px={4} {...rest} style={{...style, borderBottom: "1px solid #E3E3E3"}} />
);

export const Body = ({...rest}) => <XCol py={3} px={4} {...rest} />;

export const ModalHeader = ({onClose, children, ...rest}) => (
  <XRow
    align="center"
    bg="white"
    py={3}
    px={4}
    style={{borderBottom: `1px solid ${xcolors.gray200}`}}
    {...rest}
  >
    {children}
    {onClose && (
      <Box ml="auto">
        <DSIconButton variant="tertiary" icon={<DSIconButton />} onClick={onClose} />
      </Box>
    )}
  </XRow>
);

export const ModalBody = ({...rest}) => <XCol py={3} px={4} fillParent {...rest} />;

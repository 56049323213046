import {MouseEventHandler, forwardRef} from "react";
import {api} from "../../lib/api";
import {
  addDays,
  dayToDate,
  isDayInFuture,
  prettyShortWorkdays,
  shortDate,
  startOfUTCDay,
  toCmpDate,
  toDateStr,
} from "../../lib/date-utils";
import {waitForResult, waitForResultPromise} from "../../lib/wait-for-result";
import {cx, getMetaKeyLabel, isWithMetaKey} from "@cdx/common";
import {milestoneIconStyle} from "./milestones.css";
import {DSIconSprint, DSIconSprintNext, IconProps, Box, DSShortcutKey, Row} from "@cdx/ds";
import {Sprint, SprintId} from "../../cdx-models/Sprint";
import {Root} from "../../cdx-models/Root";
import {Card, CardId} from "../../cdx-models/Card";
import {Account} from "../../cdx-models/Account";
import {setMsThemeColor} from "./milestone-utils";
import routes from "../../routes";
import {History} from "history";
import {SprintByIdTooltipForChild} from "../../pages/milestones/SprintTooltip";
import {useHistory} from "react-router";
import {CardPropChangeOverlayForChild} from "../../components/RichTextarea/Lexical/CardPropChangeForChild";
import {CardPropChangeKey} from "../../components/RichTextarea/Lexical/CardPropChangeOverlay";
import {SprintConfig} from "../../cdx-models/SprintConfig";

export const getActiveAndFutureSprints = (opts: {
  account: Account;
  constraints: any;
  limit?: number;
  today?: Date;
}) => {
  const {account, constraints, limit, today} = opts;
  return account.$meta.find("sprints", {
    endDate: {op: "gte", value: toCmpDate(today ?? new Date())},
    isDeleted: false,
    ...constraints,
    $order: ["endDate", "sprintConfigId"],
    $limit: limit,
  });
};
export const getPastSprints = (opts: {
  account: Account;
  constraints: any;
  limit?: number;
  today?: Date;
}) => {
  const {account, constraints, limit, today} = opts;
  return account.$meta.find("sprints", {
    endDate: {op: "lt", value: toCmpDate(today ?? new Date())},
    isDeleted: false,
    ...constraints,
    $order: ["-endDate", "sprintConfigId"],
    $limit: limit,
  });
};

export const isSprintValidTargetForCards = ({
  sprintId,
  cardIds,
}: {
  sprintId: SprintId;
  cardIds: CardId[];
}) =>
  waitForResultPromise(() => {
    if (!sprintId) return {ok: true, unsupportedProjectNames: []};
    const sprint: Sprint = api.getModel({modelName: "sprint", id: sprintId});
    const cards: Card[] = cardIds.map((id) => api.getModel({modelName: "card", id}));
    const cardProjects = cards.map((c) => c?.deck?.project!).filter(Boolean);
    const targetProjectIds = new Set(
      sprint.sprintConfig.sprintProjects.map((sp) => (sp as any).projectId)
    );
    const unsupportedProjectNames = [
      ...new Set(cardProjects.filter((p) => !targetProjectIds.has(p.id)).map((p) => p.name)),
    ];
    return {ok: unsupportedProjectNames.length === 0, unsupportedProjectNames};
  });

export const isSprintPinned = ({
  sprint,
  root: {loggedInUser, account},
}: {
  sprint: Sprint;
  root: Root;
}) => {
  const accountId = account.$meta.get("id", null);
  const sprintId = sprint.$meta.get("id", null);
  return Boolean(
    accountId &&
      sprintId &&
      loggedInUser?.$meta.exists("pinnedMilestoneNext", {accountId, sprintId})
  );
};

export const getSprintPinInfo = ({
  sprint,
  root: {loggedInUser, account},
}: {
  sprint: Sprint;
  root: Root;
}) => {
  const accountId = account.$meta.get("id", null);
  const sprintId = sprint.$meta.get("id", null);
  return (
    (accountId &&
      sprintId &&
      loggedInUser?.$meta.find("pinnedMilestoneNext", {accountId, sprintId})) ??
    null
  );
};

export const getSprintVelocityFromConfig = (config: SprintConfig, account: Account) => {
  const lastProgress = config.$meta.first("progress", {
    $order: "-date",
  });
  const lastProgress7dAgo = config.$meta.first("progress", {
    $order: "-date",
    date: {op: "lt", value: toDateStr(addDays(startOfUTCDay(), -7))},
  });
  const fallbackEffort = account.fallbackEffort;

  const getEffortFromTuple = (t?: [number, number, number]) => {
    if (!t) return 0;
    return t[1] + t[2] * fallbackEffort;
  };

  const currEffort = getEffortFromTuple(lastProgress?.progress.stats.done);
  const prevEffort = getEffortFromTuple(lastProgress7dAgo?.progress.stats.done);
  return currEffort - prevEffort;
};

export const getSprintVelocity = (sprint: Sprint, account: Account) => {
  return getSprintVelocityFromConfig(sprint.sprintConfig, account);
};

export const getSprintState = (sprint: Sprint) => {
  if (sprint.completedAt && new Date() > sprint.completedAt) {
    return "past";
  } else if (isDayInFuture(sprint.startDate)) {
    return "next";
  } else {
    return "current";
  }
};

export const getMetaClickSprintHandler = (opts: {
  cardId: CardId;
  sprintId: SprintId;
  history: History;
  onClick?: MouseEventHandler<any>;
}) => {
  const {cardId, sprintId, history, onClick} = opts;
  const handleClick: MouseEventHandler<any> = (e) => {
    if (!isWithMetaKey(e)) return onClick?.(e);
    e.preventDefault();
    e.stopPropagation();
    waitForResult(
      () => {
        const {accountSeq, title} = api.getModel({
          modelName: "card",
          id: cardId,
        });
        const sprint = api.getModel({modelName: "sprint", id: sprintId});
        return {sprintAccountSeq: sprint.accountSeq, card: {accountSeq, title}};
      },
      ({sprintAccountSeq, card}: any) => {
        history.push(routes.sprintWithCard.getUrl(sprintAccountSeq, card));
      }
    );
  };
  return handleClick;
};

export const ThemedSprintIconWithTooltipAndMetaClick = (props: {
  theme: string;
  cardId: CardId;
  sprintId: SprintId;
  onDark?: boolean;
  sprintState?: null | "current" | "past" | "next";
  propChangeInfo?:
    | false
    | null
    | {cardId: CardId; cardContainerKey: string; availableShortcuts?: CardPropChangeKey[]};
  zoneInfo?: string | null;
  withShortcutHelp?: boolean;
}) => {
  const {theme, cardId, sprintId, onDark, sprintState, propChangeInfo, zoneInfo, withShortcutHelp} =
    props;
  const history = useHistory();

  if (process.env.REACT_APP_MODE === "open") return null;

  const handleClick = getMetaClickSprintHandler({cardId, sprintId, history});

  const content = (
    <SprintByIdTooltipForChild
      sprintId={sprintId}
      tooltip={
        process.env.REACT_APP_MODE !== "open" && (
          <>
            {withShortcutHelp && (
              <>
                <DSShortcutKey>,</DSShortcutKey> to change,{" "}
              </>
            )}
            {getMetaKeyLabel()} + click to open card within Run's view
          </>
        )
      }
      zoneInfo={zoneInfo}
    >
      <ThemedSprintIcon
        size={16}
        onDark={onDark}
        theme={theme}
        sprintState={sprintState}
        onClick={handleClick} // will be overridden by CardPropChangeOverlayForChild
      />
    </SprintByIdTooltipForChild>
  );

  return propChangeInfo ? (
    <CardPropChangeOverlayForChild
      cardId={propChangeInfo.cardId}
      cardContainerKey={`${propChangeInfo.cardContainerKey}-sprint`}
      initialTab="sprint"
      getChildProps={(open) => ({hidden: open ? true : undefined})}
      onClick={handleClick}
      availableShortcuts={propChangeInfo.availableShortcuts}
    >
      {content}
    </CardPropChangeOverlayForChild>
  ) : (
    content
  );
};

type ThemedSprintIconProps = {
  onDark?: boolean;
  theme: string;
  sprintState?: null | "current" | "past" | "next";
} & IconProps;
export const ThemedSprintIcon = forwardRef<SVGElement, ThemedSprintIconProps>(
  ({theme, className, sprintState, onDark, ...rest}, ref) => {
    switch (sprintState) {
      case "past":
        return (
          <DSIconSprint
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              milestoneIconStyle.past,
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
      case "next":
        return (
          <DSIconSprintNext
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
      default:
        return (
          <DSIconSprint
            className={cx(
              theme,
              milestoneIconStyle.asDsIcon[onDark ? "onDark" : "onLight"],
              className
            )}
            size={20}
            {...rest}
            ref={ref}
          />
        );
    }
  }
);

export const sprintLabel = (sprint: Sprint) => sprint.name || `Run ${sprint.index + 1}`;

export const SprintTileContent = ({
  sprint,
  account,
  onDark,
}: {
  sprint: Sprint;
  account: Account;
  onDark?: boolean;
}) => (
  <Row
    whiteSpace="nowrap"
    textOverflow="ellipsis"
    overflow="hidden"
    size={14}
    lineHeight="16px"
    sp="8px"
  >
    <ThemedSprintIcon
      theme={setMsThemeColor(sprint.sprintConfig)}
      size={16}
      inline
      sprintState={getSprintState(sprint)}
      onDark={onDark}
    />
    <Box size={12} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" bold>
      {sprintLabel(sprint)}
    </Box>
    <Box size={12} color="secondary">
      {sprint.sprintConfig.name}
    </Box>
    <Box ml="auto" size={12} flex="none" weight="normal">
      {prettyShortWorkdays({
        targetDay: dayToDate(sprint.endDate),
        workdays: account.workdays,
      })}
    </Box>
  </Row>
);

export const getSprintEndDateString = (sprint: Sprint) => {
  const {year, month, day} = sprint.endDate;
  const {endHour, endHourTimezone} = sprint.sprintConfig;
  const d = new Date(year, month - 1, day, endHour, 0, 0, 0);
  return `${shortDate(d)} (${endHourTimezone})`;
};

export const getActiveSprintConfigs = (account: Account) => {
  return account.$meta.find("sprintConfigs", {$order: "-createdAt", sprints: {cards: {}}});
};

import {useState, useEffect} from "react";
import {uuidEvent} from "../../lib/api";
import {useHistory} from "react-router-dom";
import debounce from "lodash/debounce";
import {getCurrentAccount, cdxRequest, API_ERRORS} from "../../lib/request";
import cdxEnv from "../../env";

const findButton = (node) => {
  if (node.tagName === "BUTTON") {
    return node;
  } else if (node.parentNode) {
    return findButton(node.parentNode);
  } else {
    return null;
  }
};

const findImgAlt = (node) => {
  if (node.tagName === "svg" || node.tagName === "IMG") {
    const alt = node.getAttribute("alt");
    if (alt) return alt;
  } else {
    for (const idx in node.childNodes) {
      const alt = findImgAlt(node.childNodes[idx]);
      if (alt) return alt;
    }
  }
  return null;
};

const labelButton = (buttonNode) => {
  const label = buttonNode.getAttribute("aria-label");
  if (label) return label;
  const text = (buttonNode.textContent || "").trim();
  if (text) return text;
  return findImgAlt(buttonNode);
};

const getContext = (buttonNode) => {
  const ctxList = [];
  let current = buttonNode;
  while (current) {
    const ctx = current.getAttribute && current.getAttribute("data-cdx-context");
    if (ctx) ctxList.push(ctx);
    current = current.parentNode;
  }
  return ctxList;
};

let currentBatch = {
  events: [],
  sessionId: null,
};

const getEventData = () => {
  const now = new Date().getTime();
  const events = currentBatch.events.map(({type, data, time}) => ({type, data, msAgo: now - time}));
  return {sessionId: currentBatch.sessionId, events};
};

const innerSend = () => {
  cdxRequest({
    path: "/events/bulk",
    method: "POST",
    data: getEventData(),
  }).catch((err) => {
    if (err.type === API_ERRORS.API_ERROR) {
      console.warn(`encountered '${err.message}'`);
    } else {
      return Promise.reject(err);
    }
  });
  currentBatch.events = [];
};

const scheduleSend = debounce(innerSend, 1500, {maxWait: 5000});

const addEvent = (type, data) => {
  currentBatch.events.push({type, data, time: new Date().getTime()});
  scheduleSend();
};

const sanitizePath = (path) => {
  const m = path.match(/(.*\/(?:step|card)\/\w+)-.*/);
  return m ? m[1] : path;
};

const setupLearner = (history, uuid) => {
  currentBatch.sessionId = uuid;
  const handleClick = (e) => {
    const button = findButton(e.target);
    if (!button) return;
    addEvent("button", {label: labelButton(button), context: getContext(button)});
  };
  document.addEventListener("click", handleClick, {capture: true});

  const handleUnload = () => {
    if (!navigator.sendBeacon) return;
    scheduleSend.cancel();
    const eventData = getEventData();
    eventData.events.push({type: "close", data: {}, msAgo: 0});
    navigator.sendBeacon(
      `${cdxEnv.API_HOST}/events/unload`,
      JSON.stringify({
        ...eventData,
        account: getCurrentAccount(),
      })
    );
    currentBatch.events = [];
  };
  window.addEventListener("unload", handleUnload);

  const unlistenHistory = history.listen((loc) => {
    addEvent("location", {
      path: `${sanitizePath(loc.pathname)}${loc.search}`,
      state: loc.state,
    });
  });

  const {location} = history;
  addEvent("location", {
    path: `${sanitizePath(location.pathname)}${location.search}`,
    state: location.state,
  });

  return () => {
    document.removeEventListener("click", handleClick, {capture: true});
    window.removeEventListener("unload", handleUnload);
    unlistenHistory();
  };
};

export const useTracker = (root) => {
  const [uuid, setUuid] = useState(null);
  const history = useHistory();

  useEffect(() => uuidEvent.addListener(setUuid), []);

  const isLearning = root.account && root.account.$meta.get("isLearning", false);
  const isLoggedIn = root.loggedInUser && root.loggedInUser.$meta.isLoaded;

  useEffect(() => {
    if (uuid && isLearning && isLoggedIn) {
      return setupLearner(history, uuid);
    }
  }, [uuid, isLearning, history, isLoggedIn]);
};

import {useRef, useEffect} from "react";
// import DataTransfer from "fbjs/lib/DataTransfer";

const someParent = (node, cb) => {
  if (!node) return false;
  if (cb(node)) return true;
  return someParent(node.parentNode, cb);
};

let lastFileData = null;

export const useFilePaster = (onReceiveFile) => {
  const fnRef = useRef(onReceiveFile);
  useEffect(() => {
    fnRef.current = onReceiveFile;
  }, [onReceiveFile]);
  useEffect(() => {
    const handlePaste = (e) => {
      if (lastFileData === e.clipboardData) return;
      if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") return;
      if (!fnRef.current) return;
      if (e.defaultPrevented) return;
      if (someParent(e.target, (n) => n.isContentEditable)) return;
      if (!someParent(e.target, (n) => n === document)) {
        console.warn("Pasting on detached node is forbidden!");
        return;
      }
      const files = Array.from(e.clipboardData.files);
      if (files && files.length) {
        fnRef.current(files);
        lastFileData = e.clipboardData;
      }
    };
    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, []);
};

const FilePasteHandler = ({onReceiveFile}) => {
  useFilePaster(onReceiveFile);
  return null;
};

export default FilePasteHandler;

import {useState} from "react";
import {DragController, useDropZone} from "@codecks/dnd";
import {XCol, XRow, XText, XPush} from "../../components/xui";
import {api} from "../../lib/api";
import {useShouldShowHintKey} from "../hints/HintManager";
import XPlainButton from "../../components/xui/XPlainButton";
import ProjectTile, {InnerProjectTile} from "./ProjectTile";
import CreateProject from "./CreateProject";
import {getModalUrl} from "../../components/ModalRegistry";
import XGhostButton from "../../components/xui/XGhostButton";
import XTextButton from "../../components/xui/XTextButton";
import {getMyProjects, orderProjects} from "../../lib/hooks/useSelectedProjects";
import Hint from "../../components/Hint";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {canAddProject} from "../billing/billing-utils";
import {
  hasPermissionToCreateProject,
  hasPermissionToManageSomeProjectInOrg,
} from "../../lib/permissions";

const HintAtTrelloImporter = ({location}) => {
  const [shouldShow, dismiss] = useShouldShowHintKey("integrations.trelloImporter");
  if (!shouldShow || shouldShow === "loading") return null;
  return (
    <XCol sp={1} rounded="sm" border="gray600" px={3} py={2}>
      <XText preset="bold" size={3} color="gray100">
        Import your Trello boards
      </XText>
      <XText size={1} color="gray400">
        To give you an easy start, you can add cards from Trello into an existing or into a new
        Codecks project.
      </XText>
      <XRow sp={2} justify="end" align="center">
        <XTextButton size="sm" color="dimWhite" onClick={dismiss}>
          Dismiss
        </XTextButton>
        <XGhostButton
          size="sm"
          color="white"
          to={getModalUrl({location, modal: "accountSettings.trelloImporter"})}
        >
          Open Importer
        </XGhostButton>
      </XRow>
    </XCol>
  );
};

const ArchivedProjects = ({root, location}) => {
  const [show, setShow] = useState(false);

  if (!root.account.$meta.exists("archivedProjects")) return null;

  return (
    <XCol sp={3}>
      <XCol align="end">
        <XTextButton color="dimWhite" active={show} onClick={() => setShow(!show)}>
          {show ? "Showing" : "Show"} archived projects
        </XTextButton>
      </XCol>
      {show && (
        <XCol sp={1}>
          {root.account.archivedProjects.map((project) => (
            <ProjectTile
              key={project.id}
              project={project}
              location={location}
              hideToggle
              root={root}
              noHideButton
            />
          ))}
        </XCol>
      )}
    </XCol>
  );
};

const HintSection = ({projects, root}) => {
  const [hint1, dismiss1] = useShouldShowHintKey("projects.howToUseSelection");
  const [hint2, dismiss2] = useShouldShowHintKey("projects.dndToOrder");

  if (projects.length > 1 && hint1) {
    return (
      <Hint action={{label: "Understood", onClick: dismiss1}}>
        Select or deselect projects above to only show you content of these projects.
      </Hint>
    );
  }
  if (projects.length > 1 && hint2) {
    return (
      <Hint action={{label: "Okay", onClick: dismiss2}}>
        Re-order projects by drag and drop. This order is specific to you and won't affect anyone in
        your team!
      </Hint>
    );
  }

  const userCanManageSomeProjectInOrg = hasPermissionToManageSomeProjectInOrg(root);

  const hasProjectWithNoCover = projects.some((p) => !p.coverFile);
  if (userCanManageSomeProjectInOrg && hasProjectWithNoCover) {
    return <Hint>Drop an image on a project tile to set it as a cover image</Hint>;
  }
  return null;
};

const TILEHEIGHTWITHMARGIN = 49 + 8;

const UpgradeHint = ({location}) => {
  return (
    <XCol bg="warn100" border="warn300" rounded="sm" px={3} py={2} sp={1}>
      <XText preset="bold" size={1} color="warn800">
        You've used up all free projects.
      </XText>
      <XCol align="end">
        <XPlainButton color="purple" to={getModalUrl({location, modal: "accountSettings.billing"})}>
          Upgrade plan...
        </XPlainButton>
      </XCol>
    </XCol>
  );
};

const MenuProjects = ({root, location}) => {
  const {account, loggedInUser} = root;
  const allProjects = orderProjects(root, getMyProjects(root));
  const [dragInfo, setDragInfo] = useState();
  const [frozenOrder, setFrozenOrder] = useState();

  if (dragInfo) {
    const currIdx = allProjects.findIndex((p) => p.id === dragInfo.projectId);
    allProjects.splice(dragInfo.index, 0, ...allProjects.splice(currIdx, 1));
  }

  const selectionProjects = account.$meta.isLoaded
    ? loggedInUser.$meta
        .find("projectSelections", {accountId: account.id})
        .map((ps) => ps.project)
        .filter(Boolean)
    : [];

  const selectedProjectIds = new Set(
    (selectionProjects.length === 0 || !selectionProjects[0].$meta.isLoaded
      ? allProjects
      : selectionProjects
    ).map((p) => p.id)
  );

  const handleProjectToggle = ({checked, projectId}) => {
    if (!checked && selectionProjects.length === 1) return console.warn("not possible");
    const prevIds = [...selectedProjectIds];
    const nextIds = checked ? [...prevIds, projectId] : prevIds.filter((id) => projectId !== id);
    return api.mutate.projectSelections.set({userId: loggedInUser.id, projectIds: nextIds});
  };

  const onSelectSingleProjectClick = ({projectId}) => {
    return api.mutate.projectSelections.set({userId: loggedInUser.id, projectIds: [projectId]});
  };

  const onShowAllProjectsClick = () => {
    return api.mutate.projectSelections.set({
      userId: loggedInUser.id,
      projectIds: allProjects.map((p) => p.id),
    });
  };

  const [isAdding, setIsAdding] = useState(false);

  const handleDrop = () => {
    setFrozenOrder(allProjects);
    api.mutate.projects
      .updateOrder({
        accountId: account.id,
        userId: loggedInUser.id,
        ids: allProjects.map((p) => p.id),
      })
      .then(() =>
        waitForResultPromise(() =>
          api
            .getRoot()
            .loggedInUser.$meta.find("projectOrders", {accountId: account.id})
            .forEach((o) => o.$meta.get("sortIndex"))
        )
      )
      .then(() => {
        setDragInfo(null);
        setFrozenOrder(null);
      });
  };
  const handleDragOver = ({item, position}) => {
    if (position === null) {
      setDragInfo(null);
    } else {
      const index = Math.max(
        0,
        Math.round((position.y - 8 - TILEHEIGHTWITHMARGIN / 2) / TILEHEIGHTWITHMARGIN)
      );
      if (!dragInfo || index !== dragInfo.index || dragInfo.projectId !== item.id) {
        setDragInfo({index, projectId: item.id});
      }
    }
  };

  const {ref: dropRef} = useDropZone({
    type: "PROJECT",
    onDrop: handleDrop,
    onDragOver: handleDragOver,
  });

  const orgCanAddProject = canAddProject(account);
  const userCreateProject = hasPermissionToCreateProject(root);

  return (
    <XCol sp={3} px={3}>
      <XRow align="center" sp={3} pt={0}>
        <XText preset="bold" size={5} color="white">
          Projects
        </XText>
        {userCreateProject && (
          <>
            <XPush />
            <XPlainButton color="white" active={isAdding} onClick={() => setIsAdding(!isAdding)}>
              Add
            </XPlainButton>
          </>
        )}
      </XRow>
      <XCol sp={4}>
        {isAdding &&
          (orgCanAddProject ? (
            <XCol sp={4}>
              <CreateProject root={root} onClose={() => setIsAdding(false)} />
              <HintAtTrelloImporter location={location} />
            </XCol>
          ) : (
            <UpgradeHint location={location} />
          ))}
        <DragController
          type="PROJECT"
          renderItem={({id}) => (
            <InnerProjectTile
              project={api.getModel({modelName: "project", id})}
              location={location}
              isSelected={selectedProjectIds.has(id)}
              hideToggle
              root={root}
            />
          )}
        >
          <XCol sp={1} ref={dropRef}>
            {(frozenOrder || allProjects).map((project) => (
              <ProjectTile
                key={project.id}
                project={project}
                location={location}
                isSelected={selectedProjectIds.has(project.id)}
                hideToggle={allProjects.length <= 1}
                isLastSelected={
                  selectionProjects.length === 1 && selectedProjectIds.has(project.id)
                }
                onChangeProject={handleProjectToggle}
                onSelectSingleProject={onSelectSingleProjectClick}
                onShowAllProjectsClick={onShowAllProjectsClick}
                root={root}
              />
            ))}
          </XCol>
        </DragController>
      </XCol>
      <HintSection projects={allProjects} root={root} />
      <ArchivedProjects root={root} location={location} />
    </XCol>
  );
};

export default MenuProjects;

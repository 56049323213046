const lettersToSequence = (letters, {startVal = 0, implicitZero = false} = {}) => {
  const length = letters.length;
  const letterToIndex = letters.split("").reduce((memo, letter, index) => {
    memo[letter] = index;
    return memo;
  }, {});

  return {
    intToSeq(intVal) {
      const seq = [];
      let q = intVal + startVal;
      if (implicitZero) q += 1;
      let r;
      do {
        if (implicitZero) q += -1;
        r = q % length;
        q = Math.floor(q / length);
        seq.unshift(letters[r]);
      } while (q);
      return seq.join("");
    },
    seqToInt(seq = "") {
      let intVal = letterToIndex[seq[0]] || 0;
      for (let i = 1; i < seq.length; i += 1) {
        if (implicitZero) intVal += 1;
        intVal *= length;
        intVal += letterToIndex[seq[i]];
      }
      return intVal - startVal;
    },
    letters,
  };
};

export const alphaSeq = lettersToSequence(
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
);
export const pronounceSafeSeq = lettersToSequence("123456789acefghijkoqrsuvwxyz", {
  startVal: 28 * 29 - 1,
  implicitZero: true,
});

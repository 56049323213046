import {useState, useRef, useCallback, useEffect} from "react";
import {api, uuidEvent} from "../lib/api";
import {useReveal, delayedTrigger, XCol, XText} from "@cdx/common";
import {animated} from "react-spring";
import cdxEnv from "../env";

const dbObToApiOp = {insert: "create", update: "update", delete: "delete"};

const createSocket = ({onStatusChange, accountId, onMessage}) => {
  let socket = null;
  let nextRetryDuration = 250 + Math.random() * 1250;
  const retryTrigger = delayedTrigger();

  const handleOpen = () => {
    onStatusChange("active");
    nextRetryDuration = 250 + Math.random() * 1250;
    socket.send(
      JSON.stringify({
        type: "register",
        payload: {accountId},
      })
    );
  };

  const handleMessage = (message) => {
    onMessage(JSON.parse(message.data));
  };

  const handleClose = () => {
    socket.onclose = undefined;
    socket.onmessage = undefined;
    socket.close();
    socket = null;
    onStatusChange("failed");
    retryTrigger.fire(connect, nextRetryDuration);
    nextRetryDuration = Math.min(nextRetryDuration * 1.5, 1000 * 60 * 5);
  };

  const connect = () => {
    socket = new window.WebSocket(cdxEnv.PUSH_HOST);
    socket.onopen = handleOpen;
    socket.onmessage = handleMessage;
    socket.onclose = handleClose;
  };

  if (accountId) connect();

  return () => {
    retryTrigger.cancel();
    if (socket) {
      socket.onclose = undefined;
      socket.onmessage = undefined;
      socket.close();
    }
  };
};

const OuterPushUpdates = ({root}) => {
  const hasUser = root.loggedInUser && root.loggedInUser.$meta.isLoaded;
  return hasUser ? <PushUpdates root={root} /> : null;
};

const PushUpdates = ({root}) => {
  const [status, setStatus] = useState("initial"); // initial, active, failed
  const lastActionIdRef = useRef(null);
  const accountId = root.account.$meta.get("id", null);

  const handleMessage = useCallback((data) => {
    switch (data.type) {
      case "uuid": {
        uuidEvent.emit(data.payload);
        break;
      }
      case "lastActionId": {
        const {actionId} = data.payload;
        // if server was just restarted, `actionId` is still empty, so let's ignore this case to prevent DOSing ourselves...
        if (actionId && lastActionIdRef.current && lastActionIdRef.current !== actionId) {
          // eslint-disable-next-line no-console
          console.log("outdated data: clear cache");
          lastActionIdRef.current = actionId;
          api.cache.invalidateAll();
        }
        break;
      }
      case "action": {
        let description = api.mutate.$descriptions[data.payload.actionName];
        if (!description) {
          console.warn(`Don't know action '${data.payload.actionName}'`);
        } else {
          const payload = description.convertDataForOptimistic
            ? description.convertDataForOptimistic(data.payload.payload)
            : data.payload.payload;
          api.cache.invalidate(description, payload, data.payload.retVal);
        }
        lastActionIdRef.current = data.payload.actionId;
        break;
      }
      case "notification": {
        const {type, modelName, payload} = data.payload;
        const hydratedPayload = {
          ...payload,
          lastUpdatedAt: payload.lastUpdatedAt && new Date(payload.lastUpdatedAt),
          createdAt: payload.createdAt && new Date(payload.createdAt),
        };
        api.cache.invalidate({type: dbObToApiOp[type], model: modelName}, hydratedPayload, {});
        break;
      }
      case "model_change": {
        const {modelName, type, payload} = data.payload;
        api.cache.invalidate(
          {type: dbObToApiOp[type], model: modelName},
          Object.fromEntries((payload || []).map((field) => [field, undefined])),
          {}
        );
        break;
      }
      default:
        console.warn(`unknown ws message with type  "${data.type}"`);
    }
  }, []);

  useEffect(() => {
    const destroy = createSocket({
      onStatusChange: setStatus,
      accountId,
      onMessage: handleMessage,
    });
    return destroy;
  }, [setStatus, accountId, handleMessage]);

  const reveal = useReveal(status === "failed", {
    from: {opacity: 0, scale: 0.5},
    enter: {opacity: 1, scale: 1},
  });

  return reveal((props) => (
    <XCol
      as={animated.div}
      style={{...props, bottom: 10, left: "50%", width: 180, marginLeft: -90, zIndex: 3}}
      bg="active100"
      border="active400"
      fixed
      px={1}
      py={0}
      rounded="md"
      elevation={2}
    >
      <XText color="active700" size={1} align="center" preset="bold">
        Lost connection to server. Trying to reconnect...
      </XText>
    </XCol>
  ));
};

export default window.WebSocket ? OuterPushUpdates : () => null;

import {useInstance} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import sortBy from "lodash/sortBy";
import {getMilestonesByProjectIds, msToDate} from "../../milestones/milestone-utils";
import MilestoneLabel from "../../../pages/milestones/MilestoneLabel";

const MilestoneOpt = ({id, short, account}) => {
  const ms = useInstance("milestone", id);
  return <MilestoneLabel ms={ms} short={short} account={account} />;
};

const milestoneCategory = {
  key: "milestone",
  label: "Milestone",
  getSuggestions({input, projectIds, root}) {
    const getMilestones = () => {
      const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
      return sortBy(getMilestonesByProjectIds(root.account, projectIds), (m) => {
        const diffInDays = (msToDate(m).getTime() - todayMidnight) / (24 * 3600 * 1000);
        return diffInDays < 0 ? 10000 + Math.abs(diffInDays) : diffInDays;
      });
    };
    if ("milestone".startsWith(input)) {
      return [...getMilestones().map((m) => m.id), null];
    }

    if (input.length < 2) return [];
    return [
      ...getMilestones()
        .filter((m) => m.$meta.get("name", "").toLowerCase().indexOf(input) > -1)
        .map((m) => m.id),
      ...("no milestone".startsWith(input) ? [null] : []),
    ];
  },
  valueToKey(value) {
    return value;
  },
  valuesToDbQuery(values) {
    return {milestoneId: values};
  },
  renderSuggestion(value, {root}) {
    return value ? <MilestoneOpt id={value} account={root.account} /> : "No milestone";
  },
  renderPill(value, {root}) {
    return value ? <MilestoneOpt short id={value} account={root.account} /> : "No milestone";
  },
  savedSearchLabel(value) {
    if (!value) return {prio: -2, label: "w/o milestone"};
    const ms = api.getModel({modelName: "milestone", id: value});
    return {
      prio: -2,
      label: `due for '${ms.name.slice(0, 16)}'`,
    };
  },
  nonPublic: true,
};

export default milestoneCategory;

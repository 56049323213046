import {XGhostButton} from "@cdx/common";

// fancy animated variation here: https://gist.github.com/danielberndt/e078dc2a8433272a4d3de508e42511b6

const ShowAllCardsButton = ({onClick, isCollapsed, totalCount, onPurple}) => (
  <XGhostButton onClick={onClick} color={onPurple ? "whiteOnPurple" : "faintWhite"}>
    {isCollapsed ? `See all ${totalCount}` : `See first ${Math.min(totalCount, 7)}`}
  </XGhostButton>
);

export default ShowAllCardsButton;

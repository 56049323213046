import {cx} from "@cdx/common";
import {documentStyles as styles} from "./document.css";

/** @param {{
 * size?: keyof typeof styles.bySize,
 * variant?: keyof typeof styles.variants,
 * className?: string
 * children: import("react").ReactNode
 * }} */
const Document = ({size = "md", variant = "default", className, ...rest}) => (
  <div
    className={cx(className, styles.base, styles.bySize[size], styles.variants[variant])}
    dir="auto"
    {...rest}
  />
);

export default Document;

import {forwardRef} from "react";
import {XText} from ".";
import {badgeStyles as styles} from "./badge.css";
import {cx} from "@cdx/common";
import dsStyles from "@cdx/ds/css/index.css";

const sizeToTextSize = {sm: 1, md: 2, xs: 0};

/**
 * @type {React.FC<{
 * size?: "sm" | "md" | "xs",
 * color?: string,
 * inline?: boolean,
 * children: React.ReactNode
 * }>}
 */
export const TextBadge = forwardRef(
  ({children, size = "md", color = "active", inline, className, ...rest}, ref) => (
    <div
      className={cx(
        inline ? dsStyles.display["inline-flex"] : dsStyles.display.flex,
        styles.default,
        styles.bySize[size],
        styles.byColor[color],
        inline && styles.inline,
        dsStyles.textTransform.none,
        className
      )}
      {...rest}
      ref={ref}
    >
      <XText size={sizeToTextSize[size]} color="white">
        {children}
      </XText>
    </div>
  )
);

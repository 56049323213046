import {
  Col,
  DSIconBarChart,
  DSIconButton,
  DSIconCalendar,
  DSIconChevronDown,
  DSIconClose,
  DSIconMilestone,
  DSIconPlus,
  DSRawButton,
  Row,
  Text,
} from "@cdx/ds";
import {ShowEl, useSmartSidebarOpenStore} from "../../components/SmartSidebar";
import {ArrowOverlay, DropDownForChild, TooltipForChild, useNextDropDown} from "@cdx/common";
import {useEffect, useRef} from "react";
import {Root} from "../../cdx-models/Root";
import {SprintConfigCreateForm} from "./sprint-form";
import {getActiveSprintConfigs, ThemedSprintIcon} from "../../features/milestones/sprint-utils";
import {SprintConfig, SprintConfigId} from "../../cdx-models/SprintConfig";
import {setMsThemeColor} from "../../features/milestones/milestone-utils";

const StatsBarButton = ({showEl, root}: {showEl?: ShowEl | null; root: Root}) => {
  const sprintConfigs = getActiveSprintConfigs(root.account);
  if (sprintConfigs.length === 0) {
    return (
      <TooltipForChild tooltip="Show Milestone Overview">
        <DSIconButton
          icon={<DSIconBarChart />}
          variant="secondary"
          onClick={
            showEl?.type === "msOverview"
              ? useSmartSidebarOpenStore.getState().close
              : () => useSmartSidebarOpenStore.getState().showStatsOverview({type: "milestones"})
          }
          active={showEl?.type === "msOverview"}
        />
      </TooltipForChild>
    );
  } else {
    return <StatsBarButtonWithConfigs showEl={showEl} sprintConfigs={sprintConfigs} />;
  }
};

const OfferStats = ({
  sprintConfigs,
  onClose,
}: {
  sprintConfigs: SprintConfig[];
  onClose: () => void;
}) => {
  return (
    <Col colorTheme="gray550" bg="foreground" px="16px" py="12px" sp="12px">
      <Text type="label12" color="primary" as="h3">
        Show Stats for
      </Text>
      <Col sp="4px">
        <DSIconButton
          variant="secondary"
          onClick={() => {
            useSmartSidebarOpenStore.getState().showStatsOverview({type: "milestones"});
            onClose();
          }}
          icon={<DSIconMilestone />}
          label="Milestones"
        />
        {sprintConfigs.map((sc) => (
          <DSIconButton
            variant="secondary"
            onClick={() => {
              useSmartSidebarOpenStore
                .getState()
                .showStatsOverview({type: "sprintConfig", id: sc.id as SprintConfigId});
              onClose();
            }}
            icon={
              <ThemedSprintIcon
                theme={setMsThemeColor(sc)}
                size={null}
                sprintState="current"
                onDark
              />
            }
            label={sc.name}
          />
        ))}
      </Col>
    </Col>
  );
};

const StatsBarButtonWithConfigs = ({
  showEl,
  sprintConfigs,
}: {
  showEl?: ShowEl | null;
  sprintConfigs: SprintConfig[];
}) => {
  const active = showEl?.type === "msOverview";

  const {
    ref: dropDownRef,
    toggle,
    overlayElement,
    isOpen,
  } = useNextDropDown({
    overlayProps: {
      placement: "bottom",
      distanceFromAnchor: 10,
      renderOverlay: ({close, ...overlayProps}: any) => (
        <ArrowOverlay bg="gray700" arrowSize="xs" {...overlayProps}>
          <OfferStats sprintConfigs={sprintConfigs} onClose={close} />
        </ArrowOverlay>
      ),
    },
  });

  return (
    <>
      {overlayElement}
      <TooltipForChild
        tooltip={!active && "Show stats for Milestones or Run Configurations"}
        hidden={isOpen}
        ref={dropDownRef}
      >
        <DSRawButton
          variant="secondary"
          active={active}
          onClick={active ? useSmartSidebarOpenStore.getState().close : toggle}
        >
          <Row sp="4px" align="center">
            <DSIconBarChart />
            {active ? <DSIconClose size={16} /> : <DSIconChevronDown size={16} />}
          </Row>
        </DSRawButton>
      </TooltipForChild>
    </>
  );
};

const MilestoneSidebarToggles = ({className, root}: {className?: string; root: Root}) => {
  const showEl = useSmartSidebarOpenStore((s) => s.showEl);

  const features = [];
  if (root.account.milestonesEnabled) features.push("Milestones");
  if (root.account.sprintsEnabled) features.push("Runs");

  const isOverlayOpen = showEl?.type === "calendar" || showEl?.type === "msOverview";
  const isOpenRef = useRef(isOverlayOpen);
  isOpenRef.current = isOverlayOpen;
  useEffect(() => {
    return () => {
      if (isOpenRef.current) {
        useSmartSidebarOpenStore.getState().close();
      }
    };
  }, []);

  return (
    <Row sp="8px" className={className}>
      {root.account.milestonesEnabled ? (
        <TooltipForChild tooltip={`Open Calendar to add ${features.join(" and ")}`}>
          <DSIconButton
            icon={<DSIconPlus />}
            onClick={
              showEl?.type === "calendar"
                ? useSmartSidebarOpenStore.getState().close
                : useSmartSidebarOpenStore.getState().showCalendar
            }
          />
        </TooltipForChild>
      ) : root.account.sprintsEnabled ? (
        <DropDownForChild
          setChildProps={({isOpen}: any) => ({hidden: isOpen})}
          renderOverlay={({close, ...props}: any) => (
            <ArrowOverlay bg="white" arrowSize="xs" {...props}>
              <SprintConfigCreateForm onClose={close} />
            </ArrowOverlay>
          )}
          overlayProps={{placement: "bottom", distanceFromAnchor: 10}}
        >
          <TooltipForChild tooltip="Set up Runs">
            <DSIconButton icon={<DSIconPlus />} />
          </TooltipForChild>
        </DropDownForChild>
      ) : null}
      {root.account.milestonesEnabled && (
        <TooltipForChild tooltip="Open Calendar View">
          <DSIconButton
            icon={<DSIconCalendar />}
            variant="secondary"
            onClick={
              showEl?.type === "calendar"
                ? useSmartSidebarOpenStore.getState().close
                : useSmartSidebarOpenStore.getState().showCalendar
            }
            active={showEl?.type === "calendar"}
          />
        </TooltipForChild>
      )}
      <StatsBarButton showEl={showEl} root={root} />
    </Row>
  );
};

export default MilestoneSidebarToggles;

import {cloneElement, forwardRef, lazy, ReactElement, ReactNode, Suspense, useState} from "react";
import {WorkflowItem, WorkflowItemId} from "../../cdx-models/WorkflowItem";
import useMutation from "../../lib/hooks/useMutation";
import {useInstance} from "../../lib/mate/mate-utils";
import {cx, DefaultOverlay, SpawnAnchoredOverlayWithNode, useGetNodeFromRef} from "@cdx/common";
import {Col, DSSpinner} from "@cdx/ds";
import {CardPropChangeKey} from "../../components/RichTextarea/Lexical/CardPropChangeOverlay";
import {create} from "zustand";
import {OnShortCut} from "../../components/RichTextarea/Lexical/CardPropChangeForChild";

const LazyOverlay = lazy(() => import("./WorkflowPropChangeOverlay"));

type WorkflowPropOverlayForExistingWorkflowProps = {
  itemId: WorkflowItemId | null;
  overlayProps: any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
};

const WorkflowPropOverlayForExistingCard = ({
  itemId,
  overlayProps,
  onClose,
  initialTab,
}: WorkflowPropOverlayForExistingWorkflowProps) => {
  const [doUpdate] = useMutation("workflows", "updateItem");

  const handleUpdate = ({deckId, ...rest}: any) => {
    doUpdate({itemId, targetDeckId: deckId, ...rest});
  };

  const item = useInstance("workflowItem", itemId);

  return (
    <DefaultOverlay
      {...overlayProps}
      onClick={(e: any) => e.stopPropagation()}
      onMouseMove={(e: any) => e.stopPropagation()}
    >
      {item ? (
        <Suspense
          fallback={
            <Col style={{height: 400, width: 250}} align="center" justify="center">
              <DSSpinner size={24} />
            </Col>
          }
        >
          <LazyOverlay
            item={item}
            onChange={handleUpdate}
            onClose={onClose}
            initialTab={initialTab}
          />
        </Suspense>
      ) : (
        <div>No Step found</div>
      )}
    </DefaultOverlay>
  );
};

const useStore = create<{open: null | {cardKey: string; tab: CardPropChangeKey | null}}>((set) => ({
  open: null,
}));
type WorkflowPropChangeOverlayForChildProps = {
  children: ReactNode;
  className?: string;
  placement?: string;
  itemId: WorkflowItemId | null;
  cardContainerKey: string;
  availableShortcuts?: CardPropChangeKey[];
  initialTab?: CardPropChangeKey | null;
  getChildProps?: (open: boolean) => any;
  onClick?: (e: any) => void;
  disabled?: boolean;
};
export const WorkflowPropChangeOverlayForChild = forwardRef<
  HTMLElement,
  WorkflowPropChangeOverlayForChildProps
>((props, passedRef) => {
  const {
    children,
    placement = "bottom",
    itemId,
    availableShortcuts,
    initialTab,
    getChildProps,
    onClick,
    disabled,
    cardContainerKey,
  } = props;
  const {node, ref} = useGetNodeFromRef(passedRef);
  const {open} = useStore();

  if (process.env.REACT_APP_MODE === "open" || disabled) return children as ReactElement;
  if (!itemId) return children as ReactElement;
  const key = `${cardContainerKey}:${itemId}`;

  const events = {
    onClick: (e: MouseEvent) => {
      onClick?.(e);
      if (e.defaultPrevented) return;
      e.stopPropagation();
      e.preventDefault();
      const getInitialTab = () => {
        if (initialTab) return initialTab;
        if (availableShortcuts?.length) {
          return availableShortcuts[0];
        }
        return "deck";
      };
      useStore.setState({
        open: open && open.cardKey === key ? null : {cardKey: key, tab: getInitialTab()},
      });
    },
  };

  return (
    <SpawnAnchoredOverlayWithNode
      node={node}
      isOpen={open?.cardKey === key}
      distanceFromAnchor={5}
      renderOverlay={(op: any) => (
        <WorkflowPropOverlayForExistingCard
          itemId={itemId}
          onClose={() => useStore.setState({open: null})}
          overlayProps={op}
          initialTab={open?.tab}
        />
      )}
      placement={placement}
      preventPlacement="top"
    >
      {(availableShortcuts || []).map((changeKey) => (
        <OnShortCut
          key={changeKey}
          changeKey={changeKey}
          onPress={() => useStore.setState({open: {cardKey: key, tab: changeKey}})}
        />
      ))}
      {cloneElement(children as any, {
        ...events,
        ...getChildProps?.(open?.cardKey === key),
        ref,
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

type WorkflowItemPropOverlayForNewProps = {
  item: WorkflowItem;
  onChange: (arg: any) => void;
  overlayProps: any;
  onClose: () => void;
  initialTab?: CardPropChangeKey | null;
};

const WorkflowItemPropOverlayForNew = ({
  item,
  onChange,
  overlayProps,
  onClose,
  initialTab,
}: WorkflowItemPropOverlayForNewProps) => {
  const handleChange = ({assigneeId, ...rest}: any) => {
    onChange({...rest, ...(assigneeId !== undefined && {assignee: assigneeId})});
  };

  return (
    <DefaultOverlay
      {...overlayProps}
      onClick={(e: any) => e.stopPropagation()}
      onMouseMove={(e: any) => e.stopPropagation()}
    >
      <Suspense
        fallback={
          <Col style={{height: 400}} align="center" justify="center">
            <DSSpinner size={24} />
          </Col>
        }
      >
        <LazyOverlay
          item={item}
          onChange={handleChange}
          onClose={onClose}
          initialTab={initialTab}
          isNewCard
        />
      </Suspense>
    </DefaultOverlay>
  );
};

type NewWorkflowItemPropChangeOverlayForChildProps = {
  children: ReactNode;
  className?: string;
  placement?: string;
  item: WorkflowItem;
  onChange: (arg: any) => void;
  availableShortcuts?: CardPropChangeKey[];
  initialTab?: CardPropChangeKey | null;
  getChildProps?: (open: boolean) => any;
  onClick?: (e: any) => void;
};
export const NewWorkflowItemPropChangeOverlayForChild = forwardRef<
  HTMLElement,
  NewWorkflowItemPropChangeOverlayForChildProps
>((props, passedRef) => {
  const {
    children,
    className,
    placement = "bottom",
    item,
    onChange,
    availableShortcuts,
    initialTab,
    getChildProps,
    onClick,
  } = props;
  const [open, setOpen] = useState<CardPropChangeKey | null>(null);
  const events = {
    onClick: (e: MouseEvent) => {
      onClick?.(e);
      if (e.defaultPrevented) return;
      e.stopPropagation();
      e.preventDefault();
      const getInitialTab = () => {
        if (initialTab) return initialTab;
        if (availableShortcuts?.length) {
          return availableShortcuts[0];
        }
        return "deck";
      };
      setOpen(open ? null : getInitialTab());
    },
  };
  const {node, ref} = useGetNodeFromRef(passedRef);

  return (
    <SpawnAnchoredOverlayWithNode
      node={node}
      isOpen={Boolean(open)}
      distanceFromAnchor={5}
      renderOverlay={(op: any) => (
        <WorkflowItemPropOverlayForNew
          item={item}
          onChange={onChange}
          onClose={() => setOpen(null)}
          overlayProps={op}
          initialTab={open}
        />
      )}
      placement={placement}
      preventPlacement="top"
    >
      {(availableShortcuts || []).map((key) => (
        <OnShortCut key={key} changeKey={key} onPress={() => setOpen(key)} />
      ))}
      {cloneElement(children as any, {
        ...events,
        ref,
        className: cx(className, (children as any).props.className),
        ...getChildProps?.(Boolean(open)),
      })}
    </SpawnAnchoredOverlayWithNode>
  );
});

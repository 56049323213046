import {ArrowOverlay, DropDownForChild as RawDropDownForChild, XTextButton} from "@cdx/common";
import {
  Box,
  Col,
  DSIconDoc,
  DSIconCalendar,
  Row,
  DSIconBell,
  DSIconButton,
  DSIconYoutube,
  DSIconDiscord,
  DSIconTwitter,
  DSIconMail,
  DSIconCalendarMilestone,
  BoxProps,
  DSIconQuestion,
  DSIconCheck,
  DSIconClose,
  DSSpinner,
} from "@cdx/ds";
import {decorationStyles} from "@cdx/ds/css/decoration.css";
import {layoutStyles} from "@cdx/ds/css/layout.css";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";
import {ReactNode, useEffect, useState} from "react";
import {useModalAdress} from "../../components/ModalRegistry";
import cdxEnv from "../../env";

const Entry = ({label, icon, ...props}: any) => (
  <DSIconButton icon={icon} variant="tertiary" size="md" label={label} {...props} />
);

const Heading = (props: {children: string}) => (
  <Box as="h2" color="secondary" textTransform="uppercase" size={11}>
    {props.children}
  </Box>
);

type Status = "initial" | "up" | "down" | "error";
export type HelpCenterApiStatus = Status;

const propsByStatus: {
  [S in Status]: {colorTheme: BoxProps["colorTheme"]; label: string; icon: ReactNode};
} = {
  initial: {colorTheme: "gray50", label: "Loading", icon: <DSSpinner />},
  up: {colorTheme: "active50", label: "Backend operational", icon: <DSIconCheck />},
  down: {colorTheme: "alert25", label: "Backend down", icon: <DSIconClose />},
  error: {colorTheme: "dimPurple600", label: "Cannot load status", icon: <DSIconQuestion />},
};

const DumbStatusArea = ({status}: {status: Status}) => {
  const {colorTheme, label, icon} = propsByStatus[status];
  return (
    <Row
      sp="12px"
      align="center"
      colorTheme={colorTheme}
      bg="foreground"
      py="12px"
      px="16px"
      rounded={4}
    >
      <Box size={14} color="primary" bold>
        Status
      </Box>
      <DSIconButton
        label={label}
        variant="tertiary"
        size="sm"
        icon={icon}
        iconPosition="right"
        href="https://status.codecks.io"
        className={layoutStyles.ml.auto}
      />
    </Row>
  );
};

const StatusArea = () => <DumbStatusArea status={useUptimeRobotStatus()} />;

export const HelpCenterStatusArea = DumbStatusArea;

const OverlayContent = ({
  releaseUrl,
  getsBookADemo,
}: {
  releaseUrl: string;
  getsBookADemo: boolean;
}) => (
  <Col pa="12px" sp="16px">
    <Row sp="16px" pt="12px" px="8px">
      <Col as="section" sp="12px">
        <Heading>Resources</Heading>
        <Col sp="8px" colorTheme="purpleTextOnLight">
          <Entry label="Manual" icon={<DSIconDoc />} href="https://manual.codecks.io" />
          <Entry
            label="YouTube"
            icon={<DSIconYoutube />}
            href="https://www.youtube.com/c/codecks"
          />
          <Entry label="What's new" icon={<DSIconBell />} to={releaseUrl} />
          <Entry
            label="Our Roadmap"
            icon={<DSIconCalendarMilestone />}
            href="https://open.codecks.io/codecks-roadmap"
          />
        </Col>
      </Col>
      <Col as="section" sp="12px">
        <Heading>Get in touch</Heading>
        <Col sp="8px" colorTheme="purpleTextOnLight">
          <Entry label="Discord" icon={<DSIconDiscord />} href="https://discord.gg/9dPXv6T" />
          <Entry label="Email" icon={<DSIconMail />} href="mailto:support@codecks.io" />
          {getsBookADemo && (
            <Entry
              label="Book a Demo"
              icon={<DSIconCalendar />}
              href="https://calendly.com/codecks"
            />
          )}
          <Entry label="Twitter" icon={<DSIconTwitter />} href="https://twitter.com/codecks_io" />
        </Col>
      </Col>
    </Row>
    {cdxEnv.ON_PREMISE !== "true" && <StatusArea />}
  </Col>
);

export const HelpCenterOverlayContent = OverlayContent;

const useUptimeRobotStatus = () => {
  const [status, setStatus] = useState<Status>("initial");
  useEffect(() => {
    fetch("https://api.uptimerobot.com/v2/getMonitors", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        api_key: cdxEnv.UPTIME_ROBOT_PUBLIC_API_KEY,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        type Monitor = {status: 0 | 1 | 2 | 8 | 9};
        const monitors: Monitor[] | undefined = res.monitors;
        if (!monitors || monitors.length === 0) {
          setStatus("error");
        } else {
          const someDown = monitors.some((m) => m.status === 8 || m.status === 9);
          setStatus(someDown ? "down" : "up");
        }
      });
  }, []);
  return status;
};

const Overlay = ({overlayProps, root}: any) => {
  const lastReleaseId = root.$meta.first("releases", {$order: "-createdAt"}).id;
  const releaseUrl = useModalAdress({modal: `releases.${lastReleaseId}`} as any);
  const persona = root.account.$meta.get("persona", false);
  const getsBookADemo =
    persona !== false &&
    root.account.persona !== "soloGameDev" &&
    root.account.billingType !== "education";

  return (
    <ArrowOverlay
      bg={null}
      arrowSize="xs"
      outerClassName={colorThemes.gray25}
      className={decorationStyles.bg.foreground}
      arrowUsesBgVar
      {...overlayProps}
    >
      <OverlayContent releaseUrl={releaseUrl} getsBookADemo={getsBookADemo} />
    </ArrowOverlay>
  );
};

const DropDownForChild = RawDropDownForChild as any;

export const HelpCenterButton = ({root}: any) => (
  <DropDownForChild
    setChildProps={({isOpen}: any) => ({
      tooltip: isOpen ? null : "Open Help Center",
      active: isOpen,
    })}
    renderOverlay={({close, ...props}: any) => (
      <Overlay overlayProps={props} close={close} root={root} />
    )}
    overlayProps={{placement: "bottom", distanceFromAnchor: 10}}
  >
    <XTextButton color="darkerWhite" square>
      <DSIconQuestion size={16} />
    </XTextButton>
  </DropDownForChild>
);

import {XRow} from "../../../components/xui";
import {useInstance} from "../../../lib/mate/mate-utils";
import {api} from "../../../lib/api";
import MiniProjectTile from "../../../components/MiniProjectTile";

const ProjectOpt = ({id}) => {
  const project = useInstance("project", id);
  return (
    <XRow sp={1} align="center">
      <MiniProjectTile project={project} size={16} />
      <div>{project.name}</div>
    </XRow>
  );
};

const projectCategory = {
  key: "project",
  label: "Project",
  getSuggestions({input, projects}) {
    if (input.length < 2) return [];
    return [
      ...projects
        .filter((d) => d.$meta.get("name", "").toLowerCase().indexOf(input) > -1)
        .map((d) => d.id),
      ...("no decks".startsWith(input) ? [null] : []),
    ];
  },
  valueToKey(value) {
    return value;
  },
  valuesToDbQuery(values) {
    const nonNulls = values.filter((v) => v !== null);
    if (nonNulls.length !== values.length) {
      return {$or: [{deck: {projectId: nonNulls}}, {deckId: null}]};
    } else {
      return {deck: {projectId: nonNulls}};
    }
  },
  renderSuggestion(value) {
    return value ? <ProjectOpt id={value} /> : "No project";
  },
  savedSearchLabel(value) {
    if (!value)
      return {
        prio: -3,
        label: `w/o project`,
      };
    const project = api.getModel({modelName: "project", id: value});
    return {
      prio: -3,
      label: `in '${project.name.slice(0, 16)}'`,
    };
  },
};

export default projectCategory;

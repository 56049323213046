import {pillStyles} from "./deck.css";
import {cx, TooltipForChild} from "@cdx/common";

const Pill = ({
  children,
  type,
  hasNotifications,
  size = "md",
  tooltip,
  style,
  className,
  bigNumber,
}) => (
  <TooltipForChild tooltip={tooltip} delayed bg="gray800" as="div">
    <div
      className={cx(
        className,
        pillStyles.container.base,
        pillStyles.container.bySize[size],
        pillStyles.container.byType[type]
      )}
      style={style}
    >
      <div
        className={cx(
          pillStyles.content.base,
          pillStyles.content.bySize[size],
          bigNumber && pillStyles.content.bigNumber
        )}
      >
        {children}
      </div>
      <div
        className={cx(
          pillStyles.iconArea.base,
          pillStyles.iconArea.bySize[size],
          hasNotifications && pillStyles.iconArea.withNotification
        )}
      />
    </div>
  </TooltipForChild>
);

export default Pill;

import {useState, Suspense} from "react";
import {useFitOnScreen} from "./FitOnScreen";
import {animated} from "react-spring";
import {usePosition, useZIndex} from "./Position";
import {useMeasureWithNode} from "../hooks/useMeasure";
import {useReveal, springConfigs} from "../hooks/useReveal";
import Portal from "../Portal";
import {RawSpinner} from "../xui/Spinner";
import Arrow from "../xui/Arrow";
import xcolors from "../xui/xcolors";
import {overlayStyles} from "./overlay.css";
import XCol from "../xui/XCol";
import cx from "../cx";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";

/**
 * @type any
 */
export const OverlayPlacer = ({
  node,
  isOpen,
  presenceProps,
  backdrop,
  placement: preferredPlacement,
  forcePlacement,
  preventPlacement,
  viewportMargin,
  distanceFromAnchor,
  anchorAlignment,
  renderOverlay,
}) => {
  const anchorPosition = usePosition(node);
  const zIndex = useZIndex(node);
  const [overlayNode, setOverlayNode] = useState();
  const overlayDims = useMeasureWithNode(overlayNode);

  const fitProps = useFitOnScreen({
    overlayNode,
    preferredPlacement,
    anchorPosition,
    forcePlacement,
    preventPlacement,
    viewportMargin,
    distanceFromAnchor,
    anchorAlignment,
    overlayDims,
  });
  if (!anchorPosition || !fitProps || zIndex === null) return null;
  const {style, arrowOffsetLeft, arrowOffsetTop, placement} = fitProps;
  return (
    <>
      {backdrop && (
        <XCol
          as={animated.div}
          className={overlayStyles.backdrop}
          fixed
          inset="full"
          style={{zIndex: zIndex + 4, opacity: presenceProps?.value}}
        />
      )}
      {renderOverlay({
        anchorZIndex: zIndex + 5,
        presenceProps,
        isOpen,
        anchorPosition,
        style,
        arrowOffsetLeft,
        arrowOffsetTop,
        placement,
        setNode: setOverlayNode,
      })}
    </>
  );
};

export const SpawnAnchoredOverlayWithNode = ({
  children,
  isOpen,
  node,
  springConfig = springConfigs.quick,
  ...rest
}) => {
  const reveal = useReveal(isOpen, {config: springConfig});

  return (
    <>
      {children}
      {reveal((props) => (
        <Portal>
          <OverlayPlacer node={node} presenceProps={props} isOpen={isOpen} {...rest} />
        </Portal>
      ))}
    </>
  );
};

export const SpawnAnchoredOverlay = ({children, ...rest}) => {
  const [node, setNode] = useState();
  if (typeof children !== "function")
    return <div style={{width: 20, height: 20, background: "yellow"}} />;
  return (
    <SpawnAnchoredOverlayWithNode node={node} {...rest}>
      {children(setNode)}
    </SpawnAnchoredOverlayWithNode>
  );
};

export const DefaultOverlay = (props) => {
  const {
    children,
    style,
    anchorZIndex,
    presenceProps,
    placement,
    setNode,
    className,
    bg,
    color,
    isOpen,
    arrowOffsetLeft,
    arrowOffsetTop,
    anchorPosition,
    onClick,
    close: _,
    ...restProps
  } = props;

  const xMod = placement === "left" ? 20 : placement === "right" ? -20 : 0;
  const yMod = placement === "top" ? 20 : placement === "bottom" ? -20 : 0;
  return (
    <XCol
      absolute
      rounded="sm"
      elevation={1}
      minHeight
      bg={bg || "white"}
      as={animated.div}
      className={cx(overlayStyles.defaultOverlay, className)}
      style={{
        zIndex: anchorZIndex,
        opacity: presenceProps?.value,
        transform: presenceProps?.value.to(
          (val) => `translate3d(${xMod * (1 - val)}px, ${yMod * (1 - val)}px, 0)`
        ),
        pointerEvents: presenceProps?.value.to((val) => (val > 0.9 ? "initial" : "none")),
        color: color && xcolors[color],
        ...style,
      }}
      ref={setNode}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...restProps}
    >
      <Suspense fallback={<RawSpinner />}>{children}</Suspense>
    </XCol>
  );
};

const reversePlacement = {top: "bottom", bottom: "top", left: "right", right: "left"};

export const ArrowOverlay = (props) => {
  const {
    children,
    arrowSize = "md",
    arrowUsesBgVar,
    style: {overflow, ...restStyle},
    anchorZIndex,
    presenceProps,
    placement,
    bg,
    color,
    contentStyle,
    containerStyle,
    setNode,
    className,
    outerClassName,
    isOpen,
    arrowOffsetLeft,
    arrowOffsetTop,
    anchorPosition,
    close: _,
    noPointerEvents,
    onClick,
    allowMouseEventBubbling,
    ...rest
  } = props;

  const xMod = placement === "left" ? 20 : placement === "right" ? -20 : 0;
  const yMod = placement === "top" ? 20 : placement === "bottom" ? -20 : 0;

  return (
    <XCol
      as={animated.div}
      absolute
      minHeight
      style={{
        zIndex: anchorZIndex,
        opacity: presenceProps?.value,
        transform: presenceProps?.value.to(
          (val) => `translate3d(${xMod * (1 - val)}px, ${yMod * (1 - val)}px, 0)`
        ),
        pointerEvents: presenceProps?.value.to((val) =>
          val > 0.9 && !noPointerEvents ? "initial" : "none"
        ),
        ...restStyle,
      }}
      ref={setNode}
      className={outerClassName}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...(allowMouseEventBubbling
        ? {}
        : {
            onMouseDown: (e) => e.stopPropagation(),
            onMouseUp: (e) => e.stopPropagation(),
          })}
      {...rest}
    >
      <Suspense fallback={<RawSpinner />}>
        <XCol
          elevation={2}
          bg={bg === null ? null : bg || "white"}
          className={cx(
            overlayStyles.defaultOverlay,
            className,
            bg === "active" && colorThemes.active600
          )}
          style={{color: color && xcolors[color], overflow, ...contentStyle}}
          minHeight
          rounded="sm"
          noOverflow
        >
          {children}
        </XCol>
        <Arrow
          size={arrowSize}
          color={
            arrowUsesBgVar
              ? null
              : contentStyle && contentStyle.backgroundColor
                ? contentStyle.backgroundColor
                : xcolors[bg] || xcolors.white
          }
          pointTo={reversePlacement[placement]}
          style={{
            top: arrowOffsetTop || undefined,
            left: arrowOffsetLeft || undefined,
          }}
        />
      </Suspense>
    </XCol>
  );
};

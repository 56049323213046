import {
  $getSelection,
  $isRangeSelection,
  $addUpdateTag,
  KEY_ENTER_COMMAND,
  KEY_TAB_COMMAND,
  COMMAND_PRIORITY_LOW,
  INSERT_LINE_BREAK_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  INDENT_CONTENT_COMMAND,
  $isLineBreakNode,
  RangeSelection,
  LexicalEditor,
} from "lexical";
import {useEffect} from "react";
import {mergeRegister} from "@lexical/utils";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {RootLineAstNode, parseLine} from "./parseMarkdownLineAst";
import {getSmartEnterCompletion, needToLookAtPreviousLine} from "./smartIndentHelpers";
import {sanitizeSelection} from "../CdxPlainTextPlugin";

const getLines = (sel: RangeSelection) => {
  const anchor = sel.anchor;
  if (anchor.type !== "text") return null;
  const anchorNode = anchor.getNode();
  const lines: RootLineAstNode[] = [];
  let currLine: string[] = [anchorNode.getTextContent().slice(0, anchor.offset)];
  const parent = anchorNode.getParentOrThrow();
  const candidates = parent.getChildren().slice(0, Math.max(0, anchorNode.getIndexWithinParent()));
  for (let i = candidates.length - 1; i >= 0; i -= 1) {
    const child = candidates[i];
    if ($isLineBreakNode(child)) {
      const lineAst = parseLine(currLine.join(""));
      lines.push(lineAst);
      currLine = [];
      if (!needToLookAtPreviousLine(lineAst)) break;
    }
    currLine.unshift(child.getTextContent());
  }
  if (currLine.length) lines.push(parseLine(currLine.join("")));
  return lines;
};

export const cdxTabKeyEvent = (event: KeyboardEvent | null, editor: LexicalEditor) => {
  if (!event) return false;
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return false;
  event.stopImmediatePropagation();
  event.preventDefault();
  return editor.dispatchCommand(
    event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
    undefined
  );
};

export const CdxSmartPlainTextCompletionPlugin = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    let smartPrefixStack = null as null | [string, string][];
    return mergeRegister(
      editor.registerCommand<KeyboardEvent | null>(
        KEY_ENTER_COMMAND,
        (event) => {
          if (!event) return false;
          const softBreak = event.altKey || event.shiftKey;
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return false;
          sanitizeSelection(selection);
          if (smartPrefixStack && !softBreak) {
            // todo: reduce stack by one
            const lineText = selection.anchor.getNode().getTextContent();
            const lastEl = smartPrefixStack.pop();
            if (!smartPrefixStack.length) smartPrefixStack = null;
            if (!lastEl || !lineText.endsWith(lastEl[1])) return false;
            const nextPrefix = smartPrefixStack
              ? [
                  ...smartPrefixStack?.slice(0, -1).map(([e]) => e),
                  smartPrefixStack[smartPrefixStack.length - 1][1],
                ].join("")
              : "";
            const diff = lineText.length - nextPrefix.length;
            const anchorNode = selection.anchor.getNode();
            if (!("setTextContent" in anchorNode)) return false;
            anchorNode.setTextContent(nextPrefix);
            selection.anchor.offset -= diff;
            selection.focus.offset -= diff;
            $addUpdateTag("smart-edit");
            event.stopImmediatePropagation();
            event.preventDefault();
            return true;
          } else {
            const lines = getLines(selection);
            if (!lines) return false;
            smartPrefixStack = getSmartEnterCompletion(lines);
            if (smartPrefixStack && editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false)) {
              const nextPrefix = [
                ...smartPrefixStack?.slice(0, -1).map(([e]) => e),
                smartPrefixStack[smartPrefixStack.length - 1][softBreak ? 0 : 1],
              ].join("");
              selection.insertText(nextPrefix);
              $addUpdateTag("smart-edit");
              event.stopImmediatePropagation();
              event.preventDefault();
              return true;
            } else {
              return false;
            }
          }
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<KeyboardEvent | null>(
        KEY_TAB_COMMAND,
        cdxTabKeyEvent,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerUpdateListener(({tags}) => {
        if (!tags.has("smart-edit")) smartPrefixStack = null;
      })
    );
  }, [editor]);
  return null;
};

import {useTransition, animated} from "react-spring";
import XRow from "../xui/XRow";

export const springConfigs = {
  quick: {tension: 500, friction: 30},
  quickNoBounce: {tension: 600, friction: 44},
};

export const useReveal = (show, {from = {value: 0}, enter = {value: 1}, ...opts} = {}) => {
  const transition = useTransition(show, {
    key: (i) => !!i,
    from,
    enter,
    leave: opts.leave || {value: 0.001},
    ...opts,
  });
  return (renderFn) => transition((props, item) => (item ? renderFn(props, item) : null));
};

export const RevealContainer = ({show, children, pl = 2}) => {
  const reveal = useReveal(show);
  return reveal((props) => (
    <XRow
      as={animated.div}
      inset="y"
      absolute
      align="center"
      pl={pl}
      style={{
        left: "100%",
        opacity: props.value,
        transform: props.value.to((v) => `scale(${v / 2 + 0.5})`),
      }}
    >
      {children}
    </XRow>
  ));
};

import {XRow} from "../../../components/xui";
import {projectsUsers} from "../../../lib/utils";
import {api} from "../../../lib/api";
import {useInstance} from "../../../lib/mate/mate-utils";
import Avatar from "../../../components/Avatar";

const UserOpt = ({id}) => {
  const user = useInstance("user", id);
  return (
    <XRow sp={1} align="center">
      <Avatar user={user} size={14} />
      <div>{user.name}</div>
    </XRow>
  );
};

const getSuggestions = ({input, projects, root}) => {
  const match = input.match(/^(?:@(\w*)|(\w{3,}))$/);
  if (match) {
    return projectsUsers(root.account, projects)
      .filter((u) =>
        u.$meta
          .get("name", "")
          .toLowerCase()
          .startsWith(match[1] || match[2] || "")
      )
      .map((u) => u.id);
  } else {
    return [];
  }
};

const userCategories = [
  {
    key: "cardOwner",
    label: "Owner",
    getSuggestions({input, projects, root}) {
      const match = input.match(/^(?:@(\w*)|(\w{3,}))$/);
      const results = [];
      if (input.length >= 2 && "no owner".startsWith(input)) results.push(null);
      if (match) {
        const matchingUserIds = projectsUsers(root.account, projects)
          .filter((u) =>
            u.$meta
              .get("name", "")
              .toLowerCase()
              .startsWith(match[1] || match[2] || "")
          )
          .map((u) => u.id);
        if (match[0].includes("@")) results.push(null);
        return [...matchingUserIds, ...results];
      } else {
        return results;
      }
    },
    valueToKey(value) {
      return value;
    },
    valuesToDbQuery(values) {
      return {assigneeId: values};
    },
    renderSuggestion(value) {
      return value ? <UserOpt id={value} /> : "No owner";
    },
    savedSearchLabel(value, {root}) {
      if (!value) return {prio: 10, label: `no one's`};
      const user = api.getModel({modelName: "user", id: value});
      const {loggedInUser} = root;
      return {
        prio: 10,
        label: value === loggedInUser.id ? "my" : `${user.name}'s`,
      };
    },
  },

  {
    label: "Created By",
    key: "creator",
    getSuggestions,
    valueToKey(value) {
      return value;
    },
    valuesToDbQuery(values) {
      return {creatorId: values};
    },
    renderSuggestion(value) {
      return <UserOpt id={value} />;
    },
    savedSearchLabel(value, {root}) {
      const user = api.getModel({modelName: "user", id: value});
      const {loggedInUser} = root;
      return {
        prio: 10,
        label: `by ${value === loggedInUser.id ? "me" : user.name}`,
      };
    },
  },

  {
    label: "Mentioned",
    key: "mention",
    getSuggestions,
    valueToKey(value) {
      return value;
    },
    valuesToDbQuery(values) {
      if (values.length === 1) return {mentionedUsers: {op: "has", value: values[0]}};
      return {$or: values.map((userId) => ({mentionedUsers: {op: "has", value: userId}}))};
    },
    renderSuggestion(value) {
      return <UserOpt id={value} />;
    },
    savedSearchLabel(value, {root}) {
      const user = api.getModel({modelName: "user", id: value});
      const {loggedInUser} = root;
      return {
        prio: -1,
        label: value === loggedInUser.id ? "@me" : `@${user.name}`,
      };
    },
  },

  {
    label: "Bookmarked by",
    key: "handCard",
    getSuggestions: () => [],
    valueToKey(value) {
      return value;
    },
    valuesToDbQuery(values) {
      return {handCards: {userId: values}};
    },
    renderSuggestion(value) {
      return "Bookmarks";
    },
    savedSearchLabel() {
      return {
        prio: -1,
        label: "bookmarked",
      };
    },
  },
];

export default userCategories;

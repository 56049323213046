if (process.env.NODE_ENV !== "production" || !window.__cdxEnv) window.__cdxEnv = {};

const cdxEnv = {
  API_HOST: process.env.REACT_APP_API_HOST || window.__cdxEnv.API_HOST,
  IMG_RESIZER_HOST: process.env.REACT_APP_IMG_RESIZER_HOST || window.__cdxEnv.IMG_RESIZER_HOST,
  PUSH_HOST: process.env.REACT_APP_PUSH_HOST || window.__cdxEnv.PUSH_HOST,
  MAX_STORAGE_MBS: process.env.REACT_APP_MAX_STORAGE_MBS || window.__cdxEnv.MAX_STORAGE_MBS,
  APP_NAME: window.__cdxEnv.APP_NAME,
  DEPLOYMENT_LABEL: process.env.DEPLOYMENT_LABEL || window.__cdxEnv.DEPLOYMENT_LABEL,

  ON_PREMISE: process.env.REACT_APP_ON_PREMISE,
  EXTRACT_SUBDOMAIN_REGEXP: process.env.REACT_APP_EXTRACT_SUBDOMAIN_REGEXP || "",
  OPEN_APP_HOST: process.env.REACT_APP_OPEN_APP_HOST || "",
  HOST_PATTERN: process.env.REACT_APP_HOST_PATTERN || "",
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
  TRELLO_API_KEY: process.env.REACT_APP_TRELLO_API_KEY || "",
  UPLOAD_HOST: process.env.REACT_APP_UPLOAD_HOST || "",
  CDN_HOST: process.env.REACT_APP_CDN_HOST || "",
  UPTIME_ROBOT_PUBLIC_API_KEY: process.env.REACT_APP_UPTIME_ROBOT_PUBLIC_API_KEY || "",

  // don't forget to add new entries to `validEnvKeys` within config-overrides.js!
};

export default cdxEnv;

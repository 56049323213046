import {useEffect, useRef} from "react";
import {getModalUrl} from "../../components/ModalRegistry";
import useStateIfLoaded from "../../lib/hooks/useStateIfLoaded";

const ReleaseAlerter = ({root, location, history}) => {
  const {state, isLoaded} = useStateIfLoaded(
    () => ({
      // lastSeenRelease can be empty if a seen release gets turned into "draft mode"
      lastSeenReleaseId: root.loggedInUser?.lastSeenRelease?.id,
      lastReleaseId: root.$meta.first("releases", {$order: "-createdAt"}).id,
    }),
    [root.loggedInUser?.$meta.get("id", null)]
  );
  const locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  }, [location]);
  useEffect(() => {
    if (!isLoaded || !state.lastSeenReleaseId) return;
    if (state.lastSeenReleaseId !== state.lastReleaseId) {
      history.push(
        getModalUrl({
          modal: `releases.${state.lastReleaseId}`,
          location: locationRef.current,
        })
      );
    }
  }, [history, isLoaded, state.lastSeenReleaseId, state.lastReleaseId]);
  return null;
};

export default ReleaseAlerter;

import {MenuButtonWithDropDown} from "./shared";
import {XCol, XText, DefaultOverlay, XForm, rules, useTabs} from "@cdx/common";
import {useAddCardToParent, areValidChildren} from "../workflows/workflow-utils";
import DeckPicker from "../../components/DeckPicker";
import useMutation from "../../lib/hooks/useMutation";
import CardPicker from "../../components/CardPicker";
import getSelectedProjects from "../../lib/hooks/useSelectedProjects";
import {DSIconCrown} from "@cdx/ds";

const createFormRules = {
  title: [rules.isRequired],
  deckId: [rules.isRequired],
};

const intialCreateValues = {title: "", deckId: null};

const CardCreator = ({onCreated, root}) => {
  const [create] = useMutation("cards", "create");
  const handleSubmit = ({deckId, title}) =>
    create({deckId, content: title, userId: root.loggedInUser.id}).then((c) => onCreated(c.id));

  return (
    <XCol
      as={XForm}
      initialValues={intialCreateValues}
      rules={createFormRules}
      onSubmit={handleSubmit}
      px={3}
      py={2}
      sp={3}
      buttonLabel="Create"
    >
      <XForm.Field name="title" label="Title of new Hero card" autoFocus />
      <XForm.Field as={DeckPicker} root={root} name="deckId" label="Create card in" />
    </XCol>
  );
};

export const CardPickerOverlay = ({onDone, childCardIds, cards, root, limit}) => {
  const update = useAddCardToParent();
  const handleSelect = (cardId) =>
    update({parentCardId: cardId, childCardIds}).then(() => onDone(cardId));

  const selectedProjects = getSelectedProjects(root);
  const filterProjectIds = new Set(
    [
      ...cards.map((card) => card.deck?.project.$meta.get("id", null)),
      ...selectedProjects.map((p) => p.$meta.get("id", null)),
    ].filter(Boolean)
  );
  const validParentCardFilter = {
    cardId: {op: "notIn", value: childCardIds},
    deck: {projectId: [...filterProjectIds]},
    parentCardId: null,
    visibility: "default",
  };

  const {tabsEl, activeContent} = useTabs(
    [
      {
        key: "existing",
        label: "Find existing",
        content: (
          <XCol sp={3}>
            <CardPicker
              onSelect={handleSelect}
              root={root}
              filter={validParentCardFilter}
              emptyMessage="The search exludes private and sub cards as those are no valid Hero cards."
              label="Enter title of Hero card"
              topBoxProps={{px: 3}}
              bottomBoxProps={{px: 3, bg: "gray100"}}
              limit={limit}
            />
          </XCol>
        ),
      },
      {
        key: "new",
        label: "Create new",
        content: <CardCreator onCreated={handleSelect} root={root} />,
      },
    ],
    "existing"
  );

  const {isValid, msg} = areValidChildren(cards);

  if (!isValid) {
    return (
      <XCol pa={2}>
        <XText style={{width: 250}}>{msg}</XText>
      </XCol>
    );
  }
  return (
    <XCol style={{width: 300, maxWidth: "100%"}}>
      <XCol px={3} py={1}>
        {tabsEl}
      </XCol>
      {activeContent}
    </XCol>
  );
};

const AddToParentMenu = ({
  allSelectedCards,
  selectedCardIds,
  root,
  arenaCtx,
  clearSelectedCardIds,
}) => {
  const {goToCard} = arenaCtx.routes;
  return (
    <MenuButtonWithDropDown
      shortcut="s"
      tooltip="Add to Hero card"
      overlayFn={({close, ...overlayProps}) => (
        <DefaultOverlay {...overlayProps}>
          <CardPickerOverlay
            cards={allSelectedCards}
            onClose={close}
            root={root}
            childCardIds={[...selectedCardIds]}
            goToCard={goToCard}
            clearSelectedCardIds={clearSelectedCardIds}
            onDone={(cardId) => {
              close();
              clearSelectedCardIds();
              goToCard({cardId, targetPanel: "childCards"});
            }}
          />
        </DefaultOverlay>
      )}
    >
      <DSIconCrown size={20} />
    </MenuButtonWithDropDown>
  );
};

export default AddToParentMenu;

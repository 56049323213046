type Cb<T> = (...args: T[]) => any;

class MiniEvent<T> {
  listeners = new Set<Cb<T>>();

  addListener = (cb: Cb<T>) => {
    this.listeners.add(cb);
    return () => {
      this.listeners.delete(cb);
    };
  };

  emit = (...args: T[]) => {
    this.listeners.forEach((l) => l(...args));
  };

  isSomeoneListening = () => this.listeners.size > 0;
}

export default MiniEvent;

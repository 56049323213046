import {markdownLineEndingOrSpace} from "micromark-util-character";
import {setAttacherOptions} from "./plugin-helpers";

const validPersonalTagCharacterRegex = /[\p{Letter}0-9\-_.]/u;
export const validPersonalTagContentRegex = /^[\p{Letter}0-9\-_.]+$/u;

const tokenizer = function (effects, ok, nok) {
  const self = this;

  const startAt = (code) => {
    const prev = self.previous;
    if (prev && !markdownLineEndingOrSpace(prev)) {
      return nok(code);
    }
    effects.enter("cdxTag");
    effects.enter("cdxTagMarker");
    effects.consume(code);
    effects.exit("cdxTagMarker");
    effects.enter("cdxTagContent");
    return insideFirst;
  };
  const insideFirst = (code) => {
    if (!code || markdownLineEndingOrSpace(code)) {
      return nok(code);
    } else {
      return inside(code);
    }
  };
  const inside = (code) => {
    if (!code || markdownLineEndingOrSpace(code)) {
      effects.exit("cdxTagContent");
      effects.exit("cdxTag");
      return ok(code);
    } else if (validPersonalTagCharacterRegex.test(String.fromCharCode(code))) {
      effects.consume(code);
      return inside;
    } else {
      return nok(code);
    }
  };
  return startAt;
};

export const cdxTagMicromarkExtension = {
  text: {
    // "#".charCodeAt(0) -> 35
    35: {
      name: "cdxTag",
      tokenize: tokenizer,
    },
  },
};

export const cdxTagFromMarkdown = {
  enter: {
    cdxTagContent: function (token) {
      this.enter({type: "cdxTag", tag: this.sliceSerialize(token)}, token);
    },
  },
  exit: {
    cdxTagContent: function (token) {
      this.exit(token);
    },
  },
};

export default function cdxTagMarkdownPlugin({render} = {}) {
  const data = this.data();
  (data.micromarkExtensions = data.micromarkExtensions || []).push(cdxTagMicromarkExtension);
  (data.fromMarkdownExtensions = data.fromMarkdownExtensions || []).push(cdxTagFromMarkdown);

  setAttacherOptions(this, "remarkRehype", (opts) => ({
    ...opts,
    handlers: {
      ...opts.handlers,
      cdxTag: (state, node) => ({
        type: "element",
        tagName: "cdxTag",
        properties: {tag: node.tag},
        children: state.all(node),
      }),
    },
  }));

  if (render) {
    setAttacherOptions(this, "rehypeReact", (opts) => ({
      ...opts,
      components: {
        ...opts.components,
        cdxTag: ({tag}) => render(tag),
      },
    }));
  }
}

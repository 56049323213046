import {ReactNode, createContext, useContext, useEffect, useRef, useState} from "react";
import {Root} from "../../cdx-models/Root";

const MateContext = createContext<any>(null);

export const MateContextProvider = ({children, api}: {children: ReactNode; api: any}) => {
  return <MateContext.Provider value={api}>{children}</MateContext.Provider>;
};

export const useRoot = () => {
  const api = useContext(MateContext);
  const [root, setRoot] = useState<Root>(api.getRoot);
  useEffect(() => api.registerOnChangeListener(() => setRoot(api.getRoot())), [api]);
  return root;
};

export const useApi = () => useContext(MateContext);

export const useInstance = (modelName: string, id: any) => {
  const api = useContext(MateContext);
  const [, setCounter] = useState(0);
  const modelRef = useRef<null | any>(null);
  modelRef.current = id && api.getModel({modelName, id});
  useEffect(
    () =>
      api.registerOnChangeListener(() => {
        const model = id && api.getModel({modelName, id});
        if (model !== modelRef.current) setCounter((c) => c + 1);
      }),
    [modelName, id, api]
  );
  return modelRef.current && !modelRef.current.$meta.isDeleted() ? modelRef.current : null;
};

export const checkIfPresent = <T extends any>(
  getDataFn: () => T,
  api: any
  // debug?: boolean
): {isLoaded: boolean; result: T} => {
  let isLoaded = true;
  const unsub = api.registerOnNotLoadedListener((status: string) => {
    if (status === "loading" || status === "notLoaded") {
      // if (debug) debugger;
      isLoaded = false;
    }
  });
  const result = getDataFn();
  unsub();
  return {isLoaded, result};
};

export const checkIfValid = <T extends any>(
  getDataFn: () => T,
  api: any
): {isLoaded: boolean; result: T} => {
  let isLoaded = true;
  const unsub = api.registerOnNotLoadedListener((status: string) => {
    isLoaded = false;
  });
  const result = getDataFn();
  unsub();
  return {isLoaded, result};
};

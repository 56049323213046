import {visit} from "unist-util-visit";
import {isSelectionActive} from "../../../lib/utils";
import {checkboxStyles as styles} from "../markdown.css";
import {setAttacherOptions} from "./plugin-helpers";
import {cx, isWithMetaKey} from "@cdx/common";
import {css} from "@cdx/ds";

const blockTags = new Set(["UL", "OL", "BLOCKQUOTE", "P", "PRE"]);

const isBlockTagInbetween = (clickTarget, checkboxLabelEl) => {
  if (!clickTarget) return false;
  if (clickTarget === checkboxLabelEl) return false;
  if (clickTarget.nodeName === "P" && checkboxLabelEl.children[0] === clickTarget) {
    // if we're using "paragraphed lists" (i.e. ul > li > p) accept clicking on the <p>
    return false;
  }
  if (blockTags.has(clickTarget.nodeName)) return true;
  return isBlockTagInbetween(clickTarget.parentElement, checkboxLabelEl);
};

export const CardCheckbox = ({
  checked,
  disabled,
  onChange,
  children,
  content,
  offset,
  checkboxLen,
  onClick,
}) => {
  const handleChange = (e) => {
    if (disabled) return;
    if (isSelectionActive()) return;
    const newContent = `${content.slice(0, offset)}${checked ? "[]" : "[x]"}${content.slice(
      offset + checkboxLen
    )}`;
    onChange(newContent);
  };

  const handleClick = (e) => {
    if (isWithMetaKey(e) && onClick) {
      onClick(e);
      return;
    }
    if (e.target && e.target !== e.currentTarget) {
      if (isBlockTagInbetween(e.target, e.currentTarget)) return;
    }
    e.stopPropagation();
    handleChange();
  };

  return (
    <li className={styles.listEl}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        className={styles.checkbox}
        disabled={disabled}
      />
      <div
        onClick={handleClick}
        className={cx(styles.label, !disabled && css({cursor: "pointer"}))}
        data-cdx-clickable
      >
        {children}
      </div>
    </li>
  );
};

const checkboxPlugin = function ({getProps} = {}) {
  setAttacherOptions(this, "rehypeReact", (reOpts) => ({
    ...reOpts,
    components: {
      ...reOpts.components,
      checkbox: (checkProps) => <CardCheckbox {...(getProps && getProps())} {...checkProps} />,
    },
  }));

  return (tree, vFile) => {
    visit(
      tree,
      ({type}) => type === "listItem",
      (n) => {
        if (n.checked !== null) {
          const child = n.children[0];
          if (!child) return;
          n.data = {
            ...n.data,
            hName: "checkbox",
            hProperties: {
              checked: n.checked,
              content: vFile.value,
              offset: n.markerStartOffset,
              checkboxLen: n.markerEndOffset - n.markerStartOffset,
            },
          };
          n.checked = null;
        }
      }
    );
    // TODO: verify if still needed
    // visit(
    //   tree,
    //   ({type, spread}) => type === "list" && spread === true,
    //   (n) => {
    //     const hasCheckedChild = n.children.some(
    //       (child) => child.data && "checked" in child.data.hProperties
    //     );
    //     if (hasCheckedChild) n.spread = false;
    //   }
    // );
  };
};

export default checkboxPlugin;

import {cx, useGlobalKeyPress} from "@cdx/common";
import Selection from "../../features/selection-header";
import MainNavigation from "./main-navigation";
import {XRow} from "../../components/xui";
import {Joystick} from "../../features/nudges/MenuNudges";
import {arenaHeaderStyles as styles} from "./arena.css";
import uiClasses from "@cdx/common/xui/ui.css";
import {hasPermissionToSeeOnboarding} from "../../lib/permissions";
import OnboardingIcon from "../../features/onboarding/OnboardingIcon";
import MilestoneSidebarToggles from "../../pages/milestones/MilestonesSidebarToggles";
import {colorThemes} from "@cdx/ds/css/themes/color-overwrites.css";

const ArenaHeader = ({
  root,
  setIsMenuOpen,
  activeTab,
  searchElement,
  history,
  noSelectionHeader,
}) => {
  useGlobalKeyPress({
    code: "Digit4",
    fn: () => setIsMenuOpen((val) => !val),
    description: "Toggle Mission Control",
    withShift: true,
  });

  const canSeeOnboarding = hasPermissionToSeeOnboarding(root);

  return (
    <XRow
      bg="gray800"
      className={cx(styles.header.container, colorThemes.gray850)}
      sp={3}
      data-cdx-context="MainHeader"
    >
      <XRow align="center" pl={3}>
        <Joystick root={root} onClick={() => setIsMenuOpen((val) => !val)} />
      </XRow>
      {root.loggedInUser && (
        <XRow fillParent minWidth sp={3}>
          <MainNavigation activeTab={activeTab} root={root} history={history} />
          <XRow pa={1} justify="end" style={{flex: "120px 1 2"}} align="center" sp={4}>
            {searchElement || (
              <div
                className={uiClasses.hide.sm}
                style={{maxWidth: "100%", width: activeTab === "milestones" ? 225 : 250}}
              />
            )}
            {activeTab === "milestones" && <MilestoneSidebarToggles root={root} />}
            {canSeeOnboarding && <OnboardingIcon root={root} />}
          </XRow>
        </XRow>
      )}
      {!noSelectionHeader && <Selection root={root} />}
    </XRow>
  );
};

export default ArenaHeader;

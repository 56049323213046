import {useCallback, useEffect, useMemo, useRef, useState} from "react";

export type MachineEvents = Record<
  string,
  (nextData: any, prevData: any) => readonly [string, any] | string
>;
export type MachineDescription = Record<
  string,
  {initial?: boolean; initialData?: any; on: MachineEvents}
>;

const getInitialState = (description: MachineDescription) => {
  const keys = Object.keys(description);
  const initState = keys.find((k) => description[k].initial) || keys[0];
  return [initState, description[initState].initialData] as const;
};

const useMachine = (
  description: MachineDescription,
  initialStateWithData?: readonly [string, any]
) => {
  const [stateWithData, setStateWithData] = useState<readonly [string, any]>(
    initialStateWithData || (() => getInitialState(description))
  );
  const deadRef = useRef(false);
  useEffect(
    () => () => {
      deadRef.current = true;
    },
    []
  );
  const [value, data] = stateWithData;
  const current = useMemo(() => ({value, data}), [value, data]);
  const descriptionRef = useRef(description);
  useEffect(() => {
    descriptionRef.current = description;
  }, [description]);
  const send = useCallback((name: string, arg: any) => {
    if (deadRef.current) return;
    return setStateWithData((prev) => {
      const stateDesc = descriptionRef.current[prev[0]];
      const transFn = stateDesc.on[name];
      if (!transFn) return prev;
      const res = transFn(arg, prev[1]);
      if (Array.isArray(res)) {
        return res as readonly [string, any];
      } else {
        return [res as string, null];
      }
    });
  }, []);
  return [current, send] as const;
};

// const fetchMachine = {
//   ready: {
//     initial: true,
//     initialData: {},
//     on: {
//       REQUEST: fn => ["loading", {promise: fn()}],
//     },
//   },
//   loading: {
//     on: {
//       SUCCESS: () => "ready",
//       ERROR: error => ["ready", {lastError: error}],
//     },
//   },
// };

export default useMachine;

import {useState, useEffect, useRef} from "react";
import {useTransition, animated, config} from "react-spring";
import {MiniEvent, Portal} from "@cdx/common";
import {blingBoxStyle} from "./bling.css";
import {getZIndex} from "@cdx/ds/utils/dom-utils";

const blingEvents = new MiniEvent<{msg: string; node: HTMLElement}>();

export const BlingProvider = () => {
  const [items, setItems] = useState<
    {key: number; msg: string; pos: {top: number; left: number}; zIndex: number}[]
  >([]);
  useEffect(() => {
    let nextKey = 1;
    return blingEvents.addListener(({msg, node}) => {
      const {top, left, width} = node.getBoundingClientRect();
      const pos = {top: top - 16, left: left + width / 2};
      setItems((prev) => [
        ...prev,
        {key: (nextKey += 1), msg, pos, zIndex: (getZIndex(node) || 0) + 10},
      ]);
    });
  }, []);

  const transition = useTransition(items, {
    key: (item: any) => item.key,
    from: {transform: "translate3d(-50%,0px,0) scale(0.5)", opacity: 0},
    enter: (item) => (next) =>
      next({
        transform: "translate3d(-50%,-15px,0) scale(1)",
        opacity: 1,
      }).then(() => {
        setItems((state) => state.filter((i) => i.key !== item.key));
      }),
    leave: {transform: "translate3d(-50%,-40px,0) scale(1)", opacity: 0.001},
    config: config.stiff,
  });
  return transition((props, item) => (
    <Portal>
      <animated.div className={blingBoxStyle} style={{...props, ...item.pos, zIndex: item.zIndex}}>
        {item.msg}
      </animated.div>
    </Portal>
  ));
};

const useBling = () => {
  const blingRef = useRef<HTMLElement | null>(null);

  return [
    blingRef,
    (msg: string) => {
      if (blingRef.current) blingEvents.emit({msg, node: blingRef.current});
    },
  ] as const;
};

export default useBling;

import {useRef, useEffect, useCallback} from "react";
import messenger from "../../lib/messenger";
import DropArea from "./DropArea";
import Input from "./Input";
import Uploads from "./Uploads";
import confirm from "../../lib/confirm";
import {useUploadData, uploadFile} from "./Provider";
import {useFilePaster} from "../../features/card-container/FilePasteHandler";
import {api} from "../../lib/api";
import {waitForResultPromise} from "../../lib/wait-for-result";
import {remainingSpaceInKB} from "../../features/billing/billing-utils";
import {errorToString} from "@cdx/common";
import {getImageDimensionsFromUrl} from "../CdxImg";

const Uploader = ({children, ...props}) => children(useUpload(props));

Uploader.DropArea = DropArea;
Uploader.Input = Input;
Uploader.Uploads = Uploads;

// props = {
//   accept,
//   multiple,
//   onUpload,
//   noAccount,
//   id,
//   affectsQuota,
//   allowPaste,
// }

export const transformUploadForTextEditor = (oldUploadFn) => {
  if (!oldUploadFn) return null;
  return (file) => {
    return oldUploadFn([file]).then((res) => {
      if (!res) Promise.reject("errorred");
      const [{fileName, uploadRes}] = res;
      return {
        name: fileName,
        src: uploadRes.url,
        img: uploadRes.meta?.width
          ? {width: uploadRes.meta.width, height: uploadRes.meta.height}
          : null,
      };
    });
  };
};

export const getDefaultUploadFromTextEditor = (id) => (file) => {
  const handleUpload = async (res) => {
    const meta = await getImageDimensionsFromUrl(res.url);
    return {meta};
  };
  return uploadFile({id, file, onUpload: handleUpload}).then((res) => {
    if (!res) Promise.reject("errorred");
    const {fileName, uploadRes, url} = res;
    return {
      name: fileName,
      src: url,
      img: uploadRes.meta?.width
        ? {width: uploadRes.meta.width, height: uploadRes.meta.height}
        : null,
    };
  });
};

export const useUpload = (props) => {
  const ongoingUploads = useUploadData(props.id);
  const lastPropsRef = useRef(props);
  useEffect(() => {
    lastPropsRef.current = props;
  }, [props]);

  const _uploadFile = useCallback((files, onError) => {
    const {id, affectsQuota, multiple, onUpload, noAccount} = lastPropsRef.current;
    return waitForResultPromise(
      () => affectsQuota && remainingSpaceInKB(api.getRoot().account) < 0
    ).then((isBeyond) => {
      if (isBeyond) {
        confirm({
          title: "Account storage limit exceeded",
          content: "Account storage limit has been exceeded. Please talk to one of your admins.",
          confirmLabel: "Okay",
        });
      } else if (!multiple && files.length > 1) {
        onError({type: "multiple", data: {amount: files.length}});
      } else {
        return Promise.all(
          Array.from(files).map((file) =>
            uploadFile({id, file, onUpload, noAccount, affectsQuota}).catch((e) => {
              messenger.send(errorToString(e), {type: "error"});
            })
          )
        );
      }
    });
  }, []);

  const uploadFromInput = useCallback(
    (e) =>
      _uploadFile(e.target.files, () => {
        messenger.send("You may only upload one file here", {type: "error"});
      }),
    [_uploadFile]
  );

  const dropAreaProps = {
    onUpload: props.onUpload,
    noAccount: props.noAccount,
    multiple: props.multiple,
    id: props.id,
    startUpload: _uploadFile,
  };
  const inputProps = {
    type: "file",
    onChange: uploadFromInput,
    multiple: props.multiple,
    accept: props.accept,
  };

  useFilePaster(props.allowPaste && _uploadFile);
  return {dropAreaProps, inputProps, ongoingUploads, onReceiveFile: _uploadFile};
};

export default Uploader;

import {useState, useMemo} from "react";

const delFromSet = (set, val) => {
  set.delete(val);
  return new Set(set);
};

const addToSet = (set, val) => {
  return new Set(set.add(val));
};

const newSetFn = () => new Set();

/**
 * @template T
 * @typedef {Object} SetActions
 * @property {(item: T) => void} add
 * @property {(item: T) => void} remove
 * @property {(item: T) => void} toggle
 * @property {() => void} clear
 * @property {(set: Set<T>) => void} set
 */

/**
 * @template T
 * @param {Set<T> | () => Set<T>=} initial
 * @returns {[Set<T>, SetActions<T>]}
 */
const useSet = (initial) => {
  const [set, setSet] = useState(initial || newSetFn);

  const actions = useMemo(
    () => ({
      add: (item) => setSet((prev) => addToSet(prev, item)),
      remove: (item) => setSet((prev) => delFromSet(prev, item)),
      toggle: (item) =>
        setSet((prev) => (prev.has(item) ? delFromSet(prev, item) : addToSet(prev, item))),
      clear: () => setSet(() => new Set()),
      set: setSet,
    }),
    [setSet]
  );

  return [set, actions];
};

export default useSet;

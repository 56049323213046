import {useState, useEffect} from "react";

export const delayedTrigger = () => {
  let timeoutId = null;
  return {
    fire: (fn, delay) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        timeoutId = null;
        fn();
      }, delay);
    },
    cancel: () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    },
  };
};

export const useDelayedTrigger = () => {
  const [trigger] = useState(delayedTrigger);
  useEffect(() => () => trigger.cancel(), [trigger]);
  return trigger;
};

import {forwardRef} from "react";
import {XText as RawXText} from "@cdx/common";

export {XCol, XGrid, XPush, XRow} from "@cdx/common";

export const XText = forwardRef((props, ref) => {
  if (props.color && typeof props.color === "function") {
    const {color, ...rest} = props;
    return <RawXText ref={ref} color={props.color("light")} {...rest} />;
  } else {
    return <RawXText {...props} ref={ref} />;
  }
});

import deepEqual from "deep-equal";
import {useEffect} from "react";
import {create} from "zustand";
import useOriginalCopy from "../../lib/hooks/useOriginalCopy";

export const useViewerInfoStore = create((set) => ({
  byKey: {},
  setForKey: (key, data) => set((prev) => ({byKey: {...prev.byKey, [key]: data}})),
  addForKey: (key, data) => {
    set((prev) => {
      const keyList = prev.byKey[key] || [];
      keyList.push(data);
      return {byKey: {...prev.byKey, [key]: keyList}};
    });
    return () =>
      set((prev) => {
        const keyList = prev.byKey[key];
        if (!keyList) return prev;
        keyList.splice(keyList.indexOf(data), 1);
        if (keyList.length === 0) {
          delete prev[key];
          return prev;
        } else {
          return {byKey: {...prev.byKey, [key]: keyList}};
        }
      });
  },
  deleteKey: (key) =>
    set((prev) => {
      const {byKey} = prev;
      delete byKey[key];
      return prev;
    }),
}));

export const useImageViewerInfo = ({key, imgKeysAndInfos}) => {
  const original = useOriginalCopy(imgKeysAndInfos, deepEqual);
  useEffect(() => {
    return () => useViewerInfoStore.getState().deleteKey(key);
  }, [key]);
  useEffect(() => {
    useViewerInfoStore.getState().setForKey(key, original);
  }, [original, key]);
};

const imageTypes = new Set([
  "apng",
  "avif",
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
  "bmp",
  "ico",
  "tif",
  "tiff",
]);

export const canDisplayAsImage = (file) => {
  return (
    file.meta && file.meta.type && imageTypes.has(file.meta.type.toLowerCase()) && !file.isDeleted
  );
};

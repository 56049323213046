import {
  Document,
  TooltipForChild,
  XCol,
  XForm,
  XPush,
  XRow,
  XText,
  XTextButton,
  cx,
  getMetaKeyLabel,
  useGlobalKeyPress,
  useNeoBag,
} from "@cdx/common";
import {css, DSButton, DSIconButton, DSIconFocusMode} from "@cdx/ds";
import {forwardRef} from "react";
import {transformUploadForTextEditor} from "../../components/uploader";
import {makeScrollable} from "@cdx/ds/utils/makeScrollable";
import {fieldClass} from "./unified-card-container.css";
import {CdxLexicalEditor} from "../../components/RichTextarea/Lexical/LexicalRichTextProvider";
import FocusModeCommandPlugin from "./FocusModeCommandPlugin";

const formRules = {
  content: [[(val) => !val || val.isEmpty === false, "needs to have some content"]],
};

export const cardContentEditFormRules = formRules;

const allowEmptyRules = {
  content: [],
};

const CustomTextarea = forwardRef(({hasPendingValidation, showErrors, Editor, ...props}, ref) => (
  <>
    <Editor {...props} ref={ref} />
    {showErrors.length > 0 && (
      <XCol px={1} pb={1}>
        <XText size={1} color="error600" preset="bold">
          {showErrors[0]}
        </XText>
      </XCol>
    )}
  </>
));

const ServerError = ({children}) => (
  <XCol px={0} pb={0}>
    <XCol bg="error100" px={2} py={1} rounded="sm">
      <XText preset="bold" size={1} color="error700">
        {children}
      </XText>
    </XCol>
  </XCol>
);

const SubmitButton = ({disabled, children}) => {
  const {handleSubmit, submitting} = useNeoBag();
  return (
    <DSButton
      size="md"
      onClick={handleSubmit}
      disabled={disabled}
      state={submitting ? "loading" : undefined}
    >
      {children}
    </DSButton>
  );
};

/** @type: any */
export const CardContentForm = ({
  card,
  contentField,
  onReceiveFile,
  togglePreview,
  isEmpty,
  handleSubmit,
  buttonLabel,
  onCancel,
  allowEmpty,
  placeholder,
  project,
  hasErrors,
  onCardChange,
  focusProps,
}) => {
  // allow cmd enter even if text area is not focused
  useGlobalKeyPress({key: "Enter", withMod: true, fn: handleSubmit});

  return (
    <XCol fillParent minHeight py={1} sp={1}>
      <XRow px={1} sp={2}>
        <Document size="sm">
          Enter <code>/</code> to see commands
        </Document>
        <XPush />
        {focusProps && (
          <TooltipForChild tooltip="Enter focus mode" placement="top">
            <DSIconButton
              active={focusProps.active}
              onClick={() => {
                const curr = contentField.state.ref.current;
                // editorState is not being updated when selection changes, so extract freshest state from editor
                focusProps.setActive({
                  editorState: curr?.editor.getEditorState().clone(),
                });
              }}
              icon={<DSIconFocusMode />}
              variant="tertiary"
              size="sm"
              negatePadding
            />
          </TooltipForChild>
        )}
      </XRow>
      <XCol
        as={XForm}
        onSubmit={handleSubmit}
        values={{content: {...contentField.state, isEmpty}}}
        rules={allowEmpty ? allowEmptyRules : formRules}
        fillParent
        minHeight
        serverErrorComp={ServerError}
      >
        <XForm.Field
          as={CustomTextarea}
          Editor={CdxLexicalEditor}
          maybeProject={project || (card.deck && card.deck.project)}
          name="content"
          placeholder={
            placeholder ||
            'Just start typing! Give the card a title in your first line. Then describe the "work to be done".'
          }
          onPasteFile={transformUploadForTextEditor(onReceiveFile)}
          submitOnCmdEnter
          className={cx(fieldClass, makeScrollable())}
          card={card}
          autoFocus
          onCardChange={onCardChange}
          plugins={focusProps && <FocusModeCommandPlugin focusProps={focusProps} />}
        />
        <XRow px={1} pl={2} sp={1} align="center">
          <DSButton
            variant="tertiary"
            size="sm"
            onClick={togglePreview}
            disabled={isEmpty}
            className={css({colorTheme: "purpleTextOnLight"})}
          >
            Preview
          </DSButton>
          {onCancel && (
            <XTextButton color="redOnHover" onClick={onCancel}>
              Cancel
            </XTextButton>
          )}
          <XPush />
          <XRow sp={1} align="center">
            {card.assignee && card.masterTags.length === 0 ? (
              <XText size={1}>{getMetaKeyLabel()}+Enter</XText>
            ) : (
              <XText size={1}>Press {getMetaKeyLabel()}+Enter to</XText>
            )}
            <SubmitButton size="md" type="submit" disabled={hasErrors}>
              {buttonLabel}
            </SubmitButton>
          </XRow>
        </XRow>
      </XCol>
    </XCol>
  );
};

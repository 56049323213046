import {useSlashCommand} from "@cdx/ds/components/DSTextEditor/CdxSlashCommandLexicalPlugin";
import {textUpdateSelectionHandler} from "../../components/RichTextarea/Lexical/CdxTypeAheadLexicalPlugin";
import {ConvoAction} from "../card-container/sidebars/next-conversations/get-convo-actions";

const CloseCommand = ({
  closeAction,
  commentIfContent,
}: {
  closeAction: ConvoAction;
  commentIfContent: () => Promise<void>;
}) => {
  useSlashCommand({
    key: "close",
    label: closeAction.message,
    keywords: `close resolve submit ${closeAction.message}`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        closeAction.onClick?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

const DoneCommand = ({
  closeAction,
  commentIfContent,
  onDone,
}: {
  closeAction: ConvoAction;
  commentIfContent: () => Promise<void>;
  onDone: (() => Promise<void>) | null;
}) => {
  useSlashCommand({
    key: "done",
    label: `${closeAction.message} and mark Card as done`,
    keywords: `close card mark done`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        await closeAction.onClick?.();
        onDone?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

const OptOutCommand = ({
  optOutAction,
  commentIfContent,
}: {
  optOutAction: ConvoAction;
  commentIfContent: () => Promise<void>;
}) => {
  useSlashCommand({
    key: "opt-out",
    label: optOutAction.message,
    keywords: `opt-out leave approve ${optOutAction.message}`,
    handleSelect: (e, resolution) => {
      const handler = textUpdateSelectionHandler(() => {});
      handler(null, e, resolution);
      setTimeout(async () => {
        await commentIfContent();
        optOutAction.onClick?.();
      });
    },
    groupKey: "conversation",
  });
  return null;
};

type ConvoEditorCommandsPluginProps = {
  actions: ConvoAction[];
  commentIfContent: () => Promise<void>;
  onDone: (() => Promise<void>) | null;
};
export const ConvoEditorCommandsPlugin = ({
  actions,
  commentIfContent,
  onDone,
}: ConvoEditorCommandsPluginProps) => {
  const closeAction = actions.find((a) => a.type === "close");
  const optOutAction = actions.find((a) => a.type === "opt-out");
  return (
    <>
      {closeAction && (
        <CloseCommand closeAction={closeAction} commentIfContent={commentIfContent} />
      )}
      {closeAction && onDone && (
        <DoneCommand
          closeAction={closeAction}
          commentIfContent={commentIfContent}
          onDone={onDone}
        />
      )}
      {optOutAction && (
        <OptOutCommand optOutAction={optOutAction} commentIfContent={commentIfContent} />
      )}
    </>
  );
};

export default ConvoEditorCommandsPlugin;

import {markdownLineEndingOrSpace, unicodePunctuation} from "micromark-util-character";
import {pronounceSafeSeq} from "../../../lib/sequences";
import {setAttacherOptions} from "./plugin-helpers";

const regexCheck = (regex) => {
  return (code) => code !== null && regex.test(String.fromCharCode(code));
};

const validReferenceChar = regexCheck(new RegExp(`[${pronounceSafeSeq.letters}]`));

const tokenizer = function (effects, ok, nok) {
  const self = this;
  let i = 0;

  const startAt = (code) => {
    const prev = self.previous;
    if (prev && !markdownLineEndingOrSpace(prev)) {
      return nok(code);
    }
    effects.enter("cdxCardReference");
    effects.enter("cdxCardReferenceMarker");
    effects.consume(code);
    effects.exit("cdxCardReferenceMarker");
    effects.enter("cdxCardReferenceContent");
    return insideFirst;
  };
  const insideFirst = (code) => {
    if (!code || markdownLineEndingOrSpace(code)) {
      return nok(code);
    } else {
      return inside(code);
    }
  };
  const inside = (code) => {
    if (i > 5) return nok(code);
    if (!code || markdownLineEndingOrSpace(code) || unicodePunctuation(code)) {
      if (i < 3) return nok(code);
      effects.exit("cdxCardReferenceContent");
      effects.exit("cdxCardReference");
      return ok(code);
    } else if (validReferenceChar(code)) {
      i += 1;
      effects.consume(code);
      return inside;
    } else {
      return nok(code);
    }
  };
  return startAt;
};

export const cdxCardReferenceMicromarkExtension = {
  text: {
    // "$".charCodeAt(0) -> 36
    36: {
      name: "cdxCardReference",
      tokenize: tokenizer,
    },
  },
};

export const cdxCardReferenceFromMarkdown = {
  enter: {
    cdxCardReferenceContent: function (token) {
      this.enter({type: "cdxCardReference", reference: this.sliceSerialize(token)}, token);
    },
  },
  exit: {
    cdxCardReferenceContent: function (token) {
      this.exit(token);
    },
  },
};

export default function cdxCardReferenceMarkdownPlugin({render} = {}) {
  const data = this.data();
  (data.micromarkExtensions = data.micromarkExtensions || []).push(
    cdxCardReferenceMicromarkExtension
  );
  (data.fromMarkdownExtensions = data.fromMarkdownExtensions || []).push(
    cdxCardReferenceFromMarkdown
  );

  setAttacherOptions(this, "remarkRehype", (opts) => ({
    ...opts,
    handlers: {
      ...opts.handlers,
      cdxCardReference: (state, node) => ({
        type: "element",
        tagName: "cdxCardReference",
        properties: {reference: node.reference},
        children: state.all(node),
      }),
    },
  }));

  if (render) {
    setAttacherOptions(this, "rehypeReact", (opts) => ({
      ...opts,
      components: {
        ...opts.components,
        cdxCardReference: ({reference}) => render(reference),
      },
    }));
  }
}

import {useCallback, useEffect} from "react";
import {create} from "zustand";
import {startOfDay} from "../../lib/date-utils";
import useMutation from "../../lib/hooks/useMutation";

const emptySet = new Set();

const useHintStore = create((set) => ({
  isLoading: true,
  dismissedHintKeys: emptySet,
  set,
}));

const extractHints = (root) => {
  const {loggedInUser} = root;
  const now = startOfDay();
  const dismissedHints =
    loggedInUser &&
    loggedInUser.$meta.find("dismissedHints", {
      $or: [{returnAt: null}, {returnAt: {op: "lt", value: now}}],
    });
  if (!dismissedHints || dismissedHints.length === 0) {
    useHintStore.getState().set({isLoading: false, dismissedHintKeys: emptySet});
  } else {
    if (!dismissedHints[0].$meta.get("hintKey", null)) {
      useHintStore.getState().set({isLoading: true});
    } else {
      const {set, dismissedHintKeys} = useHintStore.getState();
      const nextKeys = dismissedHints.map((dh) => dh.hintKey);
      const nextDismissedHintKeys =
        nextKeys.length === dismissedHintKeys.size &&
        nextKeys.every((k) => dismissedHintKeys.has(k))
          ? dismissedHintKeys
          : new Set(nextKeys);
      set({isLoading: false, dismissedHintKeys: nextDismissedHintKeys});
    }
  }
};

export const SetupHints = ({root}) => {
  useEffect(() => {
    extractHints(root);
  }, [root]);
  return null;
};

export const useDismissedHintKeys = () =>
  useHintStore((s) => (s.isLoading ? "loading" : s.dismissedHintKeys));

export const useShouldShowHintKey = (key, fallback = false) => {
  const shouldShow = useHintStore((s) =>
    Boolean(!key || s.isLoading ? fallback : !s.dismissedHintKeys.has(key))
  );
  const [doDismiss] = useMutation("hints", "dismiss");
  const dismissFn = useCallback(() => {
    if (key) return doDismiss({hintKey: key});
  }, [key, doDismiss]);
  return [shouldShow, dismissFn];
};

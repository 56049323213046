import xcolors from "../xui/xcolors";
import DeckLikeTile from "./DeckLikeTile";

const SmallDeck = ({root, deck, onClick, style}) => {
  const isCoverFileLoaded = deck.$meta.isFieldLoaded("coverFile");
  const stats = deck.$meta.get("stats", {});
  const counts = stats.counts || {};

  const cardCounts = {
    incomplete:
      (counts.doc || 0) +
      (counts.blocked || 0) +
      (counts.review || 0) +
      (counts.hero || 0) +
      (counts.unassigned || 0) +
      (counts.assigned || 0) +
      (counts.snoozing || 0) +
      (counts.started || 0),
    complete: counts.done || 0,
    review: counts.review || 0,
    blocked: counts.blocked || 0,
    snoozing: counts.snoozing || 0,
  };

  const coverFileUrl = deck.coverFile && deck.coverFile.$meta.get("url", null);
  const dominantColors = deck.coverFile && deck.coverFile.meta.dominantColors;
  const fallback =
    (dominantColors && dominantColors.length > 0 && dominantColors[0].color) || xcolors.gray600;
  const dominantColor = deck.$meta.get("coverColor", null) || fallback;

  return (
    <DeckLikeTile
      onClick={onClick}
      coverFileUrl={coverFileUrl}
      completeCards={root.account.hideCompletedCardCountForDecks ? 0 : cardCounts.complete}
      incompleteCards={cardCounts.incomplete}
      dominantColor={dominantColor}
      isCoverFileLoaded={isCoverFileLoaded}
      title={deck.title}
      isSmall
      style={style}
    />
  );
};

export default SmallDeck;

import {forwardRef, useState} from "react";
import RawTextarea from "react-textarea-autosize";
import {FieldWithLabel, WithLabel} from "./FieldWithLabel";
import {inputStyles as styles, readonlyInputStyle} from "./form.css";
import cx from "../cx";
import {formatMsAsSec} from "../date-utils";

export const Input = forwardRef(
  ({showingErrors, onChange, type = "text", size = "md", className, ...rest}, ref) => (
    <input
      ref={ref}
      type={type}
      className={cx(
        styles.base,
        styles.bySize[size],
        showingErrors && styles.error,
        rest.readOnly && readonlyInputStyle,
        className
      )}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  )
);

export const Textarea = forwardRef(
  ({showingErrors, onChange, minRows = 1, className, ...rest}, ref) => (
    <RawTextarea
      ref={ref}
      onChange={onChange && ((e) => onChange(e.target.value))}
      className={cx(
        styles.base,
        showingErrors && styles.error,
        rest.readOnly && readonlyInputStyle,
        className
      )}
      minRows={minRows}
      cacheMeasurements
      {...rest}
    />
  )
);

const durationRE = /^\s*(\d+)(?::(\d{1,2}))?(?::(\d{1,2}))?\s*$/;
const numberWithTimeUnitRE = /(\d+(?:\.\d+)?)\s*((?:minutes?|hours?|seconds?|mins?|secs?|m|h|s))/g;
const durationWithUnitsRE = new RegExp(`^\\s*(${numberWithTimeUnitRE.source}\\s*)+\\s*$`);
const currencyRE = /^\s*(\d*)(?:[,.](\d{1,2}))?\s*$/;

const matchAll = (re, str) => {
  let match;
  const matches = [];
  while ((match = re.exec(str))) matches.push(match.slice(1));
  return matches;
};

const parsers = {
  integer: {
    toString: (val) => val.toString(),
    isValidString: (str) => /^\s*\d+\s*$/.test(str),
    fromString: (str) => parseInt(str.trim(), 10),
  },
  float: {
    toString: (val) => val.toString(),
    isValidString: (str) => /^\s*\d+(\.\d*)?\s*$/.test(str),
    fromString: (str) => parseFloat(str.trim()),
  },
  integerWithNegative: {
    toString: (val) => val.toString(),
    isValidString: (str) => /^\s*-?\d+\s*$/.test(str),
    fromString: (str) => parseInt(str.trim(), 10),
  },
  currency: {
    toString: (val) => {
      if (val === null) return "";
      return val.asUserInput;
    },
    isValidString: (str) => currencyRE.test(str),
    fromString: (str) => {
      const m = str.match(currencyRE);
      if (!m || !m[1]) return null;
      return {
        asUserInput: str,
        amountInCents: parseInt(m[1], 10) * 100 + (m[2] ? parseInt(m[2], 10) : 0),
      };
    },
    isEqual: (v1, v2) => v1 === v2 || (v1 && v2 && v1.amountInCents === v2.amountInCents),
  },
  duration: {
    toString: (val) => (typeof val === "number" ? formatMsAsSec(val) : ""),
    isValidString: (str) => durationRE.test(str) || durationWithUnitsRE.test(str),
    fromString: (str) => {
      const durationM = str.match(durationRE);
      if (durationM) {
        let hours = 0;
        let mins = 0;
        let secs = 0;
        if (!durationM) return null;
        if (durationM[2] !== undefined) {
          hours = parseInt(durationM[1], 10);
          mins = parseInt(durationM[2], 10);
          if (durationM[3] !== undefined) secs = parseInt(durationM[3], 10);
        } else {
          mins = parseInt(durationM[1]);
        }
        return (hours * 3600 + mins * 60 + secs) * 1000;
      } else {
        const nums = {s: 0, m: 0, h: 0};
        for (const [num, unit] of matchAll(numberWithTimeUnitRE, str)) {
          const u = unit[0];
          if (u in nums) nums[u] += parseFloat(num);
        }
        return Math.round(nums.h * 3600 + nums.m * 60 + nums.s) * 1000;
      }
    },
  },
  url: {
    toString: (val) => val,
    isValidString: (str) => /^https?:\/\/[^\s/$.?#].[^\s]*$/.test(str),
    fromString: (str) => str.trim(),
  },
};

export const ParseInput = forwardRef(
  ({onChange, value, parser, invalidValue = null, onBlur, ...rest}, ref) => {
    const {
      toString,
      isValidString,
      fromString,
      isEqual = (v1, v2) => v1 === v2,
    } = typeof parser === "string" ? parsers[parser] : parser;
    const passedValueAsString = value === invalidValue ? "" : toString(value);
    const [valAsString, setValAsString] = useState(passedValueAsString);

    const isCurrStrlValid = isValidString(valAsString);

    if (isCurrStrlValid) {
      if (!isEqual(fromString(valAsString), value)) {
        setValAsString(passedValueAsString);
      }
    } else {
      if (value !== invalidValue && valAsString !== passedValueAsString) {
        setValAsString(passedValueAsString);
      }
    }

    const handleChange = (v) => {
      setValAsString(v);
      if (isValidString(v)) {
        onChange(fromString(v));
      } else {
        onChange(invalidValue);
      }
    };

    const handleBlur = (e) => {
      if (onBlur) onBlur(e);
      if (isCurrStrlValid) setValAsString(passedValueAsString);
    };

    return (
      <Input ref={ref} onChange={handleChange} value={valAsString} onBlur={handleBlur} {...rest} />
    );
  }
);

export const ParseInputWithLabel = forwardRef((props, ref) => (
  <FieldWithLabel as={ParseInput} ref={ref} {...props} />
));

export const InputWithLabel = forwardRef((props, ref) => (
  <FieldWithLabel as={Input} ref={ref} {...props} />
));

export const TextareaWithLabel = forwardRef((props, ref) => (
  <FieldWithLabel as={Textarea} ref={ref} {...props} />
));

export const SelectWithLabel = forwardRef((props, ref) => {
  const {label, name, showErrors, hint, hasPendingValidation, onChange, ...rest} = props;
  return (
    <WithLabel
      label={label}
      name={name}
      showErrors={showErrors}
      hint={hint}
      hasPendingValidation={hasPendingValidation}
    >
      <select name={name} ref={ref} onChange={(e) => onChange(e.target.value)} {...rest} />
    </WithLabel>
  );
});

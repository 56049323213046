import {ForwardedRef, forwardRef, ReactNode, useMemo, useState} from "react";
import {mergeRefs} from "react-merge-refs";
import {Col} from "../Box/Box";
import {ArrowOverlay, useNextDropDown} from "@cdx/common";
import {DSListSelector} from "./DSListSelector";
import {DSIconButton} from "../DSButton/DSButton";
import {DSIconChevronDown} from "../DSIcon/DSIcon";
import {DSSpinner} from "../DSIcon/DSSpinner";

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  return forwardRef(render) as any;
}

type LoadState<T> = {type: "loading"} | {type: "loaded"; data: T};

const Overlay = (
  props: DSSelectionButtonProps<any, any> & {close: () => void; overlayProps: any}
) => {
  const {
    overlayProps,
    close,
    label,
    getOptions,
    optionToKey,
    optionToSearchString,
    renderOption,
    onSelect,
    value,
  } = props;
  const currentKey = value !== null ? optionToKey(value) : null;
  const load = (): LoadState<any> => {
    if (getOptions === null) return {type: "loaded", data: []};
    const res = getOptions();
    if (res && (res as any).then) {
      (res as any).then((data: any) => setState({type: "loaded", data}));
      return {type: "loading"};
    } else {
      return {type: "loaded", data: res};
    }
  };
  const [state, setState] = useState<LoadState<any>>(load);

  return (
    <ArrowOverlay arrowSize="sm" {...overlayProps}>
      <Col pa="12px" width="cardPropChangeOverlay">
        {state.type === "loading" ? (
          <DSSpinner size={24} />
        ) : (
          <DSListSelector
            label={label}
            getOptions={() => state.data}
            optionToKey={optionToKey}
            optionToSearchString={optionToSearchString}
            renderOption={renderOption}
            currentOptionKey={currentKey}
            initalSelectionKey={currentKey}
            autoFocus
            onConfirm={async (key, type, val) => {
              onSelect(key, val);
              close();
            }}
          />
        )}
      </Col>
    </ArrowOverlay>
  );
};

type DSSelectionButtonProps<TValue, TKey> = {
  value: TKey | null;
  onSelect: (key: TKey, value: TValue) => unknown;
  label?: string;
  getOptions: () => Promise<TValue[]> | TValue[] | null;
  size?: "sm" | "md" | "lg";
  optionToKey: (value: TValue) => TKey;
  optionToSearchString: (value: TValue) => string;
  renderOption: (value: TValue) => ReactNode;
  renderButtonContent: (value: TKey | null) => ReactNode;
};
const InnerSelectionButton = <TVal, TKey>(
  props: DSSelectionButtonProps<TVal, TKey>,
  passedRef: ForwardedRef<HTMLButtonElement>
) => {
  const {
    value,
    onSelect,
    label,
    getOptions,
    size = "md",
    optionToKey,
    optionToSearchString,
    renderOption,
    renderButtonContent,
    ...rest
  } = props;

  const {
    ref: dropDownRef,
    toggle,
    overlayElement,
    isOpen,
  } = useNextDropDown({
    overlayProps: {
      placement: "bottom",
      distanceFromAnchor: 10,
      renderOverlay: ({close, ...overlayProps}: any) => (
        <Overlay {...props} close={close} overlayProps={overlayProps} />
      ),
    },
  });

  const ref = useMemo(() => mergeRefs([dropDownRef, passedRef]), [dropDownRef, passedRef]);

  return (
    <>
      {overlayElement}
      <DSIconButton
        variant="secondary"
        active={isOpen}
        onClick={toggle}
        ref={ref}
        size={size}
        icon={<DSIconChevronDown />}
        iconPosition="right"
        label={renderButtonContent(value) as string}
        {...rest}
      />
    </>
  );
};

export const DSSelectionButton = fixedForwardRef(InnerSelectionButton);

import {forwardRef, useState} from "react";
import StickyBox from "react-sticky-box";
import {useDropTarget} from "../../lib/hooks/useFileDrop";
import {cx, useDelayedTrigger, XCol} from "@cdx/common";
import {dropAreaStyles as styles} from "./uploader.css";

export const RawDropArea = forwardRef(
  (
    {isOver, hasError, children, isSwimlane, labelOffset, className, labelClassName, ...rest},
    ref
  ) => {
    const cl = isSwimlane ? styles.swimlaneContainer : styles.container;
    return (
      <XCol
        absolute
        inset="full"
        justify="start"
        className={cx(
          className,
          cl.base,
          isOver && cl.over,
          hasError && cl.hasError,
          isOver && hasError && cl.overError
        )}
        {...rest}
        ref={ref}
      >
        {children && (
          <XCol
            as={StickyBox}
            offsetTop={labelOffset}
            justify="start"
            align="center"
            className={cx(styles.inner, labelClassName)}
          >
            <div
              className={cx(
                styles.label.base,
                hasError && styles.label.hasError,
                isSwimlane && hasError !== false && !isOver && styles.label.hide
              )}
            >
              {children}
            </div>
          </XCol>
        )}
      </XCol>
    );
  }
);

const DropArea = ({startUpload, onUpload, noAccount, label, ...rest}) => {
  const [error, setError] = useState();
  const trigger = useDelayedTrigger();

  const handleDrop = (files) => {
    startUpload(files, () => {
      setError("You may only upload one file here");
      trigger.fire(() => setError(null), 3000);
    });
  };

  const {isDragging, isOver, dropTargetProps} = useDropTarget({onDrop: handleDrop});

  return !isDragging && !error ? null : (
    <RawDropArea isOver={isOver} hasError={error} {...rest} {...dropTargetProps}>
      {error || label}
    </RawDropArea>
  );
};

export default DropArea;
